import { useState } from 'react'

import { type Provider, useInsuranceProviderQuery } from '@nuna/api'
import { TavaDataTableSkeleton } from '@nuna/common'
import { Grid } from '@nuna/tunic'

import { Checklist } from './Checklist'
import { CredentialingDialog } from './Dialogs/CredentialingDialog'

export type CredentialingDialogTypes = 'documents' | 'packetBuilder'
export enum CredentialingDialogs {
  documents = 'documents',
  packetBuilder = 'packetBuilder',
}

type Props = {
  providerId: string
}

export function Credentialing({ providerId }: Props) {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [credentialingDialogType, setCredentialingDialogType] = useState<CredentialingDialogTypes | null>(null)
  const { data, loading } = useInsuranceProviderQuery({
    variables: {
      id: providerId,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (!data || loading) {
    return <TavaDataTableSkeleton />
  }

  const handleClose = () => {
    setCredentialingDialogType(null)
    setOpenDialog(false)
  }

  const handleAction = (dialogType: CredentialingDialogTypes) => {
    setCredentialingDialogType(dialogType)
    setOpenDialog(true)
  }

  const provider = data.provider as Provider

  return (
    <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
      {openDialog && credentialingDialogType && (
        <CredentialingDialog
          provider={provider}
          open={openDialog}
          type={credentialingDialogType}
          handleClose={handleClose}
        />
      )}
      <Grid container style={{ flex: '1 1 0' }} direction="column">
        <Checklist provider={provider} handleAction={handleAction} />
      </Grid>
    </Grid>
  )
}
