import { DialogActions, DialogContentText, DialogTitle } from '@mui/material'

import { PatientEndDateReason, useRemoveInvalidContractAssociationMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Dialog, DialogContent, FillButton, GhostButton, toast } from '@nuna/tunic'

interface Props {
  patientId: string
  onClose: () => void
}

export function RemoveInvalidAssociationDialog({ patientId, onClose }: Props) {
  const [removeInvalidAssociation, { loading }] = useRemoveInvalidContractAssociationMutation()

  const handleRemoveInvalidAssociation = async () => {
    try {
      const response = await removeInvalidAssociation({
        variables: { patientID: patientId, reason: PatientEndDateReason.NotAPartOfCompany },
        refetchQueries: ['PatientCompanyHistory'],
        awaitRefetchQueries: true,
      })

      if (response.errors) {
        toast.urgent(errorService.transformUserGraphqlError(response.errors[0], 'Unable to remove invalid association'))
      } else {
        toast.success('Successfully removed invalid association')
        onClose()
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Unable to remove invalid association'))
    }
  }

  return (
    <Dialog isOpen={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Remove Invalid Company Association</DialogTitle>
      <DialogContent paddingSize="md">
        <DialogContentText>
          <b className="text-error" style={{ fontSize: '1.2rem' }}>
            This will cancel most future sessions if a patient does not have any valid payment methods on record!!! To
            avoid this have the patient set up a new payment method prior to disassociating them.
          </b>
          <ul className="text-secondary" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <li>Session's in the next 24 hour window will not be cancelled and will be converted to cash pay</li>
            <li>Reverting this change will not restore appointments!</li>
            <li>This will not attempt to switch over any dependents contracts.</li>
            <li>Use this if a client was never associated with the company they specified when they signed up</li>
            <li>Assigns a cash pay contract to the client</li>
            <li>
              Any of their existing future appointments will attempt to collect payment, assuming the client adds a
              card.
            </li>
            <li>Appointments already attended will be marked as cash pay but the client will not be billed.</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={onClose}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={() => handleRemoveInvalidAssociation()} isLoading={loading}>
          Remove
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
