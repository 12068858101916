import { HTMLAttributes } from 'react'

import { PayerRosterDefinitionPresetArgDataType, PayerRosterDefinitionPresetMetaData } from '@nuna/api'
import { Select, TextField, csx } from '@nuna/tunic'

import { TransformationPresetArgs } from '../PayerRosterDefinition.types'

interface PresetArgsFormControlProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  args: TransformationPresetArgs
  preset: PayerRosterDefinitionPresetMetaData
  onChange: (args: TransformationPresetArgs) => void
}

export function PresetArgsFormControl({ args, preset, onChange, className, ...props }: PresetArgsFormControlProps) {
  const handleChange = (key: string, value: unknown) => {
    if (value === '') {
      const clonedArgs = { ...args }
      delete clonedArgs[key]
      onChange(clonedArgs)
    } else {
      onChange({ ...args, [key]: value })
    }
  }

  const getValue = (key: string): string => {
    return (args[key] as string) ?? ''
  }

  return (
    <div className={csx([className, 'flex-column gap-1'])} {...props}>
      {preset.args.map(d => (
        <div key={d.key}>
          {(() => {
            const getLabel = () => {
              return `${d.label}${d.required ? '' : ' (optional)'}`
            }

            if (d.valueOptions?.length > 0) {
              return (
                <Select
                  label={getLabel()}
                  value={getValue(d.key)}
                  onChange={e => handleChange(d.key, e.currentTarget.value)}
                >
                  <option value=""></option>
                  {d.valueOptions.map(option => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )
            }

            switch (d.dataType) {
              case PayerRosterDefinitionPresetArgDataType.String:
                return (
                  <TextField
                    label={getLabel()}
                    value={args[d.key] as string}
                    onChange={e => handleChange(d.key, e.currentTarget.value)}
                  />
                )

              default:
                return (
                  <TextField
                    label={getLabel()}
                    value={args[d.key] as string}
                    onChange={e => handleChange(d.key, e.currentTarget.value)}
                  />
                )
            }
          })()}
        </div>
      ))}
    </div>
  )
}
