import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PayerRosterDefinitionConfig,
  PayerRosterDefinitionConfigSheet,
  usePayerRosterDefinitionQuery,
  useSavePayerRosterDefinitionMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { toast, yellowSet } from '@nuna/tunic'

import { PayerRosterDefinitionFormValues } from './PayerRosterDefinition.types'
import { buildEmptySheet, cleanPayerRosterDefinitionConfig } from './PayerRosterDefinition.util'
import { NameAndPayerForm } from './components/NameAndPayerForm'
import { SheetsEditor } from './components/SheetsEditor'

export function PayerRosterDefinition() {
  const { payerRosterDefinitionId = '' } = useParams()
  const navigate = useNavigate()
  const { data } = usePayerRosterDefinitionQuery({
    variables: { filters: { id: payerRosterDefinitionId } },
    skip: !payerRosterDefinitionId,
  })
  const [saveDefinition, { loading: saveLoading }] = useSavePayerRosterDefinitionMutation()

  const initialValues: PayerRosterDefinitionFormValues = useMemo(
    () => ({
      id: data?.payerRosterDefinition.id,
      name: data?.payerRosterDefinition.name ?? '',
      insurancePayerId: data?.payerRosterDefinition.insurancePayer.id ?? '',
      config: buildInitialConfig(data?.payerRosterDefinition.config),
    }),
    [data],
  )

  if (payerRosterDefinitionId && !data) {
    return null
  }

  const handleSubmit = async (values: PayerRosterDefinitionFormValues) => {
    try {
      const response = await saveDefinition({ variables: { payerRosterDefinitionInput: cleanValues(values) } })
      if (!initialValues.id && response.data?.savePayerRosterDefinition.id) {
        navigate(`../${response.data.savePayerRosterDefinition.id}`)
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to save roster definition'))
    }
  }

  return (
    <>
      {initialValues.id && <EditingBanner />}

      <div className="p-2 flex-column flex-remaining-space">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {formProps => {
            return (
              <Form className="flex-column flex-remaining-space">
                <NameAndPayerForm
                  isNew={isNil(initialValues.id)}
                  {...formProps}
                  insurancePayer={data?.payerRosterDefinition.insurancePayer}
                  saveLoading={saveLoading}
                />
                {initialValues.id && <SheetsEditor {...formProps} saveLoading={saveLoading} />}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

function cleanValues(values: PayerRosterDefinitionFormValues) {
  return {
    ...values,
    config: {
      ...values.config,
      sheets: values.config.sheets.map(sheet =>
        removeExtraneousSheetColumnKeys(
          sheet,
          values.config.columns.map(c => c.key),
        ),
      ),
    },
  }
}

function removeExtraneousSheetColumnKeys(
  sheet: PayerRosterDefinitionConfigSheet,
  columnKeys: string[],
): PayerRosterDefinitionConfigSheet {
  return {
    ...sheet,
    limit: sheet.limit ? sheet.limit : null,
    columnKeys: sheet.columnKeys.filter(key => columnKeys.includes(key)),
  }
}

function buildInitialConfig(config?: PayerRosterDefinitionConfig | null): PayerRosterDefinitionConfig {
  const { sheets, changeset = [], columns = [], version = '0.1' } = config ?? {}
  return {
    sheets: buildInitialSheets(sheets),
    changeset,
    columns: cleanPayerRosterDefinitionConfig(columns),
    version,
  }
}

function buildInitialSheets(sheets?: PayerRosterDefinitionConfigSheet[] | null): PayerRosterDefinitionConfigSheet[] {
  if (sheets) {
    return cleanPayerRosterDefinitionConfig(sheets)
  }
  return [buildEmptySheet(0)]
}

const EditingBanner = styled('div')`
  background-color: ${yellowSet[30].transparency(0.8)};
  height: var(--spacing-half);
`
