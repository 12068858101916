import { HTMLAttributes, useState } from 'react'

import { useAdminNotesCountQuery } from '@nuna/api'
import { Badge, IconButton, IconCommentOutline } from '@nuna/tunic'

import { AdminNotesDrawer } from './AdminNotesDrawer'

interface Props extends HTMLAttributes<HTMLElement> {
  userId?: string
}

export function AdminNotesBadge({ userId, ...props }: Props) {
  const [adminNotesIsOpen, setAdminNotesIsOpen] = useState(false)

  const { data, loading, refetch } = useAdminNotesCountQuery({
    variables: {
      searchInput: {
        userId,
      },
    },
    skip: !userId,
  })

  const handleNotesChanged = () => {
    refetch()
  }

  if (!userId) return null

  return (
    <>
      <IconButton
        style={{ position: 'relative' }}
        tooltip="Administrative Notes"
        onClick={() => setAdminNotesIsOpen(!adminNotesIsOpen)}
        {...props}
      >
        <IconCommentOutline />

        {!loading && (data?.adminNotesCount ?? 0) > 0 && (
          <Badge style={{ position: 'absolute', top: '4px', right: '4px' }}>{data?.adminNotesCount}</Badge>
        )}
      </IconButton>
      <AdminNotesDrawer
        isOpen={adminNotesIsOpen}
        onClose={() => setAdminNotesIsOpen(false)}
        userId={userId}
        onChange={handleNotesChanged}
      />
    </>
  )
}
