export function getPaymentReceiptDowndloadLink({
  appointmentId,
  patientId,
}: {
  appointmentId?: string
  patientId?: string
}) {
  if (!appointmentId) return ''
  if (!patientId) return ''

  const searchParams = new URLSearchParams()

  searchParams.append('appointmentId', appointmentId)
  searchParams.append('patientId', patientId)

  return `${import.meta.env['VITE_API_ENDPOINT']}/resource/payment-receipt?${searchParams.toString()}`
}
