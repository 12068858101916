import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  FullInsurancePayerFragment,
  InsurancePayerStatus,
  documentUtils,
  useRemoveInsurancePayerMutation,
  useSetPayerActiveMutation,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { errorService } from '@nuna/core'
import {
  Chip,
  ChipGroup,
  Confirm,
  FillButton,
  GhostButtonExternalLink,
  IconButtonExternalLink,
  IconExport,
  IconInfo,
  IconPencil,
  IconTrash,
  OutlineButton,
  StatusLabel,
  toast,
} from '@nuna/tunic'

import { DetailDatumLabel, DetailDatumValue } from '../../../layouts/detail-layout/DetailLayout'
import { DropdownMenu } from '../../../shared/DropdownMenu'
import { PayerSubRoutes, payerSubroute } from '../../../util/routes'

const { documentUrl, DocumentPath } = documentUtils

interface Props {
  payer: FullInsurancePayerFragment
  onEdit: () => void
}

export function PayerDetailColumn({ payer, onEdit }: Props) {
  const { status } = payer
  const navigate = useNavigate()

  const [activateConfirmIsOpen, setActivateConfirmIsOpen] = useState(false)
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false)

  const [setPayerActive, { loading }] = useSetPayerActiveMutation()
  const [deletePayer, { loading: deletePayerLoading }] = useRemoveInsurancePayerMutation()

  const logoUrl = payer.logoDocumentId ? `${documentUrl(DocumentPath.PayerLogo, payer.id, payer.logoDocumentId)}` : ''

  const handleActivateToggle = async () => {
    const isActive = !(status === InsurancePayerStatus.Active)

    setActivateConfirmIsOpen(false)

    try {
      await setPayerActive({
        variables: {
          payerId: payer.id,
          activeOn: isActive ? new Date() : null,
          status: isActive ? InsurancePayerStatus.Active : InsurancePayerStatus.Inactive,
        },
      })
      toast.success(`Payer ${isActive ? 'activated' : 'deactivated'}`)
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, `Failed to ${isActive ? 'activate' : 'deactivate'} payer`))
    }
  }

  const handleDeletePayer = async () => {
    setDeleteConfirmIsOpen(false)
    try {
      await deletePayer({ variables: { id: payer.id } })
      toast.success('Payer successfully removed')
      navigate('./..')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error deleting payer'))
    }
  }

  return (
    <>
      <div className="v-align mb-3">
        <OutlineButton
          isLoading={loading}
          variant={status === InsurancePayerStatus.Active ? 'destroy' : 'primary'}
          onClick={() =>
            status === InsurancePayerStatus.Active ? setActivateConfirmIsOpen(true) : handleActivateToggle()
          }
        >
          {status === InsurancePayerStatus.Active ? 'Deactivate' : 'Activate'}
        </OutlineButton>
        <DropdownMenu
          className="ml-auto"
          dropdownButtonProps={{ tooltip: 'Actions', isLoading: deletePayerLoading }}
          menuItems={[
            {
              button: true,
              onClick: onEdit,
              leadingIcon: IconPencil,
              children: 'Edit',
            },
            {
              button: true,
              leadingIcon: IconTrash,
              children: 'Delete',
              onClick: () => setDeleteConfirmIsOpen(true),
            },
          ]}
        />
      </div>
      <Confirm
        confirmButton={
          <FillButton onClick={handleActivateToggle} isLoading={false}>
            {status === InsurancePayerStatus.Active ? 'Deactivate' : 'Activate'}
          </FillButton>
        }
        isOpen={activateConfirmIsOpen}
        onConfirm={response => (response ? handleActivateToggle() : setActivateConfirmIsOpen(false))}
      >
        Are you sure you want to deactivate this payer?
      </Confirm>
      <Confirm
        isOpen={deleteConfirmIsOpen}
        onConfirm={response => (response ? handleDeletePayer() : setDeleteConfirmIsOpen(false))}
      >
        Are you sure you want to delete this payer?
      </Confirm>
      {logoUrl && (
        <img key={payer.logoDocumentId} className="mb-2" style={{ width: '100%' }} src={logoUrl} alt="payer logo" />
      )}
      {!payer.supportsRealtimeVerification && (
        <StatusLabel className="mb-2" intent="caution">
          No real-time eligility checks
        </StatusLabel>
      )}

      <DetailDatumLabel>Name</DetailDatumLabel>
      <DetailDatumValue className="v-align">
        {payer.websiteUrl ? (
          <GhostButtonExternalLink href={payer.websiteUrl}>
            {payer.name} <IconExport className="ml-1" />
          </GhostButtonExternalLink>
        ) : (
          payer.name
        )}
        {payer.helpUrl && (
          <IconButtonExternalLink className="ml-2" href={payer.helpUrl} tooltip="Help Site">
            <IconInfo />
          </IconButtonExternalLink>
        )}
      </DetailDatumValue>
      {payer.tpaForInsurancePayer?.name && (
        <>
          <DetailDatumLabel>TPA For</DetailDatumLabel>
          <DetailDatumValue>
            <Link to={payerSubroute(payer.tpaForInsurancePayer?.id ?? '', PayerSubRoutes.Plans)}>
              {payer.tpaForInsurancePayer.name}
            </Link>
          </DetailDatumValue>
        </>
      )}
      <DetailDatumLabel>External payer id</DetailDatumLabel>
      <DetailDatumValue>{payer.externalPayerId}</DetailDatumValue>
      <DetailDatumLabel>External eligility id</DetailDatumLabel>
      <DetailDatumValue>{payer.externalEligibilityId}</DetailDatumValue>
      <DetailDatumLabel>Supports real-time eligibility checks</DetailDatumLabel>
      <DetailDatumValue>{payer.supportsRealtimeVerification ? 'Yes' : 'No'}</DetailDatumValue>
      <DetailDatumLabel>Use provider for verification</DetailDatumLabel>
      <DetailDatumValue>{payer.useProviderForVerification ? 'Yes' : 'No'}</DetailDatumValue>
      <DetailDatumLabel>Allow out-of-state coverage</DetailDatumLabel>
      <DetailDatumValue>{payer.outOfStateCoverage ? 'Yes' : 'No'}</DetailDatumValue>
      <DetailDatumLabel>Available in the following states:</DetailDatumLabel>
      <ChipGroup>
        {payer.insurancePayerStates.map(state => (
          <Chip small key={state.state}>
            {state.state}
          </Chip>
        ))}
        {payer.insurancePayerStates.length === 0 && (
          <div className="text-medium">
            No states for payer plans. However, this payer can be used for network plans.
          </div>
        )}
      </ChipGroup>
    </>
  )
}
