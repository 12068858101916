import { KnockFeedProvider, KnockProvider } from '@knocklabs/react'
// Required CSS import for Knock notifications, unless you're overriding the styling
import '@knocklabs/react/dist/index.css'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { IconContext } from 'react-icons/lib/cjs'
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom'

import { Role } from '@nuna/api'
import { AppointmentDrawer } from '@nuna/appointment-drawer'
import {
  LoginData,
  LoginScreen,
  PrivateRoute,
  ResetPasswordScreen,
  StytchProvider,
  useAuthDataContext,
} from '@nuna/auth'
import { ClientInviteDrawer } from '@nuna/client-invite'
import { TimezoneContextProvider } from '@nuna/common'
import { CommonRoutes, EnvironmentContextProvider } from '@nuna/environment'
import { FeatureFlagProvider } from '@nuna/feature-flag'
import { SessionDraftConflictContextProvider } from '@nuna/session-notes'
import { Toaster, globalStyles, harmonyAshokaStyles, tavaMuiTheme } from '@nuna/tunic'

import { NavContextProvider } from '../../context-providers/NavContextProvider'
import { environment } from '../environments/environment'
import { AdminUsersIndex } from '../scenes/admin-users/AdminUsersIndex'
import { NewAdminUser } from '../scenes/admin-users/NewAdminUser'
import { AppointmentDetail } from '../scenes/appointment/AppointmentDetail'
import { AppointmentsIndex } from '../scenes/appointment/AppointmentsIndex'
import { AppointmentViews } from '../scenes/appointment/AppointmentsViews'
import { SessionNotes } from '../scenes/appointment/SessionNotes'
import { Availability } from '../scenes/availability/Availability'
import { AdminClientDetail } from '../scenes/client/AdminClientDetail'
import { ClientList } from '../scenes/client/ClientList'
import { CptCodesList } from '../scenes/cpt-codes/CptCodesList'
import { AdminCustomerDetail } from '../scenes/customer/AdminCustomerDetail'
import { CustomerList } from '../scenes/customer/CustomerList'
import { NewCustomer } from '../scenes/customer/NewCustomer'
import { DiagnosesList } from '../scenes/diagnoses/DiagnosesList/DiagnosesList'
import { EnrollmentIndex } from '../scenes/enrollment/EnrollmentIndex'
import { EventLog } from '../scenes/event-log/EventLog'
import { HrAdminUsersIndex } from '../scenes/hr-admin-users/HrAdminUsersIndex'
import { NewHrAdminUser } from '../scenes/hr-admin-users/NewHrAdminUser'
import { InsuranceRostersIndex } from '../scenes/insurance-rosters/InsuranceRostersIndex'
import { MemberMatch } from '../scenes/member-match/MemberMatch'
import { EAPs } from '../scenes/partner/EAPs'
import { NewPartner } from '../scenes/partner/NewPartner'
import { PartnerDetail } from '../scenes/partner/PartnerDetail'
import { PartnerList } from '../scenes/partner/PartnerList'
import { NewPayerPlan } from '../scenes/payer-plan/NewPayerPlan'
import { PayerPlanDetail } from '../scenes/payer-plan/PayerPlanDetail'
import { NewPayer } from '../scenes/payer/NewPayer'
import { PayerDetail } from '../scenes/payer/PayerDetail/PayerDetail'
import { PayersIndex } from '../scenes/payer/PayersIndex'
import { PracticeAddressIndex } from '../scenes/practice-address/PracticeAddressIndex'
import { ProviderCompensationIndex } from '../scenes/provider-compensation/ProviderCompensationIndex'
import { ProviderDefaultCompensationIndex } from '../scenes/provider-default-compensation/ProviderDefaultCompensationIndex'
import { ProviderPayroll } from '../scenes/provider-payroll/ProviderPayroll'
import { ProvidersModule } from '../scenes/provider/ProvidersModule'
import { ProviderTavaAddressDrawer } from '../scenes/tava-address/ProviderTavaAddressDrawer/ProviderTavaAddressDrawer'
import { TavaAddressIndex } from '../scenes/tava-address/TavaAnddressIndex/TavaAddressIndex'
import { TaxEntityList } from '../scenes/tax-entities/TaxEntityList'
import { UnauthorizedPage } from '../shared/UnauthorizedPage'
import {
  PayerSubRoutes,
  addresses,
  adminUsers,
  appointmentsViews,
  availability,
  clientList,
  cptCodeList,
  customerIntegrationDashboard,
  customerList,
  diagnosesList,
  enrollments,
  eventLog,
  hrAdminUsers,
  insuranceRosters,
  memberMatch,
  newAdminUser,
  newCustomer,
  newHrAdminUser,
  newPartner,
  newPayer,
  partnerAdmin,
  partnerList,
  payers,
  providerCompensation,
  providerDefaultCompensation,
  providerList,
  providerPayroll,
  sessionDetail,
  tavaAddresses,
  taxEntityList,
} from '../util/routes'
import { CommandBar } from './CommandBar'
import { Telemetry } from './Telemetry'

const commonRoutes: Partial<CommonRoutes> = {
  sessionNote: (id: string) => `/session-notes/${id}`,
  adminSessionDetail: sessionDetail,
}

const AppointmentRedirect = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>()
  return <Navigate to={`${appointmentsViews}/${appointmentId}`} replace />
}

export function App() {
  const { login } = useAuthDataContext()
  return (
    <BrowserRouter>
      {globalStyles}
      {harmonyAshokaStyles}
      <Toaster />

      <EnvironmentContextProvider environmentValues={{ ...environment, commonRoutes: commonRoutes, app: 'ashoka' }}>
        <FeatureFlagProvider initializeWithPii fallbackPageFactory={() => <UnauthorizedPage />}>
          <TimezoneContextProvider>
            <NavContextProvider>
              <ThemeProvider theme={tavaMuiTheme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <StytchProvider>
                    <KnockProvider apiKey={environment.KNOCK_PUBLIC_API_KEY} userId={login?.id ?? ''}>
                      <KnockFeedProvider feedId={environment.KNOCK_FEED_CHANNEL_ID}>
                        <SessionDraftConflictContextProvider>
                          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                            <Telemetry />
                            <CommandBar />

                            <Routes>
                              <Route
                                path="/login"
                                element={<LoginScreen audience="admin" getRedirectPath={getRedirectAfterLogin} />}
                              />
                              <Route path="/reset-password" element={<ResetPasswordScreen audience="provider" />} />

                              {/* Private routes */}
                              <Route path="/" element={<PrivateRoute />}>
                                <Route index element={<Navigate to={homePath(login?.role)} />} />
                                <Route path={clientList} element={<ClientList />} />
                                <Route path="/clients/:id/*" element={<AdminClientDetail />} />

                                <Route path="/providers/*" element={<ProvidersModule />} />

                                <Route path={`sessions/*`} element={<Navigate to={appointmentsViews} replace />} />
                                <Route path={`sessions/:appointmentId`} element={<AppointmentRedirect />} />
                                <Route path={`${appointmentsViews}/*`} element={<AppointmentsIndex />}>
                                  <Route index element={<Navigate to="views" replace />} />
                                  <Route path="views/*" element={<AppointmentViews />} />
                                  <Route path=":appointmentId" element={<AppointmentDetail />} />
                                </Route>
                                <Route path={`/session-notes/:id`} element={<SessionNotes />} />

                                <Route path={customerList} element={<CustomerList />} />

                                <Route path={`${customerIntegrationDashboard}/*`} element={<AdminCustomerDetail />} />
                                <Route path={`${customerList}/:id/new-hr-admin`} element={<NewHrAdminUser />} />
                                <Route path={newCustomer} element={<NewCustomer />} />
                                <Route path={`${customerList}/:id/*`} element={<AdminCustomerDetail />} />
                                <Route path={`/EAPs/customers/:id/*`} element={<AdminCustomerDetail />} />

                                <Route path={partnerList} element={<PartnerList />} />
                                <Route path={`${partnerList}/:id/*`} element={<PartnerDetail />} />
                                <Route path={newPartner} element={<NewPartner />} />

                                <Route path={`${providerCompensation}/*`} element={<ProviderCompensationIndex />} />
                                <Route
                                  path={`${providerDefaultCompensation}/*`}
                                  element={<ProviderDefaultCompensationIndex />}
                                />
                                <Route path={providerPayroll} element={<ProviderPayroll />} />
                                <Route path={cptCodeList} element={<CptCodesList />} />
                                <Route path={payers} element={<PayersIndex />} />
                                <Route path={newPayer} element={<NewPayer />} />
                                <Route path={`${payers}/:id/${PayerSubRoutes.Plans}/new`} element={<NewPayerPlan />} />
                                <Route
                                  path={`${payers}/:id/${PayerSubRoutes.Plans}/:planId/*`}
                                  element={<PayerPlanDetail />}
                                />

                                <Route path={`${payers}/:id/*`} element={<PayerDetail />}></Route>

                                <Route path={`${enrollments}/*`} element={<EnrollmentIndex />} />

                                <Route path={`${tavaAddresses}/*`} element={<TavaAddressIndex />} />
                                <Route path={`${addresses}/*`} element={<PracticeAddressIndex />} />
                                <Route path={`${insuranceRosters}/*`} element={<InsuranceRostersIndex />} />

                                <Route path={availability} element={<Availability />} />

                                <Route path={`${memberMatch}/*`} element={<MemberMatch />}>
                                  <Route path=":customerId" element={<MemberMatch />} />
                                </Route>
                                <Route path={eventLog} element={<EventLog />} />

                                <Route path={adminUsers} element={<AdminUsersIndex />} />
                                <Route path={newAdminUser} element={<NewAdminUser />} />

                                <Route path={hrAdminUsers} element={<HrAdminUsersIndex />} />
                                <Route path={newHrAdminUser()} element={<NewHrAdminUser />} />

                                <Route path={diagnosesList} element={<DiagnosesList />} />
                                <Route path={taxEntityList} element={<TaxEntityList />} />
                                <Route path={partnerAdmin} element={<EAPs />} />
                              </Route>
                            </Routes>
                          </IconContext.Provider>
                        </SessionDraftConflictContextProvider>
                      </KnockFeedProvider>
                    </KnockProvider>
                  </StytchProvider>

                  <AppointmentDrawer audience="admin" />
                  <ClientInviteDrawer audience="admin" />
                  <ProviderTavaAddressDrawer />
                </LocalizationProvider>
              </ThemeProvider>
            </NavContextProvider>
          </TimezoneContextProvider>
        </FeatureFlagProvider>
      </EnvironmentContextProvider>
    </BrowserRouter>
  )
}

function homePath(role?: Role | '') {
  switch (role) {
    case Role.Admin:
      return providerList
    case Role.Partner:
      return partnerAdmin
    default:
      return customerIntegrationDashboard
  }
}

function getRedirectAfterLogin(loginData: LoginData, fromPath?: string | null) {
  const { role } = loginData

  if (fromPath && role !== Role.Admin) {
    return fromPath
  }

  if (role === Role.Admin && fromPath !== customerIntegrationDashboard) {
    return fromPath
  }

  const home = homePath(loginData.role)

  return home
}
