import { styled } from '@mui/material'
import { isNumber } from 'lodash'
import { HTMLAttributes, useMemo } from 'react'

import {
  DocumentType,
  InsuranceTaxEntityFragment,
  useInsuranceTaxEntitiesQuery,
  useNpiCheckQuery,
  useProviderEnrollmentDataQuery,
} from '@nuna/api'
import { useDownloadDocument } from '@nuna/common'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { DocumentCenter } from '@nuna/document-center'
import { greySet } from '@nuna/tunic'

import { UnstructuredDataPrint } from '../../../shared/UnstructuredDataPrint'
import { ProviderW9Documents } from './ProviderDocuments/components/ProviderW9Documents'

interface Props {
  providerId: string
  loginId?: string | null
}

export function ProviderEnrollmentData({ providerId, loginId }: Props) {
  const { data } = useProviderEnrollmentDataQuery({ variables: { providerId } })
  const npiNumber = data?.providerEnrollmentData?.npi && parseInt(data.providerEnrollmentData.npi)

  const { data: npiRegistryData } = useNpiCheckQuery({
    variables: { npi: npiNumber as number },
    skip: !npiNumber || !isNumber(npiNumber),
  })

  const providerData = data?.providerEnrollmentData

  if (!providerData) {
    return null
  }

  return (
    <Container className="pb-4">
      {npiRegistryData?.npiCheck && (
        <dl id="npi-names">
          <dt>NPI First Name</dt>
          <dd>{npiRegistryData.npiCheck.first_name}</dd>
          <dt>NPI Last Name</dt>
          <dd>{npiRegistryData.npiCheck.last_name}</dd>
        </dl>
      )}
      <UnstructuredDataPrint data={providerData} id="provider-data-dump" />
      <DocumentCenter
        id="provider-documents"
        loginId={loginId}
        documentTypes={[
          DocumentType.Attestation,
          DocumentType.CaqhForm,
          DocumentType.CredentialingPacket,
          DocumentType.LiabilityInsurance,
          DocumentType.PayerApprovalLetter,
          DocumentType.PayerEnrollmentForm,
          DocumentType.Resume,
          DocumentType.StateLicense,
          DocumentType.PracticeAddressW9,
        ]}
      />
      <TavaTaxEINDocuments className="py-3" id="tax-documents" />
      <ProviderW9Documents includeIds providerId={providerId} id="w9-documents" />
    </Container>
  )
}

const Container = styled('div')`
  dl {
    display: grid;
    grid-template-columns: 240px auto;

    dt {
      padding: var(--spacing-half);
      &:nth-of-type(odd) {
        background-color: ${greySet[5].hex};
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
    dd {
      display: grid;
      padding: var(--spacing-half);
      margin-left: 0;
      font-weight: 500;

      &:nth-of-type(odd) {
        background-color: ${greySet[5].hex};
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
  }
`

function TavaTaxEINDocuments(props: HTMLAttributes<HTMLDivElement>) {
  const { data, loading } = useInsuranceTaxEntitiesQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [downloadDocument, containerRef] = useDownloadDocument()

  const columns = useMemo<DataTableColumn<InsuranceTaxEntityFragment>[]>(
    () => [
      {
        Header: 'Organization Name',
        accessor: 'organizationName',
      },
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'NPI',
        accessor: 'npi',
      },
      {
        Header: 'EIN',
        accessor: 'ein',
      },
      {
        Header: 'Default',
        accessor: 'isDefault',
        Cell: ({ value }) => <>{value.toString()}</>,
      },
      {
        Header: 'EIN Document',
        accessor: 'einDocument',
        Cell: ({ value }) => (
          <a
            download
            href={value?.downloadUrl ?? ''}
            onClick={e => {
              e.preventDefault()
              downloadDocument(value?.id ?? '')
            }}
          >
            {value?.fileName}
          </a>
        ),
      },
    ],
    [downloadDocument],
  )

  const rowData = useMemo(() => data?.insuranceTaxEntities || [], [data])
  return (
    <div {...props} ref={containerRef}>
      <h4>Tax Entities</h4>
      <DataTable columns={columns} rowData={rowData} loading={loading} />
    </div>
  )
}
