import { sortBy } from 'lodash'
import { useMemo } from 'react'

import { TavaLoginFragment, useListAdminsQuery } from '@nuna/api'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { OutlineButtonLink, PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { AdminSettingsIcon } from '../../shared/AdminSettings/AdminSettingsIcon'
import { newAdminUser } from '../../util/routes'

export function AdminUsersIndex() {
  const { data, loading } = useListAdminsQuery({ fetchPolicy: 'cache-and-network' })

  const rowData = useMemo(() => (data?.listAdmins ? sortBy(data?.listAdmins, 'lastName') : []), [data])
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'id',
          Cell: ({ row }) => (
            <>
              {row.original.lastName} {row.original.firstName}
            </>
          ),
        },
        { Header: 'Email', accessor: 'email' },
        {
          Header: 'Settings',
          Cell: ({ row }) => <AdminSettingsIcon user={row.original} />,
        },
      ] as DataTableColumn<TavaLoginFragment>[],
    [],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <PageHeading className="m-0">Tava Admins</PageHeading>
          <OutlineButtonLink to={newAdminUser}>New Tava Admin</OutlineButtonLink>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <DataTable columns={columns} rowData={rowData} loading={loading} />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
