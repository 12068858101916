import { includes, upperCase } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  HubspotStage,
  LifecycleChecklistItem,
  LifecycleChecklistItemStatus,
  ProviderStatus,
  useProviderLifecycleJourneyQuery,
} from '@nuna/api'
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  GhostButton,
  Grid,
  IconChevron,
  IconHelpCircle,
  IconInfo,
  IconLink,
  Intent,
  List,
  ListItem,
  ListItemProps,
  StatusLabel,
  Tooltip,
  UserChip,
  greySet,
  salmonSet,
  tealSet,
  toast,
} from '@nuna/tunic'

import { ProviderCAQHStatus } from './components/ProviderCAQHStatus'

const hubspotStageText: { [key: string]: string } = {
  APPLICATION_SUBMITTED: 'Application Submitted',
  APPROVED_INTO_NETWORK: 'Approved',
  BLACK_HOLE: 'Declined',
  CLINICAL_REVIEW: 'Clinical Review',
  CLOSED_LOST: 'Declined',
  CREDENTIALING_COMMITTEE_REVIEW: 'Committee Review',
  INSURANCE_STARTED: 'Insurance Started',
  PRESENT_SITE: 'Present Site',
  PROFILE_IN_PROCESS: 'Profile in Process',
  QUALIFY: 'Qualify',
  REP_REVIEW: 'Rep Review',
  REVIEW_DOCUMENTS: 'Review Documents',
  REVISIONS_NEEDED: 'Revisions Needed',
  SEND_LINK_TO_SIGN_UP: 'Invited to Signup',
  UNKNOWN: 'Unknown',
}

const iconSize = 22
const greyHex = greySet[70].hex
const tealHex = tealSet[70].hex
const salmonHex = salmonSet[80].hex

interface ChecklistItemProps extends ListItemProps {
  item: LifecycleChecklistItem
}
function ChecklistItem({ item: { name, status, description }, ...props }: ChecklistItemProps) {
  const getIntent = (): Intent => {
    switch (status) {
      case LifecycleChecklistItemStatus.Pending:
        return 'default'
      case LifecycleChecklistItemStatus.Incomplete:
      case LifecycleChecklistItemStatus.Warn:
      case LifecycleChecklistItemStatus.UnableToVerify:
        return 'caution'
      case LifecycleChecklistItemStatus.Problem:
        return 'urgent'
      case LifecycleChecklistItemStatus.Complete:
        return 'information'
      default:
        return 'default'
    }
  }
  return (
    <ListItem {...props} className="v-align" style={{ maxWidth: 300 }}>
      {name}
      {description && (
        <Tooltip content={description}>
          <span className="v-align ml-1 text-secondary">
            <IconInfo />
          </span>
        </Tooltip>
      )}
      <span className="ml-auto">
        <StatusLabel intent={getIntent()}>{upperCase(status)}</StatusLabel>
      </span>
    </ListItem>
  )
}

const HubspotStageDisplay = (
  stage: HubspotStage,
  stageDate: string,
  startDate: string,
  handleCopyClick: () => void,
  handleConnectClick: () => void,
) => {
  const titleColor =
    (stage === HubspotStage.ApprovedIntoNetwork && tealHex) ||
    (includes([HubspotStage.BlackHole, HubspotStage.ClosedLost], stage) && salmonHex) ||
    undefined

  return (
    <>
      <ListItem>
        <span
          style={{
            fontSize: 20,
            color: titleColor,
          }}
        >
          {hubspotStageText[stage]}
        </span>
      </ListItem>
      <ListItem>
        {includes(
          [
            HubspotStage.Qualify,
            HubspotStage.PresentSite,
            HubspotStage.SendLinkToSignUp,
            HubspotStage.ProfileInProcess,
            HubspotStage.ApplicationSubmitted,
          ],
          stage,
        ) && (
          <GhostButton variant="secondary" onClick={() => handleCopyClick()}>
            <IconLink color={greyHex} size={iconSize} />
            <span className="ml-1">Copy Signup Link</span>
          </GhostButton>
        )}

        {includes(
          [
            HubspotStage.RepReview,
            HubspotStage.RevisionsNeeded,
            HubspotStage.ClinicalReview,
            HubspotStage.CredentialingCommitteeReview,
          ],
          stage,
        ) && <span>Wants to start {moment(startDate).format('ll')}</span>}

        {includes([HubspotStage.ApprovedIntoNetwork, HubspotStage.ClosedLost, HubspotStage.BlackHole], stage) && (
          <span>Notified on {moment(stageDate).format('ll')}</span>
        )}

        {includes([HubspotStage.Unknown], stage) && (
          <GhostButton variant="secondary" onClick={() => handleConnectClick()}>
            <span>Connect to Hubspot</span>
          </GhostButton>
        )}
      </ListItem>
    </>
  )
}

interface Props {
  providerId: string
  status: ProviderStatus
}

export function ProviderActivityLifecycle({ providerId, status }: Props) {
  const navigate = useNavigate()
  const [oneOpen, setOneOpen] = useState(() => status === ProviderStatus.Onboarding)

  const { error, data } = useProviderLifecycleJourneyQuery({
    variables: {
      providerId,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error || !data) {
    return null
  }

  const { providerLifecycleJourney } = data
  const { tavaGuide } = providerLifecycleJourney

  const handleCardClick = () => {
    setOneOpen(!oneOpen)
  }

  const handleCopyClick = () => {
    try {
      navigator.clipboard.writeText(providerLifecycleJourney.hubspotContactUrl as string)
      toast.success('Copied url to clipboard')
    } catch (e) {
      toast.urgent('Browser does not support copy to clipboard')
    }
  }

  const handleConnectClick = () => {
    navigate(`/providers/${providerId}/settings`)
  }

  return (
    <Card depth={1} shadowColor="cool" style={{ padding: 16 }}>
      <CardHeader
        divider={false}
        className="space-between v-align"
        onClick={handleCardClick}
        style={{ cursor: 'pointer' }}
      >
        <div className="v-align">
          <IconChevron direction={oneOpen ? 'up' : 'right'} />
          <h2 className="ml-2 sans-serif h6" style={{ marginBottom: 0 }}>
            Becoming a Tava Provider
          </h2>
        </div>
        {!oneOpen &&
          [ProviderStatus.AcceptingClients, ProviderStatus.NotAcceptingClients, ProviderStatus.Offboarding].includes(
            status,
          ) && <StatusLabel>Approved</StatusLabel>}
      </CardHeader>
      <Collapse isOpen={oneOpen}>
        <CardBody>
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
                lg: 5,
              }}
            >
              <span className="h5 sans-serif text-medium">Onboarding Checklist</span>
              <List className="mt-2">
                {providerLifecycleJourney.checklist.map(item => (
                  <ChecklistItem item={item} key={item.name} />
                ))}

                <ProviderCAQHStatus providerId={providerId} />
              </List>
            </Grid>

            <Grid
              size={{
                xs: 12,
                lg: 4,
              }}
            >
              <span className="h5 sans-serif text-medium">Hubspot Stage</span>
              <List className="mt-2">
                {HubspotStageDisplay(
                  providerLifecycleJourney.hubspotStage as HubspotStage,
                  providerLifecycleJourney.hubspotStageDate,
                  providerLifecycleJourney.startDate,
                  handleCopyClick,
                  handleConnectClick,
                )}
              </List>
            </Grid>

            <Grid
              size={{
                xs: 12,
                lg: 3,
              }}
            >
              <span className="h5 sans-serif text-medium">Tava Guide</span>
              <List className="mt-2">
                {tavaGuide.firstName && tavaGuide.lastName && (
                  <ListItem>
                    <UserChip avatarUrl={tavaGuide.avatarUrl}>
                      {tavaGuide.firstName}
                      {` `}
                      {tavaGuide.lastName}
                    </UserChip>
                  </ListItem>
                )}

                {!tavaGuide.firstName && !tavaGuide.lastName && (
                  <>
                    <ListItem>
                      <div className="v-align">
                        <IconHelpCircle color={greyHex} size={iconSize} />
                        <span className="ml-1">No Guide Connected</span>
                      </div>
                    </ListItem>
                    <ListItem>
                      <GhostButton variant="secondary" onClick={handleConnectClick}>
                        <span>Connect</span>
                      </GhostButton>
                    </ListItem>
                  </>
                )}
              </List>
            </Grid>
          </Grid>
        </CardBody>
      </Collapse>
    </Card>
  )
}
