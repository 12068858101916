import { DialogActions, DialogContentText, DialogTitle } from '@mui/material'

import { useManageSessionPaymentMutation, useWaiveSessionPaymentMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Dialog, DialogContent, FillButton, GhostButton, toast } from '@nuna/tunic'

interface Props {
  waiveSessionPaymentId?: string
  isRefund: boolean
  isPrePayment: boolean
  onClose: () => void
}

export function WaivePaymentDialog({ waiveSessionPaymentId, isRefund, isPrePayment, onClose }: Props) {
  const [waiveSessionPayment, { loading }] = useWaiveSessionPaymentMutation({
    refetchQueries: ['PatientTransactionHistory'],
  })
  const [manageSessionPayment] = useManageSessionPaymentMutation({
    refetchQueries: ['PatientTransactionHistory'],
  })

  const handleWaive = async () => {
    try {
      if (!waiveSessionPaymentId) throw new Error('No waiveSessionPaymentId exists!  Cannot waive payment.')

      let result

      if (isPrePayment) {
        result = await manageSessionPayment({
          variables: {
            id: waiveSessionPaymentId,
            shouldProviderBePaid: false,
          },
        })
      } else {
        result = await waiveSessionPayment({
          variables: {
            sessionPaymentId: waiveSessionPaymentId,
          },
        })
      }

      if (!result.data) throw new Error('No result data')

      toast.success(`Payment successfully ${isRefund ? 'refunded' : 'waived'}`)
      onClose()
    } catch (e) {
      console.error(e)
      toast.urgent(
        errorService.transformUserGraphqlError(
          e,
          'The payment may have been waived, but the refund or payout removal failed.',
        ),
      )
    }
  }

  return (
    <Dialog isOpen={!!waiveSessionPaymentId} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{isRefund ? 'Refund payment?' : 'Waive payment?'}</DialogTitle>
      <DialogContent paddingSize="md">
        <DialogContentText>
          {isRefund
            ? 'This will refund the payment, waive the scheduled fee and attempt to remove the therapist payout.'
            : 'This will waive the scheduled fee.  No refund will be issued and payouts should be unaffected.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={onClose}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={handleWaive} isLoading={loading}>
          Ok
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
