import { EditSessionNotes } from '@nuna/session-notes'

import { NavLayout } from '../../layouts/NavLayout'

export function SessionNotes() {
  return (
    <NavLayout>
      <EditSessionNotes />
    </NavLayout>
  )
}
