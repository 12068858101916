import { styled } from '@mui/material'
import moment from 'moment'
import { HTMLAttributes, useEffect, useState } from 'react'

import { SearchMemberMatchesQuery } from '@nuna/api'
import { DataTable, DataTableColumn, PaginatorProps } from '@nuna/data-table'

import { useMemberMatchContext } from '../MemberMatchContextProvider'
import { UnlinkedPatientRecord, normalizeMemberMatchData } from '../normalize-member-match-data'
import { UnlinkedPatientRow } from './UnlinkedPatientRow'

interface Props extends HTMLAttributes<HTMLDivElement> {
  memberMatchQuery?: SearchMemberMatchesQuery | undefined
  loading: boolean
  paginatorProps: PaginatorProps
}

const columns: DataTableColumn<UnlinkedPatientRecord>[] = [
  {
    Header: 'Joined',
  },
  {
    Header: 'Name',
  },
  {
    Header: 'Birthday',
  },
  {
    Header: 'Company',
  },
  {
    Header: 'Association',
  },
  {
    Header: 'Address',
  },
  {
    Header: 'Phone Number',
  },
  {
    Header: 'Gender',
  },
  {
    Header: 'Emergency Contact',
  },
  {
    Header: 'Contact Relationship',
  },
  {
    Header: 'Contact Phone',
  },
]

export function MemberMatchTable({ memberMatchQuery, loading, paginatorProps, ...props }: Props) {
  const [unlinkedPatients, setUnlinkedPatients] = useState<UnlinkedPatientRecord[] | null>(null)
  const { setInitialMatchState } = useMemberMatchContext()

  useEffect(() => {
    if (memberMatchQuery) {
      setUnlinkedPatients(normalizeMemberMatchData(memberMatchQuery?.searchMemberMatches.items))
    }
  }, [memberMatchQuery])

  useEffect(() => {
    if (unlinkedPatients && unlinkedPatients.length > 0) {
      setInitialMatchState(unlinkedPatients)
    }
  }, [unlinkedPatients, setInitialMatchState])

  return (
    <Container {...props}>
      <DataTable
        paginated
        paginatorProps={paginatorProps}
        style={{ width: '100%' }}
        headerRowProps={{ style: { whiteSpace: 'nowrap' } }}
        columns={columns}
        rowData={unlinkedPatients}
        loading={loading}
        noRecordsMessage="No unlinked clients to show. Hooray!"
        customRow={(rowProps, idx, allRows) => {
          const previous = allRows[idx - 1]
          const showDate = previous
            ? !compareDates(previous.original.patient.joined, rowProps.row.original.patient.joined)
            : true
          return (
            <UnlinkedPatientRow
              patientInfo={rowProps.row.original}
              showDate={showDate}
              key={rowProps.row.original.patient.id}
            />
          )
        }}
      />
    </Container>
  )
}

const Container = styled('section')`
  width: 100%;
  margin-bottom: var(--margin-6);
`

function compareDates(date1: string, date2: string) {
  const format = 'YYYY-mm-DD'
  return moment(date1).format(format) === moment(date2).format(format)
}
