import { useProviderCompensationTableContext } from './ProviderCompensationTableContextProvider'

export function LastSavedPanel() {
  const { lastSaved } = useProviderCompensationTableContext()

  if (!lastSaved) {
    return <div></div>
  }

  return <div className="italic text-secondary">Last saved: {lastSaved.format('h:mm:s')}</div>
}
