import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { tealSet } from '../../styles/colorSets'

type BadgeVariant = 'primary'

export interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
  variant?: BadgeVariant
}

export const Badge = styled('span')<{ variant?: BadgeVariant }>`
  box-sizing: content-box;
  color: #fff;
  display: inline-flex;
  height: 10px;
  min-width: 10px;
  padding: 4px;
  // fixed border radius allows badge to grow like a pill. At 9px, it appears as a circle given the height, width
  // and padding and if the width of the child node is narrow enough to fit in the circle
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;

  ${({ variant = 'primary' }) => {
    if (variant === 'primary') {
      return backgroundColor(tealSet.primary.hex)
    }
  }}
`

function backgroundColor(color: string) {
  return `background-color: ${color};`
}
