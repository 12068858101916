import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { EnrollmentForm } from './EnrollmentForm/EnrollmentForm'

interface Props {
  insurancePayerId: string
}

export function CreateEnrollmentForPlan({ insurancePayerId }: Props) {
  const navigate = useNavigate()

  const navigateBack = () => {
    navigate('../')
  }
  return (
    <Container>
      <h1 className="h4">Create Provider Enrollment</h1>
      <EnrollmentForm
        insurancePayerId={insurancePayerId}
        onCancel={navigateBack}
        afterSave={navigateBack}
        refetchQueries={['ProviderEnrollmentsByPlan']}
      />
    </Container>
  )
}

const Container = styled('div')`
  max-width: 440px;
`
