import { ApolloError } from '@apollo/client'
import { styled } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  AssociatePatientWithEligibleRecordMutationVariables,
  MemberMatchFilterInput,
  OrderBy,
  OrganizationRelationship,
  RelationToSponsoringEmployee,
  SelectCustomerFragment,
  TraversablePaginationSortInput,
  useAssociatePatientWithEligibleRecordMutation,
  useSearchMemberMatchesLazyQuery,
} from '@nuna/api'
import { CustomerSelect } from '@nuna/customer'
import { usePagination } from '@nuna/data-table'
import { FillButton, IconCustomer, PageContent, PageHeader, PageHeading, PageWrapper, toast } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { MemberMatchProvider, useMemberMatchContext } from './MemberMatchContextProvider'
import { MemberMatchTable } from './table/MemberMatchTable'

const QUERY_PARAM = 'customerId'

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]

function MemberMatchWithProvider() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const customerId = useMemo(() => searchParams.get(QUERY_PARAM), [searchParams])

  const filters = useMemo<MemberMatchFilterInput>(
    () => ({ companyId: customerId, showInactive: false, shouldSuggestChanges: true }),
    [customerId],
  )

  const [queryMemberMatches, { data: memberMatchQuery, loading: memberMatchDataLoading }] =
    useSearchMemberMatchesLazyQuery({ fetchPolicy: 'network-only' })

  const { queryOptions, getPaginatorProps, setPage } = usePagination({
    pagination: memberMatchQuery?.searchMemberMatches.pagination,
    loading: memberMatchDataLoading,
    initialSort: INITIAL_SORT,
    filters,
  })

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryMemberMatches({ variables: { filters, order: sortInput, pagination: paginationInput } })
  }, [queryOptions, queryMemberMatches])

  const { numberOfMatches, patientEmployeeMatchMap } = useMemberMatchContext()
  const [associatePatientWithEligibleRecordMutation, { loading: associateLoadingStatus }] =
    useAssociatePatientWithEligibleRecordMutation()

  const handleAssociateRecords = async () => {
    const matches = Array.from(patientEmployeeMatchMap).filter(
      ([, employeeMatch]) => !!employeeMatch.employee && !!employeeMatch.relationshipToOrganization,
    )

    const promises = matches.map(([patient, { employee, matchType, relationshipToOrganization }]) => {
      const variables: AssociatePatientWithEligibleRecordMutationVariables = {
        companyId: employee?.companyId ?? '',
        patientID: patient.id,
      }
      if (matchType === 'sponsoring employee' && relationshipToOrganization !== OrganizationRelationship.Employee) {
        variables.relationToSponsoringEmployee = orgRelationshipToEmpRelationship(relationshipToOrganization)
        variables.sponsoringEligibleRecordID = employee?.id
      } else {
        variables.eligibleRecordID = employee?.id
      }

      return associatePatientWithEligibleRecordMutation({
        variables,
      }).catch(error => {
        let errorMessage = `Error matching ${patient.firstName} ${patient.lastName}`

        if (error instanceof ApolloError) {
          errorMessage += error.graphQLErrors[0] ? `: ${error.graphQLErrors[0].message}` : ''
        }

        return new Error(errorMessage)
      })
    })

    const responses = await Promise.all(promises)

    const successes = responses.filter(response => !(response instanceof Error))
    const failures = responses.filter(response => response instanceof Error) as Error[]

    if (successes.length > 0) {
      toast.success(`${successes.length} clients associated to employee records.`)
    }

    if (failures.length > 0) {
      failures.forEach(error => console.error(error))
      toast.urgent(`Failed to match ${failures.length} clients to employee records.`)
    }

    setPage(1)
  }

  const reloadWithSearchParams = (params: URLSearchParams) => {
    navigate({ pathname: location.pathname, search: `?${params}` })
  }

  const handleCompanyFilterChange = (customer: SelectCustomerFragment | null) => {
    const queryParams = new URLSearchParams()
    if (customer) {
      queryParams.set(QUERY_PARAM, customer.id)
    }
    reloadWithSearchParams(queryParams)
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <div className="v-align">
            <PageHeading className="mb-0" withDivider>
              Member Match
            </PageHeading>
            <CompanySelecWrapper className="ml-2 v-align">
              <IconCustomer />
              <CustomerSelect
                className="ml-1 company-selector"
                value={customerId}
                onChange={handleCompanyFilterChange}
              />
            </CompanySelecWrapper>
          </div>
          <FillButton
            className="ml-3"
            disabled={numberOfMatches === 0}
            isLoading={associateLoadingStatus}
            onClick={handleAssociateRecords}
          >
            {(() => {
              if (numberOfMatches > 0) {
                return `Associate ${numberOfMatches} Record${numberOfMatches > 1 ? 's' : ''}`
              } else {
                return 'No Records to Match'
              }
            })()}
          </FillButton>
        </PageHeader>
        <StyledPageContent $paddingTop={false}>
          <MemberMatchTable
            memberMatchQuery={memberMatchQuery}
            loading={memberMatchDataLoading}
            paginatorProps={getPaginatorProps()}
          />
        </StyledPageContent>
      </PageWrapper>
    </NavLayout>
  )
}

export function MemberMatch() {
  return (
    <MemberMatchProvider>
      <MemberMatchWithProvider />
    </MemberMatchProvider>
  )
}

function orgRelationshipToEmpRelationship(
  orgRel: OrganizationRelationship | null | undefined,
): RelationToSponsoringEmployee | null | undefined {
  if (orgRel === OrganizationRelationship.Employee || !orgRel) {
    return null
  }
  return orgRel === OrganizationRelationship.Child
    ? RelationToSponsoringEmployee.Child
    : RelationToSponsoringEmployee.SpousePartner
}

const CompanySelecWrapper = styled('div')`
  width: 460px;
  .company-selector {
    flex: 1;
    margin-bottom: var(--margin-2);
  }
`

const StyledPageContent = styled(PageContent)`
  padding-left: 0;
  padding-right: 0;
  margin: 0 var(--margin-2);
`
