export const payerRosterDefinitionSchema = {
  uri: '/roster-definition-schema.json',
  fileMatch: ['*'],
  schema: {
    type: 'object',
    required: ['sheets', 'columns'],
    properties: {
      sheets: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            key: { type: 'string' },
            name: { type: 'string' },
            columnKeys: {
              type: 'array',
              items: { type: 'string' },
            },
            filters: {
              type: 'array',
              items: { type: 'object' },
            },
            groupByPrimaryLocation: { type: 'boolean' },
            limit: { type: 'integer' },
          },
          required: ['key', 'name', 'columnKeys', 'filters'],
        },
      },
      columns: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            key: { type: 'string' },
            alias: { type: 'string' },
            dataMapping: {
              type: 'array',
              items: { type: 'string' },
            },
            transformation: {
              type: 'object',
              properties: {
                valueMapping: {
                  type: 'object',
                  additionalProperties: { type: 'string' },
                },
                preset: {
                  type: 'object',
                  properties: {
                    eval: { type: 'string' },
                    args: { type: 'object' },
                  },
                },
              },
            },
            selector: { type: 'string' },
            value: { type: 'string' },
            valueWhenEmpty: { type: 'string' },
          },
          required: ['key', 'alias'],
        },
      },
      changeset: {
        type: 'array',
        items: { type: 'string' },
      },
      version: { type: 'string' },
    },
  },
}
