import { styled } from '@mui/material'
import { DialogContent } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { useMemo, useState } from 'react'

import { useProviderEnrollmentDataQuery, useSimpleProviderQuery } from '@nuna/api'
import { Dialog, DialogProps, SearchField, greySet } from '@nuna/tunic'

import { UnstructuredDataPrint } from '../../../../shared/UnstructuredDataPrint'

interface ProviderEnrollmentDataDialogProps extends DialogProps {
  providerId?: string | null
}

export function ProviderEnrollmentDataDialog({ providerId, ...props }: ProviderEnrollmentDataDialogProps) {
  const [keySearch, setKeySearch] = useState('')
  const { data: providerData } = useSimpleProviderQuery({ variables: { id: providerId ?? '' }, skip: !providerId })
  const { data } = useProviderEnrollmentDataQuery({ variables: { providerId: providerId ?? '' }, skip: !providerId })
  const providerEnrollmentData = data?.providerEnrollmentData

  const filteredKeys = useMemo<string[]>(() => {
    if (!keySearch) {
      return Object.keys(providerEnrollmentData ?? {})
    }

    const searcher = new FuzzySearch(Object.keys(providerEnrollmentData ?? {}), undefined, {
      caseSensitive: false,
    })

    return searcher.search(keySearch)
  }, [keySearch, providerEnrollmentData])

  const filteredData = useMemo(() => {
    if (!providerEnrollmentData) {
      return {}
    }
    const obj: Record<string, unknown> = {}
    filteredKeys.forEach(key => {
      obj[key] = providerEnrollmentData[key]
    })
    return obj
  }, [filteredKeys, providerEnrollmentData])

  if (!providerData || !providerEnrollmentData) {
    return null
  }

  const provider = providerData.provider

  return (
    <Dialog {...props} fullWidth>
      <Container>
        <div className="v-align pb-1">
          <h5 className="mb-0">
            {provider.firstName} {provider.lastName}
          </h5>
          <SearchField className="ml-2" value={keySearch} onChange={e => setKeySearch(e.target.value)} />
        </div>
        <div className="flex-remaining-space overflow-auto">
          <UnstructuredDataPrint data={filteredData} />
        </div>
      </Container>
    </Dialog>
  )
}

const Container = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  dl {
    display: grid;
    grid-template-columns: 240px auto;

    dt {
      padding: var(--spacing-half);
      &:nth-of-type(odd) {
        background-color: ${greySet[5].hex};
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
    dd {
      display: grid;
      padding: var(--spacing-half);
      margin-left: 0;
      font-weight: 500;

      &:nth-of-type(odd) {
        background-color: ${greySet[5].hex};
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
  }
`
