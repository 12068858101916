import { useMemo } from 'react'

import { OrderBy, SearchPatientFilterInput, usePatientLazyQuery, useSearchClientsLazyQuery } from '@nuna/api'

import { Filter } from '../filters.types'

export function useClientFilter(clientFilters: Partial<SearchPatientFilterInput>, overrides?: Partial<Filter>) {
  const [searchClients] = useSearchClientsLazyQuery()
  const [getClient] = usePatientLazyQuery()

  return useMemo<Filter>(
    () => ({
      ...{
        key: 'patientId',
        label: 'Client',
        async: true,
        getOptions: async searchValue => {
          const results = await searchClients({
            variables: {
              filters: {
                ...{
                  search: { term: searchValue, threshold: 0.2 },
                },
                ...clientFilters,
              },
              order: [{ key: 'firstName', direction: OrderBy.Asc }],
              pagination: { limit: 200 },
            },
          })
          return (
            results.data?.searchPatients.items.map(client => ({
              value: client.id,
              label: `${client.firstName} ${client.lastName}`,
            })) ?? []
          )
        },
        getSelectedValueOptions: async (value: unknown) => {
          const result = await getClient({ variables: { id: value as string } })
          if (!result.data?.patient) {
            return [{ value, label: 'Unknown Client' }]
          }
          return [{ value, label: `${result.data.patient.firstName} ${result.data.patient.lastName}` }]
        },
      },
      ...overrides,
    }),
    [overrides, getClient, searchClients, clientFilters],
  )
}
