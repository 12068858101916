import { styled } from '@mui/material'

import { UserDocumentsQuery } from '@nuna/api'
import { FileTypeIcon } from '@nuna/tunic'

import { useDownloadDocument } from '../../../../hooks/useDownloadDocument'

type CellProps = {
  document: UserDocumentsQuery['userDocuments'][0]['document']
}

export function DocumentLinkCell({ document }: CellProps) {
  const [downloadDocument] = useDownloadDocument()

  return (
    <StyledDocumentCell key={document.id}>
      <a
        href={document.downloadUrl ?? ''}
        onClick={e => {
          e.preventDefault()
          downloadDocument(document.id)
        }}
        download
      >
        <FileTypeIcon mimeType={document.mimeType} className="mr-1" />
        View Document
      </a>
    </StyledDocumentCell>
  )
}

const StyledDocumentCell = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: var(--spacing-1);
  a {
    display: flex;
    align-items: center;
  }
`
