import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { capitalize, pick } from 'lodash'
import { HTMLAttributes } from 'react'

import { CptCodeFragment, CptCodeServiceType, useSaveCptCodeMutation } from '@nuna/api'
import { formService, numberService } from '@nuna/core'
import { GreySection } from '@nuna/provider'
import { FillButton, OutlineButton, Select, TextField, toast } from '@nuna/tunic'

const { composeHelperText } = formService

interface Props {
  cptCode?: Partial<CptCodeFragment>
  onCancel: () => void
  onSave: () => void
}

const VALIDATION_SCHEMA = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  masterCharge: Yup.number().required('Master Charge is required'),
  description: Yup.string().required('Description is required'),
  serviceType: Yup.string().required('Service Type is required'),
})

const SERVICE_TYPES = Object.values(CptCodeServiceType)

export function CptCodeForm({ cptCode = {}, onCancel, onSave, ...props }: Props & HTMLAttributes<HTMLDivElement>) {
  const [saveCPTCode, { loading }] = useSaveCptCodeMutation({ refetchQueries: ['CPTCodes'] })

  const submit = async (values: Partial<CptCodeFragment>) => {
    try {
      await saveCPTCode({
        variables: {
          cptCode: {
            ...pick(values, ['id', 'code', 'duration', 'description', 'shortDescription', 'serviceType']),
            masterCharge: values.masterCharge ? numberService.dollarsToCents(values.masterCharge) : 0,
          },
        },
      })
      toast.success('CPT Code saved')
      onSave()
    } catch (error) {
      let errorMessage = 'There was a problem saving the CPT Code.'
      if (error instanceof ApolloError && error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message
      }
      toast.urgent(errorMessage)
    }
  }

  const cptCodeInDollars: Partial<CptCodeFragment> = {
    ...cptCode,
    masterCharge: cptCode.masterCharge ? numberService.centsToDollars(cptCode.masterCharge) : 0,
  }

  return (
    <GreySection {...props}>
      <Formik initialValues={cptCodeInDollars} validationSchema={VALIDATION_SCHEMA} onSubmit={submit}>
        {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="v-align">
              <TextField
                name="code"
                label="Code"
                error={touched.code && !!errors.code}
                helperText={composeHelperText('CPT Code', errors.code, touched.code)}
                className="text-field"
                value={values.code ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextField
                name="description"
                label="Description"
                error={touched.duration && !!errors.duration}
                helperText={composeHelperText('Used in billing', errors.description, touched.description)}
                className="ml-2 text-field"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextField
                name="shortDescription"
                label="Short Description"
                error={touched.shortDescription && !!errors.shortDescription}
                helperText={composeHelperText(
                  'Used in therapist screens',
                  errors.shortDescription,
                  touched.shortDescription,
                )}
                className="ml-2 text-field"
                value={values.shortDescription}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextField
                name="duration"
                label="Min Duration"
                error={touched.duration && !!errors.duration}
                helperText={composeHelperText('Duration in minutes', errors.duration, touched.code)}
                className="ml-2 text-field"
                type="number"
                value={values.duration ?? 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="masterCharge"
                label="Master charge"
                error={touched.masterCharge && !!errors.masterCharge}
                helperText={composeHelperText('Master charge in dollars', errors.masterCharge, touched.masterCharge)}
                className="ml-2 text-field"
                type="number"
                value={values.masterCharge ?? 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Select
                name="serviceType"
                label="Service Type"
                error={(touched.serviceType && errors.serviceType) as boolean}
                helperText={composeHelperText('Used to group codes', errors.serviceType, touched.serviceType)}
                className="ml-2 text-field"
                value={values.serviceType ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" disabled />
                {SERVICE_TYPES.map(serviceType => (
                  <option key={serviceType} value={serviceType}>
                    {serviceType.split('_').map(capitalize).join(' ')}
                  </option>
                ))}
              </Select>
              <span className="ml-auto actions">
                <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
                <FillButton isLoading={loading} type="submit" className="ml-1">
                  Save
                </FillButton>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </GreySection>
  )
}

const Form = styled('form')`
  .text-field {
    width: 150px;

    input[type='number'] {
      text-align: right;
    }
  }
  .actions {
    text-align: right;
  }
`
