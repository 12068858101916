import { Navigate, Route, Routes } from 'react-router-dom'

import { FillButtonLink, MenuItem, PageHeader, PageHeading, PageWrapper, ResponsiveHorizontalMenu } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { CurrentAddressEnrollments } from '../address-enrollment/CurrentAddressEnrollments'
import { TavaAddressIndex } from '../tava-address/TavaAnddressIndex/TavaAddressIndex'
import { AddressTroubleshooting } from './components/AddressTroubleshooting/PracticeAddressTroubleshooting'
import { ProviderPracticeAddresses } from './components/ProviderPracticeAddresses'

export function PracticeAddressIndex() {
  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading withDivider className="mb-0 mr-2 v-align">
            Practice Addresses
          </PageHeading>
          <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
            <MenuItem to="address-enrollments">Address Enrollments</MenuItem>
            <MenuItem to="provider">Provider</MenuItem>
            <MenuItem to="tava">Tava</MenuItem>
            <MenuItem to="troubleshooting">Troubleshooting</MenuItem>
          </ResponsiveHorizontalMenu>
          <Routes>
            <Route path="tava" element={<FillButtonLink to="new">New Address</FillButtonLink>} />
          </Routes>
        </PageHeader>
        <div className="flex-remaining-space overflow-hidden flex-column">
          <Routes>
            <Route index element={<Navigate to="address-enrollments" replace />} />
            <Route path="address-enrollments" element={<CurrentAddressEnrollments />} />
            <Route path="provider" element={<ProviderPracticeAddresses />} />
            <Route path="tava/*" element={<TavaAddressIndex />} />
            <Route path="troubleshooting" element={<AddressTroubleshooting />} />
          </Routes>
        </div>
      </PageWrapper>
    </NavLayout>
  )
}
