import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { FeatureFlagCheck } from '@nuna/feature-flag'
import { FillButtonLink, MenuItem, PageHeader, PageHeading, PageWrapper, ResponsiveHorizontalMenu } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { insuranceRosters } from '../../util/routes'
import { MasterRoster } from './MasterRoster/MasterRoster'
import { MasterRosterContextProvider } from './MasterRoster/MasterRosterContextProvider'
import { MasterRosterOptions } from './MasterRoster/MasterRosterOptions'
import { PayerRosters } from './PayerRosters/PayerRosters'
import { PayerRosterDefinition } from './PayerRosters/components/PayerRosterDefinition/PayerRosterDefinition'
import { PayerRosterDefinitionRawEditor } from './PayerRosters/components/PayerRosterDefinition/components/PayerRosterDefinitionRawEditor'
import { PayerRosterDefinitionHeader } from './PayerRosters/components/PayerRosterDefinitionHeader'

export function InsuranceRostersIndex() {
  return (
    <NavLayout>
      <MasterRosterContextProvider>
        <PageWrapper>
          <PageHeader>
            <PageHeading withDivider className="mb-0 mr-2 v-align">
              Insurance Rosters
            </PageHeading>
            <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
              <MenuItem to="master">Master Roster</MenuItem>
              <MenuItem to="rosters">Rosters</MenuItem>
            </ResponsiveHorizontalMenu>
            <div className="ml-auto">
              <Routes>
                <Route path="master" element={<MasterRosterOptions />} />
                <Route path="rosters/definitions" element={<Outlet />}>
                  <Route path="new" element={null} />
                  <Route path=":payerRosterDefinitionId/*" element={<PayerRosterDefinitionHeader />} />
                </Route>
                <Route
                  path="rosters/*"
                  element={
                    <span className="v-align gap-1">
                      <FillButtonLink to={`${insuranceRosters}/rosters/definitions`}>
                        Create Roster Definition
                      </FillButtonLink>
                    </span>
                  }
                />
              </Routes>
            </div>
          </PageHeader>
          <div className="flex-remaining-space overflow-hidden flex-column">
            <Routes>
              <Route index element={<Navigate to="master" replace />} />
              <Route path="master" element={<MasterRoster />} />
              <Route
                path="rosters"
                element={
                  <FeatureFlagCheck
                    fallback={<div className="p-2">You don't have access to this yet</div>}
                    flags={['payerRosters']}
                  >
                    <Outlet />
                  </FeatureFlagCheck>
                }
              >
                <Route index element={<PayerRosters />} />
                <Route path="definitions">
                  <Route index element={<Navigate to="new" replace />} />
                  <Route path="new" element={<PayerRosterDefinition />} />
                  <Route path=":payerRosterDefinitionId">
                    <Route index element={<Navigate to="editor" replace />} />
                    <Route path="editor" element={<PayerRosterDefinition />} />
                    <Route path="raw" element={<PayerRosterDefinitionRawEditor />} />
                  </Route>
                </Route>
                <Route path=":id/*" element={<PayerRosters />} />
              </Route>
            </Routes>
          </div>
        </PageWrapper>
      </MasterRosterContextProvider>
    </NavLayout>
  )
}
