/* eslint-disable react/jsx-no-useless-fragment */
import { styled } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { omit } from 'lodash'
import moment from 'moment-timezone'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import {
  OrderBy,
  Role,
  SearchEventInput,
  SearchEventsQuery,
  TraversablePaginationSortInput,
  UserDisplayData,
  useSearchEventsQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  CollapsePresence,
  IconChevron,
  IconFilter,
  IconToggleButton,
  makeTypographyComponent,
} from '@nuna/tunic'

import { clientDetail, providerDetail } from '../../util/routes'
import { UnstructuredDataPrint } from '../UnstructuredDataPrint'
import { UserEventLogFilters } from './UserEventLogFilters'

type Row = SearchEventsQuery['searchEvents']['items'][0] & { metadata?: object | null }

interface Props {
  fixedFilters?: SearchEventInput
  header?: ReactNode
  allowFiltering?: boolean
}

const ITEM_LIMIT = 100
const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]

const COlUMNS: DataTableColumn<Row>[] = [
  {
    accessor: 'data',
    Header: 'Description',
    Cell: ({ value }) => <>{value.renderText}</>,
  },
  {
    accessor: 'createdAt',
    Header: 'Date/Time',
    Cell: ({ value }) => <>{moment.tz(value, moment.tz.guess()).format('ll h:MM a z')}</>,
  },
  { accessor: 'user', Header: 'User', Cell: ({ value }) => <UserCell user={value} /> },
  { accessor: 'requester', Header: 'Performed By', Cell: ({ value }) => <UserCell user={value} /> },
  {
    accessor: 'metadata',
    Header: 'Metadata',
    Cell: ({ value }) => <MetaDataCell metadata={omit(value, ['__typename'])} />,
  },
]

export function UserEventLog({ fixedFilters = {}, header, allowFiltering }: Props) {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering({} as SearchEventInput)

  const { queryOptions, getPaginatorProps, initialTableState, setPagination, setLoading } = usePagination(
    {
      loading: true,
      initialSort: INITIAL_SORT,
      filters: filterValues,
    },
    { limit: ITEM_LIMIT },
  )

  const { data, loading } = useSearchEventsQuery({
    variables: {
      filters: { ...filterValues, ...fixedFilters },
      order: queryOptions.sortInput,
      pagination: queryOptions.paginationInput,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'userEventLogSearch', debounceTimeout: 200 },
  })

  useEffect(() => setPagination(data?.searchEvents.pagination), [data?.searchEvents.pagination, setPagination])

  useEffect(() => setLoading(loading), [loading, setLoading])

  const rowData: Row[] = useMemo(
    () => (data?.searchEvents?.items ?? []).map(item => ({ ...item, metadata: item.data.renderData })),
    [data],
  )

  const showHeader = !!header || allowFiltering
  return (
    <Container>
      {showHeader && (
        <Header>
          {header}
          <div className="ml-auto v-align">
            <IconToggleButton
              tooltip="Filter"
              size="md"
              isActive={filtersOpen}
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              <IconFilter />
            </IconToggleButton>
          </div>
        </Header>
      )}
      <AnimatePresence>
        {filtersOpen && (
          <CollapsePresence>
            <UserEventLogFilters
              fixedFilters={fixedFilters}
              filterValues={filterValues}
              setFilterVal={setFilterVal}
              clearFilters={clearFilters}
            />
          </CollapsePresence>
        )}
      </AnimatePresence>
      <DataTable
        className="user-log-table"
        cellPadding="6px"
        columns={COlUMNS}
        rowData={rowData}
        loading={loading}
        initialState={initialTableState}
        paginated
        paginatorProps={getPaginatorProps()}
      />
    </Container>
  )
}

function UserCell({ user }: { user?: UserDisplayData | null }) {
  if (!user) {
    return null
  }
  const { firstName, lastName, role, entityId } = user
  const fullName = [firstName, lastName].filter(Boolean).join(' ')

  if ([Role.Admin, Role.Partner, Role.Customer].includes(role)) {
    return <>{fullName}</>
  }

  const link = role === Role.Provider ? providerDetail(entityId) : clientDetail(entityId)
  return (
    <Link openInNewTab to={link}>
      {fullName}
    </Link>
  )
}

function MetaDataCell({ metadata }: { metadata?: object | null }) {
  const [isOpen, setIsOpen] = useState(false)
  if (!metadata || (metadata && Object.keys(metadata).length === 0)) {
    return <>N/A</>
  }

  return (
    <Card>
      <CardHeader
        divider={false}
        className="space-between v-align"
        wrapperClick={() => setIsOpen(!isOpen)}
        padding="2px"
        margin="0"
        style={{ cursor: 'pointer', padding: 4 }}
      >
        <div className="v-align">
          <IconChevron direction={isOpen ? 'up' : 'right'} />
          {isOpen ? 'Hide' : 'Show'}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <MetaDataContainer>
            <UnstructuredDataPrint data={metadata} />
          </MetaDataContainer>
        </CardBody>
      </Collapse>
    </Card>
  )
}

const Container = styled(
  makeTypographyComponent('flex-column overflow-hidden full-width user-log-table-wrapper', 'div'),
)`
  .user-log-table {
    background-color: #fff;
    z-index: 2;
  }
`

const Header = makeTypographyComponent('v-align mb-2', 'div')

const MetaDataContainer = styled('div')`
  dl {
    margin: 0;
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: var(--spacing-1);
    margin-left: 0;
    dl {
      padding-left: var(--spacing-2);
    }
  }
`
