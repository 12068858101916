import { groupBy } from 'lodash'
import moment from 'moment'

import { useFullstorySessionsForUserQuery } from '@nuna/api'
import { Chip, ChipGroup } from '@nuna/tunic'

interface Props {
  loginId?: string
}

export function ProviderFullstorySessions({ loginId = '' }: Props) {
  const { data } = useFullstorySessionsForUserQuery({
    variables: { loginId },
  })

  const groupedSessions = groupBy(data?.fullstorySessionsForUser || [], session => {
    return moment.unix(parseInt(session.createdTime, 10)).format('dddd, MMMM DD')
  })

  return (
    <div>
      <h4 className="mb-1">Fullstory Sessions</h4>
      <p className="mb-4 text-secondary">Showing up to 20 recent sessions</p>
      {Object.keys(groupedSessions).map(date => {
        return (
          <div className="mb-4">
            <h6>{date}</h6>
            <ChipGroup>
              {groupedSessions[date].map(session => (
                <Chip className="mb-1 mr-1" small={true} onClick={() => window.open(session.fsUrl)}>
                  {moment.unix(parseInt(session.createdTime, 10)).format('h:mma')}
                </Chip>
              ))}
            </ChipGroup>
          </div>
        )
      })}
    </div>
  )
}
