import { useState } from 'react'

import {
  AlterSessionActionType,
  WhoIsResponsibleForPayment,
  useInternalAppointmentDetailQuery,
  useManageSessionMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, toast } from '@nuna/tunic'

import { ClientContractSelect } from './ClientContractSelect'

interface SwitchAppointmentToEapFormProps {
  appointmentId: string
  onComplete: () => void
}

export function SwitchAppointmentToEapForm({ appointmentId, onComplete }: SwitchAppointmentToEapFormProps) {
  const [contractId, setContractId] = useState<string | null>(null)
  const { data: appointmentData } = useInternalAppointmentDetailQuery({
    variables: { id: appointmentId, includeFinancials: true },
  })

  const [manageSession, { loading: mutationLoading }] = useManageSessionMutation()

  const handleSwitchToEap = async () => {
    try {
      await manageSession({
        variables: {
          id: appointmentData?.internalAppointment?.session?.id ?? '',
          actionType: AlterSessionActionType.ChangeWhoIsResponsibleForPayment,
          actionData: {
            whoIsResponsibleForPayment: WhoIsResponsibleForPayment.Company,
            exercisedContractId: contractId ?? '',
          },
        },
      })
      toast.success('Session updated')
      onComplete()
    } catch (error) {
      console.error(error)
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to update session'))
    }
  }

  return (
    <div>
      <h1 className="h5 mb-0 pb-2">Switch Appointment to EAP</h1>
      <div className="pt-1">
        <p className="text-secondary caption">
          Select the contract that this session should be counted toward (should be the active contract at the time of
          the appointment).
        </p>
        <ClientContractSelect
          value={contractId}
          onChange={v => setContractId(v)}
          patientId={appointmentData?.internalAppointment?.patient.id ?? ''}
        />
      </div>
      <div className="pt-4">
        <FillButton
          isLoading={mutationLoading}
          disabled={!contractId}
          onClick={handleSwitchToEap}
          className="full-width"
        >
          Switch to EAP
        </FillButton>
      </div>
    </div>
  )
}
