import { TextFieldProps } from '@mui/material'

import { DatePicker, DatePickerProps } from './DatePicker'

export function InlineTextDatePicker(props: DatePickerProps) {
  return <DatePicker {...props} slots={{ textField: InlineDateInput }} />
}

function InlineDateInput({ value, onClick, onFocus, onKeyDown, onBlur }: TextFieldProps) {
  return (
    <span onClick={onClick} onFocus={onFocus} onKeyDown={onKeyDown} onBlur={onBlur} style={{ cursor: 'pointer' }}>
      {value as string}
    </span>
  )
}
