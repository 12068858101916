import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, styled } from '@mui/material'

import { CredentialingChecklistDataPoint } from '@nuna/api'
import { CredentialingChecklistColumns } from '@nuna/core'
import { FillButton } from '@nuna/tunic'

import { ChecklistAttestationDetailsDialogContent } from './ChecklistAttestationDetailsDialogContent'
import { ChecklistCaqhDisclosureDialogContent } from './ChecklistCaqhDisclosureDialog'
import { ChecklistEducationDialogContent } from './ChecklistEducationDialogContent'
import { ChecklistLiabilityInsuranceDialogContent } from './ChecklistLiabilityInsuranceDialogContent'
import { ChecklistLicensingDialogContent } from './ChecklistLicensingDialogContent'
import { ChecklistMedicareOptOutDialogContent } from './ChecklistMedicareOptOutDialogContent'
import { ChecklistNpdbDialogContent } from './ChecklistNpdbDialogContent'
import { ChecklistNpiDialogContent } from './ChecklistNpiDialogContent'
import { ChecklistOigInclusionsDialogContent } from './ChecklistOigInclusionsDialogContent'
import { ChecklistSamExclusionsDialogContent } from './ChecklistSamExclusionsDialogContent'
import { ChecklistStateSanctionsDialogContent } from './ChecklistStateSanctionsDialogContent'
import { ChecklistWorklistDialogContent } from './ChecklistWorklistDialogContent'

export type ChecklistDialogTypes = Extract<
  CredentialingChecklistColumns,
  | 'attestation'
  | 'npi'
  | 'education'
  | 'workHistory'
  | 'stateLicenses'
  | 'providerLiabilityInsurance'
  | 'samExclusions'
  | 'oigExclusions'
  | 'medicareOptOut'
  | 'stateSanctions'
  | 'npdb'
  | 'caqhDisclosures'
>

type Props = {
  providerId?: string
  open: boolean
  type: ChecklistDialogTypes
  handleClose: () => void
  data: CredentialingChecklistDataPoint
}

type DialogContentItem = {
  title: string | React.ReactNode
  content: string | React.ReactNode
}
type DialogContent = Record<string, DialogContentItem>

export function ChecklistDialog({ open, handleClose, type, data }: Props) {
  const dialogContent: DialogContent = {
    education: {
      title: 'Checklist Education Details',
      content: <ChecklistEducationDialogContent data={data} />,
    },
    workHistory: {
      title: 'Work History Details',
      content: <ChecklistWorklistDialogContent data={data} />,
    },
    providerLiabilityInsurance: {
      title: 'Provider Liability Insurance Details',
      content: <ChecklistLiabilityInsuranceDialogContent data={data} />,
    },
    stateLicenses: {
      title: 'State Licenses Details',
      content: <ChecklistLicensingDialogContent data={data} />,
    },
    attestation: {
      title: 'Attestation Details',
      content: <ChecklistAttestationDetailsDialogContent data={data} />,
    },
    npi: {
      title: 'NPI Details',
      content: <ChecklistNpiDialogContent data={data} />,
    },
    samExclusions: {
      title: 'SAM Exclusions Details',
      content: <ChecklistSamExclusionsDialogContent data={data} />,
    },
    oigExclusions: {
      title: 'OIG Exclusions Details',
      content: <ChecklistOigInclusionsDialogContent data={data} />,
    },
    medicareOptOut: {
      title: 'Medicare Opt Out Details',
      content: <ChecklistMedicareOptOutDialogContent data={data} />,
    },
    stateSanctions: {
      title: 'State Sanctions Details',
      content: <ChecklistStateSanctionsDialogContent data={data} />,
    },
    npdb: {
      title: 'NPDB Details',
      content: <ChecklistNpdbDialogContent data={data} />,
    },
    caqhDisclosures: {
      title: 'CAQH Disclosures Details',
      content: <ChecklistCaqhDisclosureDialogContent data={data} />,
    },
  }

  const content: DialogContentItem = dialogContent[type]

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl">
      <Title>{content.title}</Title>
      <Divider />
      <Content id="credential-checklist-alert-dialog-description">{content.content}</Content>
      <Actions>
        <FillButton onClick={handleClose} autoFocus className="ml-auto">
          Close
        </FillButton>
      </Actions>
    </Dialog>
  )
}

const Title = styled(DialogTitle)`
  font-size: 1.5rem;
  font-weight: 400;
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-1);
  margin: 0;
`

const Content = styled(DialogContent)`
  padding: 0;
  height: 600px;
`

const Actions = styled(DialogActions)`
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-2);
`
