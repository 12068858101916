import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  FullInsurancePlanFragment,
  useRemoveInsurancePayerPlanMutation,
  useSetInsurancePlanActiveMutation,
} from '@nuna/api'
import { addressService, errorService } from '@nuna/core'
import { Confirm, FillButton, IconPencil, IconTrash, OutlineButton, toast } from '@nuna/tunic'

import { DetailDatumLabel, DetailDatumValue } from '../../layouts/detail-layout/DetailLayout'
import { DropdownMenu } from '../../shared/DropdownMenu'
import { PayerPlanDetailEnterDisplay } from './PayerPlanDetailEnterDisplay'

interface Props {
  plan: FullInsurancePlanFragment
  onEdit: () => void
}

export function PayerPlanDetailColumn({ plan, onEdit }: Props) {
  const { active } = plan
  const navigate = useNavigate()

  const [activateConfirmIsOpen, setActivateConfirmIsOpen] = useState(false)
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false)

  const [setPlanActive, { loading }] = useSetInsurancePlanActiveMutation()
  const [deletePlan, { loading: deletePlanLoading }] = useRemoveInsurancePayerPlanMutation()

  const handleActivateToggle = async () => {
    const isActive = !active

    setActivateConfirmIsOpen(false)

    try {
      await setPlanActive({ variables: { active: isActive, planId: plan.id, activeOn: isActive ? new Date() : null } })
      toast.success(`Payer ${isActive ? 'activated' : 'deactivated'}`)
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, `Failed to ${isActive ? 'activate' : 'deactivate'} plan`))
    }
  }

  const handleDeletePayer = async () => {
    setDeleteConfirmIsOpen(false)

    try {
      await deletePlan({ variables: { id: plan.id } })
      toast.success('Payer successfully removed')
      navigate('./..')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error deleting plan'))
    }
  }

  return (
    <>
      <div className="v-align mb-3">
        <OutlineButton
          isLoading={loading}
          variant={active ? 'destroy' : 'primary'}
          onClick={() => (active ? setActivateConfirmIsOpen(true) : handleActivateToggle())}
        >
          {active ? 'Deactivate' : 'Activate'}
        </OutlineButton>
        <DropdownMenu
          className="ml-auto"
          dropdownButtonProps={{ tooltip: 'Actions', isLoading: deletePlanLoading }}
          menuItems={[
            {
              button: true,
              onClick: onEdit,
              leadingIcon: IconPencil,
              children: 'Edit',
            },
            {
              button: true,
              leadingIcon: IconTrash,
              children: 'Delete',
              onClick: () => setDeleteConfirmIsOpen(true),
            },
          ]}
        />
      </div>
      <Confirm
        confirmButton={
          <FillButton isLoading={loading} onClick={handleActivateToggle}>
            {active ? 'Deactivate' : 'Activate'}
          </FillButton>
        }
        isOpen={activateConfirmIsOpen}
        onConfirm={response => (response ? handleActivateToggle() : setActivateConfirmIsOpen(false))}
      >
        Are you sure you want to deactivate this plan?
      </Confirm>
      <Confirm
        isOpen={deleteConfirmIsOpen}
        onConfirm={response => (response ? handleDeletePayer() : setDeleteConfirmIsOpen(false))}
      >
        Are you sure you want to delete this plan?
      </Confirm>
      <DetailDatumLabel>Name</DetailDatumLabel>
      <DetailDatumValue>{plan.name}</DetailDatumValue>
      <DetailDatumLabel>Address</DetailDatumLabel>
      <DetailDatumValue>{addressService.formatAddress(plan)}</DetailDatumValue>
      <PayerPlanDetailEnterDisplay enterPayerPlan={{ enterPlanId: plan.enterHealthId || undefined }} />
      {plan.enterPayerPlans.map(enterPayerPlan => (
        <PayerPlanDetailEnterDisplay enterPayerPlan={enterPayerPlan} />
      ))}
      <DetailDatumLabel>Service modifiers</DetailDatumLabel>
      <DetailDatumValue>{plan.serviceModifiers && plan.serviceModifiers.join(', ')}</DetailDatumValue>
      <DetailDatumLabel>Insurance Policy Group Numbers</DetailDatumLabel>
      <DetailDatumValue>
        {plan.insurancePolicyGroups && plan.insurancePolicyGroups.map(g => g.groupId).join(', ')}
      </DetailDatumValue>
      <DetailDatumLabel>Coverage description pattern</DetailDatumLabel>
      <DetailDatumValue>{plan.coverageDescriptionPattern}</DetailDatumValue>
      <DetailDatumLabel>Coverage description exclusion</DetailDatumLabel>
      <DetailDatumValue>{plan.coverageDescriptionExclusion}</DetailDatumValue>
      {plan.allowCouplesAndFamilyTherapy && <DetailDatumLabel>Allows couples and family therapy</DetailDatumLabel>}
    </>
  )
}
