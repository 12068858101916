import { compact, startCase } from 'lodash'
import { useMemo } from 'react'

import { AddressEnrollmentSearchOptions, ProviderEnrollmentStatus, ProviderFilterInput } from '@nuna/api'

import { Filter, Filters } from '../../../../shared/filters'
import { useAssigneeFilter } from '../../../../shared/filters/common-filter-hooks/useAssigneeFilter'
import { useInsurancePayerFilter } from '../../../../shared/filters/common-filter-hooks/useInsurancePayerFilter'
import { useProviderFilter } from '../../../../shared/filters/common-filter-hooks/useProviderFilter'
import { useUrlFilters } from '../../../../shared/filters/hooks/useUrlFilters'

export type AddressEnrollmentFilterValues = Omit<
  AddressEnrollmentSearchOptions,
  'searchText' | 'taskAssigneeLoginId' | 'assignedToMe'
> & {
  assignee?: 'me' | 'none' | string
}

interface AddressEnrollmentFiltersProps<T> {
  urlFilterService: ReturnType<typeof useUrlFilters<T>>
  fixedFilters?: AddressEnrollmentFilterValues
}

const searchProviderFilters: ProviderFilterInput = {}
const providerFilterOverrides: Partial<Filter> = { urlParam: 'ae_providerId' }
const insurancePayerFilterOverrides: Partial<Filter> = { urlParam: 'ae_insurancePayerId' }
const assigneeFilterOverrides: Partial<Filter> = { urlParam: 'ae_assignee' }

export function AddressEnrollmentFilters<T extends Record<string, unknown>>({
  urlFilterService,
  fixedFilters,
}: AddressEnrollmentFiltersProps<T>) {
  const providerFilter = useProviderFilter(searchProviderFilters, providerFilterOverrides)
  const insurancePayerFilter = useInsurancePayerFilter(insurancePayerFilterOverrides)
  const assigneeFilter = useAssigneeFilter(assigneeFilterOverrides)

  const filterOptions: Filter[] = useMemo<Filter[]>(
    () =>
      compact([
        fixedFilters?.providerId ? undefined : providerFilter,
        fixedFilters?.insurancePayerId ? undefined : insurancePayerFilter,
        {
          key: 'status',
          urlParam: 'ae_status',
          label: 'Effective Status',
          async: false,
          getOptions: async () =>
            Object.values(ProviderEnrollmentStatus).map(status => ({ value: status, label: startCase(status) })),
          getSelectedValueOptions: async value => [{ label: startCase(value as string), value }],
        },
        {
          key: 'payerStatus',
          urlParam: 'ae_payerStatus',
          label: 'Payer Status',
          async: false,
          getOptions: async () =>
            Object.values(ProviderEnrollmentStatus).map(status => ({ value: status, label: startCase(status) })),
          getSelectedValueOptions: async value => [{ label: startCase(value as string), value }],
        },
        {
          key: 'followProviderEnrollment',
          urlParam: 'ae_followsProviderEnrollment',
          label: 'Submission Type',
          async: false,
          getOptions: async () => [
            { value: 'true', label: 'Provider Enrollment' },
            { value: 'false', label: 'Change of Address' },
          ],
          getSelectedValueOptions: async value => [
            value ? { label: 'Provider Enrollment', value: 'true' } : { label: 'Change of Address', value: 'false' },
          ],
        },
        assigneeFilter,
      ]),
    [providerFilter, insurancePayerFilter, assigneeFilter, fixedFilters],
  )
  return (
    <div>
      <Filters<T> filters={filterOptions} urlFilterService={urlFilterService} />
    </div>
  )
}
