import { init } from 'commandbar'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useInsurancePayersLazyQuery,
  useInsurancePlansLazyQuery,
  useSearchClientsLazyQuery,
  useSearchCompaniesLazyQuery,
  useSearchProvidersLazyQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

init('685ab433')

export function CommandBar() {
  const authData = useAuthDataContext()
  const navigate = useNavigate()

  const [searchProviders] = useSearchProvidersLazyQuery()
  const [queryClients] = useSearchClientsLazyQuery()
  const [searchCompanies] = useSearchCompaniesLazyQuery()
  const [searchPayers] = useInsurancePayersLazyQuery()
  const [searchPlans] = useInsurancePlansLazyQuery()

  const loginId = authData.login?.id
  useEffect(() => {
    if (loginId) {
      window.CommandBar.boot(loginId, { loginId })
    }
  }, [loginId])

  useEffect(() => {
    function router(url: string) {
      navigate(url)
    }

    window.CommandBar.addRouter(router)
  }, [navigate])

  useEffect(() => {
    const getClients = async (searchTerm: string) => {
      const clients = await queryClients({
        variables: {
          filters: {
            search: {
              term: searchTerm,
              threshold: 0.4,
            },
          },
          pagination: {
            limit: 5,
            page: 1,
          },
        },
      })

      const dataRows = (clients.data?.searchPatients?.items || []).map(client => {
        return {
          id: client.id,
          label: `${client.firstName} ${client.lastName}`,
        }
      })

      return dataRows
    }

    window.CommandBar.addRecords('clients', [], {
      onInputChange: searchTerm => getClients(searchTerm),
    })
  }, [queryClients])

  useEffect(() => {
    const getCompanies = async (searchTerm: string) => {
      const clients = await searchCompanies({
        variables: {
          query: searchTerm,
        },
      })

      const dataRows = (clients.data?.searchCompanies || []).map(company => {
        return {
          id: company.id,
          label: company.name,
        }
      })

      return dataRows
    }

    window.CommandBar.addRecords('companies', [], {
      onInputChange: searchTerm => getCompanies(searchTerm),
    })
  }, [searchCompanies])

  useEffect(() => {
    const getPayers = async (searchTerm: string) => {
      const clients = await searchPayers({
        variables: {
          pagination: { limit: 5 },
          filters: { name: searchTerm },
        },
      })

      const dataRows = (clients.data?.insurancePayers.items || []).map(payer => {
        return {
          id: payer.id,
          label: payer.name,
        }
      })

      return dataRows
    }

    window.CommandBar.addRecords('payers', [], {
      onInputChange: searchTerm => getPayers(searchTerm),
    })
  }, [searchPayers])

  useEffect(() => {
    const getPlans = async (searchTerm: string) => {
      const clients = await searchPlans({
        variables: {
          pagination: { limit: 5 },
          filters: { name: searchTerm },
        },
      })

      const dataRows = (clients.data?.insurancePayerPlans.items || []).map(plan => {
        return {
          id: plan.id,
          label: plan.name,
          payerId: plan.insurancePayer.id,
        }
      })

      return dataRows
    }

    window.CommandBar.addRecords('plans', [], {
      onInputChange: searchTerm => getPlans(searchTerm),
    })
  }, [searchPlans])

  useEffect(() => {
    const getProviders = async (term: string) => {
      const providers = await searchProviders({
        variables: {
          pagination: { limit: 5 },
          filters: { search: { term } },
        },
      })

      const dataRows = (providers.data?.searchProviders.items || []).map(provider => {
        return {
          id: provider.id,
          label: `${provider.firstName} ${provider.lastName}`,
          icon: provider?.avatarUrl || '',
        }
      })

      return dataRows
    }

    window.CommandBar.addRecords('providers', [], {
      onInputChange: searchTerm => getProviders(searchTerm),
    })
  }, [searchProviders])

  return null
}
