import { AddressEnrollmentStatus } from '@nuna/api'

export function useGetAvailableAddressEnrollmentStatuses(currentStatus?: AddressEnrollmentStatus | null) {
  if (!currentStatus) {
    return [AddressEnrollmentStatus.Archived]
  }

  switch (currentStatus) {
    case AddressEnrollmentStatus.Pending:
      return [
        AddressEnrollmentStatus.Submitted,
        AddressEnrollmentStatus.Archived,
        AddressEnrollmentStatus.RequestedProviderSignature,
        AddressEnrollmentStatus.BlockedByPayer,
      ]
    case AddressEnrollmentStatus.RequestedProviderSignature:
      return [
        AddressEnrollmentStatus.Pending,
        AddressEnrollmentStatus.Submitted,
        AddressEnrollmentStatus.BlockedByPayer,
        AddressEnrollmentStatus.Archived,
      ]
    case AddressEnrollmentStatus.BlockedByPayer:
      return [
        AddressEnrollmentStatus.Pending,
        AddressEnrollmentStatus.Submitted,
        AddressEnrollmentStatus.RequestedProviderSignature,
        AddressEnrollmentStatus.Archived,
      ]
    case AddressEnrollmentStatus.Submitted:
      return [
        AddressEnrollmentStatus.Pending,
        AddressEnrollmentStatus.Enrolled,
        AddressEnrollmentStatus.Denied,
        AddressEnrollmentStatus.Archived,
        AddressEnrollmentStatus.RequestedProviderSignature,
        AddressEnrollmentStatus.BlockedByPayer,
      ]
    case AddressEnrollmentStatus.Enrolled:
      return [AddressEnrollmentStatus.Submitted, AddressEnrollmentStatus.Archived]
    case AddressEnrollmentStatus.Denied:
    case AddressEnrollmentStatus.Archived:
      return [
        AddressEnrollmentStatus.Submitted,
        AddressEnrollmentStatus.Pending,
        AddressEnrollmentStatus.RequestedProviderSignature,
        AddressEnrollmentStatus.BlockedByPayer,
      ]
    default:
      return [AddressEnrollmentStatus.Archived]
  }
}
