import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { useMemo, useState } from 'react'

import { BasicSpecialtyFragment, useSpecialtiesQuery } from '@nuna/api'

export type SelectSpecialty = Pick<BasicSpecialtyFragment, 'name' | 'id'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<SelectSpecialty, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  value?: SelectSpecialty[]
  onChange: (specialties: SelectSpecialty[]) => void
  label?: string
}

export function SpecialtiesSelect({ value, onChange, label = 'Specialties', ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const { data, loading } = useSpecialtiesQuery()

  const filteredSpecialties = useMemo<SelectSpecialty[]>(() => {
    const specialties = data?.specialties ?? []
    const searcher = new FuzzySearch(specialties, ['name'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return specialties
    }
    return searcher.search(searchTerm)
  }, [data, searchTerm])

  return (
    <Autocomplete
      {...props}
      loading={loading}
      multiple
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={specialty => specialty.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={filteredSpecialties}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField name="selectdSpecialty" label={label} {...params} />}
    />
  )
}
