import { styled } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import { ProviderSchedule } from '@nuna/provider'

interface Props {
  providerId: string
  timezone?: string
}

export function AdminProviderSchedule({ providerId, timezone = moment.tz.guess() }: Props) {
  const [controlsContainer, setControlsContainer] = useState<HTMLDivElement | null>(null)

  return (
    <Container className="availability-calendar">
      <ControlsContainer ref={setControlsContainer} />

      <CalendarContainer>
        <ProviderSchedule
          providerId={providerId}
          timezone={timezone}
          controlsContainer={controlsContainer}
          audience="admin"
        />
      </CalendarContainer>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const ControlsContainer = styled('div')`
  background-color: #fff;
  border-bottom: 1px solid var(--fc-border-color, #ddd);
  width: 100%;
  padding-bottom: 1rem;
`

const CalendarContainer = styled('div')`
  overflow: auto;
  height: 100%;
`
