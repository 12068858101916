/* eslint-disable react/jsx-no-useless-fragment */
import moment from 'moment'
import { HTMLAttributes, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  InsurancePayerPlanSortItem,
  InsurancePayerPlansSearchOptions,
  InsurancePlanListItemFragment,
  OrderBy,
  TraversablePaginationFragment,
  TraversablePaginationSortInput,
  useInsurancePlansQuery,
} from '@nuna/api'
import { routeService } from '@nuna/core'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { StatusLabel } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  payerId?: string
  groupId?: string
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [
  {
    key: 'name',
    direction: OrderBy.Asc,
  },
]

const INITIAL_FILTER_STATE: InsurancePayerPlansSearchOptions = {
  name: '',
  state: null,
}

export function PayerPlanTable({ payerId, groupId, ...props }: Props) {
  const navigate = useNavigate()
  const { filterValues } = useDataTableFiltering({ ...INITIAL_FILTER_STATE, insurancePayerId: payerId, groupId })

  const { handleSort, queryOptions, getPaginatorProps, initialTableState, setPagination, setLoading } = usePagination({
    loading: true,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const { data, loading } = useInsurancePlansQuery({
    variables: {
      order: queryOptions.sortInput as InsurancePayerPlanSortItem[],
      filters: queryOptions.filters || filterValues,
      pagination: queryOptions.paginationInput,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'insurancePlansSearch', debounceTimeout: 200 },
  })

  useEffect(
    () => setPagination(data?.insurancePayerPlans.pagination as TraversablePaginationFragment),
    [data?.insurancePayerPlans.pagination, setPagination],
  )

  useEffect(() => setLoading(loading), [loading, setLoading])

  const columns = useMemo<DataTableColumn<InsurancePlanListItemFragment>[]>(() => {
    const columns: DataTableColumn<InsurancePlanListItemFragment>[] = [
      { Header: 'Name', accessor: 'name', paginatedSortable: true },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'Status',
        accessor: 'active',
        Cell: ({ value }) => (
          <StatusLabel intent={value ? 'information' : 'urgent'}>{value ? 'Active' : 'Inactive'}</StatusLabel>
        ),
      },
      { Header: 'Date added', accessor: 'createdAt', Cell: ({ value }) => <>{moment(value).format('l')}</> },
    ]

    if (groupId) {
      columns.splice(1, 0, { Header: 'Payer', accessor: 'insurancePayer', Cell: ({ value }) => <>{value.name}</> })
    }
    return columns
  }, [groupId])

  const rowData = useMemo<InsurancePlanListItemFragment[]>(() => {
    return data?.insurancePayerPlans.items ?? []
  }, [data])

  return (
    <DataTable
      style={{ width: '100%' }}
      initialState={initialTableState}
      onSort={handleSort}
      columns={columns}
      rowData={rowData}
      loading={loading}
      paginated
      paginatorProps={getPaginatorProps()}
      onRowClick={row => navigate(routeService.insurancePayerPlan(row.id, row.insurancePayer.id))}
      {...props}
    />
  )
}
