/* eslint-disable react/jsx-no-useless-fragment */
import { capitalize, sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import { MdAdd } from 'react-icons/md'

import { CptCodeFragment, useCptCodesQuery } from '@nuna/api'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { FillButton, PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../../layouts/NavLayout'
import { CptCodeForm } from './CptCodeForm'
import { CptCodeRow } from './CptCodeRow'

export function CptCodesList() {
  const [showNewForm, setShowNewForm] = useState(false)
  const { data, loading } = useCptCodesQuery({ fetchPolicy: 'cache-and-network' })

  const rowData = useMemo<CptCodeFragment[]>(() => {
    return sortBy(data?.cptCodes ?? [], 'code')
  }, [data])

  const columns = useMemo<DataTableColumn<CptCodeFragment>[]>(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
        width: 150,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 180,
      },
      {
        Header: 'Short Description',
        accessor: 'shortDescription',
        width: 180,
      },
      {
        Header: 'Min Duration',
        accessor: 'duration',
        className: 'right-align',
        width: 150,
      },
      {
        Header: 'Master Charge',
        accessor: 'masterCharge',
        className: 'right-align',
        width: 150,
        Cell: ({ value }) => <>{numberService.centsToFormattedDollars(value)}</>,
      },
      {
        Header: 'Service Type',
        accessor: 'serviceType',
        width: 200,
        Cell: ({ value }) => <>{value?.split('_').map(capitalize).join(' ') ?? null}</>,
      },
      {
        Header: <span style={{ paddingRight: '0.5rem' }}>Edit</span>,
        accessor: 'id',
        className: 'right-align',
      },
    ],
    [],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <PageHeading className="mb-0">Procedure Codes (CPT)</PageHeading>
        </PageHeader>
        <PageContent $paddingTop={false} style={{ maxWidth: '1280px' }}>
          <DataTable
            rowData={rowData}
            columns={columns}
            loading={loading}
            customRow={rowProps => {
              const { key, ...spreadRowProps } = rowProps
              return <CptCodeRow key={key} {...spreadRowProps} />
            }}
          />
          {showNewForm && (
            <CptCodeForm className="mt-4" onSave={() => setShowNewForm(false)} onCancel={() => setShowNewForm(false)} />
          )}

          {!showNewForm && (
            <FillButton leadingIcon className="mt-4" onClick={() => setShowNewForm(true)}>
              <MdAdd size={24} /> New
            </FillButton>
          )}
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
