import { PageContent } from '@nuna/tunic'

import { AddressesMissingGeolocationTable } from './components/AddressesMissingGeolocationTable'
import { ProvidersMissingPrimaryLocationTable } from './components/ProvidersMissingPrimaryLocationTable'

export function AddressTroubleshooting() {
  return (
    <PageContent className="overflow-hidden flex-column gap-5">
      <AddressesMissingGeolocationTable />
      <ProvidersMissingPrimaryLocationTable />
    </PageContent>
  )
}
