import { usePatientQuery, useUpdateClientStartIntakePermittedMutation } from '@nuna/api'
import { isMinor } from '@nuna/client'
import { ContextualAlert, Switch, toast } from '@nuna/tunic'

interface Props {
  patientId: string
}

export function MinorIntakeSection({ patientId }: Props) {
  const { error, data } = usePatientQuery({
    variables: {
      id: patientId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [updatePatientProfile] = useUpdateClientStartIntakePermittedMutation()

  if (!data) {
    return null
  }

  const { patient } = data

  const handleChange = async () => {
    try {
      await updatePatientProfile({
        variables: { patientId: patient.id, startIntakePermitted: !patient.startIntakePermitted },
      })
      const message = !patient.startIntakePermitted
        ? 'Client has permission to begin intake'
        : 'Client no longer has permission to begin intake'
      toast.success(message)
    } catch (e) {
      toast.urgent('There was an error updating the intake permission for this client')
    }
  }

  if (!error && !isMinor(patient.dob)) {
    // Don't display this section at all if client is not a minor
    return null
  }

  return (
    <section>
      {(() => {
        if (error) {
          return <ContextualAlert intent="urgent">There was an error loading the client</ContextualAlert>
        }

        return (
          <>
            <h3 className="h5">Allow client to start intake</h3>
            <p className="mb-2 text-secondary">This toggle only applies because this client is a minor</p>
            <Switch checked={patient.startIntakePermitted ?? false} onChange={handleChange}>
              Can start intake
            </Switch>
          </>
        )
      })()}
    </section>
  )
}
