export enum PatientTransactionType {
  AdminAdjustment = 'ADMIN_ADJUSTMENT',
  Invoice = 'INVOICE',
  LateCancelationFee = 'LATE_CANCELATION_FEE',
  NoShowFee = 'NO_SHOW_FEE',
  Session = 'SESSION',
}

export enum PatientTransactionStatus {
  ClaimSubmitted = 'CLAIM_SUBMITTED',
  Failed = 'FAILED',
  Paid = 'PAID',
  PaymentDue = 'PAYMENT_DUE',
  Pending = 'PENDING',
}
