import { IconProps } from './IconProps'

export function IconImage({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7184 19.8343C12.7184 21.9171 14.4158 23.6143 16.4989 23.6143C18.5821 23.6143 20.2795 21.9171 20.2795 19.8343C20.2795 17.7429 18.5821 16.0543 16.4989 16.0543C14.4158 16.0543 12.7184 17.7514 12.7184 19.8343ZM26.1366 27.2687C27.6015 25.6275 29.2301 23.8029 31.8011 23.8029C36.4818 23.8029 38.0334 32.8371 38.0334 32.8371H9.97514C9.97514 32.8371 13.0956 28.32 16.216 28.32C17.7645 28.32 18.5403 28.6956 19.3177 29.072C20.0984 29.4499 20.8806 29.8286 22.4483 29.8286C23.8517 29.8286 24.9394 28.6099 26.1366 27.2687ZM5.66309 9C5.66309 9 4 9 4 10.6629C4 12.3343 4 37.3371 4 37.3371C4 37.3371 4 39 5.66309 39C7.32619 39 42.3369 39 42.3369 39C42.3369 39 44 39 44 37.3371C44 35.6657 44 10.6629 44 10.6629C44 10.6629 44 9 42.3369 9C40.6738 9 5.66309 9 5.66309 9ZM7.33476 35.6657V12.3343H40.6738V35.6657H7.33476Z"
      />
    </svg>
  )
}
