import { styled } from '@mui/material'

import { useProviderQuery } from '@nuna/api'
import { ProviderFeesCard } from '@nuna/fee-policies'
import { ProviderProfile, withProviderLoadingState } from '@nuna/provider'

interface Props {
  providerId: string
}

export function AdminProviderProfile({ providerId }: Props) {
  const { loading, data, refetch } = useProviderQuery({
    variables: {
      id: providerId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const provider = data?.provider

  if (!provider) {
    return <div>Loading...</div>
  }

  return (
    <Container>
      {withProviderLoadingState(ProviderProfile, { provider, refetch, loading })}
      <div className="pl-4">
        <ProviderFeesCard providerId={providerId} style={{ marginTop: '4.5rem' }} />
      </div>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
`
