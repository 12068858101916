import { DateTimeCell } from '@nuna/common'
import { CredentialingChecklistStatus } from '@nuna/core'

export const DEFAULT_COL_DEFS = {
  flex: 1,
  editable: true,
}

type CellRendererParams = {
  value: CredentialingChecklistStatus
}
export const defaultChecklistDialogContentColumns = [
  {
    field: 'title',
    headerName: 'Task',
  },
  {
    field: 'description',
    headerName: 'Details',
  },
  {
    field: 'verifiedAt',
    headerName: 'Verified Date',
    cellRenderer: (params: CellRendererParams) => <DateTimeCell value={params.value} />,
  },
]
