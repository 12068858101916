import { styled } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  CompanyFilterInput,
  OrderBy,
  TraversablePaginationSortInput,
  useSearchCompaniesPaginatedLazyQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { DEFAULT_PAGINATOR_PROPS, Paginator, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { MenuItem, MenuItemStack, PageSearch } from '@nuna/tunic'

import { NavColumn } from '../../shared/NavColumn'

interface Props {
  children: JSX.Element
}

type PartnerFilterValues = Omit<CompanyFilterInput, 'search'> & {
  searchTerm?: string | null
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'name', direction: OrderBy.Asc }]

export function EAPsListNavigation({ children }: Props) {
  const [searchText, setSearchText] = useState('')
  const [searchCompanies, { data: companiesData, loading }] = useSearchCompaniesPaginatedLazyQuery()
  const { login } = useAuthDataContext()
  const { partnerId } = login || {}
  const urlParams = useParams()
  const INITIAL_FILTER_VALUES: PartnerFilterValues = useMemo(
    () => ({
      searchTerm: '',
      partnerId,
    }),
    [partnerId],
  )
  const { filterValues, setFilterVal } = useDataTableFiltering(INITIAL_FILTER_VALUES)
  const buildFilterValues = useCallback(
    (values: PartnerFilterValues = INITIAL_FILTER_VALUES): CompanyFilterInput => {
      const { searchTerm, hrisEnabled, id } = values
      return {
        search: {
          term: searchTerm,
          threshold: 0.2,
        },
        hrisEnabled,
        id,
      }
    },
    [INITIAL_FILTER_VALUES],
  )
  const { queryOptions, getPaginatorProps } = usePagination({
    pagination: companiesData?.searchCompaniesPaginated.pagination,
    loading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const search = useMemo(
    () => debounce((searchText?: string | null) => setFilterVal('searchTerm', searchText), 400),
    [setFilterVal],
  )

  useEffect(() => {
    search(searchText)
  }, [search, searchText])

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    searchCompanies({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
    })
  }, [searchCompanies, queryOptions, buildFilterValues])
  const eaps = companiesData?.searchCompaniesPaginated.items

  const buildUrl = (id: string) => {
    const subRoute = urlParams['*'] ?? ''
    return `/EAPs/customers/${id}/${subRoute}`
  }

  if (!partnerId) return children

  return (
    <Container>
      <StyedNavColumn>
        <PageSearch
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          onClear={() => setSearchText('')}
          placeholder="Search EAPs"
          className="filters-bar"
          autoFocus
        />
        <div className="list my-2 mx-1">
          <MenuItemStack>
            {eaps &&
              eaps.map(({ id, name }) => (
                <MenuItem to={buildUrl(id)} key={id}>
                  <div style={{ width: '100%' }}>{name}</div>
                </MenuItem>
              ))}
          </MenuItemStack>
        </div>
        <Paginator
          {...{ ...DEFAULT_PAGINATOR_PROPS, ...getPaginatorProps() }}
          totalLabel=" total"
          className="mt-1"
          controlProps={{ className: 'ml-auto' }}
        />
      </StyedNavColumn>
      <div className="content-body">{children}</div>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  .content-body {
    flex: 1;
  }
`

const StyedNavColumn = styled(NavColumn)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 280px;
  padding-top: 0;
  overflow: hidden;
  .filters-bar {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 32px var(--spacing-2) var(--spacing-2) var(--spacing-2);
  }
  .list {
    flex: 1;
    overflow: auto;

    .menu-item {
      padding: 0.25rem 0.75rem;
    }
  }
`
