import { styled } from '@mui/material'
import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  OrderBy,
  PartnerAdminQuery,
  SearchPartnerAdminFilterInput,
  TraversablePaginationSortInput,
  useSearchPartnerAdminsLazyQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { OutlineButton, PageSearch } from '@nuna/tunic'

import { newPartnerAdmin, partnerAdminUpdate } from '../../../util/routes'

type AdminFilterValues = Omit<SearchPartnerAdminFilterInput, 'search'> & {
  search?: string | null
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'updated_at', direction: OrderBy.Desc }]

export function PartnerAdminList() {
  const { id = '' } = useParams<{ id: string }>()
  const [searchText, setSearchText] = useState('')
  const INITIAL_FILTER_VALUES: AdminFilterValues = useMemo(
    () => ({
      search: '',
      partnerId: id,
    }),
    [id],
  )
  const buildFilterValues = useCallback(
    (values: AdminFilterValues = INITIAL_FILTER_VALUES) => {
      const { search } = values
      return {
        search: {
          term: search,
          threshold: 0.3,
        },
        partnerId: id,
      } as SearchPartnerAdminFilterInput
    },
    [INITIAL_FILTER_VALUES, id],
  )

  const navigate = useNavigate()
  const { filterValues, setFilterVal } = useDataTableFiltering(INITIAL_FILTER_VALUES)
  const [queryAdmins, { data: adminsData, loading }] = useSearchPartnerAdminsLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination({
    pagination: adminsData?.searchPartnerAdmins.pagination,
    loading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const search = useMemo(
    () => debounce((searchText?: string | null) => setFilterVal('search', searchText), 400),
    [setFilterVal],
  )

  useEffect(() => {
    search(searchText)
  }, [search, searchText])

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryAdmins({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
    })
  }, [buildFilterValues, queryAdmins, queryOptions])

  const rowData = useMemo(() => adminsData?.searchPartnerAdmins.items, [adminsData])

  const columns = useMemo<DataTableColumn<NonNullable<PartnerAdminQuery['partnerAdmin']>>[]>(
    () => [
      {
        accessor: 'login',
        id: 'login',
        Header: 'Name',
        Cell: ({ value, row }) => (
          <Link to={partnerAdminUpdate(id, row.original.id)}>
            {value.firstName} {value.lastName}
          </Link>
        ),
      },
      {
        accessor: 'login',
        Header: 'Email',
        id: 'email',
        Cell: ({ value, row }) => <Link to={partnerAdminUpdate(id, row.original.id)}>{value.email}</Link>,
      },
      {
        accessor: 'login',
        Header: 'Active',
        id: 'active',
        // eslint-disable-next-line react/jsx-no-useless-fragment
        Cell: ({ value }) => <>{value.active ? 'Yes' : 'No'}</>,
      },
      {
        accessor: 'updatedAt',
        Header: 'Last Updated',
        id: 'updatedAt',
        Cell: ({ value }) => <>{moment(value).format('MM/DD/YYYY')}</>,
      },
    ],
    [id],
  )

  return (
    <>
      <Header>
        <PageSearch
          onChange={event => setSearchText(event.currentTarget.value)}
          placeholder="Search all partner admins"
          value={searchText}
          onClear={() => setSearchText('')}
          autoFocus={true}
        />
        <OutlineButton onClick={() => navigate(newPartnerAdmin(id))}>New Partner Admin</OutlineButton>
      </Header>
      <DataTable
        paginated
        paginatorProps={getPaginatorProps()}
        onSort={handleSort}
        columns={columns}
        rowData={rowData}
        initialState={initialTableState}
        loading={loading}
        onRowClick={row => navigate(partnerAdminUpdate(id, row.id))}
      />
    </>
  )
}

export const Header = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  margin-bottom: var(--margin-1);
`
