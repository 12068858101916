import { Formik } from 'formik'
import { omit } from 'lodash'
import { useMemo } from 'react'

import { PayerRosterDefinitionConfigColumn } from '@nuna/api'
import { Dialog, IconButton, IconClose } from '@nuna/tunic'

import { ColumnFormValues } from '../PayerRosterDefinition.types'
import { COLUMN_FORM_VALIDATION_SCHEMA, EditColumnForm, buildInitialColumnFormValues } from './EditColumnForm'

interface EditColumnDialogProps {
  rosterName?: string | null
  payerRosterDefinitionId: string
  column?: PayerRosterDefinitionConfigColumn | null
  saveLoading?: boolean
  onSave: (column: Omit<PayerRosterDefinitionConfigColumn, 'key'> & { key?: string }) => void
  onClose: () => void
}

export function EditColumnDialog({
  rosterName = 'Roster',
  column,
  payerRosterDefinitionId,
  saveLoading,
  onClose,
  onSave,
}: EditColumnDialogProps) {
  const initialValues = useMemo(() => buildInitialColumnFormValues(column), [column])

  const handleSave = (formValues: ColumnFormValues) => {
    onSave(omit(formValues, ['transformationType', 'valueType']))
  }

  return (
    <Dialog isOpen={!!column} onClose={onClose} fullScreen>
      <div className="full-height flex-column">
        <div className="v-align p-1">
          <h1 className="mb-0 h5 ml-1 sans-serif text-medium">{rosterName} Column</h1>
          <IconButton variant="secondary" className="ml-auto" tooltip="Close" onClick={onClose}>
            <IconClose />
          </IconButton>
        </div>
        <Formik initialValues={initialValues} validationSchema={COLUMN_FORM_VALIDATION_SCHEMA} onSubmit={handleSave}>
          {formProps => {
            return (
              <EditColumnForm
                {...formProps}
                payerRosterDefinitionId={payerRosterDefinitionId}
                saveLoading={saveLoading}
                onCancel={onClose}
                className="flex-remaining-space"
              />
            )
          }}
        </Formik>
      </div>
    </Dialog>
  )
}
