import { EnvironmentContextValues, FeatureMode } from '@nuna/environment'

export const dynamicVariables: Omit<EnvironmentContextValues, 'production'> = {
  MIXPANEL_TOKEN: import.meta.env['VITE_MIXPANEL_TOKEN'] || '',
  API_ENDPOINT: import.meta.env['VITE_API_ENDPOINT'] || 'http://localhost:5000',
  STRICT_MODE: import.meta.env['VITE_REACT_STRICT_MODE'] === 'true' || false,
  STRIPE_PUBLISHABLE_KEY: import.meta.env['VITE_STRIPE_PUBLISHABLE_KEY'] || '',
  STYTCH_PUBLIC_TOKEN: import.meta.env['VITE_STYTCH_PUBLIC_TOKEN'] || '',
  STYTCH_B2B_PUBLIC_TOKEN: import.meta.env['VITE_STYTCH_B2B_PUBLIC_TOKEN'] || '',
  CI: import.meta.env['VITE_CI'] === 'true',
  LAUNCH_DARKLY_CLIENT_ID: import.meta.env['VITE_LAUNCH_DARKLY_CLIENT_ID'] || '',
  AG_GRID_LICENSE_KEY: import.meta.env['VITE_AG_GRID_LICENSE_KEY'] || '',
  FEATURE_MODE: (import.meta.env['VITE_FEATURE_MODE'] as FeatureMode) || 'default',
  HARMONY_BASE_URL: import.meta.env['VITE_HARMONY_BASE_URL'] || '',
  ARROW_BASE_URL: import.meta.env['VITE_ARROW_BASE_URL'] || '',
  ASHOKA_BASE_URL: import.meta.env['VITE_ASHOKA_BASE_URL'] || '',
  SANCTUARY_API_ENDPOINT: import.meta.env['VITE_SANCTUARY_API_ENDPOINT'] || '',
  SANCTUARY_API_KEY: import.meta.env['VITE_SANCTUARY_API_KEY'] || '',
  DROPBOX_SIGN_CLIENT_ID: import.meta.env['VITE_DROPBOX_SIGN_CLIENT_ID'] || '',
  DAILY_BASE_URL: import.meta.env['VITE_DAILY_BASE_URL'] || '',
  KNOCK_PUBLIC_API_KEY: import.meta.env['VITE_KNOCK_PUBLIC_API_KEY'] || '',
  KNOCK_FEED_CHANNEL_ID: import.meta.env['VITE_KNOCK_FEED_CHANNEL_ID'] || '',
}
