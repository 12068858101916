import { HTMLAttributes } from 'react'

import { useCancelPolicyStatusQuery } from '@nuna/api'
import { numberService } from '@nuna/core'
import { ContextualAlert, Skeleton } from '@nuna/tunic'

import { RateLabel, RateRow, RateValue, SectionHeading } from './FeesCardTypography'

const { centsToFormattedDollars } = numberService

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string
}

export function CancelPolicyRates({ providerId, ...props }: Props) {
  const { data, loading } = useCancelPolicyStatusQuery({ variables: { providerId } })

  const cancelPolicy = data?.cancelPolicyStatus?.cancelPolicy

  return (
    <div {...props}>
      <SectionHeading>Cancel Policy</SectionHeading>
      {loading && <Skeleton height={3} />}
      {!loading && cancelPolicy && (
        <>
          <RateRow>
            <RateLabel>Cancel Fee</RateLabel>
            <RateValue>{centsToFormattedDollars(cancelPolicy.chargeAmount)}</RateValue>
          </RateRow>
          <RateRow>
            <RateLabel>No-Show Fee</RateLabel>
            <RateValue>{centsToFormattedDollars(cancelPolicy.noShowChargeAmount)}</RateValue>
          </RateRow>
        </>
      )}
      {!loading && !cancelPolicy && <ContextualAlert>No policy in effect</ContextualAlert>}
    </div>
  )
}
