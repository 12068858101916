import { Form, Formik } from 'formik'
import { pick } from 'lodash'

import { Address, useAddressQuery, useSaveAddressMutation } from '@nuna/api'
import { addressService, errorService } from '@nuna/core'
import { Dialog, FillButton, OutlineButton, Skeleton, TextField, TimezoneSelect, toast } from '@nuna/tunic'

type FormValues = Pick<Address, 'id' | 'county' | 'timezone'>

interface EditPracticeAddressDialogProps {
  addressId?: string | null
  onCancel: () => void
  onSaved: (addressId: string) => void
}

export function EditPracticeAddressDialog({ addressId, onCancel, onSaved }: EditPracticeAddressDialogProps) {
  const { data } = useAddressQuery({ variables: { id: addressId ?? '' }, skip: !addressId })
  const [saveAddress, { loading: saveAddressLoading }] = useSaveAddressMutation()
  const initialValues: FormValues | undefined = data?.address
    ? pick(data?.address, ['id', 'county', 'timezone', 'phone'])
    : undefined

  const handleSubmit = async (values: Pick<Address, 'id' | 'county' | 'timezone'>) => {
    try {
      await saveAddress({ variables: { address: values } })
      onSaved(values.id)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to save address'))
    }
  }

  return (
    <Dialog isOpen={!!addressId} onClose={onCancel}>
      {initialValues && <Skeleton />}
      {initialValues && data?.address && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue, getFieldProps }) => (
            <Form className="p-2" style={{ minWidth: 300 }}>
              <div className="text-secondary pb-2">{addressService.formatAddress(data.address)}</div>
              <TimezoneSelect
                label="Timezone"
                className="mb-1"
                value={values.timezone}
                onChange={tz => setFieldValue('timezone', tz)}
              />
              <TextField label="County" {...getFieldProps('county')} className="mb-1" />

              <TextField label="Phone" {...getFieldProps('phone')} />

              <div className="v-align gap-1 mt-4">
                <FillButton type="submit" isLoading={saveAddressLoading}>
                  Save
                </FillButton>
                <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
