import { useMemo } from 'react'

import { InsurancePayerSortKeys, OrderBy, useInsurancePayerLazyQuery, useInsurancePayersLazyQuery } from '@nuna/api'

import { Filter } from '../filters.types'

export function useInsurancePayerFilter(overrides?: Partial<Filter>) {
  const [searchPayers] = useInsurancePayersLazyQuery()
  const [getInsurancePayer] = useInsurancePayerLazyQuery()

  return useMemo<Filter>(
    () => ({
      ...{
        key: 'insurancePayerId',
        label: 'Payer',
        async: true,
        getOptions: async searchValue => {
          const results = await searchPayers({
            variables: {
              filters: {
                search: { term: searchValue, threshold: 0.2 },
              },
              order: [{ key: InsurancePayerSortKeys.Name, direction: OrderBy.Desc }],
              pagination: { limit: 200 },
            },
          })
          return (
            results.data?.insurancePayers.items.map(payer => ({
              value: payer.id,
              label: payer.name,
            })) ?? []
          )
        },
        getSelectedValueOptions: async value => {
          const result = await getInsurancePayer({ variables: { id: value as string } })
          if (!result.data?.insurancePayer) return [{ value, label: 'Unknown Payer' }]
          return [{ value, label: result.data.insurancePayer.name }]
        },
      },
      ...overrides,
    }),
    [searchPayers, getInsurancePayer, overrides],
  )
}
