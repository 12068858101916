import { PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../layouts/NavLayout'

export function UnauthorizedPage() {
  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading className="mb-0">Whoops, you don't have access to this</PageHeading>
        </PageHeader>
        <PageContent>Contact support if you feel this is in error.</PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
