import { useState } from 'react'

import { ClientTable } from '@nuna/client'
import { useClientInviteDrawerSearchParams } from '@nuna/common'
import { FeatureFlagCheck } from '@nuna/feature-flag'
import {
  FillButtonLink,
  OutlineButton,
  PageContent,
  PageHeader,
  PageHeading,
  PageSearch,
  PageWrapper,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'

export function ClientList() {
  const [searchText, setSearchText] = useState('')
  const { openClientInviteDrawer } = useClientInviteDrawerSearchParams()

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <div className="v-align full-width">
            <PageHeading className="mb-0">Clients</PageHeading>

            <PageSearch
              className="ml-2"
              onChange={event => setSearchText(event.currentTarget.value)}
              placeholder="Search all clients"
              value={searchText}
              onClear={() => setSearchText('')}
              autoFocus={true}
            />

            <FeatureFlagCheck
              flags={['clientInvite']}
              featureType="component"
              fallback={
                <FillButtonLink className="ml-auto" to="/clients/invite">
                  Invite client
                </FillButtonLink>
              }
            >
              <OutlineButton className="ml-auto" variant="primary" onClick={() => openClientInviteDrawer({})}>
                Add Client
              </OutlineButton>
            </FeatureFlagCheck>
          </div>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <ClientTable
            searchText={searchText}
            columns={[
              'name',
              'last appt',
              'next appt',
              'eap company',
              'insurer',
              'joined',
              'state',
              'phone',
              'provider',
              'source',
            ]}
            onNavigate={row => `/clients/${row.id}`}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
