import { useParams } from 'react-router-dom'

import { usePatientQuery } from '@nuna/api'
import { AdditionalClientPage, ClientDetail } from '@nuna/client'

import { NavLayout } from '../../layouts/NavLayout'
import { AdminNotesBadge } from '../../shared/AdminNotes/AdminNotesBadge'
import { AdminClientTransactionHistory } from './AdminClientTransactionHistory'
import { ClientAccount } from './ClientAccount'
import { ClientEventLog } from './ClientEventLog'
import { ManageClient } from './ManageClient'

const ADDITIONAL_PAGES: AdditionalClientPage[] = [
  { navText: 'Manage', path: 'manage', ClientPage: ManageClient },
  { navText: 'Billing', path: 'transaction-history', ClientPage: AdminClientTransactionHistory },
  { navText: 'Account', path: 'account', ClientPage: ClientAccount },
  { navText: 'Event Log', path: 'event-log', ClientPage: ClientEventLog },
]

export function AdminClientDetail() {
  const { id = '' } = useParams<{ id: string }>()
  const { data } = usePatientQuery({
    variables: {
      id,
    },
  })

  return (
    <NavLayout>
      <ClientDetail
        additionalPages={ADDITIONAL_PAGES}
        headerRight={<AdminNotesBadge userId={data?.patient?.login?.id} className="mr-1" />}
      />
    </NavLayout>
  )
}
