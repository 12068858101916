export function getW9Link({
  addressId,
  insuranceTaxEntityId,
  addressEnrollmentId,
}: {
  addressId?: string
  insuranceTaxEntityId?: string
  addressEnrollmentId?: string
}) {
  const searchParams = new URLSearchParams()

  if (addressEnrollmentId) {
    searchParams.append('addressEnrollmentId', addressEnrollmentId)
  } else if (addressId && insuranceTaxEntityId) {
    searchParams.append('addressId', addressId)
    searchParams.append('insuranceTaxEntityId', insuranceTaxEntityId)
  } else {
    return ''
  }
  return `${import.meta.env['VITE_API_ENDPOINT']}/resource/insurance-payer-w9?${searchParams.toString()}`
}
