import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { useBaseProviderQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { Phone, TabletPortrait } from '@nuna/tunic'

import { ResponsiveMenu } from './ResponsiveMenu'

const MainBar = styled('div')`
  display: flex;
  align-items: center;
  padding: 1.5rem;

  @media (${Phone}) {
    padding: 0.5rem;
  }
`

export function NavBar() {
  const { login, onLogout } = useAuthDataContext()

  const { data } = useBaseProviderQuery({ variables: { id: login?.providerId || '' }, skip: !login?.providerId })
  const isTabletPortrait = useMediaQuery({
    query: `(${TabletPortrait})`,
  })

  const { avatarUrl = '' } = data ? data.provider : {}

  const handleLogout = () => {
    onLogout && onLogout()
  }

  const { firstName, lastName } = login || {}

  const menuProps = {
    firstName,
    lastName,
    onLogout: handleLogout,
    avatarUrl: avatarUrl || '',
  }

  return <MainBar>{isTabletPortrait && <ResponsiveMenu {...menuProps} />}</MainBar>
}
