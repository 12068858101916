import { useMemo } from 'react'

import { Role, useLoginLazyQuery, useSearchLoginsLazyQuery } from '@nuna/api'

import { Filter } from '../filters.types'

export function useAssigneeFilter(overrides?: Partial<Filter>) {
  const [searchLogins] = useSearchLoginsLazyQuery()
  const [getLogin] = useLoginLazyQuery()

  return useMemo<Filter>(
    () => ({
      ...{
        key: 'assignee',
        label: 'Assignee',
        async: false,
        getOptions: async () => {
          const result = await searchLogins({
            variables: { filters: { role: Role.Admin, active: true }, pagination: { limit: 300 } },
          })
          return [
            { value: 'me', label: 'Me' },
            { value: 'none', label: 'No Assignee' },
            ...(result.data?.searchLogins.items ?? []).map(login => ({
              value: login.id,
              label: `${login.firstName} ${login.lastName}`,
            })),
          ]
        },
        getSelectedValueOptions: async value => {
          if (value === 'me') {
            return [{ value, label: 'Me' }]
          }
          if (value === 'none') {
            return [{ value, label: 'No Assignee' }]
          }
          const result = await getLogin({ variables: { id: value as string } })
          if (!result.data?.login) return [{ value, label: 'Unknown Assignee' }]
          return [{ value, label: `${result.data.login.firstName} ${result.data.login.lastName}` }]
        },
      },
      ...overrides,
    }),
    [searchLogins, getLogin, overrides],
  )
}
