import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { ProviderActivityLogMetadata } from '@nuna/api'
import { Card, CardBody, TextButtonLink, body2, fontSize } from '@nuna/tunic'

import { PayerPlanSubRoutes, PayerSubRoutes, payerSubroute, planSubroute } from '../../../../../util/routes'

interface Props extends HTMLAttributes<HTMLDivElement> {
  metadata: ProviderActivityLogMetadata
}

export function ProviderActivityLogMetadataDisplay({ metadata, ...props }: Props) {
  if (metadata.__typename === 'ProviderActivityLogDefaultMetadata') {
    return null
  }

  return (
    <Card depth={-0.5} {...props}>
      <CardBody className="p-1">
        {(() => {
          switch (metadata.__typename) {
            case 'ProviderActivityLogEnrollmentChangeMetadata':
              return (
                <DefinitionList>
                  {metadata.oldValue && (
                    <>
                      <dt>Old value</dt>
                      <dd>{metadata.oldValue}</dd>
                    </>
                  )}
                  <dt>New value</dt>
                  <dd>{metadata.newValue}</dd>
                  {metadata.insurancePayerId && metadata.insurancePayerName && (
                    <>
                      <dt>Payer</dt>
                      <dt className="mb-1">
                        <TextButtonLink to={payerSubroute(metadata.insurancePayerId, PayerSubRoutes.Plans)}>
                          {metadata.insurancePayerName}
                        </TextButtonLink>
                      </dt>
                    </>
                  )}
                  {metadata.insurancePayerId && metadata.insurancePayerPlanId && metadata.insurancePayerPlanName && (
                    <>
                      <dt>Plan</dt>
                      <dd>
                        <TextButtonLink
                          to={planSubroute(
                            metadata.insurancePayerPlanId,
                            metadata.insurancePayerId,
                            PayerPlanSubRoutes.Providers,
                          )}
                        >
                          {metadata.insurancePayerPlanName}
                        </TextButtonLink>
                      </dd>
                    </>
                  )}
                </DefinitionList>
              )
            default:
              return null
          }
        })()}
      </CardBody>
    </Card>
  )
}

const DefinitionList = styled('dl')`
  margin: 0;
  font-size: ${fontSize.caption};
  dd {
    margin-inline-start: 0;
    margin-bottom: var(--spacing-1);
    color: ${body2};
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
