/* eslint-disable react/jsx-no-useless-fragment */
import { styled } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react'

import {
  AddressOwner,
  AddressType,
  OrderBy,
  SearchProviderAddressesQuery,
  TraversablePaginationSortInput,
  useSearchProviderAddressesQuery,
} from '@nuna/api'
import { UserLink } from '@nuna/common'
import { addressService } from '@nuna/core'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import {
  CollapsePresence,
  IconButton,
  IconFilter,
  IconPencil,
  IconToggleButton,
  makeTypographyComponent,
} from '@nuna/tunic'

import { AddressW9DropdownMenu } from './AddressW9DropdownMenu'
import { EditPracticeAddressDialog } from './EditPracticeAddressDialog'
import { FilterValues, ProviderPracticeAddressTableFilters } from './ProviderPracticeAddressTableFilters'

type Row = Omit<SearchProviderAddressesQuery['searchProviderAddresses']['items'][0], '__typename'> &
  Omit<SearchProviderAddressesQuery['searchProviderAddresses']['items'][0]['address'], '__typename'>

interface Props extends HTMLAttributes<HTMLDivElement> {
  fixedFilters?: Omit<FilterValues, 'searchText'>
  header?: ReactNode
}

const INITIAL_FILTER_VALUES: FilterValues = {}
const ITEM_LIMIT = 40
const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'address.createdAt', direction: OrderBy.Desc }]

export function ProviderPracticeAddressesTable({ header, fixedFilters, ...props }: Props) {
  const [editAddressId, setEditAddressId] = useState<string | null>(null)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(INITIAL_FILTER_VALUES)

  const { queryOptions, getPaginatorProps, initialTableState, setPagination, setLoading } = usePagination(
    {
      loading: true,
      initialSort: INITIAL_SORT,
      filters: filterValues,
    },
    { limit: ITEM_LIMIT },
  )

  const { data, loading } = useSearchProviderAddressesQuery({
    variables: {
      order: queryOptions.sortInput,
      filters: {
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Provider,
        ...queryOptions.filters,
        ...fixedFilters,
      },
      pagination: queryOptions.paginationInput,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'searchProviderAddresses', debounceTimeout: 200 },
  })

  useEffect(
    () => setPagination(data?.searchProviderAddresses.pagination),
    [data?.searchProviderAddresses.pagination, setPagination],
  )

  useEffect(() => setLoading(loading), [loading, setLoading])

  const rowData = useMemo(() => buildRows(data), [data])

  const columns: DataTableColumn<Row>[] = [
    { Header: 'Provider', accessor: 'provider', Cell: ({ value }) => <UserLink user={value} /> },
    { Header: 'Address Name', accessor: 'internalName' },
    { Header: 'Address', accessor: 'address', Cell: ({ value }) => <>{addressService.formatAddress(value)}</> },
    { Header: 'Phone', accessor: 'phone', Cell: ({ value }) => <>{value}</> },
    { Header: 'Fax', accessor: 'fax', Cell: ({ value }) => <>{value}</> },
    {
      Header: 'Public Parking Accessible',
      accessor: 'adaPublicParkingAccessible',
      Cell: ({ value }) => <YesNo value={value} />,
    },
    {
      Header: 'Public Transit Accessible',
      accessor: 'adaPublicTransitAccessible',
      Cell: ({ value }) => <YesNo value={value} />,
    },
    {
      Header: 'Wheel Chair Accessible',
      accessor: 'adaWheelchairAccessible',
      Cell: ({ value }) => <YesNo value={value} />,
    },
    { Header: 'W9', accessor: 'id', Cell: ({ row }) => <AddressW9DropdownMenu address={row.original} /> },
    {
      Header: 'Edit',
      accessor: 'timezone',
      Cell: ({ row }) => (
        <IconButton tooltip="Edit" small onClick={() => setEditAddressId(row.original.id)}>
          <IconPencil />
        </IconButton>
      ),
    },
  ]

  return (
    <Container {...props}>
      <Header>
        {header}
        <IconToggleButton
          className="ml-auto"
          tooltip="Filter"
          size="md"
          isActive={filtersOpen}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          <IconFilter />
        </IconToggleButton>
      </Header>
      <AnimatePresence>
        {filtersOpen && (
          <CollapsePresence>
            <ProviderPracticeAddressTableFilters
              fixedFilters={fixedFilters}
              filterValues={filterValues}
              setFilterVal={setFilterVal}
              clearFilters={clearFilters}
            />
          </CollapsePresence>
        )}
      </AnimatePresence>
      <DataTable
        initialState={initialTableState}
        columns={columns}
        rowData={rowData}
        loading={loading}
        scrollStrategy="within"
        className="flex-remaining-space address-table"
        paginated
        paginatorProps={getPaginatorProps()}
      />
      <EditPracticeAddressDialog
        addressId={editAddressId}
        onCancel={() => setEditAddressId(null)}
        onSaved={() => setEditAddressId(null)}
      />
    </Container>
  )
}

function buildRows(data: SearchProviderAddressesQuery | undefined): Row[] | undefined {
  if (!data?.searchProviderAddresses) return undefined

  return data.searchProviderAddresses.items.map(providerAddress => ({ ...providerAddress, ...providerAddress.address }))
}

function YesNo({ value }: { value?: boolean | null }) {
  return <>{value ? 'Yes' : 'No'}</>
}

const Container = styled(
  makeTypographyComponent('flex-column overflow-hidden full-width provider-practice-address-table-wrapper', 'div'),
)`
  .address-table {
    background-color: #fff;
    z-index: 2;
  }
`
const Header = makeTypographyComponent('v-align mb-2', 'div')
