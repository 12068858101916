import * as Yup from 'yup'
import { Formik, setNestedObjectValues } from 'formik'
import { pick } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { CompanyCreateDetails, CreateCompanyMutationVariables, useCreateCompanyMutation } from '@nuna/api'
import { FillButton, PageContent, PageHeader, PageHeading, PageWrapper, toast } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { EntityForm, EntityFormButtonRow } from '../../shared/EntityForm'
import { customerDetail } from '../../util/routes'
import {
  CompanyFormFields,
  CompanyFormValues,
  INITIAL_COMPANY_FORM_VALUES,
  companyFieldsSchema,
} from './CompanyContractFormFields'
import {
  CustomerContractFormFields,
  INITIAL_CUSTOMER_CONTRACT_FORM_VALUES,
  customerContractFormValuesToMutationInput,
  customerContractSchema,
} from './customer-contract/CustomerContractFormFields'
import { CustomerContractFormValues } from './customer-contract/shared'

interface FormValues extends CustomerContractFormValues, CompanyFormValues {}

const companySchema = Yup.object().concat(companyFieldsSchema).concat(customerContractSchema)

const initialValues: FormValues = {
  ...INITIAL_CUSTOMER_CONTRACT_FORM_VALUES,
  ...INITIAL_COMPANY_FORM_VALUES,
}

export function NewCustomer() {
  const navigate = useNavigate()

  const [createCompany, { loading: updateLoading }] = useCreateCompanyMutation()

  const submit = async (values: FormValues) => {
    try {
      const variables = formValuesToNewCompanyObject(values)
      const newCompanyResults = await createCompany({ variables, refetchQueries: ['SearchCompaniesPaginated'] })

      const companyId = newCompanyResults?.data?.createCompany?.id
      if (companyId) {
        navigate(customerDetail(companyId))
      }
    } catch (e) {
      console.error(e)
      toast.urgent('Please check the form and try again')
    }
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading className="mb-0">New Customer</PageHeading>
        </PageHeader>
        <PageContent>
          <Formik initialValues={initialValues} onSubmit={submit} validationSchema={companySchema}>
            {formProps => {
              const { values, handleSubmit, setTouched } = formProps

              return (
                <EntityForm onSubmit={handleSubmit} style={{ maxWidth: '40rem' }}>
                  <CompanyFormFields className="mb-3" {...formProps} />
                  <CustomerContractFormFields {...formProps} />
                  <EntityFormButtonRow>
                    <FillButton
                      isLoading={updateLoading}
                      type="submit"
                      onClick={() => {
                        setTouched(setNestedObjectValues(values, true))
                      }}
                    >
                      Create Customer
                    </FillButton>
                  </EntityFormButtonRow>
                </EntityForm>
              )
            }}
          </Formik>
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

// Utilities

function formValuesToNewCompanyObject(formValues: FormValues): CreateCompanyMutationVariables {
  const companyCreateDetails: CompanyCreateDetails = {
    name: formValues.name,
    ...pick(formValues, ['logoDocumentId', 'supportsHrisIntegration', 'shouldSyncHris', 'shouldRunDeEligiblization']),
  }
  return { contractDetails: customerContractFormValuesToMutationInput(formValues), companyCreateDetails }
}
