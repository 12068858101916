import { styled } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'
import { MdClose, MdMenu } from 'react-icons/md'
import { useLocation } from 'react-router-dom'

import { AuthorizedAvatar } from '@nuna/common'
import { IconButton, body2, greySet } from '@nuna/tunic'

import { Drawer } from '../Drawer'
import { SidebarNavigationItems } from '../Sidebar/SidebarNavigationItems'

const MenuIconContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  font-size: 1.5rem;
  margin-right: 0.25rem;
`

const DrawerContainer = styled('div')`
  padding: 1rem 0 2rem 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const AvatarContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const CloseButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
`

const MenuContainer = styled('div')`
  padding: 1rem;
`

interface ResponsiveMenuProps {
  onLogout?: () => void
  firstName?: string
  lastName?: string
  avatarUrl?: string
}

export const MenuButton = styled('button')`
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  height: 2.25rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  transition: 0.1s;
  border: none;
  color: ${greySet[70].hex};
  border-radius: 0.25rem;
  transition: background 300ms ease;
  display: inline-flex;
  align-items: center;

  &:focus {
    outline: 1px solid #fff;
  }

  &:hover {
    background-color: ${greySet[15].hex};
  }
`

export function ResponsiveMenu({ firstName, lastName, avatarUrl }: ResponsiveMenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    return () => handleClose()
  }, [location])

  return (
    <>
      <MenuButton onClick={handleOpen}>
        <MenuIconContainer>
          <MdMenu />
        </MenuIconContainer>
        Menu
      </MenuButton>
      <Drawer isOpen={isOpen} onClose={handleClose} size="18rem" label="Menu">
        <DrawerContainer>
          <div>
            <CloseButtonContainer>
              <IconButton tooltip="Close menu" variant="secondary" onClick={handleClose}>
                <MdClose size={24} color={body2} />
              </IconButton>
            </CloseButtonContainer>
            <AvatarContainer className="v-align">
              <AuthorizedAvatar size="sm" url={avatarUrl} />
            </AvatarContainer>
            <h2 className="h5 text-center mt-2 mb-5">
              {firstName} {lastName}
            </h2>
            <MenuContainer>
              <SidebarNavigationItems />
            </MenuContainer>
          </div>
        </DrawerContainer>
      </Drawer>
    </>
  )
}
