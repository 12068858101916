import { intersection } from 'lodash'
import { useMemo } from 'react'

import { ProviderDataDenormalizedColumnMetadata, usePayerRosterDefinitionPresetMetaDataQuery } from '@nuna/api'

import { useColumnMetaData } from './useColumnMetaData'

export function usePresetTransformationConfig(dataMappings: string[]) {
  const { columnMetaData } = useColumnMetaData()
  const { data: presetData } = usePayerRosterDefinitionPresetMetaDataQuery()
  const columns = useMemo(
    () => columnMetaData?.filter(c => dataMappings.includes(c.key)) ?? [],
    [columnMetaData, dataMappings],
  )

  const availablePresets = useMemo(() => {
    return (presetData?.payerRosterDefinitionPresetMetaData ?? []).filter(preset => {
      if (intersection(preset.forColumns, dataMappings).length > 0) {
        return true
      }

      if (
        intersection(
          preset.forDataTypes,
          columns.map(c => c.dataType),
        ).length > 0
      ) {
        return true
      }

      return false
    })
  }, [columns, presetData, dataMappings])

  const getDefaultPreset = (column: Pick<ProviderDataDenormalizedColumnMetadata, 'key' | 'dataType'>) => {
    return presetData?.payerRosterDefinitionPresetMetaData.find(preset => {
      if (column?.dataType && preset.forDataTypes.includes(column.dataType)) {
        return true
      }

      if (column?.key && preset.forColumns.includes(column.key)) {
        return true
      }

      return false
    })
  }

  return { availablePresets, getDefaultPreset }
}
