import { useParams } from 'react-router-dom'

import { TransactionHistory } from '@nuna/coverage'

type RouteProps = {
  id: string
}

export function AdminClientTransactionHistory() {
  const { id = '' } = useParams<RouteProps>()

  return (
    <>
      <h2 className="h5">Transaction history</h2>
      <TransactionHistory patientId={id} />
    </>
  )
}
