import { FormikProps } from 'formik'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'

import {
  InsurancePayer,
  PayerRosterDefinition,
  PayerRosterDefinitionConfig,
  usePayerRosterDefinitionQuery,
} from '@nuna/api'
import { PayerSelect } from '@nuna/coverage'
import { Card, CardBody, FillButton, GhostButton, OutlineButton, StatusLabel, TextField } from '@nuna/tunic'

import { PayerRosterSelect } from '../../PayerRosterSelect'
import { PayerRosterDefinitionFormValues } from '../PayerRosterDefinition.types'
import { PayerRosterChangesetMultiselect } from './PayerRosterChangesetMultiselect'

interface NameAndPayerFormProps extends FormikProps<PayerRosterDefinitionFormValues> {
  isNew: boolean
  saveLoading?: boolean
  insurancePayer?: Pick<InsurancePayer, 'id' | 'name'> | null
}
export function NameAndPayerForm({
  isNew,
  getFieldProps,
  values,
  setFieldValue,
  saveLoading,
  insurancePayer,
}: NameAndPayerFormProps) {
  const [isEdit, setIsEdit] = useState(isNil(values.id))
  const [fromRosterDefinitionId, setFromRosterDefinitionId] = useState<string | null>(null)

  const { data: otherRosterData } = usePayerRosterDefinitionQuery({
    variables: { filters: { id: fromRosterDefinitionId } },
    skip: !fromRosterDefinitionId,
  })

  useEffect(() => {
    if (!saveLoading && values.id) {
      setIsEdit(false)
    }
  }, [saveLoading, values.id])

  useEffect(() => {
    if (!!otherRosterData?.payerRosterDefinition && !!fromRosterDefinitionId) {
      setFieldValue('config', otherRosterData.payerRosterDefinition.config)
    }
  }, [otherRosterData, fromRosterDefinitionId, setFieldValue])

  const handleFromRosterSelect = (option: Pick<PayerRosterDefinition, 'id'> | null | undefined) => {
    setFromRosterDefinitionId(option?.id ?? null)
    if (!option) {
      setFieldValue('config', { sheets: [], version: '1.0', changeset: [], columns: [] } as PayerRosterDefinitionConfig)
    }
  }

  return (
    <Card depth={isEdit ? -1 : undefined}>
      <CardBody className="v-align gap-2">
        {isEdit && (
          <div>
            <div className="v-align gap-2">
              <TextField style={{ minWidth: 260 }} {...getFieldProps('name')} label="Name" />
              <PayerSelect
                label="Payer"
                style={{ minWidth: 300 }}
                value={values.insurancePayerId}
                onChange={ip => setFieldValue('insurancePayerId', ip?.id)}
              />
            </div>
            {isNew && (
              <div className="py-2">
                <PayerRosterSelect
                  label="Copy config from other roster"
                  placeholder="Select roster"
                  value={fromRosterDefinitionId}
                  onChange={handleFromRosterSelect}
                />
              </div>
            )}

            <div className="py-2">
              <PayerRosterChangesetMultiselect
                disabled={!!fromRosterDefinitionId}
                label="Tracked changes"
                value={values.config.changeset}
                onChange={changesets =>
                  setFieldValue(
                    'config.changeset',
                    changesets.map(c => c.value),
                  )
                }
              />
            </div>
            <div className="pt-2">
              <div className="v-align gap-1">
                <FillButton type="submit" isLoading={saveLoading}>
                  Save
                </FillButton>
                {!!values.id && <OutlineButton onClick={() => setIsEdit(false)}>Cancel</OutlineButton>}
              </div>
            </div>
          </div>
        )}
        {!isEdit && (
          <div>
            <div className="v-align gap-2">
              <div className="gap-2 baseline-align">
                <div className="large">{values.name}</div>
                <div className="caption text-secondary">{insurancePayer?.name}</div>
              </div>
              <GhostButton onClick={() => setIsEdit(true)}>Edit</GhostButton>
            </div>
            <div className="pt-1">
              <label className="text-medium">Tracked changes</label>
              <div className="pt-xs v-align gap-1">
                {values.config.changeset.map(changeset => (
                  <StatusLabel className="caption text-secondary">{changeset}</StatusLabel>
                ))}
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
