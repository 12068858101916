import { ProvidersFilterValues } from '../../../shared/ProviderListNavigation/ProviderFilters'
import { ProviderCompensationTable as CompTable } from './ProviderCompensationTable'
import { ProviderCompensationTableContextProvider } from './ProviderCompensationTableContextProvider'

interface Props {
  filterValues: ProvidersFilterValues
}

export function ProviderCompensationTable({ filterValues }: Props) {
  return (
    <ProviderCompensationTableContextProvider>
      <CompTable filterValues={filterValues} />
    </ProviderCompensationTableContextProvider>
  )
}
