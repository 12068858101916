import { useApolloClient } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'

import {
  AddressType,
  SearchAddressesQuery,
  useAutoAssignGeolocationsMutation,
  useSearchAddressesQuery,
} from '@nuna/api'
import { addressService } from '@nuna/core'
import { DataTable, DataTableColumn, usePagination } from '@nuna/data-table'
import { Dialog, FillButton, GhostButton, IconSwitch, OutlineButton, makeTypographyComponent } from '@nuna/tunic'

type Row = SearchAddressesQuery['searchAddresses']['items']['0']

const ADDRESS_TYPE_MAP = {
  [AddressType.ProviderHome]: 'Home',
  [AddressType.ProviderPractice]: 'Practice',
}

export function AddressesMissingGeolocationTable() {
  const client = useApolloClient()
  const [editingAddressId, setEditingAddressId] = useState<string | undefined>()
  const [autoAssignmentLoading, setAutoAssignmentLoading] = useState(false)
  const [autoAssignGeolocations] = useAutoAssignGeolocationsMutation()
  const { handleSort, queryOptions, getPaginatorProps, initialTableState, setPage, setPagination, setLoading } =
    usePagination({ loading: true }, { limit: 20 })

  const { data, loading } = useSearchAddressesQuery({
    variables: { filters: { geolocation: null }, pagination: queryOptions.paginationInput },
  })

  useEffect(() => setPagination(data?.searchAddresses.pagination), [data?.searchAddresses.pagination, setPagination])

  useEffect(() => setLoading(loading), [loading, setLoading])

  const columns: DataTableColumn<Row>[] = useMemo<DataTableColumn<Row>[]>(
    () => [
      { accessor: 'name', Header: 'Name' },
      { accessor: 'id', Header: 'Address', Cell: ({ row }) => <>{addressService.formatAddress(row.original)}</> },
      // eslint-disable-next-line react/jsx-no-useless-fragment
      { accessor: 'addressType', Header: 'Type', Cell: ({ value }) => <>{ADDRESS_TYPE_MAP[value]}</> },
      { accessor: 'owner', Header: 'Owner' },
      {
        accessor: 'updatedAt',
        Header: '',
        Cell: ({ row }) => (
          <GhostButton onClick={() => setEditingAddressId(row.original.id)}>Add Geolocation</GhostButton>
        ),
      },
    ],
    [],
  )

  const rowData = useMemo(() => data?.searchAddresses.items, [data])

  const refresh = () => {
    client.cache.evict({ fieldName: 'searchProviders' })
    setPage(1)
  }

  const handleAutoAssign = async () => {
    setAutoAssignmentLoading(true)
    await autoAssignGeolocations()
    setTimeout(() => {
      refresh()
      setAutoAssignmentLoading(false)
    }, 10000)
  }

  return (
    <Container>
      <Header>
        <h2 className="h5 mb-0">Addresses Missing Geolocation</h2>
        <div className="v-align ml-auto gap-3">
          <FillButton isLoading={autoAssignmentLoading} className="ml-auto" onClick={handleAutoAssign}>
            Auto-assign Geolocations
          </FillButton>
          <OutlineButton onClick={refresh}>
            <IconSwitch className="mr-1" />
            Refresh
          </OutlineButton>
        </div>
      </Header>
      <DataTable
        scrollStrategy="within"
        columns={columns}
        rowData={rowData}
        initialState={initialTableState}
        loading={loading}
        onSort={handleSort}
        paginated
        paginatorProps={getPaginatorProps()}
      />
      <Dialog isOpen={!!editingAddressId} onClose={() => setEditingAddressId(undefined)}>
        <div>I'm a thing</div>
      </Dialog>
    </Container>
  )
}

const Container = makeTypographyComponent('flex-column overflow-hidden', 'div')
const Header = makeTypographyComponent('v-align mb-2', 'div')
