import { useCallback, useMemo } from 'react'

import { ProviderDataDenormalized } from '@nuna/api'

import { useUrlFilters } from '../../../../shared/filters/hooks/useUrlFilters'

export type FieldKey = keyof Partial<Omit<ProviderDataDenormalized, '__typename' | 'id'>>
export type QuickViewKey = 'address' | 'licenses'

export interface MasterRosterQuickView {
  key: QuickViewKey
  label: string
  columns: FieldKey[]
}

const QUICK_VIEWS: MasterRosterQuickView[] = [
  {
    key: 'address',
    label: 'Address',
    columns: [
      'addressName',
      'addressStreetLineOne',
      'addressStreetLineTwo',
      'addressStreetFull',
      'addressCity',
      'addressCounty',
      'addressZipCode',
      'addressState',
      'addressTimezone',
      'addressPhone',
    ],
  },
  {
    key: 'licenses',
    label: 'Licenses',
    columns: [
      'primaryLicenseExpirationDate',
      'primaryLicenseIssueDate',
      'primaryLicenseNumber',
      'primaryLicenseState',
      'primaryLicenseTypeAbbrv',
      'primaryLicenseTypeName',
      'primaryLicenseTypeName',
      'primaryLicenseTypeSpecialty',
      'primaryLicenseTypeTaxonomy',
      'otherLicenses',
    ],
  },
]

export function useMasterRosterQuickView() {
  const { filterValues, setFilter, removeFilter } = useUrlFilters(data =>
    QUICK_VIEWS.find(qView => qView.key === (data?.quickView as string)),
  )

  const quickViewColumns = useMemo(() => filterValues?.columns, [filterValues])

  const setQuickView = useCallback((key: QuickViewKey) => setFilter('quickView', key), [setFilter])

  const removeQuickView = useCallback(() => removeFilter('quickView'), [removeFilter])

  return { quickViews: QUICK_VIEWS, selectedQuickView: filterValues, quickViewColumns, setQuickView, removeQuickView }
}
