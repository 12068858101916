import { groupBy, omit } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo } from 'react'

import {
  OrderBy,
  SearchProviderActivityLogFilterInput,
  TraversablePaginationSortInput,
  useSearchProviderActivityLogsQuery,
} from '@nuna/api'
import { Paginator, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { Card, CardBody, ContextualAlert, Grid, List, ListItem, SearchField } from '@nuna/tunic'

import { ProviderActivityLifecycleForm } from '../ProviderActivityLifecycleForm'
import { ProviderActivityLogItem } from './components/ProviderActivityLogItem'
import { ProviderActivityLogTypeSelect } from './components/ProviderActivityLogTypeSelect'

interface Props {
  providerId: string
  isAddingNoteState: [boolean, (value: boolean) => void]
}

type FilterValues = Omit<SearchProviderActivityLogFilterInput, 'search'> & {
  searchText?: string | null
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]

export function ProviderActivityLog({ providerId, isAddingNoteState }: Props) {
  const [isAddingNote, setIsAddingNote] = isAddingNoteState

  const initialFilterValues: FilterValues = useMemo(
    () => ({
      providerId,
    }),
    [providerId],
  )
  const { filterValues, setFilterVal } = useDataTableFiltering(initialFilterValues)
  const filters = useMemo(() => buildSearchOptions(filterValues), [filterValues])
  const { queryOptions, getPaginatorProps, setPagination, setLoading } = usePagination(
    {
      loading: true,
      initialSort: INITIAL_SORT,
      filters,
    },
    { limit: 20 },
  )
  const { data, loading } = useSearchProviderActivityLogsQuery({
    variables: {
      order: queryOptions.sortInput,
      filters: queryOptions.filters || {},
      pagination: queryOptions.paginationInput,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'providerActivityLogSearch', debounceTimeout: 200 },
  })

  useEffect(
    () => setPagination(data?.searchProviderActivityLogs.pagination),
    [data?.searchProviderActivityLogs.pagination, setPagination],
  )
  useEffect(() => setLoading(loading), [loading, setLoading])

  const providerActivityLogs = data?.searchProviderActivityLogs.items || []
  const groupedActivityLogs = groupBy(providerActivityLogs, item => moment(item.createdAt).format('YYYY-MM-DD'))
  const sortedActivityLogs = Object.keys(groupedActivityLogs).sort().reverse()

  return (
    <>
      <div className="bottom-align gap-3 mb-3">
        <SearchField
          value={filterValues.searchText}
          onChange={e => setFilterVal('searchText', e.currentTarget.value)}
        />
        <ProviderActivityLogTypeSelect
          style={{ width: 300 }}
          value={filterValues.eventType}
          onChange={option => setFilterVal('eventType', option?.value)}
        />
      </div>
      {isAddingNote && (
        <Card key="add-note-form" className="my-3" depth={-1}>
          <CardBody>
            <ProviderActivityLifecycleForm
              providerId={providerId}
              onSuccess={() => {
                setIsAddingNote(false)
              }}
              onCancel={() => {
                setIsAddingNote(false)
              }}
            />
          </CardBody>
        </Card>
      )}
      {sortedActivityLogs.length > 0 && (
        <div>
          {sortedActivityLogs.map(date => {
            const group = groupedActivityLogs[date]

            return (
              <Grid key={`activity-logs-${date}`} container spacing={2} style={{ marginTop: '0.5rem' }}>
                <Grid style={{ width: '144px' }}>
                  <span className="caption text-medium mt-0 ml-1">{date && moment(date).format('MMM Do, YYYY')}</span>
                </Grid>
                <Grid
                  size={{
                    xs: 'grow',
                  }}
                >
                  <List>
                    {group.map(item => (
                      <ListItem className="v-align" key={item.id}>
                        <ProviderActivityLogItem {...item} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )
          })}
          <Paginator {...getPaginatorProps()} />
        </div>
      )}
      {sortedActivityLogs.length === 0 && (
        <ContextualAlert className="mt-3" largeText>
          No Data
        </ContextualAlert>
      )}
    </>
  )
}

function buildSearchOptions(filterValues?: FilterValues): SearchProviderActivityLogFilterInput {
  if (!filterValues) {
    return {}
  }

  return omit(
    { ...filterValues, search: filterValues.searchText ? { term: filterValues.searchText, threshold: 0.2 } : null },
    'searchText',
  )
}
