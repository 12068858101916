import { Form, Formik } from 'formik'
import { pick } from 'lodash'
import { useMemo } from 'react'

import {
  InsurancePayerConfiguration,
  InsurancePayerEnrollmentEffectiveAfter,
  InsurancePayerEnrollmentType,
  useSaveInsurancePayerConfigurationMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { Select, Skeleton, Switch, makeTypographyComponent, toast } from '@nuna/tunic'

import { PayerCountySelect } from './PayerCountySelect'
import { StateCounty, payerCountyToString, stringToPayerCounty } from './payer-counties'

type Configuration = Pick<
  InsurancePayerConfiguration,
  | 'id'
  | 'enrollmentEffectiveAfter'
  | 'enrollmentType'
  | 'providerMustResideInState'
  | 'autoEnrollment'
  | 'insurancePayerId'
  | 'includedCounties'
  | 'excludedCounties'
  | 'enrollmentNotificationsEnabled'
  | 'asyncVerificationsEnabled'
>

interface FormValues extends Omit<Configuration, 'includedCounties' | 'excludedCounties'> {
  includedCounties: StateCounty[]
  excludedCounties: StateCounty[]
}

interface Props {
  configuration?: Configuration | null
  payerId: string
  isTpa?: boolean
}

export function PayerConfiguration({ configuration, isTpa }: Props) {
  const initialValues = useMemo(
    () => (configuration ? configurationToFormValues(configuration) : undefined),
    [configuration],
  )
  const [saveConfig] = useSaveInsurancePayerConfigurationMutation()

  const handleSave = async (values: FormValues) => {
    try {
      await saveConfig({ variables: { saveInput: formValuesToConfiguration(values) } })
      toast.success('Configuration saved')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving the configuration.'))
    }
  }

  if (!initialValues) {
    return <Skeleton height={10} />
  }

  const showEnrollmentRelatedFields = isTpa === false

  return (
    <Formik initialValues={initialValues} onSubmit={handleSave}>
      {({ values, setFieldValue, getFieldProps, submitForm }) => {
        const fieldPropsWithSave = (fieldName: string) => {
          const fieldProps = getFieldProps(fieldName)

          return {
            ...fieldProps,
            onChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
              if (fieldProps.onChange) {
                fieldProps.onChange(e)
              }
              submitForm()
            },
          }
        }
        return (
          <Form>
            <FormSection>
              <PrimaryHeader>Configuration</PrimaryHeader>
              <SecondaryHeader>How are enrollments handled?</SecondaryHeader>
              <Select {...fieldPropsWithSave('enrollmentType')}>
                <option value={InsurancePayerEnrollmentType.Packet}>Packet</option>
                <option value={InsurancePayerEnrollmentType.Portal}>Portal</option>
                <option value={InsurancePayerEnrollmentType.Roster}>Roster</option>
              </Select>
            </FormSection>

            {showEnrollmentRelatedFields && (
              <>
                <FormSection>
                  <SecondaryHeader>Automatically enroll qualified providers into payer</SecondaryHeader>
                  <Switch {...fieldPropsWithSave('autoEnrollment')} className="mt-3" checked={values.autoEnrollment}>
                    {values.autoEnrollment ? 'Yes' : 'No'}
                  </Switch>
                </FormSection>
                <FormSection>
                  <SecondaryHeader>Send automated enrollment notification emails to providers</SecondaryHeader>
                  <Switch
                    {...fieldPropsWithSave('enrollmentNotificationsEnabled')}
                    className="mt-3"
                    checked={values.enrollmentNotificationsEnabled}
                  >
                    {values.enrollmentNotificationsEnabled ? 'Yes' : 'No'}
                  </Switch>
                </FormSection>
                <FormSection>
                  <SecondaryHeader>When is a provider considered enrolled?</SecondaryHeader>
                  <Select {...fieldPropsWithSave('enrollmentEffectiveAfter')}>
                    <option value={InsurancePayerEnrollmentEffectiveAfter.Credentialed}>
                      When the provider is credentialed
                    </option>
                    <option value={InsurancePayerEnrollmentEffectiveAfter.Submitted}>
                      When the enrollment is submitted
                    </option>
                    <option value={InsurancePayerEnrollmentEffectiveAfter.Enrolled}>
                      When the payer enrolls the provider
                    </option>
                  </Select>
                </FormSection>
              </>
            )}

            <FormSection>
              <SecondaryHeader>
                A provider must reside in a state to be enrolled with a payer for that state
              </SecondaryHeader>
              <Switch
                {...fieldPropsWithSave('providerMustResideInState')}
                className="mt-3"
                checked={values.providerMustResideInState}
              >
                {values.providerMustResideInState ? 'Yes' : 'No'}
              </Switch>
            </FormSection>

            <FormSection className="full-width">
              <SecondaryHeader>Included counties</SecondaryHeader>
              <div className="caption text-secondary">
                The provider is enrolled if his/her primary practice address is in one of the following counties.
              </div>
              <PayerCountySelect
                value={values.includedCounties}
                onChange={newValue => {
                  setFieldValue('includedCounties', newValue)
                  submitForm()
                }}
              />
            </FormSection>

            <FormSection className="full-width">
              <SecondaryHeader>Excluded counties</SecondaryHeader>
              <div className="caption text-secondary">
                The provider will not be enrolled, regardless of state residency, if his/her primary practice address is
                in one of the following counties.
              </div>
              <PayerCountySelect
                value={values.excludedCounties}
                onChange={newValue => {
                  setFieldValue('excludedCounties', newValue)
                  submitForm()
                }}
              />
            </FormSection>

            <FormSection>
              <SecondaryHeader>Send e-checks asyncronously (allows for a full minute to check)</SecondaryHeader>
              <Switch
                {...fieldPropsWithSave('asyncVerificationsEnabled')}
                className="mt-3"
                checked={values.asyncVerificationsEnabled}
              >
                {values.asyncVerificationsEnabled ? 'Yes' : 'No'}
              </Switch>
            </FormSection>
          </Form>
        )
      }}
    </Formik>
  )
}

const FormSection = makeTypographyComponent('mb-3', 'div')
const PrimaryHeader = makeTypographyComponent('mb-3 h4', 'h1')
const SecondaryHeader = makeTypographyComponent('text-medium large sans-serif mb-1', 'h3')

function configurationToFormValues(configuration: Configuration): FormValues {
  return {
    ...pick(configuration, [
      'id',
      'enrollmentEffectiveAfter',
      'enrollmentType',
      'providerMustResideInState',
      'autoEnrollment',
      'insurancePayerId',
      'enrollmentNotificationsEnabled',
      'asyncVerificationsEnabled',
    ]),
    includedCounties: configuration.includedCounties.map(stringToPayerCounty),
    excludedCounties: configuration.excludedCounties.map(stringToPayerCounty),
  }
}

function formValuesToConfiguration(values: FormValues): Configuration {
  return {
    ...values,
    includedCounties: values.includedCounties.map(payerCountyToString),
    excludedCounties: values.excludedCounties.map(payerCountyToString),
  }
}
