import { ApolloError } from '@apollo/client/errors'
import { TextField } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'

import { BasicProviderFragment, ProviderNetworkStatus, useSearchProvidersQuery } from '@nuna/api'

export type SearchProvider = BasicProviderFragment & { key: string; value: string }

type ProviderSearchProps = {
  label?: string
  limit?: number
  inputSize?: 'small' | 'medium'
  entityId?: string
  onChange?: (providers: SearchProvider[] | null) => void
  onLoading?: (loading: boolean) => void
  onError?: (error: ApolloError | undefined) => void
}

export function ProviderSearch({
  label = 'Search Providers',
  limit = 25,
  inputSize = 'small',
  entityId,
  onChange,
  onLoading,
  onError,
}: ProviderSearchProps) {
  const [searchInput, setSearchInput] = useState('')

  const filters = useMemo(() => {
    return {
      id: entityId || undefined,
      search: { term: searchInput, threshold: 0.2 },
      active: true,
      networkStatusIn: [
        ProviderNetworkStatus.InProgress,
        ProviderNetworkStatus.UnderReview,
        ProviderNetworkStatus.Approved,
      ],
    }
  }, [entityId, searchInput])

  const { loading } = useSearchProvidersQuery({
    variables: {
      pagination: { limit },
      filters,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'search-providers-debounce', debounceTimeout: 500 },
    onCompleted: data => {
      if (data?.searchProviders?.items) {
        handleSearchComplete(data.searchProviders.items)
      }
    },
    onError: error => {
      if (onError) onError(error)
    },
  })

  const mapProviders = (providers: BasicProviderFragment[] | []): SearchProvider[] => {
    if (!providers) return []
    return providers.map(provider => ({
      ...provider,
      key: provider.id,
      value: `${provider.firstName} ${provider.lastName}`,
    }))
  }

  if (onLoading) onLoading(loading)

  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearchInput(value)
  }

  const handleSearchComplete = (providers: BasicProviderFragment[] | []) => {
    const mappedProviders = mapProviders(providers || [])
    if (onChange) onChange(mappedProviders)
  }

  return entityId ? null : (
    <TextField
      label={label}
      defaultValue={searchInput}
      id="outlined-size-small"
      onChange={handleChange}
      size={inputSize}
    />
  )
}
