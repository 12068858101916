import { DocumentLinkCell } from '@nuna/common'
import { CredentialingChecklistItem } from '@nuna/core'

const checkParse = (value: string) => {
  try {
    const parsed = JSON.parse(value)
    return typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed)
  } catch (e) {
    return false // If parsing fails, it's not JSON
  }
}

export const ChecklistSourceCell = ({ value, data }: { value: string; data: CredentialingChecklistItem }) => {
  if (checkParse(value)) {
    const document = JSON.parse(value)
    return <DocumentLinkCell document={document} />
  }
  if (data.sourceLink) {
    return (
      <a href={data.sourceLink} target="_blank" rel="noreferrer">
        {value}
      </a>
    )
  }
  return <span>{value}</span>
}
