import { Divider } from '@mui/material'
import { useState } from 'react'

import { Address, InsurancePayer, useInsuranceTaxEntitiesQuery } from '@nuna/api'
import {
  DropdownButton,
  IconFileDocument,
  Menu,
  MenuItem,
  MenuItemStack,
  Tooltip,
  makeTypographyComponent,
} from '@nuna/tunic'

import { getW9Link } from '../../../util/w9'

interface AddressW9DropdownMenuProps {
  address: Pick<Address, 'id' | 'state' | 'deletedAt'>
  insurancePayer?: Pick<InsurancePayer, 'outOfStateCoverage' | 'id'>
}

export function AddressW9DropdownMenu({ address, insurancePayer }: AddressW9DropdownMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { data } = useInsuranceTaxEntitiesQuery()
  const unfilteredTaxEntities = data?.insuranceTaxEntities || []

  const taxEntityStates = unfilteredTaxEntities.map(taxEntity => taxEntity.state)
  const showNationalEntities = insurancePayer?.outOfStateCoverage
  const showSingleLink = !!insurancePayer && !showNationalEntities

  const getFilteredTaxEntities = () => {
    return unfilteredTaxEntities.filter(taxEntity => {
      if (showNationalEntities) {
        return true
      }
      if (taxEntity.includedPayerIds.length > 0) {
        return true
      }

      if (taxEntity.state === address.state) {
        return true
      } else if (taxEntity.isDefault && !taxEntityStates.includes(address.state)) {
        return true
      }

      return false
    })
  }

  if (address.deletedAt) {
    return <>Address removed</>
  }

  if (showSingleLink) {
    // if we have an insurance payer and it doesn't support out-of-state-coverage, there is only one possible W9
    // so we should render a single link istead of a dropdown
    const taxEntity = unfilteredTaxEntities.find(taxEntity => {
      if (taxEntity.includedPayerIds.includes(insurancePayer.id)) {
        return true
      } else if (taxEntity.state === address.state) {
        return true
      } else if (taxEntity.isDefault) {
        return true
      }
      return false
    })

    if (!taxEntity) {
      return null
    }

    return (
      <Tooltip content={taxEntity.organizationName}>
        <a href={getW9Link({ addressId: address.id, insuranceTaxEntityId: taxEntity.id })} className="v-align">
          <IconFileDocument className="mr-xs" />
          Download
        </a>
      </Tooltip>
    )
  }

  return (
    <>
      <DropdownButton
        onClick={e => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
        isActive={!!anchorEl}
      >
        Select W9
      </DropdownButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItemStack>
          {!insurancePayer && (
            // if we don't have an insurance payer, we should show regional options and national options for W9
            <>
              <GroupHeader>Regional</GroupHeader>
              {getFilteredTaxEntities().map(taxEntity => (
                <MenuItem
                  to={getW9Link({ addressId: address.id, insuranceTaxEntityId: taxEntity.id })}
                  key={taxEntity.id}
                >
                  {taxEntity.organizationName}
                </MenuItem>
              ))}
              <div className="py-1">
                <Divider />
              </div>
              <GroupHeader>National</GroupHeader>
              {unfilteredTaxEntities.map(taxEntity => (
                <MenuItem
                  to={getW9Link({ addressId: address.id, insuranceTaxEntityId: taxEntity.id })}
                  key={taxEntity.id}
                >
                  {taxEntity.organizationName}
                </MenuItem>
              ))}
            </>
          )}
          {!!insurancePayer &&
            // if we have an insurance payer, but can't filter it down to a single tax entity, we should show all
            // tax entities in the dropdown
            unfilteredTaxEntities.map(taxEntity => (
              <MenuItem
                to={getW9Link({ addressId: address.id, insuranceTaxEntityId: taxEntity.id })}
                key={taxEntity.id}
              >
                {taxEntity.organizationName}
              </MenuItem>
            ))}
        </MenuItemStack>
      </Menu>
    </>
  )
}

const GroupHeader = makeTypographyComponent('text-secondary text-medium', 'div')
