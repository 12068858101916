import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { Column } from 'react-table'

import { DiagnosisFragment, useAvailableDiagnosesQuery } from '@nuna/api'
import { DataTable } from '@nuna/data-table'
import { FillButton, PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../../layouts/NavLayout'
import { DiagnosisForm } from './DiagnosisForm'
import { DiagnosisRow } from './DiagnosisRow'

export function DiagnosesList() {
  const [showNewForm, setShowNewForm] = useState(false)
  const { data, loading } = useAvailableDiagnosesQuery({ fetchPolicy: 'cache-and-network' })

  const rowData = useMemo<DiagnosisFragment[]>(() => {
    return sortBy(data?.availableDiagnoses ?? [], 'label')
  }, [data])

  const columns = useMemo<Column<DiagnosisFragment>[]>(
    () => [
      {
        Header: 'Label',
        accessor: 'label',
        width: 180,
      },
      {
        Header: 'Category',
        accessor: 'category',
        width: 180,
      },
      {
        Header: 'ICD10 Code',
        accessor: 'icd10code',
        // className: 'right-align',
        width: 120,
      },
      {
        Header: 'Diagnosis Level',
        accessor: 'diagnosisLevel',
        // className: 'right-align',
        width: 150,
      },
      {
        Header: <span style={{ paddingRight: '0.5rem' }}>Edit</span>,
        accessor: 'id',
        className: 'right-align',
      },
    ],
    [],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <PageHeading className="mb-0">Diagnoses</PageHeading>
        </PageHeader>
        <PageContent $paddingTop={false} style={{ maxWidth: '1280px' }}>
          <DataTable
            rowData={rowData}
            columns={columns}
            loading={loading}
            customRow={rowProps => {
              const { key, ...spreadRowProps } = rowProps
              return <DiagnosisRow key={key} {...spreadRowProps} />
            }}
          />
          {showNewForm && (
            <DiagnosisForm
              className="mt-4"
              onSave={() => setShowNewForm(false)}
              onCancel={() => setShowNewForm(false)}
            />
          )}

          {!showNewForm && (
            <FillButton leadingIcon className="mt-4" onClick={() => setShowNewForm(true)}>
              <MdAdd size={24} /> New
            </FillButton>
          )}
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
