import { compact } from 'lodash'
import { useMemo } from 'react'

import { UserLink } from '@nuna/common'
import { DataTableColumn } from '@nuna/data-table'
import { Checkbox, GhostButton, GhostButtonLink, StatusLabel } from '@nuna/tunic'

import { PayerSubRoutes, payerSubroute } from '../../../../util/routes'
import { InsurancePolicyTableColumn, InsurancePolicyTableRow } from '../InsurancePolicyTable.types'

interface Args {
  columns: InsurancePolicyTableColumn[]
  limit: number
  selectedIds: string[]
  onSelect: (id: string) => void
  onToggleSelectAll: () => void
}
export function useInsurancePolicyTableColums({ columns, selectedIds, limit, onSelect, onToggleSelectAll }: Args) {
  return useMemo<DataTableColumn<InsurancePolicyTableRow>[]>(() => {
    const cols = compact(
      columns.map<DataTableColumn<InsurancePolicyTableRow> | null>(column => {
        switch (column) {
          case 'patient':
            return { accessor: 'patient', Header: 'Client', Cell: ({ value }) => <UserLink user={value} /> }

          case 'payer':
            return {
              accessor: 'insurancePayer',
              Header: 'Payer',
              Cell: ({ value }) =>
                value ? (
                  <GhostButtonLink to={payerSubroute(value.id, PayerSubRoutes.Plans)}>{value?.name}</GhostButtonLink>
                ) : null,
            }

          case 'plan': {
            return {
              accessor: 'insurancePayerPlan.name' as keyof InsurancePolicyTableRow,
              Header: 'Plan',
            }
          }
          case 'network':
            return {
              accessor: 'insurancePayerNetwork.name' as keyof InsurancePolicyTableRow,
              Header: 'Payer network',
            }

          case 'active': {
            return {
              accessor: 'active',
              Header: 'Status',
              Cell: ({ value }) => (
                <StatusLabel intent={value ? 'information' : 'urgent'}>{value ? 'Active' : 'Inactive'}</StatusLabel>
              ),
            }
          }

          default:
            return null
        }
      }),
    )

    cols.unshift({
      accessor: 'id',
      Header: (
        <div style={{ width: 100 }}>
          <GhostButton onClick={onToggleSelectAll}>
            {selectedIds.length === limit ? 'Deselect' : 'Select'} all
          </GhostButton>
        </div>
      ),
      Cell: ({ value }) => <Checkbox checked={selectedIds.includes(value)} onChange={() => onSelect(value)} />,
    })
    return cols
  }, [columns, selectedIds, limit, onToggleSelectAll, onSelect])
}
