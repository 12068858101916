import { styled } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { AddressOwner, ProviderAddressFragment, useSearchAddressesQuery } from '@nuna/api'
import { addressService } from '@nuna/core'
import {
  Checkbox,
  ContextualAlert,
  FillButton,
  OutlineButton,
  StateAutocompleteSingle,
  makeTypographyComponent,
  tealSet,
} from '@nuna/tunic'

interface Props {
  providerAddresses: ProviderAddressFragment[]
  onAddressesSelected: (addressIds: string[]) => void
  onCancel: () => void
}

export function TavaAddressSelector({ providerAddresses, onAddressesSelected, onCancel }: Props) {
  const [selectedAddressIds, setSelectedAddressIds] = useState<string[]>([])
  const [selectedState, setSelectedState] = useState<string | undefined>(undefined)
  const { data, loading } = useSearchAddressesQuery({
    variables: { filters: { state: selectedState, owner: AddressOwner.Tava }, pagination: { limit: 1000 } },
    skip: !selectedState,
  })

  const currentAddressIds = useMemo(
    () => providerAddresses.map(providerAddress => providerAddress.address.id),
    [providerAddresses],
  )

  const addresses = (data?.searchAddresses.items ?? []).filter(address => !currentAddressIds.includes(address.id))

  const handleCancel = () => {
    setSelectedAddressIds([])
    onCancel()
  }

  useEffect(() => {
    setSelectedAddressIds([])
  }, [selectedState])

  const toggleAddressId = (addressId: string) => {
    setSelectedAddressIds(oldIds => {
      if (oldIds.includes(addressId)) {
        return oldIds.filter(id => id !== addressId)
      } else {
        return [...oldIds, addressId]
      }
    })
  }

  return (
    <Container>
      <StateAutocompleteSingle value={selectedState} onChange={option => setSelectedState(option?.value)} />
      <AddressList>
        {selectedState && !loading && addresses.length === 0 && (
          <ContextualAlert intent="caution">
            No addresses found that aren't already assigned to this provider
          </ContextualAlert>
        )}
        {addresses.map(address => (
          <Checkbox
            key={address.id}
            className="text-secondary pl-xs"
            checked={selectedAddressIds.includes(address.id)}
            onChange={() => toggleAddressId(address.id)}
          >
            <div>
              <AddressName>{address.name}</AddressName> {addressService.formatAddress(address)}
            </div>
          </Checkbox>
        ))}
      </AddressList>
      <OutlineButton className="full-width mt-auto" onClick={handleCancel}>
        Cancel
      </OutlineButton>
      <FillButton
        disabled={selectedAddressIds.length === 0}
        className="full-width mt-1"
        onClick={() => onAddressesSelected(selectedAddressIds)}
      >
        Done
      </FillButton>
    </Container>
  )
}

const Container = makeTypographyComponent('mt-2 flex-remaining-space flex-column overflow-hidden', 'div')
const AddressList = makeTypographyComponent('flex-remaining-space overflow-auto py-2 flex-column gap-2', 'div')
const AddressName = styled(makeTypographyComponent('large text-medium mb-xs', 'div'))`
  color: ${tealSet[70].hex};
  margin-top: -2px;
`
