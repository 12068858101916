import { ProviderDataDenormalizedColumnMetadata, ProviderDataDenormalizedColumnType } from '@nuna/api'

const globalTypes = `
    type HoursOfOperation = { 
        start: string,
        end: string
        timeZone: string
        dayOfWeek: string
    }

    /** some docus */ 
    type PatientAgeLimits = 'Children (Under 13)' | 'Adolescents (13-17)' | 'Younger Adults (18-39)' | 'Middle-aged Adults (40-65)' | 'Older Adults (65+)'

    declare var ctx: {
        hoursOfOperation: {
            sort: (hoursOfOperation: HoursOfOperation[], dayOfWeek?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday") => HoursOfOperation[]
        }
    }
`

const hoursOfOperation = `
    HoursOfOperation[]
`

const patientAgeLimits = `
    PatientAgeLimits[]
`

const columnTypesMap: Record<string, string> = {
  hoursOfOperation,
  patientAgeLimits,
}

function getColumnTypes(column: ProviderDataDenormalizedColumnMetadata) {
  if (columnTypesMap[column.key]) {
    return columnTypesMap[column.key]
  }

  switch (column.dataType) {
    case ProviderDataDenormalizedColumnType.Boolean:
      return 'boolean'
    case ProviderDataDenormalizedColumnType.Currency:
    case ProviderDataDenormalizedColumnType.Number:
      return 'number'
    default:
      return 'string'
  }
}

export function getCustomEvalTypeDeclarations(columns: ProviderDataDenormalizedColumnMetadata[]) {
  const types = columns.map(getColumnTypes)
  return `
     ${globalTypes}
      declare var value: ${types.length > 1 ? '[' : ''}${types.join(', ')} ${types.length > 1 ? ']' : ''};
    `
}
