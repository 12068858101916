import { styled } from '@mui/material'
import React, { HTMLAttributes, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom'

import { Logo, TabletPortrait, greySet } from '@nuna/tunic'

import { NavBar } from '../shared/NavBar/NavBar'
import { Sidebar } from '../shared/Sidebar/Sidebar'
import { HeaderHeight } from '../styles/sizes'

/**
 * Layout inspired by https://www.bitovi.com/blog/use-flexbox-to-create-a-sticky-header-and-sidebar-with-flexible-content
 */

interface Props {
  children: ReactNode
  containerProps?: HTMLAttributes<HTMLDivElement>
}

export function NavLayout({ children, containerProps = {} }: Props) {
  const isTabletPortrait = useMediaQuery({
    query: `(${TabletPortrait})`,
  })

  return (
    <Body>
      {isTabletPortrait && (
        <Header>
          <NavLink to="/">
            <Logo color="plum" height={36} />
          </NavLink>
          <NavBar />
        </Header>
      )}

      <MainLayout>
        {!isTabletPortrait && (
          <LeftColumn>
            <Sidebar />
          </LeftColumn>
        )}
        <RightColumn id="right-column-wrapper">
          <BottomRight {...containerProps}>{children}</BottomRight>
        </RightColumn>
      </MainLayout>
    </Body>
  )
}

const Body = styled('div')`
  height: 100%;
  overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px; /*removes default style*/
  box-sizing: border-box;
  width: 100%;
  position: relative;
`

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  background-color: ${greySet[0].hex};
  border-bottom: 1px solid ${greySet[15].hex};
  align-items: center;
  padding-left: 20px;
  color: white;
  height: ${HeaderHeight};
  width: 100%;
`

const MainLayout = styled('div')`
  display: flex;
  height: 100%;
`

const LeftColumn = styled('div')`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-shrink: 0; /*makes sure that content is not cut off in a smaller browser window*/
`

const RightColumn = styled('div')`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column; /*places the left and right headers above the bottom content*/
  background-color: white;
  width: 100%;
  overflow: auto;
  position: relative;
`

const BottomRight = styled('main')`
  flex-grow: 1; /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto; /*adds scroll to this container*/
  background-color: white;
  height: 100%; // Necessary for Safari 13
`
