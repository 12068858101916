import { PropsWithChildren, useContext } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import { createContext } from 'react'

import { OrganizationRelationship } from '@nuna/api'

import { MatchTypeIfDependent, MemberMatchPerson, UnlinkedPatientRecord } from './normalize-member-match-data'

export interface EmployeeMatch {
  employee?: MemberMatchPerson | null
  matchType?: MatchTypeIfDependent
  relationshipToOrganization?: OrganizationRelationship | null
}

type PatientEmployeeMatchMap = Map<MemberMatchPerson, EmployeeMatch>

interface EmployeeRecordMatchContextValues {
  isMatch: (patient: MemberMatchPerson, matchId: string) => boolean
  numberOfMatches: number
  patientEmployeeMatchMap: PatientEmployeeMatchMap
  removePatientEmployeeMatch: (patient: MemberMatchPerson) => void
  setInitialMatchState: (unlinkedPatients: UnlinkedPatientRecord[]) => void
  setPatientEmployeeMatch: (patient: MemberMatchPerson, employee: MemberMatchPerson) => void
  setPatientRelationship: (
    patient: MemberMatchPerson,
    relationshipToOrganization?: OrganizationRelationship | null,
  ) => void
}

const MemberMatchContext = createContext<EmployeeRecordMatchContextValues>(
  {} as unknown as EmployeeRecordMatchContextValues,
)

export function MemberMatchProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const [patientEmployeeMatchMap, setPatientEmployeeMatchMap] = useState<PatientEmployeeMatchMap>(new Map())
  const [numberOfMatches, setNumberOfMatches] = useState(0)

  useEffect(() => {
    setNumberOfMatches(
      Array.from(patientEmployeeMatchMap).filter(([, match]) => !!match.employee && !!match.relationshipToOrganization)
        .length,
    )
  }, [patientEmployeeMatchMap])

  const setPatientEmployeeMatch = useCallback(
    (patient: MemberMatchPerson, employee: MemberMatchPerson) => {
      const newMap = new Map(patientEmployeeMatchMap)
      const match = patientEmployeeMatchMap.get(patient) ?? {}
      newMap.set(patient, { ...match, employee })
      setPatientEmployeeMatchMap(newMap)
    },
    [patientEmployeeMatchMap],
  )

  const setPatientRelationship = useCallback(
    (patient: MemberMatchPerson, relationshipToOrganization?: OrganizationRelationship | null) => {
      const newMap = new Map(patientEmployeeMatchMap)
      const match = patientEmployeeMatchMap.get(patient) ?? {}
      newMap.set(patient, { ...match, relationshipToOrganization })
      setPatientEmployeeMatchMap(newMap)
    },
    [patientEmployeeMatchMap],
  )

  const removePatientEmployeeMatch = useCallback(
    (patient: MemberMatchPerson) => {
      const newMap = new Map(patientEmployeeMatchMap)
      const match = patientEmployeeMatchMap.get(patient) ?? {}
      newMap.set(patient, { ...match, employee: null })
      setPatientEmployeeMatchMap(newMap)
    },
    [patientEmployeeMatchMap],
  )

  const isMatch = useCallback(
    (patient: MemberMatchPerson, matchId: string) => {
      return patientEmployeeMatchMap.get(patient)?.employee?.id === matchId
    },
    [patientEmployeeMatchMap],
  )

  const setInitialMatchState = useCallback((unlinkedPatients: UnlinkedPatientRecord[]) => {
    const newMap: PatientEmployeeMatchMap = new Map()
    unlinkedPatients.forEach(patientInfo => {
      const match: EmployeeMatch = {
        matchType: patientInfo.matchTypeIfDependent,
        relationshipToOrganization: patientInfo.relationshipToOrganization,
      }
      if (patientInfo.suggestedMatch) {
        match.employee = patientInfo.suggestedMatch
      }
      newMap.set(patientInfo.patient, match)
    })
    setPatientEmployeeMatchMap(newMap)
  }, [])

  const values: EmployeeRecordMatchContextValues = {
    isMatch,
    numberOfMatches,
    patientEmployeeMatchMap,
    removePatientEmployeeMatch,
    setInitialMatchState,
    setPatientEmployeeMatch,
    setPatientRelationship,
  }
  return <MemberMatchContext.Provider value={values}>{children}</MemberMatchContext.Provider>
}

export function useMemberMatchContext() {
  return useContext(MemberMatchContext)
}
