export interface StateCounty {
  state: string
  county: string
}

export const PAYER_COUNTIES: StateCounty[] = [
  // CA
  { state: 'CA', county: 'Alameda' },
  { state: 'CA', county: 'Alpine' },
  { state: 'CA', county: 'Amador' },
  { state: 'CA', county: 'Butte' },
  { state: 'CA', county: 'Calaveras' },
  { state: 'CA', county: 'Colusa' },
  { state: 'CA', county: 'Contra Costa' },
  { state: 'CA', county: 'Del Norte' },
  { state: 'CA', county: 'El Dorado' },
  { state: 'CA', county: 'Fresno' },
  { state: 'CA', county: 'Glenn' },
  { state: 'CA', county: 'Humboldt' },
  { state: 'CA', county: 'Imperial' },
  { state: 'CA', county: 'Inyo' },
  { state: 'CA', county: 'Kern' },
  { state: 'CA', county: 'Kings' },
  { state: 'CA', county: 'Lake' },
  { state: 'CA', county: 'Lassen' },
  { state: 'CA', county: 'Los Angeles' },
  { state: 'CA', county: 'Madera' },
  { state: 'CA', county: 'Marin' },
  { state: 'CA', county: 'Mariposa' },
  { state: 'CA', county: 'Mendocino' },
  { state: 'CA', county: 'Merced' },
  { state: 'CA', county: 'Modoc' },
  { state: 'CA', county: 'Mono' },
  { state: 'CA', county: 'Monterey' },
  { state: 'CA', county: 'Napa' },
  { state: 'CA', county: 'Nevada' },
  { state: 'CA', county: 'Orange' },
  { state: 'CA', county: 'Placer' },
  { state: 'CA', county: 'Plumas' },
  { state: 'CA', county: 'Riverside' },
  { state: 'CA', county: 'Sacramento' },
  { state: 'CA', county: 'San Benito' },
  { state: 'CA', county: 'San Bernardino' },
  { state: 'CA', county: 'San Diego' },
  { state: 'CA', county: 'San Francisco' },
  { state: 'CA', county: 'San Joaquin' },
  { state: 'CA', county: 'San Luis Obispo' },
  { state: 'CA', county: 'San Mateo' },
  { state: 'CA', county: 'Santa Barbara' },
  { state: 'CA', county: 'Santa Clara' },
  { state: 'CA', county: 'Santa Cruz' },
  { state: 'CA', county: 'Shasta' },
  { state: 'CA', county: 'Sierra' },
  { state: 'CA', county: 'Siskiyou' },
  { state: 'CA', county: 'Solano' },
  { state: 'CA', county: 'Sonoma' },
  { state: 'CA', county: 'Stanislaus' },
  { state: 'CA', county: 'Sutter' },
  { state: 'CA', county: 'Tehama' },
  { state: 'CA', county: 'Trinity' },
  { state: 'CA', county: 'Tulare' },
  { state: 'CA', county: 'Tuolumne' },
  { state: 'CA', county: 'Ventura' },
  { state: 'CA', county: 'Yolo' },
  { state: 'CA', county: 'Yuba' },
  // ID
  { state: 'ID', county: 'Ada' },
  { state: 'ID', county: 'Adams' },
  { state: 'ID', county: 'Bannock' },
  { state: 'ID', county: 'Bear Lake' },
  { state: 'ID', county: 'Benewah' },
  { state: 'ID', county: 'Bingham' },
  { state: 'ID', county: 'Blaine' },
  { state: 'ID', county: 'Boise' },
  { state: 'ID', county: 'Bonner' },
  { state: 'ID', county: 'Bonneville' },
  { state: 'ID', county: 'Boundary' },
  { state: 'ID', county: 'Butte' },
  { state: 'ID', county: 'Camas' },
  { state: 'ID', county: 'Canyon' },
  { state: 'ID', county: 'Caribou' },
  { state: 'ID', county: 'Cassia' },
  { state: 'ID', county: 'Clark' },
  { state: 'ID', county: 'Clearwater' },
  { state: 'ID', county: 'Custer' },
  { state: 'ID', county: 'Elmore' },
  { state: 'ID', county: 'Franklin' },
  { state: 'ID', county: 'Fremont' },
  { state: 'ID', county: 'Gem' },
  { state: 'ID', county: 'Gooding' },
  { state: 'ID', county: 'Idaho' },
  { state: 'ID', county: 'Jefferson' },
  { state: 'ID', county: 'Jerome' },
  { state: 'ID', county: 'Kootenai' },
  { state: 'ID', county: 'Latah' },
  { state: 'ID', county: 'Lemhi' },
  { state: 'ID', county: 'Lewis' },
  { state: 'ID', county: 'Lincoln' },
  { state: 'ID', county: 'Madison' },
  { state: 'ID', county: 'Minidoka' },
  { state: 'ID', county: 'Nez Perce' },
  { state: 'ID', county: 'Oneida' },
  { state: 'ID', county: 'Owyhee' },
  { state: 'ID', county: 'Payette' },
  { state: 'ID', county: 'Power' },
  { state: 'ID', county: 'Shoshone' },
  { state: 'ID', county: 'Teton' },
  { state: 'ID', county: 'Twin Falls' },
  { state: 'ID', county: 'Valley' },
  { state: 'ID', county: 'Washington' },
  // KS
  { state: 'KS', county: 'Allen' },
  { state: 'KS', county: 'Anderson' },
  { state: 'KS', county: 'Atchison' },
  { state: 'KS', county: 'Barber' },
  { state: 'KS', county: 'Barton' },
  { state: 'KS', county: 'Bourbon' },
  { state: 'KS', county: 'Brown' },
  { state: 'KS', county: 'Butler' },
  { state: 'KS', county: 'Chase' },
  { state: 'KS', county: 'Chautauqua' },
  { state: 'KS', county: 'Cherokee' },
  { state: 'KS', county: 'Cheyenne' },
  { state: 'KS', county: 'Clark' },
  { state: 'KS', county: 'Clay' },
  { state: 'KS', county: 'Cloud' },
  { state: 'KS', county: 'Coffey' },
  { state: 'KS', county: 'Comanche' },
  { state: 'KS', county: 'Cowley' },
  { state: 'KS', county: 'Crawford' },
  { state: 'KS', county: 'Decatur' },
  { state: 'KS', county: 'Dickinson' },
  { state: 'KS', county: 'Doniphan' },
  { state: 'KS', county: 'Douglas' },
  { state: 'KS', county: 'Edwards' },
  { state: 'KS', county: 'Elk' },
  { state: 'KS', county: 'Ellis' },
  { state: 'KS', county: 'Ellsworth' },
  { state: 'KS', county: 'Finney' },
  { state: 'KS', county: 'Ford' },
  { state: 'KS', county: 'Franklin' },
  { state: 'KS', county: 'Geary' },
  { state: 'KS', county: 'Gove' },
  { state: 'KS', county: 'Graham' },
  { state: 'KS', county: 'Grant' },
  { state: 'KS', county: 'Gray' },
  { state: 'KS', county: 'Greeley' },
  { state: 'KS', county: 'Greenwood' },
  { state: 'KS', county: 'Hamilton' },
  { state: 'KS', county: 'Harper' },
  { state: 'KS', county: 'Harvey' },
  { state: 'KS', county: 'Haskell' },
  { state: 'KS', county: 'Hodgeman' },
  { state: 'KS', county: 'Jackson' },
  { state: 'KS', county: 'Jefferson' },
  { state: 'KS', county: 'Jewell' },
  { state: 'KS', county: 'Johnson' },
  { state: 'KS', county: 'Kearny' },
  { state: 'KS', county: 'Kingman' },
  { state: 'KS', county: 'Kiowa' },
  { state: 'KS', county: 'Labette' },
  { state: 'KS', county: 'Lane' },
  { state: 'KS', county: 'Leavenworth' },
  { state: 'KS', county: 'Lincoln' },
  { state: 'KS', county: 'Linn' },
  { state: 'KS', county: 'Logan' },
  { state: 'KS', county: 'Lyon' },
  { state: 'KS', county: 'Marion' },
  { state: 'KS', county: 'Marshall' },
  { state: 'KS', county: 'McPherson' },
  { state: 'KS', county: 'Meade' },
  { state: 'KS', county: 'Miami' },
  { state: 'KS', county: 'Mitchell' },
  { state: 'KS', county: 'Montgomery' },
  { state: 'KS', county: 'Morris' },
  { state: 'KS', county: 'Morton' },
  { state: 'KS', county: 'Nemaha' },
  { state: 'KS', county: 'Neosho' },
  { state: 'KS', county: 'Ness' },
  { state: 'KS', county: 'Norton' },
  { state: 'KS', county: 'Osage' },
  { state: 'KS', county: 'Osborne' },
  { state: 'KS', county: 'Ottawa' },
  { state: 'KS', county: 'Pawnee' },
  { state: 'KS', county: 'Phillips' },
  { state: 'KS', county: 'Pottawatomie' },
  { state: 'KS', county: 'Pratt' },
  { state: 'KS', county: 'Rawlins' },
  { state: 'KS', county: 'Reno' },
  { state: 'KS', county: 'Republic' },
  { state: 'KS', county: 'Rice' },
  { state: 'KS', county: 'Riley' },
  { state: 'KS', county: 'Rooks' },
  { state: 'KS', county: 'Rush' },
  { state: 'KS', county: 'Russell' },
  { state: 'KS', county: 'Saline' },
  { state: 'KS', county: 'Scott' },
  { state: 'KS', county: 'Sedgwick' },
  { state: 'KS', county: 'Seward' },
  { state: 'KS', county: 'Shawnee' },
  { state: 'KS', county: 'Sheridan' },
  { state: 'KS', county: 'Sherman' },
  { state: 'KS', county: 'Smith' },
  { state: 'KS', county: 'Stafford' },
  { state: 'KS', county: 'Stanton' },
  { state: 'KS', county: 'Stevens' },
  { state: 'KS', county: 'Sumner' },
  { state: 'KS', county: 'Thomas' },
  { state: 'KS', county: 'Trego' },
  { state: 'KS', county: 'Wabaunsee' },
  { state: 'KS', county: 'Wallace' },
  { state: 'KS', county: 'Washington' },
  { state: 'KS', county: 'Wichita' },
  { state: 'KS', county: 'Wilson' },
  { state: 'KS', county: 'Woodson' },
  { state: 'KS', county: 'Wyandotte' },
  // MO
  { state: 'MO', county: 'Adair' },
  { state: 'MO', county: 'Andrew' },
  { state: 'MO', county: 'Atchison' },
  { state: 'MO', county: 'Audrain' },
  { state: 'MO', county: 'Barry' },
  { state: 'MO', county: 'Barton' },
  { state: 'MO', county: 'Bates' },
  { state: 'MO', county: 'Benton' },
  { state: 'MO', county: 'Bollinger' },
  { state: 'MO', county: 'Boone' },
  { state: 'MO', county: 'Buchanan' },
  { state: 'MO', county: 'Butler' },
  { state: 'MO', county: 'Caldwell' },
  { state: 'MO', county: 'Callaway' },
  { state: 'MO', county: 'Camden' },
  { state: 'MO', county: 'Cape Girardeau' },
  { state: 'MO', county: 'Carroll' },
  { state: 'MO', county: 'Carter' },
  { state: 'MO', county: 'Cass' },
  { state: 'MO', county: 'Cedar' },
  { state: 'MO', county: 'Chariton' },
  { state: 'MO', county: 'Christian' },
  { state: 'MO', county: 'Clark' },
  { state: 'MO', county: 'Clay' },
  { state: 'MO', county: 'Clinton' },
  { state: 'MO', county: 'Cole' },
  { state: 'MO', county: 'Cooper' },
  { state: 'MO', county: 'Crawford' },
  { state: 'MO', county: 'Dade' },
  { state: 'MO', county: 'Dallas' },
  { state: 'MO', county: 'Daviess' },
  { state: 'MO', county: 'DeKalb' },
  { state: 'MO', county: 'Dent' },
  { state: 'MO', county: 'Douglas' },
  { state: 'MO', county: 'Dunklin' },
  { state: 'MO', county: 'Franklin' },
  { state: 'MO', county: 'Gasconade' },
  { state: 'MO', county: 'Gentry' },
  { state: 'MO', county: 'Greene' },
  { state: 'MO', county: 'Grundy' },
  { state: 'MO', county: 'Harrison' },
  { state: 'MO', county: 'Henry' },
  { state: 'MO', county: 'Hickory' },
  { state: 'MO', county: 'Holt' },
  { state: 'MO', county: 'Howard' },
  { state: 'MO', county: 'Howell' },
  { state: 'MO', county: 'Iron' },
  { state: 'MO', county: 'Jackson' },
  { state: 'MO', county: 'Jasper' },
  { state: 'MO', county: 'Jefferson' },
  { state: 'MO', county: 'Johnson' },
  { state: 'MO', county: 'Knox' },
  { state: 'MO', county: 'Laclede' },
  { state: 'MO', county: 'Lafayette' },
  { state: 'MO', county: 'Lawrence' },
  { state: 'MO', county: 'Lewis' },
  { state: 'MO', county: 'Lincoln' },
  { state: 'MO', county: 'Linn' },
  { state: 'MO', county: 'Livingston' },
  { state: 'MO', county: 'McDonald' },
  { state: 'MO', county: 'Macon' },
  { state: 'MO', county: 'Madison' },
  { state: 'MO', county: 'Maries' },
  { state: 'MO', county: 'Marion' },
  { state: 'MO', county: 'Mercer' },
  { state: 'MO', county: 'Miller' },
  { state: 'MO', county: 'Mississippi' },
  { state: 'MO', county: 'Moniteau' },
  { state: 'MO', county: 'Monroe' },
  { state: 'MO', county: 'Montgomery' },
  { state: 'MO', county: 'Morgan' },
  { state: 'MO', county: 'New Madrid' },
  { state: 'MO', county: 'Newton' },
  { state: 'MO', county: 'Nodaway' },
  { state: 'MO', county: 'Oregon' },
  { state: 'MO', county: 'Osage' },
  { state: 'MO', county: 'Ozark' },
  { state: 'MO', county: 'Pemiscot' },
  { state: 'MO', county: 'Perry' },
  { state: 'MO', county: 'Pettis' },
  { state: 'MO', county: 'Phelps' },
  { state: 'MO', county: 'Pike' },
  { state: 'MO', county: 'Platte' },
  { state: 'MO', county: 'Polk' },
  { state: 'MO', county: 'Pulaski' },
  { state: 'MO', county: 'Putnam' },
  { state: 'MO', county: 'Ralls' },
  { state: 'MO', county: 'Randolph' },
  { state: 'MO', county: 'Ray' },
  { state: 'MO', county: 'Reynolds' },
  { state: 'MO', county: 'Ripley' },
  { state: 'MO', county: 'Saline' },
  { state: 'MO', county: 'Schuyler' },
  { state: 'MO', county: 'Scotland' },
  { state: 'MO', county: 'Scott' },
  { state: 'MO', county: 'Shannon' },
  { state: 'MO', county: 'Shelby' },
  { state: 'MO', county: 'St. Charles' },
  { state: 'MO', county: 'St. Clair' },
  { state: 'MO', county: 'St. Francois' },
  { state: 'MO', county: 'St. Louis' },
  { state: 'MO', county: 'Ste. Genevieve' },
  { state: 'MO', county: 'Stoddard' },
  { state: 'MO', county: 'Stone' },
  { state: 'MO', county: 'Sullivan' },
  { state: 'MO', county: 'Taney' },
  { state: 'MO', county: 'Texas' },
  { state: 'MO', county: 'Vernon' },
  { state: 'MO', county: 'Warren' },
  { state: 'MO', county: 'Washington' },
  { state: 'MO', county: 'Wayne' },
  { state: 'MO', county: 'Webster' },
  { state: 'MO', county: 'Worth' },
  { state: 'MO', county: 'Wright' },
  { state: 'MO', county: 'St. Louis City' },
  // PA
  { state: 'PA', county: 'Adams' },
  { state: 'PA', county: 'Allegheny' },
  { state: 'PA', county: 'Armstrong' },
  { state: 'PA', county: 'Beaver' },
  { state: 'PA', county: 'Bedford' },
  { state: 'PA', county: 'Berks' },
  { state: 'PA', county: 'Blair' },
  { state: 'PA', county: 'Bradford' },
  { state: 'PA', county: 'Bucks' },
  { state: 'PA', county: 'Butler' },
  { state: 'PA', county: 'Cambria' },
  { state: 'PA', county: 'Cameron' },
  { state: 'PA', county: 'Carbon' },
  { state: 'PA', county: 'Centre' },
  { state: 'PA', county: 'Chester' },
  { state: 'PA', county: 'Clarion' },
  { state: 'PA', county: 'Clearfield' },
  { state: 'PA', county: 'Clinton' },
  { state: 'PA', county: 'Columbia' },
  { state: 'PA', county: 'Crawford' },
  { state: 'PA', county: 'Cumberland' },
  { state: 'PA', county: 'Dauphin' },
  { state: 'PA', county: 'Delaware' },
  { state: 'PA', county: 'Elk' },
  { state: 'PA', county: 'Erie' },
  { state: 'PA', county: 'Fayette' },
  { state: 'PA', county: 'Forest' },
  { state: 'PA', county: 'Franklin' },
  { state: 'PA', county: 'Fulton' },
  { state: 'PA', county: 'Greene' },
  { state: 'PA', county: 'Huntingdon' },
  { state: 'PA', county: 'Indiana' },
  { state: 'PA', county: 'Jefferson' },
  { state: 'PA', county: 'Juniata' },
  { state: 'PA', county: 'Lackawanna' },
  { state: 'PA', county: 'Lancaster' },
  { state: 'PA', county: 'Lawrence' },
  { state: 'PA', county: 'Lebanon' },
  { state: 'PA', county: 'Lehigh' },
  { state: 'PA', county: 'Luzerne' },
  { state: 'PA', county: 'Lycoming' },
  { state: 'PA', county: 'McKean' },
  { state: 'PA', county: 'Mercer' },
  { state: 'PA', county: 'Mifflin' },
  { state: 'PA', county: 'Monroe' },
  { state: 'PA', county: 'Montgomery' },
  { state: 'PA', county: 'Montour' },
  { state: 'PA', county: 'Northampton' },
  { state: 'PA', county: 'Northumberland' },
  { state: 'PA', county: 'Perry' },
  { state: 'PA', county: 'Philadelphia' },
  { state: 'PA', county: 'Pike' },
  { state: 'PA', county: 'Potter' },
  { state: 'PA', county: 'Schuylkill' },
  { state: 'PA', county: 'Snyder' },
  { state: 'PA', county: 'Somerset' },
  { state: 'PA', county: 'Sullivan' },
  { state: 'PA', county: 'Susquehanna' },
  { state: 'PA', county: 'Tioga' },
  { state: 'PA', county: 'Union' },
  { state: 'PA', county: 'Venango' },
  { state: 'PA', county: 'Warren' },
  { state: 'PA', county: 'Washington' },
  { state: 'PA', county: 'Wayne' },
  { state: 'PA', county: 'Westmoreland' },
  { state: 'PA', county: 'Wyoming' },
  { state: 'PA', county: 'York' },
  // WA
  { state: 'WA', county: 'Adams' },
  { state: 'WA', county: 'Asotin' },
  { state: 'WA', county: 'Benton' },
  { state: 'WA', county: 'Chelan' },
  { state: 'WA', county: 'Clallam' },
  { state: 'WA', county: 'Clark' },
  { state: 'WA', county: 'Columbia' },
  { state: 'WA', county: 'Cowlitz' },
  { state: 'WA', county: 'Douglas' },
  { state: 'WA', county: 'Ferry' },
  { state: 'WA', county: 'Franklin' },
  { state: 'WA', county: 'Garfield' },
  { state: 'WA', county: 'Grant' },
  { state: 'WA', county: 'Grays Harbor' },
  { state: 'WA', county: 'Island' },
  { state: 'WA', county: 'Jefferson' },
  { state: 'WA', county: 'King' },
  { state: 'WA', county: 'Kitsap' },
  { state: 'WA', county: 'Kittitas' },
  { state: 'WA', county: 'Klickitat' },
  { state: 'WA', county: 'Lewis' },
  { state: 'WA', county: 'Lincoln' },
  { state: 'WA', county: 'Mason' },
  { state: 'WA', county: 'Okanogan' },
  { state: 'WA', county: 'Pacific' },
  { state: 'WA', county: 'Pend Oreille' },
  { state: 'WA', county: 'Pierce' },
  { state: 'WA', county: 'San Juan' },
  { state: 'WA', county: 'Skagit' },
  { state: 'WA', county: 'Skamania' },
  { state: 'WA', county: 'Snohomish' },
  { state: 'WA', county: 'Spokane' },
  { state: 'WA', county: 'Stevens' },
  { state: 'WA', county: 'Thurston' },
  { state: 'WA', county: 'Wahkiakum' },
  { state: 'WA', county: 'Walla Walla' },
  { state: 'WA', county: 'Whatcom' },
  { state: 'WA', county: 'Whitman' },
  { state: 'WA', county: 'Yakima' },
  // VA
  { state: 'VA', county: 'Accomack' },
  { state: 'VA', county: 'Albemarle' },
  { state: 'VA', county: 'Alexandria' },
  { state: 'VA', county: 'Alleghany' },
  { state: 'VA', county: 'Amelia' },
  { state: 'VA', county: 'Amherst' },
  { state: 'VA', county: 'Appomattox' },
  { state: 'VA', county: 'Arlington' },
  { state: 'VA', county: 'Augusta' },
  { state: 'VA', county: 'Bath' },
  { state: 'VA', county: 'Bedford' },
  { state: 'VA', county: 'Bland' },
  { state: 'VA', county: 'Botetourt' },
  { state: 'VA', county: 'Brunswick' },
  { state: 'VA', county: 'Buchanan' },
  { state: 'VA', county: 'Buckingham' },
  { state: 'VA', county: 'Campbell' },
  { state: 'VA', county: 'Caroline' },
  { state: 'VA', county: 'Carroll' },
  { state: 'VA', county: 'Charles City' },
  { state: 'VA', county: 'Charlotte' },
  { state: 'VA', county: 'Chesterfield' },
  { state: 'VA', county: 'Clarke' },
  { state: 'VA', county: 'Craig' },
  { state: 'VA', county: 'Culpeper' },
  { state: 'VA', county: 'Cumberland' },
  { state: 'VA', county: 'Dickenson' },
  { state: 'VA', county: 'Dinwiddie' },
  { state: 'VA', county: 'Essex' },
  { state: 'VA', county: 'Fairfax' },
  { state: 'VA', county: 'Fauquier' },
  { state: 'VA', county: 'Floyd' },
  { state: 'VA', county: 'Fluvanna' },
  { state: 'VA', county: 'Franklin' },
  { state: 'VA', county: 'Frederick' },
  { state: 'VA', county: 'Giles' },
  { state: 'VA', county: 'Gloucester' },
  { state: 'VA', county: 'Greene' },
  { state: 'VA', county: 'Greensville' },
  { state: 'VA', county: 'Halifax' },
  { state: 'VA', county: 'Hampton' },
  { state: 'VA', county: 'Hanover' },
  { state: 'VA', county: 'Henrico' },
  { state: 'VA', county: 'Henry' },
  { state: 'VA', county: 'Highland' },
  { state: 'VA', county: 'Isle of Wight' },
  { state: 'VA', county: 'James City' },
  { state: 'VA', county: 'King and Queen' },
  { state: 'VA', county: 'King George' },
  { state: 'VA', county: 'King William' },
  { state: 'VA', county: 'Lancaster' },
  { state: 'VA', county: 'Lee' },
  { state: 'VA', county: 'Loudoun' },
  { state: 'VA', county: 'Louisa' },
  { state: 'VA', county: 'Lunenburg' },
  { state: 'VA', county: 'Madison' },
  { state: 'VA', county: 'Mathews' },
  { state: 'VA', county: 'Mecklenburg' },
  { state: 'VA', county: 'Middlesex' },
  { state: 'VA', county: 'Montgomery' },
  { state: 'VA', county: 'Nelson' },
  { state: 'VA', county: 'New Kent' },
  { state: 'VA', county: 'Northampton' },
  { state: 'VA', county: 'Northumberland' },
  { state: 'VA', county: 'Nottoway' },
  { state: 'VA', county: 'Orange' },
  { state: 'VA', county: 'Page' },
  { state: 'VA', county: 'Patrick' },
  { state: 'VA', county: 'Pittsylvania' },
  { state: 'VA', county: 'Poquoson' },
  { state: 'VA', county: 'Prince Edward' },
  { state: 'VA', county: 'Prince George' },
  { state: 'VA', county: 'Prince William' },
  { state: 'VA', county: 'Pulaski' },
  { state: 'VA', county: 'Rappahannock' },
  { state: 'VA', county: 'Richmond' },
  { state: 'VA', county: 'Roanoke' },
  { state: 'VA', county: 'Rockbridge' },
  { state: 'VA', county: 'Rockingham' },
  { state: 'VA', county: 'Russell' },
  { state: 'VA', county: 'Scott' },
  { state: 'VA', county: 'Shenandoah' },
  { state: 'VA', county: 'Smyth' },
  { state: 'VA', county: 'Southampton' },
  { state: 'VA', county: 'Spotsylvania' },
  { state: 'VA', county: 'Stafford' },
  { state: 'VA', county: 'Surry' },
  { state: 'VA', county: 'Sussex' },
  { state: 'VA', county: 'Tazewell' },
  { state: 'VA', county: 'Warren' },
  { state: 'VA', county: 'Washington' },
  { state: 'VA', county: 'Westmoreland' },
  { state: 'VA', county: 'Wise' },
  { state: 'VA', county: 'Wythe' },
  { state: 'VA', county: 'York' },
]

export function payerCountyToString(county: StateCounty) {
  return `${county.county}:${county.state}`
}

export function stringToPayerCounty(value: string): StateCounty {
  const [county, state] = value.split(':')
  return { county, state }
}
