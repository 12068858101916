import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { useMemo, useState } from 'react'

import { ProviderStatusOption, ProviderStatusOptions } from '../utils/util'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<ProviderStatusOption, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  onChange: (states: ProviderStatusOption[]) => void
  label?: string
}

export function ProviderStatusSelect({ onChange, value, label = 'Status', ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredStatuses = useMemo<ProviderStatusOption[]>(() => {
    const searcher = new FuzzySearch(ProviderStatusOptions, ['label'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return ProviderStatusOptions
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={state => state.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={filteredStatuses}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField name="selectdSpecialty" label={label} {...params} />}
    />
  )
}
