import { compact, startCase } from 'lodash'
import { useMemo } from 'react'

import { ProviderEnrollmentSearchOptions, ProviderEnrollmentStatus, ProviderFilterInput } from '@nuna/api'

import { Filter, Filters } from '../../../shared/filters'
import { useAssigneeFilter } from '../../../shared/filters/common-filter-hooks/useAssigneeFilter'
import { useInsurancePayerFilter } from '../../../shared/filters/common-filter-hooks/useInsurancePayerFilter'
import { useProviderFilter } from '../../../shared/filters/common-filter-hooks/useProviderFilter'
import { useUrlFilters } from '../../../shared/filters/hooks/useUrlFilters'

export type ProviderEnrollmentFilterValues = Omit<
  ProviderEnrollmentSearchOptions,
  'searchText' | 'taskAssigneeLoginId' | 'assignedToMe'
> & {
  assignee?: 'me' | 'none' | string
}

interface ProviderEnrollmentFiltersProps<T> {
  urlFilterService: ReturnType<typeof useUrlFilters<T>>
  fixedFilters?: ProviderEnrollmentFilterValues
}

const searchProviderFilters: ProviderFilterInput = {}
const providerFilterOverrides: Partial<Filter> = { urlParam: 'pe_providerId' }
const insurancePayerFilterOverrides: Partial<Filter> = { urlParam: 'pe_insurancePayerId' }
const assigneeFilterOverrides: Partial<Filter> = { urlParam: 'pe_assignee' }

export function ProviderEnrollmentFilters<T extends Record<string, unknown>>({
  urlFilterService,
  fixedFilters,
}: ProviderEnrollmentFiltersProps<T>) {
  const providerFilter = useProviderFilter(searchProviderFilters, providerFilterOverrides)
  const insurancePayerFilter = useInsurancePayerFilter(insurancePayerFilterOverrides)
  const assigneeFilter = useAssigneeFilter(assigneeFilterOverrides)

  const filterOptions: Filter[] = useMemo<Filter[]>(
    () =>
      compact([
        fixedFilters?.providerId ? undefined : providerFilter,
        fixedFilters?.insurancePayerId ? undefined : insurancePayerFilter,
        {
          key: 'status',
          urlParam: 'pe_status',
          label: 'Effective Status',
          async: false,
          getOptions: async () =>
            Object.values(ProviderEnrollmentStatus).map(status => ({ value: status, label: startCase(status) })),
          getSelectedValueOptions: async value => [{ label: startCase(value as string), value }],
        },
        {
          key: 'payerStatus',
          urlParam: 'pe_payerStatus',
          label: 'Payer Status',
          async: false,
          getOptions: async () =>
            Object.values(ProviderEnrollmentStatus).map(status => ({ value: status, label: startCase(status) })),
          getSelectedValueOptions: async value => [{ label: startCase(value as string), value }],
        },
        assigneeFilter,
      ]),
    [providerFilter, insurancePayerFilter, assigneeFilter, fixedFilters],
  )
  return (
    <div>
      <Filters<T> filters={filterOptions} urlFilterService={urlFilterService} />
    </div>
  )
}
