import { styled } from '@mui/material'

import { InternalAppointment } from '@nuna/api'
import { appointmentService } from '@nuna/core'
import { Avatar, Card, CardProps, csx } from '@nuna/tunic'

type User = { avatarUrl?: string | null; firstName: string; lastName: string }
type BaseAppointment = Pick<InternalAppointment, 'currentStatus' | 'startDatetime' | 'endDatetime'>
type AppointmentForClient = BaseAppointment & { provider: User }
type AppointmentForProvider = BaseAppointment & { client: User }

type Props = CardProps &
  (
    | { audience: 'provider'; appointment: AppointmentForProvider }
    | { audience: 'client'; appointment: AppointmentForClient }
  )

export function BasicAppointmentCard({ audience, appointment, className = '', ...props }: Props) {
  const isCanceled = appointmentService.isAppointmentCanceled(appointment)

  let avatarUrl: string | undefined | null
  let name = ''
  if (audience === 'client') {
    avatarUrl = appointment.provider.avatarUrl
    name = `${appointment.provider.firstName} ${appointment.provider.lastName}`
  } else if (audience === 'provider') {
    avatarUrl = appointment.client.avatarUrl
    name = `${appointment.client.firstName} ${appointment.client.lastName}`
  }

  return (
    <Card className={csx(['v-align pr-3', className])} depth={-1} {...props}>
      <Avatar className={csx(['flex-static', { 'fs-exclude': audience === 'provider' }])} url={avatarUrl} size="sm" />

      <div className="ml-1">
        <Time
          className={csx([{ 'strike-through text-secondary': isCanceled, 'text-default': !isCanceled }, 'text-medium'])}
        >
          {appointmentService.appointmentTimeSpan(appointment)}
        </Time>

        <div className="caption text-medium">with {name}</div>
      </div>
    </Card>
  )
}

const Time = styled('div')`
  line-height: 1.55;
`
