import { styled } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { has } from 'lodash'
import { useEffect, useState } from 'react'

import { InsurancePolicySearchOptions } from '@nuna/api'
import { ClientSelect, type SelectClient } from '@nuna/client'
import { PayerSelect } from '@nuna/coverage'
import {
  Card,
  DropdownButton,
  GhostButton,
  IconClose,
  IconFilter,
  IconToggleButton,
  Menu,
  MenuItem,
  MenuItemStack,
  csx,
} from '@nuna/tunic'

import { MAX_SELECTION_COUNT } from '../hooks/useInsurancePolicyTableRowSelection'
import { InsurancePolicyTablePlanSelect } from './InsurancePolicyTablePlanSelect'
import { UpdatePolicyPayerDrawer } from './UpdatePolicyPayerDrawer'

interface Props {
  selectedIds: string[]
  filterValues: InsurancePolicySearchOptions
  fixedFilters?: Omit<InsurancePolicySearchOptions, 'searchText'>
  setFilterVal: (key: keyof InsurancePolicySearchOptions, value: unknown) => void
  clearFilters: () => void
}
export function InsurancePolicyTableToolbar({
  filterValues,
  fixedFilters = {},
  selectedIds,
  setFilterVal,
  clearFilters,
}: Props) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [changePoliciesDrawerOpen, setUpdatePoliciesDrawerOpen] = useState(false)
  const [filtersOpen, setFilersOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState<SelectClient | null>(null)

  useEffect(() => setFilterVal('patientId', selectedClient?.id), [selectedClient, setFilterVal])

  const hasFiltersApplied = Object.entries(filterValues)
    .filter(([key]) => !has(fixedFilters, key))
    .some(([, value]) => !!value)

  return (
    <>
      <div className="bottom-align ml-auto pb-xs">
        <div className="caption text-seconary italic mb-xs">
          {selectedIds.length} {selectedIds.length === 1 ? 'policy' : 'policies'} selected (You can select up to{' '}
          {MAX_SELECTION_COUNT})
        </div>
        <DropdownButton
          className="ml-2"
          disabled={selectedIds.length === 0}
          onClick={e => setMenuAnchorEl(e.currentTarget)}
          isActive={!!menuAnchorEl}
        >
          Actions
        </DropdownButton>
        <Menu
          open={!!menuAnchorEl}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MenuItemStack>
            <MenuItem button onClick={() => setUpdatePoliciesDrawerOpen(true)}>
              Change payer and/or plan
            </MenuItem>
          </MenuItemStack>
        </Menu>
        <IconToggleButton
          className="ml-2"
          tooltip="Filter"
          size="md"
          isActive={filtersOpen}
          onClick={() => setFilersOpen(!filtersOpen)}
        >
          <IconFilter />
        </IconToggleButton>
      </div>
      <AnimatePresence>
        {filtersOpen && (
          <motion.div
            key="medication-followup"
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            <StyledCard depth={-0.5} className="p-2 my-1 v-align gap-2">
              {!has(fixedFilters, 'insurancePayerPlanId') && (
                <InsurancePolicyTablePlanSelect
                  className="filter-input"
                  value={filterValues.insurancePayerPlanId}
                  onChange={value => setFilterVal('insurancePayerPlanId', value)}
                  payerId={fixedFilters.insurancePayerId}
                />
              )}

              {!has(fixedFilters, 'insurancePayerId') && (
                <PayerSelect
                  label="Payer"
                  className="filter-input"
                  value={filterValues.insurancePayerId}
                  onChange={newValue => setFilterVal('insurancePayerId', newValue?.id)}
                />
              )}

              <ClientSelect
                className="filter-input"
                value={selectedClient}
                onChange={value => setSelectedClient(value)}
              />
              <GhostButton
                className={csx([{ hidden: !hasFiltersApplied }, 'ml-auto clear-button'])}
                variant="secondary"
                onClick={clearFilters}
              >
                <IconClose size={14} className="mr-xs" />
                Clear all
              </GhostButton>
            </StyledCard>
          </motion.div>
        )}
      </AnimatePresence>
      <UpdatePolicyPayerDrawer
        isOpen={changePoliciesDrawerOpen}
        onClose={() => setUpdatePoliciesDrawerOpen(false)}
        policyIds={selectedIds}
      />
    </>
  )
}

const StyledCard = styled(Card)`
  .filter-input {
    width: 300px;
  }

  .clear-button {
    &.hidden {
      visibility: hidden;
    }
  }
`
