import moment from 'moment'

import { FullPayerContractAmountFragment, FullPayerContractFragment, PayerContractAmountInput } from '@nuna/api'

export function formatContractDate(date: string) {
  return moment(date).format('MMM D, YYYY')
}

export function getContractFromParam(id: string, contracts: FullPayerContractFragment[] = []) {
  return contracts.find(contract => contract.id === id)
}

export function getOngoingContract(contracts: FullPayerContractFragment[] = []) {
  return contracts.find(contract => moment().isBetween(contract.startDate, contract.endDate))
}

export function contractLabel(contract: Pick<FullPayerContractFragment, 'startDate' | 'endDate'>) {
  return `${formatContractDate(contract.startDate)} - ${formatContractDate(contract.endDate)}`
}

export function transformPayerContractAmounts(amounts: FullPayerContractAmountFragment[]): PayerContractAmountInput[] {
  return amounts.map(value => ({
    contractedAmount: value.contractedAmount,
    cptCodeId: value.cptCodeId,
    credentialType: value.credentialType,
    id: value.id,
    payerContractId: value.payerContractId,
    placeOfService: value.placeOfService,
    licenseTypeId: value.licenseTypeId,
  }))
}
