import { styled } from '@mui/material'
import { isObject } from 'lodash'
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'

import { usePayerRosterTransformationPreviewLazyQuery, useProviderDataDenormalizedDistinctValuesQuery } from '@nuna/api'
import { FormProviderSelect } from '@nuna/common'
import { body2, borderGrey, eggshell, fontSize, makeTypographyComponent } from '@nuna/tunic'

import { ColumnFormValues } from '../PayerRosterDefinition.types'

interface PreviewRow {
  rawValue: unknown
  transformedValue?: string
}

interface ColumnValuePreviewProps extends HTMLAttributes<HTMLDivElement> {
  column: ColumnFormValues
  showTransformedValues: boolean
}

export function ColumnValuePreview({ column, showTransformedValues, ...props }: ColumnValuePreviewProps) {
  const [providerId, setProviderId] = useState<string | undefined>()
  const [transformedValues, setTransformedValues] = useState<string[]>([])

  const [queryTransformed] = usePayerRosterTransformationPreviewLazyQuery()

  const { data: distinctRawData } = useProviderDataDenormalizedDistinctValuesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { columnKey: column?.dataMapping?.[0] ?? '', limit: 3, providerId },
    skip: column?.dataMapping?.length !== 1,
  })

  const rawValues = useMemo(() => distinctRawData?.providerDataDenormalizedDistinctValues ?? [], [distinctRawData])

  const getTransformedValues = useCallback(async () => {
    if (showTransformedValues) {
      const results = await Promise.all(
        rawValues.map(v =>
          queryTransformed({
            variables: {
              dataValue: v,
              columnConfig: {
                transformation: column.transformation,
                selector: column.selector,
                value: column.value,
                valueWhenEmpty: column.valueWhenEmpty,
              },
            },
            fetchPolicy: 'network-only',
          }),
        ),
      )

      if (results.some(r => r.error)) {
        return
      }

      if (results.every(r => r.data && r.data.payerRosterTransformationPreview)) {
        setTransformedValues(results.map(r => r.data?.payerRosterTransformationPreview ?? ''))
      }
    } else {
      setTransformedValues([])
    }
  }, [
    column.selector,
    column.transformation,
    column.value,
    column.valueWhenEmpty,
    queryTransformed,
    rawValues,
    showTransformedValues,
  ])

  useEffect(() => {
    getTransformedValues()
  }, [getTransformedValues])

  const previewRows: PreviewRow[] = useMemo(() => {
    if (rawValues.length === 0) {
      return []
    }

    if (transformedValues.length === 0) {
      return rawValues.map(rawValue => ({ rawValue }))
    }

    return rawValues.map((rawValue, index) => {
      return { rawValue, transformedValue: transformedValues[index] }
    })
  }, [rawValues, transformedValues])

  return (
    <Container {...props}>
      <div className="v-align mb-2 space-between">
        <h2 className="large mb-0 sans-serif text-medium text-secondary">Preview</h2>
        <FormProviderSelect
          style={{ width: 300 }}
          value={providerId}
          onChange={provider => setProviderId(provider?.id)}
        />
      </div>
      {previewRows.length > 0 && (
        <PreviewTable>
          <thead>
            <tr>
              <th>Before transformation</th>
              {transformedValues.length > 0 && <th>After transformation</th>}
            </tr>
          </thead>
          <tbody>
            {previewRows.map(({ rawValue, transformedValue }) => (
              <tr>
                <td>
                  <StyledPre>{rawValueToDisplay(rawValue)}</StyledPre>
                </td>
                {transformedValue && (
                  <td>
                    <StyledPre>{transformedValue}</StyledPre>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </PreviewTable>
      )}
    </Container>
  )
}

function rawValueToDisplay(rawValue: unknown): string {
  if (!rawValue) {
    return ''
  }

  if (isObject(rawValue)) {
    return JSON.stringify(rawValue, undefined, 2)
  }

  return rawValue.toString()
}

const Container = styled(makeTypographyComponent('p-2', 'div'))`
  background-color: ${eggshell};
  border-radius: var(--border-radius-sm) var(--border-radius-sm);
`

const PreviewTable = styled('table')`
  th {
    font-weight: 400;
    font-size: ${fontSize.caption};
    color: ${body2};
  }
  td,
  th {
    text-align: left;
    vertical-align: top;
    padding-right: var(--spacing-2);
    padding-bottom: var(--spacing-1);
  }

  tr {
    td {
      &:first-of-type {
        width: 100px;
      }
    }
  }
`

const StyledPre = styled('pre')`
  border: 1px solid ${borderGrey};
  background-color: ${eggshell};
  border-radius: var(--border-radius-sm);
  margin: 0;
  padding: 0.5rem;
  max-height: 350px;
  overflow-y: auto;
`
