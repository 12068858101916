import { ApolloError } from '@apollo/client/errors'
import { styled } from '@mui/material'
import { TextField } from '@mui/material'
import { FC, memo } from 'react'
import TreeView, { INode, flattenTree } from 'react-accessible-treeview'
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils'

import { FillButton, ListItem, Skeleton, makeTypographyComponent, salmonSet } from '@nuna/tunic'

type ListInputProps = {
  label?: string
  limit?: number
  loadInitial?: boolean
  entityId?: string | null
  inputSize?: 'small' | 'medium'
}

type ListActionProps<TData> = {
  onClick?: (item: TData & ListItemProps) => void
  onChange?: (changeValue: string | (TData & ListItemProps) | (TData & ListItemProps)[]) => void
  onLoading?: (loading: boolean) => void
  onError?: (error: ApolloError | undefined) => void
  onSelect?: (item: TData & ListItemProps, collection: (TData & ListItemProps)[]) => void
  onDeselect?: (item: TData & ListItemProps, collection: (TData & ListItemProps)[]) => void
}

type ListEventProps = {
  isLoading?: boolean
  isFetching?: boolean
  isError?: boolean
}

type ColumnProps = {
  title: string
  wantsSearch?: boolean
  wantsFilter?: boolean
}

export type ListItemComponent<TData> = ListActionProps<TData> &
  ListEventProps & {
    items?: (TData & ListItemProps)[] | null
    selectedItems?: (TData & ListItemProps)[] | null
    listItem?: TData & ListItemProps
    canSelectMultiple?: boolean
    isSingleItem?: boolean
  }

type ListInputComponent = ListInputProps & ListEventProps & Pick<ListActionProps<never>, 'onChange'>

type ListProps<TData> = {
  Component?: ListComponentType<TData>
  props: ListItemComponent<TData>
}

type SearchProps = {
  Component?: SearchComponentType
  props: ListInputComponent
}

type FilterProps = {
  Component?: FilterComponentType
  props: ListInputComponent
}

export type ListBoxAggregateProps<TPrimaryData, TSecondaryData> = {
  primaryData?: (TPrimaryData & ListItemProps)[]
  secondaryData?: (TSecondaryData & ListItemProps)[]
}

type AggregateListComponentActions<TData, KData> = ListEventProps & {
  onListChange?: (items: ((TData | KData) & ListItemProps)[] | null) => void
}

type AggregateListComponent<TData, KData> = ListInputProps &
  AggregateListComponentActions<TData, KData> & {
    primaryData?: (TData & ListItemProps)[]
    secondaryData?: (KData & ListItemProps)[]
  }

type AggregateListProps<TData, KData> = {
  Component?: AggregateListComponentType<TData, KData>
  props: AggregateListComponent<TData, KData>
}

type DataColumn<TData> = ColumnProps & {
  listProps: ListProps<TData>
  searchProps?: SearchProps
  filterProps?: FilterProps
}

type AggregateColumn<TData, KData> = ColumnProps & {
  listProps: AggregateListProps<TData, KData>
  searchProps?: SearchProps
  filterProps?: FilterProps
}

type ListBoxColumns<TPrimaryData, TSecondaryData> = {
  primary: DataColumn<TPrimaryData>
  secondary: DataColumn<TSecondaryData>
  aggregate: AggregateColumn<TPrimaryData, TSecondaryData>
}

type ListBoxAction = {
  actionTitle: string
  onAction: () => void
  isDisabled?: boolean
}

type ListBoxActions = {
  primary: ListBoxAction
  secondary?: ListBoxAction
  tertiary?: ListBoxAction
}

export type AggregateListComponentType<TData, KData> = FC<AggregateListComponent<TData, KData>>
export type ListComponentType<TData> = FC<ListItemComponent<TData>>
export type SearchComponentType = FC<ListInputComponent & { type: 'search' }>
export type FilterComponentType = FC<ListInputComponent & { type: 'filter' }>
export type ListItemProps = { key: string; value: string }
export type ListBoxProps<TPrimaryData, TSecondaryData> = {
  columns: ListBoxColumns<TPrimaryData, TSecondaryData>
  actions: ListBoxActions
}

function ListSelect<TData>({ Component, props }: ListProps<TData>) {
  const { items, selectedItems, isSingleItem, isLoading, canSelectMultiple, onSelect, onDeselect, onClick } = props

  const handleDeselect = (item: TData & ListItemProps) => {
    const remainingItems = selectedItems?.filter(selectedItem => selectedItem.key !== item.key) || []
    if (onDeselect) {
      onDeselect(item, remainingItems)
    }
  }

  const handleClick = (item: TData & ListItemProps) => {
    const isSelected = selectedItems?.some(selectedItem => selectedItem.key === item.key) || false
    if (isSelected) {
      handleDeselect(item)
      return
    }
    if (canSelectMultiple) {
      if (onSelect) {
        onSelect(item, [...(selectedItems || []), item])
      }
      return
    }
    if (onDeselect) {
      onDeselect(item, [...(selectedItems || [])])
    }
  }

  const handleListItemChange = (item: TData & ListItemProps) => {
    if (onClick) {
      onClick(item)
    }
  }

  return (
    <ScrollableList className={'list-select'}>
      {isLoading && <Skeleton />}
      {!isLoading &&
        items &&
        items.map(item => {
          const classes = selectedItems?.some(selectedItem => selectedItem.key === item.key)
            ? 'list-select-item isSelected'
            : 'list-select-item'

          return (
            <ListItem
              className={classes}
              key={item.key}
              onClick={e => {
                e.stopPropagation()
                if (!isSingleItem) handleClick(item)
              }}
            >
              {Component ? <Component listItem={item} onClick={handleListItemChange} /> : item.value}
            </ListItem>
          )
        })}
    </ScrollableList>
  )
}

function ListFilter({ onChange, label, inputSize }: ListInputComponent) {
  const handleChange = (e: { target: { value: string } }) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <TextField label={label ?? 'Filter'} id="outlined-size-small" onChange={handleChange} size={inputSize ?? 'small'} />
  )
}

// https://github.com/dgreene1/react-accessible-treeview
function ListBoxAggregateTree<TPrimaryData, TSecondaryData>(
  props: ListBoxAggregateProps<TPrimaryData, TSecondaryData>,
) {
  const { primaryData, secondaryData } = props

  const hasPrimaryData = !!primaryData?.length
  const hasSecondaryData = !!secondaryData?.length

  const showTree = hasPrimaryData && hasSecondaryData

  let data: INode<IFlatMetadata>[] = []

  if (showTree) {
    const aggregateData = {
      name: '',
      children: primaryData.map(item => {
        return {
          name: item.value.toString(),
          children: secondaryData.map(subItem => ({ name: subItem.value.toString() })),
        }
      }),
    }

    data = flattenTree(aggregateData)
  }

  return (
    <ScrollableList className={'list-select'}>
      {showTree && (
        <TreeView
          data={data}
          className="basic"
          aria-label="basic example tree"
          nodeRenderer={({ element, getNodeProps, level }) => (
            <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
              {element.name}
            </div>
          )}
        />
      )}
    </ScrollableList>
  )
}

function ListColumn<TData>(props: DataColumn<TData>) {
  const { title, wantsSearch, wantsFilter, listProps, filterProps, searchProps } = props
  const DataSearch = searchProps?.Component && searchProps.Component
  const DataFilter = filterProps?.Component ? filterProps.Component : ListFilter

  return (
    <ListBoxFormColumn>
      <ListBoxFormColumnHeader>{title}</ListBoxFormColumnHeader>
      {wantsSearch && DataSearch && <DataSearch {...{ ...searchProps?.props, type: 'search' }} />}
      {wantsFilter && <DataFilter {...{ ...filterProps?.props, type: 'filter' }} />}
      <ListSelect {...listProps} />
    </ListBoxFormColumn>
  )
}

function AggregateColumn<TData, KData>(props: AggregateColumn<TData, KData>) {
  const { title, wantsFilter, wantsSearch, listProps, searchProps, filterProps } = props
  const AggregateComponent = listProps.Component ?? ListBoxAggregateTree
  const AggregateSearch = searchProps?.Component && memo(searchProps.Component)
  const AggregateFilter = filterProps?.Component ? memo(filterProps.Component) : ListFilter

  return (
    <ListBoxFormColumn>
      <ListBoxFormColumnHeader>{title}</ListBoxFormColumnHeader>
      {wantsSearch && AggregateSearch && <AggregateSearch {...{ ...searchProps.props, type: 'search' }} />}
      {wantsFilter && <AggregateFilter {...{ ...filterProps?.props, type: 'filter' }} />}
      <AggregateComponent {...listProps.props} />
    </ListBoxFormColumn>
  )
}

export function ListBox<TPrimaryData, TSecondaryData>(props: ListBoxProps<TPrimaryData, TSecondaryData>) {
  const { columns, actions } = props
  const { primary: primaryProps, secondary: secondaryProps, aggregate: aggregateProps } = columns
  const { primary: primaryAction, secondary: secondaryAction, tertiary: tertiaryAction } = actions

  return (
    <ListBoxForm>
      <ListBoxFormColumns>
        <ListColumn {...primaryProps} />
        <ListColumn {...secondaryProps} />
        <AggregateColumn {...aggregateProps} />
      </ListBoxFormColumns>
      <ListBoxFormActions>
        <FillButton disabled={primaryAction.isDisabled} onClick={() => primaryAction.onAction()}>
          {primaryAction.actionTitle}
        </FillButton>
        {secondaryAction && (
          <FillButton disabled={secondaryAction.isDisabled} onClick={() => secondaryAction.onAction()}>
            {secondaryAction.actionTitle}
          </FillButton>
        )}
        {tertiaryAction && (
          <FillButton disabled={tertiaryAction.isDisabled} onClick={() => tertiaryAction.onAction()}>
            {tertiaryAction.actionTitle}
          </FillButton>
        )}
      </ListBoxFormActions>
    </ListBoxForm>
  )
}

const ListBoxForm = styled(makeTypographyComponent('flex-column full-height', 'div'))`
  flex: 1;
`
const ListBoxFormColumns = styled(makeTypographyComponent('flex-row full-height overflow-hidden', 'div'))`
  justify-content: space-evenly;
  height: calc(100% - var(--spacing-5));
  gap: var(--spacing-2);
`
const ListBoxFormColumn = styled(makeTypographyComponent('flex-column overflow-hidden', 'div'))`
  flex: 0 1 33%;
  gap: var(--spacing-1);
`
const ListBoxFormColumnHeader = styled(makeTypographyComponent('v-align', 'h6'))``
const ListBoxFormActions = styled(makeTypographyComponent('flex-row overflow-hidden', 'div'))`
  justify-content: flex-end;
  height: var(--spacing-5);
`
const ScrollableList = styled(makeTypographyComponent('overflow-auto', 'ul'))`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: var(--spacing-1);
    cursor: pointer;

    &.isSelected {
      background-color: ${salmonSet[5].hex};
    }
  }
`
