import { styled } from '@mui/material'
import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise'
import { compact, isArray, isNil, isPlainObject } from 'lodash'
import moment from 'moment'
import { useState } from 'react'

import {
  ProviderDataDenormalizedColumnMetadata,
  ProviderDataDenormalizedValidationIssue,
  ProviderDataDenormalizedValidationIssueType,
  useGenerateProviderDataDenormalizedMutation,
  useSyncProviderInfoFromVerifiableMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import {
  CopyButton,
  Dialog,
  IconButton,
  IconExport,
  IconLeading,
  IconLoading,
  IconMaximize,
  IconPencil,
  IconSwitch,
  Menu,
  MenuItem,
  MenuItemStack,
  Popover,
  StatusLabel,
  greySet,
  toast,
} from '@nuna/tunic'

import { UnstructuredDataPrint } from '../../../shared/UnstructuredDataPrint'
import { providerDetail } from '../../../util/routes'
import { EditPracticeAddressDialog } from '../../practice-address/components/EditPracticeAddressDialog'
import { FieldKey } from './MasterRosterContextProvider'

const VERIFIABLE_PROVIDER_URL = 'https://discovery.verifiable.com/provider/'

interface MasterRosterCellClassParams extends CellClassParams {
  colDef: MasterRosterColDef
}
interface MasterRosterColDef extends ColDef {
  nullable?: boolean
  cellClassRules?: MasterRosterCellClassRules
}

interface MasterRosterCellClassRules {
  [cssClassName: string]: ((params: MasterRosterCellClassParams) => boolean) | string
}

type Metadata = Pick<ProviderDataDenormalizedColumnMetadata, 'key' | 'name' | 'dataType' | 'required'>

export const DEFAULT_MASTER_ROSTER_COLUMN_DEF: MasterRosterColDef = {
  flex: 1,
  minWidth: 220,
  editable: false,
  sortable: false,
  resizable: true,
  cellClassRules: conditionalCellClasses(),
  nullable: false,
}

export const GROUP_COLUMN_DEF: MasterRosterColDef = {
  field: 'providerId',
  headerName: 'Tava Provider',
  pinned: 'left',
  rowGroup: true,
  hide: true,
  cellClass: 'provider-cell',
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    innerRenderer: ProviderCellRenderer,
  },
}

export function buildMasterRosterColumnDefs(
  metadata: Metadata[],
  expanded: boolean,
  showValidationIssues: boolean,
): MasterRosterColDef[] {
  const buildColDef = (
    field: FieldKey,
    partial: Omit<MasterRosterColDef, 'field' | 'headerName'> = {},
  ): MasterRosterColDef => {
    const fieldMetaData = metadata.find(meta => meta.key === field)
    const { name = field, required = false } = fieldMetaData ?? {}
    return { field, headerName: name, nullable: !required, ...partial }
  }

  return compact([
    expanded
      ? {
          field: 'providerId',
          headerName: 'Provider',
          pinned: 'left',
          rowGroup: false,
          hide: false,
          cellRenderer: ProviderCellRenderer,
          cellRendererParams: { expanded: true },
        }
      : {
          field: 'providerId',
          rowGroup: true,
          hide: true,
        },
    buildColDef('createdAt', { valueFormatter: dateFormatter }),
    buildColDef('updatedAt', { valueFormatter: dateFormatter }),
    buildColDef('credentialInitialCredentialedDate', { valueFormatter: dateFormatter }),
    buildColDef('credentialCurrentCredentialedDate', { valueFormatter: dateFormatter, nullable: true }),
    buildColDef('employmentStatus'),
    buildColDef('employmentEffectiveDate', { valueFormatter: dateFormatter }),
    buildColDef('employmentTermDate', { valueFormatter: dateFormatter, nullable: true }),
    buildColDef('firstName'),
    buildColDef('lastName'),
    buildColDef('middleInitial', { nullable: true }),
    buildColDef('middleName', { nullable: true }),
    buildColDef('primaryLicenseTypeAbbrv'),
    buildColDef('taxonomy'),
    buildColDef('npi'),
    buildColDef('ssn'),
    buildColDef('caqhId'),
    buildColDef('dob', { valueFormatter: dateFormatter }),
    buildColDef('languagesSpoken', { cellRenderer: jsonCellRenderer }),
    buildColDef('gender'),
    buildColDef('ethnicities', { cellRenderer: jsonCellRenderer }),
    buildColDef('pradOrgEmail'),
    buildColDef('specialties', { cellRenderer: jsonCellRenderer }),
    buildColDef('focuses', { cellRenderer: jsonCellRenderer }),
    buildColDef('acceptingNewPatients', { valueFormatter: yesNoFormatter }),
    buildColDef('pcpOrSpecialist'),
    buildColDef('boardCertificationCertifyingBoardName', { nullable: true }),
    buildColDef('boardCertificationCertificationSpecialty', { nullable: true }),
    buildColDef('boardCertificationStatus', { nullable: true }),
    buildColDef('boardCertificationEffectiveDate', { valueFormatter: dateFormatter, nullable: true }),
    buildColDef('boardCertificationExpirationDate', { valueFormatter: dateFormatter, nullable: true }),
    buildColDef('boardCertificationCertifyingBoardNameTwo', { nullable: true }),
    buildColDef('boardCertificationCertificationSpecialtyTwo', { nullable: true }),
    buildColDef('boardCertificationStatusTwo', { nullable: true }),
    buildColDef('boardCertificationEffectiveDateTwo', { valueFormatter: dateFormatter, nullable: true }),
    buildColDef('boardCertificationExpirationDateTwo', { valueFormatter: dateFormatter, nullable: true }),
    buildColDef('primaryLicenseNumber'),
    buildColDef('primaryLicenseState'),
    buildColDef('primaryLicenseTypeSpecialty'),
    buildColDef('primaryLicenseTypeSubSpecialty'),
    buildColDef('primaryLicenseIssueDate', { valueFormatter: dateFormatter }),
    buildColDef('primaryLicenseExpirationDate', { valueFormatter: dateFormatter }),
    buildColDef('otherLicenses', { cellRenderer: JsonExpansionCellRenderer }),
    buildColDef('controlledSubstancesLicenseNumber', { cellRenderer: JsonExpansionCellRenderer, nullable: true }),
    buildColDef('medicareLicenseNumber', { nullable: true }),
    buildColDef('medicareLicenseState', { nullable: true }),
    buildColDef('medicaidLicenseNumber', { nullable: true }),
    buildColDef('medicaidLicenseState', { nullable: true }),
    buildColDef('education', { cellRenderer: JsonExpansionCellRenderer }),
    buildColDef('addressName'),
    buildColDef('addressPrimaryPracticeLocation', {
      valueFormatter: params => (params.value ? 'Primary' : 'Secondary'),
    }),
    buildColDef('addressInPersonLocation', { valueFormatter: yesNoFormatter }),
    buildColDef('addressStreetLineOne'),
    buildColDef('addressStreetLineTwo'),
    buildColDef('addressStreetFull'),
    buildColDef('addressCity'),
    buildColDef('addressState'),
    buildColDef('addressZipCode'),
    buildColDef('addressCounty'),
    buildColDef('addressPhone'),
    buildColDef('addressFax'),
    buildColDef('addressTimezone'),
    buildColDef('addressAdaPublicParkingAccessible', { valueFormatter: yesNoFormatter }),
    buildColDef('addressAdaPublicTransitAccessible', { valueFormatter: yesNoFormatter }),
    buildColDef('addressAdaWheelchairAccessible', { valueFormatter: yesNoFormatter }),
    buildColDef('otherAddresses', { cellRenderer: JsonExpansionCellRenderer }),
    buildColDef('hoursOfOperation', { cellRenderer: JsonExpansionCellRenderer }),
    buildColDef('pradOrgStreet'),
    buildColDef('pradOrgCity'),
    buildColDef('pradOrgState'),
    buildColDef('pradOrgZipCode'),
    buildColDef('pradOrgPhone'),
    buildColDef('pradRemitStreet'),
    buildColDef('pradRemitCity'),
    buildColDef('pradRemitState'),
    buildColDef('pradRemitZipCode'),
    buildColDef('pradRemitPhone'),
    buildColDef('pradBillingName'),
    buildColDef('pradBillingStreet'),
    buildColDef('pradBillingCity'),
    buildColDef('pradBillingState'),
    buildColDef('pradBillingZipCode'),
    buildColDef('pradBillingPhone'),
    buildColDef('pradBillingFax'),
    buildColDef('pradBillingEmail'),
    buildColDef('pradBillingContact'),
    buildColDef('taxEntityId'),
    buildColDef('taxEntityOrganizationName'),
    buildColDef('taxEntityNpi'),
    buildColDef('taxEntityEin'),
    showValidationIssues
      ? {
          field: 'validationIssues',
          pinned: 'right',
          cellRenderer: ValidationIssuesCellRenderer,
          cellRendererParams: { metadata },
        }
      : null,
  ])
}

function dateFormatter(params: ValueFormatterParams) {
  if (!params.value) return ''
  return moment(params.value).format('ll')
}

function yesNoFormatter(params: ValueFormatterParams) {
  return params.value ? 'Yes' : 'No'
}

function jsonCellRenderer(params: ICellRendererParams) {
  return <UnstructuredDataPrint data={params.value} />
}

function conditionalCellClasses(): MasterRosterCellClassRules | undefined {
  return {
    empty: params => !params.colDef.nullable && (isNil(params.value) || params.value === ''),
  }
}

function ProviderCellRenderer(params: ICellRendererParams & { expanded?: boolean }) {
  const [editAddressId, setEditAddressId] = useState<string | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | undefined>()
  const [generateProviderDataDenormalized, { loading: refreshLoading }] = useGenerateProviderDataDenormalizedMutation()
  const [syncVerifiable, { loading: syncVerifiableLoading }] = useSyncProviderInfoFromVerifiableMutation()

  const handleOpenInVerifiableClick = () => {
    window.open(`${VERIFIABLE_PROVIDER_URL}${params.data.verifiableId}`)
  }

  const handleRefreshProviderData = async () => {
    try {
      await generateProviderDataDenormalized({
        variables: {
          providerId: params.data.providerId,
        },
      })

      params.api.refreshServerSideStore()
      toast.success(`Provider data refreshed for ${params.data.firstName}  ${params.data.lastName}`)
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a problem refreshing the data'))
    }
  }

  const syncVerifiableData = async () => {
    try {
      await syncVerifiable({ variables: { providerId: params.data.providerId } })
      await handleRefreshProviderData()
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a syncing with verifiable'))
    }
  }

  return (
    <div className="v-align flex-remaining-space">
      <a href={providerDetail(params.value)} target="_blank" rel="noreferrer" className="provider-link">
        {params.data.firstName} {params.data.lastName}
      </a>
      {(params.node.group || params.expanded) && (
        <IconButton
          tooltip="Actions"
          small
          className="text-secondary ml-auto"
          onClick={e => setMenuAnchorEl(e.currentTarget)}
        >
          <IconLeading style={{ transform: 'rotate(90deg)' }} size={16} />
        </IconButton>
      )}

      <Menu
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItemStack>
          <MenuItem button onClick={handleOpenInVerifiableClick}>
            Open in Verifiable <IconExport className="ml-auto" size={16} />
          </MenuItem>
          <MenuItem button onClick={() => setEditAddressId(params.node.data.addressId)}>
            Edit Address
            <IconPencil className="ml-auto" />
          </MenuItem>
          <MenuItem button onClick={syncVerifiableData} disabled={syncVerifiableLoading}>
            Sync Verifiable Data
            {refreshLoading || syncVerifiableLoading ? (
              <IconLoading className="ml-auto rotate" size={18} />
            ) : (
              <IconSwitch className="ml-auto" size={18} />
            )}
          </MenuItem>
          {!params.data.locked && (
            <MenuItem button onClick={handleRefreshProviderData} disabled={refreshLoading}>
              Refresh Provider Data{' '}
              {refreshLoading ? (
                <IconLoading className="ml-auto rotate" size={18} />
              ) : (
                <IconSwitch className="ml-auto" size={18} />
              )}
            </MenuItem>
          )}
        </MenuItemStack>

        <div className="caption text-secondary mt-4">Provider ID:</div>
        <div>
          <CopyButton className="full-width" style={{ maxWidth: 250 }} value={params.data.providerId} />
        </div>
      </Menu>
      <EditPracticeAddressDialog
        addressId={editAddressId}
        onCancel={() => setEditAddressId(null)}
        onSaved={handleRefreshProviderData}
      />
    </div>
  )
}

function ValidationIssuesCellRenderer({ metadata, ...params }: ICellRendererParams & { metadata: Metadata[] }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const issues: ProviderDataDenormalizedValidationIssue[] = params.value ?? []
  if (issues.length === 0) {
    return null
  }
  return (
    <div
      className="full-width"
      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      onMouseEnter={e => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(undefined)}
    >
      {issues.length === 1 && <ValidationIssueDisplay issue={issues[0]} metadata={metadata} />}
      {issues.length > 1 && <StatusLabel intent="urgent">{issues.length} issues. Hover to learn more.</StatusLabel>}
      <Popover open={!!anchorEl} anchorEl={anchorEl} style={{ pointerEvents: 'none' }}>
        <div className="flex-column gap-1">
          {issues.map(issue => (
            <ValidationIssueDisplay issue={issue} metadata={metadata} />
          ))}
        </div>
      </Popover>
    </div>
  )
}

interface ValidationIssueDisplayProps {
  issue: ProviderDataDenormalizedValidationIssue
  metadata: Metadata[]
}

function ValidationIssueDisplay({ issue, metadata }: ValidationIssueDisplayProps) {
  const fieldMetadata = metadata.find(meta => meta.key === issue.key)
  const readableType = (() => {
    switch (issue.type) {
      case ProviderDataDenormalizedValidationIssueType.MissingRequiredColumn:
        return 'Missing required field'
      case ProviderDataDenormalizedValidationIssueType.FailedRegex:
        return 'Failed regex validation'
      case ProviderDataDenormalizedValidationIssueType.InvalidDate:
        return 'Invalid date'
      case ProviderDataDenormalizedValidationIssueType.InvalidArray:
        return 'Invalid array'
      case ProviderDataDenormalizedValidationIssueType.InvalidBoolean:
        return 'Invalid boolean'
      case ProviderDataDenormalizedValidationIssueType.InvalidNumber:
        return 'Invalid number'
      case ProviderDataDenormalizedValidationIssueType.InvalidString:
        return 'Invalid string'
      default:
        return undefined
    }
  })()

  if (!readableType) return null

  return (
    <StatusLabel intent="urgent">
      {fieldMetadata?.name}: {readableType}
    </StatusLabel>
  )
}

function JsonExpansionCellRenderer(params: ICellRendererParams) {
  const [dialogOpen, setDialogOpen] = useState(false)
  if (!params.value) {
    return null
  }

  if (isPlainObject(params.value) && Object.keys(params.value).length === 0) {
    return null
  }

  if (isArray(params.value) && params.value.length === 0) {
    return null
  }

  return (
    <>
      <IconButton tooltip="Click to expand" onClick={() => setDialogOpen(true)}>
        <IconMaximize />
      </IconButton>
      <Dialog isOpen={dialogOpen} onClose={() => setDialogOpen(false)}>
        <JsonPrintContainer className="p-2">
          <UnstructuredDataPrint data={params.value} />
        </JsonPrintContainer>
      </Dialog>
    </>
  )
}

const JsonPrintContainer = styled('div')`
  dl {
    display: grid;
    grid-template-columns: 240px auto;

    dt {
      padding: var(--spacing-half);
      &:nth-of-type(odd) {
        background-color: ${greySet[5].hex};
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
    dd {
      display: grid;
      padding: var(--spacing-half);
      margin-left: 0;
      font-weight: 500;

      &:nth-of-type(odd) {
        background-color: ${greySet[5].hex};
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
    }
  }
`
