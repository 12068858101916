import { useMemo } from 'react'

import { ProviderCredentialingStatus, ProviderDataDenormalizedSearchOptions, ProviderFilterInput } from '@nuna/api'

import { Filter, Filters } from '../../../../shared/filters'
import { useProviderFilter } from '../../../../shared/filters/common-filter-hooks/useProviderFilter'
import { UrlFilterService } from '../../../../shared/filters/hooks/useUrlFilters'
import { useMasterRosterContext } from '../MasterRosterContextProvider'

interface MasterRosterFiltersProps {
  urlFilterService: UrlFilterService<ProviderDataDenormalizedSearchOptions>
}

const searchProviderFilters: ProviderFilterInput = {
  credentialingStatuses: [ProviderCredentialingStatus.Archived, ProviderCredentialingStatus.Credentialed],
}

export function MasterRosterFilters({ urlFilterService }: MasterRosterFiltersProps) {
  const { columnMetadata } = useMasterRosterContext()
  const providerFilter = useProviderFilter(searchProviderFilters)
  const filterOptions: Filter[] = useMemo<Filter[]>(
    () => [
      providerFilter,
      {
        key: 'nullColumns',
        multi: true,
        label: 'Columns with empty values',
        getOptions: async () => columnMetadata.map(col => ({ value: col.key, label: col.name })),
        getSelectedValueOptions: async value =>
          columnMetadata
            .filter(col => ((value as string[]) ?? []).includes(col.key))
            .map(col => ({ value: col.key, label: col.name })),
      },
      {
        key: 'isValid',
        label: 'Valid Data',
        dataType: 'boolean',
      },
    ],
    [columnMetadata, providerFilter],
  )
  return <Filters filters={filterOptions} urlFilterService={urlFilterService} />
}
