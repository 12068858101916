import moment from 'moment'

import { ProviderCredentialing, ProviderCredentialingStatus } from '@nuna/api'
import { providerService } from '@nuna/core'
import { Grid } from '@nuna/tunic'

import { CredentialingStatusForm } from '../Forms/CredentialingStatusForm'

type Props = {
  providerCredentialing: Pick<
    ProviderCredentialing,
    'status' | 'providerId' | 'initialCredentialedDate' | 'recredentialDueDate' | 'recredentialedDate' | 'id'
  >
}

export function CredentialingStatus({ providerCredentialing }: Props) {
  const { status, recredentialDueDate } = providerCredentialing
  const mostRecentCredentialingDate = providerService.mostRecentCredentialingDate(providerCredentialing)
  const showCredentialedDetails = status === ProviderCredentialingStatus.Credentialed && !!mostRecentCredentialingDate
  const showForm = !showCredentialedDetails

  return (
    <Grid container>
      {showCredentialedDetails && (
        <div className="v-align gap-2 text-secondary">
          <div className="v-align gap-1">
            <label>Most recent credentialed date:</label>
            <span className="text-medium">{mostRecentCredentialingDate.format('ll')}</span>
          </div>
          {recredentialDueDate && (
            <div className="v-align gap-1">
              <label>Recredentialing due date:</label>
              <span className="text-medium">{moment(recredentialDueDate).format('ll')}</span>
            </div>
          )}
        </div>
      )}

      {showForm && <CredentialingStatusForm providerCredentialing={providerCredentialing} />}
    </Grid>
  )
}
