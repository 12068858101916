import { type Provider } from '@nuna/api'
import { Grid } from '@nuna/tunic'

import { VerifiableSyncButton } from '../../../VerifiableSyncButton'
import { type CredentialingDialogTypes, CredentialingDialogs } from '../Credentialing'
import { DocumentsButton } from '../Elements/DocumentsButton'
import { PacketBuilderButton } from '../Elements/PacketBuilderButton'

type Props = {
  provider: Provider
  handleAction: (credentialingDialogType: CredentialingDialogTypes) => void
}
export const ChecklistActions = ({ provider, handleAction }: Props) => {
  return (
    <Grid container justifyContent={'flex-end'} spacing={2}>
      <DocumentsButton
        handleClick={() => {
          handleAction(CredentialingDialogs.documents)
        }}
      />
      <PacketBuilderButton
        handleClick={() => {
          handleAction(CredentialingDialogs.packetBuilder)
        }}
      />
      {provider.id && <VerifiableSyncButton providerId={provider.id} useIcon={true} />}
    </Grid>
  )
}
