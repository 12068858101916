import { sortBy } from 'lodash'

export type EventType =
  | 'patient.session_refunded'
  | 'patient.member-matched'
  | 'patient.invalid-eap-removed'
  | 'patient.deactivated'
  | 'patient.activated'

type ReadableEventType = Record<EventType, string>

const READABLE_EVENT_TYPE_MAP: ReadableEventType = {
  'patient.activated': 'Client Activated',
  'patient.deactivated': 'Client Deactivated',
  'patient.invalid-eap-removed': 'Invalid EAP Removed',
  'patient.member-matched': 'Member Matched',
  'patient.session_refunded': 'Session Refunded',
}

export type EventTypeOption = { label: string; value: EventType }

export function getReadableEventTypeOptions(): EventTypeOption[] {
  return sortBy(
    Object.entries(READABLE_EVENT_TYPE_MAP).map(([value, label]) => ({ value, label } as EventTypeOption)),
    option => option.label,
  )
}
