import { styled } from '@mui/material'

export function SectionTitle({ title }: { title: string }) {
  return <StyledTitle>{title}</StyledTitle>
}

const StyledTitle = styled('h2')`
  font-size: 1.5rem;
  margin: 0;
  display: flex;
  align-items: center;
`
