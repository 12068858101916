import { styled } from '@mui/material'

import { body1, borderGrey } from '@nuna/tunic'

export const ValueMappingTable = styled('table')`
  width: 100%;
  border-collapse: collapse;
  td {
    border-radius: 12px;
    vertical-align: bottom;
    padding: var(--spacing-1) var(--spacing-half);
    .input-container {
      padding-top: 0;
    }
    border: 1px solid ${borderGrey};
  }
  td:first-of-type {
    max-width: 200px;
    color: ${body1};
  }
`
