import { Autocomplete, AutocompleteProps, TextField as MUITextField } from '@mui/material'
import { isString } from 'lodash'
import { useMemo, useState } from 'react'

import { InsurancePayer, InsurancePayerPlanSelectOptionFragment, useInsurancePayersQuery } from '@nuna/api'

export type PayerOption = Pick<InsurancePayer, 'id' | 'name'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<PayerOption, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

export interface PayerSelectProps extends LimitedAutocompleteProps {
  value?: PayerOption | null | string
  label?: string
  onChange: (payerPlan: PayerOption | null) => void
  displayPayerName?: boolean
}

export function PayerSelect({ value, onChange, label, ...props }: PayerSelectProps) {
  const [searchValue, setSearchValue] = useState('')

  const { data, loading } = useInsurancePayersQuery({
    variables: {
      pagination: { limit: 1000 },
      filters: { name: searchValue },
    },
    context: { debounceKey: 'insurance-payers-debounce', debounceTimeout: 500 },
  })

  const options: PayerOption[] = useMemo(() => data?.insurancePayers.items ?? [], [data])

  const valueAsOption = useMemo(() => {
    if (isString(value)) {
      return options.find(plan => plan.id === value) ?? null
    }

    return value
  }, [options, value])

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => getOptionLabel(option)}
      loading={loading}
      onChange={(_event, payer) => {
        onChange(payer as InsurancePayerPlanSelectOptionFragment)
        setSearchValue('')
      }}
      renderInput={params => (
        <MUITextField
          {...params}
          label={label}
          placeholder="Filter by payer"
          onChange={e => setSearchValue(e.currentTarget.value)}
        />
      )}
      value={valueAsOption}
      {...props}
    />
  )
}

function getOptionLabel(option: PayerOption) {
  return option.name
}
