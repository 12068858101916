import { styled } from '@mui/material'
import TreeView, { INode, INodeRendererProps, flattenTree } from 'react-accessible-treeview'
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils'

import { ProviderEnrollmentStatus } from '@nuna/api'
import { StatusLabel, salmonSet } from '@nuna/tunic'

import { ListBoxAggregateProps } from '../ListBox/ListBox'

type TreeElement = INode<IFlatMetadata> & { status?: string }

// https://github.com/dgreene1/react-accessible-treeview
export function SelectedEnrollmentsTree<TPrimaryData, TSecondaryData>(
  props: ListBoxAggregateProps<
    TPrimaryData & { status: ProviderEnrollmentStatus },
    TSecondaryData & { status: ProviderEnrollmentStatus }
  >,
) {
  const { primaryData, secondaryData } = props

  const hasPrimaryData = !!primaryData?.length
  const hasSecondaryData = !!secondaryData?.length

  let treeData: TreeElement[] = []

  if (hasPrimaryData && hasSecondaryData) {
    const aggregateData = {
      name: '',
      children: primaryData.map(item => {
        return {
          name: item.value.toString(),
          metadata: { status: item.status },
          children: secondaryData.map(subItem => ({
            name: subItem.value.toString(),
            metadata: { status: subItem.status },
          })),
        }
      }),
    }

    treeData = flattenTree(aggregateData)
  }

  return treeData.length ? (
    <StyledTreeView
      data={treeData}
      className={'basic list-select'}
      aria-label="basic example tree"
      nodeRenderer={({
        element,
        getNodeProps,
        level,
      }: {
        element: TreeElement
        getNodeProps: INodeRendererProps['getNodeProps']
        level: number
      }) => {
        return (
          <StyledTreeItem {...getNodeProps()} style={{ paddingLeft: 8 * (level - 1) }}>
            {element.name}{' '}
            {element.metadata?.status && (
              <StatusLabel
                intent={element.metadata.status === ProviderEnrollmentStatus.OptedOut ? 'caution' : 'information'}
              >
                {element.metadata.status}
              </StatusLabel>
            )}
          </StyledTreeItem>
        )
      }}
    />
  ) : null
}

const StyledTreeItem = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: var(--spacing-1);
`

const StyledTreeView = styled(TreeView)`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;

  li {
    cursor: pointer;

    .tree-node-group {
      margin: 0;
      padding: 0;
    }

    .tree-leaf-list-item {
      cursor: default;
      list-style-type: none;
    }

    &.isSelected {
      background-color: ${salmonSet[5].hex};
    }
  }
`
