import { styled } from '@mui/material'
import { useMemo, useState } from 'react'

import {
  InsuranceNetworkPayerPlan,
  InsuranceNetworkPayerPlansQuery,
  InsurancePayer,
  InsurancePayerNetwork,
  InsurancePayerPlan,
  useInsuranceNetworkPayerPlansQuery,
  useRemoveInsuranceNetworkPayerPlanMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import {
  Confirm,
  GhostButtonLink,
  IconButton,
  IconPencil,
  IconPlus,
  IconTrash,
  Select,
  borderGrey,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { PayerPlanSubRoutes, PayerSubRoutes, payerSubroute, planSubroute } from '../../../util/routes'
import { useNetworkPlanDrawerSearchParams } from '../hooks/useNetworkPlanDrawerSearchParams'
import { NetworkHeader } from './NetworkHeader'
import { PayerNetworkPlanDrawer } from './PayerNetworkPlanDrawer'

interface Props {
  payerId: string
  network: Pick<InsurancePayerNetwork, 'name' | 'id'>
}

type NetworkPlanRow = Pick<
  InsuranceNetworkPayerPlan,
  'id' | 'state' | 'counties' | 'inclusionPattern' | 'exclusionPattern'
> & {
  insurancePayerPlan: Pick<InsurancePayerPlan, 'id' | 'name'>
  associatedPayer: Pick<InsurancePayer, 'id' | 'name'>
}

export function PayerNetworkPlans({ payerId, network }: Props) {
  const [networkPlanToDelete, setNetworkPlanToDelete] = useState<string | undefined>()
  const { data, loading } = useInsuranceNetworkPayerPlansQuery({
    variables: { searchInput: { insurancePayerId: payerId } },
  })
  const [removePlan] = useRemoveInsuranceNetworkPayerPlanMutation({ refetchQueries: ['InsuranceNetworkPayerPlans'] })
  const { openDrawer } = useNetworkPlanDrawerSearchParams()

  const handleDeletePlan = async (shouldDelete: boolean) => {
    if (shouldDelete && networkPlanToDelete) {
      try {
        await removePlan({ variables: { id: networkPlanToDelete } })
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'Unable to remove associated network plan'))
      }
    }

    setNetworkPlanToDelete(undefined)
  }

  const rowData = useMemo(() => mapRows(data?.insuranceNetworkPayerPlans), [data])

  const columns = useMemo<DataTableColumn<NetworkPlanRow>[]>(
    () => [
      {
        Header: 'Associated Payer',
        accessor: 'associatedPayer',
        Cell: ({ value }) => (
          <GhostButtonLink to={payerSubroute(value.id, PayerSubRoutes.Plans)}>{value.name}</GhostButtonLink>
        ),
      },
      {
        Header: 'Associated Plan',
        accessor: 'insurancePayerPlan',
        Cell: ({ value, row }) => (
          <GhostButtonLink to={planSubroute(value.id, row.original.associatedPayer.id, PayerPlanSubRoutes.Contracts)}>
            {value.name}
          </GhostButtonLink>
        ),
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'Counties',
        accessor: 'counties',
        Cell: ({ value }) => (
          <Select value="" style={{ minWidth: '104px' }}>
            {value?.map(v => {
              return <option key={v}>{v}</option>
            })}
          </Select>
        ),
      },
      {
        Header: 'Inclusion Pattern',
        accessor: 'inclusionPattern',
      },
      {
        Header: 'Exclusion Pattern',
        accessor: 'exclusionPattern',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value }) => (
          <span className="v-align">
            <IconButton tooltip="Edit" small onClick={() => openDrawer({ id: value, networkId: network.id })}>
              <IconPencil />
            </IconButton>
            <IconButton tooltip="Delete" small onClick={() => setNetworkPlanToDelete(value)}>
              <IconTrash />
            </IconButton>
          </span>
        ),
      },
    ],
    [network.id, openDrawer],
  )

  return (
    <div>
      <NetworkHeader network={network} />

      <PlansHeader>
        Associated Plans
        <IconButton tooltip="Associate plan" small onClick={() => openDrawer({ payerId, networkId: network.id })}>
          <IconPlus size={16} />
        </IconButton>
      </PlansHeader>
      <DataTable columns={columns} rowData={rowData} loading={loading} />
      <PayerNetworkPlanDrawer />
      <Confirm isOpen={!!networkPlanToDelete} onConfirm={handleDeletePlan}>
        Are you sure you want to remove plan?
      </Confirm>
    </div>
  )
}

function mapRows(raw?: InsuranceNetworkPayerPlansQuery['insuranceNetworkPayerPlans'] | null): NetworkPlanRow[] {
  if (!raw) {
    return []
  }

  return raw.map(record => ({ ...record, associatedPayer: record.insurancePayerPlan.insurancePayer }))
}

const PlansHeader = styled(makeTypographyComponent('pb-xs space-between v-align', 'h6'))`
  border-bottom: 1px solid ${borderGrey};
`
