import { styled } from '@mui/material'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  PatientPaymentMethodFragment,
  PatientTransactionType,
  SessionPaymentStatus,
  useBasicAppointmentQuery,
  useCollectSessionPaymentMutation,
} from '@nuna/api'
import { BasicAppointmentCard, useEffectOnce } from '@nuna/common'
import { errorService, numberService } from '@nuna/core'
import { PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import {
  Card,
  Drawer,
  DrawerProps,
  FillButton,
  IconButton,
  IconClose,
  IconSwitchRole,
  eggshell,
  toast,
} from '@nuna/tunic'

export type RetryChargeSearchParams = 'retryCharge' | 'appointmentId' | 'chargeAmount' | 'chargeType'

interface Props extends Omit<DrawerProps, 'isOpen' | 'onClose'> {
  patientId: string
}

export function RetryChargeDrawer({ patientId, ...props }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    paymentMethods,
    defaultForFees,
    savePaymentMethod,
    saveMutationResult: { loading: savePaymentLoading },
  } = useClientPaymentMethods(patientId)
  const [collectSessionPayment, { loading }] = useCollectSessionPaymentMutation({
    refetchQueries: ['PatientTransactionHistory'],
  })

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PatientPaymentMethodFragment | undefined>()

  useEffectOnce(() => {
    setSelectedPaymentMethod(defaultForFees)
  }, !!defaultForFees)

  const sessionPaymentId = searchParams.get('retryCharge')
  const appointmentId = searchParams.get('appointmentId')
  const chargeAmount = searchParams.get('chargeAmount')
  const chargeType = searchParams.get('chargeType') as PatientTransactionType

  const handleClose = () => {
    setSearchParams(oldSearchParams => {
      oldSearchParams.delete('retryCharge')
      oldSearchParams.delete('appointmentId')
      oldSearchParams.delete('chargeAmount')
      oldSearchParams.delete('chargeType')
      return oldSearchParams
    })
  }

  const handleChange = (paymentMethod: PatientPaymentMethodFragment) => {
    setSelectedPaymentMethod(paymentMethod)
  }

  const handleSubmit = async () => {
    if (!sessionPaymentId) return

    try {
      const response = await collectSessionPayment({
        variables: {
          sessionPaymentId,
          paymentMethodId: selectedPaymentMethod?.id,
        },
      })

      if (!response.data) throw new Error('No response data')

      if (response.data.collectSessionPayment.status === SessionPaymentStatus.Success) {
        toast.success('Payment successful')
        handleClose()
      } else {
        toast.urgent(response.data.collectSessionPayment.note || 'Payment failed')
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Payment did not complete. Try again or contact support.'))
    }
  }

  return (
    <StyledDrawer
      size="min(400px, 100vw)"
      isOpen={!!sessionPaymentId && !!appointmentId}
      onClose={handleClose}
      {...props}
    >
      {sessionPaymentId && appointmentId && (
        <div className="pt-2 px-3 pb-3">
          <div className="v-align mt-0 mb-4">
            <h2 className="h5 v-align mb-0">
              <IconSwitchRole className="mr-1" /> Reattempt failed charge
            </h2>

            <IconButton variant="secondary" tooltip="Close" className="ml-auto" onClick={handleClose}>
              <IconClose size={20} />
            </IconButton>
          </div>

          <p className="mb-1">{getChargeTypeExplanation(chargeType)}</p>
          <AppointmentDetails appointmentId={appointmentId} />

          <p className="mb-1 mt-4">Reattempt payment with this card:</p>
          <PaymentMethodSelect
            value={selectedPaymentMethod}
            paymentMethods={paymentMethods}
            savePaymentMethod={savePaymentMethod}
            onChange={handleChange}
            loading={savePaymentLoading}
            selectHeadingText="Select a new card"
            patientId={patientId}
            showHSAWarning={
              chargeType === PatientTransactionType.LateCancelationFee ||
              chargeType === PatientTransactionType.NoShowFee
            }
          />

          <div className="my-4">
            <b>Total:</b> {numberService.centsToFormattedDollars(Number(chargeAmount))}
          </div>

          <FillButton isLoading={loading} disabled={loading} onClick={handleSubmit}>
            Submit payment
          </FillButton>
        </div>
      )}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }
`

function AppointmentDetails({ appointmentId = '' }) {
  const { data } = useBasicAppointmentQuery({ variables: { appointmentId }, skip: !appointmentId })
  const appointment = data?.internalAppointment

  if (!appointment) return <Card className="loading" style={{ height: 106 }} />

  return <BasicAppointmentCard audience="client" appointment={appointment} />
}

function getChargeTypeExplanation(chargeType: PatientTransactionType) {
  let variablePhrase = ''

  switch (chargeType) {
    case PatientTransactionType.Session:
      variablePhrase = 'session costs'
      break
    case PatientTransactionType.LateCancelationFee:
      variablePhrase = 'late cancelation fees'
      break
    case PatientTransactionType.NoShowFee:
      variablePhrase = 'no-show fees'
      break
    case PatientTransactionType.AdminAdjustment:
      variablePhrase = 'admin adjustments'
      break
    case PatientTransactionType.Invoice:
      variablePhrase = 'an invoice'
      break
    default:
      variablePhrase = 'costs'
  }

  return `Charge failed for ${variablePhrase} related to:`
}
