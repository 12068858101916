import { useMemo } from 'react'

import { OrderBy, ProviderFilterInput, useProviderLazyQuery, useProviderOptionsLazyQuery } from '@nuna/api'

import { Filter } from '../filters.types'

export function useProviderFilter(providerFilters: Partial<ProviderFilterInput>, overrides?: Partial<Filter>) {
  const [searchProviders] = useProviderOptionsLazyQuery()
  const [getProvider] = useProviderLazyQuery()

  return useMemo<Filter>(
    () => ({
      ...{
        key: 'providerId',
        label: 'Provider',
        async: true,
        getOptions: async searchValue => {
          const results = await searchProviders({
            variables: {
              filters: {
                ...{
                  search: { term: searchValue, threshold: 0.2 },
                },
                ...providerFilters,
              },
              order: [{ key: 'firstName', direction: OrderBy.Asc }],
              pagination: { limit: 200 },
            },
          })
          return (
            results.data?.searchProviders.items.map(provider => ({
              value: provider.id,
              label: `${provider.firstName} ${provider.lastName}`,
            })) ?? []
          )
        },
        getSelectedValueOptions: async (value: unknown) => {
          const result = await getProvider({ variables: { id: value as string } })
          if (!result.data?.provider) {
            return [{ value, label: 'Unknown Provider' }]
          }
          return [{ value, label: `${result.data.provider.firstName} ${result.data.provider.lastName}` }]
        },
      },
      ...overrides,
    }),
    [overrides, getProvider, searchProviders, providerFilters],
  )
}
