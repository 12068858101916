import * as Yup from 'yup'
import { styled } from '@mui/material'
import { FormikErrors, FormikProps, FormikTouched } from 'formik'
import moment from 'moment'
import { HTMLAttributes } from 'react'

import {
  AuthorizedInternationalRegionInput,
  InternationalGeographicType,
  InternationalPhoneNumberType,
} from '@nuna/api'
import { formService } from '@nuna/core'
import {
  CountrySelect,
  DatePicker,
  IconButton,
  IconTrash,
  PlaceholderButton,
  TextField,
  greySet,
  shadowDepth,
} from '@nuna/tunic'

import { CustomerContractFormValues } from './shared'

const { composeHelperTextWithError } = formService

export const internationalAreaSchema = Yup.object({
  email: Yup.string().optional().email('Must be a valid email'),
  value: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  resourceSite: Yup.string().url('Must be a valid URL').required('Required'),
  sessionCap: Yup.number().required('Required'),
  wpoPhoneNumbers: Yup.array(Yup.string().required('Required')),
  firstAvailableDate: Yup.date().nullable(),
})

const initialInternationalArea: Partial<AuthorizedInternationalRegionInput> = {
  email: '',
  value: '',
  type: InternationalGeographicType.Country,
  resourceSite: '',
  wpoPhoneNumbers: [
    { phoneNumber: '', phoneType: InternationalPhoneNumberType.TollFree },
    { phoneNumber: '', phoneType: InternationalPhoneNumberType.DirectDial },
  ],
  firstAvailableDate: null,
}

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  formProps: FormikProps<CustomerContractFormValues & T>
}

export function InternationalAreaFormSection<T extends object>({ formProps, ...props }: Props<T>) {
  const { values, setFieldValue, handleChange, handleBlur, errors, touched, setFieldTouched } = formProps
  const internationalAreas = values.supportedInternationalAreas ?? []

  return (
    <div {...props}>
      {internationalAreas.map((internationalArea, idx) => {
        const fieldName = (field: string) => `supportedInternationalAreas[${idx}].${field}`
        const phoneField = (index: number) => `${fieldName('wpoPhoneNumbers')}[${index}].phoneNumber`
        const sectionErrors = (errors.supportedInternationalAreas ?? [])[
          idx
        ] as unknown as FormikErrors<AuthorizedInternationalRegionInput>

        // const phoneNumbers = internationalArea.wpoPhoneNumbers

        const sectionTouched = (
          (touched.supportedInternationalAreas ?? []) as FormikTouched<AuthorizedInternationalRegionInput>[]
        )[idx]

        // const phoneListName = fieldName('wpoPhoneNumbers')

        return (
          <InternationalAreaFormGroup className="mb-2" key={idx}>
            <div className="v-align space-between">
              <h6>Authorized International Area</h6>
              <IconButton
                type="button"
                tooltip="Remove international area"
                onClick={() =>
                  setFieldValue(
                    'supportedInternationalAreas',
                    internationalAreas.filter((_v, vIndex) => idx !== vIndex),
                  )
                }
              >
                <IconTrash />
              </IconButton>
            </div>{' '}
            <CountrySelect
              label="Country"
              className="mb-2"
              value={internationalArea.value}
              onChange={country => setFieldValue(fieldName('value'), country?.value)}
              onBlur={() => setFieldTouched(fieldName('value'), true)}
              inputProps={composeHelperTextWithError('', sectionErrors?.value, !!sectionTouched?.value)}
            />
            <TextField
              label="Email"
              className="mb-2"
              autoFocus
              name={fieldName('email')}
              value={internationalArea.email}
              onChange={handleChange}
              onBlur={handleBlur}
              {...composeHelperTextWithError('', sectionErrors?.email, !!sectionTouched?.email)}
            />
            <TextField
              label="Resource website"
              name={fieldName('resourceSite')}
              className="mb-2"
              value={internationalArea.resourceSite}
              onChange={handleChange}
              onBlur={handleBlur}
              {...composeHelperTextWithError('', sectionErrors?.resourceSite, !!sectionTouched?.resourceSite)}
            />
            <TextField
              required
              label="Session cap"
              className="mb-2"
              name={fieldName('sessionCap')}
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              value={internationalArea.sessionCap ?? null}
              {...composeHelperTextWithError('', sectionErrors?.sessionCap, !!sectionTouched?.sessionCap)}
            />
            <div className="mb-3">
              <DatePicker
                name="firstAvailableDate"
                className="v-align"
                disablePast
                format="dddd, MMM Do, YYYY"
                label="First available date"
                onChange={selectedDate =>
                  setFieldValue(
                    fieldName('firstAvailableDate'),
                    selectedDate ? moment.utc(selectedDate)?.startOf('day').toISOString() : null,
                    true,
                  )
                }
                onBlur={handleBlur}
                value={internationalArea.firstAvailableDate ? moment.utc(internationalArea.firstAvailableDate) : null}
                {...composeHelperTextWithError(
                  '',
                  sectionErrors?.firstAvailableDate,
                  !!sectionTouched?.firstAvailableDate,
                )}
              />
            </div>
            <TextField
              label="Toll free number"
              className="mb-2"
              name={phoneField(0)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={internationalArea.wpoPhoneNumbers[0]?.phoneNumber ?? ''}
            />
            <TextField
              className="mb-2"
              label="Direct phone number"
              name={phoneField(1)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={internationalArea.wpoPhoneNumbers[1]?.phoneNumber ?? ''}
            />
          </InternationalAreaFormGroup>
        )
      })}
      <PlaceholderButton
        type="button"
        onClick={() => setFieldValue('supportedInternationalAreas', [...internationalAreas, initialInternationalArea])}
      >
        Add International Area
      </PlaceholderButton>
    </div>
  )
}

const InternationalAreaFormGroup = styled('div')`
  border: 1px solid ${greySet[15].hex};
  border-radius: var(--border-radius);
  padding: var(--margin-2);
  box-shadow: ${shadowDepth(1)};
`

// const WPOSection = styled('div')``
