import { styled } from '@mui/material'
import {
  CellEditingStoppedEvent,
  ColDef,
  Column,
  GridReadyEvent,
  IServerSideDatasource,
  ProcessCellForExportParams,
  RowNode,
  StatusPanelDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep, isNil, sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import {
  CompensatableProviderFragment,
  Credential,
  OrderBy,
  ProviderCompensationFragment,
  ProviderCompensationInput,
  useCompensatableProvidersLazyQuery,
  useCptCodesQuery,
} from '@nuna/api'
import { numberService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import { Skeleton, eggshell, error, salmonSet, yellowSet } from '@nuna/tunic'

import { ProvidersFilterValues, buildProviderFilterInput } from '../../../shared/ProviderListNavigation/ProviderFilters'
import { LastSavedPanel } from './LastSavedPanel'
import { ProviderCompensationProviderCell } from './ProviderCompensationProviderCell'
import { useProviderCompensationTableContext } from './ProviderCompensationTableContextProvider'
import { CompensationModel } from './ProviderCompensationTableContextProvider'

interface Props {
  filterValues: ProvidersFilterValues
}

export function ProviderCompensationTable({ filterValues }: Props) {
  const gridRef = useRef<AgGridReact>(null)
  const initialRendered = useRef(false)
  // ref is used for filterValues to prevent the data source from being re-assigned whenever filter values change
  const filterValuesRef = useRef(filterValues)
  const { data: cptCodeData } = useCptCodesQuery({ fetchPolicy: 'cache-first' })
  const { queueCompensation, hasError, removeError } = useProviderCompensationTableContext()
  const { adminFinancials } = useFeatureFlags()

  const [queryCompensatableProviders] = useCompensatableProvidersLazyQuery()

  const cptCodes = cptCodeData?.cptCodes

  useEffect(() => {
    // when filterValues change, mutate the ref
    filterValuesRef.current = filterValues

    if (initialRendered.current) {
      // and then refresh the table
      gridRef.current?.api.refreshServerSideStore({ purge: true })
    }
  }, [filterValues])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 80,
    }
  }, [])

  const columnDefs = useMemo<ColDef[]>(() => {
    const columns: ColDef[] = [
      {
        field: 'id',
        headerName: 'Provider',
        cellRenderer: ProviderCompensationProviderCell,
      },
      {
        field: 'credentials',
        headerName: 'Credentials',
        valueGetter: params => {
          const { credentials } = params.data
          return ((credentials ?? []) as Credential[]).map(c => c.credential).join(', ') || '—'
        },
      },
      {
        field: 'state',
        headerName: 'State',
        valueGetter: params => {
          const { homeAddress } = params.data
          return homeAddress?.state ?? '—'
        },
      },
    ]

    if (cptCodes) {
      sortBy(cptCodes, code => code.code).forEach(cptCode => {
        columns.push({
          field: cptCode.id,
          headerName: cptCode.code,
          type: 'rightAligned',
          editable: adminFinancials,
          cellClassRules: {
            'error-cell': params => {
              const provider: ProviderCompensationFragment = params.data
              return hasError({ providerId: provider.id, cptCodeId: params.colDef.field ?? '' })
            },
          },
          valueGetter: params => {
            const provider = params.data as CompensatableProviderFragment
            const compensations = provider.providerCompensations ?? []
            const cptCodeId = params.column.getColId()
            const compensation = compensations.find(comp => comp.cptCodeId === cptCodeId)

            if (compensation) {
              return numberService.centsToDollars(compensation.rate)
            }
          },
          valueFormatter: currencyFormatter,
        })
      })
    }
    return columns
  }, [cptCodes, hasError, adminFinancials])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: async params2 => {
          const page = (params2.request.endRow ?? 20) / 20
          const result = await queryCompensatableProviders({
            variables: {
              order: [
                { key: 'createdAt', direction: OrderBy.Desc },
                { key: 'id', direction: OrderBy.Asc },
              ],
              pagination: { page },
              filters: buildProviderFilterInput(filterValuesRef.current),
            },
          })
          initialRendered.current = true
          if (result.data?.compensatableProviders) {
            params2.success({
              rowData: cloneDeep(result.data.compensatableProviders.items),
              rowCount: result.data.compensatableProviders.pagination?.totalCount,
            })
          }
        },
      }
      params.api.setServerSideDatasource(dataSource)
    },
    [queryCompensatableProviders],
  )

  const setRate = useCallback(
    (column: Column, node: RowNode | null | undefined, value: number) => {
      if (!node) {
        return
      }
      const data = node.data
      const providerId: string = data.id
      const cptCodeId = column.getColId()
      const existing = findCompensation(data.providerCompensations as CompensationModel[], providerId, cptCodeId)
      const providerComp: ProviderCompensationInput = {
        providerId,
        cptCodeId,
        rate: numberService.dollarsToCents(value),
        id: existing?.id,
      }

      const currentProviderComps: (ProviderCompensationFragment | ProviderCompensationInput)[] =
        data?.providerCompensations ?? []

      if (existing) {
        existing.rate = providerComp.rate
      } else {
        currentProviderComps.push(providerComp)
      }
      const newRowData = { ...data, providerCompensations: currentProviderComps }
      node.setData(newRowData)
      removeError(providerComp)
      queueCompensation(providerComp)
    },
    [queueCompensation, removeError],
  )

  const processCellFromClipboard = useCallback(
    (params: ProcessCellForExportParams) => {
      setRate(params.column, params.node, params.value)
    },
    [setRate],
  )

  const handleCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      setRate(event.column, event.node, event.newValue)
    },
    [setRate],
  )

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[]
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: LastSavedPanel,
        },
      ],
    }
  }, [])

  return (
    <GridContainer className="ag-theme-material" style={{ height: '100%', width: '100%' }}>
      {cptCodes ? (
        <AgGridReact
          ref={gridRef}
          enableRangeSelection
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowModelType="serverSide"
          onGridReady={onGridReady}
          serverSideStoreType="partial"
          cacheBlockSize={20}
          columnHoverHighlight
          onCellEditingStopped={handleCellEditingStopped}
          getRowStyle={params => {
            const { node } = params
            if (!node.highlighted && (node.rowIndex ?? 0) % 2 === 0) {
              return { background: eggshell }
            }
          }}
          statusBar={statusBar}
          processCellFromClipboard={processCellFromClipboard}
        ></AgGridReact>
      ) : (
        <Skeleton height={10} style={{ width: '100%' }} />
      )}
    </GridContainer>
  )
}

function currencyFormatter(params: ValueFormatterParams) {
  if (isNil(params.value)) {
    return ''
  }
  return numberService.formatCurrency(params.value)
}

const GridContainer = styled('div')`
  .ag-row-hover,
  .ag-column-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    background-color: ${yellowSet[15].hex} !important;
  }
  .error-cell {
    border: 1px solid ${error} !important;
    background-color: ${salmonSet[15].hex};
  }
`

function findCompensation(compensations: CompensationModel[] = [], providerId: string, cptCodeId: string) {
  return (compensations ?? []).find(
    compensation => compensation.cptCodeId === cptCodeId && compensation.providerId === providerId,
  )
}
