import { useState } from 'react'
import { ColumnInstance } from 'react-table'

import { InsuranceTaxEntityFragment } from '@nuna/api'
import { DataTableRowComponentProps } from '@nuna/data-table'
import { IconButton, IconPencil, csx } from '@nuna/tunic'

import { TaxEntityForm } from './TaxEntityForm'

interface CustomColumn extends ColumnInstance<InsuranceTaxEntityFragment> {
  className?: string
}

export function TaxEntityRow({ row }: DataTableRowComponentProps<InsuranceTaxEntityFragment>) {
  const [isEditMode, setIsEditMode] = useState(false)

  const cells = row.cells.filter(cell => cell.column.id !== 'id')

  if (!isEditMode) {
    const { key, ...rowProps } = row.getRowProps()
    return (
      <tr key={key} {...rowProps}>
        {cells.map(cell => {
          const { key, ...cellProps } = cell.getCellProps({
            style: cell.column.width ? { width: `${cell.column.width}px` } : {},
          })
          return (
            <td key={key} {...cellProps} className={csx([(cell.column as CustomColumn).className as string])}>
              {cell.render('Cell')}
            </td>
          )
        })}

        <td className="right-align">
          <IconButton tooltip="Edit" onClick={() => setIsEditMode(true)}>
            <IconPencil />
          </IconButton>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td colSpan={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <TaxEntityForm
          insuranceTaxEntity={{
            ...row.original,
            default: row.original.isDefault ? 'yes' : 'no',
          }}
          onCancel={() => setIsEditMode(false)}
          onSave={() => setIsEditMode(false)}
        />
      </td>
    </tr>
  )
}
