import { styled } from '@mui/material'

import { AddressSearchOptions } from '@nuna/api'
import { Card, GhostButton, IconClose, StateAutocompleteSingle, csx } from '@nuna/tunic'

interface Props {
  filterValues: AddressSearchOptions
  setFilterVal: (key: keyof AddressSearchOptions, value: unknown) => void
  clearFilters: () => void
}

export function TavaAddressFilters({ filterValues, setFilterVal, clearFilters }: Props) {
  const hasFiltersApplied = Object.values(filterValues).some(value => !!value)

  return (
    <StyledCard depth={-0.5} className="p-2 mb-1 v-align gap-3">
      <StateAutocompleteSingle
        label=""
        placeholder="Filter by state"
        className="filter-input"
        value={filterValues.state}
        onChange={stateOption => setFilterVal('state', stateOption?.value)}
      />

      <GhostButton
        className={csx([{ hidden: !hasFiltersApplied }, 'ml-auto clear-button'])}
        variant="secondary"
        onClick={clearFilters}
      >
        <IconClose size={14} className="mr-xs" />
        Clear all
      </GhostButton>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  .filter-input {
    width: 200px;
    margin-right: var(--spacing-2);
  }

  .clear-button {
    &.hidden {
      visibility: hidden;
    }
  }
`
