import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { omit } from 'lodash'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AddressOwner, AddressQuery, AddressType, useAddressQuery, useSaveAddressMutation } from '@nuna/api'
import { AddressForm, OmittedAddressFields, UsAddressValues, addressValidationSchemaChecks } from '@nuna/common'
import { errorService, formService } from '@nuna/core'
import {
  FillButton,
  Grid,
  OutlineButtonLink,
  PageContent,
  Skeleton,
  TextField,
  TimezoneSelect,
  toast,
} from '@nuna/tunic'

interface FormValues extends UsAddressValues {
  name: string
  fax?: string | null
  phone?: string | null
  timezone?: string | null
  latitude?: number | null
  longitude?: number | null
}

const validationSchema = Yup.object().shape<FormValues>({
  ...addressValidationSchemaChecks,
  name: Yup.string().required('Name is required'),
  phone: Yup.string().nullable(),
  fax: Yup.string().nullable(),
  timezone: Yup.string().nullable(),
  latitude: Yup.number().nullable(),
  longitude: Yup.number().nullable(),
})

export function TavaAddressForm() {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useAddressQuery({ variables: { id }, skip: !id })

  const [saveAddress, { loading: saveAddressLoading }] = useSaveAddressMutation()

  const initialValues = useMemo(() => buildInitialValues(data?.address), [data])

  const handleSubmit = async (values: FormValues) => {
    try {
      await saveAddress({
        variables: {
          address: {
            ...omit(values, OmittedAddressFields),
            owner: AddressOwner.Tava,
            addressType: AddressType.ProviderPractice,
            id,
          },
        },
      })
      toast.success('Address saved')
      navigate('..')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was a problem saving the address'))
    }
  }

  return (
    <PageContent className="flex-column">
      <h2 className="h5 sans-serif mb-3">{id ? 'Edit' : 'Create'} address</h2>
      {loading && <Skeleton height={16} />}
      {!loading && (
        <Container>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ submitForm, getFieldProps, setFieldValue, errors, touched, values }) => (
              <>
                <Grid container className="mb-4">
                  <Grid size={12}>
                    <TextField
                      label="Name"
                      {...getFieldProps('name')}
                      {...formService.composeHelperTextWithError('', errors.name, touched.name)}
                    />
                  </Grid>
                </Grid>
                <AddressForm hideCounty={false} hideAddressLineTwo={false} />
                <Grid container spacing={6} className="mt-4">
                  <Grid size={{ xs: 6 }}>
                    <TextField label="Phone" {...getFieldProps('phone')} />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <TextField label="Fax" {...getFieldProps('fax')} />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <TimezoneSelect value={values.timezone} onChange={tz => setFieldValue('timezone', tz)} />
                  </Grid>
                </Grid>
                <div className="v-align pt-4">
                  <span className="ml-auto v-align">
                    <OutlineButtonLink to="..">Cancel</OutlineButtonLink>
                    <FillButton isLoading={saveAddressLoading} className="ml-1" onClick={submitForm}>
                      Save
                    </FillButton>
                  </span>
                </div>
              </>
            )}
          </Formik>
        </Container>
      )}
    </PageContent>
  )
}

function buildInitialValues(values?: AddressQuery['address']): FormValues {
  return {
    name: values?.name ?? '',
    addressLineOne: values?.addressLineOne ?? '',
    addressLineTwo: values?.addressLineTwo ?? '',
    city: values?.city ?? '',
    county: values?.county ?? '',
    state: values?.state ?? '',
    zipCode: values?.zipCode ?? '',
    phone: values?.phone ?? '',
    fax: values?.fax ?? '',
    timezone: values?.timezone ?? '',
  }
}

const Container = styled('div')`
  width: 40rem;
`
