import { HrAdminUsersTable } from '@nuna/customer'
import { OutlineButtonLink, PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { customerDetail, newHrAdminUser } from '../../util/routes'

export function HrAdminUsersIndex() {
  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <PageHeading className="m-0">HR Admins</PageHeading>
          <OutlineButtonLink to={newHrAdminUser()}>New HR Admin</OutlineButtonLink>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <HrAdminUsersTable customerDetailRoute={customerDetail} />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
