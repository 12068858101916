import { IconProps } from './IconProps'

export function IconFilter({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (21 / 22) * size

  return (
    <svg width={size} height={height} viewBox="0 0 22 21" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27166 17.5291C9.27166 18.1996 9.80554 18.7418 10.4648 18.7418H10.7801C11.4394 18.7418 11.9733 18.1886 11.9733 17.5291V13.5944L16.8319 6.15371C17.57 5.02166 17.0899 4.11676 15.7713 4.11676H5.46287C4.13355 4.11676 3.66775 5.02166 4.3987 6.13905L9.27166 13.5798V17.5291Z"
      />
    </svg>
  )
}
