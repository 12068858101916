import { styled } from '@mui/material'
import { sortBy } from 'lodash'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  AddressType,
  useAssignProviderAddressesMutation,
  useBasicProviderQuery,
  useProviderAddressesQuery,
  useRemoveProviderAddressMutation,
  useSetPrimaryPracticeLocationMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { Avatar, Drawer, IconButton, IconClose, PlaceholderButton, eggshell, toast } from '@nuna/tunic'

import { SelectedAssignedAddresses } from './components/SelectedAssignedAddresses'
import { TavaAddressSelector } from './components/TavaAddressSelector'

export function ProviderTavaAddressDrawer() {
  const [searchParams, setSearchParams] = useSearchParams()
  const providerId = searchParams.get('assignPracticeAddresses')
  const [selectorOpen, setSelectorOpen] = useState(false)
  const { data: providerData } = useBasicProviderQuery({ variables: { id: providerId ?? '' }, skip: !providerId })
  const { data, refetch } = useProviderAddressesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      searchOptions: { providerId, providerAddressType: AddressType.ProviderPractice },
    },
    skip: !providerId,
  })

  const [assignAddresses] = useAssignProviderAddressesMutation()
  const [removeAddress] = useRemoveProviderAddressMutation()
  const [setPrimary] = useSetPrimaryPracticeLocationMutation()

  const provider = providerData?.provider
  const providerAddresses = sortBy(data?.providerAddresses ?? [], pa => pa.address.addressLineOne)

  const closeDrawer = () => {
    setSearchParams(oldParams => {
      const copy = new URLSearchParams(oldParams)
      copy.delete('assignPracticeAddresses')
      return copy
    })
    setSelectorOpen(false)
  }

  const handleSelectedAddresses = async (addressIds: string[]) => {
    try {
      await assignAddresses({
        variables: {
          assignProviderAddressInput: {
            providerId: providerId ?? '',
            providerAddresses: addressIds.map(addressId => ({ addressId })),
          },
        },
      })
      refetch()
      setSelectorOpen(false)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e))
    }
  }

  const handleRemoveProviderAddress = async (addressId: string) => {
    try {
      await removeAddress({
        variables: {
          providerId: providerId ?? '',
          addressId,
        },
      })
      refetch()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e))
    }
  }

  const handleSetPrimaryPracticeLocation = async (addressId: string) => {
    try {
      await setPrimary({
        variables: {
          providerId: providerId ?? '',
          addressId,
        },
      })
      refetch()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e))
    }
  }

  return (
    <StyledDrawer
      size="min(400px, 100vw)"
      onClose={closeDrawer}
      isOpen={!!providerId}
      PaperProps={{ className: 'p-2' }}
    >
      {providerId && (
        <div className="flex-column full-height">
          <div className="v-align mb-2 space-between">
            <span className="v-align large text-medium">
              <Avatar size="xs" url={providerData?.provider.avatarUrl} className="mr-1" />
              {provider?.firstName} {provider?.lastName}
            </span>
            <IconButton variant="secondary" tooltip="Close" onClick={closeDrawer}>
              <IconClose size={20} />
            </IconButton>
          </div>

          <h2 className="large sans-serif text-medium text-dark-grey mb-1">Tava Practice Addresses</h2>
          {!selectorOpen && (
            <>
              <SelectedAssignedAddresses
                providerAddresses={providerAddresses}
                onSetPrimary={handleSetPrimaryPracticeLocation}
                onRemove={handleRemoveProviderAddress}
              />
              <PlaceholderButton className="mt-2" onClick={() => setSelectorOpen(true)}>
                Add Addresses
              </PlaceholderButton>
            </>
          )}
          {selectorOpen && (
            <TavaAddressSelector
              providerAddresses={providerAddresses}
              onCancel={() => setSelectorOpen(false)}
              onAddressesSelected={handleSelectedAddresses}
            />
          )}
        </div>
      )}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`
