import { useDataTableFiltering } from '@nuna/data-table'
import { PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import {
  DEFAULT_PROVIDER_TABLE_FILTER_VALUES,
  ProviderFilters,
} from '../../shared/ProviderListNavigation/ProviderFilters'
import { ProviderCompensationTable } from './ProviderCompensationTable'

export function ProviderCompensationIndex() {
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(DEFAULT_PROVIDER_TABLE_FILTER_VALUES)

  return (
    <NavLayout containerProps={{ className: 'flex-column' }}>
      <PageWrapper>
        <PageHeader>
          <div className="v-align">
            <PageHeading withDivider className="mb-0">
              Provider Compensation
            </PageHeading>
            <ProviderFilters
              filtersToInclude={[
                'active',
                'activeCredentialsIn',
                'statuses',
                'credentialStates',
                'missingCompensationAmounts',
                'insurancePayerPlanId',
              ]}
              filterValues={filterValues}
              setFilterVal={setFilterVal}
              clearFilters={clearFilters}
            />
          </div>
        </PageHeader>
        <PageContent>
          <ProviderCompensationTable filterValues={filterValues} />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
