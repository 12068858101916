import { styled } from '@mui/material'
import { Menu, MenuItem } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes, MouseEventHandler, useState } from 'react'
import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { OrganizationRelationship } from '@nuna/api'
import { routeService } from '@nuna/core'
import { StatusLabel, error, tealSet } from '@nuna/tunic'

import { MemberMatchPerson } from '../normalize-member-match-data'

interface CellProps extends HTMLAttributes<HTMLSpanElement> {
  record: MemberMatchPerson
  patientForComparison?: MemberMatchPerson
}

export interface AccessorProps extends HTMLAttributes<HTMLSpanElement> {
  accessorKey: keyof MemberMatchPerson
  formatter?: (value: string) => string
  leftPadding?: boolean
}

export type TextCellProps = CellProps & AccessorProps

export function TextCell({
  record,
  patientForComparison,
  accessorKey,
  formatter = (value: string) => value,
  ...props
}: TextCellProps) {
  const isMatch = () => {
    if (patientForComparison) {
      return record[accessorKey]?.toLowerCase() === patientForComparison[accessorKey]?.toLowerCase()
    }
  }
  return (
    <HighlightedText isMatch={isMatch()} {...props}>
      {formatter(record[accessorKey] ?? '')}
    </HighlightedText>
  )
}

export function FullNameCell({ record, patientForComparison }: CellProps) {
  const HighLight = useCallback(
    ({ accessorKey, ...props }: AccessorProps) => {
      return (
        <TextCell record={record} patientForComparison={patientForComparison} accessorKey={accessorKey} {...props} />
      )
    },
    [record, patientForComparison],
  )

  if (patientForComparison) {
    return (
      <>
        <HighLight accessorKey="firstName" />
        <HighLight accessorKey="lastName" />
      </>
    )
  } else {
    return (
      <NavLink to={routeService.patientDetail(record.id)}>
        {record.firstName} {record.lastName}
      </NavLink>
    )
  }
}

export function AddressCell({ record, patientForComparison }: CellProps) {
  const HighLight = useCallback(
    ({ accessorKey }: AccessorProps) => {
      return <TextCell record={record} patientForComparison={patientForComparison} accessorKey={accessorKey} />
    },
    [record, patientForComparison],
  )

  return (
    <>
      <HighLight accessorKey="addressLineOne" />,{' '}
      {record.addressLineTwo && (
        <>
          <HighLight accessorKey="addressLineTwo" />
          {', '}
        </>
      )}
      <HighLight accessorKey="city" />, <HighLight accessorKey="state" /> <HighLight accessorKey="zipCode" />
    </>
  )
}

export function AssociationCell({ record }: CellProps) {
  if (!record.association) {
    return <>—</>
  }

  return <StatusLabel>{readableAssociation(record.association)}</StatusLabel>
}

interface AssociationEditCellProps {
  association?: OrganizationRelationship | null
  onChange?: (value: OrganizationRelationship | null) => void
}

export function AssociationEditCell({ association, onChange = noop }: AssociationEditCellProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleChange = (relationship: OrganizationRelationship) => {
    onChange(relationship)
    setAnchorEl(null)
  }

  return (
    <div>
      <button onClick={handleClick}>
        <StatusLabel intent={association ? 'default' : 'urgent'}>
          {readableAssociation(association)} <SelectCaret />
        </StatusLabel>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChange(OrganizationRelationship.Employee)}>
          <StatusLabel>Employee</StatusLabel>
        </MenuItem>
        <MenuItem onClick={() => handleChange(OrganizationRelationship.SpousePartner)}>
          <StatusLabel>Spouse or Partner</StatusLabel>
        </MenuItem>
        <MenuItem onClick={() => handleChange(OrganizationRelationship.Child)}>
          <StatusLabel>Child</StatusLabel>
        </MenuItem>
      </Menu>
    </div>
  )
}

function readableAssociation(association?: OrganizationRelationship | null) {
  switch (association) {
    case OrganizationRelationship.Employee:
      return 'Employee'
    case OrganizationRelationship.SpousePartner:
      return 'Spouse or Partner'
    case OrganizationRelationship.Child:
      return 'Child'
    default:
      return 'Select Association'
  }
}

function SelectCaret() {
  return (
    <svg width="12" height="9" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.456l-6-6L1.716.75 6 5.034 10.284.75 12 2.456l-6 6z"
        fill="#606969"
      />
    </svg>
  )
}

export const HighlightedText = styled('span')<{ isMatch?: boolean }>`
  ${({ isMatch }) => isMatch && `color: ${tealSet[70].hex};`}
  ${({ isMatch }) => isMatch === false && `color: ${error};`}

  + span {
    margin-left: 4px;
  }
`
