import { PageContent } from '@nuna/tunic'

import { AddressEnrollmentTable } from './AddressEnrollmentTable/AddressEnrollmentTable'

export function CurrentAddressEnrollments() {
  return (
    <PageContent className="overflow-hidden flex-column">
      <AddressEnrollmentTable
        header={<h5 className="serif mb-0">Current Address Enrollments</h5>}
        columns={[
          'provider',
          'payer',
          'name',
          'address',
          'effectiveStatus',
          'payerStatus',
          'submissionType',
          'submittedDate',
          'effectiveDate',
          'archivedDate',
          'w9',
          'disableBilling',
          'assignee',
          'actions',
        ]}
      />
    </PageContent>
  )
}
