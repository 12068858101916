import { isNil, sum } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  InsurancePayerSortItem,
  InsurancePayerStatus,
  InsurancePayersSearchOptions,
  OrderBy,
  PayerListItemFragment,
  TraversablePaginationFragment,
  TraversablePaginationSortInput,
  useInsurancePayersQuery,
} from '@nuna/api'
import { routeService } from '@nuna/core'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import {
  OutlineButtonLink,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  Radio,
  StateAutocompleteSingle,
  StatusLabel,
  TextField,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { DataFilters } from '../../shared/DataFilters'
import { newPayer } from '../../util/routes'

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'name', direction: OrderBy.Asc }]

const INITIAL_FILTER_STATE: InsurancePayersSearchOptions = {
  name: '',
  status: undefined,
  state: undefined,
  externalPayerId: '',
}

export function PayersIndex() {
  const navigate = useNavigate()

  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(INITIAL_FILTER_STATE)

  const { handleSort, queryOptions, getPaginatorProps, initialTableState, setPagination, setLoading } = usePagination({
    loading: true,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const { data, loading } = useInsurancePayersQuery({
    variables: {
      order: queryOptions.sortInput as InsurancePayerSortItem[],
      filters: queryOptions.filters || filterValues,
      pagination: queryOptions.paginationInput,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'insurancePayersSearch', debounceTimeout: 200 },
  })

  useEffect(
    () => setPagination(data?.insurancePayers.pagination as TraversablePaginationFragment),
    [data?.insurancePayers.pagination, setPagination],
  )

  useEffect(() => setLoading(loading), [loading, setLoading])

  const columns = useMemo<DataTableColumn<PayerListItemFragment>[]>(() => {
    return [
      { Header: 'Name', accessor: 'name', paginatedSortable: true },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <StatusLabel
            intent={
              value === InsurancePayerStatus.Inactive
                ? 'urgent'
                : value === InsurancePayerStatus.Pending
                ? 'caution'
                : 'information'
            }
          >
            {value}
          </StatusLabel>
        ),
      },
      {
        Header: 'Capabilities',
        accessor: 'supportsRealtimeVerification',
        Cell: ({ value, row }) => (
          <div>
            {value && <StatusLabel className="mr-1">e-checks</StatusLabel>}
            {row.original.outOfStateCoverage && <StatusLabel className="mr-1">multi-state</StatusLabel>}
            {row.original.useProviderForVerification && (
              <StatusLabel className="mr-1">e-check via provider</StatusLabel>
            )}
          </div>
        ),
      },
      { Header: 'Date added', accessor: 'createdAt', Cell: ({ value }) => <>{moment(value).format('l')}</> },
    ]
  }, [])

  const rowData = useMemo<PayerListItemFragment[]>(() => {
    return data?.insurancePayers.items ?? []
  }, [data])

  const filtersAppliedCount = useMemo(
    () =>
      sum([!isNil(filterValues.status) ? 1 : 0, filterValues?.state ? 1 : 0, filterValues?.externalPayerId ? 1 : 0]),
    [filterValues],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <div className="v-align">
            <PageHeading withDivider className="mb-0">
              Payers
            </PageHeading>
            <DataFilters
              onSearchTextChange={searchText => setFilterVal('name', searchText)}
              onClearFilters={clearFilters}
              filtersApplied={filtersAppliedCount}
            >
              <div role="group">
                <Radio
                  value="active"
                  checked={filterValues.status === InsurancePayerStatus.Active}
                  onChange={() => {
                    setFilterVal('status', InsurancePayerStatus.Active)
                  }}
                >
                  Active only
                </Radio>
                <Radio
                  value="inactive"
                  checked={filterValues.status === InsurancePayerStatus.Inactive}
                  onChange={() => {
                    setFilterVal('status', InsurancePayerStatus.Inactive)
                  }}
                >
                  Inactive only
                </Radio>
                <Radio
                  value="pending"
                  checked={filterValues.status === InsurancePayerStatus.Pending}
                  onChange={() => {
                    setFilterVal('status', InsurancePayerStatus.Pending)
                  }}
                >
                  Pending only
                </Radio>
                <Radio
                  value="both"
                  checked={filterValues.status === undefined}
                  onChange={() => {
                    setFilterVal('status', undefined)
                  }}
                >
                  Active, Inactive and Pending
                </Radio>
              </div>
              <StateAutocompleteSingle
                className="mb-2"
                value={filterValues?.state}
                onChange={stateOption => {
                  setFilterVal('state', stateOption?.value)
                }}
              />
              <TextField
                label="Payer ID"
                className="mb-2"
                value={filterValues?.externalPayerId}
                onChange={event => {
                  setFilterVal('externalPayerId', event.target.value)
                }}
              />
            </DataFilters>
          </div>

          <div className="v-align">
            <OutlineButtonLink className="ml-1" to={newPayer}>
              New Payer
            </OutlineButtonLink>
          </div>
        </PageHeader>
        <PageContent $paddingTop={false} style={{ maxWidth: '1280px' }}>
          <DataTable
            initialState={initialTableState}
            onSort={handleSort}
            columns={columns}
            rowData={rowData}
            loading={loading}
            paginated
            paginatorProps={getPaginatorProps()}
            onRowClick={row => navigate(routeService.insurancePayer(row.id))}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
