import moment from 'moment-timezone'

export const DateTimeCell = ({ value }: { value: string | Date | number }) => {
  if (!value) return null

  let date: Date

  if (typeof value === 'number') {
    date = new Date(value)
  } else if (typeof value === 'string') {
    const numericValue = parseInt(value, 10)
    const isIsoValue = /^\d+$/.test(value)
    if (!isNaN(numericValue) && isIsoValue) {
      date = new Date(parseInt(value, 10))
    } else {
      date = new Date(value)
    }
  } else if (value instanceof Date) {
    date = value
  } else {
    return null
  }

  if (isNaN(date.getTime())) {
    console.error('Invalid date:', value)
    return null
  }

  return <span>{moment(date).format('MM/DD/YYYY')}</span>
}
