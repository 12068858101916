import { useMemo } from 'react'

import { CredentialingChecklistDataPoint } from '@nuna/api'
import { DateTimeCell, TavaDataTable } from '@nuna/common'
import { CredentialingChecklistSection, CredentialingChecklistStatus, CredentialingChecklistType } from '@nuna/core'

import { useCredentialingChecklistContext } from '../Context/CredentialingChecklistContextProvider'
import { ChecklistSourceCell } from './ChecklistSourceCell'
import { CredentialChecklistStatusCell } from './CredentialChecklistStatusCell'

const DEFAULT_COL_DEFS = {
  flex: 1,
}

export function ChecklistTable() {
  const { checklist, isLoading } = useCredentialingChecklistContext()

  const handleStatusClick = (data: CredentialingChecklistDataPoint) => {
    if (data.details) {
      return data.details
    }
  }

  const colDefs = [
    {
      field: 'title',
      headerName: 'Task',
    },
    {
      field: 'description',
      headerName: 'Details',
    },
    {
      field: 'source',
      headerName: 'Source/Documents',
      cellRenderer: ChecklistSourceCell,
    },
    {
      field: 'verifiedAt',
      headerName: 'Verified Date',
      cellRenderer: (params: { value: CredentialingChecklistStatus }) => <DateTimeCell value={params.value} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: (params: { value: CredentialingChecklistStatus; data: CredentialingChecklistDataPoint }) => (
        <CredentialChecklistStatusCell
          field={params.data.field as CredentialingChecklistType}
          data={params.data}
          handleClick={() => {
            handleStatusClick(params.data)
          }}
          status={params.value}
        />
      ),
    },
  ]

  const rowData = useMemo(() => {
    const multipleRowTypes = ['education', 'workHistory', 'stateLicenses']

    const mergeDataSections = () => {
      let mergedData: CredentialingChecklistDataPoint[] = []

      if (checklist) {
        Object.keys(checklist).forEach((key: string) => {
          const sectionKey = key as keyof CredentialingChecklistSection
          let isMultipleRowType = false
          if (Array.isArray(checklist[sectionKey])) {
            const items = checklist[sectionKey] as CredentialingChecklistDataPoint[]
            const mappedItems = items.map((item: CredentialingChecklistDataPoint) => {
              const parsedDetails = item.details ? JSON.parse(item.details) : {}

              if (multipleRowTypes.includes(item.field)) {
                isMultipleRowType = true
              }

              return {
                ...item,
                details: {
                  ...parsedDetails,
                  items,
                },
              }
            })

            if (mappedItems.length) {
              if (isMultipleRowType) {
                mergedData = [...mergedData, ...mappedItems]
              } else {
                const primaryItem = mappedItems.find((item: CredentialingChecklistDataPoint) => item.isPrimary)
                const mergeItem = primaryItem ?? mappedItems[0]
                mergedData = [...mergedData, mergeItem]
              }
            }
          }
        })
      }

      return mergedData
    }

    return checklist ? mergeDataSections() : []
  }, [checklist])

  return <TavaDataTable isLoading={isLoading} rowData={rowData} columnDefs={colDefs} defaultColDef={DEFAULT_COL_DEFS} />
}
