import { clone, cloneDeep, flatten, take } from 'lodash'
import { useCallback, useState } from 'react'

type PageSelections = Record<number, string[]>

interface Args {
  limit: number
  idsInView: string[]
  currentPage?: number | null
}

export const MAX_SELECTION_COUNT = 100

export function useInsurancePolicyTableRowSelection({ limit, idsInView, currentPage }: Args) {
  const [pageSelections, setPageSelections] = useState<PageSelections>({})

  const getSelectionsForPage = useCallback(
    (page?: number | undefined) => (page && pageSelections[page] ? pageSelections[page] : []),
    [pageSelections],
  )

  const getAllSelections = useCallback(() => flatten(Object.values(pageSelections)), [pageSelections])

  const toggleSelectAll = useCallback(() => {
    setPageSelections(current => {
      if (currentPage) {
        const cloned = cloneDeep(current)
        const currentCount = cloned[currentPage]?.length

        if (currentCount === limit) {
          cloned[currentPage] = []
        } else {
          const currentSelectionCount = getAllSelections().length
          cloned[currentPage] = clone(take(idsInView, MAX_SELECTION_COUNT - currentSelectionCount))
        }
        return cloned
      }
      return current
    })
  }, [idsInView, currentPage, limit, getAllSelections])

  const toggleSelect = useCallback(
    (id: string) => {
      setPageSelections(current => {
        if (currentPage) {
          const cloned = cloneDeep(current)
          const selections = cloned[currentPage] ?? []

          if (selections.includes(id)) {
            cloned[currentPage] = selections.filter(selection => selection !== id)
          } else if (getAllSelections().length < MAX_SELECTION_COUNT) {
            cloned[currentPage] = [...selections, id]
          }

          return cloned
        }

        return current
      })
    },
    [currentPage, getAllSelections],
  )

  const clearAllSelections = useCallback(() => setPageSelections({}), [])

  return {
    toggleSelect,
    toggleSelectAll,
    clearAllSelections,
    getSelectionsForPage,
    getAllSelections,
  }
}
