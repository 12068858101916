/* eslint-disable react/jsx-no-useless-fragment */
import { styled } from '@mui/material'
import { AgGridEvent, GridReadyEvent, ICellRendererParams, SelectionChangedEvent } from 'ag-grid-community'
import { ColDef, IServerSideDatasource } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { startCase } from 'lodash'
import moment from 'moment-timezone'
import { HTMLAttributes, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import {
  AddressEnrollmentFragment,
  AddressEnrollmentSearchOptions,
  AddressEnrollmentStatus,
  InsurancePayer,
  InsuranceTaskType,
  Login,
  OrderBy,
  Provider,
  SearchAddressEnrollmentsQuery,
  TraversablePaginationSortInput,
  useSaveAddressEnrollmentMutation,
  useSaveInsuranceTaskMutation,
  useSearchAddressEnrollmentsLazyQuery,
} from '@nuna/api'
import { UserLink } from '@nuna/common'
import { addressService, errorService, routeService } from '@nuna/core'
import {
  DropdownButton,
  Intent,
  Menu,
  MenuItem,
  MenuItemStack,
  StatusLabel,
  Switch,
  csx,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { AssigneePicker } from '../../../shared/Tasks/AssigneePicker'
import { useUrlFilters } from '../../../shared/filters/hooks/useUrlFilters'
import { getW9Link } from '../../../util/w9'
import { AddressW9DropdownMenu } from '../../practice-address/components/AddressW9DropdownMenu'
import { AddressEnrollmentActionsMenu } from './components/AddressEnrollmentActionsMenu'
import { AddressEnrollmentFilterValues, AddressEnrollmentFilters } from './components/AddressEnrollmentFilters'
import { ProviderEnrollmentDataDialog } from './components/ProviderEnrollmentDataDialog'
import { UpdateAddressEnrollmentsDrawer } from './components/UpdateAddressEnrollmentsDrawer'

type ColumnKeys =
  | 'name'
  | 'provider'
  | 'payer'
  | 'address'
  | 'effectiveStatus'
  | 'payerStatus'
  | 'submittedDate'
  | 'disableBilling'
  | 'effectiveDate'
  | 'type'
  | 'actions'
  | 'archivedDate'
  | 'submissionType'
  | 'w9'
  | 'assignee'

type Row = Omit<AddressEnrollmentFragment, 'providerEnrollment'> & {
  name: string
  key: string
  provider: Pick<Provider, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  insurancePayer: Pick<InsurancePayer, 'id' | 'name'>
  w9Url: string
  assignee?: Pick<Login, 'id' | 'firstName' | 'lastName'> | null
  taskId?: string
}

type Query = ReturnType<typeof useSearchAddressEnrollmentsLazyQuery>[0]

type AssigneeEvent = AgGridEvent & {
  addressEnrollmentId: string
  assigneeLoginId: string | null
  insuranceTaskId?: string
}

type EnrollmentDataDialogOpenEvent = AgGridEvent & {
  providerId: string
}

type AddressDialogOpenEvent = AgGridEvent & {
  providerId: string
}

interface AddressEnrollmentTableProps extends HTMLAttributes<HTMLDivElement> {
  columns: ColumnKeys[]
  fixedFilters?: AddressEnrollmentFilterValues
  header?: ReactNode
}

const ITEM_LIMIT = 40
const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]
const ASSIGNEE_EVENT = 'assigneeEvent'
const ENROLLMENT_DATA_EVENT = 'openEnrollmentData'
const ADDRESS_DIALOG_OPEN_EVENT = 'addressDialogOpenEvent'

const createServerSideDatasource: (query: Query, filters?: AddressEnrollmentFilterValues) => IServerSideDatasource = (
  query,
  filters = {},
) => {
  return {
    getRows: params => {
      const page = Math.ceil(((params.request.startRow ?? 0) + 1) / ITEM_LIMIT)
      query({
        variables: {
          filters: mapFilterValues(filters),
          pagination: { limit: ITEM_LIMIT, page },
          order: INITIAL_SORT,
        },
        fetchPolicy: 'network-only',
      }).then(result => {
        const { data } = result

        if (data) {
          const {
            searchAddressEnrollments: { pagination },
          } = data

          params.success({ rowData: buildRows(data), rowCount: pagination?.totalCount ?? 0 })
        } else {
          params.fail()
        }
      })
    },
  }
}

const COL_DEFS: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    pinned: 'left',
    cellRenderer: NameColumnRenderer,
    resizable: true,
    checkboxSelection: true,
    cellClass: 'row-header-column',
  },
  {
    field: 'address',
    headerName: 'Address',
    valueFormatter: ({ value }) => addressService.formatAddress(value),
    resizable: true,
  },
  {
    field: 'address',
    headerName: 'Phone',
    valueFormatter: ({ value }) => value?.phone ?? '',
    resizable: true,
  },
  {
    field: 'provider',
    headerName: 'Provider',
    cellRenderer: ProviderCellRenderer,
  },
  { field: 'insurancePayer', headerName: 'Payer', cellRenderer: PayerCellRenderer },
  {
    field: 'payerStatus',
    headerName: 'Payer Status',
    cellRenderer: ({ value }: ICellRendererParams) => {
      let intent: Intent = 'default'

      switch (value) {
        case AddressEnrollmentStatus.Denied:
          intent = 'urgent'
          break
        case AddressEnrollmentStatus.Enrolled:
          intent = 'information'
      }
      return <StatusLabel intent={intent}>{startCase(value)}</StatusLabel>
    },
  },
  {
    field: 'followProviderEnrollment',
    headerName: 'Submission Type',
    valueFormatter: ({ value }) => (value ? 'Provider Enrollment' : 'Change of Address'),
  },
  {
    field: 'disableBilling',
    headerName: 'Disable Billing',
    cellRenderer: DisableBillingColumnRenderer,
  },
  {
    field: 'submittedDate',
    headerName: 'Submitted Date',
    valueFormatter: ({ value }) => (value ? moment.utc(value).format('LL') : ''),
  },
  {
    field: 'effectiveDate',
    headerName: 'Payer Effective Date',
    valueFormatter: ({ value }) => (value ? moment.utc(value).format('LL') : ''),
  },
  {
    field: 'archivedDate',
    headerName: 'Archived Date',
    valueFormatter: ({ value }) => (value ? moment.utc(value).format('LL') : ''),
  },
  {
    field: 'w9Url',
    headerName: 'W9',
    cellRenderer: W9ColumnRenderer,
    cellClass: 'v-align',
  },
  {
    field: 'assignee',
    headerName: 'Assignee',
    cellRenderer: AssigneeColumnRenderer,
  },
]

const transformUrlFilters = (
  values: Record<string, unknown>,
): AddressEnrollmentFilterValues & {
  assignee?: string
} => {
  return {
    providerId: values.ae_providerId as string | undefined,
    insurancePayerId: values.ae_insurancePayerId as string | undefined,
    effectiveStatus: values.ae_status as AddressEnrollmentStatus | undefined,
    payerStatus: values.ae_payerStatus as AddressEnrollmentStatus | undefined,
    assignee: values.ae_assignee as string | undefined,
    followProviderEnrollment: values.ae_followsProviderEnrollment as boolean | undefined,
  }
}

export function AddressEnrollmentTable({ fixedFilters, header, className, ...props }: AddressEnrollmentTableProps) {
  const gridRef = useRef<AgGridReact>(null)
  const urlFilters = useUrlFilters(transformUrlFilters)
  const { filterValues } = urlFilters
  const memoizedFilterValues = useMemo(() => ({ ...filterValues, ...fixedFilters }), [filterValues, fixedFilters])
  const [, setSearchParams] = useSearchParams()
  const [providerIdForEnrollmentData, setProviderIdForEnrollmentData] = useState<string | null>(null)
  const [selectedRowsCount, setSelectedRowsCount] = useState(0)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [updateDrawerIsOpen, setUpdateDrawerIsOpen] = useState(false)
  const [saveInsuranceTask] = useSaveInsuranceTaskMutation()

  const [query] = useSearchAddressEnrollmentsLazyQuery()

  const handleAssigneeChange = useCallback(
    async ({ assigneeLoginId, addressEnrollmentId, insuranceTaskId }: AssigneeEvent) => {
      try {
        await saveInsuranceTask({
          variables: {
            data: {
              addressEnrollmentId,
              assigneeLoginId,
              id: insuranceTaskId,
              taskType: InsuranceTaskType.AddressEnrollment,
            },
          },
        })
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'Error assigning task'))
      }
    },
    [saveInsuranceTask],
  )

  const handleOpenEnrollmentData = useCallback(({ providerId }: EnrollmentDataDialogOpenEvent) => {
    setProviderIdForEnrollmentData(providerId)
  }, [])

  const handleOpenAddressesDrawer = useCallback(
    ({ providerId }: AddressDialogOpenEvent) => {
      setSearchParams(() => {
        const params = new URLSearchParams(document.location.search)
        params.append('assignPracticeAddresses', providerId)
        return params
      })
    },
    [setSearchParams],
  )

  const handleSelectionChange = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes()
    let selectedRowsCount = selectedRows.length

    if (selectedRowsCount > ITEM_LIMIT) {
      while (selectedRowsCount > ITEM_LIMIT) {
        event.api.deselectNode(selectedRows[selectedRowsCount - 1])
        selectedRowsCount--
      }
    }
    setSelectedRowsCount(event.api.getSelectedRows().length)
  }, [])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.addEventListener(ASSIGNEE_EVENT, handleAssigneeChange)
      params.api.addEventListener(ENROLLMENT_DATA_EVENT, handleOpenEnrollmentData)
      params.api.addEventListener(ADDRESS_DIALOG_OPEN_EVENT, handleOpenAddressesDrawer)
      params.api.setServerSideDatasource(createServerSideDatasource(query, memoizedFilterValues))
    },
    [query, memoizedFilterValues, handleAssigneeChange, handleOpenEnrollmentData, handleOpenAddressesDrawer],
  )

  useEffect(() => {
    const gridApi = gridRef.current?.api

    if (gridApi) {
      gridApi.setServerSideDatasource(
        createServerSideDatasource(
          query,
          Object.keys(memoizedFilterValues).length > 0 ? memoizedFilterValues : undefined,
        ),
      )
    }
  }, [memoizedFilterValues, query])

  return (
    <Container {...props} className={csx([className, { 'selection-limit-reached': selectedRowsCount >= ITEM_LIMIT }])}>
      <Header>
        {header}
        <AddressEnrollmentFilters urlFilterService={urlFilters} fixedFilters={fixedFilters} />
        <div className="ml-auto v-align gap-2">
          <div className="bottom-align">
            <div className="caption text-seconary italic mb-xs">
              {selectedRowsCount} {selectedRowsCount === 1 ? 'address enrollment' : 'address enrollments'} selected (You
              can select up to {ITEM_LIMIT})
            </div>
            <DropdownButton
              className="ml-2"
              disabled={selectedRowsCount === 0}
              onClick={e => setMenuAnchorEl(e.currentTarget)}
              isActive={!!menuAnchorEl}
            >
              Actions
            </DropdownButton>
            <Menu
              open={!!menuAnchorEl}
              anchorEl={menuAnchorEl}
              onClose={() => setMenuAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <MenuItemStack>
                <MenuItem button onClick={() => setUpdateDrawerIsOpen(true)}>
                  Update Selected
                </MenuItem>
              </MenuItemStack>
            </Menu>
          </div>
        </div>
      </Header>
      <div className="flex-remaining-space ag-theme-material">
        <AgGridReact
          ref={gridRef}
          columnDefs={COL_DEFS}
          rowModelType="serverSide"
          pagination={true}
          paginationPageSize={ITEM_LIMIT}
          cacheBlockSize={ITEM_LIMIT}
          onGridReady={onGridReady}
          onSelectionChanged={handleSelectionChange}
          serverSideStoreType="partial"
          rowSelection="multiple"
          suppressRowClickSelection
        />
      </div>

      <ProviderEnrollmentDataDialog
        isOpen={!!providerIdForEnrollmentData}
        providerId={providerIdForEnrollmentData}
        onClose={() => setProviderIdForEnrollmentData(null)}
      />

      <UpdateAddressEnrollmentsDrawer
        getSelectedRows={() => gridRef.current?.api?.getSelectedRows() ?? []}
        isOpen={updateDrawerIsOpen}
        onClose={() => setUpdateDrawerIsOpen(false)}
        onSaved={() => gridRef.current?.api?.refreshServerSideStore()}
      />
    </Container>
  )
}

function buildRows(data?: SearchAddressEnrollmentsQuery) {
  if (!data?.searchAddressEnrollments.items) return []

  return data.searchAddressEnrollments.items.map<Row>(item => {
    return {
      ...item,
      key: item.id,
      name: item.address.internalName,
      provider: item.providerEnrollment.provider as Provider,
      insurancePayer: item.providerEnrollment.insurancePayer,
      w9Url: getW9Link({ addressEnrollmentId: item.id }),
      assignee: item?.insuranceTasks[0]?.assigneeLogin,
      taskId: item?.insuranceTasks[0]?.id,
    }
  })
}

function mapFilterValues(
  values?: AddressEnrollmentFilterValues | null,
): AddressEnrollmentSearchOptions | undefined | null {
  if (!values) {
    return values
  }

  const { assignee, ...sansAssignee } = values

  switch (assignee) {
    case 'me':
      ;(sansAssignee as AddressEnrollmentSearchOptions).assignedToMe = true
      break
    case 'none':
      ;(sansAssignee as AddressEnrollmentSearchOptions).notAssigned = true
      break
    default:
      ;(sansAssignee as AddressEnrollmentSearchOptions).assigneeLoginId = assignee
  }

  return sansAssignee
}

function NameColumnRenderer({ data, node, api }: ICellRendererParams) {
  return (
    <div className="v-align row-header-column">
      <span className="flex-remaining-space" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {data.name}
      </span>

      <AddressEnrollmentActionsMenu
        status={data.payerStatus}
        followProviderEnrollment={data.followProviderEnrollment}
        id={data.id}
        onUpdated={addressEnrollment => node.setData({ ...data, ...addressEnrollment })}
        // @ts-expect-error - Ag Grid didn't extend event type to allow for custom data
        onOpenEnrollmentData={() => api.dispatchEvent({ type: ENROLLMENT_DATA_EVENT, providerId: data.provider.id })}
        onOpenAddressesDrawer={() =>
          // @ts-expect-error - Ag Grid didn't extend event type to allow for custom data
          api.dispatchEvent({ type: ADDRESS_DIALOG_OPEN_EVENT, providerId: data.provider.id })
        }
      />
    </div>
  )
}

function ProviderCellRenderer({ value }: ICellRendererParams) {
  return <UserLink user={value} customLink={`/providers/${value.id}/preferences/locations`} />
}

function PayerCellRenderer({ value }: ICellRendererParams) {
  return <Link to={routeService.insurancePayer(value.id)}>{value.name}</Link>
}

function W9ColumnRenderer({ data }: ICellRendererParams) {
  return <AddressW9DropdownMenu address={data.address} insurancePayer={data.insurancePayer} />
}

function AssigneeColumnRenderer({ value, data, api }: ICellRendererParams) {
  return (
    <AssigneePicker
      style={{ width: 200 }}
      value={value}
      onChange={assigneeId => {
        api.dispatchEvent({
          type: ASSIGNEE_EVENT,
          // @ts-expect-error - Ag Grid didn't extend event type to allow for custom data
          addressEnrollmentId: data.id,
          assigneeLoginId: assigneeId,
          insuranceTaskId: data.taskId,
          api,
        })
      }}
    />
  )
}

const Container = styled(
  makeTypographyComponent(
    'flex-column flex-remaining-space overflow-hidden full-width address-enrollment-table-wrapper',
    'div',
  ),
)`
  &.selection-limit-reached {
    .row-header-column {
      .ag-checkbox-input-wrapper:not(.ag-checked) {
        pointer-events: none;
        &:after {
          opacity: 0.3;
          pointer-events: none;
        }
        input {
          pointer-events: none;
        }
      }
    }
  }
`

const Header = makeTypographyComponent('v-align mb-2 flex-wrap gap-2', 'div')

function DisableBillingColumnRenderer({ value, data, node }: ICellRendererParams) {
  const [saveAddressEnrollment] = useSaveAddressEnrollmentMutation()

  const handleChange = async () => {
    try {
      const result = await saveAddressEnrollment({
        variables: { addressEnrollment: { id: data.id, disableBilling: !value } },
      })

      node.setData({ ...data, ...result.data?.saveAddressEnrollment })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error updating address enrollment'))
    }
  }
  return (
    <div className="full-height v-align">
      <Switch checked={value} onChange={handleChange}></Switch>
    </div>
  )
}
