import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { useState } from 'react'

import { AdminNote, useAdminNotesQuery, useRemoveAdminNoteMutation, useSaveAdminNoteMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import {
  Card,
  CardBody,
  CardBoot,
  CardHeader,
  Confirm,
  Drawer,
  DrawerProps,
  FillButton,
  IconTrash,
  TextField,
  greySet,
  toast,
} from '@nuna/tunic'

interface Props extends DrawerProps {
  userId?: string
  onChange?: () => void
}

export function AdminNotesDrawer({ userId, isOpen, onClose, onChange, ...props }: Props) {
  const [adminNoteToRemove, setAdminNoteToRemove] = useState<AdminNote | null>(null)
  const [adminNote, setNewAdminNote] = useState('')
  const [saveAdminNote, { loading: saving }] = useSaveAdminNoteMutation()
  const [removeAdminNote, { loading: deleting }] = useRemoveAdminNoteMutation()

  const { data, loading, refetch } = useAdminNotesQuery({
    variables: {
      searchInput: {
        userId,
      },
    },
    skip: !isOpen || !userId,
  })

  const handleNewAdminNoteSave = async () => {
    try {
      await saveAdminNote({
        variables: {
          saveInput: {
            note: adminNote?.trim(),
            userId,
          },
        },
      })

      setNewAdminNote('')
      refetch()
      onChange && onChange()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving the note.'))
    }
  }

  const handleRemoveAdminNote = async (adminNote: AdminNote | null) => {
    if (!adminNote) return

    try {
      setAdminNoteToRemove(null)

      await removeAdminNote({
        variables: {
          id: adminNote.id,
        },
      })

      refetch()
      onChange && onChange()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error deleting the note.'))
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => !saving && !deleting && onClose()}
      {...props}
      size="600px"
      style={{ overflowY: 'hidden' }}
    >
      <DrawerHeader className="v-align">
        <TextField
          multiline
          onChange={e => setNewAdminNote(e.currentTarget.value)}
          label="New Note"
          rows={2}
          rowsMax={10}
          value={adminNote}
          style={{ width: '478px' }}
        />
        <FillButton
          variant="primary"
          disabled={loading || deleting || saving || !adminNote?.length}
          onClick={() => handleNewAdminNoteSave()}
        >
          Save
        </FillButton>
      </DrawerHeader>
      <DrawerContent>
        {!data?.adminNotes?.length && <span>Nothing to show</span>}

        {data?.adminNotes?.map(adminNote => (
          <Card depth={1} intent="default" shadowColor="warm" className="mb-2">
            <CardHeader className="space-between v-align" style={{ paddingBottom: '8px' }}>
              <span className="text-medium">
                {adminNote.createdByUser?.firstName} {adminNote.createdByUser?.lastName}
              </span>
              <IconTrash
                size={20}
                onClick={() => setAdminNoteToRemove(adminNote as AdminNote)}
                style={{ cursor: 'pointer' }}
              />
            </CardHeader>
            <CardBody>{adminNote.note}</CardBody>
            <CardBoot intent="default">
              <div> on {moment(adminNote.createdAt).format('MMM DD YYYY [at] h:mma')}</div>
            </CardBoot>
          </Card>
        ))}

        <Confirm
          isOpen={!!adminNoteToRemove}
          onConfirm={response => (response ? handleRemoveAdminNote(adminNoteToRemove) : setAdminNoteToRemove(null))}
        >
          Are you sure you want to delete administrative note created on{' '}
          <span className="text-medium">{moment(adminNoteToRemove?.createdAt).format('MMM DD YYYY [at] h:mma')}</span>{' '}
          by{' '}
          <span className="text-medium">
            {adminNoteToRemove?.createdByUser?.firstName} {adminNoteToRemove?.createdByUser?.lastName}
          </span>
          ?
        </Confirm>
      </DrawerContent>
    </Drawer>
  )
}

const DrawerHeader = styled('div')`
  padding: 24px;
  border-bottom: 1px solid ${greySet[15].hex};
`

const DrawerContent = styled('div')`
  padding: 24px;
  overflow-y: scroll;
`
