import { styled } from '@mui/material'
import { capitalize, noop, startCase } from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  AccountSource,
  AppointmentCurrentStatus,
  InternalAppointmentDetailQuery,
  SessionFinancialFragment,
  useAppointmentVideoConversationMessagesQuery,
  useInternalAppointmentDetailQuery,
  useResetAppointmentMutation,
} from '@nuna/api'
import {
  AdminPayoutAdjustmentForm,
  AppointmentLocationIcon,
  CancelAsAdminForm,
  MarkNoShowAsAdminForm,
  WriteOffForm,
} from '@nuna/appointment'
import { TimeZoneControl, UserLink, useTimeZoneContext } from '@nuna/common'
import { addressService, appointmentService, errorService, financialsService } from '@nuna/core'
import { TransactionHistory } from '@nuna/coverage'
import { DataTable, DataTableState } from '@nuna/data-table'
import {
  Confirm,
  Drawer,
  FillButton,
  InfoWithHeading,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  StatusLabel,
  TextButton,
  toast,
} from '@nuna/tunic'

import { sessionNotes } from '../../util/routes'
import { columns } from '../provider-payroll/ProviderPayrollProviderDetailRow'
import { AppointmentConversationTable } from './AppointmentConversationTable'
import { ClaimsInfo } from './ClaimsInfo'
import { ManageAppointment } from './ManageAppointment/ManageAppointment'

const { AppointmentStatusMap, appointmentChangeReasons } = appointmentService

const DrawerContent = styled('div')`
  padding: 1.6rem;
`

type InternalAppointment = NonNullable<InternalAppointmentDetailQuery['internalAppointment']>

type OpenDrawer = 'none' | 'cancel' | 'no-show' | 'write-off' | 'admin-adjustment'

type PathParams = {
  appointmentId: string
}

function getScheduledBy({ createdById, source, provider, patient }: InternalAppointment): string {
  if (source === AccountSource.Zocdoc) {
    return source
  }
  if (provider?.loginId === createdById) {
    return 'Provider'
  }
  if (patient?.loginId === createdById) {
    return 'Patient'
  }

  return 'Admin'
}

const initialPayoutDetailsState: DataTableState<SessionFinancialFragment> = {
  sortBy: [{ id: 'providerCompensationPayableDate', desc: true }],
}

const payoutColumns = columns(() => noop, false).filter(c =>
  [
    'id',
    'providerCompensationPayableDate',
    'externalPayrollStatus',
    'reason',
    'cptCode',
    'providerCompensatedAmount',
  ].includes(c.accessor as string),
)

export function AppointmentDetail() {
  const { appointmentId = '' } = useParams<PathParams>()
  const { timeZoneChange, addUserInContext, removeUserInContext, timeZoneToUse } = useTimeZoneContext()
  const { error, data } = useInternalAppointmentDetailQuery({
    variables: { id: appointmentId, includeFinancials: true },
    fetchPolicy: 'network-only',
  })

  const { data: conversationData } = useAppointmentVideoConversationMessagesQuery({
    variables: { appointmentId },
    fetchPolicy: 'network-only',
  })

  const [resetAppointment, { loading }] = useResetAppointmentMutation()

  const navigate = useNavigate()

  const [openDrawer, setOpenDrawer] = useState<OpenDrawer>('none')
  const [showResetConfirmation, setShowResetConfirmation] = useState(false)
  const closeDrawer = useCallback(() => setOpenDrawer('none'), [])

  const appointment = useMemo(() => data?.internalAppointment, [data])
  const payoutDetails = useMemo(() => appointment?.payoutDetails, [appointment])

  const handleReset = useCallback(
    async (confirmed: boolean) => {
      if (!confirmed) {
        setShowResetConfirmation(false)
        return
      }

      try {
        const { data: resetResult } = await resetAppointment({
          variables: { appointmentId: appointment?.id ?? '' },
          refetchQueries: ['InternalAppointmentDetail'],
        })

        const warning = resetResult?.resetAppointment?.warning

        if (warning) toast.caution(warning)
        else toast.success(`Appointment reset`)
      } catch (error) {
        console.error(error)
        toast.urgent(errorService.transformGraphQlError(error, 'Failed to reset appointment'))
      } finally {
        setShowResetConfirmation(false)
      }
    },
    [appointment?.id, resetAppointment],
  )

  useEffect(() => {
    addUserInContext(appointment?.provider)
    addUserInContext(appointment?.patient)
    return () => {
      removeUserInContext('Provider')
      removeUserInContext('Patient')
    }
  }, [timeZoneChange, addUserInContext, removeUserInContext, appointment])

  if (error || !appointment) return null

  const { currentChangeReason, currentFreeFormReason, currentReasonDate, createdById, provider, patient, address } =
    appointment

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading className="m-0 v-align">
          <span>Appointment Detail</span> <TimeZoneControl className="ml-1 v-align" />
        </PageHeading>
        <ManageAppointment appointmentId={appointmentId} />
      </PageHeader>
      <PageContent>
        <h2 className="h6 mb-3">Overview</h2>

        {createdById && <InfoWithHeading heading="Scheduled By" info={getScheduledBy(appointment)} />}

        <InfoWithHeading
          heading="Date Created"
          info={moment.tz(appointment.createdAt, timeZoneToUse).format('M/D/YYYY [at] LT')}
        />
        <InfoWithHeading
          heading="Scheduled Start Time"
          info={moment.tz(appointment.startDatetime, timeZoneToUse).format('M/D/YYYY [at] LT')}
        />
        <InfoWithHeading
          heading="Location"
          info={
            <span className="v-align">
              <AppointmentLocationIcon iconSize={14} appointment={{ address }} />
            </span>
          }
          secondaryInfo={address?.name}
          tertiaryInfo={address && <span>{addressService.formatAddress(address)}</span>}
        />

        <InfoWithHeading heading="Provider" info={<UserLink user={provider} />} secondaryInfo={provider.timezone} />
        <InfoWithHeading heading="Client" info={<UserLink user={patient} />} secondaryInfo={patient.timezone} />
        <InfoWithHeading
          heading="Status"
          info={
            appointment.session?.id ? (
              <>
                <StatusLabel intent="information">Note Completed</StatusLabel> on{' '}
                {moment.tz(appointment.session.createdAt, timeZoneToUse).format('l [at] h:mm A')}
                <TextButton className="ml-4" onClick={() => navigate(sessionNotes(appointment.session?.id ?? ''))}>
                  View Session Note
                </TextButton>
              </>
            ) : (
              <div>
                {getAppointmentStatus(appointment)}
                {currentReasonDate && <> on {moment.tz(currentReasonDate, timeZoneToUse).format('M/D/YYYY [at] LT')}</>}
              </div>
            )
          }
        />

        {currentChangeReason && (
          <InfoWithHeading
            heading="Status Details"
            info={
              <>
                {appointmentChangeReasons.find(reason => reason.value === currentChangeReason)?.display}
                {currentFreeFormReason && (
                  <>
                    <br />
                    Note: "{currentFreeFormReason}"
                  </>
                )}
              </>
            }
          />
        )}

        <InfoWithHeading
          heading="Financial Attribution"
          info={financialsService.getFinancialAttributionText(appointment.financialAttribution)}
        />
        <InfoWithHeading
          heading="Client Payment Type"
          info={financialsService.getPaymentTypeText(appointment.whoIsResponsibleForPayment)}
        />
        {appointment.writtenOffDate && (
          <InfoWithHeading
            heading="Written Off"
            info={moment.tz(appointment.writtenOffDate, timeZoneToUse).format('M/D/YYYY')}
            secondaryInfo={`By ${appointment.writtenOffBy}`}
            tertiaryInfo={`Reason: ${capitalize(startCase(appointment.writtenOffReason ?? ''))}`}
          />
        )}

        <h2 className="h6 mt-4 mb-3">Provider Payouts</h2>
        {provider && (
          <DataTable
            loading={false}
            initialState={initialPayoutDetailsState}
            columns={payoutColumns}
            rowData={payoutDetails}
          />
        )}

        <h2 className="h6 mt-4 mb-3">Client Transactions</h2>
        {patient && <TransactionHistory appointmentId={appointment.id} patientId={patient.id} />}

        <h2 className="h6 mt-4 mb-3">Claims</h2>
        <ClaimsInfo sessionId={appointment.session?.id} />

        <h2 className="h6 mt-4 mb-3">Session Chat</h2>
        <AppointmentConversationTable
          provider={appointment.provider}
          patient={appointment.patient}
          messages={conversationData?.appointmentVideoConversationMessages || []}
        />

        <Drawer isOpen={openDrawer !== 'none'} onClose={closeDrawer} size="400px">
          <DrawerContent>
            {openDrawer === 'cancel' && <CancelAsAdminForm appointment={appointment} onComplete={closeDrawer} />}
            {openDrawer === 'no-show' && <MarkNoShowAsAdminForm appointment={appointment} onComplete={closeDrawer} />}
            {openDrawer === 'write-off' && <WriteOffForm appointmentId={appointment.id} onComplete={closeDrawer} />}
            {openDrawer === 'admin-adjustment' && (
              <AdminPayoutAdjustmentForm appointmentId={appointment.id} onComplete={closeDrawer} />
            )}
          </DrawerContent>
        </Drawer>

        <Confirm
          isOpen={showResetConfirmation}
          onConfirm={handleReset}
          confirmButton={
            <FillButton disabled={loading} isLoading={loading} onClick={() => handleReset(true)}>
              Confirm
            </FillButton>
          }
        >
          <div className="p-2">
            <p className="mb-3">Are you sure you want to reset this appointment?</p>
            <p>The status will be reset back to Active and all cancelation or no-show actions will be cleared.</p>
          </div>
        </Confirm>
      </PageContent>
    </PageWrapper>
  )
}

const getAppointmentStatus = (appt: NonNullable<InternalAppointmentDetailQuery['internalAppointment']>) => {
  if (appt.currentStatus === AppointmentCurrentStatus.Active) {
    if (moment(appt.startDatetime).isAfter(moment())) {
      return AppointmentStatusMap[AppointmentCurrentStatus.Active]
    } else {
      return appt?.session?.id ? '' : 'Needs Session Note'
    }
  } else {
    return AppointmentStatusMap[appt.currentStatus]
  }
}
