import { flatten } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { ProviderEnrollmentInput, ProviderEnrollmentStatus } from '@nuna/api'
import {
  PayerListItem,
  PayerSearch,
  ProviderSearch,
  SearchPayer,
  SearchProvider,
  useProviderEnrollmentsAndStates,
} from '@nuna/common'
import { toast } from '@nuna/tunic'

import {
  AggregateListComponentType,
  ListBox,
  ListBoxProps,
  ListComponentType,
  ListItemProps,
  type SearchComponentType,
} from '../ListBox/ListBox'
import { SelectedEnrollmentsTree } from './SelectedEnrollmentsTree'
import { ChildViewProps, SelectedPayer } from './types'

export function AddPayerEnrollments({ onSave, isSaving, viewScopeId }: ChildViewProps) {
  const [providers, setProviders] = useState<(SearchProvider & ListItemProps)[]>([])
  const [payers, setPayers] = useState<(SearchPayer & ListItemProps)[]>([])
  const [selectedProviders, setSelectedProviders] = useState<(SearchProvider & ListItemProps)[]>([])
  const [selectedPayers, setSelectedPayers] = useState<(SelectedPayer & ListItemProps)[]>([])
  const { providerMissingCredentialedState, providerHasPayerEnrollment, resetMap } =
    useProviderEnrollmentsAndStates(selectedProviders)

  const handleSelectProviders = (_: SearchProvider, selectedProviders: SearchProvider[]) => {
    setSelectedProviders(selectedProviders)
  }

  const handleRemoveProviders = (_: SearchProvider, selectedProviders: SearchProvider[]) => {
    setSelectedProviders(selectedProviders)
  }

  const removeProvider = useCallback((providerId: string) => {
    setSelectedProviders(prev => prev.filter(provider => provider.id !== providerId))
  }, [])

  const convertToEnrollmentBulkPayload = (
    providers: SearchProvider[],
    payers: SearchPayer[],
  ): ProviderEnrollmentInput[] => {
    const payload = providers.map(provider => {
      return payers.map(payer => {
        return {
          active: true,
          disableBilling: false,
          providerId: provider.id,
          insurancePayerId: payer.id,
          payerStatus: payer.enrollmentStatus || ProviderEnrollmentStatus.Pending,
        }
      })
    })

    return flatten(payload)
  }

  const handleSave = () => {
    onSave(convertToEnrollmentBulkPayload(selectedProviders, selectedPayers))
    resetMap()
  }

  const handleSearchProvidersChange = (changeValue: string | SearchProvider[]) => {
    if (typeof changeValue === 'string') return
    if (changeValue) {
      setProviders(changeValue as SearchProvider[])
    }
  }

  const handleSearchPayersChange = (changeValue: string | never[]) => {
    if (typeof changeValue === 'string') return
    if (changeValue) {
      setPayers(changeValue as SearchPayer[])
      setSelectedPayers(changeValue as SearchPayer[])
    }
  }

  const handleSelectedPayerOptionsChange = (payer: SearchPayer) => {
    const payers = selectedPayers.filter(selectedPayer => selectedPayer.id !== payer.id)
    if (payer) {
      setSelectedPayers([...payers, payer])
    }
  }

  const listBoxProps: ListBoxProps<SearchPayer, SearchProvider> = {
    columns: {
      primary: {
        title: 'Payer',
        wantsSearch: true,
        listProps: {
          Component: PayerListItem as unknown as ListComponentType<SearchPayer>,
          props: {
            items: payers,
            selectedItems: selectedPayers,
            isLoading: false,
            isSingleItem: true,
            onClick: handleSelectedPayerOptionsChange,
          },
        },
        searchProps: {
          Component: PayerSearch as unknown as SearchComponentType,
          props: {
            label: 'Search Payers',
            limit: 1,
            entityId: viewScopeId,
            onChange: handleSearchPayersChange,
          },
        },
      },
      secondary: {
        title: 'Select Providers',
        wantsSearch: true,
        listProps: {
          props: {
            items: providers,
            selectedItems: selectedProviders,
            isLoading: false,
            canSelectMultiple: true,
            onSelect: handleSelectProviders,
            onDeselect: handleRemoveProviders,
          },
        },
        searchProps: {
          Component: ProviderSearch as unknown as SearchComponentType,
          props: {
            label: 'Search Providers',
            limit: 25,
            loadInitial: true,
            onChange: handleSearchProvidersChange,
          },
        },
      },
      aggregate: {
        title: 'Proposed Enrollments',
        listProps: {
          Component: SelectedEnrollmentsTree as unknown as AggregateListComponentType<SelectedPayer, SearchProvider>,
          props: {
            primaryData: selectedPayers,
            secondaryData: selectedProviders,
          },
        },
      },
    },
    actions: {
      primary: {
        actionTitle: 'Save',
        onAction: handleSave,
        isDisabled: isSaving,
      },
    },
  }

  useEffect(() => {
    selectedPayers.forEach(payer => {
      const alreadyEnrolledProviderId = providerHasPayerEnrollment(payer.id)
      if (alreadyEnrolledProviderId) {
        removeProvider(alreadyEnrolledProviderId)
        toast.urgent(`Provider is already enrolled with this payer`)
      }
    })
  }, [selectedProviders, selectedPayers, providerMissingCredentialedState, providerHasPayerEnrollment, removeProvider])

  return <ListBox<SearchPayer, SearchProvider> {...listBoxProps} />
}
