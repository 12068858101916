import { DialogActions, DialogContentText, DialogTitle } from '@mui/material'
import { useState } from 'react'

import {
  EligibleRecordInfo,
  EmploymentStatus,
  PatientCompanyHistoryQuery,
  RelationToSponsoringEmployee,
  useAssociatePatientWithEligibleRecordMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { CustomerEmployeeRecordSelect, SelectCustomerEmployeeRecord } from '@nuna/customer'
import { Dialog, DialogContent, FillButton, GhostButton, Select, toast } from '@nuna/tunic'

type CompanyHistoryItem = PatientCompanyHistoryQuery['patientCompanyHistory']['historyItems'][number]

interface Props {
  patientId: string
  item: CompanyHistoryItem
  isEmployee: boolean
  onClose: () => void
}

const linkedRecordToSelectRecord = (
  eligibleRecord?: EligibleRecordInfo | null,
): SelectCustomerEmployeeRecord | undefined => {
  if (!eligibleRecord) return undefined

  return {
    id: eligibleRecord.id,
    firstName: eligibleRecord.firstName ?? '',
    lastName: eligibleRecord.lastName ?? '',
    employmentStatus:
      Object.values(EmploymentStatus).find(s => s.toString() === eligibleRecord.employmentStatus) ??
      EmploymentStatus.Active,
  }
}

export function AssociateClientDialog({ patientId, item, isEmployee, onClose }: Props) {
  const [linkedMember, setLinkedMember] = useState<SelectCustomerEmployeeRecord | undefined>(
    linkedRecordToSelectRecord(isEmployee ? item.linkedEmployee : item.linkedSponsor),
  )
  const [relationship, setRelationship] = useState<RelationToSponsoringEmployee>(
    item.linkedSponsorRelation ?? RelationToSponsoringEmployee.Child,
  )
  const [associatePatientWithEligibleRecordMutation, { loading }] = useAssociatePatientWithEligibleRecordMutation()

  const handleAssociateMember = async () => {
    try {
      if (!item.companyContractSummary?.companyId) throw new Error('No company ID found')

      const variables = {
        patientID: patientId,
        companyId: item.companyContractSummary.companyId,
        sponsoringEligibleRecordID: (isEmployee ? item.linkedSponsor?.id : linkedMember?.id) ?? null,
        eligibleRecordID: (isEmployee ? linkedMember?.id : item.linkedEmployee?.id) ?? null,
        relationToSponsoringEmployee: isEmployee
          ? item.linkedSponsorRelation ?? null
          : linkedMember
          ? relationship
          : null,
      }
      const response = await associatePatientWithEligibleRecordMutation({
        variables,
        refetchQueries: ['PatientCompanyHistory'],
        awaitRefetchQueries: true,
      })

      if (response.errors) {
        toast.urgent(errorService.transformUserGraphqlError(response.errors[0], 'Unable to link member'))
      } else {
        toast.success('Successfully linked member')
        onClose()
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Unable to link member'))
    }
  }

  return (
    <Dialog isOpen={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit {isEmployee ? 'Employee' : 'Sponsor'} Member Link</DialogTitle>
      <DialogContent paddingSize="md">
        <DialogContentText>
          <p className="body">You can search for a member record below or clear the current member.</p>
          {!isEmployee && (
            <Select
              value={relationship ?? ''}
              label="Relationship"
              onChange={event =>
                setRelationship(
                  Object.values(RelationToSponsoringEmployee).find(v => v.toString() === event.currentTarget.value) ??
                    RelationToSponsoringEmployee.Child,
                )
              }
            >
              <option value={RelationToSponsoringEmployee.Child}>Child</option>
              <option value={RelationToSponsoringEmployee.SpousePartner}>Spouse or Partner</option>
            </Select>
          )}
          <CustomerEmployeeRecordSelect
            className="mt-1"
            label={isEmployee ? 'Employee' : 'Sponsor'}
            companyId={item.companyContractSummary?.companyId ?? ''}
            onChange={setLinkedMember}
            value={linkedMember}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={onClose}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={() => handleAssociateMember()} isLoading={loading}>
          Save
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
