import { styled } from '@mui/material'
import { startCase } from 'lodash'
import { useEffect } from 'react'

import { useIntegrationsLazyQuery, usePatientQuery } from '@nuna/api'
import { borderGrey } from '@nuna/tunic'

import { PatientIntake } from './PatientIntake'

interface Props {
  patientId: string
}

const Section = styled('div')`
  margin-bottom: 4rem;
`

export function ClientAccount({ patientId }: Props) {
  const { data } = usePatientQuery({
    variables: {
      id: patientId,
    },
  })

  const [listIntegrations, { data: integrationsResult }] = useIntegrationsLazyQuery()

  useEffect(() => {
    if (data?.patient.login?.id) {
      listIntegrations({
        variables: { userId: data.patient.login.id },
      })
    }
  }, [data, listIntegrations])

  if (!data) {
    return null
  }

  return (
    <div>
      <Section>
        <h1 className="h5 mb-2 text-dark">Integrations</h1>

        <SettingsTable>
          <colgroup>
            <col span={1} style={{ width: '30%' }} />
            <col span={3} style={{ width: '70%' }} />
          </colgroup>
          <tbody>
            {(integrationsResult?.integrations ?? []).map(it => (
              <tr key={`${it.type}-${it.id}`}>
                <td>{startCase(it.type.toLowerCase())}</td>
                <td colSpan={3}>
                  {it.url ? (
                    <a href={it.url} target="_blank" rel="noreferrer">
                      {it.url}
                    </a>
                  ) : (
                    'ID: ' + it.id
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </SettingsTable>
      </Section>
      <Section>
        <h1 className="h5 mb-2 text-dark">Account</h1>

        <SettingsTable>
          <colgroup>
            <col span={1} style={{ width: '30%' }} />
            <col span={3} style={{ width: '70%' }} />
          </colgroup>
          <tbody>
            <PatientIntake patientId={patientId} />
          </tbody>
        </SettingsTable>
      </Section>
    </div>
  )
}

const SettingsTable = styled('table')`
  border-spacing: 0;
  font-size: 14px;

  td {
    border-top: 1px solid ${borderGrey};
    padding: 1rem;

    &:not(:first-of-type) {
      border-left: 1px solid ${borderGrey};
    }
  }
`
