import { styled } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import { useState } from 'react'

import {
  InsurancePayerNetwork,
  useInsurancePayerNetworksQuery,
  useSaveInsurancePayerNetworkMutation,
  useSavePayerMutation,
} from '@nuna/api'
import { Confirm, FillButton } from '@nuna/tunic'

interface Props {
  payerId: string
}

export function AddToPayerNetwork({ payerId }: Props) {
  const [value, setValue] = useState<Pick<InsurancePayerNetwork, 'name' | 'id'> | null>(null)
  const [networkToAdd, setNetworkToAdd] = useState<string | undefined>()
  const [savePayerNetwork] = useSaveInsurancePayerNetworkMutation()
  const [savePayer] = useSavePayerMutation()
  const { data } = useInsurancePayerNetworksQuery({ variables: { searchInput: {} }, fetchPolicy: 'network-only' })

  const options = data?.insurancePayerNetworks ?? []

  const handleAddToNetwork = async (insurancePayerNetworkId: string) => {
    return savePayer({ variables: { payerInput: { id: payerId, insurancePayerNetworkId } } })
  }

  const handleAddNetwork = async (shouldAdd: boolean) => {
    if (shouldAdd && networkToAdd) {
      const result = await savePayerNetwork({ variables: { saveInput: { name: networkToAdd } } })
      const newNetwork = result.data?.saveInsurancePayerNetwork
      if (newNetwork) {
        setValue(newNetwork)
        await handleAddToNetwork(newNetwork.id)
      }
      setNetworkToAdd(undefined)
    } else {
      setNetworkToAdd(undefined)
    }
  }

  const getLabel = (inputValue: string) => {
    return inputValue && inputValue.length > 2 && !options.find(option => option.name === inputValue)
      ? 'Press enter to add'
      : 'Select network'
  }

  return (
    <Container>
      <h5>Add to network or create new network</h5>
      <Autocomplete
        value={value}
        options={options}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          return option.name
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setNetworkToAdd(newValue)
          } else {
            setValue(newValue)
          }
        }}
        renderInput={params => (
          <TextField {...params} label={getLabel((params.inputProps as { value: string }).value)} />
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
      />

      <FillButton disabled={!value} className="mt-3" onClick={() => handleAddToNetwork(value?.id ?? '')}>
        Add To Network
      </FillButton>

      <Confirm isOpen={!!networkToAdd} onConfirm={handleAddNetwork}>
        Create <b>{networkToAdd}</b> and add this payer?
      </Confirm>
    </Container>
  )
}

const Container = styled('div')`
  width: 440px;
`
