import { styled } from '@mui/material'

import { FormProviderSelect } from '@nuna/common'
import { Card, GhostButton, IconClose, StateAutocompleteSingle, csx } from '@nuna/tunic'

export type FilterValues = {
  providerId?: string | null
  state?: string | null
}

interface Props {
  filterValues: FilterValues
  fixedFilters?: FilterValues
  setFilterVal: (key: keyof FilterValues, value: unknown) => void
  clearFilters: () => void
}

export function ProviderPracticeAddressTableFilters({ filterValues, fixedFilters, setFilterVal, clearFilters }: Props) {
  const hasFiltersApplied = Object.values(filterValues).some(value => !!value)

  return (
    <StyledCard depth={-0.5} className="p-2 mb-1 v-align gap-3">
      {!fixedFilters?.providerId && (
        <FormProviderSelect
          className="filter-input-wide"
          value={filterValues.providerId || null}
          onChange={provider => setFilterVal('providerId', provider?.id)}
        />
      )}
      <StateAutocompleteSingle
        label=""
        placeholder="Filter by state"
        className="filter-input"
        value={filterValues.state}
        onChange={stateOption => setFilterVal('state', stateOption?.value)}
      />
      <GhostButton
        className={csx([{ hidden: !hasFiltersApplied }, 'ml-auto clear-button'])}
        variant="secondary"
        onClick={clearFilters}
      >
        <IconClose size={14} className="mr-xs" />
        Clear all
      </GhostButton>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  .filter-input {
    width: 200px;
  }

  .filter-input-wide {
    width: 250px;
  }

  .clear-button {
    &.hidden {
      visibility: hidden;
    }
  }
`
