import { PayerRosterDefinitionConfig, PayerRosterDefinitionConfigColumn, PayerRosterDefinitionInput } from '@nuna/api'

export type ColumnValueType = 'static' | 'mapped'
export type ColumnTransformationType = 'none' | 'preset' | 'valueMapping' | 'eval' | 'regex' | 'boolean'

export enum CustomDenormColumn {
  RecordType = 'recordType',
  ChangeType = 'changeType',
}

export type PayerRosterDefinitionFormValues = Omit<PayerRosterDefinitionInput, 'config'> & {
  config: PayerRosterDefinitionConfig
}

export interface ColumnFormValues extends Omit<PayerRosterDefinitionConfigColumn, 'key'> {
  key?: string
  valueType: ColumnValueType
  transformationType: ColumnTransformationType
}

export enum PayerRosterSheetFilter {
  Add = 'add',
  Change = 'change',
  Term = 'term',
  NoChange = 'no_change',
  Recredentialed = 'recredentialed',
}

export type TransformationPresetArgs = Record<string, unknown>
