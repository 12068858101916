import { ReactNode, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { usePayerRosterDefinitionQuery, usePromotePayerRosterDefinitionMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import {
  Confirm,
  ConfirmRef,
  FillButton,
  GhostButtonLink,
  IconFileDocument,
  IconSettings,
  NavMenuButtonLink,
  Tooltip,
  toast,
} from '@nuna/tunic'

export function PayerRosterDefinitionHeader() {
  const { payerRosterDefinitionId = '' } = useParams()
  const confirmRef = useRef<ConfirmRef>(null)
  const [promotePayerRosterDefinition] = usePromotePayerRosterDefinitionMutation()
  const { data } = usePayerRosterDefinitionQuery({
    variables: { filters: { id: payerRosterDefinitionId } },
    fetchPolicy: 'cache-and-network',
  })

  const version = data?.payerRosterDefinition?.config.version

  const isMajorVersion = version ? version?.split('.')[1] === '0' : true

  const handlePromote = async () => {
    try {
      await promotePayerRosterDefinition({ variables: { payerRosterDefinitionId } })
      toast.success('Roster definition promoted')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to promote roster definition'))
    }
  }

  const handePromoteClick = () => {
    confirmRef.current?.open(response => {
      if (response) {
        handlePromote()
      }
    })
  }

  return (
    <div className="v-align">
      <div className="caption italic text-secondary">Version: {version}</div>
      <div className="v-align gap-1 ml-2">
        <PayerRosterDefinitionLink tooltip="UI Editor" path="editor">
          <IconSettings size={16} />
        </PayerRosterDefinitionLink>
        <PayerRosterDefinitionLink tooltip="Raw Editor" path="raw">
          <IconFileDocument size={22} />
        </PayerRosterDefinitionLink>
      </div>
      <GhostButtonLink className="ml-2" to={`../../rosters/${payerRosterDefinitionId}`}>
        Stop editing
      </GhostButtonLink>
      <FillButton className="ml-2" disabled={isMajorVersion} onClick={handePromoteClick}>
        Promote
      </FillButton>
      <Confirm ref={confirmRef}>
        Are you sure you want to promote the current draft version from {version} to the major version{' '}
        {getNextDraftVersion(version)}? Version {getNextDraftVersion(version)} will be used to publish rosters.
      </Confirm>
    </div>
  )
}

interface PayerRosterDefinitionLinkProps {
  path: string
  children: ReactNode
  tooltip: ReactNode
}

function PayerRosterDefinitionLink({ path, tooltip, children }: PayerRosterDefinitionLinkProps) {
  return (
    <Tooltip content={tooltip}>
      <NavMenuButtonLink to={path}>{children}</NavMenuButtonLink>
    </Tooltip>
  )
}

function getNextDraftVersion(version?: string | null) {
  if (!version) return ''

  return parseInt(version.split('.')[0]) + 1 + '.0'
}
