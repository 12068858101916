import { styled } from '@mui/material'
import { ColDef, ColumnMovedEvent, GetMainMenuItemsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { FormikProps } from 'formik'
import { camelCase, compact, debounce, isUndefined, uniq } from 'lodash'
import { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  PayerRosterDefinitionConfigColumn,
  PayerRosterDefinitionConfigSheet,
  usePayerRosterSheetDataQuery,
} from '@nuna/api'
import { Confirm, ConfirmRef, makeTypographyComponent, salmonSet } from '@nuna/tunic'

import { PayerRosterDefinitionFormValues } from '../PayerRosterDefinition.types'
import {
  AddColumnEvent,
  CreateColumnEvent,
  RosterDefinitionSheetTableEvent,
  buildEmptyColumn,
  getColumnLetters,
} from '../PayerRosterDefinition.util'
import { AddColumnHeader } from './AddColumnHeader'
import { EditColumnDialog } from './EditColumnDialog'

interface SheetColumnsEditorProps extends FormikProps<PayerRosterDefinitionFormValues>, HTMLAttributes<HTMLDivElement> {
  sheetIndex: number
  saveLoading?: boolean
}

const DEFAULT_COL_DEF: ColDef = {
  resizable: true,
  editable: false,
  sortable: false,
  cellClassRules: {
    error: params => params.value === '#ERROR!',
  },
}

export function SheetColumnsEditor({
  values,
  sheetIndex,
  setFieldValue,
  submitForm,
  saveLoading,
  ...props
}: SheetColumnsEditorProps) {
  const gridRef = useRef<AgGridReact>(null)
  const [gridReady, setGridReady] = useState(false)
  const confirmRef = useRef<ConfirmRef>(null)
  const [columnToEdit, setColumnToEdit] = useState<PayerRosterDefinitionConfigColumn>()
  const sheet = values.config.sheets[sheetIndex]

  const debounceSubmitForm = useMemo(() => debounce(submitForm, 500), [submitForm])

  const { data, refetch } = usePayerRosterSheetDataQuery({
    variables: { filters: { sheetKey: sheet.key, payerRosterDefinitionId: values.id ?? '' }, limit: 20 },
    skip: !sheet.key,
  })

  const rowData = useMemo(() => data?.payerRosterSheetData?.sheet.data ?? [], [data])
  const colDefs = useMemo(() => getColDefs(values.config.columns, sheet), [values, sheet])

  useEffect(() => {
    const handleSetColumnToEdit = (e: CreateColumnEvent) => {
      setColumnToEdit(buildEmptyColumn(e.payload))
    }
    const handleAddColumnEvent = (e: AddColumnEvent) => {
      setFieldValue(`config.sheets[${sheetIndex}].columnKeys`, uniq([...sheet.columnKeys, e.payload]))
      debounceSubmitForm()
    }

    const gridApi = gridRef.current?.api

    if (gridApi) {
      gridApi.addEventListener(RosterDefinitionSheetTableEvent.CreateColumn, handleSetColumnToEdit)
      gridApi.addEventListener(RosterDefinitionSheetTableEvent.AddColumn, handleAddColumnEvent)

      return () => {
        gridApi.removeEventListener(RosterDefinitionSheetTableEvent.CreateColumn, handleSetColumnToEdit)
        gridApi.removeEventListener(RosterDefinitionSheetTableEvent.AddColumn, handleAddColumnEvent)
      }
    }
  }, [gridReady, setFieldValue, sheet, sheetIndex, debounceSubmitForm])

  const handleGridReady = useCallback(() => {
    setGridReady(true)
  }, [])

  const handleColumnSave = async (column: Omit<PayerRosterDefinitionConfigColumn, 'key'> & { key?: string | null }) => {
    const withKey = { ...column, key: column.key || generateColumnKey(column.alias) }
    const existingColumnIndex = values.config.columns.findIndex(c => c.key === withKey.key)
    if (existingColumnIndex >= 0) {
      setFieldValue(`config.columns[${existingColumnIndex}]`, withKey)
    } else {
      setFieldValue(`config.columns`, [...values.config.columns, withKey])
    }

    if (!sheet.columnKeys.includes(withKey.key)) {
      setFieldValue(`config.sheets[${sheetIndex}].columnKeys`, [...sheet.columnKeys, withKey.key])
    }

    await submitForm()
    refetch()
    setColumnToEdit(undefined)
  }

  const cloneColumn = useCallback(
    (column: PayerRosterDefinitionConfigColumn) => {
      const newColumn = { ...column, key: generateColumnKey(column.alias) }
      setFieldValue(`config.columns`, [...values.config.columns, newColumn])
      setFieldValue(`config.sheets[${sheetIndex}].columnKeys`, [...sheet.columnKeys, newColumn.key])
      setColumnToEdit(newColumn)
    },
    [setFieldValue, sheetIndex, sheet.columnKeys, values.config.columns],
  )

  const removeColumnFromSheet = useCallback(
    (column: PayerRosterDefinitionConfigColumn) => {
      setFieldValue(
        `config.sheets[${sheetIndex}].columnKeys`,
        sheet.columnKeys.filter(k => k !== column.key),
      )
      debounceSubmitForm()
    },
    [setFieldValue, sheetIndex, sheet.columnKeys, debounceSubmitForm],
  )

  const deleteColumn = useCallback(
    (column: PayerRosterDefinitionConfigColumn) => {
      confirmRef.current?.open(confirmed => {
        if (confirmed) {
          setFieldValue(
            `config.sheets[${sheetIndex}].columnKeys`,
            sheet.columnKeys.filter(key => key !== column.key),
          )
          setFieldValue(
            `config.columns`,
            values.config.columns.filter(c => c.key !== column.key),
          )

          submitForm()
        }
      })
    },
    [setFieldValue, sheetIndex, values, sheet.columnKeys, submitForm],
  )

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const items = params.defaultItems as any[]
      const column = values.config.columns.find(c => c.key === params.column.getColId())

      if (!column) {
        console.error(`Column not found for column menu - ${params.column.getColId()}`)
        return items
      }

      const actionRunner = (action: (column: PayerRosterDefinitionConfigColumn) => void) => {
        return () => {
          action(column)
        }
      }

      items.push(
        {
          name: 'Edit Column',
          action: actionRunner(setColumnToEdit),
        },
        {
          name: 'Clone Column',
          action: actionRunner(cloneColumn),
        },
        { name: 'Remove from sheet', action: actionRunner(removeColumnFromSheet) },
        { name: 'Delete Column', action: actionRunner(deleteColumn) },
      )
      return items
    },
    [values.config.columns, cloneColumn, removeColumnFromSheet, deleteColumn],
  )

  const handleColumnMoved = (e: ColumnMovedEvent) => {
    const columnId = e.column?.getColId()
    const newIndex = e.toIndex

    if (columnId && !isUndefined(newIndex) && sheet) {
      const newOrder = sheet.columnKeys.filter(key => key !== columnId)
      newOrder.splice(newIndex, 0, columnId)
      setFieldValue(`config.sheets[${sheetIndex}].columnKeys`, newOrder)
      debounceSubmitForm()
    }
  }

  return (
    <Container {...props}>
      <AgGridReact
        ref={gridRef}
        defaultColDef={DEFAULT_COL_DEF}
        columnDefs={colDefs}
        rowData={rowData}
        onGridReady={handleGridReady}
        getMainMenuItems={getMainMenuItems}
        onColumnMoved={handleColumnMoved}
      />
      <EditColumnDialog
        rosterName={values.name}
        payerRosterDefinitionId={values.id ?? ''}
        saveLoading={saveLoading}
        column={columnToEdit}
        onClose={() => setColumnToEdit(undefined)}
        onSave={handleColumnSave}
      />
      <Confirm ref={confirmRef}>Are you sure you want to delete this column?</Confirm>
    </Container>
  )
}

function getColDefs(columns: PayerRosterDefinitionConfigColumn[], sheet: PayerRosterDefinitionConfigSheet): ColDef[] {
  const keys = sheet.columnKeys
  return [
    ...compact(
      keys.map((key, idx) => {
        const column = columns.find(c => c.key === key)
        if (!column) return undefined
        return {
          field: column.key,
          headerName: column.alias,
          tooltipField: column.key,
          headerTooltip: column.alias,
          headerComponentParams: {
            // This is mostly the default AG-Grid header component which can be found at:
            // https://www.ag-grid.com/archive/27.2.0/react-data-grid/column-headers/
            // The only difference is the addition of the letter-header div which displays the column letter
            template: `
                <div class="ag-cell-label-container custom-header-label-container" role="presentation">
                  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                      <span ref="eText" class="ag-header-cell-text"></span>
                      <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
                      <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
                      <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
                      <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
                      <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
                  </div>
                  <div class="letter-header">${getColumnLetters(idx)}</div>
                </div>  

            `,
          },
        } as ColDef
      }),
    ),
    {
      headerName: 'Add',
      pinned: 'right',
      headerClass: 'add-column-header',
      headerComponent: AddColumnHeader,
      headerComponentParams: { rosterColumns: columns, currrentSheetColumnKeys: sheet.columnKeys },
      suppressKeyboardEvent: () => true,
      suppressHeaderKeyboardEvent: () => true,
    },
  ]
}

const Container = styled(makeTypographyComponent('ag-theme-material flex-remaining-space', 'div'))`
  .add-column-header {
    padding: 0;
  }

  .custom-header-label-container {
    padding-top: 16px;

    .letter-header {
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .error {
    background-color: ${salmonSet[15].hex};
  }
`

function generateColumnKey(alias: string) {
  return [camelCase(alias), (Math.random() + 1).toString(36).substring(7)].join('_')
}
