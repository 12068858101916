import * as Yup from 'yup'
import { styled } from '@mui/material'
import { useFormik } from 'formik'
import { noop } from 'lodash'

import {
  AccountSource,
  AccountSourceChannel,
  FinancialAttribution,
  PatientAttribution,
  PatientAttributionDocument,
  usePatientAttributionQuery,
  useSavePatientAttributionMutation,
} from '@nuna/api'
import { ProviderSelect, SelectProvider } from '@nuna/common'
import { OutlineButton, Select, TextField, toast } from '@nuna/tunic'

interface SourcesFormValues {
  source?: AccountSource | null
  sourceChannel?: AccountSourceChannel | null
  sourceProviderId?: string | null
  financialAttribution?: FinancialAttribution | null
}

interface SourcesFormProps {
  onSubmit: (values: SourcesFormValues) => void
  isRequestLoading: boolean
  patientAttribution?: PatientAttribution
}

const SourcesSchema = Yup.object().shape({
  source: Yup.string().nullable(),
  sourceChannel: Yup.string().nullable(),
  sourceProviderId: Yup.string().nullable(),
  financialAttribution: Yup.mixed<FinancialAttribution>().oneOf(Object.values(FinancialAttribution)).nullable(),
})

const Selections = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    max-width: 320px;
  }

  > button {
    width: min-content;
  }
`

function SourcesForm({ patientAttribution, onSubmit, isRequestLoading }: SourcesFormProps) {
  const { handleSubmit, values, setFieldValue } = useFormik<SourcesFormValues>({
    initialValues: {
      source: patientAttribution?.source as AccountSource,
      sourceChannel: patientAttribution?.sourceChannel as AccountSourceChannel,
      financialAttribution: patientAttribution?.financialAttribution,
      sourceProviderId: patientAttribution?.sourceProvider?.id,
    },
    onSubmit,
    validationSchema: SourcesSchema,
  })

  const selectedProvider = patientAttribution?.sourceProvider as SelectProvider

  return (
    <form onSubmit={handleSubmit}>
      <Selections>
        <Select
          label="Source"
          value={values.source ?? ''}
          onChange={e => setFieldValue('source', e.currentTarget.value)}
        >
          <option value=""></option>
          {Object.entries(AccountSource).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </Select>
        <Select
          label="Channel"
          value={values.sourceChannel ?? ''}
          onChange={e => setFieldValue('sourceChannel', e.currentTarget.value)}
        >
          <option value=""></option>
          {Object.entries(AccountSourceChannel).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </Select>
        <ProviderSelect
          label="Provider"
          multiple={false}
          selectedProviders={selectedProvider}
          onChange={provider => setFieldValue('sourceProviderId', provider?.id ?? null)}
        />
        <TextField
          value={patientAttribution?.financialAttribution}
          label="Financial Attribution"
          onChange={noop}
          helperText="* this field is automatically derived"
        />
        <OutlineButton type="submit" isLoading={isRequestLoading}>
          Change
        </OutlineButton>
      </Selections>
    </form>
  )
}

export function ClientSourceSection({ patientId }: { patientId: string }) {
  const { data, loading } = usePatientAttributionQuery({
    variables: {
      patientId,
    },
  })

  const [save, { loading: loadingSave }] = useSavePatientAttributionMutation()

  const savePatientAttribution = async (values: SourcesFormValues) => {
    try {
      if (!values.source) throw new Error('Source is required')

      const response = await save({
        variables: {
          patientId,
          source: values.source,
          sourceChannel: values.sourceChannel,
          sourceProviderId: values.sourceProviderId || null,
        },
        refetchQueries: [{ query: PatientAttributionDocument, variables: { patientId } }],
      })

      if (response.errors) throw new Error(response.errors[0].message)
    } catch (error: unknown) {
      if (error instanceof Error) {
        toast.urgent('Failed to update patient attribution: ' + error.message)
      }
    }
  }

  return (
    <section>
      <h3 className="h5">Client Attribution</h3>

      <p>
        Clients can only have one therapist financial attribution! Changing the provider will cause all other providers
        associated with this patient to be tava referrals.
      </p>

      <p className="mb-1">*To convert a patient to tava referrals, remove the provider</p>

      {!loading && (
        <SourcesForm
          patientAttribution={data?.patientAttribution as PatientAttribution}
          onSubmit={savePatientAttribution}
          isRequestLoading={loadingSave}
        />
      )}
    </section>
  )
}
