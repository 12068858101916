import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { isString, sortBy } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { ProviderActivityEventType } from '@nuna/api'

type EventLabelMap = Record<ProviderActivityEventType, string>

interface EventTypeOption {
  label: string
  value: ProviderActivityEventType
}

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<EventTypeOption | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface Props extends LimitedAutocompleteProps {
  value?: EventTypeOption | string | null
  onChange: (state: EventTypeOption | undefined | null) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
}

const EVENT_LABEL_MAP: EventLabelMap = {
  [ProviderActivityEventType.Approved]: 'Approved into network',
  [ProviderActivityEventType.BackgroundCheckCanceled]: 'Background check canceled',
  [ProviderActivityEventType.BackgroundCheckDisputed]: 'Background check disputed',
  [ProviderActivityEventType.BackgroundCheckDone]: 'Background check done',
  [ProviderActivityEventType.BackgroundCheckSubmitted]: 'Background check submitted',
  [ProviderActivityEventType.BackgroundCheckSuspended]: 'Background check suspended',
  [ProviderActivityEventType.CompletedAccount]: 'Account completed',
  [ProviderActivityEventType.CreatedAccount]: 'Account created',
  [ProviderActivityEventType.Denied]: 'Provider denied',
  [ProviderActivityEventType.EnrollmentStatusChange]: 'Provider enrollment status change',
  [ProviderActivityEventType.FileUpload]: 'File upload',
  [ProviderActivityEventType.InsuranceCredentialingStarted]: 'Insurance credentialing started',
  [ProviderActivityEventType.InsuranceCredentialingCompleted]: 'Insurance credentialing completed',
  [ProviderActivityEventType.LicensesCheckDone]: 'License check done',
  [ProviderActivityEventType.LicensesCheckSubmitted]: 'License check submitted',
  [ProviderActivityEventType.Note]: 'Note',
  [ProviderActivityEventType.PausedOnboarding]: 'Onboarding paused',
  [ProviderActivityEventType.ProfileInProcess]: 'Profile in process',
  [ProviderActivityEventType.TurnedOnAllReferrals]: 'Turned on all referrals',
  [ProviderActivityEventType.TurnedOffAllReferrals]: 'Turned off all referrals',
}

const OPTIONS: EventTypeOption[] = sortBy(
  Object.entries(EVENT_LABEL_MAP).map(([key, value]) => ({
    label: value,
    value: key as ProviderActivityEventType,
  })),
  option => option.label,
)

export function ProviderActivityLogTypeSelect({
  value,
  onChange,
  error,
  helperText,
  label = 'Event type',
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const valueAsOption = useMemo<EventTypeOption | null | undefined>(() => {
    if (isString(value)) {
      return OPTIONS.find(option => option.value === value) ?? null
    }
    return value
  }, [value])

  const filteredOptions = useMemo<EventTypeOption[]>(() => {
    const searcher = new FuzzySearch(OPTIONS, ['label'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return OPTIONS
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={state => state?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={filteredOptions}
      value={valueAsOption}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          data-testid="provider-activity-log-event-type-select"
          error={error}
          helperText={helperText}
          label={label}
          {...params}
        />
      )}
    />
  )
}
