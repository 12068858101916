import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { capitalize, omit } from 'lodash'
import { HTMLAttributes } from 'react'

import { DiagnosisFragment, DiagnosisLevel, useSaveDiagnosisMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { GreySection } from '@nuna/provider'
import { FillButton, OutlineButton, Select, TextField, toast } from '@nuna/tunic'

const { composeHelperText } = formService

interface Props {
  diagnosis?: Partial<DiagnosisFragment>
  onCancel: () => void
  onSave: () => void
}

const VALIDATION_SCHEMA = Yup.object().shape({
  label: Yup.string().required('Label is required'),
  icd10code: Yup.string().required('ICD 10 code is required'),
  diagnosisLevel: Yup.string().required('Diagnosis level is required'),
})

const DIAGNOSIS_LEVEL = Object.values(DiagnosisLevel)

export function DiagnosisForm({
  diagnosis = { diagnosisLevel: DiagnosisLevel.Primary },
  onCancel,
  onSave,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const [saveDiagnosis, { loading }] = useSaveDiagnosisMutation({ refetchQueries: ['AvailableDiagnoses'] })

  const submit = async (values: Partial<DiagnosisFragment>) => {
    try {
      await saveDiagnosis({
        variables: {
          diagnosisInput: omit(values, ['__typename']),
        },
      })
      toast.success('Diagnosis saved')
      onSave()
    } catch (error) {
      let errorMessage = 'There was a problem saving the diagnosis.'
      if (error instanceof ApolloError && error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message
      }
      toast.urgent(errorMessage)
    }
  }

  return (
    <GreySection {...props}>
      <Formik initialValues={diagnosis} validationSchema={VALIDATION_SCHEMA} onSubmit={submit}>
        {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="v-align">
              <TextField
                name="label"
                label="Label"
                error={touched.label && !!errors.label}
                helperText={composeHelperText('Label', errors.label, touched.label)}
                className="text-field"
                value={values.label ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextField
                name="category"
                label="Category"
                error={touched.category && !!errors.category}
                helperText={composeHelperText('Category', errors.category, touched.category)}
                className="ml-2 text-field"
                value={values.category ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextField
                name="icd10code"
                label="ICD 10 Code"
                error={touched.icd10code && !!errors.icd10code}
                helperText={composeHelperText('Duration in minutes', errors.icd10code, touched.icd10code)}
                className="ml-2 text-field"
                value={values.icd10code ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Select
                name="diagnosisLevel"
                label="Diagnosis Level"
                helperText={composeHelperText(
                  'Diagnosis level (medical necessity)',
                  errors.diagnosisLevel,
                  touched.diagnosisLevel,
                )}
                className="ml-2 text-field"
                value={values.diagnosisLevel ?? DIAGNOSIS_LEVEL[0]}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {DIAGNOSIS_LEVEL.map(diagnosisLevel => (
                  <option key={diagnosisLevel} value={diagnosisLevel}>
                    {diagnosisLevel.split('_').map(capitalize).join(' ')}
                  </option>
                ))}
              </Select>
              <span className="ml-auto actions">
                <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
                <FillButton isLoading={loading} type="submit" className="ml-1">
                  Save
                </FillButton>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </GreySection>
  )
}

const Form = styled('form')`
  .text-field {
    width: 150px;

    input[type='number'] {
      text-align: right;
    }
  }
  .actions {
    text-align: right;
  }
`
