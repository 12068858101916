import * as Yup from 'yup'
import { Formik } from 'formik'
import { noop, omit, pick } from 'lodash'
import { useState } from 'react'

import {
  DocumentType,
  FullInsurancePayerFragment,
  InsurancePayerInput,
  InsurancePayerStatus,
  StateAbbreviation,
  documentUtils,
  useDocumentUpload,
  useSavePayerMutation,
} from '@nuna/api'
import { StateOption, addressService, errorService, formService } from '@nuna/core'
import { PayerSelect } from '@nuna/coverage'
import {
  FileUpload,
  FillButton,
  Grid,
  OutlineButton,
  OutlineButtonLink,
  Skeleton,
  StateAutocompleteMultiple,
  Switch,
  TextField,
  toast,
} from '@nuna/tunic'

import { EntityForm } from '../../shared/EntityForm'
import { payers } from '../../util/routes'

const { documentUrl, DocumentPath } = documentUtils
const { composeHelperText } = formService
const { getStateNameFromAbbreviation } = addressService

type FormValues = Partial<Omit<InsurancePayerInput, 'states'>> & { states: StateOption[] }

const payerSchema = Yup.object<FormValues>().shape({
  name: Yup.string().required('Name is required'),
  externalPayerId: Yup.string().required('Enter Health Payer is required'),
})

const initialValues: FormValues = {
  name: '',
  externalPayerId: '',
  externalEligibilityId: '',
  status: InsurancePayerStatus.Pending,
  supportsRealtimeVerification: true,
  outOfStateCoverage: false,
  useProviderForVerification: false,
  websiteUrl: '',
  helpUrl: '',
  states: [],
}

interface Props {
  payer?: FullInsurancePayerFragment
  onCancel?: () => void
  afterSave?: (payerId: string | undefined) => void
}

export function PayerForm({ payer, onCancel = noop, afterSave = noop }: Props) {
  const [logoToUpload, setLogoToUpload] = useState<File | null>(null)
  const [logoUrl, setLogoUrl] = useState<string | ArrayBuffer | null>(null)
  const { uploadDocument, loading: uploadLogoLoading } = useDocumentUpload()
  const [savePayer, { loading }] = useSavePayerMutation({ refetchQueries: ['InsurancePayer'] })

  const savedLogoUrl = payer?.logoDocumentId
    ? documentUrl(DocumentPath.PayerLogo, payer?.id, payer?.logoDocumentId)
    : ''

  const logoUrlToDisplay = (logoUrl as string) || savedLogoUrl

  const submit = async (values: FormValues) => {
    try {
      const logoDocumentResult = logoToUpload ? await uploadDocument(logoToUpload, DocumentType.PayerLogo) : null
      const result = await savePayer({
        variables: { payerInput: formValuesToPayerInput(values, logoDocumentResult?.id) },
      })
      const payerId = result.data?.saveInsurancePayer.id
      toast.success(`Payer ${payer ? 'updated' : 'created'}`)
      afterSave(payerId)
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a problem saving the Payer'))
    }
  }

  const handleLogoUpload = (logo: File) => {
    setLogoToUpload(logo)
    const reader = new FileReader()
    reader.onloadend = () => {
      setLogoUrl(reader.result)
    }
    reader.readAsDataURL(logo)
  }

  return (
    <Formik initialValues={buildInitialValues(payer)} validationSchema={payerSchema} onSubmit={submit}>
      {({ values, handleChange, handleBlur, setFieldValue, touched, errors }) => {
        const isError = (key: keyof FormValues) => {
          return !!touched[key] && !!errors[key]
        }
        const helperText = (key: keyof FormValues, text = '') => {
          const isTouched = touched[key] as boolean
          const errorMsg = errors[key] as string | undefined
          return composeHelperText(text, errorMsg, isTouched)
        }

        const fieldProps = (key: keyof FormValues) => ({
          name: key,
          error: isError(key),
          onBlur: handleBlur,
          onChange: handleChange,
          helperText: helperText(key),
        })

        return (
          <EntityForm>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  md: 12,
                }}
              >
                <TextField label="Name" value={values.name} {...fieldProps('name')} />
              </Grid>
              <Grid size={12}>
                <PayerSelect
                  label="TPA For"
                  value={values.tpaForInsurancePayerId ?? null}
                  onChange={payer => setFieldValue('tpaForInsurancePayerId', payer?.id ?? null)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <TextField label="Website URL" value={values.websiteUrl} {...fieldProps('websiteUrl')} />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <TextField
                  label="External Payer ID"
                  value={values.externalPayerId}
                  {...fieldProps('externalPayerId')}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <TextField
                  label="External Eligibility ID"
                  value={values.externalEligibilityId}
                  {...fieldProps('externalEligibilityId')}
                />
              </Grid>
              <Grid size={12}>
                <StateAutocompleteMultiple
                  disableCloseOnSelect
                  label="Avalable in the following states"
                  onChange={newStateValues => {
                    setFieldValue('states', newStateValues)
                  }}
                  value={values.states}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <TextField label="Help URL" value={values.helpUrl} {...fieldProps('helpUrl')} />
              </Grid>
              <Grid size={12}>
                <Switch
                  className="mt-2"
                  checked={values.supportsRealtimeVerification ?? undefined}
                  {...omit(fieldProps('supportsRealtimeVerification'), 'helperText')}
                >
                  Supports real-time eligibility checks
                </Switch>
              </Grid>
              <Grid size={12}>
                <Switch
                  className="mt-2"
                  checked={values.outOfStateCoverage ?? undefined}
                  {...omit(fieldProps('outOfStateCoverage'), 'helperText')}
                >
                  Allow out-of-state coverage
                </Switch>
              </Grid>
              <Grid size={12}>
                <Switch
                  className="mt-2"
                  checked={values.useProviderForVerification ?? undefined}
                  {...omit(fieldProps('useProviderForVerification'), 'helperText')}
                >
                  Use provider for verification
                </Switch>
              </Grid>
              <Grid size={12}>
                <label className="mt-2 mb-1 text-medium-grey" style={{ display: 'block' }}>
                  Payer Logo
                </label>
                <FileUpload accept="image/*" onDrop={files => handleLogoUpload(files[0])} />
                {logoUrlToDisplay && (
                  <img className="mt-2" style={{ maxWidth: '100%' }} src={logoUrlToDisplay} alt="payer logo" />
                )}
              </Grid>

              <Grid className="v-align" size={12}>
                <div className="ml-auto mt-2">
                  {payer?.id && (
                    <OutlineButton onClick={onCancel} className="mr-2">
                      Cancel
                    </OutlineButton>
                  )}
                  {!payer?.id && (
                    <OutlineButtonLink to={payers} className="mr-2">
                      Cancel
                    </OutlineButtonLink>
                  )}
                  <FillButton type="submit" isLoading={loading || uploadLogoLoading}>
                    Save
                  </FillButton>
                </div>
              </Grid>
            </Grid>
          </EntityForm>
        )
      }}
    </Formik>
  )
}

function formValuesToPayerInput(values: FormValues, logoDocumentId?: string): InsurancePayerInput {
  return {
    ...values,
    logoDocumentId,
    websiteUrl: values.websiteUrl || undefined,
    helpUrl: values.helpUrl || undefined,
    states: values.states.map(state => ({ state: state.value as StateAbbreviation })),
  }
}

function buildInitialValues(payer?: FullInsurancePayerFragment): FormValues {
  if (!payer) {
    return initialValues
  }

  const formValues: FormValues = {
    ...pick(
      payer,
      Object.keys(initialValues).filter(key => !['insurancePayerStates', 'tpaForInsurancePayer'].includes(key)),
    ),
    states: payer.insurancePayerStates.map(state => ({
      label: getStateNameFromAbbreviation(state.state) as string,
      value: state.state,
    })),
    tpaForInsurancePayerId: payer.tpaForInsurancePayer?.id,
  }

  if (payer) {
    formValues.id = payer.id
  }

  return formValues
}

const FieldSkeleton = () => <Skeleton height={3} style={{ width: '100%' }} />

export function PayerFormLoader() {
  return (
    <div style={{ width: '40rem' }}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={12}>
          <FieldSkeleton />
        </Grid>
        <Grid size={12}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={12} className="v-align mt-3">
          <div className="ml-auto">
            <Skeleton height={3} width={10} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
