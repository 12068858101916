import { styled } from '@mui/material'

import { AddressOwner, ProviderAddressFragment } from '@nuna/api'
import { addressService } from '@nuna/core'
import { Card, IconButton, IconCheck, IconTrash, StatusLabel, makeTypographyComponent, tealSet } from '@nuna/tunic'

interface Props {
  onRemove: (addressId: string) => void
  onSetPrimary: (addressId: string) => void
  providerAddresses: ProviderAddressFragment[]
}
export function SelectedAssignedAddresses({ onRemove, onSetPrimary, providerAddresses }: Props) {
  return (
    <div className="flex-column gap-1">
      {providerAddresses.map(providerAddress => (
        <StyledCard key={providerAddress.address.id} className="p-1 v-align" depth={-1}>
          <div className="flex-column">
            <div className="v-align gap-xs">
              {providerAddress.primaryPracticeLocation && (
                <StatusLabel intent="information" className="mb-xs">
                  Primary
                </StatusLabel>
              )}
              {providerAddress.address.owner === AddressOwner.Provider && (
                <StatusLabel className="mb-xs" intent="caution">
                  Provider Supplied
                </StatusLabel>
              )}
            </div>
            <AddressName>{providerAddress.address.name}</AddressName>
            <div>{addressService.formatAddress(providerAddress.address)}</div>
          </div>
          <span className="pl-1 ml-auto v-align g-1 button-container">
            {!providerAddress.primaryPracticeLocation && (
              <IconButton
                small
                tooltip="Set as primary practice"
                onClick={() => onSetPrimary(providerAddress.address.id)}
              >
                <IconCheck />
              </IconButton>
            )}

            {providerAddress.address.owner === AddressOwner.Tava && (
              <IconButton small tooltip="Unassign address" onClick={() => onRemove(providerAddress.address.id)}>
                <IconTrash />
              </IconButton>
            )}
          </span>
        </StyledCard>
      ))}
    </div>
  )
}

const StyledCard = styled(Card)`
  .button-container {
    button {
      visibility: hidden;
    }
  }

  &:hover {
    .button-container {
      button {
        visibility: visible;
      }
    }
  }
`

const AddressName = styled(makeTypographyComponent('large text-medium mb-xs', 'div'))`
  color: ${tealSet[70].hex};
`
