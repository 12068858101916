import * as Yup from 'yup'
import { Formik, setNestedObjectValues } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'

import { PartnerAdminInput, PartnerAdminQuery, usePartnerAdminQuery, useSavePartnerAdminMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { Checkbox, FillButton, PageHeader, PageHeading, TextField, toast } from '@nuna/tunic'

import { EntityForm, EntityFormButtonRow, EntityFormSection } from '../../../shared/EntityForm'
import { partnerAdminList } from '../../../util/routes'

const partnerSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  active: Yup.boolean().required('Active is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  partnerId: Yup.string().required('Partner is required'),
  loginId: Yup.string(),
  id: Yup.string(),
})

export function PartnerAdminForm() {
  const navigate = useNavigate()
  const { id: partnerId, adminId } = useParams<{
    id: string
    adminId?: string
  }>()
  const { data: partnerAdminData } = usePartnerAdminQuery({
    variables: { id: adminId || '' },
    skip: !adminId,
  })
  const { partnerAdmin } = partnerAdminData || {}
  const [createAdmin, { loading: updateLoading }] = useSavePartnerAdminMutation()
  const { composeHelperTextWithError } = formService

  const submit = async (values: PartnerAdminInput) => {
    try {
      const { data: newPartnerData } = await createAdmin({
        variables: { data: values },
        refetchQueries: ['SearchPartnerAdmins'],
        awaitRefetchQueries: true,
      })

      const savedPartnerId = newPartnerData?.savePartnerAdmin?.partnerId
      if (savedPartnerId) {
        toast.success('Partner Admin saved')
        navigate(partnerAdminList(savedPartnerId))
      }
    } catch (e) {
      console.error(e)
      toast.urgent('Please check the form and try again')
    }
  }

  return (
    <>
      <PageHeader border={false}>
        <PageHeading className="mb-0">{adminId ? 'Edit' : 'Create'} Partner Admin</PageHeading>
      </PageHeader>
      <div className="px-3">
        <Formik
          initialValues={buildInitialValues(partnerId, partnerAdmin)}
          onSubmit={submit}
          validationSchema={partnerSchema}
          enableReinitialize
        >
          {formProps => {
            const { values, handleSubmit, setTouched, handleBlur, handleChange, errors, touched } = formProps
            return (
              <EntityForm onSubmit={handleSubmit} style={{ maxWidth: '40rem' }}>
                <EntityFormSection>
                  <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...composeHelperTextWithError('', errors.firstName, touched.firstName)}
                  />
                  <TextField
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...composeHelperTextWithError('', errors.lastName, touched.lastName)}
                  />
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...composeHelperTextWithError('', errors.email, touched.email)}
                  />
                  <Checkbox
                    id="active"
                    label="Active"
                    name="active"
                    checked={values.active ?? false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...composeHelperTextWithError('', errors.active, touched.active)}
                    className="mt-2"
                  >
                    Is Active?
                  </Checkbox>
                </EntityFormSection>
                <EntityFormButtonRow>
                  <FillButton
                    isLoading={updateLoading}
                    type="submit"
                    onClick={() => {
                      setTouched(setNestedObjectValues(values, true))
                    }}
                  >
                    {adminId ? 'Update' : 'Save'} Partner Admin
                  </FillButton>
                </EntityFormButtonRow>
              </EntityForm>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

const buildInitialValues = (
  partnerId?: string,
  partnerAdmin?: PartnerAdminQuery['partnerAdmin'],
): PartnerAdminInput => {
  return {
    firstName: partnerAdmin?.login.firstName || '',
    lastName: partnerAdmin?.login.lastName || '',
    email: partnerAdmin?.login.email || '',
    active: partnerAdmin ? partnerAdmin.login.active : true,
    id: partnerAdmin?.id || undefined,
    loginId: partnerAdmin?.login.id || undefined,
    partnerId: partnerAdmin?.partner.id || partnerId || undefined,
  }
}
