import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material'
import { useMemo, useState } from 'react'

import {
  EnterHealthPayer,
  EnterHealthPayerFragment,
  useEnterHealthPayerQuery,
  useEnterHealthPayersQuery,
} from '@nuna/api'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<EnterHealthPayerFragment | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

type PartialTextFieldProps = Pick<TextFieldProps, 'helperText' | 'error' | 'name'>

interface Props extends LimitedAutocompleteProps, PartialTextFieldProps {
  insuranceTaxEntityId?: string | null | undefined
  value?: string | null | undefined
  onChange: (priorEnterPayerId: string | null | undefined, enterPayer: EnterHealthPayer | null | undefined) => void
  label?: string
}

export function EnterPayerSelect({
  value,
  insuranceTaxEntityId,
  onChange,
  label = 'Enter Health Plan',
  helperText,
  error,
  name,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const { data, loading } = useEnterHealthPayersQuery({
    variables: { insuranceTaxEntityId, nameFilter: searchTerm ?? undefined },
    context: { debounceKey: `enter-payer-debounce-${insuranceTaxEntityId}`, debounceTimeout: 500 },
    fetchPolicy: 'network-only',
  })

  const valueQueryVariables = useMemo(
    () => ({ insuranceTaxEntityId: insuranceTaxEntityId ?? '', enterHealthPayerId: value ?? '' }),
    [insuranceTaxEntityId, value],
  )

  const { data: valueData, loading: valueDataLoading } = useEnterHealthPayerQuery({
    variables: valueQueryVariables,
    skip: !value || !insuranceTaxEntityId,
    fetchPolicy: 'cache-first',
  })

  const payerOptions = useMemo(() => {
    const options = (data?.enterHealthPayers ?? []).filter(payer => payer.id !== valueData?.enterHealthPayer?.id)

    if (valueData?.enterHealthPayer) {
      options.unshift(valueData.enterHealthPayer)
    }
    return options
  }, [data, valueData])

  const valueAsOption = useMemo<EnterHealthPayerFragment | null | undefined>(() => {
    return valueData?.enterHealthPayer ?? null
  }, [valueData])

  return (
    <Autocomplete
      {...props}
      loading={loading || valueDataLoading}
      options={payerOptions}
      onChange={(_event, option) => {
        onChange(value, option)
      }}
      getOptionLabel={option => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      value={valueAsOption}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField name={name} label={label} {...params} helperText={helperText} error={error} />}
    />
  )
}
