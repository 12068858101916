import { HTMLAttributes, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { CreateEnrollmentForPlan } from '../../enrollment/CreateEnrollmentForPlan'
import { ProviderEnrollmentTable } from '../../enrollment/components/ProviderEnrollmentTable/ProviderEnrollmentTable'

interface Props extends HTMLAttributes<HTMLDivElement> {
  payerId: string
}

export function PayerEnrollments({ payerId, ...props }: Props) {
  const fixedFilters = useMemo(() => ({ insurancePayerId: payerId }), [payerId])
  return (
    <div {...props}>
      <Routes>
        <Route
          index
          element={
            <ProviderEnrollmentTable
              className="mt-1 full-height"
              columns={[
                'provider',
                'effectiveStatus',
                'payerStatus',
                'submittedDate',
                'effectiveDate',
                'ignoreEnrollment',
                'disableBilling',
                'payer',
              ]}
              fixedFilters={fixedFilters}
            />
          }
        />
        <Route path="add" element={<CreateEnrollmentForPlan insurancePayerId={payerId} />} />
      </Routes>
    </div>
  )
}
