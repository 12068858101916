import { styled } from '@mui/material'
import moment from 'moment-timezone'

import { ClaimMessageAuthorType, ClaimMessageFragment } from '@nuna/api'
import { Card, CardBody, CardBoot, CardHeader, Drawer, DrawerProps, StatusLabel, greySet } from '@nuna/tunic'

interface Props extends DrawerProps {
  claimMessage: ClaimMessageFragment
  externalClaimId: string
}

export function ClaimMessageDrawer({ claimMessage, externalClaimId, isOpen, onClose, ...props }: Props) {
  const enterBlueTint = 'rgb(46, 107, 230, .80)'
  const tavaTealTint = 'rgb(1, 125, 141, .80)'

  return (
    <Drawer isOpen={isOpen} onClose={() => onClose()} {...props} size="600px" style={{ overflowY: 'hidden' }}>
      <DrawerHeader>
        <h5>{claimMessage.subject}</h5>
        <a href={`https://provider.enter.health/claims/${externalClaimId}`} target="_blank" rel="noreferrer">
          <StatusLabel intent={'default'} style={{ padding: '8px' }}>
            External Claim ID - {externalClaimId}
          </StatusLabel>
        </a>
      </DrawerHeader>
      <DrawerContent>
        {claimMessage.comments.map(comment => (
          <Card depth={1} intent="default" shadowColor="warm" className="mb-2" key={comment.id}>
            <CardHeader
              className="space-between v-align text-right"
              style={{
                paddingBottom: '8px',
                justifyContent: comment.author.type === ClaimMessageAuthorType.Support ? 'left' : 'right',
              }}
            >
              {comment.author.type === ClaimMessageAuthorType.Support ? (
                <span
                  className="text-medium"
                  style={{
                    color: enterBlueTint,
                  }}
                >
                  {comment.author.nickname?.split(' ')[0]} {comment.author.nickname?.split(' ')[1][0]}.{' '}
                  <small>({comment.author.type?.toLocaleLowerCase()})</small>
                </span>
              ) : (
                <span
                  className="text-medium"
                  style={{
                    color: tavaTealTint,
                  }}
                >
                  {comment.author.nickname}
                </span>
              )}
            </CardHeader>
            <CardBody
              style={{
                color: comment.author.type === ClaimMessageAuthorType.Support ? enterBlueTint : tavaTealTint,
                textAlign: comment.author.type === ClaimMessageAuthorType.Support ? 'left' : 'right',
              }}
            >
              <span>{comment.body}</span>
            </CardBody>
            <CardBoot
              intent="default"
              style={{
                color: 'white',
                backgroundColor: comment.author.type === ClaimMessageAuthorType.Support ? enterBlueTint : tavaTealTint,
                justifyContent: comment.author.type === ClaimMessageAuthorType.Support ? 'left' : 'right',
              }}
            >
              <span> on {moment(comment.created).format('MMM DD, YYYY [at] h:mma')}</span>
            </CardBoot>
          </Card>
        ))}
      </DrawerContent>
    </Drawer>
  )
}

const DrawerHeader = styled('div')`
  padding: 24px;
  border-bottom: 1px solid ${greySet[15].hex};
`

const DrawerContent = styled('div')`
  padding: 24px;
  overflow-y: scroll;
`
