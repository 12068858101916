import { useCallback, useEffect, useState } from 'react'

import { Provider, useProviderCredentialsAndEnrollmentLazyQuery } from '@nuna/api'
import { toast } from '@nuna/tunic'

interface ProviderStatesAndEnrollments {
  states: string[]
  payerIds: string[]
}

type ProviderStatesAndEnrollmentsMap = Map<string, ProviderStatesAndEnrollments>

export function useProviderEnrollmentsAndStates(providers: Pick<Provider, 'id'>[]) {
  const [queryCredentialsAndEnrollments] = useProviderCredentialsAndEnrollmentLazyQuery()
  const [map, setMap] = useState<ProviderStatesAndEnrollmentsMap>(new Map())

  const updateMap = useCallback(
    async (providers: Pick<Provider, 'id'>[], providerMap: ProviderStatesAndEnrollmentsMap) => {
      const newMap: ProviderStatesAndEnrollmentsMap = new Map()
      const promises: Promise<unknown>[] = []
      providers.forEach(provider => {
        const providerId = provider.id
        const existing = providerMap.get(providerId)
        if (existing) {
          newMap.set(provider.id, existing)
        } else {
          promises.push(
            queryCredentialsAndEnrollments({ variables: { providerId } })
              .then(result => {
                const creds = result.data?.provider.credentials ?? []
                const enrollments = result.data?.providerEnrollments ?? []
                newMap.set(providerId, {
                  payerIds: enrollments.map(e => e.insurancePayer.id),
                  states: creds.map(c => c.state),
                })
              })
              .catch(() => {
                toast.urgent('There was a problem fetching licenses and enrollments for ')
                return null
              }),
          )
        }
      })

      await Promise.all(promises)

      setMap(newMap)
    },
    [queryCredentialsAndEnrollments],
  )

  useEffect(() => {
    if (
      Array.from(map.keys()).sort().join('') !==
      providers
        .map(p => p.id)
        .sort()
        .join('')
    ) {
      updateMap(providers, map)
    }
  }, [providers, map, updateMap])

  const providerMissingCredentialedState = useCallback(
    (state?: string | null) => {
      if (!state) return undefined

      for (const [key, value] of map) {
        if (!value.states.includes(state)) {
          return key
        }
      }
    },
    [map],
  )

  const providerHasPayerEnrollment = useCallback(
    (payerId: string) => {
      for (const [key, value] of map) {
        if (value.payerIds.includes(payerId)) {
          return key
        }
      }
    },
    [map],
  )

  const resetMap = useCallback(() => setMap(new Map()), [])

  return { providerStatesAndEnrollments: map, providerMissingCredentialedState, providerHasPayerEnrollment, resetMap }
}
