import { capitalize, lowerCase } from 'lodash'
import moment from 'moment-timezone'
import { Fragment, useState } from 'react'

import { PatientCompanyHistoryQuery, usePatientCompanyHistoryQuery } from '@nuna/api'
import { IconButton, IconClose, InfoWithHeading, Skeleton, TextButton, Tooltip, yellowSet } from '@nuna/tunic'

import { AssociateClientDialog } from './AssociateClientDialog'
import { DeleteEAPHistoryItemDialog } from './DeleteEAPHistoryItemDialog'
import { EndContractAtDateDialog } from './EndContractAtDateDialog'
import { RemoveInvalidAssociationDialog } from './RemoveInvalidAssociationDialog'
import { RestoreCompanyHistoryItemButton } from './RestoreCompanyHistoryItemButton'

export interface ManageClientEAPHistoryProps {
  patientId: string
}

type CompanyHistoryItem = PatientCompanyHistoryQuery['patientCompanyHistory']['historyItems'][number]
type DialogType = 'removeInvalidAssociation' | 'endContractAtDate' | 'associateClient' | 'deleteHistoryItem'

export function ManageClientEAPHistory({ patientId }: ManageClientEAPHistoryProps) {
  const [getCurrentDialog, setCurrentDialog] = useState<DialogType | undefined>(undefined)
  const [selectedItem, setSelectedItem] = useState<(CompanyHistoryItem & { isEmployee: boolean }) | undefined>(
    undefined,
  )
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null)
  const { data, loading, refetch } = usePatientCompanyHistoryQuery({
    variables: { patientId },
  })

  function isCurrentItem(item: CompanyHistoryItem) {
    return moment(item.firstAssignedDate).isBefore(moment()) && moment(item.lastUnassignedDate).isAfter(moment())
  }

  function isCashContract(item: CompanyHistoryItem) {
    return item.contractType === 'CASH_CONTRACT'
  }

  function isEAPContract(item: CompanyHistoryItem) {
    return item.contractType === 'SPONSORED_COMPANY_CONTRACT' || item.contractType === 'COMPANY_CONTRACT'
  }

  function handleCloseDialog() {
    setCurrentDialog(undefined)
    setSelectedItem(undefined)
  }

  function RemoveInvalidAssociation({ item }: { item: CompanyHistoryItem }) {
    if (isCurrentItem(item) && !isCashContract(item)) {
      return (
        <TextButton onClick={() => setCurrentDialog('removeInvalidAssociation')}>Remove invalid association</TextButton>
      )
    }
    return null
  }

  function EndCurrentContractAtDate({ item }: { item: CompanyHistoryItem }) {
    if (isCurrentItem(item) && !isCashContract(item)) {
      return <TextButton onClick={() => setCurrentDialog('endContractAtDate')}>Change End Date</TextButton>
    }
    return null
  }

  function LinkedMember({ item, isEmployee }: { item: CompanyHistoryItem; isEmployee: boolean }) {
    if (isCashContract(item)) return null
    const buttonHandler = () => {
      setSelectedItem({ ...item, isEmployee })
      setCurrentDialog('associateClient')
    }

    const linkedMember = isEmployee ? item.linkedEmployee : item.linkedSponsor
    const heading = `Matched ${isEmployee ? 'Employee' : 'Sponsor'}`
    if (linkedMember) {
      const name = `${linkedMember.firstName} ${linkedMember.lastName}`
      const idOrRelation = isEmployee ? linkedMember.ancillaryEmployeeId : item.linkedSponsorRelation
      return (
        <InfoWithHeading
          heading={heading}
          info={`${name} (${idOrRelation} ${linkedMember.employmentStatus})`}
          secondaryInfo={isCurrentItem(item) ? <TextButton onClick={buttonHandler}>Edit</TextButton> : null}
        />
      )
    }

    return (
      <InfoWithHeading
        heading={heading}
        info="None"
        secondaryInfo={isCurrentItem(item) ? <TextButton onClick={buttonHandler}>Link</TextButton> : null}
      />
    )
  }

  if (loading) {
    return <Skeleton height={1.5} width={10} />
  }

  return (
    <section>
      <h1 className="h5">EAP History</h1>
      <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr 50px' }}>
        {(data?.patientCompanyHistory?.historyItems ?? []).map((item, index) => (
          <Fragment key={item.firstAssignedDate}>
            <div
              className="p-2"
              style={{
                backgroundColor: isCurrentItem(item) ? yellowSet[15].hex : undefined,
              }}
            >
              <Tooltip
                content={moment
                  .tz(item.firstAssignedDate, data?.patientCompanyHistory.patientTimezone || 'UTC')
                  .format('ll [at] LT z')}
              >
                <span>
                  {moment.tz(item.firstAssignedDate, data?.patientCompanyHistory.patientTimezone || 'UTC').format('ll')}
                </span>
              </Tooltip>
            </div>
            <div
              className="p-2"
              style={{
                backgroundColor: isCurrentItem(item) ? yellowSet[15].hex : undefined,
              }}
            >
              {!isEAPContract(item) && <InfoWithHeading heading={isCashContract(item) ? 'Cash Pay' : 'Free Coupon'} />}
              {isEAPContract(item) && (
                <>
                  <InfoWithHeading
                    heading={`${item.contractType === 'SPONSORED_COMPANY_CONTRACT' ? 'Sponsored' : ''} Company`}
                    info={item.companyContractSummary?.companyName}
                    secondaryInfo={<RemoveInvalidAssociation item={item} />}
                    tertiaryInfo={<EndCurrentContractAtDate item={item} />}
                  />
                  {item.unassignDateReason && (
                    <InfoWithHeading
                      heading="Removal Reason"
                      info={capitalize(lowerCase(item.unassignDateReason))}
                      secondaryInfo={
                        <RestoreCompanyHistoryItemButton
                          patientId={patientId}
                          historyItems={data?.patientCompanyHistory?.historyItems ?? []}
                          itemIndex={index}
                          onSaved={refetch}
                        />
                      }
                    />
                  )}
                  <LinkedMember item={item} isEmployee={true} />
                  <LinkedMember item={item} isEmployee={false} />
                </>
              )}
            </div>
            <div
              className="p-0"
              style={{
                backgroundColor: isCurrentItem(item) ? yellowSet[15].hex : undefined,
              }}
            >
              {index > 0 && (
                <IconButton
                  tooltip="Remove from history"
                  onClick={() => {
                    setSelectedItemIndex(index)
                    setCurrentDialog('deleteHistoryItem')
                  }}
                >
                  <IconClose size={16} />
                </IconButton>
              )}
            </div>
          </Fragment>
        ))}
      </div>

      {getCurrentDialog === 'removeInvalidAssociation' && data?.patientCompanyHistory?.patientId && (
        <RemoveInvalidAssociationDialog patientId={data.patientCompanyHistory.patientId} onClose={handleCloseDialog} />
      )}
      {getCurrentDialog === 'endContractAtDate' && data?.patientCompanyHistory?.patientId && (
        <EndContractAtDateDialog
          patientId={data.patientCompanyHistory.patientId}
          latestContractDate={moment(
            data?.patientCompanyHistory?.historyItems.find(isCurrentItem)?.companyContractSummary?.lastCompanyEndDate,
          ).toDate()}
          onClose={handleCloseDialog}
        />
      )}
      {getCurrentDialog === 'associateClient' && data?.patientCompanyHistory?.patientId && selectedItem && (
        <AssociateClientDialog
          patientId={data.patientCompanyHistory.patientId}
          item={selectedItem}
          isEmployee={selectedItem.isEmployee}
          onClose={handleCloseDialog}
        />
      )}
      {getCurrentDialog === 'deleteHistoryItem' && data?.patientCompanyHistory && selectedItemIndex !== null && (
        <DeleteEAPHistoryItemDialog
          history={data.patientCompanyHistory}
          deleteIndex={selectedItemIndex}
          onClose={handleCloseDialog}
        />
      )}
    </section>
  )
}
