import { Autocomplete, AutocompleteProps, TextField as MUITextField } from '@mui/material'
import { isString, throttle } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import {
  InsurancePayerPlanSelectOptionFragment,
  InsurancePayerPlanSortKeys,
  InsurancePayerPlansSearchOptions,
  OrderBy,
  useInsurancePayerPlanSelectOptionsLazyQuery,
} from '@nuna/api'

export type PayerPlanOption = InsurancePayerPlanSelectOptionFragment

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<PayerPlanOption, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

export interface InsurancePayerPlanSelectProps extends LimitedAutocompleteProps {
  value?: PayerPlanOption | null | string
  onChange: (payerPlan: InsurancePayerPlanSelectOptionFragment | null) => void
  displayPayerName?: boolean
}

export function InsurancePayerPlanSelect({
  value,
  displayPayerName = false,
  onChange,
  ...props
}: InsurancePayerPlanSelectProps) {
  const [queryPlans, { data, loading }] = useInsurancePayerPlanSelectOptionsLazyQuery()
  const [searchValue, setSearchValue] = useState('')

  const options: PayerPlanOption[] = useMemo(() => data?.insurancePayerPlans.items ?? [], [data])

  const valueAsOption = useMemo(() => {
    if (isString(value)) {
      return options.find(plan => plan.id === value) ?? null
    }

    return value
  }, [options, value])

  const fetch = useMemo(
    () =>
      throttle((searchOptions: InsurancePayerPlansSearchOptions) =>
        queryPlans({
          variables: {
            filters: searchOptions,
            order: [{ direction: OrderBy.Desc, key: InsurancePayerPlanSortKeys.Name }],
            pagination: {
              limit: 1000,
            },
          },
        }),
      ),
    [queryPlans],
  )

  useEffect(() => {
    const searchOptions: InsurancePayerPlansSearchOptions = { active: true }
    if (searchValue) {
      searchOptions.name = searchValue
    }
    fetch(searchOptions)
  }, [searchValue, fetch])

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => getOptionLabel(option, displayPayerName)}
      loading={loading}
      onChange={(_event, payer) => {
        onChange(payer as InsurancePayerPlanSelectOptionFragment)
      }}
      renderInput={params => (
        <MUITextField {...params} label="Insurance Plan" onChange={e => setSearchValue(e.currentTarget.value)} />
      )}
      value={valueAsOption}
      {...props}
    />
  )
}

function getOptionLabel(option: PayerPlanOption, displayPayerName: boolean) {
  if (displayPayerName) {
    return `${option.insurancePayer.name} — ${option.name}`
  }

  return option.name
}
