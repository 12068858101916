import { ProviderEducationVerificationStatus } from '@nuna/api'

export const CredentialingChecklistStatus = {
  VERIFIED: 'verified',
  PENDING: 'pending',
  INCOMPLETE: 'incomplete',
  FLAG: 'flag',
  MISSING: 'missing',
  EXPIRED: 'expired',
} as const

export const CredentialingChecklist = {
  ATTESTATION: 'attestation',
  NPI: 'npi',
  STATE_LICENSES: 'stateLicenses',
  EDUCATION: 'education',
  PROVIDER_LIABILITY_INSURANCE: 'providerLiabilityInsurance',
  WORK_HISTORY: 'workHistory',
  SAM_EXCLUSIONS: 'samExclusions',
  OIG_EXCLUSIONS: 'oigExclusions',
  MEDICARE_OPT_OUT: 'medicareOptOut',
  STATE_SANCTIONS: 'stateSanctions',
  NPDB: 'npdb',
  CAQH_DISCLOSURES: 'caqhDisclosures',
} as const
export type CredentialingChecklistType = (typeof CredentialingChecklist)[keyof typeof CredentialingChecklist]

export type CredentialingChecklistColumns = (typeof CredentialingChecklist)[keyof typeof CredentialingChecklist]

export type CredentialingChecklistStatus =
  (typeof CredentialingChecklistStatus)[keyof typeof CredentialingChecklistStatus]

export type CredentialingChecklistInsuranceDetails = {
  carrier?: string | null
  policyNumber?: string | null
  effectiveDate?: string | null
  expirationDate?: string | null
  perClaimAmount?: string | null
  aggregateAmount?: string | null
}

export type CredentialingChecklistEducationDetails = {
  id?: string
  degree?: string | null
  completed?: boolean | null
  branchCode?: string | null
  schoolCode?: string | null
  graduateType?: string | null
  endDate?: string | null
  lastUpdatedAt?: string | null
}

export type CredentialingChecklistSection = Record<CredentialingChecklistColumns, CredentialingChecklistItem[]>

export type CredentialingChecklistItem = {
  field: CredentialingChecklistType
  tid: string
  providerId: string
  verifiableId: string
  verificationStatus: string | CredentialingChecklistStatus | ProviderEducationVerificationStatus
  verifiedAt: string
  isPrimary: boolean
  grouping?: string | null
  title?: string
  description?: string
  source?: string
  sourceLink?: string
  status?: CredentialingChecklistStatus
  details?: string
}
export type CredentialingChecklistItemKeys = keyof Omit<
  CredentialingChecklistItem,
  'isPrimary' | 'grouping' | 'sourceLink'
>
