import { compact } from 'lodash'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useNetworkPlanDrawerSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const openDrawer = ({ id, payerId, networkId }: { id?: string; payerId?: string; networkId: string }) => {
    if (id) {
      setSearchParams({ networkPayerPlanId: id, networkId })
    } else if (payerId) {
      setSearchParams({ associateNetworkPlan: payerId, networkId })
    } else {
      console.error('Attempted to open network plan drawer without an id')
    }
  }

  const closeDrawer = () => {
    setSearchParams({})
  }

  const drawerConfig = useMemo(() => {
    const networkPayerPlanId = searchParams.get('networkPayerPlanId')
    const payerId = searchParams.get('associateNetworkPlan')
    const networkId = searchParams.get('networkId')
    return {
      networkPayerPlanId,
      payerId,
      networkId,
      isOpen: !!networkId && compact([payerId, networkPayerPlanId]).length > 0,
    }
  }, [searchParams])

  return { openDrawer, closeDrawer, drawerConfig }
}
