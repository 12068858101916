import { styled } from '@mui/material'
import { useState } from 'react'

import { ProviderEnrollmentStatus } from '@nuna/api'
import { StatusLabel } from '@nuna/tunic'

import { statusMap } from '../payers/status-map'
// TODO: These props can be imported from ListBox if we decide to move ListBox to Tunic
// import {
//   ListItemComponent,
//   ListItemProps
// } from '../../../coverage/src/components/EnrollmentTable/components/ListBox/ListBox'
import { SearchPayer } from './PayerSearch'
import { PayerStatusSelect } from './PayerStatusSelect'

type ListItemProps = { key: string; value: string }
type Props = {
  listItem: SearchPayer
  onClick: (item: SearchPayer & ListItemProps) => void
}
export function PayerListItem({ listItem, onClick }: Props) {
  const [selectedStatus, setSelectedStatus] = useState<ProviderEnrollmentStatus>()
  const handleStatusChange = (enrollmentStatus: ProviderEnrollmentStatus) => {
    setSelectedStatus(enrollmentStatus)
    onClick({ ...listItem, enrollmentStatus })
  }

  const { intent, status } = statusMap(listItem.status)

  return listItem?.name ? (
    <ListDetails>
      <ListItemValue>{listItem.name}</ListItemValue>
      <ListItemActions>
        <PayerStatusSelect status={selectedStatus} onChange={handleStatusChange} />
        <StatusLabel intent={intent}>{status}</StatusLabel>
      </ListItemActions>
    </ListDetails>
  ) : null
}

const ListDetails = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const ListItemValue = styled('span')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-1);
`
const ListItemActions = styled(ListItemValue)`
  span {
    min-width: 75px;
    text-align: center;
  }
`
