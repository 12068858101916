import { DialogActions, DialogContentText, DialogTitle } from '@mui/material'
import moment from 'moment-timezone'
import { useState } from 'react'

import { PatientEndDateReason, useMovePatientToCashContractAtDateMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { DatePicker, Dialog, DialogContent, FillButton, GhostButton, InfoWithHeading, toast } from '@nuna/tunic'

interface Props {
  patientId: string
  latestContractDate: Date
  onClose: () => void
}

export function EndContractAtDateDialog({ patientId, latestContractDate, onClose }: Props) {
  const [currentEndDate, setEndDate] = useState(moment().add(1, 'day').endOf('day'))
  const [endCurrentContractAtDateMutation, { loading }] = useMovePatientToCashContractAtDateMutation()

  const handleEndContractAtDate = async () => {
    try {
      const response = await endCurrentContractAtDateMutation({
        variables: {
          patientID: patientId,
          reason: PatientEndDateReason.RemovedFromRoster,
          newEndDate: moment(currentEndDate).toDate(),
        },
        refetchQueries: ['PatientCompanyHistory'],
        awaitRefetchQueries: true,
      })

      if (response.errors) {
        toast.urgent(errorService.transformUserGraphqlError(response.errors[0], 'Unable to end current contract'))
      } else {
        toast.success('Successfully ended current contract')
        onClose()
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Unable to end current contract'))
    }
  }

  return (
    <Dialog isOpen={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>End Current Client Contract</DialogTitle>
      <DialogContent paddingSize="md">
        <DialogContentText>
          <InfoWithHeading
            heading="End Date"
            info={
              <DatePicker
                minDate={moment().add(1, 'days')}
                maxDate={moment(latestContractDate)}
                value={currentEndDate}
                onChange={date => setEndDate(moment(date).endOf('day'))}
              />
            }
          />
          <ul className="text-secondary">
            <li>This will attempt to move over any dependents current contract who are not an employee themselves.</li>
            <li>
              This will end this client's current contract at the provided date. Make sure to account for the grace
              period.
            </li>
            <li>
              If they have another contract lined up after this one, they will transition to that contract at the
              provided date. Otherwise they will move to be a cash user.
            </li>
            <li>
              NOTE: This date represents the end of the day! So select the day before to allow a proper transition into
              the next contract.
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={onClose}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={() => handleEndContractAtDate()} isLoading={loading}>
          End Contract
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
