import { GridApi } from 'ag-grid-enterprise'
import { noop, xor } from 'lodash'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  ProviderDataDenormalized,
  ProviderDataDenormalizedColumnMetadata,
  useProviderDataDenormalizedColumnMetadataQuery,
} from '@nuna/api'

import { useMasterRosterQuickView } from './hooks/useMasterRosterQuickView'

export type FieldKey = keyof Partial<Omit<ProviderDataDenormalized, '__typename' | 'id'>>

interface DataState {
  id?: string
  updatedAt?: string
  refreshing?: boolean
}

interface MasterRosterContextValue {
  visibleColumns: FieldKey[]
  hiddenColumns: FieldKey[]
  gridApi: GridApi | undefined
  dataState: DataState
  columnMetadata: Pick<ProviderDataDenormalizedColumnMetadata, 'key' | 'name' | 'dataType' | 'required'>[]
  columnMetadataLoading: boolean
  columnKeys: FieldKey[]
  setGridApi: (gridApi: GridApi | undefined) => void
  setDataState: (state: DataState) => void
}

const InsuranceIntakeContext = createContext<MasterRosterContextValue>(defaultValue())

export function MasterRosterContextProvider({ children }: { children: React.ReactNode }) {
  const { data: metadataData, loading: columnMetadataLoading } = useProviderDataDenormalizedColumnMetadataQuery()
  const { quickViewColumns } = useMasterRosterQuickView()
  const columnMetadata = useMemo(() => metadataData?.providerDataDenormalizedColumnMetadata || [], [metadataData])
  const columnKeys = useMemo(() => columnMetadata.map(({ key }) => key) as FieldKey[], [columnMetadata])
  const [visibleColumns, setVisibleColumns] = useState<FieldKey[]>([])
  const hiddenColumns = xor(columnKeys, visibleColumns)
  const [gridApi, setGridApi] = useState<GridApi | undefined>()
  const [dataState, setDataState] = useState<DataState>({})

  useEffect(() => {
    setVisibleColumns(columnKeys)
  }, [columnKeys])

  const showAllColumns = useCallback(() => setVisibleColumns(columnKeys), [columnKeys])

  useEffect(() => {
    if (quickViewColumns) {
      setVisibleColumns(quickViewColumns)
    } else {
      showAllColumns()
    }
  }, [quickViewColumns, showAllColumns])

  const value: MasterRosterContextValue = {
    visibleColumns,
    hiddenColumns,
    gridApi,
    dataState,
    columnMetadata,
    columnKeys,
    columnMetadataLoading,
    setGridApi,
    setDataState,
  }

  return <InsuranceIntakeContext.Provider value={value}>{children}</InsuranceIntakeContext.Provider>
}

export function useMasterRosterContext() {
  return useContext(InsuranceIntakeContext)
}

function defaultValue(): MasterRosterContextValue {
  return {
    visibleColumns: [],
    hiddenColumns: [],
    gridApi: undefined,
    dataState: {},
    columnMetadata: [],
    columnKeys: [],
    columnMetadataLoading: true,
    setGridApi: noop,
    setDataState: noop,
  }
}
