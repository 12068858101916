import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { borderGrey, csx } from '@nuna/tunic'

import { Breadcrumb, BreadcrumbItem } from './Breadcrumbs'

interface Props extends HTMLAttributes<HTMLDivElement> {
  breadcrumbs: BreadcrumbItem[]
  loading: boolean
}

export function BreadcrumbBar({ breadcrumbs, className, loading, children, ...props }: Props) {
  return (
    <BreadcrumbBarStyled className={csx([className, 'space-between v-align'])} {...props}>
      <Breadcrumb breadcrumbs={breadcrumbs} loading={loading} />
      <div>{children}</div>
    </BreadcrumbBarStyled>
  )
}
const BreadcrumbBarStyled = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  padding: 0 1.5rem;
  height: 82px;
  min-height: 82px;
`
