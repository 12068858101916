import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { omit } from 'lodash'
import { useMemo } from 'react'

import { ProviderEnrollmentStatus, useSaveProviderEnrollmentMutation } from '@nuna/api'
import { ProviderSelect, SelectProvider } from '@nuna/common'
import { errorService, formService } from '@nuna/core'
import { FillButton, OutlineButton, Select, toast } from '@nuna/tunic'

const { composeHelperTextWithError } = formService

interface Props {
  id?: string | null
  insurancePayerId: string
  refetchQueries?: string[]
  onCancel: () => void
  afterSave: () => void
}

interface FormValues {
  id?: string | null
  insurancePayerId: string
  provider?: SelectProvider
  payerStatus: ProviderEnrollmentStatus
}

const schema = Yup.object().shape<FormValues>({
  id: Yup.string().nullable(),
  insurancePayerId: Yup.string().required(),
  payerStatus: Yup.mixed().oneOf(Object.values(ProviderEnrollmentStatus)).required(),
  provider: Yup.mixed<SelectProvider>().required('Provider is required'),
})

export function EnrollmentForm({ insurancePayerId, id, refetchQueries, onCancel, afterSave }: Props) {
  const [saveEnrollment] = useSaveProviderEnrollmentMutation({ refetchQueries })
  const initialValues: FormValues = useMemo(
    () => ({ id, insurancePayerId, provider: undefined, payerStatus: ProviderEnrollmentStatus.Pending }),
    [insurancePayerId, id],
  )

  const handleSubmit = async (values: FormValues) => {
    try {
      await saveEnrollment({
        variables: { enrollment: { ...omit(values, 'provider'), providerId: values.provider?.id } },
      })
      toast.success('Enrollment saved')
      afterSave()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'An error occurred'))
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values, setFieldValue, getFieldProps, touched, errors }) => (
        <Form>
          <ProviderSelect
            label="Provider"
            multiple={false}
            selectedProviders={values.provider}
            onChange={providers => setFieldValue('provider', providers)}
            {...composeHelperTextWithError('', errors.provider, touched.provider)}
          />
          <Select className="mt-3" {...getFieldProps('payerStatus')} label="Status">
            <option value={ProviderEnrollmentStatus.Pending}>Pending</option>
            <option value={ProviderEnrollmentStatus.Submitted}>Submitted</option>
            <option value={ProviderEnrollmentStatus.Enrolled}>Enrolled</option>
            <option value={ProviderEnrollmentStatus.Denied}>Denied</option>
          </Select>
          <div className="mt-5 v-align">
            <span className="ml-auto">
              <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
              <FillButton type="submit" className="ml-2">
                Save
              </FillButton>
            </span>
          </div>
        </Form>
      )}
    </Formik>
  )
}
