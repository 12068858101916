import { lowerCase, startCase } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppointmentCurrentStatus, AppointmentSearchOptions, ProviderFilterInput } from '@nuna/api'

import { Filter, Filters } from '../../../../shared/filters'
import { useClientFilter } from '../../../../shared/filters/common-filter-hooks/useClientFilter'
import { useProviderFilter } from '../../../../shared/filters/common-filter-hooks/useProviderFilter'
import { UrlFilterService, UrlFilters } from '../../../../shared/filters/hooks/useUrlFilters'
import { AppointmentTableFilterKey, useAppointmentTableFilterPresets } from '../hooks/useAppointmentTableFilterPresets'

interface AppointmentTableFiltersProps {
  urlFilterService: UrlFilterService<AppointmentSearchOptions>
}

const transformUrlFilters = (values: UrlFilters) => {
  return values
}

const searchProviderFilters: ProviderFilterInput = {}
const searchClientFilters = {}

export function AppointmentTableFilters({ urlFilterService }: AppointmentTableFiltersProps) {
  const providerFilter = useProviderFilter(searchProviderFilters)
  const clientFilter = useClientFilter(searchClientFilters)
  const navigate = useNavigate()

  const presets = useAppointmentTableFilterPresets()

  const navigateToAllAppointments = useCallback(() => {
    navigate({ pathname: 'all', search: document.location.search })
  }, [navigate])

  const filterOptions: Filter[] = useMemo<Filter[]>(
    () => [
      providerFilter,
      clientFilter,
      {
        key: AppointmentTableFilterKey.CurrentStatus,
        label: 'Current Status',
        getOptions: async () =>
          Object.values(AppointmentCurrentStatus).map(status => ({
            value: status,
            label: startCase(lowerCase(status)),
          })),
      },
      {
        key: AppointmentTableFilterKey.HasSessionNote,
        dataType: 'boolean',
        label: 'Has Session Note',
      },
      {
        key: AppointmentTableFilterKey.StartDatetime,
        dataType: 'datetime',
        label: 'Start Date',
        value: presets[AppointmentTableFilterKey.StartDatetime],
        onChange: navigateToAllAppointments,
        onRemove: navigateToAllAppointments,
      },
    ],
    [providerFilter, clientFilter, presets, navigateToAllAppointments],
  )
  return (
    <Filters filters={filterOptions} urlFilterService={urlFilterService} transformUrlFilters={transformUrlFilters} />
  )
}
