import { styled } from '@mui/material'

import { SearchEventInput } from '@nuna/api'
import { Card, GhostButton, IconClose, csx } from '@nuna/tunic'

import { EventTypeSelect } from './EventTypeSelect'

interface Props {
  filterValues: SearchEventInput
  fixedFilters?: SearchEventInput
  setFilterVal: (key: keyof SearchEventInput, value: unknown) => void
  clearFilters: () => void
}

export function UserEventLogFilters({ filterValues, fixedFilters, setFilterVal, clearFilters }: Props) {
  const hasFiltersApplied = Object.values(filterValues).some(value => !!value)

  return (
    <StyledCard depth={-0.5} className="p-2 mb-1 v-align">
      {!fixedFilters?.eventType && (
        <EventTypeSelect
          className="filter-input"
          value={filterValues.eventType || null}
          onChange={option => setFilterVal('eventType', option?.value)}
        />
      )}
      {/* TODO: add more controls when  */}
      <GhostButton
        className={csx([{ hidden: !hasFiltersApplied }, 'ml-auto clear-button'])}
        variant="secondary"
        onClick={clearFilters}
      >
        <IconClose size={14} className="mr-xs" />
        Clear all
      </GhostButton>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  .filter-input {
    width: 200px;
    margin-right: var(--spacing-2);
  }

  .filter-input-wide {
    width: 240px;
    margin-right: var(--spacing-2);
  }

  .clear-button {
    &.hidden {
      visibility: hidden;
    }
  }
`
