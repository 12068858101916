import { capitalize } from 'lodash'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Row } from 'react-table'

import { ProviderPayrollSummaryFragment, SessionFinancialFragment, usePayrollDetailsQuery } from '@nuna/api'
import { Link, UserLink } from '@nuna/common'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn, DataTableState, dateSortComparer, profileUserSortComparer } from '@nuna/data-table'
import { useFeatureFlags } from '@nuna/feature-flag'
import { TextButton, Tooltip } from '@nuna/tunic'

import { sessionDetail } from '../../util/routes'
import { AdminAdjustmentForm } from './AdminAdjustmentForm'

interface Props extends Row<ProviderPayrollSummaryFragment> {
  startDate: moment.Moment
  endDate: moment.Moment
  onChange: () => void
}

export const columns: (
  handleAdjustmentClick: (row: SessionFinancialFragment) => void,
  canMakeAdjustments: boolean,
) => DataTableColumn<SessionFinancialFragment>[] = (handleAdjustmentClick, canMakeAdjustments) => [
  {
    accessor: 'id',
    Header: 'Appointment Date',
    Cell: ({ row }) => (
      <Link to={sessionDetail(row.original.appointmentId)} openInNewTab>
        {moment(row.original.appointmentInfoSummary.endDatetime).format('M/D/YYYY')}
      </Link>
    ),
    sortType: dateSortComparer(row => row.original.appointmentInfoSummary.endDatetime),
  },
  {
    accessor: 'providerCompensationPayableDate',
    Header: 'Payable Date',
    Cell: ({ value }) => <>{moment(value).format('M/D/YYYY')}</>,
    sortType: dateSortComparer(row => row.original.providerCompensationPayableDate),
  },
  {
    accessor: 'externalPayrollStatus',
    Header: 'Payment Date',
    Cell: ({ value, row }) => {
      return value ? (
        <Tooltip content={capitalize(value)}>
          <span>
            <Link to={row.original.externalPaymentLink || '#'} openInNewTab>
              {moment(row.original.externalPaymentDate).format('M/D/YYYY')}
            </Link>
          </span>
        </Tooltip>
      ) : (
        <>Unpaid</>
      )
    },
  },
  {
    accessor: 'reason',
    Header: 'Outcome',
    Cell: ({ value, row }) => {
      const prettyValue = row.original.note ? row.original.note : capitalize(value.replace(/_/g, ' '))
      const shortDescription = row.original.note
        ? capitalize(value.replace(/_/g, ' '))
        : prettyValue.startsWith('Session')
        ? capitalize(prettyValue.substring(prettyValue.indexOf(' ') + 1))
        : prettyValue.substring(0, prettyValue.indexOf(' ', 3))
      return (
        <Tooltip content={prettyValue}>
          <span>{shortDescription}</span>
        </Tooltip>
      )
    },
  },
  {
    accessor: 'appointmentInfoSummary',
    Header: 'Patient',
    Cell: ({ value }) => (
      <UserLink
        openInNewTab
        showAvatar={false}
        user={{ __typename: 'Patient', id: value.patientId, firstName: value.patientName, lastName: '' }}
      />
    ),
    sortType: profileUserSortComparer(row => ({
      __typename: 'Patient',
      firstName: row.original.appointmentInfoSummary.patientName,
      lastName: '',
      id: '',
    })),
  },
  {
    accessor: 'cptCode',
    Header: 'CPT Code',
  },
  {
    accessor: 'providerCompensatedAmount',
    Header: 'Amount',
    className: 'right-align',
    Cell: ({ value, row }) =>
      canMakeAdjustments ? (
        <Tooltip content="Create adjustment">
          <TextButton variant="secondary" onClick={() => handleAdjustmentClick(row.original)}>
            {numberService.centsToFormattedDollars(value, { maximumFractionDigits: 2 })}
          </TextButton>
        </Tooltip>
      ) : (
        <>{numberService.centsToFormattedDollars(value, { maximumFractionDigits: 2 })}</>
      ),
  },
]

const initialTableState: DataTableState<SessionFinancialFragment> = {
  sortBy: [{ id: 'providerCompensatedAmount', desc: true }],
}

export function ProviderPayrollProviderDetailRow({ original: summary, startDate, endDate, onChange }: Props) {
  const [adjustmentFinancial, setAdjustmentFinancial] = useState<Partial<SessionFinancialFragment> | null>(null)
  const { adminFinancials } = useFeatureFlags()
  const { data, loading, refetch } = usePayrollDetailsQuery({
    variables: { startDate: startDate.format(), endDate: endDate.format(), providerId: summary.providerId },
  })
  const rowData: SessionFinancialFragment[] | undefined = useMemo(() => data?.payrollDetails, [data])

  function handleAdjustmentClick(financial: SessionFinancialFragment) {
    setAdjustmentFinancial({
      appointmentId: financial.appointmentId,
      appointmentHistoryItemId: financial.appointmentHistoryItemId,
      cptCode: financial.cptCode,
    })
  }

  function handleSave() {
    setAdjustmentFinancial(null)
    refetch()
    onChange()
  }

  return (
    <div className="m-1">
      <DataTable
        loading={loading}
        initialState={initialTableState}
        columns={columns(handleAdjustmentClick, adminFinancials)}
        rowData={rowData}
      />
      {adjustmentFinancial && (
        <AdminAdjustmentForm
          adjustment={adjustmentFinancial}
          onCancel={() => setAdjustmentFinancial(null)}
          onSave={handleSave}
        />
      )}
    </div>
  )
}
