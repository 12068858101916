import React from 'react'

import { useSyncProviderInfoFromVerifiableMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { IconHalfway, IconProgressIndicator, NavMenuButton, OutlineButton, Tooltip, toast } from '@nuna/tunic'

type Props = {
  providerId: string
  useIcon?: boolean
}
export function VerifiableSyncButton({ providerId, useIcon = false }: Props) {
  const [syncVerifiableInfo, { loading: syncVerifiableInfoLoading }] = useSyncProviderInfoFromVerifiableMutation({
    refetchQueries: ['Provider'],
  })
  const handleSyncVerifiableInfo = async () => {
    try {
      await syncVerifiableInfo({ variables: { providerId } })
      toast.success('Synced provider education from Verifiable')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, `Error syncing provider education from verifiable`))
    }
  }

  const icon = syncVerifiableInfoLoading ? (
    <Tooltip content={'Syncing'}>
      <NavMenuButton>
        <IconProgressIndicator />
      </NavMenuButton>
    </Tooltip>
  ) : (
    <Tooltip content={'Sync With Verifiable'}>
      <NavMenuButton>
        <IconHalfway onClick={handleSyncVerifiableInfo} />
      </NavMenuButton>
    </Tooltip>
  )

  return useIcon ? (
    icon
  ) : (
    <OutlineButton onClick={handleSyncVerifiableInfo} isLoading={syncVerifiableInfoLoading}>
      Sync From Verifiable
    </OutlineButton>
  )
}
