import { Navigate, Route, Routes } from 'react-router-dom'

import { TimeZoneControl } from '@nuna/common'
import { MenuItem, PageContent, PageHeader, PageHeading, PageWrapper, ResponsiveHorizontalMenu } from '@nuna/tunic'

import { AppointmentTable } from './AppointmentTable/AppointmentTable'
import { AppointmentTableFilterPresetKey } from './AppointmentTable/hooks/useAppointmentTableFilterPresets'

export function AppointmentViews() {
  return (
    <PageWrapper>
      <PageHeader>
        <div className="v-align flex-remaining-space" style={{ overflow: 'hidden' }}>
          <PageHeading className="mb-0 mr-2">Appointments</PageHeading>
          <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
            <MenuItem to={AppointmentTableFilterPresetKey.Today}>Today</MenuItem>
            <MenuItem to="all">All Appointments</MenuItem>
          </ResponsiveHorizontalMenu>
        </div>
        <TimeZoneControl />
      </PageHeader>
      <PageContent>
        <Routes>
          <Route index element={<Navigate to="today" replace />} />
          <Route path="*" element={<AppointmentTable />}></Route>
        </Routes>
      </PageContent>
    </PageWrapper>
  )
}
