import { isNil } from 'lodash'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import { ChangeEvent } from 'react'

import { ProviderFilterInput, ProviderStatus } from '@nuna/api'
import { CredentialingStatusSelect, CredentialsSelect, type SelectSpecialty, SpecialtiesSelect } from '@nuna/common'
import { StateOption } from '@nuna/core'
import { InsurancePayerPlanSelect } from '@nuna/coverage'
import { ProviderStatusOption, ProviderStatusSelect } from '@nuna/provider'
import { Select, StateAutocompleteMultiple, StateAutocompleteSingle } from '@nuna/tunic'

import { DataFilters } from '../../shared/DataFilters'

export type ProvidersFilterValues = {
  searchTerm: string
  statuses: ProviderStatusOption[]
  specialties: SelectSpecialty[]
  credentialStates: StateOption[]
  activeCredentialsIn: string[]
} & Pick<
  ProviderFilterInput,
  | 'gender'
  | 'active'
  | 'resideInState'
  | 'missingCompensationAmounts'
  | 'insurancePayerPlanId'
  | 'credentialingStatuses'
>

interface Props extends HTMLAttributes<HTMLDivElement> {
  filterValues: ProvidersFilterValues
  setFilterVal: (key: keyof ProvidersFilterValues, value: unknown) => void
  clearFilters: () => void
  filtersToInclude: (keyof ProvidersFilterValues)[]
  orientation?: 'horizontal' | 'vertical'
}

interface IncludeFilterProps extends HTMLAttributes<HTMLDivElement> {
  filterKey: keyof ProvidersFilterValues
}

export const DEFAULT_PROVIDER_TABLE_FILTER_VALUES: ProvidersFilterValues = {
  active: undefined,
  gender: undefined,
  statuses: [],
  credentialStates: [],
  resideInState: null,
  specialties: [],
  searchTerm: '',
  activeCredentialsIn: [],
}

export function ProviderFilters({ filterValues, setFilterVal, clearFilters, filtersToInclude, ...props }: Props) {
  const handleSearchTextChange = useCallback(
    (searchText: string) => {
      setFilterVal('searchTerm', searchText)
    },
    [setFilterVal],
  )

  const IncludeFilter = useCallback(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ({ filterKey, children }: IncludeFilterProps) => (filtersToInclude.includes(filterKey) ? <>{children}</> : null),
    [filtersToInclude],
  )

  const handleActiveFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value
    const shouldFilterOnActive = value === 'true' ? true : value === 'false' ? false : null
    setFilterVal('active', shouldFilterOnActive)
  }

  const handleMissingCompensationAmountsFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const rawValue = event.currentTarget.value
    const value = rawValue === 'true' ? true : rawValue === 'false' ? false : null
    setFilterVal('missingCompensationAmounts', value)
  }

  const handleGenderChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value === 'any' ? undefined : event.currentTarget.value
    setFilterVal('gender', value)
  }

  const appliedFiltersCount = useMemo(() => {
    return countAppliedProviderFilters(filterValues)
  }, [filterValues])

  return (
    <DataFilters
      onSearchTextChange={handleSearchTextChange}
      filtersApplied={appliedFiltersCount}
      searchPlaceholder="Search providers"
      onClearFilters={clearFilters}
      {...props}
    >
      <IncludeFilter filterKey="missingCompensationAmounts">
        <Select
          className="mb-3"
          value={filterValues.missingCompensationAmounts?.toString() ?? 'any'}
          label="Missing Rates"
          onChange={handleMissingCompensationAmountsFilterChange}
        >
          <option value="any">Show All</option>
          <option value="true">Missing Any Rates</option>
          <option value="false">No Missing Rates</option>
        </Select>
      </IncludeFilter>

      <IncludeFilter filterKey="active">
        <Select
          className="mb-3"
          value={filterValues.active?.toString() ?? 'any'}
          label="Active Status"
          onChange={handleActiveFilterChange}
        >
          <option value="any">Any</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </Select>
      </IncludeFilter>

      <IncludeFilter filterKey="statuses">
        <ProviderStatusSelect
          className="mb-3"
          value={filterValues.statuses}
          onChange={newStatuses => setFilterVal('statuses', newStatuses)}
        />
      </IncludeFilter>

      <IncludeFilter filterKey="activeCredentialsIn">
        <CredentialsSelect
          className="mb-3"
          value={filterValues.activeCredentialsIn ?? []}
          onChange={credentials => setFilterVal('activeCredentialsIn', credentials)}
        />
      </IncludeFilter>

      <IncludeFilter filterKey="insurancePayerPlanId">
        <InsurancePayerPlanSelect
          className="mb-3"
          displayPayerName
          value={filterValues.insurancePayerPlanId}
          onChange={payer => setFilterVal('insurancePayerPlanId', payer?.id)}
        />
      </IncludeFilter>

      <IncludeFilter filterKey="specialties">
        <SpecialtiesSelect
          className="mb-3"
          label="Specialties"
          value={filterValues.specialties}
          onChange={newSpecialties => setFilterVal('specialties', newSpecialties)}
        />
      </IncludeFilter>

      <IncludeFilter filterKey="credentialStates">
        <StateAutocompleteMultiple
          label="Credentialed State"
          className="mb-3"
          value={filterValues.credentialStates}
          onChange={newStates => setFilterVal('credentialStates', newStates)}
        />
      </IncludeFilter>

      <IncludeFilter filterKey="resideInState">
        <StateAutocompleteSingle
          label="Residential State"
          className="mb-3"
          value={filterValues.resideInState ?? null}
          onChange={newState => setFilterVal('resideInState', newState?.value)}
        />
      </IncludeFilter>

      <IncludeFilter filterKey="gender">
        <Select className="mb-3" value={filterValues.gender ?? 'any'} label="Gender" onChange={handleGenderChange}>
          <option value="any">Any</option>
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
        </Select>
      </IncludeFilter>

      <IncludeFilter filterKey="credentialingStatuses">
        <CredentialingStatusSelect
          className="mb-3"
          value={filterValues.credentialingStatuses ?? []}
          onChange={credentialingStatuses =>
            setFilterVal('credentialingStatuses', credentialingStatuses.length > 0 ? credentialingStatuses : undefined)
          }
        />
      </IncludeFilter>
    </DataFilters>
  )
}

export function buildProviderFilterInput(
  values: Partial<ProvidersFilterValues> = DEFAULT_PROVIDER_TABLE_FILTER_VALUES,
  fixedFilters: Partial<ProvidersFilterValues> = {},
): ProviderFilterInput {
  const {
    gender,
    searchTerm,
    active,
    statuses = [],
    credentialStates = [],
    resideInState,
    specialties = [],
    activeCredentialsIn = [],
    missingCompensationAmounts,
    insurancePayerPlanId,
    credentialingStatuses,
  } = values
  return {
    ...fixedFilters,
    search: {
      term: searchTerm,
      threshold: 0.31,
    },
    gender,
    active,
    resideInState,
    activeCredentialsIn,
    statusIn: statuses.map(status => status.value as ProviderStatus),
    credentialedInStates: credentialStates.map(state => state.value),
    specializesIn: specialties.map(specialty => specialty.id),
    missingCompensationAmounts,
    insurancePayerPlanId,
    credentialingStatuses: credentialingStatuses?.length ? credentialingStatuses : fixedFilters?.credentialingStatuses,
  }
}

export function countAppliedProviderFilters(filterValues: ProvidersFilterValues) {
  const {
    gender,
    statuses,
    specialties,
    active,
    credentialStates,
    resideInState,
    activeCredentialsIn,
    missingCompensationAmounts,
    searchTerm,
    credentialingStatuses,
  } = filterValues
  const hasSearchTerm = searchTerm ? 1 : 0
  const genderFilterCount = gender ? 1 : 0
  const statusFilterApplied = statuses.length ? 1 : 0
  const activeFilterApplied = isNil(active) ? 0 : 1
  const missingRateFilterApplied = isNil(missingCompensationAmounts) ? 0 : 1
  const resideInStateApplied = resideInState ? 1 : 0
  const credentialFilterApplied = (activeCredentialsIn ?? []).length ? 1 : 0
  const credentialingStatusFilterApplied = credentialingStatuses?.length ? 1 : 0
  return (
    hasSearchTerm +
    specialties.length +
    credentialStates.length +
    genderFilterCount +
    statusFilterApplied +
    activeFilterApplied +
    resideInStateApplied +
    credentialFilterApplied +
    missingRateFilterApplied +
    credentialingStatusFilterApplied
  )
}
