import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { compact } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { ProviderDataDenormalizedColumnMetadata } from '@nuna/api'

import { useColumnMetaData } from '../hooks/useColumnMetaData'

type Option = Pick<ProviderDataDenormalizedColumnMetadata, 'key' | 'name'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<Option | undefined, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface ProviderDataDenormColumnMultiSelectProps extends LimitedAutocompleteProps {
  value: string[]
  onChange: (options: Option[]) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
}

export function ProviderDataDenormColumnMultiSelect({
  value: valueInput = [],
  label,
  placeholder,
  helperText,
  error,
  onChange,
  ...props
}: ProviderDataDenormColumnMultiSelectProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const { columnMetaData } = useColumnMetaData()
  const options = useMemo(() => buildOptions(columnMetaData), [columnMetaData])

  const value = useMemo<Option[]>(
    () => options.filter(option => valueInput.includes(option.key)),
    [valueInput, options],
  )

  const filteredOptions = useMemo<Option[]>(() => {
    if (!searchTerm) {
      return options
    }
    const searcher = new FuzzySearch(options, ['name', 'key'], {
      caseSensitive: false,
    })

    return searcher.search(searchTerm)
  }, [searchTerm, options])

  return (
    <Autocomplete
      {...props}
      loading={!columnMetaData}
      multiple
      onChange={(_event, value) => {
        onChange(compact(value))
      }}
      defaultValue={[]}
      getOptionLabel={option => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.key === value?.key}
      options={filteredOptions}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          name="selected-column"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  )
}

function buildOptions(data: ProviderDataDenormalizedColumnMetadata[] | undefined): Option[] {
  if (!data) return []
  return data.map(({ key, name }) => ({ key, name }))
}
