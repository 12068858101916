import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import { DocumentType, PartnerInput, useDocumentUpload, usePartnerQuery, useSavePartnerMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { FillButton, Grid, HexColorField, PictureField, Skeleton, TextField, toast } from '@nuna/tunic'

import { EntityForm, EntityFormButtonRow } from '../../shared/EntityForm'
import { partnerDetail } from '../../util/routes'

const { composeHelperTextWithError } = formService

type RouteProps = { id: string }

const hexColorCodeSchema = Yup.string().matches(/^#[0-9a-fA-F]{3,6}$/)

const partnerSchema = Yup.object().shape({
  id: Yup.string().required('ID is required'),
  name: Yup.string().required('Name is required'),
  primaryColor: hexColorCodeSchema.nullable(),
  secondaryColor: hexColorCodeSchema.nullable(),
  buttonColor: hexColorCodeSchema.nullable(),
  buttonHoverColor: hexColorCodeSchema.nullable(),
  logoDocumentId: Yup.string().nullable(),
})

export function UpdatePartner() {
  const navigate = useNavigate()
  const { uploadDocument } = useDocumentUpload()
  const { id: partnerId } = useParams<RouteProps>()
  const { data, loading } = usePartnerQuery({ variables: { id: partnerId || '' }, skip: !partnerId })
  const { partner } = data || {}
  const [updatePartner, { loading: updateLoading }] = useSavePartnerMutation()
  const initialValues: PartnerInput = {
    ...pick(partner, ['name', 'id']),
    ...pick(partner?.branding, ['primaryColor', 'secondaryColor', 'buttonColor', 'buttonHoverColor', 'logoDocumentId']),
  }

  if (loading || !data) {
    return <Skeleton width={100} height={20} dimensionUnits="%" />
  }

  const submit = async (values: PartnerInput) => {
    try {
      const variables = {
        data: {
          ...values,
          primaryColor: values.primaryColor || null,
          secondaryColor: values.secondaryColor || null,
          buttonColor: values.buttonColor || null,
          buttonHoverColor: values.buttonHoverColor || null,
        },
      }
      await updatePartner({ variables, refetchQueries: ['Partner'], awaitRefetchQueries: true })

      if (partnerId) {
        navigate(partnerDetail(partnerId))
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Please check the form and try again'))
    }
  }

  return (
    <Formik initialValues={{ ...initialValues }} onSubmit={submit} validationSchema={partnerSchema}>
      {formProps => {
        const { handleSubmit, touched, errors, values, handleChange, setFieldValue } = formProps

        const uploadPartnerLogo = async (file: File) => {
          try {
            const document = await uploadDocument(file, DocumentType.CompanyLogo)

            setFieldValue('logoDocumentId', document.id)
          } catch (e) {
            toast.urgent(errorService.transformUserGraphqlError(e, 'Failed to upload logo'))
          }
        }

        return (
          <PartnerEntityForm onSubmit={handleSubmit}>
            <TextField
              name="name"
              value={values.name}
              label="Name"
              onChange={handleChange}
              {...composeHelperTextWithError('', errors.name, !!touched.name)}
            />
            <div>
              <label htmlFor="logoDocumentId" className="block mb-1">
                Logo
              </label>
              <PictureField
                accept={['image/png', 'image/svg+xml']}
                maxSize={1000000}
                purpose="logo"
                onImageAdd={image => image && uploadPartnerLogo(image)}
                captionText="Image must be .png or .svg format and less than 1 MB"
              ></PictureField>
            </div>
            <Grid container spacing={6}>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <HexColorField
                  name="primaryColor"
                  value={values.primaryColor}
                  label="Primary Color"
                  onChange={handleChange}
                  {...composeHelperTextWithError('', errors.primaryColor, !!touched.primaryColor)}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <HexColorField
                  name="secondaryColor"
                  value={values.secondaryColor}
                  label="Secondary Color"
                  onChange={handleChange}
                  {...composeHelperTextWithError('', errors.secondaryColor, !!touched.secondaryColor)}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <HexColorField
                  name="buttonColor"
                  value={values.buttonColor}
                  label="Button Color"
                  error={!!touched.buttonColor && !!errors.buttonColor}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <HexColorField
                  name="buttonHoverColor"
                  value={values.buttonHoverColor}
                  label="Button Hover Color"
                  error={!!touched.buttonHoverColor && !!errors.buttonHoverColor}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <EntityFormButtonRow>
              <FillButton isLoading={updateLoading} type="submit">
                Update Partner
              </FillButton>
            </EntityFormButtonRow>
          </PartnerEntityForm>
        )
      }}
    </Formik>
  )
}

const PartnerEntityForm = styled(EntityForm)`
  display: flex;
  flex-direction: column;
  gap: var(--margin-3);
`
