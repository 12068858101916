import { styled } from '@mui/material'

import { ProviderActivityEventType, ProviderActivityLog } from '@nuna/api'
import { Card, CardBody, IconButtonExternalLink, IconDownload, IconExport } from '@nuna/tunic'

import { ProviderActivityLogMetadataDisplay } from './ProviderActivityLogMetadataDisplay'

export function ProviderActivityLogItem({
  eventType,
  title,
  description,
  link,
  downloadLink,
  authorDisplayData,
  metadata,
}: ProviderActivityLog) {
  switch (eventType) {
    case ProviderActivityEventType.FileUpload:
    case ProviderActivityEventType.LicensesCheckDone:
    case ProviderActivityEventType.BackgroundCheckDone:
      return (
        <ActivityCard depth={1} shadowColor="cool">
          <CardBody className="v-align" style={{ display: 'flex', flexFlow: 'row', height: 56 }}>
            <h4 className="body text-bold mb-0">{title}</h4>&nbsp;{description}
            {link && (
              <IconButtonExternalLink className="ml-auto" tooltip="View at source" variant="secondary" href={link}>
                <IconExport />
              </IconButtonExternalLink>
            )}
            {downloadLink && (
              <a className="ml-auto" href={downloadLink} download>
                <IconDownload />
              </a>
            )}
          </CardBody>
        </ActivityCard>
      )
    default:
      return (
        <ActivityText>
          <h4 className="body text-bold mb-0">{title}</h4>
          {authorDisplayData?.firstName && (
            <div className="caption text-secondary">
              Action performed by {authorDisplayData.firstName} {authorDisplayData?.lastName}
            </div>
          )}
          {description && <div className="body mt-xs">{description}</div>}
          <ProviderActivityLogMetadataDisplay className="mt-xs" metadata={metadata} />
        </ActivityText>
      )
  }
}

const ActivityCard = styled(Card)`
  width: 100%;
  margin: 0rem 0rem 1.25rem 0rem;
  border-radius: var(--border-radius-sm);
`

const ActivityText = styled('div')`
  padding: 0rem 0rem 1.25em 1.1em;
  width: 100%;
`
