import { NotificationFeed } from '@knocklabs/react'
import { styled } from '@mui/material'
import { motion } from 'framer-motion'

import { borderGrey, shadowDepth } from '@nuna/tunic'

import { NotificationFeedHeader } from './components/NotificationFeedHeader'

interface NotificationCenterProps {
  isOpen: boolean
  onClose: () => void
}
export function NotificationCenter({ isOpen, onClose }: NotificationCenterProps) {
  return (
    <motion.div
      animate={{ width: isOpen ? 'auto' : 0 }}
      initial={false}
      transition={{ duration: 0.3, ease: 'easeOut' }}
    >
      <InnerWrapper>
        <NotificationFeed renderHeader={headerProps => <NotificationFeedHeader {...headerProps} onClose={onClose} />} />
      </InnerWrapper>
    </motion.div>
  )
}

const InnerWrapper = styled('div')`
  width: 460px;
  box-shadow: ${shadowDepth(4)};
  border-right: 1px solid ${borderGrey};
  height: 100%;
  padding: var(--spacing-1);
`
