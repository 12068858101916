import { useApolloClient } from '@apollo/client'
import { includes } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import {
  OrderBy,
  ProviderCredentialingStatus,
  ProviderListItemFragment,
  TraversablePaginationSortInput,
  useAutoAssignPracticeAddressesMutation,
  useSearchProvidersQuery,
} from '@nuna/api'
import { UserLink } from '@nuna/common'
import { DataTable, DataTableColumn, usePagination } from '@nuna/data-table'
import { CredentialingStatusLabel } from '@nuna/provider'
import { FillButton, IconSwitch, OutlineButton, makeTypographyComponent } from '@nuna/tunic'

import { ProviderStatusLabel } from '../../../../provider/ProviderStatusLabel'

const INITIAL_SORT: TraversablePaginationSortInput[] = [
  { key: 'status', direction: OrderBy.Asc },
  { key: 'createdAt', direction: OrderBy.Desc },
]

export function ProvidersMissingPrimaryLocationTable() {
  const client = useApolloClient()
  const [autoAssignmentLoading, setAutoAssignmentLoading] = useState(false)
  const [autoAssignPracticeAddresses] = useAutoAssignPracticeAddressesMutation()
  const { handleSort, queryOptions, getPaginatorProps, initialTableState, setPage, setPagination, setLoading } =
    usePagination({ loading: true, initialSort: INITIAL_SORT }, { limit: 20 })

  const { data, loading } = useSearchProvidersQuery({
    variables: {
      filters: {
        credentialingStatuses: Object.values(ProviderCredentialingStatus).filter(
          v => !includes([ProviderCredentialingStatus.Archived, ProviderCredentialingStatus.Denied], v),
        ),
        missingPrimaryPracticeLocation: true,
      },
      pagination: queryOptions.paginationInput,
    },
  })

  useEffect(() => setPagination(data?.searchProviders.pagination), [data?.searchProviders.pagination, setPagination])

  useEffect(() => setLoading(loading), [loading, setLoading])

  const columns: DataTableColumn<ProviderListItemFragment>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'lastName',
        Cell: ({ row }) => <UserLink user={row.original} />,
        paginatedSortable: true,
      },
      {
        Header: 'Credentialing Status',
        accessor: row => row.providerCredentialing?.status,
        Cell: ({ value }: { value: ProviderCredentialingStatus }) => <CredentialingStatusLabel status={value} />,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, row }) => <ProviderStatusLabel status={value} endDate={row.original.endDate} />,
        paginatedSortable: true,
      },
      {
        Header: 'Date Added',
        accessor: 'createdAt',
        paginatedSortable: true,
        Cell: ({ value }) => <>{moment(value).format('l')}</>,
      },
    ],
    [],
  )

  const rowData = useMemo(() => data?.searchProviders.items, [data])

  const refresh = () => {
    client.cache.evict({ fieldName: 'searchProviders' })
    setPage(1)
  }

  const handleAutoAssign = async () => {
    setAutoAssignmentLoading(true)
    await autoAssignPracticeAddresses()
    setTimeout(() => {
      refresh()
      setAutoAssignmentLoading(false)
    }, 10000)
  }

  return (
    <Container>
      <Header>
        <h2 className="mb-0 h5">Providers Missing Primary Practice Location</h2>
        <div className="v-align ml-auto gap-3">
          <FillButton isLoading={autoAssignmentLoading} className="ml-auto" onClick={handleAutoAssign}>
            Auto-assign Practice Locations
          </FillButton>
          <OutlineButton onClick={refresh}>
            <IconSwitch className="mr-1" />
            Refresh
          </OutlineButton>
        </div>
      </Header>
      <DataTable
        scrollStrategy="within"
        columns={columns}
        rowData={rowData}
        initialState={initialTableState}
        loading={loading}
        onSort={handleSort}
        paginated
        paginatorProps={getPaginatorProps()}
      />
    </Container>
  )
}

const Container = makeTypographyComponent('flex-column overflow-hidden', 'div')
const Header = makeTypographyComponent('v-align mb-2', 'div')
