import { HTMLAttributes, useState } from 'react'

import { ProviderCompensationRateType, useProviderCompensationRatesQuery } from '@nuna/api'
import { numberService } from '@nuna/core'
import { Dialog, GhostButton, IconButton, IconClose } from '@nuna/tunic'

import { RateLabel, RateRow, RateValue, SectionHeading } from './FeesCardTypography'
import { SelfReferralInsuranceRatesTable } from './SelfReferralInsuranceRatesTable'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string
}

export function SelfReferralRates({ providerId, ...props }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { data } = useProviderCompensationRatesQuery({ variables: { providerId }, fetchPolicy: 'cache-and-network' })

  const rates = data?.providerCompensationRates?.selfReferredRates ?? []
  const cashRate = rates.filter(r => r.type === ProviderCompensationRateType.CashPay)[0]
  const insuranceRates = rates.filter(r => r.type === ProviderCompensationRateType.Insurance)

  return (
    <div {...props}>
      <SectionHeading>Self Referral Rates</SectionHeading>
      {cashRate && (
        <RateRow>
          <RateLabel>Cash Pay</RateLabel>
          <RateValue className="text-medium">{numberService.centsToFormattedDollars(cashRate.rate)}</RateValue>
        </RateRow>
      )}
      {insuranceRates.length > 0 && (
        <RateRow>
          <RateLabel>Insurance</RateLabel>
          <GhostButton variant="secondary" onClick={() => setDialogOpen(true)}>
            See rates
          </GhostButton>
        </RateRow>
      )}
      <Dialog isOpen={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xl">
        <div className="p-4 flex-column" style={{ overflow: 'hidden' }}>
          <div className="v-align space-between mb-2">
            <h6 className="mb-0">Insurance Session Rates</h6>
            <IconButton tooltip="Close" onClick={() => setDialogOpen(false)} style={{ marginRight: -12 }}>
              <IconClose size={18} />
            </IconButton>
          </div>
          <div className="flex-remaining-space" style={{ overflowY: 'auto' }}>
            <SelfReferralInsuranceRatesTable rates={insuranceRates} />
          </div>
        </div>
      </Dialog>
    </div>
  )
}
