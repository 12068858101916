import * as Yup from 'yup'
import { Formik, setNestedObjectValues } from 'formik'
import { useNavigate } from 'react-router-dom'

import { PartnerInput, useSavePartnerMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { FillButton, PageContent, PageHeader, PageHeading, PageWrapper, TextField, toast } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { EntityForm, EntityFormButtonRow, EntityFormSection } from '../../shared/EntityForm'
import { partnerDetail } from '../../util/routes'

type FormValues = Pick<PartnerInput, 'name'>

const partnerSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const initialValues: FormValues = {
  name: '',
}

export function NewPartner() {
  const navigate = useNavigate()

  const [createPartner, { loading: updateLoading }] = useSavePartnerMutation()

  const submit = async (values: FormValues) => {
    try {
      const { data: newPartnerData } = await createPartner({ variables: { data: { name: values.name } } })

      const partnerId = newPartnerData?.savePartner?.id
      if (partnerId) {
        navigate(partnerDetail(partnerId))
      }
    } catch (e) {
      console.error(e)
      toast.urgent('Please check the form and try again')
    }
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading className="mb-0">New Partner</PageHeading>
        </PageHeader>
        <PageContent>
          <Formik initialValues={initialValues} onSubmit={submit} validationSchema={partnerSchema}>
            {formProps => {
              const { values, handleSubmit, setTouched, handleBlur, handleChange, errors, touched } = formProps

              return (
                <EntityForm onSubmit={handleSubmit} style={{ maxWidth: '40rem' }}>
                  <EntityFormSection>
                    <TextField
                      required
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      {...formService.composeHelperTextWithError('', errors.name, !!touched.name)}
                    />
                  </EntityFormSection>
                  <EntityFormButtonRow>
                    <FillButton
                      isLoading={updateLoading}
                      type="submit"
                      onClick={() => {
                        setTouched(setNestedObjectValues(values, true))
                      }}
                    >
                      Create Partner
                    </FillButton>
                  </EntityFormButtonRow>
                </EntityForm>
              )
            }}
          </Formik>
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
