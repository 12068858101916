import { CredentialingChecklistDataPoint } from '@nuna/api'
import { TavaDataTable } from '@nuna/common'

import { DEFAULT_COL_DEFS, defaultChecklistDialogContentColumns } from './defaults'

export const ChecklistWorklistDialogContent = ({ data }: { data: CredentialingChecklistDataPoint }) => {
  const { items } = typeof data.details === 'string' ? JSON.parse(data.details) : data.details

  return (
    <TavaDataTable rowData={items} columnDefs={defaultChecklistDialogContentColumns} defaultColDef={DEFAULT_COL_DEFS} />
  )
}
