import { styled } from '@mui/material'
import { HTMLAttributes, useMemo } from 'react'

import { EnrollmentPacketsGenerator } from '@nuna/provider'
import { Card, CardBody } from '@nuna/tunic'

import { AddressEnrollmentTable } from '../../address-enrollment/AddressEnrollmentTable/AddressEnrollmentTable'
import { ProviderEnrollmentTable } from '../../enrollment/components/ProviderEnrollmentTable/ProviderEnrollmentTable'

interface ProviderInsuranceEnrollmentsProps {
  providerId: string
}

export function ProviderInsuranceEnrollments({ providerId }: ProviderInsuranceEnrollmentsProps) {
  const fixedFilters = useMemo(() => ({ providerId }), [providerId])
  return (
    <div className="flex-column gap-2 full-height">
      <EnrollmentPacketsGenerator providerId={providerId} />
      <TableCard>
        <ProviderEnrollmentTable
          fixedFilters={fixedFilters}
          columns={[
            'payer',
            'effectiveStatus',
            'payerStatus',
            'submittedDate',
            'effectiveDate',
            'ignoreEnrollment',
            'disableBilling',
            'assignee',
          ]}
          header={<h5 className="mb-0">Insurance Enrollments</h5>}
        />
      </TableCard>
      <TableCard>
        <AddressEnrollmentTable
          fixedFilters={fixedFilters}
          columns={[
            'provider',
            'payer',
            'name',
            'address',
            'effectiveStatus',
            'payerStatus',
            'submissionType',
            'submittedDate',
            'effectiveDate',
            'archivedDate',
            'w9',
            'disableBilling',
            'assignee',
            'actions',
          ]}
          header={<h5 className="mb-0">Address Enrollments</h5>}
        />
      </TableCard>
    </div>
  )
}

// TODO - abstract this if it becomes a common pattern
function TableCard({ children, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledCard className="flex-remaining-space" {...props}>
      <CardBody className="full-height flex-column">{children}</CardBody>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  min-height: 400px;
`
