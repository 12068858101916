import { useMemo } from 'react'

import { useProviderDataDenormalizedDistinctValuesQuery } from '@nuna/api'
import { TextField } from '@nuna/tunic'

import { ValueMappingTable } from './ValueMappingTable'

interface ValueMapping {
  key: string
  value: string
}

interface EditValueMappingProps {
  columnKey: string
  valueMapping: Record<string, string>
  onChange: (valueMapping: Record<string, string>) => void
}

export function EditValueMapping({ columnKey, valueMapping, onChange }: EditValueMappingProps) {
  const { data } = useProviderDataDenormalizedDistinctValuesQuery({ variables: { columnKey } })

  const valueMappings = useMemo<ValueMapping[]>(() => {
    if (!data?.providerDataDenormalizedDistinctValues) return []
    const mappings: ValueMapping[] = [
      { key: '', value: '' },
      ...data.providerDataDenormalizedDistinctValues.map(val => ({ key: val, value: '' })),
    ]

    Object.entries(valueMapping).forEach(([key, value]) => {
      const mapping = mappings.find(mapping => mapping.key === key)
      if (mapping) {
        mapping.value = value
      }
    })

    return mappings
  }, [data, valueMapping])

  const updateValue = (key: string, value: string) => {
    const newValueMapping: Record<string, string> = valueMappings.reduce((acc, mapping) => {
      acc[mapping.key] = mapping.value
      return acc
    }, {} as Record<string, string>)

    newValueMapping[key] = value
    onChange(newValueMapping)
  }

  if (!data) return null

  return (
    <ValueMappingTable>
      <tbody>
        {valueMappings.map(({ key, value }) => (
          <tr key={key}>
            <td>{key || <span className="italic">empty</span>}</td>
            <td>
              <TextField placeholder="Value" value={value as string} onChange={e => updateValue(key, e.target.value)} />
            </td>
          </tr>
        ))}
      </tbody>
    </ValueMappingTable>
  )
}
