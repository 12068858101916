import { styled } from '@mui/material'
import {
  CellEditingStoppedEvent,
  ColDef,
  Column,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideDatasource,
  ProcessCellForExportParams,
  RowNode,
  StatusPanelDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { isNil, sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import {
  BasicProviderDefaultCompensationFragment,
  LicenseCompensationTier,
  ProviderDefaultCompensationInput,
  useCptCodesQuery,
  useProviderDefaultCompensationsLazyQuery,
} from '@nuna/api'
import { addressService, numberService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import { Skeleton, eggshell, error, salmonSet, yellowSet } from '@nuna/tunic'

import { LastSavedPanel } from '../../provider-compensation/ProviderCompensationTable/LastSavedPanel'
import { useProviderDefaultCompensationTableContext } from './ProviderDefaultCompensationTableContextProvider'

const { USStates } = addressService

interface Props {
  tier: LicenseCompensationTier
}

interface StateCompensation {
  state: {
    name: string
    code: string
  }
  compensations: BasicProviderDefaultCompensationFragment[]
}

export function ProviderDefaultCompensationTable({ tier }: Props) {
  const gridRef = useRef<AgGridReact>(null)
  const initialRendered = useRef(false)
  const { data: cptCodeData } = useCptCodesQuery({ fetchPolicy: 'cache-first' })
  const { queueCompensation, hasError, removeError } = useProviderDefaultCompensationTableContext()
  const { adminFinancials } = useFeatureFlags()
  const [queryProviderDefaultCompensations] = useProviderDefaultCompensationsLazyQuery({
    variables: { searchOptions: { tier } },
  })

  const cptCodes = cptCodeData?.cptCodes

  useEffect(() => {
    if (initialRendered.current) {
      gridRef.current?.api.refreshServerSideStore({ purge: true })
    }
  }, [tier])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 80,
    }
  }, [])

  const columnDefs = useMemo<ColDef[]>(() => {
    const columns: ColDef[] = [
      {
        field: 'state',
        headerName: 'State',
        cellRenderer: (props: ICellRendererParams) => (
          <>
            {props.data.state.name} ({props.data.state.code})
          </>
        ),
      },
    ]

    if (cptCodes) {
      sortBy(cptCodes, code => code.code).forEach(cptCode => {
        columns.push({
          field: cptCode.id,
          headerName: cptCode.code,
          headerTooltip: cptCode.shortDescription || '',
          type: 'rightAligned',
          editable: adminFinancials,
          cellClassRules: {
            'error-cell': params => {
              const comp: BasicProviderDefaultCompensationFragment = params.data
              return hasError({ state: comp.state, cptCodeId: comp.cptCodeId, tier: comp.tier })
            },
          },
          valueGetter: params => {
            const row = params.data as StateCompensation
            const cptCodeId = params.column.getColId()
            const compensation = row.compensations.find(comp => comp.cptCodeId === cptCodeId)

            if (compensation) {
              return numberService.centsToDollars(compensation.rate)
            }
          },
          valueFormatter: currencyFormatter,
        })
      })
    }
    return columns
  }, [cptCodes, adminFinancials, hasError])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: async params2 => {
          const result = await queryProviderDefaultCompensations()
          initialRendered.current = true
          if (cptCodes && result.data?.providerDefaultCompensations) {
            //Transform the set to include a placeholder for every state and cpt code
            const compensations = Object.entries(USStates).map(([code, name]) => ({
              state: { code, name },
              compensations: cptCodes?.map(cptCode => {
                const existingComp = result.data?.providerDefaultCompensations.find(
                  c => c.state === code && c.cptCodeId === cptCode.id,
                )
                if (existingComp) return existingComp
                return {
                  state: code,
                  cptCodeId: cptCode.id,
                  tier,
                  rate: 0,
                }
              }),
            }))
            params2.success({
              rowData: compensations,
            })
          }
        },
      }
      params.api.setServerSideDatasource(dataSource)
    },
    [queryProviderDefaultCompensations, cptCodes, tier],
  )

  const setRate = useCallback(
    (column: Column, node: RowNode | null | undefined, value: number) => {
      if (!node) {
        return
      }
      const data = node.data as StateCompensation
      const state = data.state.code
      const cptCodeId = column.getColId()
      const compIdx = data.compensations.findIndex(c => c.cptCodeId === cptCodeId && c.state === state)
      const providerDefaultComp: ProviderDefaultCompensationInput = {
        state,
        cptCodeId,
        tier,
        rate: numberService.dollarsToCents(value),
        ...(compIdx > -1 && { id: data.compensations[compIdx]?.id }),
      }

      const currentComps: (BasicProviderDefaultCompensationFragment | ProviderDefaultCompensationInput)[] = [
        ...data.compensations,
      ]

      if (compIdx > -1) {
        currentComps.splice(compIdx, 1, providerDefaultComp)
      }

      node.setData({ ...data, compensations: currentComps })
      removeError(providerDefaultComp)
      queueCompensation(providerDefaultComp)
    },
    [queueCompensation, removeError, tier],
  )

  const processCellFromClipboard = useCallback(
    (params: ProcessCellForExportParams) => {
      setRate(params.column, params.node, params.value)
    },
    [setRate],
  )

  const handleCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      setRate(event.column, event.node, event.newValue)
    },
    [setRate],
  )

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[]
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: LastSavedPanel,
        },
      ],
    }
  }, [])

  return (
    <GridContainer className="ag-theme-material" style={{ height: '100%', width: '100%' }}>
      {cptCodes ? (
        <AgGridReact
          ref={gridRef}
          enableRangeSelection
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowModelType="serverSide"
          onGridReady={onGridReady}
          columnHoverHighlight
          onCellEditingStopped={handleCellEditingStopped}
          getRowStyle={params => {
            const { node } = params
            if (!node.highlighted && (node.rowIndex ?? 0) % 2 === 0) {
              return { background: eggshell }
            }
          }}
          statusBar={statusBar}
          processCellFromClipboard={processCellFromClipboard}
        ></AgGridReact>
      ) : (
        <Skeleton height={10} style={{ width: '100%' }} />
      )}
    </GridContainer>
  )
}

function currencyFormatter(params: ValueFormatterParams) {
  if (isNil(params.value)) {
    return ''
  }
  return numberService.formatCurrency(params.value)
}

const GridContainer = styled('div')`
  .ag-row-hover,
  .ag-column-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    background-color: ${yellowSet[15].hex} !important;
  }
  .error-cell {
    border: 1px solid ${error} !important;
    background-color: ${salmonSet[15].hex};
  }
`
