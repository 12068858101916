import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { flatten, pick } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import {
  InsurancePayerPlanQuery,
  InsurancePayersForNetworkQuery,
  useInsurancePayerPlanQuery,
  useInsurancePayersForNetworkQuery,
} from '@nuna/api'

import { useNetworkPlanDrawerSearchParams } from '../hooks/useNetworkPlanDrawerSearchParams'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<string | undefined | null, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface PlanOption {
  id: string
  name: string
  payerId: string
  payerName: string
}

interface Props extends LimitedAutocompleteProps {
  error?: boolean
  helperText?: string | ReactNode
  value?: string | null
  onChange: (value: string | null) => void
}

export function NetworkPlanSelect({ value, error, helperText, onChange }: Props) {
  const {
    drawerConfig: { networkId, payerId },
  } = useNetworkPlanDrawerSearchParams()

  const [searchTerm, setSearchTerm] = useState('')

  const { data, loading } = useInsurancePayersForNetworkQuery({
    variables: {
      filters: {
        insurancePayerNetworkId: networkId,
        search: searchTerm ? { term: searchTerm, threshold: 0.2 } : undefined,
      },
      pagination: { limit: 100 },
    },
    fetchPolicy: 'network-only',
    context: { debounceKey: 'network-plan-select-query', debounceTimeout: 300 },
  })

  // query the plan of the value because it may not be included in the paginated query
  const { data: planData, loading: planLoading } = useInsurancePayerPlanQuery({
    variables: { id: value ?? '' },
    skip: !value,
  })

  const options = useMemo(
    () => buildOptions(planData?.insurancePayerPlan, data?.insurancePayers?.items, payerId),
    [data, planData, payerId],
  )
  const valueAsOption = useMemo(() => options.find(o => o.id === value) ?? null, [options, value])

  return (
    <Autocomplete
      value={valueAsOption}
      options={options}
      loading={loading || planLoading}
      groupBy={option => (option as PlanOption).payerName}
      filterOptions={x => x}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_e, option) => onChange((option as PlanOption)?.id ?? null)}
      renderInput={params => (
        <TextField
          label="Select network plan"
          error={error}
          helperText={helperText}
          {...params}
          onChange={e => setSearchTerm(e.currentTarget.value)}
        />
      )}
    />
  )
}

function buildOptions(
  valuePlan?: InsurancePayerPlanQuery['insurancePayerPlan'],
  results?: InsurancePayersForNetworkQuery['insurancePayers']['items'],
  payerId?: string | null,
): PlanOption[] {
  if (!results) {
    return []
  }

  const plans = flatten(
    results
      .filter(payer => payer.id !== payerId && (payer.insurancePayerPlans ?? []).length > 0)
      .map(payer => {
        return (payer.insurancePayerPlans ?? []).map(plan => {
          return { ...pick(plan, ['id', 'name']), payerName: payer.name, payerId: payer.id }
        })
      }),
  )

  // if there is a plan found from directly querying the value plan and the options does not contain
  // the value plan, insert an option for the value plan at the beginning of the options array
  if (valuePlan && !plans.find(plan => plan.id === valuePlan.id)) {
    plans.unshift({
      ...pick(valuePlan, ['id', 'name']),
      payerName: valuePlan.insurancePayer.name,
      payerId: valuePlan.insurancePayer.id,
    })
  }

  return plans
}
