import { IconProps } from './IconProps'

export function IconBook({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (31 / 22) * size

  return (
    <svg width={size} height={height} viewBox="0 0 22 31" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.402 3.1867L7.6273 8.72957C7.05993 8.9698 6.36973 9.08698 5.65027 9.08698C4.66176 9.08698 3.61475 8.84675 2.8953 8.54793C2.62623 8.43074 2.41566 8.3077 2.26943 8.21981C2.32792 8.19051 2.38642 8.16122 2.45076 8.13192L14.1199 3.06365L15.8571 3.81364V1.65743C15.8571 1.32931 15.7051 1.03049 15.5003 0.848851C15.2605 0.667214 14.9622 0.608621 14.6931 0.731666L1.91848 6.27453C1.53243 6.42687 1.19903 6.63195 0.900716 6.90147C0.631653 7.171 0.362589 7.62216 0.391835 8.13192V8.16122V27.7019V27.7312C0.391835 27.7312 0.391835 27.7312 0.391835 27.7605V27.7898C0.421081 28.4812 0.748637 28.9265 1.08204 29.196C2.0998 30.0925 3.83702 30.6022 5.69122 30.6667C6.5569 30.6667 7.45768 30.5143 8.26487 30.1862L21.0395 24.6433C21.3671 24.491 21.6069 24.1336 21.6069 23.7469V4.17691C21.6011 3.78434 21.449 3.48552 21.2092 3.30388C20.9693 3.12225 20.671 3.06365 20.402 3.1867Z"
      />
    </svg>
  )
}
