import moment from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { DateTimeFilterValue } from '../../../../shared/filters'

export enum AppointmentTableFilterKey {
  Provider = 'providerId',
  CurrentStatus = 'currentStatus',
  HasSessionNote = 'hasSessionNote',
  StartDatetime = 'startDatetime',
}

export enum AppointmentTableFilterPresetKey {
  Today = 'today',
}

export type AppointmentTablePresetFilterValueMap = Partial<{
  [AppointmentTableFilterKey.StartDatetime]: DateTimeFilterValue
}>

export function useAppointmentTableFilterPresets(): AppointmentTablePresetFilterValueMap {
  const location = useLocation()
  return useMemo<AppointmentTablePresetFilterValueMap>(() => {
    const path = location.pathname.split('/').pop()
    switch (path) {
      case AppointmentTableFilterPresetKey.Today:
        return {
          [AppointmentTableFilterKey.StartDatetime]: {
            op: 'eq',
            v: moment().format('YYYY-MM-DD'),
          } as DateTimeFilterValue,
        }
      default:
        return {}
    }
  }, [location])
}
