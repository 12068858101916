import { ReactNode, useState } from 'react'
import { ColumnInstance } from 'react-table'

import { DiagnosisFragment } from '@nuna/api'
import { DataTableRowComponentProps } from '@nuna/data-table'
import { IconButton, IconPencil, csx } from '@nuna/tunic'

import { DiagnosisForm } from './DiagnosisForm'

interface CustomColumn extends ColumnInstance<DiagnosisFragment> {
  className?: string
}

export function DiagnosisRow({ row }: DataTableRowComponentProps<DiagnosisFragment>) {
  const [isEditMode, setIsEditMode] = useState(false)

  const cells = row.cells.filter(cell => cell.column.id !== 'id')

  if (!isEditMode) {
    const { key, ...rowProps } = row.getRowProps()
    return (
      <tr key={key} {...rowProps}>
        {cells.map(cell => {
          const { key, ...spreadRowProps } = cell.getCellProps({
            style: cell.column.width ? { width: `${cell.column.width}px` } : {},
          })

          return (
            <td key={key} {...spreadRowProps} className={csx([(cell.column as CustomColumn).className as string])}>
              {cell.render('Cell') as ReactNode}
            </td>
          )
        })}

        <td className="right-align">
          <IconButton tooltip="Edit" onClick={() => setIsEditMode(true)}>
            <IconPencil />
          </IconButton>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td colSpan={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <DiagnosisForm
          diagnosis={row.original}
          onCancel={() => setIsEditMode(false)}
          onSave={() => setIsEditMode(false)}
        />
      </td>
    </tr>
  )
}
