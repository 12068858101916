import { DialogActions, DialogContentText, DialogTitle } from '@mui/material'

import { Dialog, DialogContent, FillButton, GhostButton } from '@nuna/tunic'

interface Props {
  isOpen: boolean
  onClose: (shouldRemove: boolean) => void
}

export function HubspotContactRemove({ isOpen, onClose }: Props) {
  const handleCancel = () => {
    onClose(false)
  }

  const handleRemove = () => {
    onClose(true)
  }

  return (
    <Dialog isOpen={isOpen} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>{'Remove linked Hubspot contact?'}</DialogTitle>
      <DialogContent paddingSize="md">
        <DialogContentText>
          This will unlink the provider's Hubspot contact. You can always re-link a Hubspot contact id to the provider.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={handleCancel}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={handleRemove}>
          Ok
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
