import { styled } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Logo, greySet } from '@nuna/tunic'

import { NotificationCenter } from '../NotificationCenter/NotificationCenter'
import { SidebarNavigationItems } from './SidebarNavigationItems'

const Container = styled('div')`
  height: 100%;
  background-color: ${greySet[0].hex};
  border-right: 1px solid ${greySet[15].hex};
  padding: 1.75rem 0.75rem 0.75rem;
  width: 192px;
`

export function Sidebar() {
  const [navigationCenterOpen, setNavigationCenterOpen] = useState(false)
  return (
    <>
      <Container className="flex-column">
        <LogoLink className="mb-3" to="/">
          <Logo height={34} color="plum" />
        </LogoLink>
        <SidebarNavigationItems
          setNotificationCenterOpen={setNavigationCenterOpen}
          style={{ flex: '1 0 0', overflow: 'auto' }}
        />
      </Container>
      <NotificationCenter isOpen={navigationCenterOpen} onClose={() => setNavigationCenterOpen(false)} />
    </>
  )
}

const LogoLink = styled(NavLink)`
  display: block;
  padding-left: 0.5rem;
`
