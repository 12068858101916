import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Skeleton, borderGrey, csx, makeTypographyComponent } from '@nuna/tunic'

export const DetailLayout = styled('div')`
  display: flex;
`

export const DetailColumn = styled('aside')`
  height: 100%;
  padding: 1.5rem;
  width: 264px;
  border-right: 1px solid ${borderGrey};
  transition: width 0.3s;

  &.wide {
    width: 480px;
  }
`

export const DetailDatumLabel = makeTypographyComponent('body caption text-medium text-medium-grey', 'div')
// export const DetailDatumValue = makeTypographyComponent('mb-2', 'div')

export function DetailDatumValue({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={csx([className, 'mb-2'])} {...props}>
      {children ? children : '—'}
    </div>
  )
}

export const DetailMainView = styled('main')`
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const DetailContentNav = styled('nav')`
  display: flex;
  align-items: center;
  &.bottom-buffer {
    margin-bottom: 22px;
  }
`

export const DetailContent = styled('div')`
  overflow-y: auto;
  flex: 1 0 0;
  display: flex;
`

export function DetailColumnLoader() {
  return (
    <div>
      <Skeleton className="mb-2" style={{ width: '100px' }} height={1.4} />
      <Skeleton className="mb-2" style={{ width: '100px' }} height={1.4} />
      <Skeleton className="mb-2" style={{ width: '100px' }} height={1.4} />
    </div>
  )
}
