import { HTMLAttributes, useMemo } from 'react'

import { HubspotContact } from '@nuna/api'
import { DataTable, DataTableColumn } from '@nuna/data-table'

interface Props {
  onContactSelect: (provider: HubspotContact) => void
  selectedContact: HubspotContact | null
  searchResults: HubspotContact[]
  loading: boolean
}

export function HubspotContactSearchResults({
  searchResults,
  onContactSelect,
  selectedContact,
  loading,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const rowData = useMemo<HubspotContact[]>(() => searchResults, [searchResults])

  const columns = useMemo<DataTableColumn<HubspotContact>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'lastname',
        Cell: ({ row, value }) => (
          <>
            {row.original?.firstname} {value}
          </>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'ID',
        accessor: 'hs_object_id',
      },
    ],
    [],
  )
  return (
    <div {...props}>
      <DataTable
        columns={columns}
        rowData={rowData}
        loading={loading}
        noRecordsMessage="No contacts found"
        loadingRows={3}
        isRowSelected={data => data.hs_object_id === selectedContact?.hs_object_id}
        onRowClick={onContactSelect}
      />
    </div>
  )
}
