import { ProviderEnrollmentStatus } from '@nuna/api'

export function useGetAvailableEnrollmentStatuses(currentStatus?: ProviderEnrollmentStatus | null) {
  if (!currentStatus) {
    return []
  }

  switch (currentStatus) {
    case ProviderEnrollmentStatus.Pending:
      return [
        ProviderEnrollmentStatus.Submitted,
        ProviderEnrollmentStatus.RequestedProviderSignature,
        ProviderEnrollmentStatus.BlockedByPayer,
        ProviderEnrollmentStatus.OptedOut,
      ]
    case ProviderEnrollmentStatus.RequestedProviderSignature:
      return [
        ProviderEnrollmentStatus.Pending,
        ProviderEnrollmentStatus.Submitted,
        ProviderEnrollmentStatus.BlockedByPayer,
        ProviderEnrollmentStatus.OptedOut,
      ]
    case ProviderEnrollmentStatus.BlockedByPayer:
      return [
        ProviderEnrollmentStatus.Pending,
        ProviderEnrollmentStatus.Submitted,
        ProviderEnrollmentStatus.RequestedProviderSignature,
        ProviderEnrollmentStatus.OptedOut,
      ]
    case ProviderEnrollmentStatus.OptedOut:
      return [
        ProviderEnrollmentStatus.Pending,
        ProviderEnrollmentStatus.Submitted,
        ProviderEnrollmentStatus.RequestedProviderSignature,
        ProviderEnrollmentStatus.BlockedByPayer,
      ]
    case ProviderEnrollmentStatus.Submitted:
      return [
        ProviderEnrollmentStatus.Pending,
        ProviderEnrollmentStatus.Enrolled,
        ProviderEnrollmentStatus.Denied,
        ProviderEnrollmentStatus.OptedOut,
      ]
    case ProviderEnrollmentStatus.Enrolled:
      return [ProviderEnrollmentStatus.Submitted, ProviderEnrollmentStatus.Archived, ProviderEnrollmentStatus.OptedOut]
    case ProviderEnrollmentStatus.Denied:
      return [
        ProviderEnrollmentStatus.Pending,
        ProviderEnrollmentStatus.Submitted,
        ProviderEnrollmentStatus.Archived,
        ProviderEnrollmentStatus.OptedOut,
      ]
    default:
      return []
  }
}
