import { isNil } from 'lodash'
import { useState } from 'react'

import { useSearchProvidersDataDenormalizedQuery } from '@nuna/api'

export function useRosterValidation(payerRosterDefinitionId?: string | null) {
  const [refetchLoading, setRefetchLoading] = useState(false)
  const { data, loading, refetch } = useSearchProvidersDataDenormalizedQuery({
    variables: {
      filters: {
        isValid: false,
        currentPayerRosterDefinitionId: payerRosterDefinitionId ?? undefined,
        defaultTaxEntity: true,
      },
      pagination: { limit: 1 },
    },
  })

  const totalCount = data?.searchProvidersDataDenormalized.pagination?.totalCount

  const revalidate = async () => {
    setRefetchLoading(true)
    await refetch()
    setRefetchLoading(false)
  }

  return { loading: loading || refetchLoading, isValid: !isNil(totalCount) ? totalCount === 0 : undefined, revalidate }
}
