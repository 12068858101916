import moment from 'moment'
import { HTMLAttributes } from 'react'

import { Maybe, ProviderStatus, Role } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { providerService } from '@nuna/core'
import { StatusLabel } from '@nuna/tunic'

import { ProviderStatusIntentColorMap } from '../utils/util'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  status?: Maybe<ProviderStatus>
  endDate?: string | null
}

export function ProviderStatusLabel({ status, endDate, ...props }: Props) {
  const { login } = useAuthDataContext()

  const notAdmin = !login?.role ? true : login.role !== Role.Admin

  if (!status) {
    return null
  }

  const isOffboarding = endDate ? moment() < moment(endDate) : false

  const statusText = isOffboarding ? 'Offboarding' : providerService.readableProviderStatus(status)

  // Do not show the status if the user is not an admin and the status is not
  // Offboarding or Onboarding
  if (!['Offboarding', 'Onboarding'].includes(statusText as string) && notAdmin) {
    return null
  }

  const intent = isOffboarding ? 'urgent' : ProviderStatusIntentColorMap[status]

  return (
    <StatusLabel {...props} intent={intent}>
      {statusText}
    </StatusLabel>
  )
}
