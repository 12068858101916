import { IconCategory, NavMenuButton, Tooltip } from '@nuna/tunic'

type Props = {
  tooltip?: string
  handleClick: () => void
}
export const PacketBuilderButton = ({ tooltip = 'Open Packet Builder', handleClick }: Props) => {
  return (
    <Tooltip content={tooltip}>
      <NavMenuButton>
        <IconCategory onClick={handleClick} />
      </NavMenuButton>
    </Tooltip>
  )
}
