import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { Form, Formik } from 'formik'
import { noop } from 'lodash'
import moment, { Moment } from 'moment'
import { HTMLAttributes, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  FullPayerContractFragment,
  GeneratePlanContractMutation,
  PayerContractInput,
  SavePlanContractMutation,
  useGeneratePlanContractMutation,
  useSavePlanContractMutation,
} from '@nuna/api'
import { formService } from '@nuna/core'
import { GreySection } from '@nuna/provider'
import { DatePicker, FillButton, OutlineButton, csx, toast } from '@nuna/tunic'

import { planContract } from '../../../util/routes'
import { transformPayerContractAmounts } from './plan-contract-utils'

const { composeHelperText } = formService

interface Props {
  insurancePayerPlanId: string
  payerId: string
  initialStartDate?: Moment | null
  contract?: FullPayerContractFragment
  showCancelButton?: boolean
  onCancel?: () => void
  afterSave?: () => void
}

type FormValues = Partial<PayerContractInput>

const formSchema = Yup.object().shape<FormValues>({
  startDate: Yup.date().typeError('Invalid date').required('Start date is required'),
  endDate: Yup.date()
    .typeError('Invalid date')
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must come after Start date'),
  insurancePayerPlanId: Yup.string().required(),
})

export function PlanContractForm({
  insurancePayerPlanId,
  payerId,
  initialStartDate,
  onCancel = noop,
  afterSave = noop,
  showCancelButton = false,
  contract,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate()
  const [generateContract, { loading }] = useGeneratePlanContractMutation({ refetchQueries: ['PlanContracts'] })
  const [saveContract, { loading: upsertLoading }] = useSavePlanContractMutation({
    refetchQueries: ['PlanContracts'],
  })

  const initialValues = useMemo<FormValues>(() => {
    const startDate = contract
      ? moment(contract.startDate)
      : initialStartDate
      ? initialStartDate
      : moment().startOf('day')

    const endDate = contract ? moment(contract.endDate) : startDate.clone().add(1, 'years')

    return {
      insurancePayerPlanId,
      startDate,
      endDate,
    }
  }, [insurancePayerPlanId, contract, initialStartDate])

  const submit = async (values: FormValues) => {
    try {
      const result = contract
        ? await saveContract({
            variables: {
              payerContract: {
                id: contract?.id ?? '',
                insurancePayerPlanId,
                startDate: values.startDate,
                endDate: values.endDate,
                contractAmounts: transformPayerContractAmounts(contract?.contractAmounts ?? []),
              },
            },
          })
        : await generateContract({
            variables: { planId: insurancePayerPlanId, startDate: values.startDate, endDate: values.endDate },
          })

      const id = contract
        ? (result.data as SavePlanContractMutation | null)?.savePayerContract.id
        : (result.data as GeneratePlanContractMutation)?.generatePayerContract.id

      navigate(planContract(insurancePayerPlanId, payerId, id ?? ''))
      afterSave()
    } catch (error) {
      let errorMessage = 'Failed to save contract'
      if (error instanceof ApolloError) {
        errorMessage = error.graphQLErrors[0] ? error.graphQLErrors[0].message : errorMessage
      }
      toast.urgent(errorMessage)
    }
  }
  return (
    <GreySection style={{ width: '100%' }} {...props}>
      <h2 className="h5">{contract ? 'Edit contract' : 'Add a contract'}</h2>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={submit}>
        {({ values, setFieldValue, errors, touched, handleBlur }) => (
          <Form className="v-align">
            <div>
              <DatePicker
                name="startDate"
                label="Start date"
                value={values.startDate}
                error={touched.startDate && !!errors.startDate}
                helperText={composeHelperText('Start date of contract', errors.startDate, !!touched.startDate)}
                onChange={date => setFieldValue('startDate', date, true)}
              />
            </div>
            <div className="ml-3">
              <DatePicker
                name="endDate"
                label="End date"
                value={values.endDate}
                error={touched.endDate && !!errors.endDate}
                helperText={composeHelperText('End date of contract', errors.endDate, !!touched.endDate)}
                onBlur={handleBlur}
                onChange={date => setFieldValue('endDate', date, true)}
              />
            </div>
            {showCancelButton && (
              <OutlineButton className="ml-3" onClick={onCancel}>
                Cancel
              </OutlineButton>
            )}
            <FillButton
              isLoading={loading || upsertLoading}
              className={csx({ 'ml-2': showCancelButton, 'ml-3': !showCancelButton })}
              type="submit"
            >
              Save
            </FillButton>
          </Form>
        )}
      </Formik>
    </GreySection>
  )
}
