import { useAdminAvailabilityConstraintsQuery } from '@nuna/api'
import { GreySection, ManageAvailability } from '@nuna/provider'
import { error as errorColor } from '@nuna/tunic'

interface ProviderAvailabilityProps {
  providerId: string
}

export function AdminProviderAvailability({ providerId }: ProviderAvailabilityProps) {
  const { data, error } = useAdminAvailabilityConstraintsQuery({
    variables: {
      providerId,
    },
  })

  if (!data) {
    return <div className="loading" style={{ height: '300px', maxWidth: '34rem' }} />
  }

  if (error) {
    return <p style={{ color: errorColor }}>{error.message}</p>
  }

  const provider = data.provider

  if (!provider) {
    return <p style={{ color: errorColor }}>Provider not found</p>
  }

  return (
    <GreySection>
      <ManageAvailability provider={provider} refetchQueries={['AdminAvailabilityConstraints']} />
    </GreySection>
  )
}
