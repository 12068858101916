import { styled } from '@mui/material'
import { FocusEvent, MouseEventHandler, useRef, useState } from 'react'

import { IconClose, IconSearch } from '../../icons'
import { body2, interactiveFill } from '../../styles/colors'
import { IconButton } from '../Button'
import { TextField, TextFieldProps } from '../TextField/TextField'

export type SearchFieldProps = Omit<TextFieldProps, 'multiline'> & {
  onClear?: MouseEventHandler<HTMLButtonElement>
}

export function SearchField({ style, className, onClear, ...props }: SearchFieldProps) {
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    props.onFocus && props.onFocus(e)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    props.onBlur && props.onBlur(e)
  }

  const handleIconClick = () => {
    ref.current?.focus()
  }

  return (
    <Container style={style} className={`v-align ${className}`}>
      <IconSearch
        className="mr-1"
        color={isFocused ? interactiveFill : body2}
        onClick={handleIconClick}
        style={{ cursor: 'pointer' }}
      />
      <TextField
        $hideUnderline
        {...props}
        className="search-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        $inputRef={ref}
      />
      {props.value && onClear && (
        <IconButton tooltip="Clear search" variant="secondary" customSize={32} onClick={onClear}>
          <IconClose size={16} />
        </IconButton>
      )}
    </Container>
  )
}

const Container = styled('div')`
  position: relative;

  button {
    position: absolute;
    right: -0.5rem;
  }

  svg {
    margin-bottom: 2px;
  }

  > div > div {
    padding-top: 0;
  }
`
