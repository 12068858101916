import { HTMLAttributes, useEffect, useMemo } from 'react'

import { InsurancePolicySearchOptions, useSearchInsurancePolicyListQuery } from '@nuna/api'
import { DataTable, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { csx } from '@nuna/tunic'

import { InsurancePolicyTableColumn } from './InsurancePolicyTable.types'
import { InsurancePolicyTableToolbar } from './components/InsurancePolicyTableToolbar'
import { useInsurancePolicyTableColums } from './hooks/useInsurancePolicyTableColumns'
import { useInsurancePolicyTableRowSelection } from './hooks/useInsurancePolicyTableRowSelection'

const ITEM_LIMIT = 100

interface Props extends HTMLAttributes<HTMLDivElement> {
  fixedFilters?: InsurancePolicySearchOptions
  columns: InsurancePolicyTableColumn[]
}

export function InsurancePolicyTable({ fixedFilters = {}, columns: columnsInput, className, ...props }: Props) {
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(fixedFilters)
  const { queryOptions, getPaginatorProps, initialTableState, setPagination, setLoading } = usePagination(
    {
      loading: true,
      filters: filterValues,
    },
    { limit: ITEM_LIMIT },
  )
  const { data, loading } = useSearchInsurancePolicyListQuery({
    variables: { filters: filterValues, pagination: queryOptions.paginationInput },
    fetchPolicy: 'cache-first',
    context: { debounceKey: 'insurancePolicyTableSearch', debounceTimeout: 200 },
  })

  useEffect(
    () => setPagination(data?.searchInsurancePolicies.pagination),
    [data?.searchInsurancePolicies.pagination, setPagination],
  )
  useEffect(() => setLoading(loading), [loading, setLoading])

  const idsInView = useMemo(() => data?.searchInsurancePolicies.items.map(item => item.id) ?? [], [data])
  const stringifiedFilters = JSON.stringify(filterValues)

  const { toggleSelect, toggleSelectAll, clearAllSelections, getSelectionsForPage, getAllSelections } =
    useInsurancePolicyTableRowSelection({
      currentPage: getPaginatorProps().page,
      idsInView,
      limit: ITEM_LIMIT,
    })

  useEffect(() => clearAllSelections(), [clearAllSelections, stringifiedFilters])

  const columns = useInsurancePolicyTableColums({
    columns: columnsInput,
    limit: getPaginatorProps().itemCount ?? ITEM_LIMIT,
    selectedIds: getSelectionsForPage(data?.searchInsurancePolicies.pagination?.page),
    onSelect: toggleSelect,
    onToggleSelectAll: toggleSelectAll,
  })

  const rowData = useMemo(() => data?.searchInsurancePolicies.items, [data])

  return (
    <div className={csx([className, 'full-width flex-column overflow-hidden'])} {...props}>
      <InsurancePolicyTableToolbar
        filterValues={filterValues}
        fixedFilters={fixedFilters}
        setFilterVal={setFilterVal}
        clearFilters={clearFilters}
        selectedIds={getAllSelections()}
      />
      <DataTable
        className="full-width background-white"
        style={{ zIndex: 1 }}
        loading={loading}
        rowData={rowData}
        columns={columns}
        initialState={initialTableState}
        paginated
        paginatorProps={getPaginatorProps()}
        scrollStrategy="within"
      />
    </div>
  )
}
