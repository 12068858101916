import { Filter } from '../../filters.types'
import { FilterAutocompleteValueSelector } from './components/FilterAutocompleteValueSelector'
import { FilterBooleanValueSelector } from './components/FilterBooleanValueSelector'
import { FilterDateValueSelector } from './components/FilterDateValueSelector'

interface FilterValueSelectorProps {
  filter: Filter
  onChange: (value: unknown) => void
  initial?: boolean
}

export function FilterValueSelector({ filter, initial = true, onChange }: FilterValueSelectorProps) {
  switch (filter.dataType) {
    case 'boolean':
      return <FilterBooleanValueSelector filter={filter} initial={initial} onChange={onChange} />
    case 'datetime':
      return <FilterDateValueSelector filter={filter} onChange={onChange} />
    default:
      return <FilterAutocompleteValueSelector filter={filter} onChange={onChange} />
  }
}
