import { IconProps } from './IconProps'

export function IconPayment({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4825 29.6465V12.1833C40.4825 9.78215 38.5179 7.81754 36.1167 7.81754H5.55611C3.15492 7.81754 1.19031 9.78215 1.19031 12.1833V29.6465C1.19031 32.0477 3.15492 34.0123 5.55611 34.0123H36.1167C38.5179 34.0123 40.4825 32.0477 40.4825 29.6465ZM36.1167 28.5987C36.1167 29.1774 35.6476 29.6465 35.0689 29.6465H6.6039C6.02522 29.6465 5.55611 29.1774 5.55611 28.5987V13.2311C5.55611 12.6524 6.02522 12.1833 6.6039 12.1833H35.0689C35.6476 12.1833 36.1167 12.6524 36.1167 13.2311V28.5987ZM20.8364 14.3662C17.2128 14.3662 14.2877 17.2913 14.2877 20.9149C14.2877 24.5385 17.2128 27.4636 20.8364 27.4636C24.46 27.4636 27.3851 24.5385 27.3851 20.9149C27.3851 17.2913 24.46 14.3662 20.8364 14.3662ZM45.0229 12.2707C46.1803 12.2707 47.1185 13.2089 47.1185 14.3662V36.2825C47.1185 38.6837 45.1539 40.6483 42.7527 40.6483H7.73901C6.58165 40.6483 5.64342 39.7101 5.64342 38.5528C5.64342 38.0327 5.64342 37.4874 5.64342 36.2825H41.7049C42.2836 36.2825 42.7527 35.8134 42.7527 35.2348V12.2707C43.9575 12.2707 44.5029 12.2707 45.0229 12.2707Z"
        fill={color}
      />
    </svg>
  )
}
