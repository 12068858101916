import { styled } from '@mui/material'
import { camelCase, snakeCase, startCase } from 'lodash'
import { ChangeEvent } from 'react'

import {
  TavaLoginFragment,
  useAddAdminNotificationUserMutation,
  useAddAdminNotificationUserToGroupMutation,
  useAdminNotificationGroupsQuery,
  useAdminNotificationUserQuery,
  useRemoveAdminNotificationUserFromGroupMutation,
  useRemoveAdminNotificationUserMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { Drawer, DrawerProps, Switch, greySet, toast } from '@nuna/tunic'

interface Props extends DrawerProps {
  user?: TavaLoginFragment
}

export function AdminSettingsDrawer({ user, isOpen, onClose, ...props }: Props) {
  const [addAdminNotificationUser, { loading: addingUser }] = useAddAdminNotificationUserMutation()
  const [removeAdminNotificationUser, { loading: removingUser }] = useRemoveAdminNotificationUserMutation()
  const [addAdminNotificationUserToGroup, { loading: addingGroup }] = useAddAdminNotificationUserToGroupMutation()
  const [removeAdminNotificationUserFromGroup, { loading: removingGroup }] =
    useRemoveAdminNotificationUserFromGroupMutation()
  const loading = addingUser || removingUser || addingGroup || removingGroup

  const { data: userData, refetch: refetchUser } = useAdminNotificationUserQuery({
    variables: {
      userId: user?.id ?? '',
    },
    skip: !isOpen || !user?.id,
  })

  const { data: groupsData } = useAdminNotificationGroupsQuery({
    variables: {},
    skip: !isOpen || !user?.id,
  })

  const toggleAdminNotificationsUser = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!user?.id) return

    try {
      if (event.target.checked) {
        await addAdminNotificationUser({
          variables: {
            input: {
              userId: user.id,
              email: user.email,
              name: `${user.firstName} ${user.lastName}`,
            },
          },
        })
      } else {
        await removeAdminNotificationUser({
          variables: {
            userId: user.id,
          },
        })
      }

      refetchUser()
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(
          e,
          `There was an error ${event.target.checked ? 'enabling' : 'disabling'} In App Notifications`,
        ),
      )
    }
  }

  const toggleAdminNotificationsGroup = async (event: ChangeEvent<HTMLInputElement>, group: string) => {
    if (!user?.id || !group) return

    try {
      if (event.target.checked) {
        await addAdminNotificationUserToGroup({
          variables: {
            userId: user.id,
            group,
          },
        })
      } else {
        await removeAdminNotificationUserFromGroup({
          variables: {
            userId: user.id,
            group,
          },
        })
      }

      refetchUser()
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(
          e,
          `There was an error ${event.target.checked ? 'subscribing' : 'unsubscribing'} to ${startCase(
            camelCase(group),
          )} events`,
        ),
      )
    }
  }

  const groups = groupsData?.adminNotificationGroups ?? []

  return (
    <Drawer isOpen={isOpen} onClose={() => onClose()} {...props} size="600px">
      <DrawerHeader className="v-align">
        <h2 className="h5 mb-2">
          Settings for {user?.firstName} {user?.lastName}
        </h2>
      </DrawerHeader>
      <DrawerContent>
        <Switch
          onChange={toggleAdminNotificationsUser}
          checked={!!userData?.adminNotificationUser?.id}
          disabled={loading}
        >
          Enable In App Notifications
        </Switch>
        <br />

        {userData?.adminNotificationUser?.id && (
          <div className="ml-2 mt-1">
            {groups.map(group => {
              return (
                <Switch
                  className="mb-2"
                  onChange={event => toggleAdminNotificationsGroup(event, snakeCase(group.collection).toUpperCase())}
                  checked={!!userData?.adminNotificationUser?.groups.find(userGroup => userGroup.id === group.id)}
                  disabled={loading}
                >
                  Subscribe to&nbsp;<b>{group.properties.name}</b>&nbsp;events
                </Switch>
              )
            })}
          </div>
        )}
      </DrawerContent>
    </Drawer>
  )
}

const DrawerHeader = styled('div')`
  padding: 24px;
  border-bottom: 1px solid ${greySet[15].hex};
`

const DrawerContent = styled('div')`
  padding: 24px;
  overflow-y: scroll;
`
