import { Route, Routes, useParams } from 'react-router-dom'

import { useIsAdmin } from '@nuna/auth'
import { CustomerDetail } from '@nuna/customer'
import { OutlineButtonLink } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { newHrAdminUser } from '../../util/routes'
import { EAPsListNavigation } from '../partner/EAPsListNavigation'
import { UpdateCustomer } from './UpdateCustomer'
import { EditCustomerContract } from './customer-contract/EditCustomerContract'
import { NewCustomerContract } from './customer-contract/NewCustomerContract'

export function AdminCustomerDetail() {
  const { id } = useParams<{ id: string }>()
  const isAdmin = useIsAdmin()

  return (
    <NavLayout>
      <EAPsListNavigation>
        <CustomerDetail
          NewCustomerContract={<NewCustomerContract />}
          EditCustomerContract={<EditCustomerContract />}
          UpdateCompany={<UpdateCustomer />}
        >
          <Routes>
            {isAdmin && (
              <>
                <Route
                  path="/overview"
                  element={<OutlineButtonLink to={`/customers/${id}/update`}>Update Customer</OutlineButtonLink>}
                />
                <Route path="contracts" element={<OutlineButtonLink to="new">New Contract</OutlineButtonLink>} />
                <Route
                  path="contracts/:contractId"
                  element={<OutlineButtonLink to={`/customers/${id}/contracts/new`}>New Contract</OutlineButtonLink>}
                />
                <Route
                  path="hr-admins"
                  element={<OutlineButtonLink to={newHrAdminUser(id)}>New HR Admin</OutlineButtonLink>}
                />
              </>
            )}
          </Routes>
        </CustomerDetail>
      </EAPsListNavigation>
    </NavLayout>
  )
}
