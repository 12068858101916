import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { useState } from 'react'

import { SearchCustomerEmployeeRecordsQuery, useSearchCustomerEmployeeRecordsQuery } from '@nuna/api'
import { useDebounce } from '@nuna/common'

export type SelectCustomerEmployeeRecord = Pick<
  SearchCustomerEmployeeRecordsQuery['searchCustomerEmployeeRecords']['items'][number],
  'firstName' | 'lastName' | 'id' | 'employmentStatus'
>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<SelectCustomerEmployeeRecord, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

interface Props extends LimitedAutocompleteProps {
  companyId: string
  label?: string
  value?: SelectCustomerEmployeeRecord
  onChange: (employeeRecord: SelectCustomerEmployeeRecord | undefined) => void
}

export function CustomerEmployeeRecordSelect({ companyId, label, value, onChange, ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 450)
  const { data, loading } = useSearchCustomerEmployeeRecordsQuery({
    variables: {
      filters: { companyId, search: { term: debouncedSearchTerm, threshold: 0.2 } },
      pagination: { limit: 30 },
    },
    fetchPolicy: 'no-cache',
  })

  const employeeRecords: SelectCustomerEmployeeRecord[] = data?.searchCustomerEmployeeRecords?.items ?? []

  return (
    <Autocomplete
      {...props}
      loading={loading}
      multiple={false}
      onChange={(_event, value) => {
        onChange(value === null ? undefined : value)
      }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      getOptionLabel={employeeRecord =>
        `${employeeRecord?.firstName} ${employeeRecord?.lastName} (${employeeRecord?.employmentStatus})`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={employeeRecords}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      renderInput={params => (
        <TextField
          name="selectedCustomerEmployeeRecord"
          label={label ?? 'Employee Record'}
          placeholder="Filter by employee record"
          {...params}
        />
      )}
    />
  )
}
