import * as Yup from 'yup'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { useCreateAdminMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import {
  FillButton,
  Grid,
  OutlineButtonLink,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  TextField,
  toast,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { EntityForm } from '../../shared/EntityForm'
import { adminUsers } from '../../util/routes'

const { composeHelperText } = formService

interface FormValues {
  firstName: string
  lastName: string
  email: string
}

const validationSchema = Yup.object<FormValues>().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Must be a valid email format')
    .matches(/@tavahealth.com/, 'Must be an @tavahealth.com email address'),
})

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
}

export function NewAdminUser() {
  const [createAdmin, { loading }] = useCreateAdminMutation({ refetchQueries: ['ListAdmins'] })
  const navigate = useNavigate()

  const submit = async (values: FormValues) => {
    try {
      await createAdmin({ variables: values })
      toast.success(
        `Admin user created. An email will be sent to ${values.firstName} with instructions to continue setting up their account.`,
      )
      navigate(adminUsers)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to create admin user'))
    }
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading className="m-0">New Tava Admin</PageHeading>
        </PageHeader>
        <PageContent>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {({ values, handleChange, handleBlur, touched, errors }) => {
              const isError = (key: keyof FormValues) => {
                return !!touched[key] && !!errors[key]
              }
              const helperText = (key: keyof FormValues, text = '') => {
                const isTouched = touched[key] as boolean
                const errorMsg = errors[key] as string | undefined
                return composeHelperText(text, errorMsg, isTouched)
              }

              return (
                <EntityForm>
                  <Grid container spacing={2}>
                    <Grid
                      size={{
                        xs: 12,
                      }}
                    >
                      <TextField
                        label="First name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError('firstName')}
                        helperText={helperText('firstName')}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                      }}
                    >
                      <TextField
                        label="Last name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError('lastName')}
                        helperText={helperText('lastName')}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                      }}
                    >
                      <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError('email')}
                        helperText={helperText('email')}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                      }}
                      className="v-align"
                    >
                      <div className="ml-auto mt-2">
                        <FillButton type="submit" isLoading={loading}>
                          Save
                        </FillButton>
                        <OutlineButtonLink to={adminUsers} className="ml-2">
                          Cancel
                        </OutlineButtonLink>
                      </div>
                    </Grid>
                  </Grid>
                </EntityForm>
              )
            }}
          </Formik>
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
