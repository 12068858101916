import { FilterStatus, NotificationFeedHeaderProps as KnockHeaderProps, useKnockFeed } from '@knocklabs/react'
import { styled } from '@mui/material'

import { GhostButton, IconButton, IconClose, Select, fontSize } from '@nuna/tunic'

interface NotificationFeedHeaderProps extends KnockHeaderProps {
  onClose: () => void
}

export function NotificationFeedHeader({ onClose, filterStatus, setFilterStatus }: NotificationFeedHeaderProps) {
  const { useFeedStore, feedClient } = useKnockFeed()
  const store = useFeedStore()
  const undreadItems = store.items.filter(item => !item.read_at)
  return (
    <Container className="space-between v-align pb-2">
      <div className="v-align large text-medium gap-2">
        Notifications
        <Select
          className="filter-select"
          onChange={e => setFilterStatus(e.currentTarget.value as FilterStatus)}
          value={filterStatus}
        >
          <option value={FilterStatus.All}>All</option>
          <option value={FilterStatus.Unread}>Unread</option>
          <option value={FilterStatus.Unseen}>Unseen</option>
          <option value={FilterStatus.Read}>All</option>
        </Select>
      </div>
      <div className="v-align gap-1">
        {!!undreadItems.length && (
          <GhostButton className="mark-all-as-read-button" onClick={() => feedClient.markAllAsRead()}>
            Mark all as read
          </GhostButton>
        )}
        <IconButton tooltip="Close notification center" small onClick={onClose}>
          <IconClose size={16} />
        </IconButton>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  padding-left: 22px;
  .filter-select {
    .input-container {
      padding-top: 0;
    }
  }

  .mark-all-as-read-button {
    .inner-button {
      z-index: unset;
    }
    font-size: ${fontSize.caption};
  }
`
