import { styled } from '@mui/material'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import { ProviderListItemFragment } from '@nuna/api'
import { DEFAULT_PAGINATOR_PROPS, Paginator } from '@nuna/data-table'
import { MenuItem, MenuItemStack, makeTypographyComponent } from '@nuna/tunic'

import { NavColumn } from '../../shared/NavColumn'
import { useProviderListContext } from './ProviderListContextProvider'
import { ProviderListFilters } from './ProviderListFilters'

interface Props {
  children: ReactNode
  baseRoute?: string
  subHeading: (provider: ProviderListItemFragment) => ReactNode
}
export function ProviderListNavigation({ children, baseRoute = '/providers', subHeading }: Props) {
  const { providerListData, getPaginatorProps } = useProviderListContext()
  const urlParams = useParams()

  const providers = providerListData?.searchProviders.items

  const buildUrl = (providerId: string) => {
    const subRoute = urlParams['*'] ?? ''
    return `${baseRoute}/${providerId}/${subRoute}`
  }

  return (
    <Container>
      <StyledNavColumn>
        <ProviderListFilters className="filters-bar" orientation="vertical" />
        <div className="list">
          <MenuItemStack>
            {providers &&
              providers.map(provider => (
                <MenuItem to={buildUrl(provider.id)} key={provider.id}>
                  <div style={{ width: '100%' }}>
                    <div>
                      {provider.firstName} {provider.lastName}
                    </div>
                    <StatusText>{subHeading(provider)}</StatusText>
                  </div>
                </MenuItem>
              ))}
          </MenuItemStack>
        </div>
        <Paginator
          {...{ ...DEFAULT_PAGINATOR_PROPS, ...getPaginatorProps() }}
          totalLabel=" total"
          className="mt-1"
          controlProps={{ className: 'ml-auto' }}
        />
      </StyledNavColumn>
      <div className="content-body">{children}</div>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  .content-body {
    flex: 1;
    overflow-x: auto;
  }
`

const StyledNavColumn = styled(NavColumn)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 280px;
  width: 280px;
  padding-top: 0;
  overflow: hidden;
  .filters-bar {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 32px var(--spacing-2) var(--spacing-2) var(--spacing-2);
  }
  .list {
    flex: 1;
    overflow: auto;

    .menu-item {
      padding: 0.25rem 0.75rem;
    }
  }
`

const StatusText = makeTypographyComponent('caption italic text-light-grey', 'div')
