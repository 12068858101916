import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import DebounceLink from 'apollo-link-debounce'

import { handleErrorLink, handleWrongRoleLink } from '@nuna/auth'

import { environment } from '../environments/environment'

const apiEndpoint = environment.API_ENDPOINT
const DEFAULT_DEBOUNCE_TIMEOUT = 100

export const client = new ApolloClient({
  link: ApolloLink.from([
    handleErrorLink,
    handleWrongRoleLink,
    new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
    new HttpLink({ uri: apiEndpoint, credentials: 'include' }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      AssessmentResponseItem: {
        keyFields: false, // Otherwise cache tries to normalize on a non-unique id like CORE10_1 which is bad
      },
      CancelPolicy: {
        keyFields: false, // Otherwise bad things happen when requesting a cancelPolicyStatus for different clients
      },
      ClientDiscount: {
        keyFields: ['clientId', 'providerId'],
      },
      ProviderAddress: {
        keyFields: ['addressId', 'providerId'],
      },
      ProviderCalendar: {
        keyFields: false, // allows it to cache on query variables but not try to normalize results
      },
      CoverageProvider: {
        keyFields: false,
      },
      TherapyTypeCoverageItem: {
        keyFields: false,
      },
    },
    possibleTypes: {
      PatientHistoryItemMeta: ['ViewLinkAndPreview', 'ViewLinkFileDownloadAndPreview', 'NoteObject'],
    },
  }),
})
