import { useMemo, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { useInsurancePlanQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { MenuItem, MenuItemStack, csx } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { BreadcrumbBar } from '../../layouts/detail-layout/BreadcrumbBar'
import { BreadcrumbItem } from '../../layouts/detail-layout/Breadcrumbs'
import {
  DetailColumn,
  DetailColumnLoader,
  DetailContent,
  DetailContentNav,
  DetailLayout,
  DetailMainView,
} from '../../layouts/detail-layout/DetailLayout'
import { PayerPlanSubRoutes, payers } from '../../util/routes'
import { InsurancePolicyTable } from '../insurance-policy/InsurancePolicyTable/InsurancePolicyTable'
import { PayerPlanDetailColumn } from './PayerPlanDetailColumn'
import { PayerPlanForm } from './PayerPlanForm'
import { PlanContracts } from './PlanContracts/PlanContracts'

export function PayerPlanDetail() {
  const { planId = '', id: payerId = '' } = useParams<{ planId: string; id: string }>()
  const [isEditing, setIsEditing] = useState(false)
  const { data, loading } = useInsurancePlanQuery({ variables: { id: planId } })

  const breadcrumbs = useMemo<BreadcrumbItem[]>(
    () => [
      { label: 'Payers', path: payers },
      {
        label: data?.insurancePayerPlan.insurancePayer.name ?? '',
        path: routeService.insurancePayer(data?.insurancePayerPlan.insurancePayer.id ?? ''),
      },
      {
        label: data?.insurancePayerPlan.name ?? '',
      },
    ],
    [data],
  )

  const plan = data?.insurancePayerPlan

  return (
    <NavLayout containerProps={{ className: 'flex-column' }}>
      <BreadcrumbBar breadcrumbs={breadcrumbs} loading={loading} />
      <DetailLayout className="flex-remaining-space">
        <DetailColumn className={csx({ wide: isEditing })}>
          {!isEditing && loading && <DetailColumnLoader />}
          {!isEditing && !loading && plan && <PayerPlanDetailColumn plan={plan} onEdit={() => setIsEditing(true)} />}
          {isEditing && plan && (
            <PayerPlanForm
              plan={plan}
              payerId={payerId}
              onCancel={() => setIsEditing(false)}
              afterSave={() => setIsEditing(false)}
            />
          )}
        </DetailColumn>
        <DetailMainView>
          <DetailContentNav className="mb-1">
            <MenuItemStack direction="row">
              <MenuItem to={PayerPlanSubRoutes.Contracts}>Contracts</MenuItem>
              <MenuItem to={PayerPlanSubRoutes.Policies}>Policies</MenuItem>
            </MenuItemStack>
          </DetailContentNav>
          <DetailContent className="overflow-hidden">
            <Routes>
              <Route index element={<Navigate to={PayerPlanSubRoutes.Contracts} replace />} />
              <Route path={PayerPlanSubRoutes.Contracts}>
                <Route
                  index
                  element={
                    <PlanContracts
                      payerId={payerId}
                      planId={planId}
                      payerName={data?.insurancePayerPlan.insurancePayer.name ?? ''}
                      planName={data?.insurancePayerPlan.name ?? ''}
                    />
                  }
                />
                <Route
                  path=":planContractId"
                  element={
                    <PlanContracts
                      payerId={payerId}
                      planId={planId}
                      payerName={data?.insurancePayerPlan.insurancePayer.name ?? ''}
                      planName={data?.insurancePayerPlan.name ?? ''}
                    />
                  }
                />
              </Route>
              <Route
                path={PayerPlanSubRoutes.Policies}
                element={
                  <InsurancePolicyTable
                    columns={['patient', 'payer', 'network']}
                    fixedFilters={{ insurancePayerPlanId: planId }}
                  />
                }
              />
            </Routes>
          </DetailContent>
        </DetailMainView>
      </DetailLayout>
    </NavLayout>
  )
}
