import { usePatientQuery } from '@nuna/api'

import { UserEventLog } from '../../shared/UserEventLog/UserEventLog'

interface Props {
  patientId: string
}

export function ClientEventLog({ patientId }: Props) {
  const { data } = usePatientQuery({
    variables: {
      id: patientId,
    },
  })

  if (!data) {
    return null
  }

  return (
    <UserEventLog
      header={<h5 className="mb-0">{data.patient.firstName}'s Event Log</h5>}
      allowFiltering
      fixedFilters={{ userId: data.patient.login.id }}
    />
  )
}
