import { useMemo } from 'react'

import { EnterHealthPayer, EnterPayerPlan, useEnterHealthPayerQuery, useInsuranceTaxEntityQuery } from '@nuna/api'
import { Skeleton } from '@nuna/tunic'

import { DetailDatumLabel, DetailDatumValue } from '../../layouts/detail-layout/DetailLayout'

interface Props {
  enterPayerPlan: Partial<EnterPayerPlan>
  label?: string
}

export function PayerPlanDetailEnterDisplay({ enterPayerPlan, label = 'Enter Health plan' }: Props) {
  const { data, loading: taxEntityLoading } = useInsuranceTaxEntityQuery({
    variables: {
      id: enterPayerPlan.insuranceTaxEntityId as string,
    },
    skip: !enterPayerPlan.insuranceTaxEntityId,
    fetchPolicy: 'cache-and-network',
  })

  const displaylabel = useMemo(() => {
    if (!data?.insuranceTaxEntity) {
      return label
    }

    return `${label} "${data.insuranceTaxEntity.state}"`
  }, [data, label])

  const { data: enterHealthData, loading: enterDataLoading } = useEnterHealthPayerQuery({
    variables: {
      insuranceTaxEntityId: enterPayerPlan.insuranceTaxEntityId ?? '',
      enterHealthPayerId: enterPayerPlan.enterPlanId ?? '',
    },
    skip: !enterPayerPlan.insuranceTaxEntityId || !enterPayerPlan.enterPlanId,
  })

  const enterPayer = useMemo(() => {
    return enterHealthData?.enterHealthPayer ?? ({} as EnterHealthPayer)
  }, [enterHealthData])

  return (
    <>
      <DetailDatumLabel>{displaylabel}</DetailDatumLabel>
      <DetailDatumValue>
        {enterDataLoading || taxEntityLoading ? <Skeleton height={2} style={{ width: '100%' }} /> : enterPayer.name}
      </DetailDatumValue>
    </>
  )
}
