import { styled } from '@mui/material'
import { GridApi, GridReadyEvent, StatusPanelDef } from 'ag-grid-community'
import { ColDef, IServerSideDatasource } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { compact } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PayerRosterDefinitionQuery, usePayerRosterSheetDataLazyQuery } from '@nuna/api'
import { IconLoading, salmonSet } from '@nuna/tunic'

type Query = ReturnType<typeof usePayerRosterSheetDataLazyQuery>[0]

interface SheetTableProps {
  rosterDefinition: PayerRosterDefinitionQuery['payerRosterDefinition']
}

const ITEM_LIMIT = 40
const DEFAULT_COL_DEF: ColDef = {
  resizable: true,
  suppressMovable: true,
  editable: false,
  sortable: false,
  cellClassRules: {
    error: params => params.value === '#ERROR!',
  },
}

const createServerSideDatasource: (
  query: Query,
  payerRosterDefinitionId: string,
  sheetKey: string,
) => IServerSideDatasource = (query, payerRosterDefinitionId, sheetKey) => {
  return {
    getRows: params => {
      query({
        variables: {
          filters: { sheetKey, payerRosterDefinitionId },
          limit: ITEM_LIMIT,
          offset: params.request.startRow,
        },
        fetchPolicy: 'network-only',
      }).then(result => {
        const { data } = result

        if (data) {
          const {
            payerRosterSheetData: {
              total,
              sheet: { data: rowData },
            },
          } = data

          params.success({ rowData, rowCount: total })
        } else {
          params.fail()
        }
      })
    },
  }
}
export function SheetTable({ rosterDefinition }: SheetTableProps) {
  const gridRef = useRef<AgGridReact>(null)
  const { sheetKey = '' } = useParams()
  const columnKeys = useMemo(
    () => rosterDefinition.config.sheets.find(sheet => sheet.key === sheetKey)?.columnKeys ?? [],
    [sheetKey, rosterDefinition],
  )
  const columnDefs: ColDef[] = useMemo(
    () =>
      compact(
        columnKeys.map(key => {
          const column = rosterDefinition.config.columns.find(c => c.key === key)
          if (!column) return undefined
          return { field: column.key, headerName: column.alias }
        }),
      ),
    [columnKeys, rosterDefinition],
  )

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: function StatusPanel(params: { api: GridApi }) {
            const [rowCount, setRowCount] = useState<number | undefined>()
            params.api.addEventListener('modelUpdated', () => {
              setRowCount(params.api.getDisplayedRowCount())
            })

            return (
              <div className="v-align py-1">
                <span style={{ fontWeight: 400 }}>Total Rows:</span>
                {rowCount ? <b className="ml-xs">{rowCount}</b> : <IconLoading className="ml-1 rotate" size={16} />}
              </div>
            )
          },
        },
      ],
    }
  }, [])

  const [query] = usePayerRosterSheetDataLazyQuery()

  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (gridApi) {
      gridApi.setServerSideDatasource(createServerSideDatasource(query, rosterDefinition.id, sheetKey))
    }
  }, [query, rosterDefinition, sheetKey])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.setServerSideDatasource(createServerSideDatasource(query, rosterDefinition.id, sheetKey))
    },
    [query, rosterDefinition.id, sheetKey],
  )

  return (
    <Container className="flex-remaining-space ag-theme-material">
      <AgGridReact
        ref={gridRef}
        defaultColDef={DEFAULT_COL_DEF}
        columnDefs={columnDefs}
        rowModelType="serverSide"
        cacheBlockSize={ITEM_LIMIT}
        onGridReady={onGridReady}
        serverSideStoreType="partial"
        statusBar={statusBar}
        enableRangeSelection={true}
        suppressRowClickSelection
      />
    </Container>
  )
}

const Container = styled('div')`
  .error {
    background-color: ${salmonSet[15].hex};
  }
`
