import { DocumentCenter } from '@nuna/document-center'

import { ProviderCoi } from '../../ProviderCoi/ProviderCoi'
import { ProviderW9Documents } from './components/ProviderW9Documents'

interface Props {
  loginId?: string | null
  providerId: string
}
export function ProviderDocuments(props: Props) {
  return (
    <>
      <DocumentCenter {...props} />
      <ProviderW9Documents className="mt-3" providerId={props.providerId} />
      <div className="py-4">
        <h4>COI</h4>
        <ProviderCoi {...props} />
      </div>
    </>
  )
}
