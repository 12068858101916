/* eslint-disable react/jsx-no-useless-fragment */
import { isNil } from 'lodash'
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CompanyFilterInput,
  CustomerListItemFragment,
  OrderBy,
  TraversablePaginationSortInput,
  useSearchCustomersPaginatedLazyQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { OutlineButton, PageContent, PageHeader, PageHeading, PageWrapper, Radio } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { DataFilters } from '../../shared/DataFilters'
import { customerDetail, newCustomer } from '../../util/routes'

type CustomerFilterValues = Omit<CompanyFilterInput, 'search'> & {
  searchTerm?: string | null
}

const INITIAL_FILTER_VALUES: CustomerFilterValues = {
  searchTerm: '',
}
const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'name', direction: OrderBy.Asc }]

export function CustomerList() {
  const navigate = useNavigate()
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(INITIAL_FILTER_VALUES)
  const [queryCustomers, { data: customerData, loading }] = useSearchCustomersPaginatedLazyQuery()

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination({
    pagination: customerData?.searchCompaniesPaginated.pagination,
    loading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryCustomers({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
    })
  }, [queryCustomers, queryOptions])

  const rowData = useMemo(() => customerData?.searchCompaniesPaginated.items, [customerData])

  const columns = React.useMemo<DataTableColumn<CustomerListItemFragment>[]>(
    () => [
      {
        accessor: 'name',
        id: 'name',
        Header: 'Name',
        Cell: cell => {
          return <Link to={customerDetail(cell.row.original.id)}>{cell.value}</Link>
        },
        paginatedSortable: true,
      },
      {
        accessor: 'supportsHrisIntegration',
        Header: 'HRIS Sync Enabled',
        Cell: ({ value }) => <>{value ? 'Yes' : 'No'}</>,
      },
      { accessor: 'integrationName', Header: 'Connected HRIS' },
    ],
    [],
  )

  const handleHrisEnabledFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      const shouldFilterOnHrisSyncEnabled = value === 'HRIS_ENABLED' ? true : value === 'HRIS_DISABLED' ? false : null
      setFilterVal('hrisEnabled', shouldFilterOnHrisSyncEnabled)
    },
    [setFilterVal],
  )

  const filtersApplied = useMemo(() => (isNil(filterValues.hrisEnabled) ? 0 : 1), [filterValues])

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <div className="v-align">
            <PageHeading className="m-0" withDivider>
              Customers
            </PageHeading>
            <DataFilters
              className="ml-2"
              searchPlaceholder="Search all customers"
              onSearchTextChange={searchText => setFilterVal('searchTerm', searchText)}
              filtersApplied={filtersApplied}
              onClearFilters={clearFilters}
            >
              <label>HRIS Sync Enabled</label>
              <div role="group" className="mt-2">
                <Radio
                  name="hris-enabled"
                  value=""
                  checked={isNil(filterValues.hrisEnabled)}
                  onChange={handleHrisEnabledFilterChange}
                >
                  Either
                </Radio>
                <Radio
                  name="hris-enabled"
                  value="HRIS_ENABLED"
                  checked={filterValues.hrisEnabled === true}
                  onChange={handleHrisEnabledFilterChange}
                >
                  Yes
                </Radio>
                <Radio
                  name="hris-enabled"
                  value="HRIS_DISABLED"
                  checked={filterValues.hrisEnabled === false}
                  onChange={handleHrisEnabledFilterChange}
                >
                  No
                </Radio>
              </div>
            </DataFilters>
          </div>
          <OutlineButton onClick={() => navigate(newCustomer)}>New Customer</OutlineButton>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <DataTable
            paginated
            paginatorProps={getPaginatorProps()}
            onSort={handleSort}
            columns={columns}
            rowData={rowData}
            initialState={initialTableState}
            loading={loading}
            onRowClick={row => navigate(customerDetail(row.id))}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

function buildFilterValues(values: CustomerFilterValues = INITIAL_FILTER_VALUES): CompanyFilterInput {
  const { searchTerm, hrisEnabled, id } = values
  return {
    search: {
      term: searchTerm,
      threshold: 0.2,
    },
    hrisEnabled,
    id,
  }
}
