import { IconProps } from './IconProps'

export function IconLink({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7698 11.7956C10.6127 12.956 8.73801 12.956 7.58093 11.7956L6.18614 10.3968L7.58093 8.99808L8.97571 10.3968C9.36141 10.7791 9.98928 10.7836 10.3705 10.3968L14.5593 6.19606C14.9405 5.80927 14.9405 5.1796 14.5593 4.79281L13.1646 3.39405C12.7833 3.01175 12.151 3.01175 11.7698 3.39405L10.2718 4.89625C9.57668 4.48697 8.79183 4.31156 8.01147 4.36553L10.375 1.99529C11.5321 0.834904 13.4067 0.834904 14.5638 1.99529L15.9586 3.39405C17.1157 4.54993 17.1157 6.42994 15.9586 7.59482L11.7698 11.7956ZM6.18614 14.5931C5.80044 14.9799 5.17257 14.9754 4.79136 14.5931L3.39657 13.1944C3.01087 12.8121 3.01087 12.1779 3.39657 11.7956L7.58541 7.59482C7.96662 7.21252 8.59898 7.21252 8.9802 7.59482L10.375 8.99358L11.7698 7.59482L10.375 6.19606C9.21789 5.03568 7.34323 5.03568 6.18614 6.19606L1.99282 10.3968C0.835728 11.5572 0.835728 13.4372 1.99282 14.5976L3.3876 15.9964C4.54469 17.1568 6.41935 17.1568 7.57644 15.9964L9.93995 13.6261C9.15959 13.6801 8.37474 13.5047 7.67959 13.0954L6.18614 14.5931Z"
        fill={color}
      />
    </svg>
  )
}
