import { styled } from '@mui/material'
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { isArray, isBoolean, isString, pick } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import {
  OrderBy,
  ProviderDataDenormalized,
  ProviderDataDenormalizedSearchOptions,
  TraversablePaginationSortInput,
  useProvidersDataDenormalizedQuery,
  useSearchProvidersDataDenormalizedLazyQuery,
} from '@nuna/api'
import { TavaDataTable } from '@nuna/common'
import { Skeleton, borderGrey, makeTypographyComponent, salmonSet } from '@nuna/tunic'

import { useUrlFilters } from '../../../shared/filters/hooks/useUrlFilters'
import { useMasterRosterContext } from './MasterRosterContextProvider'
import { DEFAULT_MASTER_ROSTER_COLUMN_DEF, GROUP_COLUMN_DEF, buildMasterRosterColumnDefs } from './columnDefs'
import { ExpandAllRowsFilter, ExpandAllRowsSwitch } from './components/ExpandAllRowsSwitch'
import { MasterRosterFilters } from './components/MasterRosterFilters'
import { QuickViewSelect } from './components/QuickViewSelect'

type Query = ReturnType<typeof useSearchProvidersDataDenormalizedLazyQuery>[0]

const INITIAL_SORT: TraversablePaginationSortInput[] = [
  { key: 'updatedAt', direction: OrderBy.Desc },
  { key: 'addressStreetLineOne', direction: OrderBy.Desc },
  { key: 'addressZipCode', direction: OrderBy.Desc },
]

const PAGE_SIZE = 100

const createServerSideDatasource: (
  query: Query,
  expanded: boolean,
  filters?: ProviderDataDenormalizedSearchOptions,
  onDataLoaded?: (items: ProviderDataDenormalized[]) => void,
) => IServerSideDatasource = (query, expanded, filters = {}, onDataLoaded) => {
  return {
    getRows: params => {
      const page = Math.ceil(((params.request.startRow ?? 0) + 1) / PAGE_SIZE)
      const providerId = filters.providerId ?? params.request.groupKeys?.[0]
      query({
        variables: {
          filters: {
            ...filters,
            defaultTaxEntity: true,
            primaryAddress: expanded ? undefined : params.request.groupKeys?.[0] ? false : true,
            providerId,
          },
          pagination: { limit: PAGE_SIZE, page },
          order: INITIAL_SORT,
        },
        fetchPolicy: 'network-only',
      }).then(result => {
        const { data } = result

        if (data) {
          const {
            searchProvidersDataDenormalized: { items, pagination },
          } = data

          onDataLoaded && onDataLoaded(items)

          params.success({ rowData: items ?? [], rowCount: pagination?.totalCount ?? 0 })
        } else {
          params.fail()
        }
      })
    },
  }
}

const POLLING_INTERVAL = 1000 * 20

const transformFilters = (values: Record<string, unknown>): ProviderDataDenormalizedSearchOptions => {
  const { nullColumns, ...rFilters } = values
  const nullColumnsArray = isArray(nullColumns)
    ? nullColumns
    : isString(nullColumns)
    ? nullColumns.split(',')
    : undefined

  return {
    ...pick(rFilters as ProviderDataDenormalizedSearchOptions, ['providerId', 'isValid']),
    nullColumns: nullColumnsArray,
  }
}

const transformeGrouped = (value: Record<string, unknown>): ExpandAllRowsFilter => {
  if (isBoolean(value.expanded)) {
    return { expanded: value.expanded }
  }

  return { expanded: false }
}

export function MasterRoster() {
  const urlFilters = useUrlFilters(transformFilters)
  const groupedFilter = useUrlFilters(transformeGrouped)

  const { filterValues } = urlFilters
  const { filterValues: expandedFilterValues } = groupedFilter
  const { visibleColumns, hiddenColumns, dataState, columnMetadata, columnMetadataLoading, setGridApi, setDataState } =
    useMasterRosterContext()
  const gridRef = useRef<AgGridReact>(null)
  const columnDefs = useMemo(
    () =>
      buildMasterRosterColumnDefs(
        columnMetadata,
        expandedFilterValues.expanded,
        isBoolean(filterValues.isValid) ? !filterValues.isValid : false,
      ),
    [columnMetadata, expandedFilterValues, filterValues.isValid],
  )

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.setColumnDefs(columnDefs)
    }
  }, [columnDefs])

  const [query] = useSearchProvidersDataDenormalizedLazyQuery()
  const {
    data: pollData,
    startPolling,
    stopPolling,
  } = useProvidersDataDenormalizedQuery({
    pollInterval: POLLING_INTERVAL,
    variables: {
      searchOptions: {
        id: dataState.id,
      },
    },
    skip: !dataState.id,
  })

  useEffect(() => {
    if (dataState.refreshing && dataState.updatedAt !== pollData?.providersDataDenormalized?.[0]?.updatedAt) {
      const gridApi = gridRef.current?.api

      if (gridApi) {
        gridApi.setServerSideDatasource(
          createServerSideDatasource(
            query,
            expandedFilterValues.expanded,
            Object.keys(filterValues).length > 0 ? filterValues : undefined,
            () => {
              setDataState({
                ...dataState,
                updatedAt: pollData?.providersDataDenormalized?.[0]?.updatedAt,
                refreshing: false,
              })
            },
          ),
        )
      }
    }
  }, [filterValues, expandedFilterValues, query, dataState, pollData, setDataState])

  useEffect(() => {
    if (dataState.id && dataState.updatedAt && dataState.refreshing) {
      startPolling(POLLING_INTERVAL)
    } else {
      stopPolling()
    }
  }, [dataState, startPolling, stopPolling])

  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (gridApi) {
      gridApi.setServerSideDatasource(createServerSideDatasource(query, expandedFilterValues.expanded, filterValues))
    }
  }, [filterValues, expandedFilterValues, query])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridApi(params.api)

      params.api.setColumnDefs(columnDefs)

      params.api.setServerSideDatasource(
        createServerSideDatasource(query, expandedFilterValues.expanded, filterValues, items => {
          if (!dataState.id && items.length) {
            setDataState({
              id: items[0].id,
              updatedAt: items[0].updatedAt,
              refreshing: false,
            })
          }
        }),
      )
    },
    [query, filterValues, setGridApi, dataState, setDataState, columnDefs, expandedFilterValues],
  )

  useEffect(() => {
    if (gridRef.current?.columnApi) {
      gridRef.current.columnApi.setColumnsVisible(
        visibleColumns.filter(c => (c === 'providerId' && !expandedFilterValues.expanded ? false : true)),
        true,
      )
      gridRef.current.columnApi.setColumnsVisible(
        hiddenColumns.filter(c => (c === 'providerId' && expandedFilterValues.expanded ? false : true)),
        false,
      )
    }
  }, [visibleColumns, hiddenColumns, expandedFilterValues])

  return (
    <Container>
      {columnMetadataLoading && <Skeleton />}
      {!columnMetadataLoading && (
        <>
          <Toolbar className="space-between">
            <span className="v-align gap-2">
              <ExpandAllRowsSwitch urlFilterService={groupedFilter} />
              <MasterRosterFilters urlFilterService={urlFilters} />
            </span>
            <QuickViewSelect />
          </Toolbar>
          <TavaDataTable
            ref={gridRef}
            defaultColDef={DEFAULT_MASTER_ROSTER_COLUMN_DEF}
            rowModelType="serverSide"
            pagination={true}
            paginationPageSize={PAGE_SIZE}
            cacheBlockSize={PAGE_SIZE}
            onGridReady={onGridReady}
            serverSideStoreType="partial"
            enableRangeSelection={true}
            autoGroupColumnDef={GROUP_COLUMN_DEF}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled(makeTypographyComponent('ag-theme-material flex-column', 'div'))`
  height: 100%;
  width: 100%;
  .empty {
    background-color: ${salmonSet[15].hex};
  }
  .provider-cell {
    display: flex;
    .ag-row-group {
      flex: 1;
      overflow: hidden;
      .ag-group-value {
        flex: 1;
        overflow: hidden;
        .provider-link {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
`

const Toolbar = styled(makeTypographyComponent('p-2 v-align', 'div'))`
  border-bottom: 1px solid ${borderGrey};
`
