import { ApolloError } from '@apollo/client'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'

import {
  EventGroupLogFilterType,
  EventLogItem,
  EventTypeLogFilterType,
  TransactionDirection,
  useGetEventLogsQuery,
  useRollTransactionMutation,
} from '@nuna/api'
import { DataTable } from '@nuna/data-table'
import { OutlineButton, StatusLabel, toast } from '@nuna/tunic'

import { EventLogRow } from './EventLogRow'

interface Props {
  companyId?: string | null
  clientId?: string | null
  startDate: moment.Moment
  endDate: moment.Moment
  eventTypes: EventTypeLogFilterType[]
  filtersLoading: boolean
}

export function EventLogTable({ companyId, startDate, endDate, eventTypes, clientId, filtersLoading }: Props) {
  const { data, loading } = useGetEventLogsQuery({
    variables: {
      eventLogFilters: {
        companyID: companyId,
        patientID: clientId,
        startDate,
        endDate,
        eventTypeGroups: [EventGroupLogFilterType.Eligibility],
        eventTypes,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: filtersLoading,
  })

  const [rollTransaction] = useRollTransactionMutation()

  const handleRevert = useCallback(
    async (row: Row<EventLogItem>) => {
      try {
        await rollTransaction({
          variables: {
            direction: TransactionDirection.Backward,
            transactionID: row.original.transactionalActionId ?? '',
          },
        })
        toast.success(`Rolled back event for ${row.original.who}.`)
      } catch (error: unknown) {
        if (error instanceof ApolloError) {
          toast.urgent(error.graphQLErrors[0] ? error.graphQLErrors[0].message : 'Error rolling back event')
        }
      }
    },
    [rollTransaction],
  )

  const columns = useMemo<Column<EventLogItem>[]>(
    () => [
      {
        Header: 'Event',
        accessor: 'eventName',
        Cell: cell => {
          return <StatusLabel>{cell.value}</StatusLabel>
        },
      },
      {
        Header: 'When',
        accessor: 'when',
        Cell: cell => <>{moment(cell.value).format('MM/DD/YYYY h:mm a')}</>,
      },
      {
        Header: 'Who',
        accessor: 'who',
      },
      {
        Header: 'What',
        accessor: 'what',
      },
      {
        Header: 'Where',
        accessor: 'where',
      },

      {
        Header: 'Why',
        accessor: 'why',
      },
      {
        Header: '',
        accessor: 'transactionalActionId',
        Cell: ({ row }) => (
          <OutlineButton
            onClick={e => {
              e.stopPropagation()
              handleRevert(row)
            }}
          >
            Revert
          </OutlineButton>
        ),
      },
    ],
    [handleRevert],
  )

  const rowData: EventLogItem[] = useMemo(() => {
    return data?.getEventLogs ?? []
  }, [data])

  return (
    <DataTable
      columns={columns}
      rowData={rowData}
      loading={loading}
      noRecordsMessage="No records to show. Try adjusting the filters."
      customRow={rowProps => <EventLogRow colSpan={columns.length} {...rowProps} />}
    />
  )
}
