import { useCallback, useRef } from 'react'

import { useRemovePayerRosterArtifactMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Confirm, ConfirmRef, IconButton, IconTrash, toast } from '@nuna/tunic'

export function DeleteRosterButton({ rosterArtifactId, disabled }: Props) {
  const confirmRef = useRef<ConfirmRef>(null)

  const [removeRoster, { loading: removeRosterLoading }] = useRemovePayerRosterArtifactMutation()

  const handleClick = useCallback(async () => {
    confirmRef.current?.open(async response => {
      if (response) {
        try {
          await removeRoster({
            variables: { id: rosterArtifactId },
            refetchQueries: ['PayerRosterArtifacts', 'PayerRosterArtifact'],
            awaitRefetchQueries: true,
          })
          toast.success('Roster deleted successfully')
        } catch (e) {
          toast.urgent(errorService.transformGraphQlError(e, 'Unable to delete roster'))
        }
      }
    })
  }, [removeRoster, rosterArtifactId])

  return (
    <>
      <IconButton
        small
        variant="destroy"
        tooltip="Delete roster"
        onClick={handleClick}
        disabled={disabled || removeRosterLoading}
      >
        <IconTrash size={22} />
      </IconButton>
      <Confirm ref={confirmRef}>Are you sure you want to delete this roster?</Confirm>
    </>
  )
}

type Props = {
  rosterArtifactId: string
  disabled?: boolean
  size?: number
}
