import { useNavigate } from 'react-router-dom'

import { routeService } from '@nuna/core'
import { PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { PayerForm } from './PayerForm'

export function NewPayer() {
  const navigate = useNavigate()
  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading className="m-0">New Payer</PageHeading>
        </PageHeader>
        <PageContent>
          <PayerForm afterSave={payerId => navigate(routeService.insurancePayer(payerId ?? ''))} />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
