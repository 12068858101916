import { styled } from '@mui/material'

import { borderGrey } from '@nuna/tunic'

export const NavColumn = styled('div')`
  border-right: 1px solid ${borderGrey};
  width: 280px;
  padding: var(--margin-1);
  overflow-y: auto;
`
