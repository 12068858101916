import { NotificationIconButton } from '@knocklabs/react'
import { styled } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes } from 'react'
import { GrConnect } from 'react-icons/gr'
import { MdAccountBox, MdAttachMoney, MdCardMembership, MdEvent } from 'react-icons/md'

import { Role } from '@nuna/api'
import { useAuthDataContext, useHasRole, useIsAdmin } from '@nuna/auth'
import {
  IconBook,
  IconClock,
  IconCustomer,
  IconFileDocument,
  IconHandshake,
  IconLocation,
  IconPayment,
  IconPower,
  IconSessions,
  IconTherapist,
  IconUser,
  MenuItem,
  MenuItemStack,
  borderGrey,
} from '@nuna/tunic'

import {
  addresses,
  adminUsers,
  appointmentsViews,
  availability,
  clientList,
  cptCodeList,
  customerIntegrationDashboard,
  customerList,
  diagnosesList,
  enrollments,
  eventLog,
  hrAdminUsers,
  insuranceProviders,
  insuranceRosters,
  memberMatch,
  partnerAdmin,
  partnerList,
  payers,
  payroll,
  providerCompensation,
  providerDefaultCompensation,
  providerList,
  providerPayroll,
  taxEntityList,
} from '../../util/routes'
import { NavGroup } from './NavGroup'

interface SidebarNavigationItemsProps extends HTMLAttributes<HTMLDivElement> {
  setNotificationCenterOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export function SidebarNavigationItems({ setNotificationCenterOpen, ...props }: SidebarNavigationItemsProps) {
  const { onLogout } = useAuthDataContext()
  const isCustomer = useHasRole(Role.Customer)
  const isPartnerAdmin = useHasRole(Role.Partner)
  const isAdmin = useIsAdmin()

  return (
    <div {...props}>
      <MenuItemStack>
        {isAdmin && (
          <>
            <MenuItem to={providerList} leadingIcon={IconTherapist}>
              Providers
            </MenuItem>
            <MenuItem to={clientList} leadingIcon={IconUser}>
              Clients
            </MenuItem>
            <MenuItem to={customerList} leadingIcon={IconCustomer}>
              Customers
            </MenuItem>
            <MenuItem to={partnerList} leadingIcon={IconHandshake}>
              Partners
            </MenuItem>
            <MenuItem to={appointmentsViews} leadingIcon={IconSessions}>
              Appointments
            </MenuItem>
            <MenuItem to={availability} leadingIcon={IconClock}>
              Availability
            </MenuItem>
            <MenuItem to={memberMatch} leadingIcon={MdCardMembership}>
              Member Match
            </MenuItem>
            <MenuItem to={eventLog} leadingIcon={MdEvent}>
              Event Log
            </MenuItem>
            <NavGroup label="Users" name="users" pathMatches={[adminUsers, hrAdminUsers]}>
              <MenuItem to={adminUsers} leadingIcon={MdAccountBox}>
                Tava Admins
              </MenuItem>
              <MenuItem to={hrAdminUsers} leadingIcon={MdAccountBox}>
                HR Admins
              </MenuItem>
            </NavGroup>
            <NavGroup
              label="Insurance"
              name="insurance"
              pathMatches={[cptCodeList, payers, enrollments, insuranceProviders, insuranceRosters]}
            >
              <MenuItem to={cptCodeList} leadingIcon={MdCardMembership}>
                CPT
              </MenuItem>
              <MenuItem to={diagnosesList} leadingIcon={MdCardMembership}>
                Diagnoses
              </MenuItem>
              <MenuItem to={addresses} leadingIcon={IconLocation}>
                Addresses
              </MenuItem>
              <MenuItem to={payers} leadingIcon={MdCardMembership}>
                Payers
              </MenuItem>
              <MenuItem to={taxEntityList} leadingIcon={MdCardMembership}>
                Tax Entities
              </MenuItem>
              <MenuItem to={enrollments} leadingIcon={IconBook}>
                Enrollments
              </MenuItem>
              <MenuItem to={insuranceRosters} leadingIcon={IconFileDocument}>
                Rosters
              </MenuItem>
            </NavGroup>
            <NavGroup label="Finance" name="finance" pathMatches={[payroll, providerCompensation, providerPayroll]}>
              <MenuItem to={providerPayroll} leadingIcon={IconPayment}>
                Payouts
              </MenuItem>
              <MenuItem to={providerCompensation} leadingIcon={MdAttachMoney}>
                Compensation
              </MenuItem>
              <MenuItem to={providerDefaultCompensation} leadingIcon={MdAttachMoney}>
                Compensation Tiers
              </MenuItem>
            </NavGroup>
          </>
        )}

        {isCustomer && (
          <MenuItem to={customerIntegrationDashboard} leadingIcon={GrConnect}>
            Integrations
          </MenuItem>
        )}

        {isPartnerAdmin && (
          <MenuItem to={partnerAdmin} leadingIcon={IconUser}>
            EAPs
          </MenuItem>
        )}

        <SidebarDivider />
        {!!setNotificationCenterOpen && (
          <MenuItem
            button
            className="v-align"
            style={{ padding: '0.5rem 0.35rem', gap: 2 }}
            onClick={() => setNotificationCenterOpen(prev => !prev)}
          >
            <NotificationIconButton onClick={noop} badgeCountType="unread" />
            Notifications
          </MenuItem>
        )}

        <MenuItem button leadingIcon={IconPower} onClick={() => onLogout()}>
          Sign Out
        </MenuItem>
      </MenuItemStack>
    </div>
  )
}

export const SidebarDivider = styled('div')`
  border-top: 1px solid ${borderGrey};
  margin: 1rem 0;
`
