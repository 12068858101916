import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  OrderBy,
  PartnerQuery,
  SearchCompaniesPaginatedQuery,
  TraversablePaginationSortInput,
  useSearchCompaniesPaginatedLazyQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { PageSearch } from '@nuna/tunic'

import { customerDetail } from '../../util/routes'

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'name', direction: OrderBy.Asc }]

export function PartnerOverview({ partner }: { partner: PartnerQuery['partner'] }) {
  const { id } = partner
  const [searchText, setSearchText] = useState('')

  const buildFilterValues = useCallback((values: { search: string } | undefined) => {
    const { search } = values || {}
    return {
      search: {
        term: search || '',
        threshold: 0.2,
      },
    }
  }, [])

  const navigate = useNavigate()

  const [querySearchCompanies, { data: customerListData, loading }] = useSearchCompaniesPaginatedLazyQuery({
    variables: { filters: { partnerId: id } },
    fetchPolicy: 'cache-and-network',
  })
  const { filterValues, setFilterVal } = useDataTableFiltering({ search: '' })

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination({
    pagination: customerListData?.searchCompaniesPaginated.pagination,
    loading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const search = useMemo(
    () => debounce((searchText?: string | null) => setFilterVal('search', searchText), 400),
    [setFilterVal],
  )

  useEffect(() => {
    search(searchText)
  }, [search, searchText])

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    querySearchCompanies({
      variables: {
        order: sortInput,
        filters: { ...buildFilterValues(filters), partnerId: id },
        pagination: paginationInput,
      },
    })
  }, [querySearchCompanies, queryOptions, id, buildFilterValues])

  const rowData = useMemo(() => customerListData?.searchCompaniesPaginated.items, [customerListData])

  const columns = useMemo<
    DataTableColumn<
      Pick<
        NonNullable<SearchCompaniesPaginatedQuery['searchCompaniesPaginated']['items'][0]>,
        '__typename' | 'id' | 'name'
      >
    >[]
  >(
    () => [
      {
        accessor: 'name',
        id: 'name',
        Header: 'Customer Name',
        Cell: ({ value, row }) => <Link to={customerDetail(row.original.id)}>{value}</Link>,
      },
    ],
    [],
  )

  return (
    <>
      <PageSearch
        style={{ position: 'relative', width: 'max-content' }}
        className="mb-2"
        placeholder="Search all customers"
        onChange={event => setSearchText(event.currentTarget.value)}
        value={searchText}
        onClear={() => setSearchText('')}
        autoFocus={true}
      />
      <DataTable
        paginated
        paginatorProps={getPaginatorProps()}
        onSort={handleSort}
        columns={columns}
        rowData={rowData}
        initialState={initialTableState}
        loading={loading}
        onRowClick={row => navigate(customerDetail(row.id))}
      />
    </>
  )
}
