import { styled } from '@mui/material'
import { isArray, take } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useEffect, useState } from 'react'

import { IconButton, IconClose, Menu, makeTypographyComponent, tealSet, white } from '@nuna/tunic'

import { DateTimeFilterValue, Filter, FilterUrlAccessor, FilterValueOption } from '../filters.types'
import { FilterValueSelector } from './FilterValueSelector/FilterValueSelector'

const MULTI_VALUE_DISPLAY_LIMIT = 3

interface FilterDisplayProps extends HTMLAttributes<HTMLDivElement> {
  filter: Filter
  onRemove: (filter: FilterUrlAccessor) => void
  onMultiValueRemove?: (filter: FilterUrlAccessor, value: unknown) => void
  onChange: (value: unknown) => void
}

export function FilterDisplay({ filter, onRemove, onMultiValueRemove, onChange, ...props }: FilterDisplayProps) {
  const [displayValue, setDisplayValue] = useState<string | FilterValueOption[]>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { label, getOptions, getSelectedValueOptions, value, multi } = filter

  const operator = (() => {
    switch ((value as DateTimeFilterValue)?.op) {
      case 'lt':
        return '<'
      case 'gt':
        return '>'
      default:
        return '='
    }
  })()

  useEffect(() => {
    if (getSelectedValueOptions) {
      getSelectedValueOptions(value).then(options => setDisplayValue(options.length === 1 ? options[0].label : options))
    } else {
      setDisplayValue(value as string)
    }

    if (filter.dataType === 'boolean') {
      setDisplayValue(value ? 'Yes' : 'No')
    }

    if (filter.dataType === 'datetime') {
      setDisplayValue(moment((value as DateTimeFilterValue).v).format('MMM D, YYYY'))
    }
  }, [value, multi, getOptions, getSelectedValueOptions, filter.dataType])

  return (
    <>
      <FilterChip {...props}>
        {label} {operator}
        {!isArray(displayValue) && (
          <button onClick={e => setAnchorEl(e.currentTarget)} className="value-button">
            {displayValue as string}
          </button>
        )}
        {isArray(displayValue) &&
          take(displayValue, MULTI_VALUE_DISPLAY_LIMIT).map(option => (
            <button
              className="value-button v-align"
              key={option.value as string}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              {option.label}
              <IconButton
                variant="dark"
                className="remove-button ml-xs"
                small
                tooltip="Remove filter"
                onClick={e => {
                  e.stopPropagation()
                  if (onMultiValueRemove) {
                    onMultiValueRemove(filter, option.value)
                  }
                }}
              >
                <IconClose size={9} />
              </IconButton>
            </button>
          ))}
        {isArray(displayValue) && displayValue.length > MULTI_VALUE_DISPLAY_LIMIT && (
          <button className="value-button v-align" onClick={e => setAnchorEl(e.currentTarget)}>
            + {displayValue.length - MULTI_VALUE_DISPLAY_LIMIT}
          </button>
        )}
        <IconButton
          variant="dark"
          className="remove-button ml-xs"
          small
          tooltip="Remove filter"
          onClick={() => onRemove(filter)}
        >
          <IconClose size={14} />
        </IconButton>
      </FilterChip>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
      >
        <FilterValueSelector
          initial={false}
          filter={filter}
          onChange={value => {
            setAnchorEl(null)
            onChange(value)
            if (filter.onChange) {
              filter.onChange(value)
            }
          }}
        />
      </Menu>
    </>
  )
}

const FilterChip = styled(makeTypographyComponent('v-align no-flex-wrap py-xs px-1 gap-1', 'div'))`
  color: ${white.hex};
  background-color: ${tealSet[50].hex};
  border-radius: var(--border-radius-sm);

  .value-button {
    background-color: ${tealSet.tint[40]};
    padding: 0 4px;
    border-radius: var(--border-radius-sm);
    transition: background-color 0.3s;
    &:hover {
      background-color: ${tealSet.tint[60]};
    }
    &:focus-visible {
      outline: none;
    }
  }

  .remove-button {
    height: 14px;
    width: 14px;
  }
`
