import { styled } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'

import { useMarkPatientAccountActiveStatusMutation, usePatientQuery } from '@nuna/api'
import { Confirm, FillButton, OutlineButton, toast } from '@nuna/tunic'

interface Props {
  patientId: string
}

export function PatientStatus({ patientId }: Props) {
  const [activateConfirmIsOpen, setActivateConfirmIsOpen] = useState(false)
  const [markPatientAccountStatusMutation] = useMarkPatientAccountActiveStatusMutation({ refetchQueries: ['Patient'] })
  const { loading, error, data } = usePatientQuery({
    variables: {
      id: patientId,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (loading || !data) {
    return (
      <section>
        <Center>
          <CircularProgress />
        </Center>
      </section>
    )
  }

  const { patient } = data
  const active = patient.login.active ?? true

  const caption = active
    ? 'Deactivating will prevent this client from logging in'
    : 'Activating will allow this client to log in'

  const handleSetActiveStatus = async () => {
    setActivateConfirmIsOpen(false)
    await markPatientAccountStatusMutation({ variables: { patientId, status: !active } })

    const message = patient.login.active ? 'Client deactivated' : 'Client activated'

    toast.info(message)
  }

  return (
    <section>
      <h3 className="h5">Client Account Status</h3>
      <p className="mb-2 text-secondary">{caption}</p>
      {error && (
        <Center>
          <p>Error occurred loading client data</p>
        </Center>
      )}
      {patient && (
        <OutlineButton
          variant={active ? 'destroy' : 'primary'}
          onClick={() => (active ? setActivateConfirmIsOpen(true) : handleSetActiveStatus())}
        >
          {active ? 'Deactivate' : 'Activate'}
        </OutlineButton>
      )}
      <Confirm
        confirmButton={
          <FillButton onClick={handleSetActiveStatus} isLoading={false}>
            {active ? 'Deactivate' : 'Activate'}
          </FillButton>
        }
        isOpen={activateConfirmIsOpen}
        onConfirm={response => (response ? handleSetActiveStatus() : setActivateConfirmIsOpen(false))}
      >
        Are you sure you want to deactivate this client?
      </Confirm>
    </section>
  )
}

const Center = styled('div')`
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
