import { styled } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import { InsurancePayerPlanSelect, PayerPlanOption } from '@nuna/coverage'
import { DateRangePicker, IconFilter, PageContent, PageHeader, PageHeading, PageWrapper, Switch } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { AvailabilityTable } from './AvailabilityTable'

export function Availability() {
  const [range, setRange] = useState({
    fromDate: moment().add(1, 'weeks').startOf('week'),
    toDate: moment().add(2, 'weeks').endOf('week'),
  })

  const [insurancePayerPlan, setInsurancePayerPlan] = useState<PayerPlanOption | null>(null)

  const [hideNotAcceptingPatients, setHideNotAcceptingPatients] = useState(false)

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader withBottomMargin border={false}>
          <div className="v-align">
            <PageHeading className="mb-0" withDivider>
              Provider Availability
            </PageHeading>
            <DateRangePicker value={range} onChange={setRange} />
            <PlanWrapper className="v-align ml-2">
              <InsurancePayerPlanSelect
                value={insurancePayerPlan}
                className="plan-select"
                onChange={plan => setInsurancePayerPlan(plan)}
              />
            </PlanWrapper>
          </div>

          <div className="v-align text-secondary">
            <IconFilter className="mr-1" />
            <Switch
              labelPosition="left"
              checked={hideNotAcceptingPatients}
              onChange={() => setHideNotAcceptingPatients(!hideNotAcceptingPatients)}
            >
              Accepting new clients
            </Switch>
          </div>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <AvailabilityTable
            from={range.fromDate}
            to={range.toDate}
            hideNotAcceptingPatients={hideNotAcceptingPatients}
            insurancePayerPlanId={insurancePayerPlan?.id}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

const PlanWrapper = styled('div')`
  .plan-select {
    margin-bottom: var(--margin-2);
    min-width: 200px;
  }
`
