import { useEffect } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { useSimpleProviderQuery } from '@nuna/api'
import { ClientTable } from '@nuna/client'
import { AuthorizedAvatar, useTimeZoneContext } from '@nuna/common'
import { FeatureFlagCheck } from '@nuna/feature-flag'
import { Credentialing, InsuranceAccepted, Licenses, Preferences } from '@nuna/provider'
import {
  IconButtonExternalLink,
  IconMailDeprecated,
  MenuItem,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  ResponsiveHorizontalMenu,
} from '@nuna/tunic'

import { AdminNotesBadge } from '../../../shared/AdminNotes/AdminNotesBadge'
import { ProviderStatusLabel } from '../ProviderStatusLabel'
import { AdminProviderAvailability } from './AdminProviderAvailability'
import { AdminProviderProfile } from './AdminProviderProfile'
import { AdminProviderSchedule } from './AdminProviderSchedule'
import { ProviderActivity } from './ProviderActivity'
import { ProviderDocuments } from './ProviderDocuments/ProviderDocuments'
import { ProviderEnrollmentData } from './ProviderEnrollmentData'
import { ProviderInsuranceEnrollments } from './ProviderInsuranceEnrollments'
import { ProviderSettings } from './ProviderSettings'

type PathParams = {
  id: string
}

export function ProviderDetail() {
  const { setCustomTimezone, addUserInContext, removeUserInContext, timeZoneToUse } = useTimeZoneContext()
  const { id = '' } = useParams<PathParams>()

  const { error, data } = useSimpleProviderQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setCustomTimezone(data?.provider.timezone || null)
    addUserInContext(data?.provider)
    return () => {
      setCustomTimezone(null)
      removeUserInContext('Provider')
    }
  }, [data?.provider, setCustomTimezone, removeUserInContext, addUserInContext])

  if (error || !data) {
    return null
  }

  const { firstName, lastName, email, status, endDate, loginId } = data.provider

  return (
    <PageWrapper>
      <PageHeader>
        <div className="v-align flex-remaining-space" style={{ overflow: 'hidden' }}>
          <PageHeading withDivider className="mb-0 mr-2 v-align">
            <AuthorizedAvatar url={data.provider.avatarUrl || ''} size="xs" />
            <span className="h5 mb-0 ml-1" style={{ lineHeight: '3rem' }}>
              {firstName} {lastName}
            </span>
          </PageHeading>
          <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
            <MenuItem to={`schedule`}>Schedule</MenuItem>
            <MenuItem to={`clients`}>Clients</MenuItem>
            <FeatureFlagCheck featureType={'component'} flags={['credentialing']}>
              <MenuItem to={`credentialing`}>Credentialing</MenuItem>
            </FeatureFlagCheck>
            <MenuItem to={`availability`}>Availability</MenuItem>
            <MenuItem to={`profile`}>Profile</MenuItem>
            <MenuItem to={`preferences`}>Preferences</MenuItem>
            <MenuItem to={`licensure`}>Licenses</MenuItem>
            <MenuItem to={`insurance`}>Insurance</MenuItem>
            <MenuItem to={`insurance-enrollments`}>Insurance Enrollments</MenuItem>
            <MenuItem to={`documents`}>Documents</MenuItem>
            <MenuItem to={`account`}>Account</MenuItem>
            <MenuItem to={`activity`}>Activity</MenuItem>
            <MenuItem to={`enrollment-data`}>Enrollment Data</MenuItem>
          </ResponsiveHorizontalMenu>
        </div>

        <AdminNotesBadge userId={loginId} className="mr-2" />

        <div className="ml-auto v-align">
          <ProviderStatusLabel status={status} endDate={endDate} className="mr-2" />

          <IconButtonExternalLink tooltip="Email provider" variant="secondary" href={`mailto:${email}`}>
            <IconMailDeprecated />
          </IconButtonExternalLink>
        </div>
      </PageHeader>
      <PageContent $paddingTop={false} className="mt-2">
        <Routes>
          <Route index element={<Navigate to="schedule" replace />} />
          {/* TODO - react-router no longer supports optional params (e.g. /some-path/:optionalParam?). We used an optional
            param for the schedule to load an appointment into the side drawer without navigating away from the schedule
            before upgrading to react-router v6. Defining two distinct routes under schedule is one new approach to handle this.
            However, this results in AdminProviderSchedule flickering when an appointment is opened and closed because
            the entire thing is rendered every time. We didn't have the flicker before with optional params. Another approach
            would be to nest the appointment slug route in the schedule. That would prevent AdminProviderSchedule from re-rendering */}
          <Route path="schedule" element={<AdminProviderSchedule providerId={id} timezone={timeZoneToUse} />}>
            <Route index element={<AdminProviderSchedule providerId={id} timezone={timeZoneToUse} />} />
            <Route path=":appointmentId" element={<AdminProviderSchedule providerId={id} timezone={timeZoneToUse} />} />
          </Route>

          <Route
            path="clients"
            element={
              <ClientTable
                providerId={id}
                onNavigate={row => `/clients/${row.id}`}
                columns={['name', 'eap company', 'insurer', 'joined', 'state', 'remove']}
              />
            }
          />
          <Route
            path="/credentialing/*"
            element={
              <FeatureFlagCheck flags={['credentialing']}>
                <Credentialing providerId={id} />
              </FeatureFlagCheck>
            }
          />
          <Route path="availability" element={<AdminProviderAvailability providerId={id} />} />
          <Route
            path="profile"
            element={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AdminProviderProfile providerId={id} />
              </div>
            }
          />
          <Route path="licensure" element={<Licenses providerId={id} />} />
          <Route path="insurance" element={<InsuranceAccepted providerId={id} />} />
          <Route path="insurance-enrollments" element={<ProviderInsuranceEnrollments providerId={id} />} />
          <Route
            path="preferences/*"
            element={
              <div className="p-2">
                <Preferences providerId={id} providerLoginId={loginId} />
              </div>
            }
          />
          <Route path="account" element={<ProviderSettings providerId={id} loginId={loginId} />} />
          <Route path="settings" element={<Navigate to="../account" replace />} />
          <Route path="activity" element={<ProviderActivity providerId={id} />} />
          <Route
            path="enrollment-data"
            element={<ProviderEnrollmentData providerId={id} loginId={data?.provider.loginId} />}
          />
          <Route path="documents" element={<ProviderDocuments loginId={data?.provider.loginId} providerId={id} />} />
        </Routes>
      </PageContent>
    </PageWrapper>
  )
}
