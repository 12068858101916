import { HTMLAttributes } from 'react'

import { Switch } from '@nuna/tunic'

import { useUrlFilters } from '../../../../shared/filters/hooks/useUrlFilters'

export interface ExpandAllRowsFilter {
  expanded: boolean
}

interface ExpandAllRowsSwitchProps extends HTMLAttributes<HTMLDivElement> {
  urlFilterService: ReturnType<typeof useUrlFilters<ExpandAllRowsFilter>>
}

export function ExpandAllRowsSwitch({ urlFilterService }: ExpandAllRowsSwitchProps) {
  const { filterValues, setFilter } = urlFilterService

  return (
    <div>
      <Switch checked={filterValues.expanded} onChange={() => setFilter('expanded', !filterValues.expanded)}>
        Expand all rows
      </Switch>
    </div>
  )
}
