import { AgGridEvent, ColDef, ICellRendererParams, ITooltipParams, ValueFormatterParams } from 'ag-grid-community'
import { startCase } from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'

import {
  Login,
  ProviderEnrollmentFragment,
  ProviderEnrollmentStatus,
  useSaveProviderEnrollmentMutation,
} from '@nuna/api'
import { UserLink } from '@nuna/common'
import { errorService, routeService } from '@nuna/core'
import { Intent, StatusLabel, Switch, toast } from '@nuna/tunic'

import { AssigneePicker } from '../../../../../shared/Tasks/AssigneePicker'
import { ProviderEnrollmentTableActionsMenu } from './ProviderEnrollmentTableActionsMenu'

export type ProviderEnrollmentTableColumnKeys =
  | 'provider'
  | 'payer'
  | 'effectiveStatus'
  | 'payerStatus'
  | 'submittedDate'
  | 'effectiveDate'
  | 'archivedDate'
  | 'disableBilling'
  | 'ignoreEnrollment'
  | 'assignee'

export type ProviderEnrollmentTableRow = ProviderEnrollmentFragment & {
  assignee?: Pick<Login, 'id' | 'firstName' | 'lastName'> | null
  taskId?: string
}

interface CustomCellRendererParams extends ICellRendererParams {
  columnKey: ProviderEnrollmentTableColumnKeys
}

export type EnrollmentDataDialogOpenEvent = AgGridEvent & {
  providerId: string
}

export const ENROLLMENT_DATA_EVENT = 'openEnrollmentData'

const FIELD_KEYS: Record<ProviderEnrollmentTableColumnKeys, keyof ProviderEnrollmentTableRow> = {
  provider: 'provider',
  payer: 'insurancePayer',
  effectiveStatus: 'status',
  payerStatus: 'payerStatus',
  submittedDate: 'submittedDate',
  effectiveDate: 'effectiveDate',
  archivedDate: 'archivedDate',
  disableBilling: 'disableBilling',
  ignoreEnrollment: 'active',
  assignee: 'assignee',
}

const COLUMN_HEADERS: Record<ProviderEnrollmentTableColumnKeys, string> = {
  payer: 'Payer',
  provider: 'Provider',
  payerStatus: 'Payer Status',
  effectiveStatus: 'Effective Status',
  assignee: 'Assignee',
  submittedDate: 'Submitted Date',
  effectiveDate: 'Effective Date',
  archivedDate: 'Archived Date',
  disableBilling: 'Disable Billing',
  ignoreEnrollment: 'Ignore Enrollment',
}

const CELL_RENDERERS: Partial<
  Record<ProviderEnrollmentTableColumnKeys, ((params: CustomCellRendererParams) => JSX.Element) | undefined>
> = {
  payer: PayerCellRenderer,
  provider: ProviderCellRenderer,
  payerStatus: EnrollmentStatusCellRenderer,
  effectiveStatus: EnrollmentStatusCellRenderer,
  disableBilling: SwitchCell,
  ignoreEnrollment: SwitchCell,
  assignee: AssigneeColumnRenderer,
}

const VALUE_FORMATTERS: Partial<Record<ProviderEnrollmentTableColumnKeys, (params: ValueFormatterParams) => string>> = {
  submittedDate: dateFormatter,
  effectiveDate: dateFormatter,
  archivedDate: dateFormatter,
}

const TOOLTIP_VALUE_GETTERS: Partial<Record<ProviderEnrollmentTableColumnKeys, (params: ITooltipParams) => unknown>> = {
  provider: ({ data }) => data.provider.firstName + ' ' + data.provider.lastName,
  payer: ({ data }) => data.insurancePayer.name,
  payerStatus: ({ data }) => startCase(data.payerStatus),
  effectiveStatus: ({ data }) => startCase(data.status),
}

export const PROVIDER_ENROLLMENT_ASSIGNEE_EVENT = 'assigneeEvent'

export const DEFAULT_PROVIDER_ENROLLMENT_TABLE_COL_DEF: ColDef = {
  resizable: true,
}

export function buildColumns(columnKeys: ProviderEnrollmentTableColumnKeys[]): ColDef[] {
  return columnKeys.map((key, idx) => {
    let colDef: ColDef = {}
    if (idx === 0) {
      colDef = {
        field: FIELD_KEYS[key],
        headerName: COLUMN_HEADERS[key],
        cellRendererParams: { columnKey: key },
        cellRenderer: FirstColumn,
        valueFormatter: VALUE_FORMATTERS[key],
        checkboxSelection: true,
        minWidth: 300,
        tooltipValueGetter: TOOLTIP_VALUE_GETTERS[key],
        pinned: 'left',
      }
    } else {
      colDef = {
        field: FIELD_KEYS[key],
        headerName: COLUMN_HEADERS[key],
        cellRendererParams: { columnKey: key },
        cellRenderer: CELL_RENDERERS[key],
        valueFormatter: VALUE_FORMATTERS[key],
        tooltipValueGetter: TOOLTIP_VALUE_GETTERS[key],
      }
    }

    if (!colDef.tooltipValueGetter) {
      colDef.tooltipField = FIELD_KEYS[key]
    }

    return colDef
  })
}

function FirstColumn(params: CustomCellRendererParams) {
  const { data, node, api, valueFormatted } = params
  const CellRenderer = CELL_RENDERERS[params.columnKey]
  return (
    <div className="v-align row-header-column">
      <span className="flex-remaining-space" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {CellRenderer ? <CellRenderer {...params} /> : valueFormatted}
      </span>

      <ProviderEnrollmentTableActionsMenu
        status={data.payerStatus}
        id={data.id}
        onUpdated={addressEnrollment => node.setData({ ...data, ...addressEnrollment })}
        // @ts-expect-error - Ag Grid didn't extend event type to allow for custom data
        onOpenEnrollmentData={() => api.dispatchEvent({ type: ENROLLMENT_DATA_EVENT, providerId: data.provider.id })}
      />
    </div>
  )
}

function PayerCellRenderer({ value }: ICellRendererParams) {
  return <Link to={routeService.insurancePayer(value.id)}>{value.name}</Link>
}

function ProviderCellRenderer({ value }: ICellRendererParams) {
  return <UserLink user={value} customLink={`/providers/${value.id}`} />
}

function SwitchCell({ value: inputValue, columnKey, data, node }: CustomCellRendererParams) {
  const value = columnKey === 'ignoreEnrollment' ? !inputValue : inputValue
  const saveKey = columnKey === 'ignoreEnrollment' ? 'active' : 'disableBilling'
  const [saveEnrollment] = useSaveProviderEnrollmentMutation()

  const handleChange = async () => {
    try {
      await saveEnrollment({ variables: { enrollment: { id: data.id, [saveKey]: !inputValue } } })
      node.setData({ ...data, [saveKey]: !inputValue })
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to save provider enrollment'))
    }
  }
  return (
    <div className="full-height v-align">
      <Switch checked={value} onChange={handleChange} />
    </div>
  )
}

function EnrollmentStatusCellRenderer({ value }: ICellRendererParams) {
  let intent: Intent = 'default'

  switch (value) {
    case ProviderEnrollmentStatus.Denied:
      intent = 'urgent'
      break
    case ProviderEnrollmentStatus.Enrolled:
      intent = 'information'
  }
  return <StatusLabel intent={intent}>{startCase(value)}</StatusLabel>
}

function AssigneeColumnRenderer({ value, data, api }: ICellRendererParams) {
  return (
    <AssigneePicker
      style={{ width: 200 }}
      value={value}
      onChange={assigneeId => {
        api.dispatchEvent({
          type: PROVIDER_ENROLLMENT_ASSIGNEE_EVENT,
          // @ts-expect-error - Ag Grid didn't extend event type to allow for custom data
          providerEnrollmentId: data.id,
          assigneeLoginId: assigneeId,
          insuranceTaskId: data.taskId,
          api,
        })
      }}
    />
  )
}

function dateFormatter(params: ValueFormatterParams) {
  if (!params.value) return ''
  return moment(params.value).format('ll')
}
