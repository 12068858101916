import { LicenseCompensationTier } from '@nuna/api'

import { ProviderDefaultCompensationTable as ProviderDefaultCompensationTableWithContext } from './ProviderDefaultCompensationTable'
import { ProviderDefaultCompensationTableContextProvider } from './ProviderDefaultCompensationTableContextProvider'

interface Props {
  tier: LicenseCompensationTier
}

export function ProviderDefaultCompensationTable({ tier }: Props) {
  return (
    <ProviderDefaultCompensationTableContextProvider>
      <ProviderDefaultCompensationTableWithContext tier={tier} />
    </ProviderDefaultCompensationTableContextProvider>
  )
}
