import { styled } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'

import { PayerRosterDefinitionQuery } from '@nuna/api'
import { MenuItem, ResponsiveHorizontalMenu, borderGrey, greySet, tealSet } from '@nuna/tunic'

import { SheetTable } from './SheetTable'

interface CurrentPayerRosterProps {
  rosterDefinition?: PayerRosterDefinitionQuery['payerRosterDefinition'] | null
}
export function CurrentPayerRosterPreview({ rosterDefinition }: CurrentPayerRosterProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const sheets = useMemo(() => rosterDefinition?.config?.sheets ?? [], [rosterDefinition])

  useEffect(() => {
    if (!params['*'] && sheets.length) {
      navigate(sheets[0].key, { replace: true })
    }
  }, [sheets, location, params, navigate])

  if (!rosterDefinition) return null

  return (
    <Container className="flex-column flex-remaining-space">
      <ResponsiveHorizontalMenu className="tabs">
        {sheets.map(sheet => (
          <MenuItem to={sheet.key} key={sheet.key}>
            {sheet.name}
          </MenuItem>
        ))}
      </ResponsiveHorizontalMenu>
      <Routes>
        <Route path=":sheetKey" element={<SheetTable rosterDefinition={rosterDefinition} />} />
      </Routes>
    </Container>
  )
}

const Container = styled('div')`
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius-sm);
  .tabs {
    .horizontal-menu {
      padding: 0;
      li {
        padding: 0;
        a {
          border-radius: 0;
        }
      }
      border-bottom: 1px solid ${borderGrey};
      width: 100%;

      .menu-item {
        &.active {
          background-color: ${tealSet[30].transparency(0.35)};
        }

        &.focus-visible {
          box-shadow: 0 0 0 4px ${tealSet.tint[40]};
        }

        &:hover {
          background-color: ${tealSet[50].transparency(0.32)};
          color: ${greySet[80].hex};
        }
      }
    }
  }
`
