import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { startCase } from 'lodash'
import moment from 'moment'

import { AppointmentCurrentStatus } from '@nuna/api'
import { Link, UserLink } from '@nuna/common'
import { StatusLabel } from '@nuna/tunic'

export const APPOINTMENT_TABLE_DEFAULT_COL_DEF: ColDef = {
  resizable: true,
}

export const APPOINTMENT_TABLE_COL_DEFS: ColDef[] = [
  {
    field: 'startDatetime',
    headerName: 'Start Time',
    cellRenderer: StartDatetimeCellRenderer,
    sortable: true,
  },
  { field: 'provider', headerName: 'Provider', cellRenderer: UserCellRenderer },
  { field: 'patient', headerName: 'Client', cellRenderer: UserCellRenderer },
  { field: 'currentStatus', headerName: 'Status', cellRenderer: StatusCellRenderer },
  { field: 'source', headerName: 'Source', valueFormatter: startCaseValueFormatter },
  { field: 'whoIsResponsibleForPayment', headerName: 'Responsible Party', valueFormatter: startCaseValueFormatter },
  { field: 'financialAttribution', headerName: 'Financial Attribution', valueFormatter: startCaseValueFormatter },
]

function UserCellRenderer({ value }: ICellRendererParams) {
  return <UserLink user={value} />
}

function startCaseValueFormatter(params: ValueFormatterParams) {
  return startCase(params.value)
}

function StartDatetimeCellRenderer({ value, data }: ICellRendererParams) {
  const formatted = moment(value).format('ll hh:mm A')

  return <Link to={`../../${data.id}`}>{formatted}</Link>
}

function StatusCellRenderer({ value, data }: ICellRendererParams) {
  const status = (() => {
    if (data.session) return 'Note Completed'

    if (
      !data.session?.note &&
      moment(data.startDatetime).isBefore(moment()) &&
      value === AppointmentCurrentStatus.Active
    )
      return 'Needs Note'

    return value
  })()
  return <StatusLabel>{startCase(status)}</StatusLabel>
}
