import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Autocomplete, TextField as MUITextField } from '@mui/material'
import { FormikProps } from 'formik'
import { HTMLAttributes } from 'react'

import { DocumentType, useDocumentUpload } from '@nuna/api'
import { formService } from '@nuna/core'
import { Checkbox, Chip, PictureField, TextField, greySet, toast } from '@nuna/tunic'

import { EntityFormSection } from '../../shared/EntityForm'

const { composeHelperTextWithError } = formService

export interface CompanyFormValues {
  name: string
  supportsHrisIntegration: boolean
  shouldSyncHris: boolean
  shouldRunDeEligiblization: boolean
  insurancePolicyGroups: string[]
  isReferralIdRequired: boolean
}

export const companyFieldsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  supportsHrisIntegration: Yup.boolean().required('Required'),
  shouldSyncHris: Yup.boolean().required('Required'),
  shouldRunDeEligiblization: Yup.boolean().required('Required'),
  insurancePolicyGroups: Yup.array().of(Yup.string().required('Required')),
  isReferralIdRequired: Yup.boolean().required('Required'),
})

export const INITIAL_COMPANY_FORM_VALUES = {
  name: '',
  logoDocumentId: undefined,
  supportsHrisIntegration: true,
  shouldSyncHris: true,
  shouldRunDeEligiblization: true,
  insurancePolicyGroups: [],
  isReferralIdRequired: false,
}

interface Props<T> extends FormikProps<CompanyFormValues & T> {
  isEditForm?: boolean
}

export function CompanyFormFields<T extends object>({
  className,
  style,
  ...formProps
}: Props<T> & HTMLAttributes<HTMLDivElement>) {
  const { uploadDocument } = useDocumentUpload()
  const { values, touched, setFieldValue, handleChange, handleBlur, errors } = formProps

  const uploadCompanyLogo = async (file: File) => {
    try {
      const document = await uploadDocument(file, DocumentType.CompanyLogo)

      setFieldValue('logoDocumentId', document.id)
    } catch (e) {
      toast.caution((e as Error).message)
    }
  }

  return (
    <div className={className} style={style}>
      <EntityFormSection>
        <TextField
          required
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          {...composeHelperTextWithError('', errors.name, !!touched.name)}
        />
      </EntityFormSection>
      <EntityFormSection>
        <FormLabel htmlFor="logoDocumentId">Logo</FormLabel>
        <PictureField
          accept="image/png"
          maxSize={1000000}
          purpose="logo"
          onImageAdd={image => image && uploadCompanyLogo(image)}
          captionText="Image must be .png format and less than 1 MB"
        ></PictureField>
      </EntityFormSection>
      <Checkbox
        className="mb-2"
        checked={values.supportsHrisIntegration}
        name="supportsHrisIntegration"
        onChange={ev => setFieldValue('supportsHrisIntegration', ev.target.checked)}
      >
        Sync using HRIS
      </Checkbox>
      <Checkbox
        className="mb-2"
        checked={values.shouldRunDeEligiblization}
        name="shouldRunDeEligiblization"
        onChange={ev => setFieldValue('shouldRunDeEligiblization', ev.target.checked)}
      >
        Check patients eligibility monthly
      </Checkbox>
      <Checkbox
        className="mb-2"
        checked={values.isReferralIdRequired}
        name="isReferralIdRequired"
        onChange={ev => setFieldValue('isReferralIdRequired', ev.target.checked)}
      >
        Require client to sign up with referral partner case ID
      </Checkbox>
      <Autocomplete
        multiple
        value={values.insurancePolicyGroups}
        id="insurance-policy-groups"
        options={[] as string[]}
        freeSolo
        onChange={(_event, value) => setFieldValue('insurancePolicyGroups', value)}
        renderTags={(value: string[]) => (
          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
            {value.map((option: string) => (
              <Chip
                small
                key={option}
                style={{ marginRight: 4 }}
                label={option}
                onRemove={() =>
                  setFieldValue(
                    'insurancePolicyGroups',
                    values.insurancePolicyGroups.filter(v => v !== option),
                  )
                }
              >
                {option}
              </Chip>
            ))}
          </div>
        )}
        renderInput={params => <MUITextField {...params} variant="filled" label="Insurance Policy Groups" />}
      />
    </div>
  )
}

const FormLabel = styled('label')`
  margin-bottom: 0.4rem;
  display: block;
  color: ${greySet.primary.hex};
`
