import { Autocomplete, AutocompleteProps, TextField as MUITextField, TextFieldProps, styled } from '@mui/material'
import { isString, uniqBy } from 'lodash'
import { useMemo, useState } from 'react'

import { Provider, useSearchProvidersQuery } from '@nuna/api'
import { Avatar, makeTypographyComponent } from '@nuna/tunic'

export type ProviderOption = Pick<Provider, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<ProviderOption, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

export interface EnrollmentProviderSelectProps extends LimitedAutocompleteProps {
  value?: ProviderOption | null | string
  onChange: (payerPlan: ProviderOption | null) => void
  displayPayerName?: boolean
  placeholder?: TextFieldProps['placeholder']
}

export function FormProviderSelect({
  value,
  placeholder = 'Filter by provider',
  onChange,
  ...props
}: EnrollmentProviderSelectProps) {
  const [searchValue, setSearchValue] = useState('')
  const [cachedOptions, setCachedOptions] = useState<ProviderOption[]>([])

  const { data, loading } = useSearchProvidersQuery({
    variables: {
      pagination: { limit: 100 },
      filters: { search: { term: searchValue }, id: isString(value) ? value : value?.id },
    },
    context: { debounceKey: 'enrollment-provider-select', debounceTimeout: 500 },
  })

  const options: ProviderOption[] = useMemo(() => data?.searchProviders.items ?? [], [data])

  const valueAsOption = useMemo(() => {
    if (isString(value)) {
      return [...options, ...cachedOptions].find(provider => provider.id === value) ?? null
    }

    return value
  }, [options, value, cachedOptions])

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => getOptionLabel(option)}
      loading={loading}
      onChange={(_event, provider) => {
        onChange(provider)
        // cache a selected option so we can use it to match on the value, otherwise the value as an option might
        // be nullified when new results for options are returned
        setCachedOptions(options => (provider ? uniqBy([...options, provider], p => p.id) : options))
        setSearchValue('')
      }}
      renderInput={params => (
        <MUITextField {...params} placeholder={placeholder} onChange={e => setSearchValue(e.currentTarget.value)} />
      )}
      value={valueAsOption}
      renderOption={(renderOptionProps, provider) => {
        return (
          <li {...renderOptionProps}>
            <div className="v-align">
              <Avatar className="mr-1" size="xs" url={provider.avatarUrl} />
              <Name>
                {provider.firstName} {provider.lastName}
              </Name>
            </div>
          </li>
        )
      }}
      {...props}
    />
  )
}

function getOptionLabel(option: ProviderOption) {
  return `${option.firstName} ${option.lastName}`
}

const Name = styled(makeTypographyComponent('text-medium text-secondary', 'span'))``
