import { HTMLAttributes } from 'react'

import { ProviderFilters } from './ProviderFilters'
import { useProviderListContext } from './ProviderListContextProvider'

interface Props extends HTMLAttributes<HTMLDivElement> {
  orientation?: 'horizontal' | 'vertical'
}

export function ProviderListFilters(props: Props) {
  const { filterValues, setFilterVal, clearFilters } = useProviderListContext()

  return (
    <ProviderFilters
      filtersToInclude={[
        'active',
        'activeCredentialsIn',
        'statuses',
        'credentialStates',
        'gender',
        'specialties',
        'insurancePayerPlanId',
        'credentialingStatuses',
      ]}
      filterValues={filterValues}
      setFilterVal={setFilterVal}
      clearFilters={clearFilters}
      {...props}
    />
  )
}
