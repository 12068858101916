import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Card, csx } from '@nuna/tunic'

import { CancelPolicyRates } from './components/CancelPolicyRates'
import { SelfReferralRates } from './components/SelfReferralRates'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string
}

export function ProviderFeesCard({ providerId, className, ...props }: Props) {
  return (
    <StyledCard className={csx([className, 'px-2 py-3'])} depth={-0.5} {...props}>
      <SelfReferralRates providerId={providerId} />
      <CancelPolicyRates providerId={providerId} className="mt-3" />
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  width: 272px;
  div {
    div + div {
      margin-top: var(--spacing-1);
    }
  }
`
