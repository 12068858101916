import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { usePartnerQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import {
  CenteredHeader,
  MenuItem,
  MenuItemStack,
  OutlineButtonLink,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  Skeleton,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { PartnerOverview } from './PartnerOverview'
import { UpdatePartner } from './UpdatePartner'
import { PartnerAdminForm } from './partner-admin/PartnerAdminForm'
import { PartnerAdminList } from './partner-admin/PartnerAdminList'

export function PartnerDetail() {
  const { id = '' } = useParams<{ id: string }>()
  const isAdmin = useIsAdmin()

  const variables = { id }

  const { data, loading } = usePartnerQuery({ variables })
  const { partner } = data || {}
  const { name } = partner || {}

  if (loading || !data || !partner) {
    return (
      <PageWrapper>
        <PageHeader>
          <PageHeading className="mb-0 loading">Loading Partner Detail</PageHeading>
        </PageHeader>

        <PageContent>
          <Skeleton width={100} height={20} dimensionUnits="%" />
        </PageContent>
      </PageWrapper>
    )
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <CenteredHeader className="v-align">
            <div className="centered-header-left">
              <PageHeading className="mb-0 ml-1" style={{ lineHeight: '3rem' }}>
                {name}
              </PageHeading>
            </div>
            <MenuItemStack component="nav" direction="row" className="centered-header-center">
              <MenuItem to={`overview`}>Overview</MenuItem>
              <MenuItem to={`admins`}>Partner Admins</MenuItem>
            </MenuItemStack>
            <div className="centered-header-right v-align">
              {isAdmin && (
                <Routes>
                  <Route
                    path="overview"
                    element={<OutlineButtonLink to={`/partners/${id}/update`}>Update Partner</OutlineButtonLink>}
                  />
                </Routes>
              )}
            </div>
          </CenteredHeader>
        </PageHeader>
        <PageContent>
          {isAdmin && (
            <Routes>
              <Route index element={<Navigate to="overview" replace />} />
              <Route path="overview" element={<PartnerOverview partner={partner} />} />
              <Route path="update" element={<UpdatePartner />} />
              <Route path="admins" element={<PartnerAdminList />} />
              <Route path="admins/:adminId/update" element={<PartnerAdminForm />} />
              <Route path="admins/new" element={<PartnerAdminForm />} />
            </Routes>
          )}
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
