import { styled } from '@mui/material'
import { useState } from 'react'

import { EventLogItem } from '@nuna/api'
import { DataTableRowComponentProps } from '@nuna/data-table'
import { borderGrey, csx, greySet } from '@nuna/tunic'

interface Props {
  colSpan: number
}

export function EventLogRow({ row, colSpan, className, ...props }: Props & DataTableRowComponentProps<EventLogItem>) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExtended = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <LogRow onClick={toggleExtended} className={csx([{ 'expanded-header': isExpanded }, className])} {...props}>
        {row.cells.map(cell => (
          <td key={row.original.id + cell.value} className="log-row-cell">
            {cell.render('Cell')}
          </td>
        ))}
      </LogRow>
      {isExpanded && (
        <LogRow className="expanded">
          <td colSpan={colSpan}>
            <CodeBlock>{JSON.stringify(row.original.ancillaryData, null, 2)}</CodeBlock>
          </td>
        </LogRow>
      )}
    </>
  )
}

const LogRow = styled('tr')`
  &.expanded-header td,
  &.expanded td {
    border-color: ${borderGrey};
    border-style: solid;
  }

  &.expanded-header {
    td {
      background-color: ${greySet[5].hex};
      border-width: 1px 0;

      &:first-of-type {
        border-left-width: 1px;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-right-width: 1px;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.expanded {
    cursor: default;
    td:last-child {
      background-color: #fff;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 6px 6px;
    }

    &:hover {
      background-color: transparent;
    }
  }
`

const CodeBlock = styled('pre')`
  background-color: ${greySet[5].hex};
  padding: var(--margin-2);
  border-radius: var(--border-radius);
  border: 1px solid ${borderGrey};
`
