/* eslint-disable react/jsx-no-useless-fragment */
import { Moment } from 'moment'
import { CSSProperties, useMemo } from 'react'
import { Row } from 'react-table'

import { useStateProviderAvailabilityListQuery } from '@nuna/api'
import { UserLink } from '@nuna/common'
import { type ProfileUser } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { eggshell } from '@nuna/tunic'

interface Props {
  from: Moment
  to: Moment
  insurancePayerPlanId?: string | null
  hideNotAcceptingPatients: boolean
}

interface AvailabilityRow {
  type: 'state' | 'provider'
  state?: string
  provider?: ProfileUser
  acceptingNewClients?: 'Yes' | 'No'
  bookings: number
  openings: number
  weekdayOpenings: number
  weekendOpenings: number
  bookedPercentage?: number
}

export function AvailabilityTable({ from, to, insurancePayerPlanId = '', hideNotAcceptingPatients }: Props) {
  const { loading, error, data } = useStateProviderAvailabilityListQuery({
    variables: {
      from: from.format(),
      to: to.format(),
      hideNotAcceptingPatients,
      acceptsInsurancePayerPlanId: insurancePayerPlanId,
    },
  })

  const columns = useMemo<DataTableColumn<AvailabilityRow>[]>(() => {
    return [
      { Header: 'State', accessor: 'state' },
      {
        Header: 'Provider',
        accessor: 'provider',
        Cell: ({ value, row }) => <>{row.original.type === 'provider' ? <UserLink user={value} /> : ''}</>,
      },
      {
        Header: 'Accepting new clients',
        accessor: 'acceptingNewClients',
      },
      {
        Header: 'Booked slots',
        accessor: 'bookings',
      },
      {
        Header: 'Unbooked slots',
        accessor: 'openings',
      },
      {
        Header: 'Weekday slots',
        accessor: 'weekdayOpenings',
      },
      {
        Header: 'Weekend slots',
        accessor: 'weekendOpenings',
      },
      {
        Header: 'Booked percentage',
        accessor: 'bookedPercentage',
        Cell: ({ value, row }) => <>{row.original.type === 'state' ? `${Math.round((value ?? 0) * 100)}%` : ''}</>,
      },
    ]
  }, [])

  const rowData = useMemo<AvailabilityRow[]>(() => {
    const rows: AvailabilityRow[] = []
    ;(data?.stateProviderAvailabilityList ?? []).forEach(item => {
      rows.push({
        type: 'state',
        state: item.state,
        openings: item.openings,
        bookings: item.bookings,
        weekdayOpenings: item.weekdayOpenings,
        weekendOpenings: item.weekendOpenings,
        bookedPercentage: item.bookingsToOpeningsRatio,
      })

      item.providers.forEach(({ provider, openings, bookings, weekdayOpenings, weekendOpenings }) => {
        rows.push({
          type: 'provider',
          provider: provider,
          acceptingNewClients: provider.internalOnly ? 'No' : 'Yes',
          openings,
          bookings,
          weekdayOpenings,
          weekendOpenings,
        })
      })
    })
    return rows
  }, [data])

  if (error) {
    return <div>Error</div>
  }

  const conditionalCellStyle = ({ original }: Row<AvailabilityRow>): CSSProperties => {
    return { backgroundColor: original.type === 'state' ? eggshell : '#fff' }
  }

  return (
    <DataTable
      columns={columns}
      rowData={rowData}
      loading={loading}
      disableSort
      customCellStyle={conditionalCellStyle}
    />
  )
}
