import { Editor, type EditorProps } from '@monaco-editor/react'
import { isNumber } from 'lodash'
import { useState } from 'react'

type CodeValue = string

type CodeProps = {
  code: CodeValue
  onCodeChange: (value: string) => void
  editorOptions?: { height?: number | string; codeLanguage?: string; codeTheme?: string }
  onCodeSave?: (value: string) => void
  codeIsSaving?: boolean
  codeOptions?: EditorProps['options']
}

export const CodeEditor = ({ code, editorOptions, codeOptions, onCodeChange }: CodeProps) => {
  const initialCode = code ?? ''
  const [updatedCode, setUpdatedCode] = useState<CodeValue>(initialCode)

  const handleEditorChange = (newCode: string | undefined) => {
    const formattedCode = newCode ?? ''
    onCodeChange(formattedCode)
    setUpdatedCode(formattedCode)
  }

  const mergedOptions = { minimap: { enabled: false }, fontSize: 12, ...codeOptions }

  const mergedEditorOptions = {
    height: 500,
    codeLanguage: 'javascript',
    codeTheme: 'vs-dark',
    ...editorOptions,
  }

  const height = isNumber(mergedEditorOptions.height) ? `${mergedEditorOptions.height}px` : mergedEditorOptions.height

  return (
    <Editor
      height={height}
      defaultLanguage={mergedEditorOptions.codeLanguage}
      theme={mergedEditorOptions.codeTheme}
      defaultValue={updatedCode}
      onChange={handleEditorChange}
      options={mergedOptions}
    />
  )
}
