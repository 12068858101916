import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { CustomerCompanyDocument, PatientDocument } from '@nuna/api'
import { SelectClient } from '@nuna/client'
import { useURLSearchParams } from '@nuna/common'

import { EventLogCompany } from './event-log.types'

export function useEventLogFiltersFromParams() {
  const params = useURLSearchParams()
  const client = useApolloClient()

  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState<EventLogCompany | null | undefined>()
  const [patient, setPatient] = useState<SelectClient | null | undefined>()

  useEffect(() => {
    const companyId = params.get('companyId') ?? ''
    const patientId = params.get('patientId') ?? ''

    const promises = []

    if (companyId) {
      promises.push(
        client
          .query<{ customerCompany: EventLogCompany }>({ query: CustomerCompanyDocument, variables: { id: companyId } })
          .then(result => {
            if (!result.error) {
              setCompany(result.data?.customerCompany)
            }
          })
          .catch(() => toast.error('Unable to find company specified in the URL.')),
      )
    }

    if (patientId) {
      promises.push(
        client
          .query<{ patient: SelectClient }>({ query: PatientDocument, variables: { id: patientId } })
          .then(result => {
            if (!result.error) {
              setPatient(result.data?.patient)
            }
          })
          .catch(() => toast.error('Unable to find client specified in the URL.')),
      )
    }

    Promise.all(promises).then(() => setLoading(false))
  }, [params, client])

  return { company, patient, urlSearchParams: params, loading }
}
