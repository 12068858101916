import moment, { Moment } from 'moment'
import { FormEvent, useState } from 'react'

import { DatePicker, GhostButton, Select } from '@nuna/tunic'

import { DateTimeFilterOperator, DateTimeFilterValue, Filter } from '../../../filters.types'

interface FilterDateValueSelectorProps {
  filter: Filter
  onChange: (value: unknown) => void
}

export function FilterDateValueSelector({ filter, onChange }: FilterDateValueSelectorProps) {
  const [date, setDate] = useState<Moment | null>(
    (filter.value as DateTimeFilterValue)?.v ? moment((filter.value as DateTimeFilterValue)?.v) : null,
  )

  const [operator, setOperator] = useState<DateTimeFilterOperator>((filter.value as DateTimeFilterValue)?.op ?? 'eq')

  const handleDateChange = (newValue: moment.Moment | null) => {
    setDate(newValue)
  }

  const handleOperatorChange = (event: FormEvent<HTMLSelectElement>) => {
    setOperator(event.currentTarget.value as DateTimeFilterOperator)
  }

  const handleSetFilter = () => {
    if (!date) {
      onChange(null)
    }

    onChange({
      op: operator,
      v: date?.toISOString(),
    })
  }

  return (
    <div className="bottom-align gap-4">
      <div className="v-align gap-3">
        <Select style={{ minWidth: 100 }} value={operator} label="Operator" onChange={handleOperatorChange}>
          <option value="eq">=</option>
          <option value="lt">{'<'}</option>
          <option value="eq">{'>'}</option>
        </Select>
        <DatePicker label="Date" value={date} onChange={handleDateChange} />
      </div>
      <div className="pb-1">
        <GhostButton onClick={handleSetFilter}>Ok</GhostButton>
      </div>
    </div>
  )
}
