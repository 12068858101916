/* eslint-disable no-console */
import * as Yup from 'yup'
import { Autocomplete, TextField as MUITextField } from '@mui/material'
import { Formik } from 'formik'
import { noop, omit, pick, sortBy } from 'lodash'
import { useMemo } from 'react'

import {
  EnterPayerPlan,
  FullInsurancePlanFragment,
  InsurancePayerPlanInput,
  InsuranceTaxEntityFragment,
  useInsuranceTaxEntitiesQuery,
  useSaveInsurancePlanMutation,
} from '@nuna/api'
import { errorService, formService, routeService } from '@nuna/core'
import {
  Chip,
  FillButton,
  Grid,
  OutlineButton,
  OutlineButtonLink,
  Skeleton,
  StateSelect,
  Switch,
  TextField,
  toast,
} from '@nuna/tunic'

import { EntityForm } from '../../shared/EntityForm'
import { EnterPayerSelect } from './EnterPayerSelect'

const { composeHelperText } = formService

interface FormValues extends Partial<Omit<InsurancePayerPlanInput, 'insurancePolicyGroups'>> {
  serviceModifiers: string[]
  insurancePolicyGroups: string[]
}

interface EnterPayerValues {
  [key: string]: string | undefined | null
}

const planSchema = Yup.object<FormValues>().shape({
  name: Yup.string().required('Name is required'),
})

const initialValues: FormValues = {
  active: false,
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  coverageDescriptionPattern: '',
  coverageDescriptionExclusion: '',
  name: '',
  phone: '',
  state: '',
  zipCode: '',
  enterHealthId: '',
  enterPayerPlans: [],
  serviceModifiers: [],
  insurancePolicyGroups: [],
  allowCouplesAndFamilyTherapy: false,
}

interface Props {
  payerId?: string
  plan?: FullInsurancePlanFragment
  onCancel?: () => void
  afterSave?: (planId: string | undefined) => void
}

export function PayerPlanForm({ plan, payerId, onCancel = noop, afterSave = noop }: Props) {
  const [savePlan, { loading }] = useSaveInsurancePlanMutation()

  const submit = async (values: FormValues) => {
    try {
      const result = await savePlan({
        variables: {
          planInput: {
            insurancePayerId: payerId,
            ...pick(
              values,
              'id',
              'name',
              'enterHealthId',
              'coverageDescriptionPattern',
              'coverageDescriptionExclusion',
              'addressLineOne',
              'addressLineTwo',
              'city',
              'state',
              'zipCode',
              'phone',
              'active',
              'serviceModifiers',
              'allowCouplesAndFamilyTherapy',
            ),
            enterPayerPlans: values.enterPayerPlans
              ?.filter(epp => epp?.enterPlanId)
              ?.map(epp => omit(epp, '__typename')),
            insurancePolicyGroups: values.insurancePolicyGroups?.map(groupId => ({ groupId })),
          },
        },
      })
      const planId = result.data?.saveInsurancePayerPlan.id
      toast.success(`Plan ${plan ? 'updated' : 'created'}`)
      afterSave(planId)
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a problem saving the Plan'))
    }
  }

  const { data: insuranceTaxEntityData, loading: taxEntityLoading } = useInsuranceTaxEntitiesQuery({
    fetchPolicy: 'cache-and-network',
  })

  const insuranceTaxEntites = useMemo<InsuranceTaxEntityFragment[]>(() => {
    return sortBy(insuranceTaxEntityData?.insuranceTaxEntities || [], 'organizationName')
  }, [insuranceTaxEntityData])

  const enterPayerValues: EnterPayerValues = {}

  return (
    <Formik initialValues={buildInitialValues(plan, enterPayerValues)} validationSchema={planSchema} onSubmit={submit}>
      {({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => {
        const isError = (key: keyof FormValues) => {
          return !!touched[key] && !!errors[key]
        }

        const helperText = (key: keyof FormValues, text = '') => {
          const isTouched = touched[key] as boolean
          const errorMsg = errors[key] as string | undefined
          return composeHelperText(text, errorMsg, isTouched)
        }

        const fieldProps = (key: keyof FormValues) => ({
          name: key,
          error: isError(key),
          onBlur: handleBlur,
          onChange: handleChange,
          helperText: helperText(key),
        })

        return (
          <EntityForm>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 12 }}>
                <TextField label="Name" value={values.name} {...fieldProps('name')} />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <EnterPayerSelect
                  label="Enter Health Plan"
                  value={values.enterHealthId}
                  {...fieldProps('enterHealthId')}
                  onChange={(priorValue, enterPlan) => {
                    setFieldValue('enterHealthId', enterPlan?.id)
                  }}
                />
              </Grid>
              {insuranceTaxEntites.map(taxEntity => {
                return (
                  <Grid size={{ xs: 12, md: 12 }}>
                    <EnterPayerSelect
                      label={`Enter Health Plan "${taxEntity.state}"`}
                      insuranceTaxEntityId={taxEntity.id}
                      value={enterPayerValues[taxEntity.id]}
                      {...fieldProps('enterHealthId')}
                      onChange={(priorEnterPlanId, enterPlan) => {
                        enterPayerValues[taxEntity.id] = enterPlan?.id

                        const newPayerPlans = (values.enterPayerPlans || []).filter(epp => {
                          if (!priorEnterPlanId) {
                            return true
                          }

                          return epp?.enterPlanId !== priorEnterPlanId
                        })

                        if (enterPlan) {
                          const enterPayerPlan = {
                            enterPlanId: enterPlan?.id,
                            insurancePayerPlanId: plan?.id,
                            insuranceTaxEntityId: taxEntity.id,
                          } as EnterPayerPlan

                          if (enterPayerPlan) {
                            newPayerPlans.push(enterPayerPlan)
                          }
                        }

                        setFieldValue('enterPayerPlans', newPayerPlans)
                      }}
                    />
                  </Grid>
                )
              })}
              <Grid size={12}>
                <TextField label="Street address" value={values.addressLineOne} {...fieldProps('addressLineOne')} />
              </Grid>
              <Grid size={12}>
                <TextField label="Address line 2" value={values.addressLineTwo} {...fieldProps('addressLineTwo')} />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <TextField label="City" value={values.city} {...fieldProps('city')} />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <StateSelect value={values.state ?? ''} {...fieldProps('state')} />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <TextField label="Zip code" value={values.zipCode} {...fieldProps('zipCode')} />
              </Grid>
              <Grid size={12}>
                <TextField
                  label="Coverage description pattern"
                  value={values.coverageDescriptionPattern}
                  {...fieldProps('coverageDescriptionPattern')}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  label="Coverage description exclusion"
                  value={values.coverageDescriptionExclusion}
                  {...fieldProps('coverageDescriptionExclusion')}
                />
              </Grid>
              <Grid size={12}>
                <Autocomplete
                  multiple
                  value={values.serviceModifiers}
                  id="tags-filled"
                  options={[] as string[]}
                  freeSolo
                  onChange={(_event, value) => setFieldValue('serviceModifiers', value)}
                  renderTags={(value: string[]) => (
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                      {value.map((option: string) => (
                        <Chip
                          small
                          key={option}
                          style={{ marginRight: 4 }}
                          label={option}
                          onRemove={() =>
                            setFieldValue(
                              'serviceModifiers',
                              values.serviceModifiers.filter(v => v !== option),
                            )
                          }
                        >
                          {option}
                        </Chip>
                      ))}
                    </div>
                  )}
                  renderInput={params => <MUITextField {...params} label="Service Modifiers" />}
                />
              </Grid>
              <Grid size={12}>
                <Autocomplete
                  multiple
                  value={values.insurancePolicyGroups}
                  id="insurancePolicyGroups-filled"
                  options={[] as string[]}
                  freeSolo
                  onChange={(_event, value) => setFieldValue('insurancePolicyGroups', value)}
                  renderTags={(value: string[]) => (
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                      {value.map((option: string) => (
                        <Chip
                          small
                          key={option}
                          style={{ marginRight: 4 }}
                          label={option}
                          onRemove={() =>
                            setFieldValue(
                              'insurancePolicyGroups',
                              values.insurancePolicyGroups.filter(v => v !== option),
                            )
                          }
                        >
                          {option}
                        </Chip>
                      ))}
                    </div>
                  )}
                  renderInput={params => <MUITextField {...params} label="Insurance Policy Group Numbers" />}
                />
              </Grid>
              <Grid size={12}>
                <Switch
                  checked={values.allowCouplesAndFamilyTherapy ?? false}
                  {...fieldProps('allowCouplesAndFamilyTherapy')}
                >
                  Allow Couples Therapy
                </Switch>
              </Grid>

              <Grid size={12} className="v-align">
                <div className="ml-auto mt-2">
                  {plan?.id && (
                    <OutlineButton onClick={onCancel} className="mr-2">
                      Cancel
                    </OutlineButton>
                  )}
                  {!plan?.id && payerId && (
                    <OutlineButtonLink to={routeService.insurancePayer(payerId)} className="mr-2">
                      Cancel
                    </OutlineButtonLink>
                  )}
                  <FillButton type="submit" isLoading={loading || taxEntityLoading}>
                    Save
                  </FillButton>
                </div>
              </Grid>
            </Grid>
          </EntityForm>
        )
      }}
    </Formik>
  )
}

function buildInitialValues(plan?: FullInsurancePlanFragment, enterPayerValues?: EnterPayerValues): FormValues {
  if (!plan) {
    return initialValues
  }

  const formValues: FormValues = {
    ...pick(plan, Object.keys(initialValues)),
    serviceModifiers: plan.serviceModifiers ?? [],
    insurancePolicyGroups: plan.insurancePolicyGroups?.map(g => g.groupId) ?? [],
  }

  if (plan) {
    formValues.id = plan.id
  }

  if (typeof enterPayerValues === 'object' && formValues.enterPayerPlans?.length) {
    formValues.enterPayerPlans.forEach(enterPayerPlan => {
      if (enterPayerPlan?.insuranceTaxEntityId) {
        enterPayerValues[enterPayerPlan.insuranceTaxEntityId] = enterPayerPlan?.enterPlanId
      }
    })
  }

  return formValues
}

const FieldSkeleton = () => <Skeleton height={3} style={{ width: '100%' }} />

export function PayerFormLoader() {
  return (
    <div style={{ width: '40rem' }}>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <FieldSkeleton />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <FieldSkeleton />
        </Grid>
        <Grid size={12}>
          <FieldSkeleton />
        </Grid>
        <Grid size={12}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <FieldSkeleton />
        </Grid>
        <Grid size={12} className="v-align mt-3">
          <div className="ml-auto">
            <Skeleton height={3} width={10} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
