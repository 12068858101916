import { styled } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import {
  Address,
  AddressOwner,
  AddressSearchOptions,
  AddressType,
  OrderBy,
  SearchAddressesQuery,
  TraversablePaginationSortInput,
  useRemoveAddressMutation,
  useSearchAddressesQuery,
} from '@nuna/api'
import { addressService, errorService } from '@nuna/core'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import {
  CollapsePresence,
  Confirm,
  IconButton,
  IconButtonLink,
  IconFilter,
  IconPencil,
  IconToggleButton,
  IconTrash,
  error,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { TavaAddressFilters } from '../../../../shared/TavaAddress/TavaAddressFilters'
import { AddressW9DropdownMenu } from '../../../practice-address/components/AddressW9DropdownMenu'

interface Row extends Pick<Address, 'id' | 'name' | 'phone' | 'fax' | 'createdAt' | 'updatedAt' | 'state'> {
  address: string
  w9DownloadId: string
}

type AddressColumn = DataTableColumn<Row>

const INITIAL_FILTER_VALUES: AddressSearchOptions = {
  owner: AddressOwner.Tava,
  providerAddressType: AddressType.ProviderPractice,
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]

interface Props {
  header?: ReactNode
}
export function TavaAddressTable({ header }: Props) {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [addressIdToRemove, setAddressIdToRemove] = useState<string | undefined>()
  const [removeAddress] = useRemoveAddressMutation()
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(INITIAL_FILTER_VALUES)

  const { handleSort, queryOptions, getPaginatorProps, initialTableState, setPagination, setLoading } = usePagination({
    loading: true,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const { data, loading, refetch } = useSearchAddressesQuery({
    variables: {
      order: queryOptions.sortInput,
      filters: queryOptions.filters ?? {},
      pagination: queryOptions.paginationInput,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'insurancePlanGroupsSearch', debounceTimeout: 200 },
  })

  const handleRemoveAddress = async (confirmRemoveAddress: boolean) => {
    if (confirmRemoveAddress && addressIdToRemove) {
      try {
        await removeAddress({ variables: { id: addressIdToRemove } })
        toast.success('Address removed')
        refetch()
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'Unable to remove address'))
      }
    }
    setAddressIdToRemove(undefined)
  }

  useEffect(() => setPagination(data?.searchAddresses.pagination), [data?.searchAddresses.pagination, setPagination])
  useEffect(() => setLoading(loading), [loading, setLoading])

  const rowData = useMemo(() => buildRows(data?.searchAddresses.items), [data])

  const columns: AddressColumn[] = useMemo<AddressColumn[]>(
    () => [
      { accessor: 'name', Header: 'Name' },
      { accessor: 'address', Header: 'Address' },
      { accessor: 'phone', Header: 'Phone' },
      { accessor: 'fax', Header: 'Fax' },
      {
        accessor: 'w9DownloadId',
        Header: 'W9',
        Cell: ({ row }) => <AddressW9DropdownMenu address={row.original} />,
      },
      {
        accessor: 'id',
        Header: '',
        Cell: ({ value }) => (
          <div className="actions">
            <IconButtonLink small tooltip="Edit address" to={value}>
              <IconPencil />
            </IconButtonLink>
            <IconButton
              small
              tooltip="Remove address"
              onClick={e => {
                e.stopPropagation()
                setAddressIdToRemove(value)
              }}
            >
              <IconTrash color={error} />
            </IconButton>
          </div>
        ),
      },
    ],
    [],
  )

  return (
    <Container>
      <Header>
        {header}
        <IconToggleButton
          tooltip="Filter"
          className="ml-auto"
          size="md"
          isActive={filtersOpen}
          onClick={() => setFiltersOpen(!filtersOpen)}
        >
          <IconFilter />
        </IconToggleButton>
      </Header>
      <AnimatePresence>
        {filtersOpen && (
          <CollapsePresence>
            <TavaAddressFilters filterValues={filterValues} setFilterVal={setFilterVal} clearFilters={clearFilters} />
          </CollapsePresence>
        )}
      </AnimatePresence>
      <DataTable
        className="address-table flex-remaining-space full-width"
        scrollStrategy="within"
        initialState={initialTableState}
        onSort={handleSort}
        columns={columns}
        rowData={rowData}
        loading={loading}
        paginated
        paginatorProps={getPaginatorProps()}
        isRowSelected={row => row.id === addressIdToRemove}
      />
      <Confirm isOpen={!!addressIdToRemove} onConfirm={handleRemoveAddress}>
        Are you sure you want to remove this address?
      </Confirm>
    </Container>
  )
}

const Container = styled(
  makeTypographyComponent('flex-column overflow-hidden full-width address-table-wrapper', 'div'),
)`
  .actions {
    visibility: hidden;
  }
  .address-table {
    z-index: 2;
    background-color: #fff;
    tbody tr:hover {
      .actions {
        visibility: visible;
      }
    }
  }
`

const Header = makeTypographyComponent('v-align mb-2', 'div')

function buildRows(rawRows?: SearchAddressesQuery['searchAddresses']['items']): Row[] | undefined {
  if (!rawRows) return undefined

  return rawRows.map<Row>(row => ({ ...row, address: addressService.formatAddress(row), w9DownloadId: row.id }))
}
