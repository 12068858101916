import { ApolloError } from '@apollo/client'
import moment from 'moment-timezone'
import { useState } from 'react'
import { HTMLAttributes } from 'react'

import { PatientEndDateReason, useRemoveInvalidContractAssociationMutation } from '@nuna/api'
import { AuthorizedAvatar } from '@nuna/common'
import { IconReply, toast } from '@nuna/tunic'

import { AdminNotesBadge } from '../../../shared/AdminNotes/AdminNotesBadge'
import { useMemberMatchContext } from '../MemberMatchContextProvider'
import { UnlinkedPatientRecord } from '../normalize-member-match-data'
import { EmployeeMatchTableRow } from './EmployeeMatchTableRow'
import { EmployeeRecordRows } from './EmployeeRecordRows'
import { AccessorProps, AddressCell, AssociationEditCell, FullNameCell, TextCell } from './cells'

interface Props extends HTMLAttributes<HTMLDivElement> {
  patientInfo: UnlinkedPatientRecord
  showDate: boolean
}

export function UnlinkedPatientRow({ patientInfo, showDate }: Props) {
  const { patient, matchTypeIfDependent: matchType, relatedEmployeeName, strictlyEnforceRoster } = patientInfo
  const { removePatientEmployeeMatch, patientEmployeeMatchMap, setPatientRelationship } = useMemberMatchContext()
  const association = patientEmployeeMatchMap.get(patient)?.relationshipToOrganization
  const [markedAsIneligible, setMarkedAsIneligible] = useState(false)

  const [markAsIneligibleMutation, { loading: markAsIneligibleLoading }] = useRemoveInvalidContractAssociationMutation()

  const handleMarkAsIneligible = async () => {
    removePatientEmployeeMatch(patient)
    try {
      await markAsIneligibleMutation({
        variables: { patientID: patientInfo.patient.id, reason: PatientEndDateReason.NotAPartOfCompany },
      })
      setMarkedAsIneligible(true)
      toast.success(`${patient.firstName} ${patient.lastName} marked as ineligible.`)
    } catch (error) {
      if (error instanceof ApolloError) {
        toast.urgent(error.graphQLErrors[0] ? error.graphQLErrors[0].message : 'Error marking member as ineligible')
      }
    }
  }

  if (markedAsIneligible) {
    return null
  }

  const PatientCell = ({ ...props }: AccessorProps) => <TextCell record={patient} {...props} />

  const showRelatedEmployee = relatedEmployeeName && !strictlyEnforceRoster

  return (
    <>
      <EmployeeMatchTableRow
        columns={[
          showDate ? (
            <PatientCell
              accessorKey="joined"
              className="text-bold"
              formatter={value => {
                return moment.utc(value).format('MMM DD, YYYY')
              }}
            />
          ) : null,
          <span className="v-align">
            <AuthorizedAvatar url={patient.avatarUrl} size="mini" className="mr-1" />
            <FullNameCell record={patient} />
            <AdminNotesBadge userId={patient.loginId}></AdminNotesBadge>
          </span>,
          <PatientCell accessorKey="dob" formatter={dob => moment.tz(dob, 'UTC').format('MM/DD/YYYY')} />,
          <PatientCell accessorKey="companyName" />,
          <AssociationEditCell
            association={association}
            onChange={relationship => setPatientRelationship(patient, relationship)}
          />,
          <AddressCell record={patient} />,
          <PatientCell accessorKey="phone" />,
          <PatientCell accessorKey="gender" />,
          patient.emergencyContactName,
          patient.emergencyContactRelationship,
          patient.emergencyContactPhone,
        ]}
      />
      {showRelatedEmployee && (
        <EmployeeMatchTableRow
          columns={[
            null,
            <span className="v-align">
              <IconReply className="mr-1" size={25} />
              {relatedEmployeeName}
            </span>,
          ]}
        />
      )}
      <EmployeeRecordRows
        patient={patient}
        matchTypeIfDependent={matchType}
        markAsIneligible={handleMarkAsIneligible}
        markAsIneligibleLoading={markAsIneligibleLoading}
        association={association}
        strictlyEnforceRoster={strictlyEnforceRoster}
      />
    </>
  )
}
