import { InsurancePayerStatus as StatusEnum } from '@nuna/api'
import { type InsurancePayerStatus } from '@nuna/core'
import { Intent } from '@nuna/tunic'

export const statusMap = (inputStatus?: InsurancePayerStatus) => {
  const status = inputStatus || 'Inactive'

  const intent: Record<StatusEnum, Intent> = {
    [StatusEnum.Active]: 'information',
    [StatusEnum.Inactive]: 'urgent',
    [StatusEnum.Pending]: 'caution',
  }

  return {
    intent: intent[status],
    status,
  }
}
