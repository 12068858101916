import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, FormikProps } from 'formik'
import { isNil, uniq } from 'lodash'
import { HTMLAttributes, ReactNode } from 'react'

import {
  PayerRosterDefinitionConfigColumn,
  PayerRosterDefinitionTransformation,
  PayerRosterDefinitionTransformationBoolean,
  ProviderDataDenormalizedColumnType,
} from '@nuna/api'
import { formService } from '@nuna/core'
import {
  FillButton,
  OutlineButton,
  Radio,
  RadioGroup,
  TextField,
  borderGrey,
  csx,
  eggshell,
  makeTypographyComponent,
} from '@nuna/tunic'

import { ColumnFormValues, ColumnTransformationType, ColumnValueType } from '../PayerRosterDefinition.types'
import { useColumnMetaData } from '../hooks/useColumnMetaData'
import { usePresetTransformationConfig } from '../hooks/usePresetTransformationConfig'
import { BooleanValueMapping } from './BooleanValueMapping'
import { ColumnValuePreview } from './ColumnValuePreview'
import { EditColumnRegex } from './EditColumnRegex'
import { EditCustomEvalFunction } from './EditCustomEvalFunction'
import { EditValueMapping } from './EditValueMapping'
import { PresetEvalSelector } from './PresetEvalSelector'
import { ProviderDataDenormColumnMultiSelect } from './ProviderDataDenormColumnMultiSelect'

interface EditColumnFormProps extends FormikProps<ColumnFormValues>, HTMLAttributes<HTMLFormElement> {
  payerRosterDefinitionId: string
  onCancel: () => void
  saveLoading?: boolean
}

export const COLUMN_FORM_VALIDATION_SCHEMA = Yup.object<ColumnFormValues>({
  key: Yup.string().optional(),
  valueType: Yup.mixed<ColumnValueType>().oneOf(['static', 'mapped']),
  transformationType: Yup.mixed<ColumnTransformationType>().oneOf([
    'none',
    'preset',
    'valueMapping',
    'eval',
    'regex',
    'boolean',
  ]),
  alias: Yup.string(),
  dataMapping: Yup.mixed().when('valueType', {
    is: 'mapped',
    then: Yup.array().of(Yup.string()).min(1, 'Column must be mapped or have a static value'),
    else: Yup.array().of(Yup.string()).optional(),
  }),
  valueWhenEmpty: Yup.string().nullable(),
  value: Yup.string().optional(),
  transformation: Yup.object<PayerRosterDefinitionTransformation>({
    boolean: Yup.object<PayerRosterDefinitionTransformationBoolean>({
      true: Yup.string(),
      false: Yup.string(),
    }).optional(),
  }).optional(),
})

export function EditColumnForm({
  values,
  saveLoading,
  getFieldProps,
  setFieldValue,
  setValues,
  onCancel,
  className,
  errors,
  touched,
  ...props
}: EditColumnFormProps) {
  const { columnMetaData } = useColumnMetaData()
  const { getDefaultPreset, availablePresets } = usePresetTransformationConfig(values.dataMapping)

  const getColumnMetadata = (dataMapping: string[] = []) => columnMetaData?.find(m => m.key === dataMapping[0])

  const currentColumnMetadata = getColumnMetadata(values?.dataMapping)

  const disallowedTransformationTypes: ColumnTransformationType[] = (() => {
    const disallowedTypes = [] as ColumnTransformationType[]
    const dataType = currentColumnMetadata?.dataType

    if (availablePresets.length === 0) {
      disallowedTypes.push('preset')
    }

    if (!dataType) {
      disallowedTypes.push('boolean', 'regex', 'eval', 'valueMapping', 'preset')
    }
    if (dataType === ProviderDataDenormalizedColumnType.Array) {
      disallowedTypes.push('boolean', 'valueMapping')
    }

    return uniq(disallowedTypes)
  })()

  const { dataMapping, transformationType, valueType } = values

  const hasDataMappings = dataMapping.length > 0

  const updateValueType = (valueType: ColumnValueType) => {
    setValues(prev => ({
      ...prev,
      value: valueType === 'static' ? '' : undefined,
      transformation: buildDefaultTransformation('none'),
      valueType,
      transformationType: 'none',
    }))
  }

  const updateTransformationType = (transformationType: ColumnTransformationType) => {
    setValues(prev => ({ ...prev, transformation: buildDefaultTransformation(transformationType), transformationType }))
  }

  const handleDataMappingChange = (dataMapping: { key: string; name: string }[]) => {
    let updateValues: Partial<ColumnFormValues> = {
      dataMapping: dataMapping.map(v => v.key),
    }

    setFieldValue(
      'dataMapping',
      dataMapping.map(v => v.key),
    )

    if (dataMapping.length === 1) {
      const columnMetaData = getColumnMetadata(dataMapping.map(v => v.key))
      if (columnMetaData?.dataType === ProviderDataDenormalizedColumnType.Boolean) {
        updateValues = {
          ...updateValues,
          transformation: buildDefaultTransformation('boolean'),
          transformationType: 'boolean',
        }
        return
      }

      const preset = columnMetaData ? getDefaultPreset(columnMetaData) : null

      if (preset) {
        updateValues = {
          ...updateValues,
          transformation: {
            preset: { eval: preset.key },
          },
          transformationType: 'preset',
        }
      }
    }

    setValues(prev => ({ ...prev, ...updateValues }))
  }

  const TransformationRadioButton = ({
    type: radioTransformationType,
    children: radioChildren,
  }: {
    type: ColumnTransformationType
    children: ReactNode
  }) =>
    !disallowedTransformationTypes.includes(radioTransformationType) ? (
      <Radio
        value={radioTransformationType}
        onChange={() => updateTransformationType(radioTransformationType)}
        checked={transformationType === radioTransformationType}
      >
        {radioChildren}
      </Radio>
    ) : null

  return (
    <StyledForm className={csx([className, 'p-2 top-align gap-2 flex-column overflow-hidden'])} {...props}>
      <div className="flex-remaining-space top-align gap-2 overflow-hidden full-width">
        <div className="form-controls overflow-auto full-height">
          <FormSection>
            <TextField {...getFieldProps('alias')} label="Column name" />
          </FormSection>
          <FormSection>
            <WizardLabel>Value type</WizardLabel>
            <HorizontalRadioGroup>
              <Radio value="mapped" checked={valueType === 'mapped'} onChange={() => updateValueType('mapped')}>
                Mapped
              </Radio>
              <Radio value="static" checked={valueType === 'static'} onChange={() => updateValueType('static')}>
                Static
              </Radio>
            </HorizontalRadioGroup>
          </FormSection>
          {valueType === 'static' && (
            <FormSection>
              <TextField autoFocus {...getFieldProps('value')} label="Static value" />
            </FormSection>
          )}
          {valueType === 'mapped' && (
            <FormSection>
              <ProviderDataDenormColumnMultiSelect
                label="Mapped Tava Column"
                value={values.dataMapping ?? []}
                onChange={handleDataMappingChange}
                {...formService.composeHelperTextWithError('', errors.dataMapping, touched.dataMapping)}
              />
            </FormSection>
          )}
          {valueType === 'mapped' &&
            hasDataMappings &&
            currentColumnMetadata?.dataType === ProviderDataDenormalizedColumnType.Array && (
              <FormSection className="pb-2">
                <TextField autoFocus {...getFieldProps('selector')} label="Accessor" />
              </FormSection>
            )}
          {valueType === 'mapped' && hasDataMappings && (
            <>
              <FormSection>
                <WizardLabel>Transformation</WizardLabel>
                <RadioGroup>
                  <TransformationRadioButton type="none">None</TransformationRadioButton>
                  <TransformationRadioButton type="valueMapping">Value Map</TransformationRadioButton>
                  <TransformationRadioButton type="preset">Preset</TransformationRadioButton>
                  <TransformationRadioButton type="boolean">True/False</TransformationRadioButton>
                  <TransformationRadioButton type="regex">Regular Expression</TransformationRadioButton>
                  <TransformationRadioButton type="eval">Custom Eval Function</TransformationRadioButton>
                </RadioGroup>
              </FormSection>
              {values.transformation && (
                <TransformationSettingsContainer>
                  {transformationType === 'preset' && (
                    <FormSection>
                      <PresetEvalSelector
                        dataMapping={values.dataMapping}
                        transformation={values.transformation}
                        onChange={value => setFieldValue('transformation', value)}
                      />
                    </FormSection>
                  )}
                  {transformationType === 'eval' && (
                    <EditCustomEvalFunction
                      style={{ minWidth: 900 }}
                      dataMappingKeys={values.dataMapping}
                      value={values.transformation?.eval}
                      onChange={customEval => setFieldValue('transformation', { eval: customEval })}
                    />
                  )}
                  {transformationType === 'valueMapping' && values?.dataMapping?.[0] && (
                    <FormSection>
                      <WizardLabel>Mapped Values</WizardLabel>
                      <EditValueMapping
                        columnKey={values.dataMapping[0]}
                        valueMapping={values.transformation.valueMapping}
                        onChange={valueMapping =>
                          setFieldValue('transformation', { ...values.transformation, valueMapping })
                        }
                      />
                    </FormSection>
                  )}
                  {transformationType === 'regex' && values.transformation.regex && (
                    <EditColumnRegex
                      regexTransformation={values.transformation.regex}
                      onChange={t => setFieldValue('transformation', { regex: t })}
                    />
                  )}
                  {transformationType === 'boolean' && values.transformation.boolean && (
                    <BooleanValueMapping
                      booleanTransformation={values.transformation.boolean}
                      onChange={t => setFieldValue('transformation', { boolean: t })}
                    />
                  )}
                </TransformationSettingsContainer>
              )}
              <FormSection className="mt-2">
                <TextField {...getFieldProps('valueWhenEmpty')} label="Value when empty" />
              </FormSection>
            </>
          )}
        </div>
        {values?.dataMapping?.length === 1 && (
          <ColumnValuePreview
            className="full-height flex-remaining-space overflow-auto"
            showTransformedValues={!['valueMapping'].includes(transformationType)}
            column={values}
          />
        )}
      </div>

      <ActionBar className="v-align mt-auto full-width gap-1 py-2 pl-1">
        <OutlineButton type="button" onClick={onCancel}>
          Cancel
        </OutlineButton>
        <FillButton isLoading={saveLoading} type="submit">
          Save
        </FillButton>
      </ActionBar>
    </StyledForm>
  )
}

export function buildInitialColumnFormValues(
  column: Partial<PayerRosterDefinitionConfigColumn> | null = {},
): ColumnFormValues {
  return {
    ...column,
    dataMapping: column?.dataMapping ?? [],
    alias: column?.alias ?? '',
    valueType: isNil(column?.value) ? 'mapped' : 'static',
    transformationType: determineTransformationType(column?.transformation),
  }
}

function determineTransformationType(
  transformation?: PayerRosterDefinitionTransformation | null,
): ColumnTransformationType {
  if (!transformation) return 'none'
  const { valueMapping, preset, eval: customEval, regex, boolean } = transformation
  if (valueMapping) return 'valueMapping'
  if (preset) return 'preset'
  if (customEval) return 'eval'
  if (regex) return 'regex'
  if (boolean) return 'boolean'
  return 'none'
}

function buildDefaultTransformation(type: ColumnTransformationType): PayerRosterDefinitionTransformation | undefined {
  switch (type) {
    case 'none':
      return undefined
    case 'valueMapping':
      return { valueMapping: {} }
    case 'boolean':
      return { boolean: { true: '', false: '' } }
    case 'regex':
      return { regex: { pattern: '', matchRequired: false } }
    default:
      return {}
  }
}

const StyledForm = styled(Form)`
  .form-controls {
    padding-left: 4px;
    overflow-y: auto;
    flex: 0 1 auto;
    min-width: 430px;
  }
`

const FormSection = styled(makeTypographyComponent('py-1', 'div'))`
  max-width: 400px;
  &.auto-width {
    max-width: 100%;
  }
`

const TransformationSettingsContainer = styled('div')`
  padding: var(--spacing-1);
  border-radius: var(--border-radius-sm);
  background-color: ${eggshell};
`

const WizardLabel = makeTypographyComponent('caption text-secondary pb-1 v-align', 'label')
const HorizontalRadioGroup = styled(RadioGroup)`
  .radio-container {
    margin-bottom: 0;
  }
`
HorizontalRadioGroup.defaultProps = { className: 'v-align gap-2' }

const ActionBar = styled('div')`
  border-top: 1px solid ${borderGrey};
`
