import { styled } from '@mui/material'
import { startCase } from 'lodash'
import moment, { Moment } from 'moment-timezone'
import React, { useState } from 'react'

import {
  ProviderStatus,
  useIntegrationsQuery,
  useProviderQuery,
  useSetInternalOnlyStatusMutation,
  useSetProviderActiveStatusMutation,
  useStartOffboardingForProviderMutation,
  useSyncProviderInfoFromVerifiableMutation,
  useSyncProviderToVerifiableMutation,
  useUpdateProviderStatusMutation,
} from '@nuna/api'
import { errorService, routeService } from '@nuna/core'
import { CAQHCredentials } from '@nuna/provider'
import {
  Confirm,
  DatePicker,
  FillButton,
  GhostButtonExternalLink,
  OutlineButton,
  StatusLabel,
  Switch,
  TextButtonExternalLink,
  borderGrey,
  toast,
} from '@nuna/tunic'

import { ProviderHubspotBadge } from '../Hubspot/ProviderHubspotBadge'

interface Props {
  providerId: string
  loginId: string
}

const Section = styled('div')`
  margin-bottom: 4rem;
`

export function ProviderSettings({ providerId, loginId }: Props) {
  const [offboardingPromptIsOpen, setOffboardingPromptIsOpen] = useState(false)
  const [activateConfirmIsOpen, setActivateConfirmIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOffboardingLoading, setIsOffboardingLoading] = useState(false)
  const [syncWithVerifiable, { loading: syncWithVerfiableLoading }] = useSyncProviderToVerifiableMutation({
    refetchQueries: ['Provider'],
  })
  const [syncVerifiableInfo, { loading: syncVerifiableInfoLoading }] = useSyncProviderInfoFromVerifiableMutation({
    refetchQueries: ['Provider'],
  })
  const [setProviderActiveStatus] = useSetProviderActiveStatusMutation()
  const [setInternalOnlyStatus] = useSetInternalOnlyStatusMutation()
  const [startOffboardingForProvider] = useStartOffboardingForProviderMutation()
  const [updateProviderStatusMutation] = useUpdateProviderStatusMutation({ refetchQueries: ['Provider'] })
  const [endDate, setEndDate] = useState<Moment | null>()
  const { data: integrationsResult } = useIntegrationsQuery({
    variables: {
      userId: loginId,
    },
  })

  const { data } = useProviderQuery({
    variables: {
      id: providerId,
    },
  })

  if (!data) {
    return null
  }

  const { provider } = data

  const handleInternalOnlyChange: React.FormEventHandler<HTMLInputElement> = async () => {
    setIsLoading(true)
    await setInternalOnlyStatus({
      variables: {
        providerId,
        internalOnly: !provider.internalOnly,
      },
    })
    setIsLoading(false)

    const message = provider.internalOnly
      ? 'Provider will now appear in suggestions list'
      : 'Provider removed from suggestions list'

    toast.info(message)
  }

  const handleSetActiveStatus = async () => {
    setActivateConfirmIsOpen(false)
    setIsLoading(true)
    await setProviderActiveStatus({
      variables: {
        providerId,
        active: !provider.active,
      },
    })
    setIsLoading(false)

    const message = provider.active ? 'Provider deactivated' : 'Provider activated'

    toast.info(message)
  }

  const handleStartOffboarding = async () => {
    try {
      setIsOffboardingLoading(true)
      await startOffboardingForProvider({
        variables: {
          providerId,
          endDate: moment(endDate).endOf('day').toISOString(),
        },
      })
      toast.success('Provider offboarding started')
    } catch (e) {
      toast.urgent('There was an error starting the provider offboarding.')
    } finally {
      setIsOffboardingLoading(false)
    }
  }

  const handleRestartOnboarding = async () => {
    try {
      await updateProviderStatusMutation({ variables: { id: providerId, status: ProviderStatus.Onboarding } })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, `There was an error updating the provider's status`))
    }
  }

  const handleSyncWithVerifiable = async () => {
    try {
      await syncWithVerifiable({ variables: { providerId } })
      toast.success('Provider synced with Verifiable')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, `There was an error syncing with Verifiable`))
    }
  }

  const handleSyncVerifiableInfo = async () => {
    try {
      await syncVerifiableInfo({ variables: { providerId } })
      toast.success('Synced provider education from Verifiable')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, `Error syncing provider education from verifiable`))
    }
  }

  return (
    <div>
      <Section>
        <h1 className="h5 mb-2 text-dark">Integrations</h1>

        <SettingsTable>
          <colgroup>
            <col span={1} style={{ width: '30%' }} />
            <col span={3} style={{ width: '70%' }} />
          </colgroup>
          <tbody>
            {integrationsResult?.integrations &&
              integrationsResult?.integrations.map(it => (
                <tr key={it.id}>
                  <td>{startCase(it.type.toLowerCase())}</td>
                  <td colSpan={3}>
                    {it.url ? (
                      <a href={it.url} target="_blank" rel="noreferrer">
                        {it.url}
                      </a>
                    ) : (
                      'ID: ' + it.id
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </SettingsTable>
      </Section>
      <Section>
        <h1 className="h5 mb-2 text-dark">Account</h1>

        <SettingsTable>
          <colgroup>
            <col span={1} style={{ width: '30%' }} />
            <col span={3} style={{ width: '70%' }} />
          </colgroup>
          <tbody>
            {provider.active && (
              <tr>
                <td>
                  <Switch checked={!provider.internalOnly} onChange={handleInternalOnlyChange}>
                    Show in Suggested Providers
                  </Switch>
                </td>
                <td colSpan={3}>
                  {provider.internalOnly
                    ? 'Show provider in the suggested provider list that clients see when choosing or switching their provider'
                    : 'Hide provider in the suggested provider list that clients see when choosing or switching their provider'}
                </td>
              </tr>
            )}
            <tr>
              <td style={{ float: 'left', width: '100%' }}>
                <ProviderHubspotBadge provider={provider} />
              </td>
              <td colSpan={3}>
                {provider.hubspotId ? (
                  <>
                    This provider's intake is being kept up to date with Hubspot. {provider.firstName}{' '}
                    {provider.lastName} is linked with the Hubspot ID{' '}
                    <GhostButtonExternalLink
                      href={`https://app.hubspot.com/contacts/21407699/contact/${provider.hubspotId}`}
                      scheme="light"
                      variant="primary"
                    >
                      {provider.hubspotId}
                    </GhostButtonExternalLink>
                    . You can't remove this mapping, but can link to a different contact in Hubspot if this is
                    incorrect.
                  </>
                ) : (
                  `During the provider intake process we will sync current signup phase with Hubspot. Please link to the correct Hubspot contact here.`
                )}
              </td>
            </tr>
            <tr>
              <td>
                <OutlineButton onClick={handleSyncWithVerifiable} isLoading={syncWithVerfiableLoading}>
                  Sync with Verifiable
                </OutlineButton>
              </td>
              <td>
                {provider.verifiableId ? (
                  <>
                    Click the button to sync any changes with Verifiable.{' '}
                    <TextButtonExternalLink href={routeService.verifiableProviderProfile(provider.verifiableId)}>
                      Click here
                    </TextButtonExternalLink>{' '}
                    to view the provider in Verifiable
                  </>
                ) : (
                  <>Provider is not linked with verifiable. Click the button to sync to verifiable.</>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <OutlineButton onClick={handleSyncVerifiableInfo} isLoading={syncVerifiableInfoLoading}>
                  Sync From Verifiable
                </OutlineButton>
              </td>
              <td>
                {provider.verifiableId ? (
                  <>
                    Click the button to sync any changes from Verifiable.{' '}
                    <TextButtonExternalLink href={routeService.verifiableProviderProfile(provider.verifiableId)}>
                      Click here
                    </TextButtonExternalLink>{' '}
                    to view the provider in Verifiable
                  </>
                ) : (
                  <>Provider is not linked with verifiable.</>
                )}
              </td>
            </tr>
          </tbody>
        </SettingsTable>
      </Section>
      <Section>
        <h1 className="h5 mb-2 text-dark">Actions</h1>

        <SettingsTable>
          <colgroup>
            <col span={1} style={{ width: '30%' }} />
            <col span={3} style={{ width: '70%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td>
                {!provider.endDate ? (
                  <>
                    <DatePicker
                      minDate={moment()}
                      maxDate={moment().add('12', 'months')}
                      onChange={selectedDate => setEndDate(selectedDate)}
                      format={'MM/DD/YYYY'}
                      value={endDate}
                      disablePast
                      placeholder="MM/DD/YYYY"
                    />
                    <OutlineButton
                      disabled={!endDate}
                      className="mt-1 ml-2"
                      onClick={() => !!endDate && setOffboardingPromptIsOpen(true)}
                    >
                      Start Offboarding Process
                    </OutlineButton>
                    <Confirm
                      isOpen={offboardingPromptIsOpen}
                      confirmButton={
                        <FillButton onClick={handleStartOffboarding} isLoading={isOffboardingLoading}>
                          Start Offboarding Process
                        </FillButton>
                      }
                      onConfirm={response => (response ? handleStartOffboarding() : setOffboardingPromptIsOpen(false))}
                    >
                      Are you sure you want to start the offboarding process for {provider.firstName}{' '}
                      {provider.lastName}? This will email all their clients and send an offboarding email to them. This
                      action cannot be undone.
                    </Confirm>
                  </>
                ) : (
                  <b>
                    {moment(provider.endDate).isBefore(moment())
                      ? `Provider's last day was ${moment(provider.endDate).format('MM/DD/YYYY')}`
                      : `Offboarding Process has started. End date: ${moment(provider.endDate).format('MM/DD/YYYY')}`}
                  </b>
                )}
              </td>
              <td>
                When the offboarding process for a provider has started, they are no longer visible in the suggested
                providers list. Additionally, emails are sent out to each of their clients, as well as the provider
                themselves, with details on how the offboarding process will work.
              </td>
            </tr>

            <tr>
              <td>
                <OutlineButton
                  variant={provider.active ? 'destroy' : 'primary'}
                  onClick={() => (provider.active ? setActivateConfirmIsOpen(true) : handleSetActiveStatus())}
                >
                  {provider.active ? 'Deactivate' : 'Activate'}
                </OutlineButton>
                <Confirm
                  confirmButton={
                    <FillButton onClick={handleSetActiveStatus} isLoading={isLoading}>
                      {provider.active ? 'Deactivate' : 'Activate'}
                    </FillButton>
                  }
                  isOpen={activateConfirmIsOpen}
                  onConfirm={response => (response ? handleSetActiveStatus() : setActivateConfirmIsOpen(false))}
                >
                  Are you sure you want to deactivate this provider? Doing so will remove their clients, cancel upcoming
                  appointments, and remove their calendar integration.
                </Confirm>
              </td>
              <td>
                {provider.active
                  ? `Deactivating will restrict this provider from logging in and remove them from the provider suggestion list. It will will remove their clients, cancel upcoming appointments, and remove their calendar integration.`
                  : `Activating will enable provider login and add provider to the suggestion list. You can adjust the suggestion list visibility later.`}
              </td>
            </tr>

            {provider.status && [ProviderStatus.Denied, ProviderStatus.Terminated].includes(provider?.status) && (
              <tr>
                <td>
                  <OutlineButton onClick={handleRestartOnboarding}>Restart Onboarding</OutlineButton>
                </td>
                <td>
                  Restarting onboarding will change the provider's status to <StatusLabel>Onboarding</StatusLabel>,
                  while reactivating their account. They will not show up in provider search results until their status
                  changes to <StatusLabel>Accepting Clients</StatusLabel>.
                </td>
              </tr>
            )}
          </tbody>
        </SettingsTable>
      </Section>
      <Section>
        <h1 className="h5 mb-2 text-dark">CAQH Credentials</h1>
        <CAQHCredentials providerId={providerId} />
      </Section>
    </div>
  )
}

const SettingsTable = styled('table')`
  border-spacing: 0;
  font-size: 14px;

  td {
    border-top: 1px solid ${borderGrey};
    padding: 1rem;

    &:not(:first-of-type) {
      border-left: 1px solid ${borderGrey};
    }
  }
`
