/* eslint-disable react/jsx-no-useless-fragment */
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  OrderBy,
  PartnerQueryResult,
  SearchPartnerFilterInput,
  TraversablePaginationSortInput,
  useSearchPartnersLazyQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { OutlineButton, PageContent, PageHeader, PageHeading, PageSearch, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { newPartner, partnerDetail } from '../../util/routes'

type PartnerFilterValues = Omit<SearchPartnerFilterInput, 'search'> & {
  searchTerm?: string | null
}

const INITIAL_FILTER_VALUES: PartnerFilterValues = {
  searchTerm: '',
}
const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'name', direction: OrderBy.Asc }]

export function PartnerList() {
  const [searchText, setSearchText] = useState('')

  const navigate = useNavigate()
  const { filterValues, setFilterVal } = useDataTableFiltering(INITIAL_FILTER_VALUES)
  const [querySearchPartners, { data: partnersData, loading }] = useSearchPartnersLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination({
    pagination: partnersData?.searchPartners.pagination,
    loading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const search = useMemo(
    () => debounce((searchText?: string | null) => setFilterVal('searchTerm', searchText), 400),
    [setFilterVal],
  )

  useEffect(() => {
    search(searchText)
  }, [search, searchText])

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    querySearchPartners({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
    })
  }, [querySearchPartners, queryOptions])

  const rowData = useMemo(() => partnersData?.searchPartners.items, [partnersData])

  const columns = React.useMemo<
    DataTableColumn<Pick<NonNullable<PartnerQueryResult['data']>['partner'], '__typename' | 'id' | 'name'>>[]
  >(
    () => [
      {
        accessor: 'name',
        id: 'name',
        Header: 'Name',
        Cell: ({ value, row }) => <Link to={partnerDetail(row.original.id)}>{value}</Link>,
      },
    ],
    [],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <div className="v-align">
            <PageHeading className="m-0" withDivider>
              Partners
            </PageHeading>
            <PageSearch
              className="ml-2"
              onChange={event => setSearchText(event.currentTarget.value)}
              placeholder="Search all partners"
              value={searchText}
              onClear={() => setSearchText('')}
              autoFocus={true}
            />
          </div>
          <OutlineButton onClick={() => navigate(newPartner)}>New Partner</OutlineButton>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <DataTable
            paginated
            paginatorProps={getPaginatorProps()}
            onSort={handleSort}
            columns={columns}
            rowData={rowData}
            initialState={initialTableState}
            loading={loading}
            onRowClick={row => navigate(partnerDetail(row.id))}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

function buildFilterValues(values: PartnerFilterValues = INITIAL_FILTER_VALUES): SearchPartnerFilterInput {
  const { searchTerm } = values
  return {
    search: {
      term: searchTerm,
      threshold: 0.2,
    },
  }
}
