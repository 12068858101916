import { cloneDeep, noop } from 'lodash'
import moment from 'moment'
import { ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react'

import {
  BasicProviderDefaultCompensationFragment,
  ProviderDefaultCompensationInput,
  useSaveProviderDefaultCompensationsMutation,
} from '@nuna/api'
import { toast } from '@nuna/tunic'

export type CompensationModel = BasicProviderDefaultCompensationFragment | ProviderDefaultCompensationInput
type UniqueCompensation = Pick<CompensationModel, 'cptCodeId' | 'state' | 'tier'>

interface ProviderDefaultCompensationTableContext {
  queueCompensation: (compensation: ProviderDefaultCompensationInput) => void
  hasError: (compensation: UniqueCompensation) => boolean
  removeError: (compensation: UniqueCompensation) => void
  lastSaved?: moment.Moment
}

export const initialValue: ProviderDefaultCompensationTableContext = {
  queueCompensation: noop,
  hasError: () => false,
  removeError: noop,
}

const ContextProvider = createContext(initialValue)

export function ProviderDefaultCompensationTableContextProvider({ children }: { children: ReactNode }) {
  const [lastSaved, setLastSaved] = useState<moment.Moment | undefined>()
  const [failedSaves, setFailedSaves] = useState<Set<string>>(new Set())
  const queued = useRef<ProviderDefaultCompensationInput[]>([])
  const timer = useRef<unknown>(null)
  const [saveComps] = useSaveProviderDefaultCompensationsMutation()

  const handleSave = useCallback(
    async (providerDefaultCompensations: ProviderDefaultCompensationInput[]) => {
      try {
        await saveComps({ variables: { providerDefaultCompensations } })
        return true
      } catch (e) {
        setFailedSaves(current => {
          return new Set([...current, ...providerDefaultCompensations.map(comp => uniqueCompKey(comp))])
        })
        toast.urgent('There was a problem saving some rates. Check the table.')
        return false
      }
    },
    [saveComps],
  )

  const startSaveTimer = useCallback(() => {
    if (queued.current.length === 0 || timer.current) {
      return
    }
    timer.current = setTimeout(async () => {
      const compensationsToSave = queued.current
      queued.current = []
      const saved = await handleSave(compensationsToSave)
      if (saved) {
        const lastSavedTime = moment()
        setLastSaved(lastSavedTime)
      }
      clearTimeout(timer.current as NodeJS.Timeout)
      timer.current = null
      startSaveTimer()
    }, 3000)
  }, [handleSave])

  const queueCompensation = useCallback(
    (compensation: ProviderDefaultCompensationInput) => {
      queued.current = upsertCompensation(queued.current, compensation)
      startSaveTimer()
    },
    [startSaveTimer],
  )

  const hasError = useCallback(
    (compensation: UniqueCompensation) => {
      return failedSaves.has(uniqueCompKey(compensation))
    },
    [failedSaves],
  )

  const removeError = useCallback(
    (compensation: UniqueCompensation) => {
      if (hasError(compensation)) {
        setFailedSaves(current => {
          current.delete(uniqueCompKey(compensation))
          return new Set(...current)
        })
      }
    },
    [hasError],
  )

  const value: ProviderDefaultCompensationTableContext = {
    queueCompensation,
    lastSaved,
    hasError,
    removeError,
  }

  return <ContextProvider.Provider value={value}>{children}</ContextProvider.Provider>
}

export function useProviderDefaultCompensationTableContext() {
  return useContext(ContextProvider)
}

function upsertCompensation(
  compensations: ProviderDefaultCompensationInput[],
  compensation: ProviderDefaultCompensationInput,
) {
  const newComps = cloneDeep(compensations)
  const existing = newComps.find(
    comp =>
      comp.cptCodeId === compensation.cptCodeId && comp.state === compensation.state && comp.tier === compensation.tier,
  )

  if (existing) {
    existing.rate = compensation.rate
  } else newComps.push(compensation)

  return newComps
}

function uniqueCompKey(compensation: UniqueCompensation) {
  return [compensation.state, compensation.cptCodeId, compensation.tier].join('_')
}
