import { ApolloError } from '@apollo/client/errors'
import { TextField } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'

import { InsurancePayersQuery, ProviderEnrollmentStatus, useInsurancePayersQuery } from '@nuna/api'

type InsurancePayer = InsurancePayersQuery['insurancePayers']['items'][number]
export type SearchPayer = InsurancePayer & { key: string; value: string; enrollmentStatus?: ProviderEnrollmentStatus }

type Props = {
  label?: string
  limit?: number
  inputSize?: 'small' | 'medium'
  entityId?: string
  onChange?: (providers: SearchPayer[] | null) => void
  onLoading?: (loading: boolean) => void
  onError?: (error: ApolloError | undefined) => void
}

export function PayerSearch({
  label = 'Search Payers',
  limit = 1000,
  inputSize = 'small',
  entityId,
  onChange,
  onLoading,
  onError,
}: Props) {
  const [searchInput, setSearchInput] = useState('')

  const filters = useMemo(() => {
    return entityId ? { ids: [entityId] } : { name: searchInput }
  }, [entityId, searchInput])

  const { loading } = useInsurancePayersQuery({
    variables: {
      pagination: { limit },
      filters,
    },
    fetchPolicy: 'cache-and-network',
    context: { debounceKey: 'search-payers-debounce', debounceTimeout: 500 },
    onCompleted: data => {
      if (data?.insurancePayers?.items) {
        handleSearchComplete(data.insurancePayers.items)
      }
    },
    onError: error => {
      if (onError) onError(error)
    },
  })

  const mapPayers = (payers: InsurancePayer[] | []): SearchPayer[] => {
    if (!payers) return []
    return payers.map(payer => ({
      ...payer,
      key: payer.id,
      value: payer.name,
      enrollmentStatus: ProviderEnrollmentStatus.Pending,
    }))
  }

  if (onLoading) onLoading(loading)

  function handleInputChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearchInput(value)
  }

  function handleSearchComplete(payers: InsurancePayer[]) {
    if (onChange) onChange(mapPayers(payers))
  }

  return entityId ? null : (
    <TextField
      label={label}
      defaultValue={searchInput}
      id="outlined-size-small"
      onChange={handleInputChange}
      size={inputSize}
    />
  )
}
