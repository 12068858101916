import { styled } from '@mui/material'
import { FormEvent, useState } from 'react'

import { HubspotContact, useHubspotContactSearchLazyQuery } from '@nuna/api'
import { ContextualAlert, Dialog, DialogContent, FillButton, Grid, OutlineButton, Select, TextField } from '@nuna/tunic'

import { HubspotContactSearchResults } from './HubspotContactSearchResults'

type HubspotSearchParam = 'id' | 'name'

interface Props {
  isOpen: boolean
  onClose: (hubspotContact?: HubspotContact | null) => void
}
export function HubspotContactSelect({ isOpen, onClose }: Props) {
  const [selectedContact, setSelectedContact] = useState<HubspotContact | null>(null)
  const [hasSearched, setHasSearched] = useState(false)
  const [idSearchValue, setIdSearchValue] = useState('')
  const [nameSearchValue, setNameSearchValue] = useState<[string, string]>(['', ''])
  const [searchOn, setSearchOn] = useState<HubspotSearchParam>('name')

  const [searchHubspot, { data, loading, error }] = useHubspotContactSearchLazyQuery({
    fetchPolicy: 'network-only',
  })

  const searchDisabled = searchOn === 'id' ? !idSearchValue : !nameSearchValue.join('')
  const searchResults = data?.hubspotContactSearch ?? []

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (searchDisabled) {
      return
    }

    setSelectedContact(null)

    searchHubspot({
      variables:
        searchOn === 'name'
          ? { firstName: nameSearchValue[0], lastName: nameSearchValue[1] }
          : { contactId: idSearchValue },
    })

    setHasSearched(true)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent paddingSize="md">
        <h2 className="h4 mb-5">Select Hubspot Contact</h2>
        <form onSubmit={handleSearch}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 2 }}>
              <Select
                label="Search on"
                value={searchOn}
                onChange={event => setSearchOn(event.currentTarget.value as HubspotSearchParam)}
              >
                <option value="id">ID</option>
                <option value="name">Name</option>
              </Select>
            </Grid>
            <Grid size={{ xs: 7 }}>
              {searchOn === 'name' ? (
                <Grid container spacing={5}>
                  <Grid size={{ xs: 6 }}>
                    <TextField
                      label="First name"
                      value={nameSearchValue[0]}
                      onChange={event => setNameSearchValue([event.target.value, nameSearchValue[1]])}
                    />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <TextField
                      label="Last name"
                      value={nameSearchValue[1]}
                      onChange={event => setNameSearchValue([nameSearchValue[0], event.target.value])}
                    />
                  </Grid>
                </Grid>
              ) : (
                <TextField
                  label={`Hubspot ID`}
                  value={idSearchValue}
                  onChange={event => setIdSearchValue(event.target.value)}
                />
              )}
            </Grid>
            <Grid size={{ xs: 3 }} className="v-align">
              <OutlineButton disabled={searchDisabled} className="ml-auto" type="submit">
                Search
              </OutlineButton>
            </Grid>
          </Grid>
        </form>
        {error && (
          <ContextualAlert intent="urgent" className="mt-3">
            An error occured searching Hubspot
          </ContextualAlert>
        )}
        {hasSearched && !error && (
          <ResultsSection className="mt-3 flex-column">
            <HubspotContactSearchResults
              style={{ flex: 1 }}
              selectedContact={selectedContact}
              onContactSelect={contact => setSelectedContact(contact)}
              loading={loading}
              searchResults={searchResults.map(r => r.properties as HubspotContact)}
            />
            <FillButton className="ml-auto mt-3" disabled={!selectedContact} onClick={() => onClose(selectedContact)}>
              Ok
            </FillButton>
          </ResultsSection>
        )}
      </DialogContent>
    </Dialog>
  )
}

const ResultsSection = styled('section')`
  min-height: 200px;
`
