/* eslint-disable react/jsx-no-useless-fragment */
import { styled } from '@mui/material'
import { AgGridEvent, GridReadyEvent, IServerSideDatasource, SelectionChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { HTMLAttributes, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  InsuranceTaskType,
  Login,
  OrderBy,
  ProviderEnrollmentFragment,
  ProviderEnrollmentSearchOptions,
  ProviderEnrollmentStatus,
  SearchProviderEnrollmentsQuery,
  TraversablePaginationSortInput,
  useSaveInsuranceTaskMutation,
  useSearchProviderEnrollmentsLazyQuery,
} from '@nuna/api'
import { TavaDataTable } from '@nuna/common'
import { errorService } from '@nuna/core'
import { DropdownButton, Menu, MenuItem, MenuItemStack, csx, makeTypographyComponent, toast } from '@nuna/tunic'

import { useUrlFilters } from '../../../../shared/filters/hooks/useUrlFilters'
import { ProviderEnrollmentDataDialog } from '../../../address-enrollment/AddressEnrollmentTable/components/ProviderEnrollmentDataDialog'
import { CreateNewEnrollment } from '../../NewEnrollments/CreateNewEnrollment'
import { ProviderEnrollmentFilterValues, ProviderEnrollmentFilters } from '../ProviderEnrollmentFilters'
import { UpdateEnrollmentsDrawer } from '../UpdateEnrollmentsDrawer'
import {
  DEFAULT_PROVIDER_ENROLLMENT_TABLE_COL_DEF,
  ENROLLMENT_DATA_EVENT,
  EnrollmentDataDialogOpenEvent,
  PROVIDER_ENROLLMENT_ASSIGNEE_EVENT,
  ProviderEnrollmentTableColumnKeys,
  buildColumns,
} from './components/ProviderEnrollmentTableColumns'

type Row = ProviderEnrollmentFragment & {
  assignee?: Pick<Login, 'id' | 'firstName' | 'lastName'> | null
  taskId?: string
}

type Query = ReturnType<typeof useSearchProviderEnrollmentsLazyQuery>[0]

type AssigneeEvent = AgGridEvent & {
  providerEnrollmentId: string
  assigneeLoginId: string | null
  insuranceTaskId?: string
}

interface ProviderEnrollmentTableProps extends HTMLAttributes<HTMLDivElement> {
  columns: ProviderEnrollmentTableColumnKeys[]
  fixedFilters?: ProviderEnrollmentFilterValues
  header?: ReactNode
}

const ITEM_LIMIT = 40
const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]

const createServerSideDatasource: (query: Query, filters?: ProviderEnrollmentFilterValues) => IServerSideDatasource = (
  query,
  filters = {},
) => {
  return {
    getRows: params => {
      const page = Math.ceil(((params.request.startRow ?? 0) + 1) / ITEM_LIMIT)
      query({
        variables: {
          filters: mapFilterValues(filters),
          pagination: { limit: ITEM_LIMIT, page },
          order: INITIAL_SORT,
        },
        fetchPolicy: 'network-only',
      }).then(result => {
        const { data } = result

        if (data) {
          const {
            searchProviderEnrollments: { pagination },
          } = data

          params.success({ rowData: buildRows(data), rowCount: pagination?.totalCount ?? 0 })
        } else {
          params.fail()
        }
      })
    },
  }
}

const transformUrlFilters = (
  values: Record<string, unknown>,
): ProviderEnrollmentFilterValues & {
  assignee?: string
} => {
  return {
    providerId: values.pe_providerId as string | undefined,
    insurancePayerId: values.pe_insurancePayerId as string | undefined,
    status: values.pe_status as ProviderEnrollmentStatus | undefined,
    payerStatus: values.pe_payerStatus as ProviderEnrollmentStatus | undefined,
    assignee: values.pe_assignee as string | undefined,
  }
}

export function ProviderEnrollmentTable({
  columns: columnKeys,
  className,
  header,
  fixedFilters,
  ...props
}: ProviderEnrollmentTableProps) {
  const gridRef = useRef<AgGridReact>(null)
  const urlFilters = useUrlFilters(transformUrlFilters)
  const { filterValues } = urlFilters
  const memoizedFilterValues = useMemo(() => ({ ...filterValues, ...fixedFilters }), [filterValues, fixedFilters])
  const [selectedRowsCount, setSelectedRowsCount] = useState(0)
  const [updateDrawerIsOpen, setUpdateDrawerIsOpen] = useState(false)
  const [providerIdForEnrollmentData, setProviderIdForEnrollmentData] = useState<string | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const [saveInsuranceTask] = useSaveInsuranceTaskMutation()
  const [query] = useSearchProviderEnrollmentsLazyQuery()

  useEffect(() => {
    const gridApi = gridRef.current?.api

    if (gridApi) {
      gridApi.setServerSideDatasource(
        createServerSideDatasource(
          query,
          Object.keys(memoizedFilterValues).length > 0 ? memoizedFilterValues : undefined,
        ),
      )
    }
  }, [memoizedFilterValues, query])

  const handleAssigneeChange = useCallback(
    async ({ assigneeLoginId, providerEnrollmentId, insuranceTaskId }: AssigneeEvent) => {
      try {
        await saveInsuranceTask({
          variables: {
            data: {
              providerEnrollmentId,
              assigneeLoginId,
              id: insuranceTaskId,
              taskType: InsuranceTaskType.ProviderEnrollment,
            },
          },
        })
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'Error assigning task'))
      }
    },
    [saveInsuranceTask],
  )

  const handleSelectionChange = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes()
    let selectedRowsCount = selectedRows.length

    if (selectedRowsCount > ITEM_LIMIT) {
      while (selectedRowsCount > ITEM_LIMIT) {
        event.api.deselectNode(selectedRows[selectedRowsCount - 1])
        selectedRowsCount--
      }
    }
    setSelectedRowsCount(event.api.getSelectedRows().length)
  }, [])

  const handleOpenEnrollmentData = useCallback(({ providerId }: EnrollmentDataDialogOpenEvent) => {
    setProviderIdForEnrollmentData(providerId)
  }, [])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.addEventListener(PROVIDER_ENROLLMENT_ASSIGNEE_EVENT, handleAssigneeChange)
      params.api.addEventListener(ENROLLMENT_DATA_EVENT, handleOpenEnrollmentData)
      params.api.setServerSideDatasource(createServerSideDatasource(query, memoizedFilterValues))
    },
    [query, memoizedFilterValues, handleAssigneeChange, handleOpenEnrollmentData],
  )

  const colDefs = useMemo(() => buildColumns(columnKeys), [columnKeys])

  return (
    <Container {...props} className={csx([className, { 'selection-limit-reached': selectedRowsCount >= ITEM_LIMIT }])}>
      <Header>
        {header}
        <ProviderEnrollmentFilters urlFilterService={urlFilters} fixedFilters={fixedFilters} />
        <div className="ml-auto v-align gap-2">
          <div className="bottom-align">
            <div className="caption text-seconary italic mb-xs">
              {selectedRowsCount} {selectedRowsCount === 1 ? 'address enrollment' : 'address enrollments'} selected (You
              can select up to {ITEM_LIMIT})
            </div>
            <DropdownButton
              className="ml-2"
              disabled={selectedRowsCount === 0}
              onClick={e => setMenuAnchorEl(e.currentTarget)}
              isActive={!!menuAnchorEl}
            >
              Actions
            </DropdownButton>
            <Menu
              open={!!menuAnchorEl}
              anchorEl={menuAnchorEl}
              onClose={() => setMenuAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <MenuItemStack>
                <MenuItem button onClick={() => setUpdateDrawerIsOpen(true)}>
                  Update Selected
                </MenuItem>
              </MenuItemStack>
            </Menu>
          </div>
          <CreateNewEnrollment
            scope={{ providerId: fixedFilters?.providerId }}
            refetchEnrollments={() => gridRef?.current?.api.refreshServerSideStore()}
          />
        </div>
      </Header>
      <TavaDataTable
        ref={gridRef}
        defaultColDef={DEFAULT_PROVIDER_ENROLLMENT_TABLE_COL_DEF}
        columnDefs={colDefs}
        rowModelType="serverSide"
        pagination={true}
        paginationPageSize={ITEM_LIMIT}
        cacheBlockSize={ITEM_LIMIT}
        onGridReady={onGridReady}
        onSelectionChanged={handleSelectionChange}
        serverSideStoreType="partial"
        rowSelection="multiple"
        suppressRowClickSelection
      />

      <ProviderEnrollmentDataDialog
        isOpen={!!providerIdForEnrollmentData}
        providerId={providerIdForEnrollmentData}
        onClose={() => setProviderIdForEnrollmentData(null)}
      />
      <UpdateEnrollmentsDrawer
        getSelectedRows={() => gridRef.current?.api?.getSelectedRows() ?? []}
        isOpen={updateDrawerIsOpen}
        onClose={() => setUpdateDrawerIsOpen(false)}
        onSaved={() => gridRef.current?.api?.refreshServerSideStore()}
      />
    </Container>
  )
}

const Container = styled(
  makeTypographyComponent(
    'flex-column flex-remaining-space overflow-hidden full-width address-enrollment-table-wrapper',
    'div',
  ),
)`
  &.selection-limit-reached {
    .row-header-column {
      .ag-checkbox-input-wrapper:not(.ag-checked) {
        pointer-events: none;
        &:after {
          opacity: 0.3;
          pointer-events: none;
        }
        input {
          pointer-events: none;
        }
      }
    }
  }
`

const Header = makeTypographyComponent('v-align mb-2 flex-wrap gap-2', 'div')

function buildRows(data?: SearchProviderEnrollmentsQuery) {
  if (!data?.searchProviderEnrollments.items) return []

  return data.searchProviderEnrollments.items.map<Row>(item => {
    return {
      ...item,
      key: item.id,
      assignee: item?.insuranceTasks[0]?.assigneeLogin,
      taskId: item?.insuranceTasks[0]?.id,
    }
  })
}

function mapFilterValues(
  values?: ProviderEnrollmentFilterValues | null,
): ProviderEnrollmentSearchOptions | undefined | null {
  if (!values) {
    return values
  }

  const { assignee, ...sansAssignee } = values

  switch (assignee) {
    case 'me':
      ;(sansAssignee as ProviderEnrollmentSearchOptions).assignedToMe = true
      break
    case 'none':
      ;(sansAssignee as ProviderEnrollmentSearchOptions).notAssigned = true
      break
    default:
      ;(sansAssignee as ProviderEnrollmentSearchOptions).taskAssigneeLoginId = assignee
  }

  return sansAssignee
}
