import { styled } from '@mui/material'
import { HTMLAttributes, useState } from 'react'

import { DropdownButton, IconButton, IconClose, makeTypographyComponent, plumSet, white } from '@nuna/tunic'

import { StyledMuiMenu, StyledMuiMenuItem } from '../../../../shared/filters/components/FilterSelector'
import { QuickViewKey, useMasterRosterQuickView } from '../hooks/useMasterRosterQuickView'

export function QuickViewSelect(props: HTMLAttributes<HTMLButtonElement>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { quickViews, selectedQuickView, setQuickView, removeQuickView } = useMasterRosterQuickView()

  const handleQuickViewChange = (key: QuickViewKey) => {
    setQuickView(key)
    setAnchorEl(null)
  }

  return (
    <>
      {selectedQuickView && (
        <QuickViewChip>
          Quick View: <b>{selectedQuickView.label}</b>
          <IconButton
            variant="dark"
            className="remove-button ml-xs"
            small
            tooltip="Remove filter"
            onClick={() => removeQuickView()}
          >
            <IconClose size={14} />
          </IconButton>
        </QuickViewChip>
      )}
      {!selectedQuickView && (
        <DropdownButton {...props} onClick={e => setAnchorEl(e.currentTarget)}>
          Quick View
        </DropdownButton>
      )}
      <StyledMuiMenu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {quickViews.map(quickView => (
          <StyledMuiMenuItem key={quickView.key} onClick={() => handleQuickViewChange(quickView.key)}>
            {quickView.label}
          </StyledMuiMenuItem>
        ))}
      </StyledMuiMenu>
    </>
  )
}

const QuickViewChip = styled(makeTypographyComponent('v-align no-flex-wrap py-xs px-1 gap-1', 'div'))`
  color: ${white.hex};
  background-color: ${plumSet[50].hex};
  border-radius: var(--border-radius-sm);

  .value-button {
    background-color: ${plumSet.tint[40]};
    padding: 0 4px;
    border-radius: var(--border-radius-sm);
    transition: background-color 0.3s;
    &:hover {
      background-color: ${plumSet.tint[20]};
    }
    &:focus-visible {
      outline: none;
    }
  }

  .remove-button {
    height: 14px;
    width: 14px;
  }
`
