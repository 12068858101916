import * as Yup from 'yup'
import { Formik } from 'formik'
import { omit, pick } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CompanyCreateDetails,
  UpdateCompanyMutationVariables,
  useCustomerCompanyQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, Skeleton, toast } from '@nuna/tunic'

import { EntityForm, EntityFormButtonRow } from '../../shared/EntityForm'
import { customerDetail } from '../../util/routes'
import {
  CompanyFormFields,
  CompanyFormValues,
  INITIAL_COMPANY_FORM_VALUES,
  companyFieldsSchema,
} from './CompanyContractFormFields'

type RouteProps = {
  id: string
}

const companySchema = Yup.object().concat(companyFieldsSchema)

const initialValues: CompanyFormValues = {
  ...INITIAL_COMPANY_FORM_VALUES,
}

export function UpdateCustomer() {
  const navigate = useNavigate()
  const { id: companyId } = useParams<RouteProps>()
  const { data, loading } = useGetCompanyQuery({ variables: { id: companyId || '' }, skip: !companyId })
  const [updateCompany, { loading: updateLoading }] = useUpdateCompanyMutation()
  const { id } = useParams<RouteProps>()
  const variables = id ? { id: companyId } : {}

  const { refetch: refetchCustomerCompany } = useCustomerCompanyQuery({ variables })

  if (loading || !data) {
    return <Skeleton width={100} height={20} dimensionUnits="%" />
  }

  const submit = async (values: CompanyFormValues) => {
    try {
      const variables = formValuesToUpdateCompanyObject(values, companyId || '')
      await updateCompany({ variables })
      await refetchCustomerCompany() // force refetch of customer company to update cache as the component hierarchy
      // doesn't really support passing this around, and it wouldn't update prior to adding this manual refetch

      if (companyId) {
        navigate(customerDetail(companyId))
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Please check the form and try again'))
    }
  }

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...omit(data.company, 'insurancePolicyGroups'),
        ...(data.company.insurancePolicyGroups?.length > 0 && {
          insurancePolicyGroups: data.company.insurancePolicyGroups.map(g => g.groupId),
        }),
      }}
      onSubmit={submit}
      validationSchema={companySchema}
    >
      {formProps => {
        const { handleSubmit } = formProps

        return (
          <EntityForm onSubmit={handleSubmit}>
            <CompanyFormFields {...formProps} />
            <EntityFormButtonRow>
              <FillButton isLoading={updateLoading} type="submit">
                Update Customer
              </FillButton>
            </EntityFormButtonRow>
          </EntityForm>
        )
      }}
    </Formik>
  )
}

// Utilities

function formValuesToUpdateCompanyObject(
  formValues: CompanyFormValues,
  companyId: string,
): UpdateCompanyMutationVariables {
  const updateDetails: CompanyCreateDetails = {
    name: formValues.name,
    ...pick(formValues, [
      'logoDocumentId',
      'supportsHrisIntegration',
      'shouldSyncHris',
      'shouldRunDeEligiblization',
      'isReferralIdRequired',
    ]),
  }
  const insurancePolicyGroups = formValues.insurancePolicyGroups.map(groupId => ({ groupId }))

  return { company: { ...updateDetails, id: companyId, insurancePolicyGroups } }
}
