import { styled } from '@mui/material'
import { startCase } from 'lodash'
import { useState } from 'react'

import { ProviderEnrollment, ProviderEnrollmentStatus, useSaveProviderEnrollmentMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { IconButton, IconLeading, Menu, MenuItem, MenuItemStack, Select, toast } from '@nuna/tunic'

import { useGetAvailableEnrollmentStatuses } from '../../../hooks/useGetAvailableEnrollmentStatuses'

interface ProviderEnrollmentTableActionsMenuProps {
  status: ProviderEnrollmentStatus
  id: string
  onOpenEnrollmentData: () => void
  onUpdated: (providerEnrollment: Pick<ProviderEnrollment, 'id' | 'status' | 'payerStatus'>) => void
}

export function ProviderEnrollmentTableActionsMenu({
  status,
  onOpenEnrollmentData,
  onUpdated,
  id,
}: ProviderEnrollmentTableActionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [saveProviderEnrollment] = useSaveProviderEnrollmentMutation()

  const handleSave = async (providerEnrollment: Partial<Pick<ProviderEnrollment, 'id' | 'payerStatus'>>) => {
    setAnchorEl(null)
    try {
      const results = await saveProviderEnrollment({ variables: { enrollment: providerEnrollment } })
      if (results.data?.saveProviderEnrollment) {
        onUpdated(results.data.saveProviderEnrollment)
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error updating provider enrollment'))
    }
  }

  const availableStatuses = useGetAvailableEnrollmentStatuses(status)

  return (
    <>
      <IconButton small tooltip="Actions" onClick={e => setAnchorEl(e.currentTarget)}>
        <IconLeading style={{ transform: 'rotate(90deg)' }} />
      </IconButton>
      <StyledMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItemStack>
          <div className="caption text-medium">More detail</div>
          <StyledMenuItem button onClick={onOpenEnrollmentData}>
            Provider data
          </StyledMenuItem>
          <Select
            label="Status"
            className="pb-2"
            value={status}
            onChange={e => handleSave({ id, payerStatus: e.currentTarget.value as ProviderEnrollmentStatus })}
          >
            <option value={status}>{startCase(status)}</option>
            {availableStatuses.map(newStatus => (
              <option key={newStatus} value={newStatus}>
                {startCase(newStatus)}
              </option>
            ))}
          </Select>
        </MenuItemStack>
      </StyledMenu>
    </>
  )
}

const StyledMenu = styled(Menu)`
  .MuiPopover-paper {
    padding: 0.5rem;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.menu-item {
    padding: 0.5rem;
  }
`
