import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { pick } from 'lodash'
import { useState } from 'react'

import { InsurancePayerNetwork, useSaveInsurancePayerNetworkMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, GhostButton, IconButton, IconPencil, TextField, makeTypographyComponent, toast } from '@nuna/tunic'

interface FormValues {
  id: string
  name?: string
}

const FORM_SCHEMA = Yup.object().shape<FormValues>({
  id: Yup.string().required(),
  name: Yup.string().required(),
})

interface Props {
  network: Pick<InsurancePayerNetwork, 'name' | 'id'>
}

export function NetworkHeader({ network }: Props) {
  const [editMode, setEditMode] = useState(false)
  const [saveNetwork, { loading }] = useSaveInsurancePayerNetworkMutation({ refetchQueries: ['InsurancePayer'] })

  const handleSubmit = async (values: FormValues) => {
    try {
      await saveNetwork({ variables: { saveInput: pick(values, ['id', 'name']) } })
      setEditMode(false)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to save network'))
    }
  }

  if (editMode) {
    return (
      <Formik initialValues={network} validationSchema={FORM_SCHEMA} onSubmit={handleSubmit}>
        {({ getFieldProps, errors }) => (
          <Form>
            <div className="v-align mb-2">
              <TextField autoFocus {...getFieldProps('name')} error={!!errors.name} />
              <FillButton isLoading={loading} type="submit" className="ml-2">
                Save
              </FillButton>
              <GhostButton className="ml-1" onClick={() => setEditMode(false)}>
                Cancel
              </GhostButton>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <Display>
      {network.name}{' '}
      <IconButton className="edit-button ml-1" tooltip="Edit" onClick={() => setEditMode(true)}>
        <IconPencil />
      </IconButton>
    </Display>
  )
}

const Display = styled(makeTypographyComponent('v-align', 'h4'))`
  .edit-button {
    visibility: hidden;
  }

  &:hover {
    .edit-button {
      visibility: visible;
    }
  }
`
