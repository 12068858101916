import { useState } from 'react'

import { AlterSessionActionType, WhoIsResponsibleForPayment, useManageSessionMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, toast } from '@nuna/tunic'

import { ClientPaymentIntentSelect } from './ClientPaymentIntentSelect'

interface SwitchAppointmentToCashPayFormProps {
  appointmentId: string
  patientId: string
  onComplete: () => void
}

export function SwitchAppointmentToCashPayForm({
  appointmentId,
  patientId,
  onComplete,
}: SwitchAppointmentToCashPayFormProps) {
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string | undefined>()
  const [manageSession, { loading: mutationLoading }] = useManageSessionMutation()

  const handleSwitchToCashPay = async () => {
    try {
      await manageSession({
        variables: {
          id: appointmentId,
          actionType: AlterSessionActionType.ChangeWhoIsResponsibleForPayment,
          actionData: {
            whoIsResponsibleForPayment: WhoIsResponsibleForPayment.Client,
            stripePaymentIntentId,
            stripePaymentIntentStatus: stripePaymentIntentId ? 'succeeded' : null,
          },
        },
      })
      toast.success('Session updated')
      onComplete()
    } catch (error) {
      console.error(error)
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to update session'))
    }
  }

  return (
    <div>
      <h1 className="h5 mb-0 pb-2">Switch Appointment to Cash Pay</h1>
      <div className="pt-1">
        <p className="text-secondary caption">
          If a manual charge was already performed for this session in stripe, choose the payment intent id from the
          options below. Otherwise a new charge may be scheduled for this session upon changing to cash pay.
        </p>

        <ClientPaymentIntentSelect
          value={stripePaymentIntentId}
          onChange={v => setStripePaymentIntentId(v ?? undefined)}
          patientId={patientId}
        />

        <div className="pt-4">
          <FillButton isLoading={mutationLoading} onClick={handleSwitchToCashPay} className="full-width">
            Switch to Cash Pay
          </FillButton>
        </div>
      </div>
    </div>
  )
}
