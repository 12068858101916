import { useMemo } from 'react'

import {
  PayerRosterDefinitionPresetMetaData,
  PayerRosterDefinitionTransformation,
  PayerRosterDefinitionTransformationEval,
} from '@nuna/api'
import { Select } from '@nuna/tunic'

import { TransformationPresetArgs } from '../PayerRosterDefinition.types'
import { usePresetTransformationConfig } from '../hooks/usePresetTransformationConfig'
import { PresetArgsFormControl } from './PresetArgsFormControl'

interface EditColumnTransformationProps {
  dataMapping: string[]
  transformation: PayerRosterDefinitionTransformation
  onChange: (transformation: PayerRosterDefinitionTransformation) => void
}

export function PresetEvalSelector({ dataMapping, transformation, onChange }: EditColumnTransformationProps) {
  const { availablePresets } = usePresetTransformationConfig(dataMapping)
  const handlePresetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const preset = e.target.value as PayerRosterDefinitionTransformationEval
    onChange({ ...transformation, preset: { ...transformation.preset, eval: preset, args: {} } })
  }

  const selectedPreset: PayerRosterDefinitionPresetMetaData | undefined = useMemo(
    () => availablePresets.find(p => p.key === transformation.preset?.eval),
    [transformation, availablePresets],
  )

  const handleArgsChange = (args: TransformationPresetArgs) => {
    if (transformation.preset?.eval) {
      onChange({
        ...transformation,
        preset: {
          ...transformation.preset,
          args,
        },
      })
    }
  }

  return (
    <div>
      <Select label="Preset transformation" onChange={handlePresetChange} value={transformation.preset?.eval ?? ''}>
        <option value=""></option>
        {availablePresets.map(option => (
          <option value={option.key}>{option.label}</option>
        ))}
      </Select>
      {selectedPreset && selectedPreset.args.length > 0 && transformation.preset && (
        <PresetArgsFormControl
          className="pt-1"
          preset={selectedPreset}
          args={transformation.preset.args ?? {}}
          onChange={handleArgsChange}
        />
      )}
    </div>
  )
}
