import { FormEvent, HTMLAttributes, useEffect, useMemo, useState } from 'react'

import {
  CaqhSyncStatus,
  ProviderPanelingFragment,
  useProviderCaqhValidationQuery,
  useSyncProviderCaqhToVerifiableMutation,
} from '@nuna/api'
import { CAQHValidationSection, useCAQHSetupContext } from '@nuna/caqh-setup'
import { errorService, providerService } from '@nuna/core'
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Grid,
  IconChevron,
  Intent,
  PersistentAlert,
  StatusLabel,
} from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  panelingInfo?: ProviderPanelingFragment | null
}

const statusToIntentMap: Record<string, Intent> = {
  'Invalid Credentials': 'urgent',
  'Not Synced': 'urgent',
  Complete: 'information',
  Incomplete: 'caution',
}

export function ProviderCaqhInformation({ ...props }: Props) {
  const {
    providerId,
    credentialData: providerCredentialData,
    isCredentialPolling,
    startCredentialPolling,
    stopCredentialPolling,
  } = useCAQHSetupContext()

  const [syncProviderCaqhToVerifiable, { loading: syncProviderToVerifiableLoading }] =
    useSyncProviderCaqhToVerifiableMutation()

  const {
    data: caqhValidationData,
    error: caqhValidationError,
    refetch: refetchValidationData,
  } = useProviderCaqhValidationQuery({
    variables: { providerId },
  })
  const [oneOpen, setOneOpen] = useState(false)

  const handleCardClick = () => {
    setOneOpen(!oneOpen)
  }

  const handleUpdateStatusClick = async (e: FormEvent) => {
    e.stopPropagation()
    await syncProviderCaqhToVerifiable({
      variables: {
        providerId,
      },
    })
    startCredentialPolling()
  }

  const status = useMemo(() => {
    const caqhErrorMessage = errorService.transformGraphQlError(caqhValidationError)
    if (caqhErrorMessage === 'Invalid CAQH credentials') return 'Invalid Credentials'
    if (!caqhValidationData) return 'Not Synced'
    return caqhValidationData?.providerCaqhValidation.sectionResults.every(section => section.complete)
      ? 'Complete'
      : 'Incomplete'
  }, [caqhValidationData, caqhValidationError])

  useEffect(() => {
    if (
      providerCredentialData?.providerCredentialingData?.caqhSyncStatus === CaqhSyncStatus.Completed ||
      providerCredentialData?.providerCredentialingData?.caqhSyncStatus === CaqhSyncStatus.Failed
    ) {
      stopCredentialPolling()
      refetchValidationData()
    }
  }, [providerCredentialData?.providerCredentialingData?.caqhSyncStatus, refetchValidationData, stopCredentialPolling])

  const statusNotComplete = status !== 'Complete'

  const percentCompleted = useMemo(() => {
    if (caqhValidationData) {
      return providerService.calculatePercentCaqhValidationCompleted(
        caqhValidationData?.providerCaqhValidation.sectionResults,
      )
    }
    return 0
  }, [caqhValidationData])

  useEffect(() => {
    if (percentCompleted === 100) {
      setOneOpen(false)
    }
  }, [percentCompleted])

  return (
    <Card depth={1} shadowColor="cool" style={{ padding: 16 }} {...props}>
      <CardHeader
        divider={false}
        className="space-between v-align"
        onClick={statusNotComplete ? handleCardClick : undefined}
        style={{ cursor: 'pointer' }}
      >
        <div className="v-align">
          {statusNotComplete && <IconChevron direction={oneOpen ? 'up' : 'right'} />}
          <h2 className={`${statusNotComplete && 'ml-2'} sans-serif h6`} style={{ marginBottom: 0 }}>
            CAQH Information
          </h2>
        </div>
        {!oneOpen && <StatusLabel intent={statusToIntentMap[status]}>{status}</StatusLabel>}
        {oneOpen && (
          <button disabled={isCredentialPolling} className="flex-static" onClick={handleUpdateStatusClick}>
            <PersistentAlert
              className="ml-2 v-align"
              intent={isCredentialPolling || syncProviderToVerifiableLoading ? 'loading' : 'gauge'}
              current={percentCompleted}
              target={100}
            >
              Update Status
            </PersistentAlert>
          </button>
        )}
      </CardHeader>
      {status === 'Complete' ? (
        <CardBody>All required CAQH fields are complete and up to date.</CardBody>
      ) : (
        <Collapse isOpen={oneOpen}>
          <CardBody className="space-between">
            <Grid direction="column" container spacing={0}>
              {!caqhValidationData ? (
                <p>{errorService.transformUserGraphqlError(caqhValidationError, 'CAQH Profile not synced')}</p>
              ) : (
                caqhValidationData?.providerCaqhValidation.sectionResults
                  .filter(section => !section.complete)
                  .map(section => (
                    <Grid>
                      <CAQHValidationSection section={section} compact />
                    </Grid>
                  ))
              )}
            </Grid>
          </CardBody>
        </Collapse>
      )}
    </Card>
  )
}
