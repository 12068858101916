import { PageContent } from '@nuna/tunic'

import { ProviderPracticeAddressesTable } from './ProviderPracticeAddressesTable'

export function ProviderPracticeAddresses() {
  return (
    <PageContent className="flex-column overflow-hidden">
      <ProviderPracticeAddressesTable header={<h5 className="serif mb-0">Provider Practice Addresses</h5>} />
    </PageContent>
  )
}
