import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { OrderBy, SearchProvidersQuery, TraversablePaginationSortInput, useSearchProvidersLazyQuery } from '@nuna/api'
import { DataTableState, PaginatorProps, useDataTableFiltering, usePagination } from '@nuna/data-table'

import {
  DEFAULT_PROVIDER_TABLE_FILTER_VALUES,
  ProvidersFilterValues,
  buildProviderFilterInput,
} from './ProviderFilters'

const INITIAL_SORT: TraversablePaginationSortInput[] = [
  { key: 'status', direction: OrderBy.Desc },
  { key: 'lastName', direction: OrderBy.Asc },
]

interface ProviderListContextValues {
  setFilterVal: (key: keyof typeof DEFAULT_PROVIDER_TABLE_FILTER_VALUES, value: unknown) => void
  clearFilters: () => void
  filterValues: ProvidersFilterValues
  resetFilterVal: (key: keyof typeof DEFAULT_PROVIDER_TABLE_FILTER_VALUES) => void
  providerListData: SearchProvidersQuery | undefined
  providerListDataLoading: boolean
  handleSort: (key: string, desc: boolean | undefined, multiSort: boolean) => void
  getPaginatorProps: () => PaginatorProps
  initialTableState: DataTableState<object>
  listOpen: boolean
  setListOpen: (open: boolean) => void
}

const ProviderListContext = createContext({} as unknown as ProviderListContextValues)

export function ProviderListContextProvider({
  children,
  fixedFilters,
}: {
  children: ReactNode
  fixedFilters?: Partial<ProvidersFilterValues>
}) {
  const [listOpen, setListOpen] = useState(true)
  const { filterValues, setFilterVal, resetFilterVal, clearFilters } = useDataTableFiltering(
    DEFAULT_PROVIDER_TABLE_FILTER_VALUES,
  )
  const [queryProviders, { data: providerListData, loading: providerListDataLoading }] = useSearchProvidersLazyQuery()
  const totalLabel =
    (providerListData?.searchProviders.items ?? []).length === 1 ? 'total therapist' : 'total therapists'

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination(
    {
      pagination: providerListData?.searchProviders.pagination,
      loading: providerListDataLoading,
      initialSort: INITIAL_SORT,
      filters: filterValues,
    },
    { totalLabel },
  )

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryProviders({
      variables: {
        order: sortInput,
        filters: buildProviderFilterInput(filters, fixedFilters),
        pagination: paginationInput,
      },
    })
  }, [queryProviders, queryOptions, fixedFilters])

  const contextValue: ProviderListContextValues = {
    setFilterVal,
    filterValues,
    resetFilterVal,
    providerListData,
    providerListDataLoading,
    handleSort,
    getPaginatorProps,
    initialTableState,
    listOpen,
    setListOpen,
    clearFilters,
  }
  return <ProviderListContext.Provider value={contextValue}>{children}</ProviderListContext.Provider>
}

export function useProviderListContext() {
  return useContext(ProviderListContext)
}
