import { Form, Formik, setNestedObjectValues } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'

import { Contract, useCustomerContractsQuery, useIssueNewContractToCompanyMutation } from '@nuna/api'
import { dateService, errorService } from '@nuna/core'
import { FillButton, OutlineButton, Skeleton, toast } from '@nuna/tunic'

import {
  CustomerContractFormFields,
  INITIAL_CUSTOMER_CONTRACT_FORM_VALUES,
  customerContractFormValuesToMutationInput,
  customerContractSchema,
} from './CustomerContractFormFields'
import { CustomerContractFormValues } from './shared'

export function NewCustomerContract() {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const { data, loading: isContractsLoading } = useCustomerContractsQuery({
    variables: { companyId: id },
    fetchPolicy: 'cache-and-network',
  })
  const [createContract, { loading: updateLoading }] = useIssueNewContractToCompanyMutation({
    refetchQueries: ['CustomerContracts'],
  })

  if (isContractsLoading) {
    return <Skeleton />
  }

  const latestContract = [...(data?.contracts ?? [])].pop()

  const formDefaults = {
    ...INITIAL_CUSTOMER_CONTRACT_FORM_VALUES,
    ...latestContract,
    ...computeNewDates(latestContract as Contract),
  }

  const submit = async (values: CustomerContractFormValues) => {
    try {
      await createContract({
        variables: { companyID: id, contractDetails: customerContractFormValuesToMutationInput(values) },
        refetchQueries: ['SearchCompaniesPaginated'],
      })
      navigate(`/customers/${id}/contracts`)
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Please check the form and try again'))
    }
  }

  return (
    <div>
      <h2 className="h4 mb-3">New Contract</h2>
      <Formik initialValues={formDefaults} onSubmit={submit} validationSchema={customerContractSchema}>
        {formProps => (
          <Form style={{ maxWidth: '40rem' }}>
            <CustomerContractFormFields {...formProps} />
            <section className="mb-5 mt-3 v-align">
              <div className="ml-auto">
                <OutlineButton onClick={() => navigate(-1)}>Cancel</OutlineButton>
                <FillButton
                  className="ml-2"
                  isLoading={updateLoading}
                  type="submit"
                  onClick={() => {
                    formProps.setTouched(setNestedObjectValues(formProps.values, true))
                  }}
                >
                  Create Contract
                </FillButton>
              </div>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function computeNewDates(latestContract: Contract | undefined) {
  const newGoLiveDate = latestContract?.endDate ?? ''
  const newEndDate = newGoLiveDate ? dateService.addDays(newGoLiveDate, 364).toISOString() : ''
  const newCapResetDate = newGoLiveDate ? dateService.addDays(newGoLiveDate, 365).toISOString() : ''

  return {
    goLiveDate: newGoLiveDate,
    endDate: newEndDate,
    capResetDate: newCapResetDate,
  }
}
