import { Autocomplete, TextField } from '@mui/material'
import { IHeaderParams } from 'ag-grid-community'
import FuzzySearch from 'fuzzy-search'
import { isNil, isPlainObject, isString } from 'lodash'
import { ChangeEvent, useMemo, useState } from 'react'

import { PayerRosterDefinitionConfigColumn } from '@nuna/api'
import { IconButton, IconPlus } from '@nuna/tunic'

import { RosterDefinitionSheetTableEvent } from '../PayerRosterDefinition.util'

interface AddColumnHeaderProps extends IHeaderParams {
  rosterColumns: PayerRosterDefinitionConfigColumn[]
  currrentSheetColumnKeys: string[]
}

export function AddColumnHeader({ rosterColumns, currrentSheetColumnKeys, api }: AddColumnHeaderProps) {
  const [optionSearch, setOptionSearch] = useState('')

  const availableColumns = useMemo(
    () => rosterColumns.filter(c => isNil(c.value) && !currrentSheetColumnKeys.includes(c.key)),
    [rosterColumns, currrentSheetColumnKeys],
  )

  const filteredOptions = useMemo<PayerRosterDefinitionConfigColumn[]>(() => {
    if (!optionSearch) {
      return availableColumns
    }

    const searcher = new FuzzySearch(availableColumns, ['alias'], {
      caseSensitive: false,
    })

    return searcher.search(optionSearch)
  }, [optionSearch, availableColumns])

  const handleChange = (event: ChangeEvent<unknown>, value: PayerRosterDefinitionConfigColumn | null | string) => {
    event.stopPropagation()
    event.preventDefault()
    if (isString(value)) {
      // @ts-expect-error - this is a custom event
      api.dispatchEvent({ type: RosterDefinitionSheetTableEvent.CreateColumn, payload: value })
    } else if (isPlainObject(value)) {
      // @ts-expect-error - this is a custom event
      api.dispatchEvent({ type: RosterDefinitionSheetTableEvent.AddColumn, payload: value.key })
    }
  }

  const handleNewButtonClick = () => {
    // @ts-expect-error - this is a custom event
    api.dispatchEvent({ type: RosterDefinitionSheetTableEvent.CreateColumn, payload: '' })
  }

  return (
    <div className="px-1 full-width v-align">
      <Autocomplete
        className="flex-remaining-space"
        freeSolo
        options={filteredOptions}
        getOptionLabel={option => (typeof option !== 'string' && 'alias' in option ? option?.alias : '')}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            className="full-width"
            placeholder="Add Column"
            {...params}
            onChange={e => setOptionSearch(e.target.value)}
          />
        )}
      />
      <IconButton
        variant="secondary"
        className="ml-2"
        type="button"
        small
        tooltip="New Column"
        onClick={handleNewButtonClick}
      >
        <IconPlus size={16} />
      </IconButton>
    </div>
  )
}
