import { useCallback } from 'react'

import { DocumentType, useSaveProviderCoiMutation, useSaveUserDocuments } from '@nuna/api'

export function useUploadProviderCoiDocument(providerId: string, loginId?: string | null) {
  const [saveCoi, { loading: saveCoiLoading }] = useSaveProviderCoiMutation()
  const [saveDocuments, { loading: uploadDocLoading }] = useSaveUserDocuments()

  const saveProviderCoiDocument = useCallback(
    async (files: File[], providerCoiId?: string) => {
      if (!loginId) throw new Error('Login ID is required')
      const documentIds = await saveDocuments(files, { documentType: DocumentType.LiabilityInsurance, loginId })
      if (!documentIds || (!!documentIds && documentIds.length < 1)) throw new Error('Failed to upload document')
      await saveCoi({ variables: { data: { providerId, documentId: documentIds[0], id: providerCoiId } } })
    },
    [providerId, loginId, saveCoi, saveDocuments],
  )

  return [saveProviderCoiDocument, { loading: saveCoiLoading || uploadDocLoading }] as const
}
