import { capitalize, startCase } from 'lodash'
import moment from 'moment'
import { useState } from 'react'

import { useSearchClaimsQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { numberService } from '@nuna/core'
import { GhostButton, IconCommentOutline, MenuDivider } from '@nuna/tunic'
import { Card, CardBody, IconButtonExternalLink, IconExport, InfoWithHeading } from '@nuna/tunic'

import { ClaimMessageDrawer } from './ClaimMessageDrawer'

interface Props {
  sessionId?: string
}

export function ClaimsInfo({ sessionId }: Props) {
  const isAdmin = useIsAdmin()
  const [claimMessageOpen, setClaimMessageOpen] = useState('')
  const { data } = useSearchClaimsQuery({
    variables: { filters: { sessionId } },
    skip: !sessionId,
  })

  const rowData = data?.searchClaims.items ?? []

  if (!rowData.length) return <div className="text-secondary">No claims</div>

  return (
    <div>
      {rowData.map(claim => (
        <Card>
          <CardBody>
            <InfoWithHeading
              heading="Transmission ID"
              info={claim.payerAppointmentId}
              secondaryInfo={
                <IconButtonExternalLink
                  href={`https://provider.enter.health/claims/${claim.externalClaimId}`}
                  tooltip="Link to claim in Enter dashboard"
                >
                  <IconExport size={19} />
                </IconButtonExternalLink>
              }
            />
            <InfoWithHeading heading="Status" info={capitalize(startCase(claim.claimStatus ?? ''))} />
            <InfoWithHeading
              heading="Responsibility"
              info={capitalize(startCase(claim.claimResponsibilityType ?? ''))}
            />
            <InfoWithHeading
              heading="Adjudicated"
              info={claim.claimAdjudicatedDate ? moment(claim.claimAdjudicatedDate).format('MMM D, YYYY') : ''}
            />
            <InfoWithHeading
              heading="Payer Allowed Amount"
              info={numberService.centsToFormattedDollars(claim.allowedAmountInCents, { maximumFractionDigits: 2 })}
            />
            <InfoWithHeading
              heading="Patient Responsible Amount"
              info={numberService.centsToFormattedDollars(claim.patientResponsibleAmountInCents, {
                maximumFractionDigits: 2,
              })}
            />
            <InfoWithHeading
              heading="Patient Outstanding Amount"
              info={numberService.centsToFormattedDollars(claim.patientOutstandingAmountInCents, {
                maximumFractionDigits: 2,
              })}
            />
            <InfoWithHeading heading="Collection Status" info={capitalize(startCase(claim.collectionStatus ?? ''))} />
            {claim.claimLineItems?.map(lineItem => (
              <InfoWithHeading
                key={lineItem.id}
                heading="Billed Item"
                info={`${lineItem.quantity} x Procedure code ${
                  lineItem.procedureCode
                } with modifiers ${lineItem.procedureModifierCodes.join(
                  ', ',
                )} at ${numberService.centsToFormattedDollars(lineItem.amountDueInCents, {
                  maximumFractionDigits: 2,
                })}`}
              />
            ))}
            {claim.claimPaymentItems?.map(paymentItem => (
              <InfoWithHeading
                key={paymentItem.id}
                heading="Payment recorded"
                info={`${moment(paymentItem.paymentDate).format('ll [at] LT z')} - ${
                  paymentItem.responsibleParty
                }: ${numberService.centsToFormattedDollars(paymentItem.amountPaidInCents, {
                  maximumFractionDigits: 2,
                })}`}
              />
            ))}
            {isAdmin && (
              <>
                <br />
                <MenuDivider />
                <InfoWithHeading
                  className="mt-4"
                  heading="Enter Messages"
                  info={claim.claimMessages?.map(claimMessage => (
                    <>
                      <GhostButton
                        variant="primary"
                        onClick={() => setClaimMessageOpen(claimMessage.id)}
                        className="mr-1 v-align"
                      >
                        <IconCommentOutline className="mr-1" size={20} />
                        {claimMessage.subject}
                      </GhostButton>
                      <ClaimMessageDrawer
                        claimMessage={claimMessage}
                        externalClaimId={claim.externalClaimId ?? ''}
                        isOpen={claimMessageOpen === claimMessage.id}
                        onClose={() => setClaimMessageOpen('')}
                      />
                    </>
                  ))}
                />
              </>
            )}
          </CardBody>
        </Card>
      ))}
    </div>
  )
}
