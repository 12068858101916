import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { throttle } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { Patient, useAllPatientsLazyQuery } from '@nuna/api'

export type SelectClient = Pick<Patient, 'firstName' | 'lastName' | 'id'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<SelectClient, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  value?: SelectClient | null
  onChange: (patient: SelectClient | null) => void
}

export function ClientSelect({ value, onChange, className, ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchPatients, { data }] = useAllPatientsLazyQuery({ fetchPolicy: 'network-only' })

  const patients: SelectClient[] = data?.patients ?? []

  const fetch = useMemo(
    () => throttle((search: string) => searchPatients({ variables: { search, first: 50 } })),
    [searchPatients],
  )

  useEffect(() => {
    fetch(searchTerm)
  }, [fetch, searchTerm])

  const classes = ['fs-exlude']

  if (className) {
    classes.push(className)
  }

  return (
    <Autocomplete
      {...props}
      className={classes.join(' ')}
      multiple={false}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={patient => `${patient.firstName} ${patient.lastName}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={patients}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField name="selectedPatient" label="Client" placeholder="Filter by client" {...params} />
      )}
    />
  )
}
