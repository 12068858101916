import { Fragment } from '@fullcalendar/common/vdom'

import { Link } from '@nuna/common'
import { Skeleton } from '@nuna/tunic'

export interface BreadcrumbItem {
  path?: string
  label: string
}

interface Props {
  breadcrumbs: BreadcrumbItem[]
  loading?: boolean
}

export function Breadcrumb({ breadcrumbs, loading }: Props) {
  if (loading) {
    return <Skeleton height={1.5} width={10} />
  }
  return (
    <div>
      {breadcrumbs.map((breadcrumb, idx) => {
        return (
          <Fragment key={idx}>
            <BreadcrumbLink breadcrumb={breadcrumb} className="h5" />{' '}
            {idx + 1 < breadcrumbs.length && <span className="mx-1 h5">/</span>}
          </Fragment>
        )
      })}
    </div>
  )
}
interface BreadcrumbLinkProps {
  breadcrumb: BreadcrumbItem
  className?: string
}
function BreadcrumbLink({ breadcrumb: { path, label }, ...props }: BreadcrumbLinkProps) {
  if (!path) {
    return <span {...props}>{label}</span>
  }

  return (
    <Link to={path} {...props}>
      {label}
    </Link>
  )
}
