import { styled } from '@mui/material'
import {
  Autocomplete,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import moment from 'moment'
import { SyntheticEvent, useEffect, useState } from 'react'
import { MdEventNote } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'

import { EventTypeLogFilterType, useCompaniesQuery } from '@nuna/api'
import { ClientSelect, type SelectClient } from '@nuna/client'
import {
  Checkbox,
  DateRangePicker,
  IconCustomer,
  IconUser,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { EventLogCompany } from './event-log.types'
import { EventLogTable } from './table/EventLogTable'
import { useEventLogFiltersFromParams } from './useEventLogFiltersFromParams'

const EVENT_OPTIONS = Object.values(EventTypeLogFilterType)

export function EventLog() {
  const location = useLocation()
  const navigate = useNavigate()
  const [range, setRange] = useState(getPrevious30Days())
  const [selectedCompany, setSelectedCompany] = useState<EventLogCompany | null | undefined>(null)
  const [selectedPatient, setSelectedPatient] = useState<SelectClient | null | undefined>(null)
  const [eventTypes, setEventTypes] = useState<EventTypeLogFilterType[]>([])

  const { data: companiesData } = useCompaniesQuery({ fetchPolicy: 'cache-and-network' })
  const companies = companiesData?.companies ?? []

  const { company, patient, loading: paramFiltersLoading, urlSearchParams } = useEventLogFiltersFromParams()

  useEffect(() => setSelectedCompany(company ?? null), [company])

  useEffect(() => setSelectedPatient(patient ?? null), [patient])

  const handleEventTypeFilterChange = (event: SelectChangeEvent<EventTypeLogFilterType[]>) => {
    const {
      target: { value },
    } = event

    const values = typeof value === 'string' ? value.split(',') : (value as string[])
    setEventTypes(values as EventTypeLogFilterType[])
  }

  const reloadWithSearchParams = (params: URLSearchParams) => {
    navigate({ pathname: location.pathname, search: `?${params}` })
  }

  const handlePatientFilterChange = (patient: SelectClient | null) => {
    if (patient) {
      urlSearchParams.set('patientId', patient.id)
    } else {
      urlSearchParams.delete('patientId')
    }
    setSelectedPatient(patient)

    reloadWithSearchParams(urlSearchParams)
  }

  const handleCompanyFilterChange = (
    _event: SyntheticEvent<Element, Event>,
    value: EventLogCompany | null | undefined,
  ) => {
    if (value) {
      urlSearchParams.set('companyId', value.id)
    } else {
      urlSearchParams.delete('companyId')
    }
    setSelectedCompany(value)
    reloadWithSearchParams(urlSearchParams)
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin={true}>
          <div className="v-align" style={{ overflow: 'hidden' }}>
            <PageHeading className="mb-0 mr-2" withDivider>
              Roster Event Log
            </PageHeading>
            <div className="v-align flex-remaining-space" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <div className="v-align">
                <DateRangePicker value={range} onChange={setRange} />
                <FilterSelectWrapper>
                  <IconCustomer />
                  <Autocomplete
                    loading={paramFiltersLoading}
                    disabled={companies.length === 0}
                    className="filter-select ml-1"
                    onChange={handleCompanyFilterChange}
                    getOptionLabel={company => company.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    id="company-select"
                    options={companies}
                    value={selectedCompany}
                    renderInput={params => <TextField name="selectedCompany" label="Company" {...params} />}
                  />
                </FilterSelectWrapper>
                <FilterSelectWrapper>
                  <MdEventNote size={22} className="mr-1" />
                  <FormControl className="filter-select">
                    <InputLabel id="event-type-filter-label">Event type</InputLabel>
                    <Select
                      multiple
                      labelId="event-type-filter-label"
                      value={eventTypes}
                      onChange={handleEventTypeFilterChange}
                      renderValue={selected => (selected as string[]).join(', ')}
                    >
                      {EVENT_OPTIONS.map(eventOption => (
                        <MenuItem key={eventOption} value={eventOption}>
                          <Checkbox
                            readOnly
                            checked={eventTypes.includes(eventOption)}
                            onClick={e => e.stopPropagation()}
                          />
                          <ListItemText>{eventOption}</ListItemText>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FilterSelectWrapper>
                <FilterSelectWrapper>
                  <IconUser size={18} />
                  <ClientSelect
                    value={selectedPatient}
                    onChange={handlePatientFilterChange}
                    loading={paramFiltersLoading}
                    className="filter-select ml-1"
                  />
                </FilterSelectWrapper>
              </div>
            </div>
          </div>
          {/* <div className="large text-medium-grey">{filteredRowData.length} Appointments</div> */}
        </PageHeader>
        <PageContent $paddingTop={false}>
          <EventLogTable
            companyId={selectedCompany?.id}
            startDate={range.fromDate}
            endDate={range.toDate}
            eventTypes={eventTypes}
            clientId={selectedPatient?.id}
            filtersLoading={paramFiltersLoading}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

function getPrevious30Days() {
  return {
    toDate: moment().endOf('day'),
    fromDate: moment().subtract(30, 'days').startOf('day'),
  }
}

const FilterSelectWrapper = styled('div')`
  display: flex;
  width: 360px;

  .filter-select {
    flex: 1;
    margin-bottom: var(--margin-2);
  }
`
FilterSelectWrapper.defaultProps = { className: 'v-align ml-3' }
