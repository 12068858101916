import { cloneDeep, noop } from 'lodash'
import moment from 'moment'
import { ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react'

import {
  ProviderCompensationFragment,
  ProviderCompensationInput,
  useSaveProviderCompensationsMutation,
} from '@nuna/api'
import { toast } from '@nuna/tunic'

export type CompensationModel = ProviderCompensationFragment | ProviderCompensationInput
type UniqueCompensation = Pick<CompensationModel, 'cptCodeId' | 'providerId'>

interface ProviderCompensationTableContext {
  queueCompensation: (compensation: ProviderCompensationInput) => void
  hasError: (compensation: UniqueCompensation) => boolean
  removeError: (compensation: UniqueCompensation) => void
  lastSaved?: moment.Moment
}

export const initialValue: ProviderCompensationTableContext = {
  queueCompensation: noop,
  hasError: () => false,
  removeError: noop,
}

const ContextProvider = createContext(initialValue)

export function ProviderCompensationTableContextProvider({ children }: { children: ReactNode }) {
  const [lastSaved, setLastSaved] = useState<moment.Moment | undefined>()
  const [failedSaves, setFailedSaves] = useState<Set<string>>(new Set())
  const queued = useRef<ProviderCompensationInput[]>([])
  const timer = useRef<unknown>(null)
  const [saveComps] = useSaveProviderCompensationsMutation()

  const handleSave = useCallback(
    async (providerCompensations: ProviderCompensationInput[]) => {
      try {
        await saveComps({ variables: { providerCompensations } })
        return true
      } catch (e) {
        setFailedSaves(current => {
          return new Set([...current, ...providerCompensations.map(comp => uniqueCompKey(comp))])
        })
        toast.urgent('There was a problem saving some rates. Check the table.')
        return false
      }
    },
    [saveComps],
  )

  const startSaveTimer = useCallback(() => {
    if (queued.current.length === 0 || timer.current) {
      return
    }
    timer.current = setTimeout(async () => {
      const compensationsToSave = queued.current
      queued.current = []
      const saved = await handleSave(compensationsToSave)
      if (saved) {
        const lastSavedTime = moment()
        setLastSaved(lastSavedTime)
      }
      clearTimeout(timer.current as NodeJS.Timeout)
      timer.current = null
      startSaveTimer()
    }, 3000)
  }, [handleSave])

  const queueCompensation = useCallback(
    (compensation: ProviderCompensationInput) => {
      queued.current = upsertCompensation(queued.current, compensation)
      startSaveTimer()
    },
    [startSaveTimer],
  )

  const hasError = useCallback(
    (compensation: UniqueCompensation) => {
      return failedSaves.has(uniqueCompKey(compensation))
    },
    [failedSaves],
  )

  const removeError = useCallback(
    (compensation: UniqueCompensation) => {
      if (hasError(compensation)) {
        setFailedSaves(current => {
          current.delete(uniqueCompKey(compensation))
          return new Set(...current)
        })
      }
    },
    [hasError],
  )

  const value: ProviderCompensationTableContext = {
    queueCompensation,
    lastSaved,
    hasError,
    removeError,
  }

  return <ContextProvider.Provider value={value}>{children}</ContextProvider.Provider>
}

export function useProviderCompensationTableContext() {
  return useContext(ContextProvider)
}

function upsertCompensation(compensations: ProviderCompensationInput[], compensation: ProviderCompensationInput) {
  const newComps = cloneDeep(compensations)
  const existing = newComps.find(
    comp => comp.cptCodeId === compensation.cptCodeId && comp.providerId === compensation.providerId,
  )

  if (existing) {
    existing.rate = compensation.rate
  } else newComps.push(compensation)

  return newComps
}

function uniqueCompKey(compensation: UniqueCompensation) {
  return [compensation.providerId, compensation.cptCodeId].join('_')
}
