import { isArray, isBoolean, isNil, isNumber, isString, startCase } from 'lodash'
import moment from 'moment-timezone'
import { Fragment } from 'react'

import { dateService } from '@nuna/core'
import { StatusLabel } from '@nuna/tunic'

type UnstructuredData = object | string | number | boolean | Array<UnstructuredData>
interface DataPrintProps {
  data: UnstructuredData
  id?: string
}

export function UnstructuredDataPrint({ data, id }: DataPrintProps) {
  if (isNil(data)) {
    return <>--</>
  }

  if (isArray(data) && data.every(d => isString(d) || isNumber(d) || isBoolean(d))) {
    return (
      <>
        {data.map((d, i) => (
          <span key={i}>
            <StatusLabel className="mb-xs">{d as string | number | boolean}</StatusLabel>
          </span>
        ))}
      </>
    )
  }

  if (isArray(data)) {
    return (
      <>
        {data.map((d, i) => (
          <UnstructuredDataPrint key={i} data={d as UnstructuredData} />
        ))}
      </>
    )
  }

  if (
    isNaN(data as number) &&
    isString(data) &&
    moment(data).isValid() &&
    moment(data).isBetween('1900-01-01', '2100-01-01')
  ) {
    const formattedDate = dateService.isIso8601UtcFormat(data)
      ? dateService.utcFormat(data, 'll')
      : moment(data).format('ll')

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{formattedDate}</>
  }

  if (isBoolean(data)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{data ? 'Yes' : 'No'}</>
  }

  if (isString(data) || isNumber(data) || isBoolean(data)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{data}</>
  }

  return (
    <dl id={id}>
      {Object.entries(data)
        .filter(([key]) => key !== '__typename')
        .map(([key, value]) => (
          <Fragment key={key}>
            <dt>{startCase(key)}</dt>
            <dd>
              <UnstructuredDataPrint data={value as UnstructuredData} />
            </dd>
          </Fragment>
        ))}
    </dl>
  )
}
