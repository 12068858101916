import { Form, Formik, setNestedObjectValues } from 'formik'
import { omit, pick } from 'lodash'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  AuthorizedInternationalRegionFragment,
  AuthorizedInternationalRegionInput,
  ContractInput,
  CustomerContractQuery,
  PaymentPreference,
  useCustomerContractQuery,
  useUpdateContractMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, OutlineButton, Skeleton, toast } from '@nuna/tunic'

import { CustomerContractFormFields, customerContractSchema } from './CustomerContractFormFields'
import { CustomerContractFormValues } from './shared'

export function EditCustomerContract() {
  const navigate = useNavigate()
  const { contractId = '', id = '' } = useParams()
  const { data } = useCustomerContractQuery({ variables: { id: contractId } })

  const initialValues = useMemo(() => contractToFormValues(data?.contract), [data])

  const [updateContract, { loading: updateLoading }] = useUpdateContractMutation()

  const submit = async (values: CustomerContractFormValues) => {
    try {
      await updateContract({
        variables: { contract: { id: contractId, ...customerContractFormValuesContractInput(values) } },
      })
      navigate(`/customers/${id}/contracts/${contractId}`)
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Please check the form and try again'))
    }
  }

  if (!initialValues) {
    return (
      <div>
        <Skeleton height={2} width={20} className="mb-2" />
        <Skeleton height={2} width={20} className="mb-2" />
        <Skeleton height={2} width={20} className="mb-2" />
        <Skeleton height={2} width={20} className="mb-2" />
      </div>
    )
  }

  return (
    <div>
      <h2 className="h4 mb-3">Edit Contract</h2>
      <Formik initialValues={initialValues} onSubmit={submit} validationSchema={customerContractSchema}>
        {formProps => (
          <Form style={{ maxWidth: '40rem' }}>
            <CustomerContractFormFields isEditForm {...formProps} />
            <section className="mb-5 mt-3 v-align">
              <div className="ml-auto">
                <OutlineButton onClick={() => navigate(-1)}>Cancel</OutlineButton>
                <FillButton
                  className="ml-2"
                  isLoading={updateLoading}
                  type="submit"
                  onClick={() => {
                    formProps.setTouched(setNestedObjectValues(formProps.values, true))
                  }}
                >
                  Save Contract
                </FillButton>
              </div>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function contractToFormValues(contract?: CustomerContractQuery['contract'] | null): CustomerContractFormValues | null {
  if (!contract) {
    return null
  }
  // so i can find you
  const {
    availableToMedicalPlanEnrolled,
    availableToBenefitsEligible,
    availableToBenefitsEnrolled,
    availableToContractors,
    availableToDependents,
    availableToFullTime,
    availableToInterns,
    availableToPartTime,
    capResetDate,
    goLiveDate,
    endDate,
    hasMedMgmt,
    postCapPatientSessionCost,
    sessionAllowanceToType,
    alternateCoverageSuggestion,
    supportedInternationalAreas,
    partnerId,
    strictlyEnforceRoster,
    isEligiblityEnforced,
    pepmFeeInCents,
    sessionFeeInCents,
    annualFeeInCents,
    isAPreviewContract,
  } = contract

  return {
    availableToBenefitsEligible,
    availableToBenefitsEnrolled,
    availableToMedicalPlanEnrolled,
    availableToContractors,
    availableToDependents,
    availableToFullTime,
    availableToInterns,
    availableToPartTime,
    hasMedMgmt,
    capResetDate,
    endDate,
    goLiveDate,
    partnerId,
    strictlyEnforceRoster,
    isEligiblityEnforced,
    isReferralPartner: !!partnerId,
    isCoachingOffered: false,
    postCapPatientSessionCost,
    isInfiniteSessions: sessionAllowanceToType === null,
    sessionAllowanceToType,
    alternateCoverageSuggestion: alternateCoverageSuggestion || PaymentPreference.Insurance,
    supportedInternationalAreas: internationalAreasFragmentToInput(supportedInternationalAreas),
    pepmFeeInCents,
    sessionFeeInCents,
    annualFeeInCents,
    isAPreviewContract,
  }
}

function customerContractFormValuesContractInput<T extends object>(
  formValues: CustomerContractFormValues & T,
): ContractInput {
  const contractDetails: ContractInput = {
    hasMedMgmt: formValues.hasMedMgmt,
    isCoachingOffered: formValues.isCoachingOffered,
    availableToFullTime: formValues.availableToFullTime,
    availableToDependents: formValues.availableToDependents,
    availableToPartTime: formValues.availableToPartTime,
    availableToContractors: formValues.availableToContractors,
    availableToInterns: formValues.availableToInterns,
    availableToBenefitsEnrolled: formValues.availableToBenefitsEnrolled,
    availableToBenefitsEligible: formValues.availableToBenefitsEligible,
    availableToMedicalPlanEnrolled: formValues.availableToMedicalPlanEnrolled,
    capResetDate: formValues.capResetDate,
    postCapPatientSessionCost: formValues.postCapPatientSessionCost ?? 0,
    sessionAllowanceToType: formValues.isInfiniteSessions ? null : (formValues.sessionAllowanceToType as number),
    alternateCoverageSuggestion: formValues.alternateCoverageSuggestion,
    excludedGeographicalAreas: formValues.excludedGeographicalAreas,
    parentOrganization: formValues.parentOrganization,
    supportedInternationalAreas: formValues.supportedInternationalAreas,
    endDate: formValues.endDate,
    partnerId: formValues.isReferralPartner ? formValues.partnerId : null,
    isEligiblityEnforced: formValues.isEligiblityEnforced,
    strictlyEnforceRoster: formValues.strictlyEnforceRoster,
    pepmFeeInCents: formValues.pepmFeeInCents,
    annualFeeInCents: formValues.annualFeeInCents,
    sessionFeeInCents: formValues.sessionFeeInCents,
    isAPreviewContract: formValues.isAPreviewContract,
  }

  return contractDetails
}

export function internationalAreasFragmentToInput(
  internationalAreas: AuthorizedInternationalRegionFragment[] | null | undefined,
): AuthorizedInternationalRegionInput[] {
  if (!internationalAreas) {
    return []
  }

  return internationalAreas.map<AuthorizedInternationalRegionInput>(area => {
    return {
      ...omit(area, '__typename'),
      wpoPhoneNumbers: area.wpoPhoneNumbers.map(phoneNumber => pick(phoneNumber, ['phoneNumber', 'phoneType'])),
    }
  })
}
