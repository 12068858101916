import { styled } from '@mui/material'
import { startCase } from 'lodash'
import { useState } from 'react'

import { AddressEnrollment, AddressEnrollmentStatus, useSaveAddressEnrollmentMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { IconButton, IconLeading, Menu, MenuItem, MenuItemStack, Select, toast } from '@nuna/tunic'

import { useGetAvailableAddressEnrollmentStatuses } from '../hooks/useGetAvailableAddressEnrollmentStatuses'

interface Props {
  status: AddressEnrollmentStatus
  followProviderEnrollment: boolean
  id: string
  onOpenEnrollmentData: () => void
  onOpenAddressesDrawer: () => void
  onUpdated: (
    addressEnrollment: Pick<
      AddressEnrollment,
      | 'id'
      | 'payerStatus'
      | 'effectiveStatus'
      | 'submittedDate'
      | 'effectiveDate'
      | 'archivedDate'
      | 'followProviderEnrollment'
    >,
  ) => void
}
enum AddressEnrollmentType {
  Enrollment = 'Provider Enrollment',
  Change = 'Change of Address',
}

export function AddressEnrollmentActionsMenu({
  status,
  followProviderEnrollment,
  onOpenEnrollmentData,
  onOpenAddressesDrawer,
  onUpdated,
  id,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [saveAddressEnrollment] = useSaveAddressEnrollmentMutation()

  const handleSave = async (
    addressEnrollment: Partial<Pick<AddressEnrollment, 'id' | 'payerStatus' | 'followProviderEnrollment'>>,
  ) => {
    setAnchorEl(null)
    try {
      const results = await saveAddressEnrollment({ variables: { addressEnrollment } })
      if (results.data?.saveAddressEnrollment) {
        onUpdated(results.data.saveAddressEnrollment)
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error updating address enrollment'))
    }
  }

  const availableStatuses = useGetAvailableAddressEnrollmentStatuses(status)

  const selectedType = followProviderEnrollment ? AddressEnrollmentType.Enrollment : AddressEnrollmentType.Change

  return (
    <>
      <IconButton small tooltip="Actions" onClick={e => setAnchorEl(e.currentTarget)}>
        <IconLeading style={{ transform: 'rotate(90deg)' }} />
      </IconButton>
      <StyledMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItemStack>
          <div className="caption text-medium">More detail</div>
          <StyledMenuItem button onClick={onOpenEnrollmentData}>
            Provider data
          </StyledMenuItem>
          <StyledMenuItem button onClick={onOpenAddressesDrawer}>
            Provider addresses
          </StyledMenuItem>
          <hr />
          <div className="caption text-medium mb-xs">Update</div>
          {!followProviderEnrollment && (
            <Select
              label="Status"
              className="pb-2"
              value={status}
              onChange={e => handleSave({ id, payerStatus: e.currentTarget.value as AddressEnrollmentStatus })}
            >
              <option value={status}>{startCase(status)}</option>
              {availableStatuses.map(newStatus => (
                <option key={newStatus} value={newStatus}>
                  {startCase(newStatus)}
                </option>
              ))}
            </Select>
          )}

          <Select
            label="Submission type"
            className="pb-2"
            value={selectedType}
            onChange={e =>
              handleSave({ id, followProviderEnrollment: e.currentTarget.value === AddressEnrollmentType.Enrollment })
            }
          >
            {Object.values(AddressEnrollmentType).map(type => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Select>
        </MenuItemStack>
      </StyledMenu>
    </>
  )
}

const StyledMenu = styled(Menu)`
  .MuiPopover-paper {
    padding: 0.5rem;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.menu-item {
    padding: 0.5rem;
  }
`
