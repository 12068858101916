import { ApolloError } from '@apollo/client'
import { useMonaco } from '@monaco-editor/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePayerRosterDefinitionQuery, useSavePayerRosterDefinitionMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, toast } from '@nuna/tunic'

import { CodeEditor } from '../../../../../../shared/Editor/Editor'
import { cleanPayerRosterDefinitionConfig } from '../PayerRosterDefinition.util'
import { payerRosterDefinitionSchema } from '../payer-roster-definition-schema'

export function PayerRosterDefinitionRawEditor() {
  const [configString, setConfigString] = useState<string | null>(null)
  const { payerRosterDefinitionId = '' } = useParams()
  const { data } = usePayerRosterDefinitionQuery({ variables: { filters: { id: payerRosterDefinitionId } } })
  const [saveRosterDefinition] = useSavePayerRosterDefinitionMutation()

  const monaco = useMonaco()

  useEffect(() => {
    if (monaco) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [payerRosterDefinitionSchema],
      })
    }
  }, [monaco])

  useEffect(() => {
    setConfigString(
      data?.payerRosterDefinition.config
        ? JSON.stringify(cleanPayerRosterDefinitionConfig(data?.payerRosterDefinition.config), null, 2)
        : null,
    )
  }, [data?.payerRosterDefinition.config])

  const handleSave = async () => {
    try {
      const config = JSON.parse(configString || '')
      await saveRosterDefinition({ variables: { payerRosterDefinitionInput: { id: payerRosterDefinitionId, config } } })
      toast.success('Payer roster definition saved successfully')
    } catch (e) {
      if (e instanceof ApolloError) {
        return toast.urgent(errorService.transformGraphQlError(e))
      }

      if ((e as Error).message) {
        return toast.urgent((e as Error).message)
      }

      toast.urgent('An unknown error occurred')
    }
  }

  if (!configString) return null

  return (
    <div className="p-2 pb-4 full-height flex-column">
      <div className="flex-remaining-space">
        <CodeEditor
          editorOptions={{ height: '100%', codeLanguage: 'json' }}
          code={configString}
          onCodeChange={newConfig => setConfigString(newConfig)}
        />
      </div>
      <div className="py-2">
        <FillButton onClick={handleSave}>Save</FillButton>
      </div>
    </div>
  )
}
