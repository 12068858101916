import { useState } from 'react'

import { GhostButton, Switch } from '@nuna/tunic'

import { Filter } from '../../../filters.types'

interface FilterBooleanValueSelectorProps {
  filter: Filter
  onChange: (value: boolean) => void
  initial: boolean
}

export function FilterBooleanValueSelector({ filter, initial, onChange }: FilterBooleanValueSelectorProps) {
  const [internalValue, setInternalValue] = useState<boolean>(!!filter.value as boolean)

  const handleChange = () => {
    if (!initial) {
      onChange(!filter.value)
    } else {
      setInternalValue(!internalValue)
    }
  }

  return (
    <div className="v-align gap-2">
      <Switch checked={filter.value as boolean} onChange={handleChange}>
        {filter.label}
      </Switch>
      {initial && <GhostButton onClick={() => onChange(internalValue)}>Ok</GhostButton>}
    </div>
  )
}
