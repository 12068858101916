import { PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { useProviderListContext } from '../../shared/ProviderListNavigation/ProviderListContextProvider'
import { ProviderListFilters } from '../../shared/ProviderListNavigation/ProviderListFilters'
import { ProviderTable } from './ProviderTable'

export function ProvidersIndex() {
  const { filterValues } = useProviderListContext()

  return (
    <PageWrapper>
      <PageHeader border={false} withBottomMargin>
        <div className="v-align full-width">
          <PageHeading withDivider className="mb-0 mr-2">
            Providers
          </PageHeading>
          <ProviderListFilters className="flex-remaining-space" />
        </div>
      </PageHeader>
      <PageContent $paddingTop={false}>
        <ProviderTable filterOptions={filterValues} />
      </PageContent>
    </PageWrapper>
  )
}
