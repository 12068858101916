import { styled } from '@mui/material'
import moment from 'moment'
import { HTMLAttributes } from 'react'

import { DocumentType, usePayerRosterArtifactQuery, usePublishPayerRosterMutation } from '@nuna/api'
import { useDownloadDocument } from '@nuna/common'
import { errorService } from '@nuna/core'
import { ContextualAlert, FileTypeIcon, FillButton, GhostButtonLink, csx, toast } from '@nuna/tunic'

import { useRosterValidation } from '../../MasterRoster/hooks/useRosterValidation'
import { DeleteRosterButton } from './DeleteRosterButton'
import { RosterDataValidationStatus } from './RosterDataValidationStatus'

interface PayerRosterPublishingProps extends HTMLAttributes<HTMLDivElement> {
  payerRosterDefinitionId?: string | null
}

export function PayerRosterPublishing({ payerRosterDefinitionId, className, ...props }: PayerRosterPublishingProps) {
  const { isValid, loading: rosterValidationLoading, revalidate } = useRosterValidation(payerRosterDefinitionId)

  const [downloadDocument, downloadDocumentRef] = useDownloadDocument()

  const [publishRoster] = usePublishPayerRosterMutation()

  const { data } = usePayerRosterArtifactQuery({
    variables: { filters: { payerRosterDefinitionId, mostRecent: true } },
    skip: !payerRosterDefinitionId,
  })

  const mostRecentRoster = data?.payerRosterArtifact

  const mostRecentDocument = mostRecentRoster?.payerRosterArtifactDocuments.find(
    doc => doc.document.documentType === DocumentType.PayerTavaRoster,
  )?.document

  const handlePublishRoster = async () => {
    try {
      await publishRoster({ variables: { payerRosterDefinitionId: payerRosterDefinitionId ?? '' } })
      toast.success('Roster publishing. Check back in a few minutes.')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to publish roster'))
    }
  }

  if (!payerRosterDefinitionId) {
    return null
  }

  return (
    <div {...props} className={csx([className, 'v-align gap-2 flex-remaining-space'])}>
      {mostRecentRoster && mostRecentDocument && (
        <div ref={downloadDocumentRef}>
          <ContextualAlert intent="information">
            <div>
              <div className="body text-medium mb-xs v-align">
                Most Recent Roster
                <span className="caption italic ml-1 text-secondary">
                  {moment(mostRecentRoster.createdAt).format('lll')}
                </span>
              </div>
              <StyledElementActions>
                <a
                  href={mostRecentDocument.downloadUrl ?? ''}
                  onClick={e => {
                    e.preventDefault()
                    downloadDocument(mostRecentDocument.id)
                  }}
                  className="v-align"
                >
                  <FileTypeIcon mimeType={mostRecentDocument.mimeType} /> {mostRecentDocument.fileName}
                </a>
                <DeleteRosterButton rosterArtifactId={mostRecentRoster.id} />
              </StyledElementActions>
            </div>
          </ContextualAlert>
        </div>
      )}
      <div className="ml-auto v-align gap-2">
        {!isValid && (
          <RosterDataValidationStatus
            validationLoading={rosterValidationLoading}
            revalidate={revalidate}
            isValid={isValid}
          />
        )}
        <FillButton disabled={!isValid || rosterValidationLoading} onClick={handlePublishRoster}>
          Publish Current
        </FillButton>
        <GhostButtonLink to={`../definitions/${payerRosterDefinitionId}`}>Edit</GhostButtonLink>
      </div>
    </div>
  )
}

const StyledElementActions = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  button svg {
    margin-right: 0;
  }
`
