import { styled } from '@mui/material'
import { HTMLAttributes, useState } from 'react'

import { Login } from '@nuna/api'
import { Chip } from '@nuna/tunic'

import { AdminSelect } from '../AdminSelect'

type Assignee = Pick<Login, 'id' | 'firstName' | 'lastName'>

interface AssigneePickerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'value' | 'onChange'> {
  value?: Assignee | null
  label?: string
  onChange: (assigneeId: string | null) => void
}

export function AssigneePicker({ value, onChange, label = '', ...props }: AssigneePickerProps) {
  const [assignee, setAssignee] = useState(value)

  const handleChange = (assignee: Assignee | null | undefined) => {
    setAssignee(assignee)
    onChange(assignee?.id ?? null)
  }

  return (
    <Container {...props}>
      {assignee && (
        <>
          {label && <label className="label mb-xs">{label}</label>}
          <Chip className="assignee-chip" small onRemove={() => handleChange(null)}>
            {assignee.firstName} {assignee.lastName}
          </Chip>
        </>
      )}
      {!assignee && <AdminSelect label={label} placeholder="Type to filter" value={null} onChange={handleChange} />}
    </Container>
  )
}

const Container = styled('div')`
  .label {
    display: block;
  }
  .assignee-chip {
    line-height: 1.2;
    .chip-container {
      padding: 2px 8px 2px 4px;
    }
    button.remove-button {
      height: 12px;
      width: 12px;
    }
  }
`
