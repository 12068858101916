import { DialogActions, DialogTitle } from '@mui/material'
import { last, omit } from 'lodash'
import moment from 'moment-timezone'
import { useState } from 'react'

import { ContractType, PatientCompanyHistoryQuery, useUpdatePatientCompanyHistoryMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import {
  Dialog,
  DialogContent,
  FillButton,
  GhostButton,
  RadioCard,
  TextButtonExternalLink,
  greySet,
  toast,
} from '@nuna/tunic'

type PatientCompanyHistory = PatientCompanyHistoryQuery['patientCompanyHistory']
type CompanyHistoryItem = PatientCompanyHistory['historyItems'][number]

interface Props {
  history: PatientCompanyHistory
  deleteIndex: number
  onClose: () => void
}

export function DeleteEAPHistoryItemDialog({ history, deleteIndex, onClose }: Props) {
  const [moveDatesEarlier, setMoveDatesEarlier] = useState<boolean>(false)
  const [updatePatientCompanyHistory, { loading }] = useUpdatePatientCompanyHistoryMutation()

  const afterItem = history.historyItems[deleteIndex - 1]
  const removedItem = history.historyItems[deleteIndex]
  const beforeItem =
    history.historyItems.length > deleteIndex + 1
      ? history.historyItems[deleteIndex + 1]
      : { firstAssignedDate: history.patientCreatedDate, contractType: ContractType.CashContract }

  function itemTitle(item: Pick<CompanyHistoryItem, 'contractType' | 'companyContractSummary'>) {
    switch (item.contractType) {
      case ContractType.CashContract:
        return 'Cash Pay'
      case ContractType.SponsoredCompanyContract:
      case ContractType.CompanyContract:
        return item.companyContractSummary?.companyName ?? 'Unknown Company'
      default:
        return 'Free Coupon'
    }
  }

  const handleAssociateMember = async () => {
    try {
      // Create an altered history based on the direction we want to move dates
      const newHistory = history.historyItems.reduce((items, currentItem, index) => {
        if (moveDatesEarlier && index + 1 === deleteIndex) {
          // When we want to move dates earlier take the next items first assigned date
          items.push(
            Object.assign({}, currentItem, { firstAssignedDate: history.historyItems[index + 1].firstAssignedDate }),
          )
        } else if (!moveDatesEarlier && index === deleteIndex + 1) {
          items.push(
            Object.assign({}, currentItem, { lastUnassignedDate: history.historyItems[index - 1].lastUnassignedDate }),
          )
        } else if (index !== deleteIndex) {
          items.push(currentItem)
        }
        return items
      }, [] as CompanyHistoryItem[])

      // If last item and they chose to move up to patient start date with cash contract, we need to add one
      const cashContractItem = history.historyItems.find(item => item.contractType === ContractType.CashContract)
      if (cashContractItem && !moveDatesEarlier && history.historyItems.length === deleteIndex + 1) {
        newHistory.push({
          ...cashContractItem,
          firstAssignedDate: history.patientCreatedDate,
          lastUnassignedDate: moment(last(newHistory)?.firstAssignedDate).subtract(1, 'millisecond').toISOString(),
        })
      }

      const response = await updatePatientCompanyHistory({
        variables: {
          patientId: history.patientId,
          historyItems: newHistory.map(item => ({
            ...omit(item, [
              '__typename',
              'companyContractSummary',
              'couponContractSummary',
              'linkedEmployee',
              'linkedSponsor',
            ]),
            companyId: item.companyContractSummary?.companyId,
            contractId: item.couponContractSummary?.contractId,
            eligibleRecordId: item.linkedEmployee?.id ?? null,
            sponsoringEmployeeId: item.linkedSponsor?.id ?? null,
          })),
        },
        refetchQueries: ['PatientCompanyHistory'],
      })

      if (response.errors) {
        toast.urgent(errorService.transformUserGraphqlError(response.errors[0], 'Unable to update history'))
      } else {
        toast.success('Successfully deleted history item')
        onClose()
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Unable to update history'))
    }
  }

  return (
    <Dialog isOpen={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Remove Item From History</DialogTitle>
      <DialogContent paddingSize="md">
        <p className="body">
          By changing contract periods, appointments may incorrectly be attributed to previous contracts. Please correct
          these separately in{' '}
          <TextButtonExternalLink href="https://retool.tavahealth.com/apps/baaae650-2297-11ee-9a1b-b3dfe1c03fe1/Patients/Manage%20Session">
            retool
          </TextButtonExternalLink>
        </p>
        <p className="body">Which history item should fill the time created by removing this contract?</p>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 8 }}>
          <div className="p-1 text-center">
            <RadioCard
              checked={!moveDatesEarlier}
              text={moment.tz(beforeItem.firstAssignedDate, history.patientTimezone).format('ll')}
              subtext={itemTitle(beforeItem)}
              onChange={() => setMoveDatesEarlier(false)}
              compact
            />
          </div>
          <div className="m-1 p-2 text-center rounded" style={{ border: `1px dotted ${greySet[15].hex}` }}>
            <p>{moment.tz(removedItem.firstAssignedDate, history.patientTimezone).format('ll')}</p>
            <p>{itemTitle(removedItem)}</p>
          </div>
          <div className="p-1">
            <RadioCard
              checked={moveDatesEarlier}
              text={moment.tz(afterItem.firstAssignedDate, history.patientTimezone).format('ll')}
              subtext={itemTitle(afterItem)}
              onChange={() => setMoveDatesEarlier(true)}
              compact
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={onClose}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={() => handleAssociateMember()} isLoading={loading}>
          Save
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
