import React from 'react'

import { CredentialingChecklistDataPoint, Maybe } from '@nuna/api'
import { Box, MenuItem, MenuItemStack, Stack, borderGrey } from '@nuna/tunic'

import { useCredentialingChecklistContext } from '../Context/CredentialingChecklistContextProvider'

type Props = {
  selectedItem: CredentialingChecklistDataPoint
  setSelectedEntity: (entity: CredentialingChecklistDataPoint) => void
}
export const ChecklistEducationList = ({ selectedItem, setSelectedEntity }: Props) => {
  const context = useCredentialingChecklistContext()
  const { checklist } = context

  const isActive = (elementId?: Maybe<string>): boolean => {
    if (selectedItem?.tid && elementId) {
      return selectedItem.tid === elementId
    }
    return false
  }

  return (
    <Stack
      direction={'column'}
      sx={{ width: '280px', height: '100%', p: 2, overflowY: 'auto', borderRight: `1px solid ${borderGrey}` }}
    >
      <MenuItemStack>
        {checklist?.education &&
          checklist.education.map(edu => (
            <MenuItem
              button
              onClick={() => {
                setSelectedEntity(edu)
              }}
              key={edu.tid}
              isActive={() => {
                return isActive(edu.tid)
              }}
            >
              <Box sx={{ justifyContent: 'flex-start' }}>
                <div>{edu.description}</div>
              </Box>
            </MenuItem>
          ))}
      </MenuItemStack>
    </Stack>
  )
}
