import { compact, groupBy } from 'lodash'
import { useMemo } from 'react'

import { ProviderCompensationRateFragment } from '@nuna/api'
import { compensationService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'

interface Props {
  rates: ProviderCompensationRateFragment[]
}

interface RowData extends Record<string, string> {
  payer: string
}

export function SelfReferralInsuranceRatesTable({ rates }: Props) {
  const groupedByPayer = useMemo(() => groupBy(rates, r => r.description), [rates])
  const columns: DataTableColumn<RowData>[] = useMemo(() => {
    if (Object.keys(groupedByPayer).length === 0) {
      return []
    }

    const examplePayerRates: ProviderCompensationRateFragment[] = Object.keys(groupedByPayer).reduce(
      (current: ProviderCompensationRateFragment[], key) => {
        if (groupedByPayer[key].length > current.length) {
          return groupedByPayer[key]
        }
        return current
      },
      [],
    )

    const rateDescriptions = compact(examplePayerRates.map(r => r.shortDescription))

    return [
      { accessor: 'payer', Header: 'Payer' },
      ...rateDescriptions.map(accessor => ({ accessor, Header: accessor })),
    ]
  }, [groupedByPayer])

  const rowData: RowData[] = useMemo(() => {
    const groupedByPayer = groupBy(rates, r => r.description)
    return Object.keys(groupedByPayer).map(payer => {
      return {
        payer,
        ...groupedByPayer[payer].reduce((acc: Record<string, string>, rate) => {
          if (rate.shortDescription) {
            acc[rate.shortDescription] = compensationService.compensationRangeDisplay(rate)
          }
          return acc
        }, {}),
      }
    })
  }, [rates])

  return <DataTable columns={columns} rowData={rowData} loading={false} />
}
