import { styled } from '@mui/material'
import { ReactNode } from 'react'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { usePayerRosterDefinitionQuery } from '@nuna/api'
import { Grid, MenuItem, ResponsiveHorizontalMenu, borderGrey, eggshell, makeTypographyComponent } from '@nuna/tunic'

import { insuranceRosters } from '../../../util/routes'
import { CurrentPayerRosterPreview } from './components/CurrentPayerRosterPreview'
import { PayerRosterPublishing } from './components/PayerRosterPublishing'
import { PayerRosterOption, PayerRosterSelect } from './components/PayerRosterSelect'
import { PreviousPayerRosters } from './components/PreviousPayerRosters'

export function PayerRosters() {
  const { id = null } = useParams()
  const navigate = useNavigate()

  const { data, loading } = usePayerRosterDefinitionQuery({
    variables: { filters: { id, mostRecentMajorVersion: true } },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  })

  const handleRosterSelect = (option?: PayerRosterOption | null) => {
    if (option) {
      navigate(`${insuranceRosters}/rosters/${option.id}`)
    }
  }

  const version = data?.payerRosterDefinition?.config.version

  return (
    <>
      <TopSection>
        <Grid container spacing={5} className="roster-details">
          <Grid style={{ maxWidth: 400 }} size={{ sm: 6 }}>
            <PayerRosterSelect placeholder="Select roster" value={id} onChange={handleRosterSelect} />
            {version && <div className="caption italic text-secondary pt-1">Version: {version}</div>}
          </Grid>
          {!loading && (
            <Grid className="v-align flex-remaining-space">
              <PayerRosterPublishing className="ml-2 flex-remaining-space" payerRosterDefinitionId={id} />
            </Grid>
          )}
        </Grid>
      </TopSection>

      <BottomSection>
        {!loading && data && (
          <SubNav>
            <Routes>
              <Route index element={<Navigate to="current" replace />} />
              <Route
                path="current/*"
                element={<CurrentPayerRosterPreview rosterDefinition={data?.payerRosterDefinition} />}
              />
              <Route path="previous" element={<PreviousPayerRosters />} />
            </Routes>
          </SubNav>
        )}
      </BottomSection>
    </>
  )
}

interface SubNavProps {
  children: ReactNode
}
function SubNav({ children }: SubNavProps) {
  return (
    <>
      <ResponsiveHorizontalMenu>
        <MenuItem to="current">Live Preview</MenuItem>
        <MenuItem to="Previous">Previous Rosters</MenuItem>
      </ResponsiveHorizontalMenu>
      <OutletContainer>{children}</OutletContainer>
    </>
  )
}

const TopSection = styled(makeTypographyComponent('p-2', 'div'))`
  background-color: ${eggshell};
  border-bottom: 1px solid ${borderGrey};
  .roster-details {
    background-color: ${eggshell};
  }
`

const BottomSection = makeTypographyComponent('px-2 py-4 flex-remaining-space flex-column', 'div')

const OutletContainer = styled(makeTypographyComponent('pt-2 flex-column flex-remaining-space', 'div'))`
  border-top: 1px solid #eee;
`
