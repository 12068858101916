import { Container } from '@mui/material'
import { useEffect, useState } from 'react'

import { useProviderActivityQuery } from '@nuna/api'
import { CAQHSetupContextProvider } from '@nuna/caqh-setup'
import { errorService } from '@nuna/core'
import { Drawer, GhostButton, OutlineButton, toast } from '@nuna/tunic'

import { ProviderActivityLifecycle } from './ProviderActivityLifecycle'
import { ProviderActivityLog } from './ProviderActivityLog/ProviderActivityLog'
import { ProviderCaqhInformation } from './ProviderCaqhInformation'
import { ProviderFullstorySessions } from './ProviderFullstorySessions'
import { ProviderPanelingLifecycle } from './ProviderPanelingLifecycle'

interface Props {
  providerId: string
}

export function ProviderActivity({ providerId }: Props) {
  const isAddingNoteState = useState(false)
  const [isAddingNote, setIsAddingNote] = isAddingNoteState
  const { data, error } = useProviderActivityQuery({ variables: { providerId } })

  const [isFullstoryDrawerOpen, setIsFullstoryDrawerOpen] = useState(false)

  const status = data?.provider.status
  const panelingInfo = data?.providerPaneling

  const handleAddNoteClick = () => {
    if (!isAddingNote) {
      setIsAddingNote(true)
    }
  }

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a problem loading provider activity data'))
    }
  }, [error])

  return (
    <Container
      style={{
        padding: '16px 0',
      }}
    >
      <h4>Lifecycle Journeys</h4>

      {status && (
        <>
          <ProviderActivityLifecycle providerId={providerId} status={status} />
          {panelingInfo && (
            <ProviderPanelingLifecycle providerId={providerId} panelingInfo={panelingInfo} className="mt-2" />
          )}

          <CAQHSetupContextProvider providerId={providerId} providerLoginId={data?.provider.loginId}>
            <ProviderCaqhInformation className="mt-2" />
          </CAQHSetupContextProvider>
        </>
      )}

      <div className="mt-6 space-between">
        <h4 className="m-0">Activity Log</h4>

        <div className="v-align">
          <GhostButton variant={'secondary'} onClick={() => setIsFullstoryDrawerOpen(true)}>
            Fullstory Sessions
          </GhostButton>
          <OutlineButton variant="secondary" onClick={handleAddNoteClick} className="ml-2">
            Add Note
          </OutlineButton>
        </div>
      </div>

      <Drawer isOpen={isFullstoryDrawerOpen} onClose={() => setIsFullstoryDrawerOpen(false)} size="400px">
        <div style={{ padding: '1.2rem' }}>
          <ProviderFullstorySessions loginId={data?.provider.loginId} />
        </div>
      </Drawer>

      <ProviderActivityLog providerId={providerId} isAddingNoteState={isAddingNoteState} />
    </Container>
  )
}
