import { Form, Formik } from 'formik'
import { noop } from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { ProviderCredentialing, ProviderCredentialingStatus } from '@nuna/api'
import { providerService } from '@nuna/core'
import { DatePicker, FillButton, Grid, Select } from '@nuna/tunic'

interface FormValues {
  status: ProviderCredentialingStatus
  credentialedDate?: moment.Moment | null
}

interface Props {
  providerCredentialing: Pick<
    ProviderCredentialing,
    'status' | 'providerId' | 'recredentialDueDate' | 'initialCredentialedDate' | 'id'
  >
}

export function CredentialingStatusForm({ providerCredentialing }: Props) {
  const initialValues: FormValues = useMemo(
    () => ({
      status: providerCredentialing.status,
      credentialedDate: providerService.mostRecentCredentialingDate(providerCredentialing) ?? null,
    }),
    [providerCredentialing],
  )
  return (
    <Formik initialValues={initialValues} onSubmit={noop}>
      {({ values, setFieldValue, getFieldProps }) => (
        <Form>
          <Grid container spacing={5}>
            <Grid>
              <Select label="Status" {...getFieldProps('status')}>
                {Object.values(ProviderCredentialingStatus).map(value => (
                  <option key={value} value={value}>
                    {providerService.readableCredentialingStatus(value)}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid>
              <DatePicker
                label="Credentialed Date"
                value={values.credentialedDate ?? null}
                onChange={date => setFieldValue('credentialedDate', moment(date).startOf('day'))}
              />
            </Grid>
            <Grid className="flex-remaining-space v-align">
              <FillButton className="ml-auto">Save</FillButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
