import { keyBy, sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import { MdAdd } from 'react-icons/md'

import {
  InsurancePayerSortKeys,
  InsuranceTaxEntityFragment,
  OrderBy,
  PayerListItemFragment,
  useInsurancePayersQuery,
  useInsuranceTaxEntitiesQuery,
} from '@nuna/api'
import { useDownloadDocument } from '@nuna/common'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { Chip, FillButton, PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { TaxEntityForm } from './TaxEntityForm'
import { TaxEntityRow } from './TaxEntityRow'

export function TaxEntityList() {
  const [showNewForm, setShowNewForm] = useState(false)
  const { data, loading, refetch } = useInsuranceTaxEntitiesQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [downloadDocument, containerRef] = useDownloadDocument()

  const rowData = useMemo<InsuranceTaxEntityFragment[]>(() => {
    return sortBy(data?.insuranceTaxEntities || [], 'organizationName')
  }, [data])

  const { data: payerData, loading: payerLoading } = useInsurancePayersQuery({
    variables: {
      pagination: {
        limit: 1000,
      },
      order: [{ direction: OrderBy.Asc, key: InsurancePayerSortKeys.Name }],
    },
    fetchPolicy: 'cache-and-network',
  })

  const payers = useMemo(() => (payerData?.insurancePayers?.items as PayerListItemFragment[]) || [], [payerData])
  const payersKeyed = keyBy(payers, 'id')
  const columns = useMemo<DataTableColumn<InsuranceTaxEntityFragment>[]>(
    () => [
      {
        Header: 'Organization Name',
        accessor: 'organizationName',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'NPI',
        accessor: 'npi',
      },
      {
        Header: 'EIN',
        accessor: 'ein',
      },
      {
        Header: 'Default',
        accessor: 'isDefault',
        Cell: ({ value }) => <>{value.toString()}</>,
      },
      {
        Header: 'Included Payers',
        accessor: 'includedPayerIds',
        width: '400px',
        Cell: ({ value }) => (
          <>
            {value
              .filter(payerId => !!payersKeyed[payerId])
              .map((payerId, index) => (
                <Chip key={payerId} checked className={'mt-1 ' + (index !== 0 ? 'ml-1' : '')}>
                  {payersKeyed[payerId].name}
                </Chip>
              ))}
          </>
        ),
      },
      {
        Header: 'EIN Document',
        accessor: 'einDocument',
        Cell: ({ value }) => (
          <a
            download
            href={value?.downloadUrl ?? ''}
            onClick={e => {
              e.preventDefault()
              downloadDocument(value?.id ?? '')
            }}
          >
            {value?.fileName}
          </a>
        ),
      },
      {
        Header: <span style={{ paddingRight: '0.5rem' }}>Edit</span>,
        accessor: 'id',
        className: 'right-align',
      },
    ],
    [payersKeyed, downloadDocument],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin>
          <PageHeading className="mb-0">Insurance Tax Entities</PageHeading>
        </PageHeader>
        <PageContent $paddingTop={false} style={{ maxWidth: '1280px' }} ref={containerRef}>
          <DataTable
            rowData={rowData}
            columns={columns}
            loading={loading || payerLoading}
            customRow={rowProps => {
              const { key, ...spreadRowProps } = rowProps
              return <TaxEntityRow key={key} {...spreadRowProps} />
            }}
          />
          {showNewForm && (
            <TaxEntityForm
              className="mt-4"
              onSave={() => {
                setShowNewForm(false)
                refetch()
              }}
              onCancel={() => setShowNewForm(false)}
            />
          )}

          {!showNewForm && (
            <FillButton leadingIcon className="mt-4" onClick={() => setShowNewForm(true)}>
              <MdAdd size={24} /> New
            </FillButton>
          )}
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
