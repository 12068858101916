import { styled } from '@mui/material'
import { Form } from 'formik'

import { makeTypographyComponent } from '@nuna/tunic'

export const EntityForm = styled(Form)`
  max-width: 40rem;
`
export const EntityFormSection = makeTypographyComponent('mb-3', 'section')
export const EntityFormButtonRow = makeTypographyComponent('flex-end mt-5 mb-3', 'section')
