import { useIntakeProgressQuery, useSavePatientIntakeMutation } from '@nuna/api'
import { Switch, toast } from '@nuna/tunic'

interface Props {
  patientId: string
}

export function PatientIntake({ patientId }: Props) {
  const [savePatientIntake] = useSavePatientIntakeMutation()
  const { data } = useIntakeProgressQuery({
    variables: {
      id: patientId,
    },
  })

  const completed = data?.intakeProgress?.intakeCompleted

  const handleIntakeToggle = async () => {
    await savePatientIntake({
      variables: {
        id: patientId,
        input: {
          intakeCompleted: !completed,
        },
      },
      optimisticResponse: {
        savePatientIntake: {
          __typename: 'PatientIntake',
          id: patientId,
          intakeCompleted: !completed,
        },
      },
    })

    toast.info('Patient intake changed')
  }

  return (
    <tr>
      <td>
        <Switch checked={completed} onChange={handleIntakeToggle}>
          Intake Complete
        </Switch>
      </td>
      <td colSpan={3}>
        {completed
          ? 'Patient intake is complete. The user will NOT be prompted to finish their intake.'
          : 'Patient intake is NOT complete. The user will be prompted to finish their intake.'}
      </td>
    </tr>
  )
}
