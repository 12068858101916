import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CompanyFilterInput,
  OrderBy,
  SearchCompaniesPaginatedQuery,
  TraversablePaginationSortInput,
  useSearchCompaniesPaginatedLazyQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { PageContent, PageHeader, PageHeading, PageSearch, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { partnerAdminCustomerDetail } from '../../util/routes'

type PartnerFilterValues = Omit<CompanyFilterInput, 'search'> & {
  searchTerm?: string | null
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'name', direction: OrderBy.Asc }]

export function EAPs() {
  const [searchText, setSearchText] = useState('')
  const [searchCompanies, { data: companiesData, loading }] = useSearchCompaniesPaginatedLazyQuery()
  const { login } = useAuthDataContext()
  const { partnerId } = login || {}

  const INITIAL_FILTER_VALUES: PartnerFilterValues = useMemo(
    () => ({
      searchTerm: '',
      partnerId,
    }),
    [partnerId],
  )
  const { filterValues, setFilterVal } = useDataTableFiltering(INITIAL_FILTER_VALUES)
  const buildFilterValues = useCallback(
    (values: PartnerFilterValues = INITIAL_FILTER_VALUES): CompanyFilterInput => {
      const { searchTerm, hrisEnabled, id } = values
      return {
        search: {
          term: searchTerm,
          threshold: 0.2,
        },
        hrisEnabled,
        id,
      }
    },
    [INITIAL_FILTER_VALUES],
  )
  const navigate = useNavigate()
  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination({
    pagination: companiesData?.searchCompaniesPaginated.pagination,
    loading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const search = useMemo(
    () => debounce((searchText?: string | null) => setFilterVal('searchTerm', searchText), 400),
    [setFilterVal],
  )

  useEffect(() => {
    search(searchText)
  }, [search, searchText])

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    searchCompanies({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
    })
  }, [searchCompanies, queryOptions, buildFilterValues])

  const rowData = useMemo(() => companiesData?.searchCompaniesPaginated.items, [companiesData])

  const columns = useMemo<
    DataTableColumn<
      Pick<
        NonNullable<SearchCompaniesPaginatedQuery['searchCompaniesPaginated']>['items'][0],
        '__typename' | 'id' | 'name'
      >
    >[]
  >(
    () => [
      {
        accessor: 'name',
        id: 'name',
        Header: 'Name',
        Cell: ({ value, row }) => <Link to={partnerAdminCustomerDetail(row.original.id)}>{value}</Link>,
      },
    ],
    [],
  )
  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader withBottomMargin>
          <PageHeading className="m-0">EAPs</PageHeading>
          <PageSearch
            onChange={event => setSearchText(event.currentTarget.value)}
            placeholder="Search all EAPs"
            value={searchText}
            onClear={() => setSearchText('')}
            autoFocus={true}
          />
        </PageHeader>
        <PageContent>
          <DataTable
            paginated
            paginatorProps={getPaginatorProps()}
            onSort={handleSort}
            columns={columns}
            rowData={rowData}
            initialState={initialTableState}
            loading={loading}
            onRowClick={row => navigate(partnerAdminCustomerDetail(row.id))}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
