import { useMemo } from 'react'

import { useProviderDataDenormalizedColumnMetadataQuery } from '@nuna/api'

import { getCustomColumnMetaData } from '../PayerRosterDefinition.util'

export function useColumnMetaData() {
  const { data: resultData, error, loading } = useProviderDataDenormalizedColumnMetadataQuery()

  const columnMetaData = useMemo(
    () =>
      resultData ? [...getCustomColumnMetaData(), ...resultData.providerDataDenormalizedColumnMetadata] : resultData,
    [resultData],
  )

  return { columnMetaData, error, loading }
}
