import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { includes, noop, omit } from 'lodash'
import { FocusEventHandler, ReactNode, useMemo } from 'react'

import { InsurancePayerSortKeys, OrderBy, PayerListItemFragment, useInsurancePayersQuery } from '@nuna/api'
import { Checkbox, Chip } from '@nuna/tunic'

interface Props
  extends Pick<
    AutocompleteProps<PayerListItemFragment, true, false, false>,
    'onChange' | 'style' | 'className' | 'disabled'
  > {
  onRemove: (payer: PayerListItemFragment) => void
  value: string[]
  error: boolean
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  helperText?: string | ReactNode
}

export function TaxEntityPayerSelect({ error, helperText, onRemove, onBlur, ...props }: Props) {
  const { data } = useInsurancePayersQuery({
    variables: {
      pagination: {
        limit: 1000,
      },
      order: [{ direction: OrderBy.Asc, key: InsurancePayerSortKeys.Name }],
    },
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(() => (data?.insurancePayers?.items as PayerListItemFragment[]) || [], [data])

  const value = options.filter(option => includes(props.value, option.id))

  return (
    <Autocomplete
      multiple
      options={options}
      disabled={props.disabled}
      style={props.style}
      className={props.className}
      onChange={props.onChange}
      value={value}
      getOptionLabel={option => option.name}
      renderOption={(renderOptionProps, option, { selected }) => (
        <li {...renderOptionProps}>
          <Checkbox style={{ pointerEvents: 'none' }} checked={selected} onChange={noop}>
            {option.name}
          </Checkbox>
        </li>
      )}
      renderTags={(payers, getTagProps) => {
        return payers.map((payer, index) => (
          <Chip truncateAt={100} small {...omit(getTagProps({ index }), ['onDelete'])} onRemove={() => onRemove(payer)}>
            {payer.name}
          </Chip>
        ))
      }}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          onBlur={onBlur}
          variant="standard"
          label="Payer ID's"
          placeholder=""
          style={{ minHeight: 0 }}
        />
      )}
    />
  )
}
