import { PageContent } from '@nuna/tunic'

import { ProviderEnrollmentTable } from '../components/ProviderEnrollmentTable/ProviderEnrollmentTable'

export function CurrentEnrollments() {
  return (
    <PageContent className="overflow-hidden flex-column">
      <ProviderEnrollmentTable
        header={<h5 className="serif mb-0">Current Enrollments</h5>}
        columns={[
          'provider',
          'payer',
          'effectiveStatus',
          'payerStatus',
          'submittedDate',
          'effectiveDate',
          'archivedDate',
          'ignoreEnrollment',
          'disableBilling',
          'assignee',
        ]}
      />
    </PageContent>
  )
}
