import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { compact } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { PayerRosterSheetFilter } from '../PayerRosterDefinition.types'

type Option = { value: PayerRosterSheetFilter; label: string }

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<Option | undefined, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface SheetFiltersMultiselectProps extends LimitedAutocompleteProps {
  value?: PayerRosterSheetFilter[]
  onChange: (options: Option[]) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
}

export function SheetFiltersMultielect({
  value: valueInput = [],
  error,
  helperText,
  placeholder,
  label,
  onChange,
  ...props
}: SheetFiltersMultiselectProps) {
  const [searchTerm, setSearchTerm] = useState('')

  const options = useMemo(() => buildOptions(), [])

  const value = useMemo<Option[]>(
    () => options.filter(option => valueInput.includes(option.value)),
    [valueInput, options],
  )

  const filteredOptions = useMemo<Option[]>(() => {
    if (!searchTerm) {
      return options
    }
    const searcher = new FuzzySearch(options, ['name', 'key'], {
      caseSensitive: false,
    })

    return searcher.search(searchTerm)
  }, [searchTerm, options])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(compact(value))
      }}
      defaultValue={[]}
      getOptionLabel={option => option?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={filteredOptions}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          name="selected-filters"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  )
}

function buildOptions(): Option[] {
  return Object.values(PayerRosterSheetFilter).map(changeset => ({
    value: changeset,
    label: getOptionLabel(changeset),
  }))
}

function getOptionLabel(value: PayerRosterSheetFilter) {
  switch (value) {
    case PayerRosterSheetFilter.Add:
      return 'New Adds'
    case PayerRosterSheetFilter.Change:
      return 'Changes'
    case PayerRosterSheetFilter.NoChange:
      return 'No Changes'
    case PayerRosterSheetFilter.Recredentialed:
      return 'Recredentialed'
    case PayerRosterSheetFilter.Term:
      return 'Termed'
  }
}
