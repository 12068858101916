import { Autocomplete, AutocompleteProps, InputProps, TextField } from '@mui/material'
import { throttle } from 'lodash'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import { SearchPartnersQuery, useSearchPartnersLazyQuery, useSearchPartnersQuery } from '@nuna/api'

type Partner = SearchPartnersQuery['searchPartners']['items'][0]

interface Props {
  label?: string
  value?: Partner['id'] | string | null
  error?: boolean
  helperText?: string | ReactNode
  onChange: (partnerId: Partner['id'] | null) => void
  onBlur?: InputProps['onBlur']
}

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<Partner, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

export function PartnerSelect({
  value,
  onChange,
  label = 'Partner',
  helperText,
  error = false,
  onBlur,
  ...props
}: Props & LimitedAutocompleteProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectionMade, setSelectionMade] = useState(false)
  const [searchPartners, { data: partnersData, loading: searchLoading }] = useSearchPartnersLazyQuery({
    fetchPolicy: 'network-only',
  })
  // the initial partner may not be in the list of partners fetched from the server as it is paginated
  const { data: initialPartnerData, loading: loadingInitial } = useSearchPartnersQuery({
    variables: {
      filters: {
        id: value,
      },
      pagination: { limit: 1 },
    },
    skip: !value,
  })
  const initialPartner = initialPartnerData?.searchPartners.items[0]

  const partners = useMemo(() => {
    const fetchedPartners = partnersData?.searchPartners.items ?? []
    if (initialPartner && !fetchedPartners.find(p => p.id === initialPartner.id)) {
      return [initialPartner, ...fetchedPartners]
    }
    return fetchedPartners
  }, [partnersData, initialPartner])

  const fetch = useMemo(
    () =>
      throttle((search: string) => {
        // this prevents sending an extra request when the user has already selected a partner
        if (!selectionMade) {
          searchPartners({
            variables: {
              filters: {
                search: {
                  term: search,
                  threshold: 0.1,
                },
              },
              pagination: { limit: 25 },
            },
          })
        }
      }, 500),
    [searchPartners, selectionMade],
  )

  useEffect(() => {
    fetch(searchTerm)
  }, [fetch, searchTerm])

  return (
    <Autocomplete
      loading={searchLoading || loadingInitial}
      onChange={(_event, newValue) => {
        setSelectionMade(true)
        onChange(newValue?.id ?? null)
      }}
      getOptionLabel={partner => partner?.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={partners}
      value={partners.find(p => p.id === value) ?? null}
      renderInput={params => (
        <TextField name="partnerId" error={error} label={label} helperText={helperText} onBlur={onBlur} {...params} />
      )}
      onInputChange={(_event, searchTerm) => {
        setSelectionMade(false)
        setSearchTerm(searchTerm)
      }}
      {...props}
    />
  )
}
