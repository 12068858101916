import { styled } from '@mui/material'
import { omit } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useMemo } from 'react'

import { LiabilityInsurancePolicy, SearchProviderCoisQuery, useSearchProviderCoisQuery } from '@nuna/api'
import { useDownloadDocument } from '@nuna/common'
import { errorService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { FileTypeIcon, FileUpload, toast } from '@nuna/tunic'

import { useUploadProviderCoiDocument } from './hooks/useUploadProviderCoiDocument'

interface ProviderCoiProps extends HTMLAttributes<HTMLDivElement> {
  providerId: string
  loginId?: string | null
}

type Row = Omit<SearchProviderCoisQuery['searchProviderCois']['items'][0], '__typename'> &
  Pick<LiabilityInsurancePolicy, 'name'>

export function ProviderCoi({ providerId, loginId, ...props }: ProviderCoiProps) {
  const [downloadDocument] = useDownloadDocument()
  const [uploadCoi] = useUploadProviderCoiDocument(providerId, loginId)

  const { data, loading, refetch } = useSearchProviderCoisQuery({
    variables: { filters: { providerId }, pagination: { limit: 100 } },
  })
  const rowData = useMemo<Row[]>(
    () =>
      data?.searchProviderCois?.items.map(item => ({ ...item, ...omit(item.liabilityInsurancePolicy, ['id']) })) ?? [],
    [data],
  )
  const columns = useMemo<DataTableColumn<Row>[]>(
    () => [
      { Header: 'Policy', accessor: 'name' },
      {
        Header: 'COI',
        accessor: 'document',
        Cell: ({ value }) => (
          <div className="v-align pb-1">
            <FileTypeIcon mimeType={value.mimeType} className="mr-1" />
            <a
              href={value.fileName}
              onClick={e => {
                e.preventDefault()
                downloadDocument(value.id)
              }}
              download
            >
              {value.fileName}
            </a>
          </div>
        ),
      },
    ],
    [downloadDocument],
  )

  const handleUploadCoi = async (files: File[]) => {
    try {
      await uploadCoi(files, currentCoiId(rowData))
      refetch()
      toast.success('COI uploaded successfully')
    } catch (error) {
      toast.urgent(
        errorService.transformGraphQlError(error, (error as Record<string, string>)?.message ?? 'Failed to upload COI'),
      )
    }
  }

  return (
    <ProviderCoiStyled className="flex-column gap-2" {...props}>
      <FileUpload onDrop={handleUploadCoi} clearOnDrop />
      <DataTable loading={loading} columns={columns} rowData={rowData} />
    </ProviderCoiStyled>
  )
}

const ProviderCoiStyled = styled('div')``

function currentCoiId(cois: Row[]) {
  return cois.find(coi =>
    moment().isBetween(
      coi.liabilityInsurancePolicy.currentEffectiveDate,
      coi.liabilityInsurancePolicy.currentExpirationDate,
    ),
  )?.id
}
