import { useState } from 'react'

import {
  HubspotContact,
  Provider,
  useRemoveProviderHubspotIdMutation,
  useSetProviderHubspotIdMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { GhostButton, OutlineButton, toast } from '@nuna/tunic'

import { HubspotContactRemove } from './HubspotContactRemove/HubspotContactRemove'
import { HubspotContactSelect } from './HubspotContactSelect/HubspotContactSelect'

interface Props {
  provider: Pick<Provider, 'id' | 'hubspotId'>
}

export function ProviderHubspotBadge({ provider }: Props) {
  const [changeDialogOpen, setChangeDialogOpen] = useState(false)
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [setHubspotId] = useSetProviderHubspotIdMutation()
  const [removeHubspotId] = useRemoveProviderHubspotIdMutation()

  const handleSelectClose = async (hubspotContact?: HubspotContact | null) => {
    setChangeDialogOpen(false)

    if (hubspotContact?.hs_object_id) {
      try {
        await setHubspotId({
          variables: { providerId: provider.id, hubspotId: hubspotContact.hs_object_id },
        })
        toast.success('Linked provider to Hubspot')
      } catch (e) {
        console.error(e)
        toast.urgent(errorService.transformGraphQlError(e, 'Failed to link provider to Hubspot'))
      }
    }
  }

  const handleRemoveClose = async (shouldRemove: boolean) => {
    setRemoveDialogOpen(false)

    if (shouldRemove) {
      try {
        await removeHubspotId({
          variables: { providerId: provider.id },
        })

        toast.success('Removed provider Hubspot contact')
      } catch (e) {
        console.error(e)
        toast.urgent(errorService.transformGraphQlError(e, 'Failed to remove provider Hubspot contact'))
      }
    }
  }

  return (
    <div className="v-align" style={{ float: 'left' }}>
      {provider.hubspotId && (
        <div className="flex-column">
          <OutlineButton className="ml-auto" onClick={() => setChangeDialogOpen(true)}>
            Change Hubspot Link
          </OutlineButton>
          <GhostButton
            variant="secondary"
            className="ml-auto mt-2 full-width"
            onClick={() => setRemoveDialogOpen(true)}
          >
            Remove Hubspot Link
          </GhostButton>
        </div>
      )}
      {!provider.hubspotId && (
        <OutlineButton onClick={() => setChangeDialogOpen(true)}>Link with Hubspot</OutlineButton>
      )}
      <HubspotContactSelect isOpen={changeDialogOpen} onClose={handleSelectClose} />
      <HubspotContactRemove isOpen={removeDialogOpen} onClose={handleRemoveClose} />
    </div>
  )
}
