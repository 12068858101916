import * as Sentry from '@sentry/browser'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

import { useAuthDataContext } from '@nuna/auth'
import { sessionCaptureService } from '@nuna/telemetry'

export function Telemetry() {
  const { login } = useAuthDataContext()

  useEffect(() => {
    if (login) {
      if (import.meta.env.PROD) {
        Sentry.setUser({
          email: login ? login.email : '',
          id: login ? login.id : '',
        })

        mixpanel.identify(login.id)
        mixpanel.register({
          id: login.id,
          name: `${login.firstName} ${login.lastName}`,
          role: login.role,
          email: login.email,
        })

        sessionCaptureService.identify(login)
      }
    }
  }, [login])

  return null
}
