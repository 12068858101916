import * as Yup from 'yup'
import { styled } from '@mui/material'
import { FormControl, InputLabel, MenuItem, MenuProps, Select, SelectChangeEvent, Theme, useTheme } from '@mui/material'
import { FormikProps } from 'formik'
import moment from 'moment-timezone'
import { ChangeEvent } from 'react'

import {
  DocumentType,
  GeographicType,
  GeographicalExclusionInput,
  NewCompanyContractDetails,
  PaymentPreference,
  useDocumentUpload,
} from '@nuna/api'
import { coverageService } from '@nuna/core'
import { addressService, formService, numberService } from '@nuna/core'
import { Checkbox, ChipGroup, DatePicker, FileUpload, IconInfo, Radio, TextField, Tooltip, toast } from '@nuna/tunic'

import { InternationalAreaFormSection, internationalAreaSchema } from './InternationalAreaFormSection'
import { PartnerSelect } from './PartnerSelect'
import { CustomerContractFormValues } from './shared'

const { USStates } = addressService
const { composeHelperTextWithError } = formService

export const INITIAL_CUSTOMER_CONTRACT_FORM_VALUES: CustomerContractFormValues = {
  isReferralPartner: false,
  goLiveDate: moment.utc().startOf('day').toISOString(),
  endDate: moment.utc(moment().add(364, 'days'))?.endOf('day').toISOString(),
  hasMedMgmt: false,
  isCoachingOffered: false,
  availableToFullTime: true,
  availableToPartTime: false,
  availableToContractors: false,
  availableToInterns: false,
  availableToBenefitsEnrolled: false,
  availableToBenefitsEligible: false,
  availableToDependents: false,
  availableToMedicalPlanEnrolled: false,
  isInfiniteSessions: false,
  sessionAllowanceToType: 12,
  alternateCoverageSuggestion: PaymentPreference.Insurance,
  postCapPatientSessionCost: 12500,
  capResetDate: moment.utc(moment().add(1, 'year')).startOf('day').toISOString(),
  excludedGeographicalAreas: [],
  benefitsGuideDocumentId: undefined,
  supportedInternationalAreas: [],
  isEligiblityEnforced: true,
  strictlyEnforceRoster: false,
  isAPreviewContract: false,
}

export const customerContractSchema = Yup.object().shape({
  isReferralPartner: Yup.boolean().required('Required'),
  goLiveDate: Yup.date()
    .required('Required')
    .typeError('Required')
    .test('is-before-endDate', 'Go live date must come before the end date', function (value) {
      const { endDate } = this.parent
      return endDate && value ? value < endDate : true
    }),
  endDate: Yup.date()
    .required('Required')
    .typeError('Required')
    .test('is-after-goLiveDate', 'Contract end date must come after the go live date', function (value) {
      const { goLiveDate } = this.parent
      return goLiveDate && value ? value > goLiveDate : true
    }),
  hasMedMgmt: Yup.boolean().required('Required'),
  availableToFullTime: Yup.boolean().required('Required'),
  availableToPartTime: Yup.boolean().required('Required'),
  availableToContractors: Yup.boolean().required('Required'),
  availableToInterns: Yup.boolean().required('Required'),
  availableToBenefitsEnrolled: Yup.boolean().required('Required'),
  availableToBenefitsEligible: Yup.boolean().required('Required'),
  availableToMedicalPlanEnrolled: Yup.boolean().required('Required'),
  availableToDependents: Yup.boolean().required('Required'),
  isCoachingOffered: Yup.boolean().required('Required'),
  isInfiniteSessions: Yup.boolean(),
  sessionAllowanceToType: Yup.number().nullable(),
  alternateCoverageSuggestion: Yup.mixed<PaymentPreference>(),
  postCapPatientSessionCost: Yup.number().required('Required'),
  capResetDate: Yup.date().required('Required').typeError('Required'),
  supportedInternationalAreas: Yup.array(internationalAreaSchema),
  partnerId: Yup.string().when('isReferralPartner', {
    is: true,
    then: Yup.string().nullable().required('Please select a partner or uncheck the referral partner checkbox above'),
    otherwise: Yup.string().nullable(),
  }),
})

enum AVAILABILITY_TYPES {
  FullTime = 'availableToFullTime',
  PartTime = 'availableToPartTime',
  Contractors = 'availableToContractors',
  Interns = 'availableToInterns',
  Dependents = 'availableToDependents',
}

enum ENROLLMENT_TYPES {
  BenefitsEligible = 'availableToBenefitsEligible',
  BenefitsEnrolled = 'availableToBenefitsEnrolled',
  MedicalPlanEnrolled = 'availableToMedicalPlanEnrolled',
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MENU_PROPS: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface Props<T> extends FormikProps<CustomerContractFormValues & T> {
  isEditForm?: boolean
}

export function CustomerContractFormFields<T extends object>({ isEditForm = false, ...formProps }: Props<T>) {
  const theme = useTheme()
  const { uploadDocument } = useDocumentUpload()
  const { getHumanReadablePaymentPreference } = coverageService
  const { values, touched, setFieldValue, handleChange, handleBlur, errors, initialValues } = formProps

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    const values = typeof value === 'string' ? value.split(',') : (value as string[])

    setFieldValue(
      'excludedGeographicalAreas',
      values.map<GeographicalExclusionInput>(value => ({ type: GeographicType.State, value })),
    )
  }

  const uploadBenefitsGuide = async (file: File) => {
    try {
      const document = await uploadDocument(file, DocumentType.BenefitsGuide)

      setFieldValue('benefitsGuideDocumentId', document.id)
    } catch (e) {
      toast.caution((e as Error).message)
    }
  }

  const clearEnrollmentTypes = () => {
    setFieldValue(ENROLLMENT_TYPES.BenefitsEligible, false)
    setFieldValue(ENROLLMENT_TYPES.BenefitsEnrolled, false)
    setFieldValue(ENROLLMENT_TYPES.MedicalPlanEnrolled, false)
  }

  const clearAvailabilityTypes = (clearDependents = false) => {
    setFieldValue(AVAILABILITY_TYPES.FullTime, false)
    setFieldValue(AVAILABILITY_TYPES.PartTime, false)
    setFieldValue(AVAILABILITY_TYPES.Contractors, false)
    setFieldValue(AVAILABILITY_TYPES.Interns, false)

    if (clearDependents) setFieldValue(AVAILABILITY_TYPES.Dependents, false)
  }

  const handleAvailabilityTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, checked },
    } = event

    clearEnrollmentTypes()
    setFieldValue(name, checked)
  }

  const handleOnEnrolledTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event

    clearEnrollmentTypes()
    clearAvailabilityTypes(value === ENROLLMENT_TYPES.MedicalPlanEnrolled)
    setFieldValue(value, true)
  }

  return (
    <>
      <section className="mb-3">
        <Checkbox
          className="mb-2"
          checked={values.isAPreviewContract ?? false}
          name="isAPreviewContract"
          onChange={ev => setFieldValue('isAPreviewContract', ev.target.checked)}
        >
          Trial contract
        </Checkbox>
        <Checkbox
          checked={values.isReferralPartner}
          name="isReferralPartner"
          onChange={ev => setFieldValue('isReferralPartner', ev.target.checked)}
        >
          Care through this customer is managed by a Referral Partner
        </Checkbox>
        {values.isReferralPartner && (
          <PartnerSelect
            label="Partner"
            value={values.partnerId}
            onChange={partner => setFieldValue('partnerId', partner)}
            className="mt-1 mb-2 pl-3"
            {...composeHelperTextWithError('', errors.partnerId, !!touched.partnerId)}
          />
        )}
      </section>
      <section className="mb-3">
        <DatePicker
          name="goLiveDate"
          containerProps={{ className: 'v-align mb-3' }}
          disablePast
          format="dddd, MMM Do, YYYY"
          label="Go live date"
          maxDate={moment(values.endDate) ?? moment().add(100, 'years')}
          onBlur={handleBlur}
          onChange={selectedDate =>
            setFieldValue(
              'goLiveDate',
              selectedDate ? moment.utc(selectedDate).startOf('day').toISOString() : null,
              true,
            )
          }
          value={moment.utc(values.goLiveDate)}
          disabled={isEditForm}
          {...composeHelperTextWithError(
            'The date members can start using the service',
            errors.goLiveDate,
            !!touched.goLiveDate,
          )}
        />
      </section>
      <section className="mb-3">
        <DatePicker
          name="endDate"
          containerProps={{ className: 'v-align mb-3' }}
          disabled={!initialValues.endDate}
          disablePast
          minDate={moment(values.goLiveDate)}
          format="dddd, MMM Do, YYYY"
          label="End date"
          onBlur={handleBlur}
          onChange={selectedDate =>
            setFieldValue('endDate', selectedDate ? moment.utc(selectedDate).endOf('day').toISOString() : null, true)
          }
          value={moment.utc(values.endDate)}
          {...composeHelperTextWithError(
            'The last date that members will be able to use the service on the initial contract',
            errors.endDate,
            !!touched.endDate,
          )}
        />
      </section>
      <section className="mb-3">
        <Checkbox
          checked={values.isInfiniteSessions}
          name="isInfiniteSessions"
          onChange={ev => setFieldValue('isInfiniteSessions', ev.target.checked)}
        >
          Allow infinite sessions covered by this customer
        </Checkbox>
      </section>
      {!values.isInfiniteSessions && (
        <section className="mb-3">
          <TextField
            required
            label="Session cap"
            name="sessionAllowanceToType"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            value={values.sessionAllowanceToType}
            {...composeHelperTextWithError('', errors.sessionAllowanceToType, !!touched.sessionAllowanceToType)}
          />
        </section>
      )}

      {values.sessionAllowanceToType === 0 && (
        <section className="mb-3">
          <MultiSelectFormControl variant="standard">
            <InputLabel id="demo-multiple-name-label">Zero cap payment preference</InputLabel>
            <Select
              className="mt-3"
              labelId="zero-cap-preference"
              name="alternateCoverageSuggestion"
              value={values.alternateCoverageSuggestion}
              onChange={selection => setFieldValue('alternateCoverageSuggestion', selection.target.value)}
              onBlur={handleBlur}
              MenuProps={MENU_PROPS}
            >
              <MenuItem key={PaymentPreference.Insurance} value={PaymentPreference.Insurance}>
                {getHumanReadablePaymentPreference(PaymentPreference.Insurance)}
              </MenuItem>
              <MenuItem key={PaymentPreference.Cash} value={PaymentPreference.Cash}>
                {getHumanReadablePaymentPreference(PaymentPreference.Cash)}
              </MenuItem>
            </Select>
          </MultiSelectFormControl>
        </section>
      )}

      <section className="mb-3">
        <DatePicker
          name="capResetDate"
          containerProps={{ className: 'v-align mb-3' }}
          disablePast
          format="dddd, MMM Do, YYYY"
          label="Cap reset date"
          onChange={selectedDate =>
            setFieldValue('capResetDate', moment.utc(selectedDate)?.startOf('day').toISOString())
          }
          value={values.capResetDate ? moment.utc(values.capResetDate) : null}
          {...composeHelperTextWithError(
            'The date when members remaining sessions available will show as the session limit (i.e. the date on which members who used all of their sessions will be able to start using new sessions)',
            errors.capResetDate,
            !!touched.capResetDate,
          )}
        />
      </section>
      <section className="mb-3">
        <TextField
          required
          label="Session client cost after cap is reached (dollars)"
          name="postCapPatientSessionCost"
          onChange={event => {
            const rawValue = parseFloat(event.currentTarget.value)
            let newValue = 0
            if (!isNaN(rawValue)) {
              newValue = numberService.dollarsToCents(rawValue)
            }
            setFieldValue('postCapPatientSessionCost', newValue)
          }}
          onBlur={handleBlur}
          type="number"
          value={numberService.centsToDollars(values.postCapPatientSessionCost)}
          {...composeHelperTextWithError('', errors.postCapPatientSessionCost, !!touched.sessionAllowanceToType)}
        />
      </section>
      <section className="mb-3">
        <TextField
          label="PEPM fee (dollars)"
          name="pepmFeeInCents"
          onChange={event => {
            const rawValue = parseFloat(event.currentTarget.value)
            let newValue = null
            if (!isNaN(rawValue)) {
              newValue = numberService.dollarsToCents(rawValue)
            }
            setFieldValue('pepmFeeInCents', newValue)
          }}
          onBlur={handleBlur}
          type="number"
          value={values.pepmFeeInCents ? numberService.centsToDollars(values.pepmFeeInCents) : null}
          {...composeHelperTextWithError('', errors.pepmFeeInCents, !!touched.pepmFeeInCents)}
        />
      </section>
      <section className="mb-3">
        <TextField
          label="Session fee (dollars)"
          name="sessionFeeInCents"
          onChange={event => {
            const rawValue = parseFloat(event.currentTarget.value)
            let newValue = null
            if (!isNaN(rawValue)) {
              newValue = numberService.dollarsToCents(rawValue)
            }
            setFieldValue('sessionFeeInCents', newValue)
          }}
          onBlur={handleBlur}
          type="number"
          value={values.sessionFeeInCents ? numberService.centsToDollars(values.sessionFeeInCents) : null}
          {...composeHelperTextWithError('', errors.sessionFeeInCents, !!touched.sessionFeeInCents)}
        />
      </section>
      <section className="mb-3">
        <TextField
          label="Annual fee (dollars)"
          name="annualFeeInCents"
          onChange={event => {
            const rawValue = parseFloat(event.currentTarget.value)
            let newValue = null
            if (!isNaN(rawValue)) {
              newValue = numberService.dollarsToCents(rawValue)
            }
            setFieldValue('annualFeeInCents', newValue)
          }}
          onBlur={handleBlur}
          type="number"
          value={values.annualFeeInCents ? numberService.centsToDollars(values.annualFeeInCents) : null}
          {...composeHelperTextWithError('', errors.annualFeeInCents, !!touched.annualFeeInCents)}
        />
      </section>
      <InternationalAreaFormSection className="mb-3" formProps={formProps} />
      <section className="mb-3">
        <MultiSelectFormControl variant="standard">
          <InputLabel id="demo-multiple-name-label">Excluded geographical areas</InputLabel>
          <Select
            name="excludedGeographicalAreas"
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={values.excludedGeographicalAreas?.map(item => item.value) ?? []}
            onChange={handleMultiSelectChange}
            MenuProps={MENU_PROPS}
          >
            {Object.keys(USStates).map(state => (
              <MenuItem
                key={state}
                value={state}
                style={getStyles(state, values.excludedGeographicalAreas?.map(item => item.value) ?? [], theme)}
              >
                {USStates[state]}
              </MenuItem>
            ))}
          </Select>
        </MultiSelectFormControl>
      </section>
      <section className="mb-3">
        <h6 className="mt-5 mb-2">
          Which of the following groups should be covered members in this customer's first Tava contract? Select all
          applicable.
        </h6>
        <div role="group">
          <Checkbox
            checked={values.availableToFullTime}
            name="availableToFullTime"
            onChange={handleAvailabilityTypeChange}
          >
            Full-time employees
          </Checkbox>
          <Checkbox
            checked={values.availableToPartTime}
            name="availableToPartTime"
            onChange={handleAvailabilityTypeChange}
          >
            Part-time employees
          </Checkbox>
          <Checkbox
            checked={values.availableToContractors}
            name="availableToContractors"
            onChange={handleAvailabilityTypeChange}
          >
            Contractors
          </Checkbox>
          <Checkbox
            checked={values.availableToInterns}
            name="availableToInterns"
            onChange={handleAvailabilityTypeChange}
          >
            Interns
          </Checkbox>
          <Checkbox
            checked={values.availableToDependents}
            name="availableToDependents"
            onChange={handleAvailabilityTypeChange}
          >
            Dependents
          </Checkbox>
        </div>
      </section>
      <section className="mb-3">
        <StyledChipGroup>
          <Radio
            name="enrolledState"
            checked={values.availableToBenefitsEligible}
            value={ENROLLMENT_TYPES.BenefitsEligible}
            onChange={handleOnEnrolledTypeChange}
          >
            Benefits eligible
          </Radio>
          <Radio
            name="enrolledState"
            checked={values.availableToBenefitsEnrolled}
            value={ENROLLMENT_TYPES.BenefitsEnrolled}
            onChange={handleOnEnrolledTypeChange}
          >
            Benefits enrolled
          </Radio>
          <Radio
            name="enrolledState"
            checked={values.availableToMedicalPlanEnrolled}
            value={ENROLLMENT_TYPES.MedicalPlanEnrolled}
            onChange={handleOnEnrolledTypeChange}
          >
            Medical plan enrolled
          </Radio>
        </StyledChipGroup>
      </section>
      <section className="mb-3">
        <h6 className="mt-5 mb-2">Additional services offered</h6>
        <div role="group">
          <Checkbox
            checked={values.hasMedMgmt}
            name="hasMedMgmt"
            onChange={ev => setFieldValue('hasMedMgmt', ev.target.checked)}
          >
            Medication management
          </Checkbox>
          <Checkbox
            checked={values.isCoachingOffered}
            name="isCoachingOffered"
            onChange={ev => setFieldValue('isCoachingOffered', ev.target.checked)}
          >
            Coaching offered
          </Checkbox>
        </div>
      </section>
      <section className="mb-3">
        <h6 className="mt-5 mb-2">Eligibility enforcement</h6>
        <div role="group">
          <Checkbox
            checked={values.isEligiblityEnforced ?? false}
            name="isEligiblityEnforced"
            onChange={ev => setFieldValue('isEligiblityEnforced', ev.target.checked)}
          >
            Enforce eligibility
            <Tooltip content="If eligibility is enforced, eligibility will run every month against the company's roster and member matches will need to be performed. If it is not enforced then eligibility will not run and the company's members will not appear in Member Match.">
              <span className="text-secondary ml-1">
                <IconInfo />
              </span>
            </Tooltip>
          </Checkbox>
          <Checkbox
            checked={values.strictlyEnforceRoster ?? false}
            name="strictlyEnforceRoster"
            onChange={ev => setFieldValue('strictlyEnforceRoster', ev.target.checked)}
          >
            Strictly enforce roster (dependents match to themselves)
          </Checkbox>
        </div>
      </section>
      {!isEditForm && (
        <section className="mb-3">
          <h6 className="mt-5 mb-2">
            Please upload the PDF benefits guide for this customer so that we can host it on our website.
          </h6>
          <FileUpload onDrop={files => files?.length > 0 && uploadBenefitsGuide(files[0])} accept=".pdf" />
        </section>
      )}
    </>
  )
}

const MultiSelectFormControl = styled(FormControl)`
  width: 100%;
`

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

export function customerContractFormValuesToMutationInput<T extends object>(
  formValues: CustomerContractFormValues & T,
): NewCompanyContractDetails {
  return {
    goLiveDate: formValues.goLiveDate,
    endDate: formValues.endDate,
    hasMedMgmt: formValues.hasMedMgmt,
    isCoachingOffered: formValues.isCoachingOffered,
    availableToFullTime: formValues.availableToFullTime,
    availableToDependents: formValues.availableToDependents,
    availableToPartTime: formValues.availableToPartTime,
    availableToContractors: formValues.availableToContractors,
    availableToInterns: formValues.availableToInterns,
    availableToBenefitsEnrolled: formValues.availableToBenefitsEnrolled,
    availableToBenefitsEligible: formValues.availableToBenefitsEligible,
    availableToMedicalPlanEnrolled: formValues.availableToMedicalPlanEnrolled,
    capResetDate: formValues.capResetDate,
    postCapPatientSessionCost: formValues.postCapPatientSessionCost ?? 0,
    sessionAllowanceToType: formValues.sessionAllowanceToType as number,
    excludedGeographicalAreas: formValues.excludedGeographicalAreas,
    parentOrganization: formValues.parentOrganization,
    supportedInternationalAreas: formValues.supportedInternationalAreas,
    partnerId: formValues.isReferralPartner ? formValues.partnerId : null,
    strictlyEnforceRoster: formValues.strictlyEnforceRoster,
    isEligiblityEnforced: formValues.isEligiblityEnforced,
    pepmFeeInCents: formValues.pepmFeeInCents,
    annualFeeInCents: formValues.annualFeeInCents,
    sessionFeeInCents: formValues.sessionFeeInCents,
    isAPreviewContract: formValues.isAPreviewContract,
    benefitsGuideDocumentId: formValues.benefitsGuideDocumentId,
  } as NewCompanyContractDetails
}

const StyledChipGroup = styled(ChipGroup)`
  margin-left: 2px;
`
