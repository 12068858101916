import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { ReactNode } from 'react'

import { greySet } from '@nuna/tunic'

type RowType = 'patient' | 'employeeRecord' | 'header'
interface Props extends HTMLAttributes<HTMLDivElement> {
  columns: ReactNode[]
  rowType?: RowType
}

export function EmployeeMatchTableRow({ columns, rowType = 'patient', ...props }: Props) {
  return (
    <Row {...props} isRowHeader={rowType === 'header'} rowType={rowType}>
      {columns.map((column, idx) => (
        <Column key={idx} isColumnHeader={idx === 0}>
          {column}
        </Column>
      ))}
    </Row>
  )
}

const Column = styled('td')<{ isColumnHeader: boolean }>`
  padding: var(--margin-1) var(--margin-2);
  ${({ isColumnHeader }) =>
    isColumnHeader
      ? `
    position: sticky;
    position: -webkit-sticky;
    left 0;
    z-index: 1;
    background-color: #fff;
  `
      : ''}/* &:empty {
    &:before {
      content: '\00a0';
      visibility: hidden;
    }
  } */
`

const Row = styled('tr')<{ isRowHeader: boolean; rowType: RowType }>`
  white-space: nowrap;
  ${({ isRowHeader }) =>
    isRowHeader &&
    `
    position: sticky;
    position: -webkit-sticky;
    top 0;
    z-index: 2;
  `}

  ${Column} {
    ${({ isRowHeader }) =>
      isRowHeader
        ? `
    background-color: ${greySet[15].hex};
    text-transform: uppercase;
    font-weight: bold;

    :first-of-type {
        border-radius: 8px 0 0 8px;
    }

    :last-child {
        border-radius: 0 8px 8px 0;
    }
  `
        : ``}

    ${({ rowType }) => {
      switch (rowType) {
        case 'patient':
          return `
            padding-top: var(--margin-2);
          `
        case 'employeeRecord':
          return `
            &:not(:first-of-type) {
              background-color: ${greySet[0].hex}; 
            }
          `
        default:
          return ''
      }
    }}
  }
`
