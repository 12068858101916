import { isArray, isPlainObject } from 'lodash'

import {
  PayerRosterDefinitionConfigColumn,
  PayerRosterDefinitionConfigSheet,
  ProviderDataDenormalizedColumnMetadata,
  ProviderDataDenormalizedColumnType,
} from '@nuna/api'

import { CustomDenormColumn } from './PayerRosterDefinition.types'

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

export enum RosterDefinitionSheetTableEvent {
  AddColumn = 'AddColumn',
  CreateColumn = 'CreateColumn',
}

export type AddColumnEvent = { type: RosterDefinitionSheetTableEvent.AddColumn; payload: string }
export type CreateColumnEvent = { type: RosterDefinitionSheetTableEvent.CreateColumn; payload: string }

export function getCustomColumnMetaData(): ProviderDataDenormalizedColumnMetadata[] {
  return [
    {
      key: CustomDenormColumn.RecordType,
      name: 'Status',
      dataType: ProviderDataDenormalizedColumnType.String,
      required: false,
    },
    {
      key: CustomDenormColumn.ChangeType,
      name: 'Change Type',
      dataType: ProviderDataDenormalizedColumnType.String,
      required: false,
    },
  ]
}

export function buildEmptySheet(index: number): PayerRosterDefinitionConfigSheet {
  return { name: `Sheet${index + 1}`, key: `sheet${index}`, columnKeys: [], filters: [] }
}

export function buildEmptyColumn(alias: string): PayerRosterDefinitionConfigColumn {
  return { alias, dataMapping: [], key: '' }
}

export function getColumnLetters(index: number): string {
  if (index < ALPHABET.length) {
    return ALPHABET[index]
  }

  const letterIndex = Math.floor(index / ALPHABET.length) - 1
  const nextLetterIndex = ((index + 1) % ALPHABET.length || ALPHABET.length) - 1

  return [ALPHABET[letterIndex], getColumnLetters(nextLetterIndex)].flat().join('')
}

export function getIndexFromLetters(letters: string): number {
  const toUpperCase = letters.toUpperCase()
  const split = toUpperCase.split('')

  return split.reverse().reduce((acc, letter, idx) => {
    const letterIndex = ALPHABET.indexOf(letter)

    if (idx === 0) {
      return letterIndex
    }
    const letterMultiplier = letterIndex + 1

    return acc + ALPHABET.length * idx * letterMultiplier
  }, 0)
}

export function getLetterOptions(count: number) {
  const options: string[] = []
  for (let i = 0; i < count; i++) {
    options.push(getColumnLetters(i))
  }
  return options
}

export function cleanPayerRosterDefinitionConfig<T>(obj: unknown): T {
  if (isPlainObject(obj)) {
    const newObj: Record<string, unknown> = {}
    for (const key in obj as Record<string, unknown>) {
      if ((obj as Record<string, unknown>)[key] === null) {
        continue
      }

      if (key === '__typename') {
        continue
      }

      newObj[key] = cleanPayerRosterDefinitionConfig((obj as Record<string, unknown>)[key])
    }
    return newObj as T
  }

  if (isArray(obj)) {
    return obj.map(cleanPayerRosterDefinitionConfig) as T
  }

  return obj as T
}
