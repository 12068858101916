import { Autocomplete, TextField } from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'

import { ContractType, useSearchPatientContractRelationsQuery } from '@nuna/api'
import { csx } from '@nuna/tunic'

interface Option {
  value: string
  label: string
  dates: string
}

interface ClientContractSelectProps {
  patientId: string
  onChange: (value: string | null) => void
  value?: string | null
}

export function ClientContractSelect({ patientId, value, onChange }: ClientContractSelectProps) {
  const { data, loading } = useSearchPatientContractRelationsQuery({
    variables: {
      filters: { contractTypes: [ContractType.CompanyContract, ContractType.SponsoredCompanyContract], patientId },
      pagination: { limit: 100 },
    },
    fetchPolicy: 'network-only',
    skip: !patientId,
  })

  const options = useMemo<Option[]>(
    () =>
      (data?.searchPatientContractRelations.items ?? [])
        .filter(item => !!item.contract)
        .map(item => ({
          value: item.contract?.id ?? '',
          label: item.contract?.company?.name ?? item.contract?.contractType ?? '',
          dates: `${moment(item.assignedDate).format('MM/DD/YYYY')} - ${moment(item.unassignedDate).format(
            'MM/DD/YYYY',
          )}`,
        })),
    [data],
  )

  return (
    <Autocomplete
      loading={loading}
      isOptionEqualToValue={option => option.value === value}
      options={options}
      renderOption={(props, option) => (
        <li {...props} className={csx([props.className, 'v-align space-between full-width'])}>
          {option.label} <div className="caption ml-auto text-light-grey">{option.dates}</div>
        </li>
      )}
      onChange={(_, v) => onChange(v?.value ?? null)}
      renderInput={params => <TextField placeholder="Select a contract" {...params} />}
    />
  )
}
