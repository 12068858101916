import { IServerSideDatasource } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { omit } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useRef } from 'react'

import {
  AppointmentSearchOptions,
  OrderBy,
  TraversablePaginationSortInput,
  useSearchAppointmentsLazyQuery,
} from '@nuna/api'
import { TavaDataTable } from '@nuna/common'

import { DateTimeFilterValue } from '../../../shared/filters'
import { useUrlFilters } from '../../../shared/filters/hooks/useUrlFilters'
import { APPOINTMENT_TABLE_COL_DEFS, APPOINTMENT_TABLE_DEFAULT_COL_DEF } from './components/AppointmentTableColDefs'
import { AppointmentTableFilters } from './components/AppointmentTableFilters'
import {
  AppointmentTablePresetFilterValueMap,
  useAppointmentTableFilterPresets,
} from './hooks/useAppointmentTableFilterPresets'

type Query = ReturnType<typeof useSearchAppointmentsLazyQuery>[0]

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'startDatetime', direction: OrderBy.Desc }]
const PAGE_SIZE = 100

const createServerSideDatasource: (query: Query, filters?: AppointmentSearchOptions) => IServerSideDatasource = (
  query,
  filters = {},
) => {
  return {
    getRows: params => {
      const page = Math.ceil(((params.request.startRow ?? 0) + 1) / PAGE_SIZE)
      const sortModel = params.request.sortModel

      query({
        variables: {
          filters,
          pagination: { limit: PAGE_SIZE, page },
          order: sortModel.length
            ? sortModel.map(model => ({
                key: model.colId,
                direction: model.sort === 'asc' ? OrderBy.Asc : OrderBy.Desc,
              }))
            : INITIAL_SORT,
        },
        fetchPolicy: 'network-only',
      }).then(result => {
        const { data } = result

        if (data) {
          const {
            searchAppointments: { items, pagination },
          } = data

          params.success({ rowData: items ?? [], rowCount: pagination?.totalCount ?? 0 })
        } else {
          params.fail()
        }
      })
    },
  }
}

const transformDateFilter = ({ op, v }: DateTimeFilterValue): AppointmentSearchOptions => {
  const offset = moment().utcOffset()
  switch (op) {
    case 'eq':
      return {
        afterStartDatetime: moment.utc(v).startOf('day').subtract(offset, 'minutes').toISOString(),
        beforeStartDatetime: moment.utc(v).endOf('day').subtract(offset, 'minutes').toISOString(),
      } as AppointmentSearchOptions
    case 'gt':
      return { afterStartDatetime: v } as AppointmentSearchOptions
    default:
      return { beforeStartDatetime: v }
  }
}

const mergePresetAndUrlFilters = (
  { startDatetime }: AppointmentTablePresetFilterValueMap,
  urlFilterValues: AppointmentSearchOptions,
): AppointmentSearchOptions => {
  return { ...(startDatetime ? transformDateFilter(startDatetime) : {}), ...urlFilterValues }
}

const transformFilters = (values: Record<string, unknown>): AppointmentSearchOptions => {
  const dateFilters = values.startDatetime ? transformDateFilter(values.startDatetime as DateTimeFilterValue) : {}
  return { ...omit(values, ['startDatetime']), ...dateFilters } as AppointmentSearchOptions
}

export function AppointmentTable() {
  const gridRef = useRef<AgGridReact>(null)
  const [query] = useSearchAppointmentsLazyQuery()

  const urlFiltersService = useUrlFilters(transformFilters)
  const { filterValues } = urlFiltersService
  const presetFilters = useAppointmentTableFilterPresets()

  const dataSource = useMemo(() => {
    return createServerSideDatasource(query, mergePresetAndUrlFilters(presetFilters, filterValues))
  }, [query, filterValues, presetFilters])

  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (gridApi) {
      gridApi.setServerSideDatasource(
        createServerSideDatasource(query, mergePresetAndUrlFilters(presetFilters, filterValues)),
      )
    }
  }, [query, filterValues, presetFilters])

  return (
    <div className="full-height flex-column">
      <div className="py-2">
        <AppointmentTableFilters urlFilterService={urlFiltersService} />
      </div>
      <TavaDataTable
        ref={gridRef}
        rowModelType="serverSide"
        pagination={true}
        paginationPageSize={PAGE_SIZE}
        cacheBlockSize={PAGE_SIZE}
        defaultColDef={APPOINTMENT_TABLE_DEFAULT_COL_DEF}
        columnDefs={APPOINTMENT_TABLE_COL_DEFS}
        serverSideStoreType="partial"
        serverSideDatasource={dataSource}
      />
    </div>
  )
}
