import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { omit } from 'lodash'
import { useMemo } from 'react'

import {
  StateAbbreviation,
  useInsuranceNetworkPayerPlanQuery,
  useSaveInsuranceNetworkPayerPlanMutation,
} from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import {
  Drawer,
  FillButton,
  IconButton,
  IconClose,
  Skeleton,
  StateAutocompleteSingle,
  TextField,
  eggshell,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { PayerCountySelect } from '../../payer-configuration/PayerCountySelect'
import { StateCounty, payerCountyToString, stringToPayerCounty } from '../../payer-configuration/payer-counties'
import { useNetworkPlanDrawerSearchParams } from '../hooks/useNetworkPlanDrawerSearchParams'
import { NetworkPlanSelect } from './NetworkPlanSelect'

interface FormValues {
  id?: string | null
  insurancePayerId: string
  insurancePayerPlanId?: string | null
  state?: StateAbbreviation | null
  counties?: StateCounty[]
  inclusionPattern?: string | null
  exclusionPattern?: string | null
}

const FORM_SCHEMA = Yup.object().shape<FormValues>({
  id: Yup.string().nullable(),
  insurancePayerId: Yup.string().required(),
  insurancePayerPlanId: Yup.string().required('Plan is required'),
  state: Yup.mixed().oneOf(Object.values(StateAbbreviation)).required('State is required'),
  counties: Yup.array<StateCounty>().optional(),
  inclusionPattern: Yup.string().nullable(),
  exclusionPattern: Yup.string().nullable(),
})

export function PayerNetworkPlanDrawer() {
  const {
    closeDrawer,
    drawerConfig: { isOpen, payerId, networkPayerPlanId },
  } = useNetworkPlanDrawerSearchParams()

  const [saveNetworkPlan, { loading: saveLoading }] = useSaveInsuranceNetworkPayerPlanMutation({
    refetchQueries: ['InsuranceNetworkPayerPlans'],
  })

  const { data: existingData, loading: existingLoading } = useInsuranceNetworkPayerPlanQuery({
    variables: { id: networkPayerPlanId ?? '' },
    skip: !networkPayerPlanId,
  })

  const initialValues: FormValues = useMemo<FormValues>(() => {
    if (networkPayerPlanId) {
      const {
        id = null,
        insurancePayerMain,
        insurancePayerPlan,
        state = null,
        counties,
        inclusionPattern = '',
        exclusionPattern = '',
      } = existingData?.insuranceNetworkPayerPlan ?? {}

      return {
        id,
        insurancePayerId: insurancePayerMain?.id ?? '',
        insurancePayerPlanId: insurancePayerPlan?.id ?? '',
        state,
        counties: (counties ?? []).map(stringToPayerCounty),
        inclusionPattern,
        exclusionPattern,
      }
    }
    return {
      insurancePayerId: payerId ?? '',
      insurancePayerPlanId: null,
      state: null,
      inclusionPattern: '',
      exclusionPattern: '',
    }
  }, [existingData, networkPayerPlanId, payerId])

  const handleSubmit = async (values: FormValues) => {
    try {
      await saveNetworkPlan({
        variables: {
          saveInput: {
            ...omit(values, 'counties'),
            counties: values.counties?.map(payerCountyToString),
          },
        },
      })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving the network plan'))
    }
    closeDrawer()
  }

  return (
    <StyledDrawer size="min(400px, 100vw)" onClose={closeDrawer} isOpen={isOpen} PaperProps={{ className: 'py-2' }}>
      <ContentContainer>
        <Header>
          {networkPayerPlanId ? 'Edit' : 'Add'} network plan{' '}
          <IconButton tooltip="Close" variant="secondary" onClick={closeDrawer}>
            <IconClose size={20} />
          </IconButton>
        </Header>
        {existingLoading && <Skeleton height={4} className="mt-2" />}
        {!existingLoading && (
          <Formik initialValues={initialValues} validationSchema={FORM_SCHEMA} onSubmit={handleSubmit}>
            {({ values, errors, touched, setFieldValue, getFieldProps }) => (
              <Form>
                <FormGroup>
                  <NetworkPlanSelect
                    value={values.insurancePayerPlanId}
                    {...formService.composeHelperTextWithError(
                      '',
                      errors.insurancePayerPlanId,
                      touched.insurancePayerId,
                    )}
                    onChange={val => setFieldValue('insurancePayerPlanId', val)}
                  />
                </FormGroup>
                <FormGroup>
                  <StateAutocompleteSingle
                    value={values.state}
                    onChange={state => setFieldValue('state', state?.value ?? null)}
                    {...formService.composeHelperTextWithError('', errors.state, touched.state)}
                  />
                </FormGroup>
                <FormGroup>
                  <PayerCountySelect
                    value={values.counties}
                    onChange={newValue => {
                      setFieldValue('counties', newValue)
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField label="Inclusion pattern" {...getFieldProps('inclusionPattern')} />
                </FormGroup>
                <FormGroup>
                  <TextField label="Exclusion pattern" {...getFieldProps('exclusionPattern')} />
                </FormGroup>
                <FillButton isLoading={saveLoading} className="full-width mt-3" type="submit">
                  Save
                </FillButton>
              </Form>
            )}
          </Formik>
        )}
      </ContentContainer>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`

const ContentContainer = makeTypographyComponent('px-2', 'div')
const Header = makeTypographyComponent('sans-serif text-medium large space-between v-align', 'h1')
const FormGroup = makeTypographyComponent('pb-2', 'div')
