import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import { HTMLAttributes, useMemo, useState } from 'react'

import {
  DocumentType,
  InsuranceTaxEntityFragment,
  useDocumentUpload,
  useSaveInsuranceTaxEntityMutation,
} from '@nuna/api'
import { formService } from '@nuna/core'
import { GreySection } from '@nuna/provider'
import { Grid } from '@nuna/tunic'
import { FileUpload, FillButton, OutlineButton, Select, TextField, toast } from '@nuna/tunic'

import { TaxEntityPayerSelect } from './TaxEntityPayerSelect'

const { composeHelperText } = formService

interface InsuranceTaxEntitySave extends InsuranceTaxEntityFragment {
  default: string
  enterClientSecret: string
  einDocumentId?: string | null
}

interface Props {
  insuranceTaxEntity?: Partial<InsuranceTaxEntitySave>
  onCancel: () => void
  onSave: () => void
}

export function TaxEntityForm({
  insuranceTaxEntity = {
    id: '',
    organizationName: '',
    state: '',
    npi: '',
    ein: '',
    default: 'no',
    includedPayerIds: [],
    enterClientId: '',
    enterClientSecret: '',
    einDocumentId: undefined,
  },
  onCancel,
  onSave,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const [saveInsuranceTaxEntity, { loading }] = useSaveInsuranceTaxEntityMutation({
    refetchQueries: ['InsuranceTaxEntities'],
  })
  const [einDocument, setEinDocument] = useState<File | null | undefined>()

  const { uploadDocument } = useDocumentUpload()

  const VALIDATION_SCHEMA = useMemo(() => {
    return Yup.object().shape({
      organizationName: Yup.string().required('Name is required'),
      state: Yup.string().required('State is required'),
      npi: Yup.string().required('NPI is required'),
      ein: Yup.string().required('EIN is required'),
      default: Yup.string().required('Is Default is required'),
      includedPayerIds: Yup.array(Yup.string()),
      enterClientId: Yup.string().required('Client ID is required'),
      enterClientSecret: insuranceTaxEntity.id ? Yup.string() : Yup.string().required('Client Secret is required'),
    })
  }, [insuranceTaxEntity.id])

  const submit = async (values: Partial<InsuranceTaxEntitySave>) => {
    try {
      const savedDocument = einDocument ? await uploadDocument(einDocument, DocumentType.TavaEin) : null

      await saveInsuranceTaxEntity({
        variables: {
          insuranceTaxEntityInput: {
            id: insuranceTaxEntity.id ? insuranceTaxEntity.id : undefined,
            isDefault: values.default === 'yes',
            einDocumentId: savedDocument?.id,
            ...pick(values, [
              'organizationName',
              'state',
              'npi',
              'ein',
              'enterClientId',
              'enterClientSecret',
              'includedPayerIds',
            ]),
          },
        },
      })
      toast.success('Insurance tax entity saved')
      onSave()
    } catch (error) {
      let errorMessage = 'There was a problem saving the Insurance Tax Entity.'

      if (error instanceof ApolloError && error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message
      }

      toast.urgent(errorMessage)
    }
  }

  return (
    <GreySection {...props} style={{ padding: '1rem', paddingBottom: '2rem' }}>
      <Formik initialValues={insuranceTaxEntity} validationSchema={VALIDATION_SCHEMA} onSubmit={submit}>
        {({ values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldTouched, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <div className="v-align">
                    <TextField
                      name="organizationName"
                      label="Org. Name"
                      error={touched.organizationName && !!errors.organizationName}
                      helperText={composeHelperText('', errors.organizationName, touched.organizationName)}
                      className="ml-2 text-field"
                      value={values.organizationName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      name="state"
                      label="State"
                      error={touched.state && !!errors.state}
                      helperText={composeHelperText('', errors.state, touched.state)}
                      className="ml-2 text-field"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      name="npi"
                      label="NPI"
                      error={touched.npi && !!errors.npi}
                      helperText={composeHelperText('', errors.npi, touched.npi)}
                      className="ml-2 text-field"
                      value={values.npi}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      name="ein"
                      label="EIN"
                      error={touched.ein && !!errors.ein}
                      helperText={composeHelperText('', errors.ein, touched.ein)}
                      className="ml-2 text-field"
                      value={values.ein}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </Grid>
                <Grid size={12}>
                  <div className="v-align">
                    <Select
                      name="default"
                      label="Default"
                      helperText={composeHelperText('', errors.default, touched.default)}
                      className="ml-2 text-field"
                      value={values.default ?? 'no'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option key={'yes'} value={'yes'}>
                        Yes
                      </option>
                      <option key={'no'} value={'no'}>
                        No
                      </option>
                    </Select>

                    <TaxEntityPayerSelect
                      error={(touched.includedPayerIds && !!errors.includedPayerIds) || false}
                      helperText={composeHelperText('', errors.includedPayerIds, touched.includedPayerIds)}
                      className="ml-2 text-field"
                      value={values.includedPayerIds || []}
                      onChange={(_e, payers) => {
                        setFieldTouched('includedPayerIds', true)
                        setFieldValue(
                          'includedPayerIds',
                          payers.map(p => p.id),
                        )
                      }}
                      onRemove={payer => {
                        setFieldTouched('includedPayerIds', true)
                        setFieldValue(
                          'includedPayerIds',
                          values.includedPayerIds?.filter(includedPayerId => includedPayerId !== payer.id),
                        )
                      }}
                    />

                    <TextField
                      name="enterClientId"
                      label="Client ID"
                      error={touched.enterClientId && !!errors.enterClientId}
                      helperText={composeHelperText('', errors.enterClientId, touched.enterClientId)}
                      className="ml-2 text-field"
                      value={values.enterClientId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <TextField
                      name="enterClientSecret"
                      label="Client Secret"
                      error={touched.enterClientSecret && !!errors.enterClientSecret}
                      helperText={composeHelperText('', errors.enterClientSecret, touched.enterClientSecret)}
                      className="ml-2 text-field"
                      value={values.enterClientSecret}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="ml-auto actions">
                      <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
                      <FillButton isLoading={loading} type="submit" className="ml-1">
                        Save
                      </FillButton>
                    </div>
                  </div>
                </Grid>
                <Grid size={12}>
                  <label>EIN Document</label>
                  <FileUpload
                    renderInlineDisplay
                    savedFiles={
                      insuranceTaxEntity?.einDocument
                        ? [{ ...insuranceTaxEntity.einDocument, url: insuranceTaxEntity.einDocument.downloadUrl ?? '' }]
                        : []
                    }
                    onDrop={files => setEinDocument(files[0])}
                  />
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </GreySection>
  )
}

const Form = styled('form')`
  .text-field {
    width: 200px;

    input[type='number'] {
      text-align: right;
    }
  }

  .actions {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
`
