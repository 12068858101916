import { PayerRosterDefinitionTransformationRegex } from '@nuna/api'
import { Switch, TextField } from '@nuna/tunic'

interface EditColumnRegexProps {
  regexTransformation: PayerRosterDefinitionTransformationRegex
  onChange: (regexTransformation: PayerRosterDefinitionTransformationRegex) => void
}

export function EditColumnRegex({ regexTransformation, onChange }: EditColumnRegexProps) {
  const handleChange = (key: keyof PayerRosterDefinitionTransformationRegex, value: string | boolean) => {
    onChange({ ...regexTransformation, [key]: value })
  }
  return (
    <div>
      <TextField
        label="Pattern"
        autoFocus
        value={regexTransformation.pattern}
        onChange={e => handleChange('pattern', e.currentTarget.value)}
      />
      <TextField
        label="Flags"
        className="mt-2"
        autoFocus
        value={regexTransformation.flags}
        onChange={e => handleChange('flags', e.currentTarget.value)}
      />
      <Switch
        className="mt-2"
        checked={!!regexTransformation.matchRequired}
        onChange={() => handleChange('matchRequired', !regexTransformation.matchRequired)}
      >
        Match Required
      </Switch>
    </div>
  )
}
