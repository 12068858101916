import { Navigate, Route, Routes } from 'react-router-dom'

import { MenuItem, PageHeader, PageHeading, PageWrapper, ResponsiveHorizontalMenu } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { CurrentEnrollments } from './CurrentEnrollments/CurrentEnrollments'

export function EnrollmentIndex() {
  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading withDivider className="mb-0 mr-2 v-align">
            Enrollments
          </PageHeading>
          <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
            <MenuItem to="current">Current</MenuItem>
          </ResponsiveHorizontalMenu>
        </PageHeader>
        <div className="flex-remaining-space overflow-hidden flex-column">
          <Routes>
            <Route index element={<Navigate to="current" replace />} />
            <Route path="current" element={<CurrentEnrollments />} />
          </Routes>
        </div>
      </PageWrapper>
    </NavLayout>
  )
}
