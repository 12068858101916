/* eslint-disable react/jsx-no-useless-fragment */
import { take } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Credential,
  ListProviderFragment,
  OrderBy,
  ProviderListItemFragment,
  TraversablePaginationSortInput,
  useSearchProvidersLazyQuery,
} from '@nuna/api'
import { UserLink } from '@nuna/common'
import { routeService } from '@nuna/core'
import { DataTable, DataTableColumn, usePagination } from '@nuna/data-table'
import { ProviderStatusLabel } from '@nuna/provider'
import { StatusLabel } from '@nuna/tunic'

import { ProvidersFilterValues, buildProviderFilterInput } from '../../shared/ProviderListNavigation/ProviderFilters'

interface Props extends HTMLAttributes<HTMLTableElement> {
  providers?: ListProviderFragment[] | null
  filterOptions?: Partial<ProvidersFilterValues>
}

type ProviderCredential = Pick<Credential, 'currentlyUtilized' | 'state'>

const INITIAL_SORT: TraversablePaginationSortInput[] = [
  { key: 'name', direction: OrderBy.Asc },
  { key: 'createdAt', direction: OrderBy.Desc },
]

export function ProviderTable({ filterOptions, ...props }: Props) {
  const navigate = useNavigate()

  const [queryProviders, { data: providerData, loading: providerDataLoading }] = useSearchProvidersLazyQuery()

  const totalLabel = (providerData?.searchProviders.items ?? []).length === 1 ? 'total therapist' : 'total therapists'

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination(
    {
      pagination: providerData?.searchProviders.pagination,
      loading: providerDataLoading,
      initialSort: INITIAL_SORT,
      filters: filterOptions,
    },
    { totalLabel },
  )

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryProviders({
      variables: {
        order: sortInput,
        filters: buildProviderFilterInput(filters),
        pagination: paginationInput,
      },
    })
  }, [queryProviders, queryOptions])

  const columns: DataTableColumn<ProviderListItemFragment>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'lastName',
        Cell: ({ row }) => <UserLink user={row.original} />,
        paginatedSortable: true,
      },
      {
        Header: 'Accepting New Clients',
        accessor: 'internalOnly',
        Cell: ({ value }) => <>{value ? 'No' : 'Yes'}</>,
      },
      {
        Header: 'Active Licenses',
        accessor: 'credentials',
        Cell: ({ value }) => <>{credentialsString(value)}</>,
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ value }) => (
          <StatusLabel intent={value ? 'information' : 'default'}>{value ? 'Active' : 'Inactive'}</StatusLabel>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, row }) => <ProviderStatusLabel status={value} endDate={row.original.endDate} />,
        paginatedSortable: true,
      },
      {
        Header: 'Date Added',
        accessor: 'createdAt',
        paginatedSortable: true,
        Cell: ({ value }) => <>{moment(value).format('l')}</>,
      },
    ],
    [],
  )

  const rowData = useMemo(() => providerData?.searchProviders.items, [providerData])

  return (
    <DataTable
      columns={columns}
      rowData={rowData}
      initialState={initialTableState}
      loading={providerDataLoading}
      onRowClick={row => navigate(routeService.providerDetail(row.id))}
      onSort={handleSort}
      paginated
      paginatorProps={getPaginatorProps()}
      {...props}
    />
  )
}

function credentialsString(credentials: ProviderCredential[]) {
  const activeLicenses = credentials.filter(cred => cred.currentlyUtilized)
  const licenseStateList = take(
    activeLicenses.sort((a, b) => a.state.localeCompare(b.state)).map(cred => cred.state),
    5,
  )
  const extraLicenses = activeLicenses.length - licenseStateList.length
  return licenseStateList.join(', ') + (extraLicenses > 0 ? ` +${extraLicenses}` : '')
}
