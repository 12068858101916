import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { Form, Formik } from 'formik'
import { noop } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { FullPayerContractFragment, useCopyPayerContractMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { InsurancePayerPlanSelect, PayerPlanOption } from '@nuna/coverage'
import { GreySection } from '@nuna/provider'
import { DatePicker, FillButton, Grid, OutlineButton, toast } from '@nuna/tunic'

import { planContract } from '../../../util/routes'

const { composeHelperText } = formService

interface Props {
  contract: FullPayerContractFragment
  onCancel?: () => void
  afterSave?: () => void
}

type FormValues = {
  startDate: Date
  endDate: Date
  insurancePayerPlan: PayerPlanOption | null
}

const formSchema = Yup.object<FormValues>({
  startDate: Yup.date().typeError('Invalid date').required('Start date is required'),
  endDate: Yup.date()
    .typeError('Invalid date')
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must come after Start date'),
  insurancePayerPlan: Yup.object<PayerPlanOption>({
    id: Yup.string(),
    name: Yup.string(),
    insurancePayer: Yup.object<PayerPlanOption['insurancePayer']>({
      id: Yup.string(),
      name: Yup.string(),
    }),
  })
    .nullable()
    .required('Insurance plan is required'),
})

export function PlanContractCopyForm({
  onCancel = noop,
  afterSave = noop,
  contract,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate()
  const [copyPayerContract, { loading: copying }] = useCopyPayerContractMutation()

  const initialValues = useMemo<FormValues>(() => {
    const startDate = moment(contract.startDate)
    const endDate = moment(contract.endDate)

    return {
      insurancePayerPlan: null,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    }
  }, [contract])

  const submit = async (values: FormValues) => {
    try {
      if (!values.insurancePayerPlan?.id) throw new Error('No plan selected!')
      const result = await copyPayerContract({
        variables: {
          contractId: contract.id,
          insurancePayerPlanId: values.insurancePayerPlan.id,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      })
      if (!result.data) throw new Error('No contract returned!')
      const newContract = result.data.copyPayerContract

      navigate(
        planContract(newContract.insurancePayerPlanId, values.insurancePayerPlan.insurancePayer.id, newContract.id),
      )
      afterSave()
    } catch (error) {
      let errorMessage = 'Failed to save contract'
      if (error instanceof ApolloError) {
        errorMessage = error.graphQLErrors[0] ? error.graphQLErrors[0].message : errorMessage
      }
      toast.urgent(errorMessage)
    }
  }
  return (
    <GreySection style={{ width: '100%' }} {...props}>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={submit}>
        {({ values, setFieldValue, errors, touched, handleBlur }) => (
          <Form>
            <Grid container>
              <Grid size={{ xs: 6 }}>
                <h2 className="h5">Copy Contract</h2>
              </Grid>
              <Grid size={{ xs: 6 }} className="text-right">
                <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
                <FillButton isLoading={copying} className="ml-1" type="submit">
                  Save
                </FillButton>
              </Grid>
              <Grid size={12} className="v-align">
                <div>
                  <DatePicker
                    name="startDate"
                    label="Start date"
                    value={moment(values.startDate)}
                    error={touched.startDate && !!errors.startDate}
                    helperText={composeHelperText('Start date of contract', errors.startDate, !!touched.startDate)}
                    onChange={date => setFieldValue('startDate', date, true)}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="ml-3">
                  <DatePicker
                    name="endDate"
                    label="End date"
                    value={moment(values.endDate)}
                    error={touched.endDate && !!errors.endDate}
                    helperText={composeHelperText('End date of contract', errors.endDate, !!touched.endDate)}
                    onChange={date => setFieldValue('endDate', date, true)}
                    onBlur={handleBlur}
                  />
                </div>
              </Grid>
              <Grid size={12}>
                <InsurancePayerPlanSelect
                  value={values.insurancePayerPlan}
                  onChange={plan => setFieldValue('insurancePayerPlan', plan, true)}
                />
                {errors.insurancePayerPlan && (
                  <div className="text-error" style={{ fontSize: '0.75rem' }}>
                    {errors.insurancePayerPlan}
                  </div>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </GreySection>
  )
}
