import * as Yup from 'yup'
import { Formik } from 'formik'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SelectCustomerFragment, useCreateHrAdminMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { CustomerSelect } from '@nuna/customer'
import {
  FillButton,
  Grid,
  OutlineButtonLink,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  PhoneTextField,
  TextField,
  phoneUSAValidationRegex,
  toast,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { EntityForm } from '../../shared/EntityForm'
import { customerDetail, hrAdminUsers } from '../../util/routes'

const { composeHelperText } = formService

interface FormValues {
  firstName: string
  lastName: string
  email: string
  companyId: string
  phoneNumber?: string
}

const validationSchema = Yup.object<FormValues>().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Must be a valid email format'),
  companyId: Yup.string().required('Customer is required'),
  phoneNumber: Yup.string().matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
})

export function NewHrAdminUser() {
  const { id: customerId } = useParams<{ id: string }>()
  const [selectedCustomer, setSelectedCustomer] = useState<SelectCustomerFragment | null | undefined>(null)
  const redirectUrl = customerId ? customerDetail(customerId, 'hr-admins') : hrAdminUsers
  const [createHrAdmin, { loading }] = useCreateHrAdminMutation({ refetchQueries: ['ListCustomers'] })
  const navigate = useNavigate()

  const initialValues = useMemo(() => buildInitialValues(customerId), [customerId])

  const submit = async (values: FormValues) => {
    try {
      await createHrAdmin({ variables: values })
      toast.success(
        `HR Admin user created. An email will be sent to ${values.firstName} with instructions to continue setting up their account.`,
      )
      navigate(redirectUrl)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to create admin user'))
    }
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader>
          <PageHeading className="m-0">New HR Admin</PageHeading>
        </PageHeader>
        <PageContent>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {({ values, handleChange, handleBlur, touched, errors, setFieldValue, setFieldTouched }) => {
              const isError = (key: keyof FormValues) => {
                return !!touched[key] && !!errors[key]
              }
              const helperText = (key: keyof FormValues, text = '') => {
                const isTouched = touched[key] as boolean
                const errorMsg = errors[key] as string | undefined
                return composeHelperText(text, errorMsg, isTouched)
              }

              return (
                <EntityForm>
                  <Grid container spacing={2}>
                    <Grid
                      size={{
                        xs: 12,
                        sm: 6,
                      }}
                    >
                      <TextField
                        label="First name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError('firstName')}
                        helperText={helperText('firstName')}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                        sm: 6,
                      }}
                    >
                      <TextField
                        label="Last name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError('lastName')}
                        helperText={helperText('lastName')}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                        sm: 6,
                      }}
                    >
                      <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={isError('email')}
                        helperText={helperText('email')}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                        sm: 6,
                      }}
                    >
                      <PhoneTextField
                        name="phoneNumber"
                        onChange={value => setFieldValue('phoneNumber', value)}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        error={isError('phoneNumber')}
                        helperText={helperText('phoneNumber')}
                        style={{ width: '100%' }}
                        label="Phone number"
                        inputProps={{
                          'data-testid': 'intake-emergency-phone',
                        }}
                      />
                    </Grid>
                    {!customerId && (
                      <Grid size={12}>
                        <CustomerSelect
                          label="Customer"
                          value={selectedCustomer}
                          onChange={customer => {
                            setFieldTouched('companyId', true)
                            setFieldValue('companyId', customer?.id ?? '')
                            setSelectedCustomer(customer)
                          }}
                          onBlur={handleBlur}
                          error={isError('companyId')}
                          helperText={helperText('companyId')}
                        />
                      </Grid>
                    )}
                    <Grid size={12} className="v-align">
                      <div className="ml-auto mt-2">
                        <FillButton type="submit" isLoading={loading}>
                          Save
                        </FillButton>
                        <OutlineButtonLink to={redirectUrl} className="ml-2">
                          Cancel
                        </OutlineButtonLink>
                      </div>
                    </Grid>
                  </Grid>
                </EntityForm>
              )
            }}
          </Formik>
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

function buildInitialValues(companyId?: string | null): FormValues {
  return {
    firstName: '',
    lastName: '',
    email: '',
    companyId: companyId ?? '',
    phoneNumber: '',
  }
}
