import { styled } from '@mui/material'
import {
  AgEvent,
  CellKeyDownEvent,
  ColDef,
  GridApi,
  ICellRendererParams,
  RowDataTransaction,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { compact, groupBy, intersectionBy, kebabCase } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ContractAmountPlaceOfService,
  FullPayerContractAmountFragment,
  FullPayerContractFragment,
  PayerContractAmountInput,
  useSavePlanContractMutation,
} from '@nuna/api'
import { usePrevious } from '@nuna/common'
import { numberService } from '@nuna/core'
import {
  Checkbox,
  FillButton,
  IconButton,
  IconDownload,
  IconInfo,
  OutlineButton,
  Tooltip,
  borderGrey,
  eggshell,
  greySet,
  toast,
} from '@nuna/tunic'

const GROUP_COMP_TIER_EVENT = 'groupCompTierEvent'

type ContractAmountFields = Pick<
  FullPayerContractAmountFragment,
  | 'credentialType'
  | 'contractedAmount'
  | 'payerContractId'
  | 'cptCodeId'
  | 'placeOfService'
  | 'licenseTypeId'
  | 'licenseType'
>

interface AmountRow extends ContractAmountFields {
  cptCode: string
  masterCharge: number
  id: string
  key: string
  officeAmountId: string
  officeContractedAmount: number
  groupedByCompTier?: boolean
  ungroupedRows?: AmountRow[]
}

interface Props {
  payerContract: FullPayerContractFragment
  payerName: string
  planName: string
}

type GroupCompTierEvent = AgEvent & {
  checked: boolean
  compTier: string
  cptCode: string
}

const columnDefs: ColDef[] = [
  {
    field: 'groupedByCompTier',
    headerComponent: () => {
      return (
        <div className="v-align">
          Grouped
          <Tooltip content="Group by comp tier, meaning all license types for a given CPT code have the same rates.">
            <span className="ml-xs">
              <IconInfo size={18} />
            </span>
          </Tooltip>
        </div>
      )
    },
    cellRenderer: CompTierGroupCellRenderer,
    cellClass: 'v-align',
    maxWidth: 100,
  },
  {
    field: 'licenseType.compTier',
    headerName: 'Comp Tier',
    sort: 'asc',
  },
  { field: 'cptCode', headerName: 'CPT Code', sort: 'asc' },
  { field: 'credentialType', headerName: 'Credential Type', sort: 'asc' },
  {
    field: 'masterCharge',
    headerName: 'Master Charge',
    type: 'numericColumn',
    valueFormatter: currencyFormatter,
  },
  {
    field: 'contractedAmount',
    headerName: 'Telehealth Amount',
    editable: true,
    type: 'numericColumn',
    valueFormatter: currencyFormatter,
    valueGetter: params => params.data.contractedAmount,
    valueSetter: params => {
      params.data.contractedAmount = parseFloat(params.newValue)
      return true
    },
    cellStyle: { backgroundColor: '#fff' },
  },
  {
    field: 'officeContractedAmount',
    headerName: 'In-person Amount',
    editable: true,
    type: 'numericColumn',
    valueFormatter: currencyFormatter,
    valueGetter: params => params.data.officeContractedAmount,
    valueSetter: params => {
      params.data.officeContractedAmount = parseFloat(params.newValue)
      return true
    },
    cellStyle: { backgroundColor: '#fff' },
  },
]

export function PlanContractAmountTable({ payerContract, payerName, planName }: Props) {
  const gridRef = useRef<AgGridReact>(null)
  const [hasChanged, setHasChanged] = useState(false)
  const [isCellEditing, setIsCellEditing] = useState(false)
  const [gridReady, setGridReady] = useState(false)
  const [savePayerContract, { loading: saveLoading }] = useSavePlanContractMutation()

  const [rows, setRows] = useState<AmountRow[] | undefined>()

  useEffect(() => setRows(generateRowData(payerContract?.contractAmounts)), [payerContract?.contractAmounts])

  const transaction = useGetRowDataTransaction(rows, gridReady)

  const exportFileName = useMemo(() => {
    const dates = [
      moment(payerContract.startDate).format('YYYY-MM-DD'),
      moment(payerContract.endDate).format('YYYY-MM-DD'),
    ]
    return [kebabCase(payerName), kebabCase(planName), ...dates].join('_')
  }, [payerContract, payerName, planName])

  const handleCompTierGrouping = useCallback(
    (event: GroupCompTierEvent) => {
      if (gridRef?.current && gridReady && rows) {
        try {
          setRows(updateRowsForGrouping(rows, event))
          setHasChanged(true)
        } catch (e) {
          toast.urgent((e as Error).message)
        }
      }
    },
    [gridReady, rows],
  )

  useEffect(() => {
    const currentGrid = gridRef.current

    if (gridReady && currentGrid) {
      currentGrid.api.applyTransaction(transaction)
      currentGrid.api.addEventListener(GROUP_COMP_TIER_EVENT, handleCompTierGrouping)
    }
    return () => {
      if (gridReady && currentGrid) {
        currentGrid.api.removeEventListener(GROUP_COMP_TIER_EVENT, handleCompTierGrouping)
      }
    }
  }, [gridReady, transaction, handleCompTierGrouping])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: false,
      sortable: true,
      cellStyle: { backgroundColor: greySet[0].hex },
    }
  }, [])

  /**
   * Changes focus to the cell below current when enter is hit upon edit complete
   */
  const handleCellKeyDown = useCallback(
    (event: CellKeyDownEvent) => {
      const keyPressed = (event.event as KeyboardEvent)?.key

      if (keyPressed === 'Enter' && !isCellEditing) {
        const currentCell = event.api.getFocusedCell()
        const finalRowIndex = event.api.paginationGetRowCount() - 1

        // If we are editing the last row in the grid, don't move to next line
        if (!currentCell || currentCell?.rowIndex === finalRowIndex) {
          return
        }

        event.api.setFocusedCell(currentCell.rowIndex + 1, currentCell.column.getColId())
      }
    },
    [isCellEditing],
  )

  const onGridReady = useCallback(() => {
    setGridReady(true)
  }, [])

  const handleSave = async () => {
    if (gridRef.current) {
      try {
        const { id, insurancePayerPlanId, startDate, endDate } = payerContract
        const contractAmounts = prepareContractAmountsForSave(gridRef.current.api)
        await savePayerContract({
          variables: { payerContract: { id, insurancePayerPlanId, startDate, endDate, contractAmounts } },
        })
        toast.info('Contract amounts saved')
      } catch (e) {
        console.error(e)
        toast.urgent('There was an error saving the contract amounts')
      }
    }
  }

  const handleCancel = () => {
    setHasChanged(false)
    gridRef.current?.api.applyTransaction({ update: generateRowData(payerContract.contractAmounts) })
    toast.caution('Table reset to previously saved state.')
  }

  return (
    <TableWell className="flex-column flex-remaining-space">
      <div className="mb-2 space-between">
        <IconButton
          tooltip="Export as csv"
          onClick={() => gridRef?.current?.api.exportDataAsCsv({ fileName: exportFileName })}
        >
          <IconDownload />
        </IconButton>
        <span>
          <OutlineButton disabled={!hasChanged} onClick={handleCancel}>
            Cancel
          </OutlineButton>
          <FillButton className="ml-1" isLoading={saveLoading} disabled={!hasChanged} onClick={handleSave}>
            Save
          </FillButton>
        </span>
      </div>
      <div className="ag-theme-material flex-remaining-space">
        <AgGridReact
          ref={gridRef}
          getRowId={params => params.data.key}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection={true}
          enableFillHandle={true}
          onGridReady={onGridReady}
          onCellKeyDown={handleCellKeyDown}
          onCellEditingStarted={() => setIsCellEditing(true)}
          onCellEditingStopped={() => setIsCellEditing(false)}
          onCellValueChanged={() => setHasChanged(true)}
        ></AgGridReact>
      </div>
    </TableWell>
  )
}

function CompTierGroupCellRenderer(params: ICellRendererParams) {
  return (
    <Checkbox
      checked={!!params.value}
      onChange={() =>
        params.api.dispatchEvent({
          type: GROUP_COMP_TIER_EVENT,
          // @ts-expect-error AG Grid didn't type AgEvent as a generic. But you can pass whatever you want with the event as log as you include a type
          checked: !params.value,
          compTier: params.data.licenseType.compTier,
          cptCode: params.data.cptCode,
        })
      }
    />
  )
}

function generateRowData(rows?: FullPayerContractAmountFragment[] | null): AmountRow[] | undefined {
  if (!rows) {
    return undefined
  }

  const groupedByTypeAndCode = groupBy(rows, row => [row.cptCodeId, row.credentialType].join('_'))

  const unGroupedRows = compact(
    Object.values(groupedByTypeAndCode).map<AmountRow | null>(groupedRows => {
      const teleHealth = groupedRows.find(row => row.placeOfService === ContractAmountPlaceOfService.Telehealth)
      const office = groupedRows.find(row => row.placeOfService === ContractAmountPlaceOfService.Office)

      if (!teleHealth || !office) return null

      const {
        cptCodeId,
        payerContractId,
        cptCode,
        contractedAmount,
        credentialType,
        licenseType,
        licenseTypeId,
        placeOfService,
      } = teleHealth

      return {
        cptCodeId: cptCodeId,
        payerContractId: payerContractId,
        cptCode: cptCode?.code ?? '',
        masterCharge: numberService.centsToDollars(cptCode?.masterCharge ?? 0),
        contractedAmount: numberService.centsToDollars(contractedAmount),
        credentialType: credentialType,
        id: teleHealth.id,
        key: teleHealth.id,
        licenseTypeId: licenseTypeId,
        licenseType: licenseType,
        placeOfService: placeOfService,
        officeAmountId: office.id,
        officeContractedAmount: numberService.centsToDollars(office.contractedAmount),
      }
    }),
  )

  const groupedByCompTier = groupBy(unGroupedRows, row => [row.licenseType.compTier, row.cptCode].join('_'))

  return Object.values(groupedByCompTier).flatMap(compTierRows => {
    const canGroup = compTierRows.every(
      row =>
        row.contractedAmount === compTierRows[0].contractedAmount &&
        row.officeContractedAmount === compTierRows[0].officeContractedAmount,
    )

    if (canGroup) {
      return [
        {
          ...compTierRows[0],
          groupedByCompTier: true,
          ungroupedRows: compTierRows,
          credentialType: '--',
          key: [compTierRows[0].key, 'grouped'].join('_'),
        },
      ]
    }

    return compTierRows
  })
}

function currencyFormatter(params: ValueFormatterParams) {
  return numberService.formatCurrency(params.value, { maximumFractionDigits: 2 })
}

function useGetRowDataTransaction(rows: AmountRow[] | undefined, gridReady: boolean): RowDataTransaction {
  const previousRows = usePrevious(gridReady ? rows : undefined)

  return useMemo(() => {
    if (!rows) {
      return {}
    }

    if (!previousRows) {
      return { add: rows }
    }

    const add = rows.filter(row => !previousRows.map(pRow => pRow.key).includes(row.key))
    const update = intersectionBy(rows, previousRows, row => row.key)
    const remove = previousRows.filter(row => !update.map(uRow => uRow.key).includes(row.key))

    if (!add.length && !remove.length) {
      return {}
    }

    return { update, add, remove }
  }, [rows, previousRows])
}

function updateRowsForGrouping(rows: AmountRow[], event: GroupCompTierEvent): AmountRow[] {
  const { checked: isGrouping, compTier, cptCode } = event
  const byCompTierAndCptCode = (row: AmountRow) => row.licenseType.compTier === compTier && row.cptCode === cptCode
  const compTierRow = rows.find(byCompTierAndCptCode)

  if (!compTierRow) {
    throw new Error(`There was an issue ${isGrouping ? 'ungrouping' : 'grouping'} the rows by comp tier.`)
  }

  const newRows = isGrouping
    ? [
        {
          ...compTierRow,
          groupedByCompTier: true,
          ungroupedRows: rows.filter(byCompTierAndCptCode),
          credentialType: '--',
          key: [compTierRow.key, 'grouped'].join('_'),
        },
      ]
    : compTierRow.ungroupedRows ?? []

  return [...rows.filter(row => row.licenseType.compTier !== compTier || row.cptCode !== cptCode), ...newRows]
}

function prepareContractAmountsForSave(gridApi: GridApi): PayerContractAmountInput[] {
  const rowData: AmountRow[] = []
  gridApi.forEachNode(row => rowData.push(row.data))
  return rowData.flatMap<PayerContractAmountInput>(row => {
    if (row.groupedByCompTier) {
      return (
        row.ungroupedRows?.flatMap<PayerContractAmountInput>(ungroupedRow => {
          return [
            {
              id: ungroupedRow.id,
              payerContractId: ungroupedRow.payerContractId,
              cptCodeId: ungroupedRow.cptCodeId,
              credentialType: ungroupedRow.credentialType,
              licenseTypeId: ungroupedRow.licenseTypeId,
              placeOfService: ContractAmountPlaceOfService.Telehealth,
              contractedAmount: numberService.dollarsToCents(row.contractedAmount),
            },
            {
              id: ungroupedRow.officeAmountId,
              payerContractId: ungroupedRow.payerContractId,
              cptCodeId: ungroupedRow.cptCodeId,
              credentialType: ungroupedRow.credentialType,
              licenseTypeId: ungroupedRow.licenseTypeId,
              placeOfService: ContractAmountPlaceOfService.Office,
              contractedAmount: numberService.dollarsToCents(row.officeContractedAmount),
            },
          ]
        }) ?? []
      )
    }

    return [
      {
        id: row.id,
        payerContractId: row.payerContractId,
        cptCodeId: row.cptCodeId,
        credentialType: row.credentialType,
        licenseTypeId: row.licenseTypeId,
        placeOfService: ContractAmountPlaceOfService.Telehealth,
        contractedAmount: numberService.dollarsToCents(row.contractedAmount),
      },
      {
        id: row.officeAmountId,
        payerContractId: row.payerContractId,
        cptCodeId: row.cptCodeId,
        credentialType: row.credentialType,
        licenseTypeId: row.licenseTypeId,
        placeOfService: ContractAmountPlaceOfService.Office,
        contractedAmount: numberService.dollarsToCents(row.officeContractedAmount),
      },
    ]
  })
}

const TableWell = styled('section')`
  background-color: ${eggshell};
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius-sm);
  padding: 2rem;
`
