import { useParams } from 'react-router-dom'

import { Stack } from '@nuna/tunic'

import { ClientSourceSection } from './ClientSourceSection'
import { ManageClientEAPHistory } from './ManageClientEAPHistory'
import { MinorIntakeSection } from './MinorIntakeSection'
import { PatientPaymentPreference } from './PatientPaymentPreference'
import { PatientStatus } from './PatientStatus'

type RouteProps = {
  id: string
}

export function ManageClient() {
  const { id = '' } = useParams<RouteProps>()
  return (
    <Stack spacing={6}>
      <ManageClientEAPHistory patientId={id} />
      <PatientPaymentPreference patientId={id} />
      <PatientStatus patientId={id} />
      <MinorIntakeSection patientId={id} />
      <ClientSourceSection patientId={id} />
    </Stack>
  )
}
