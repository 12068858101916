import { styled } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import moment from 'moment-timezone'
import { HTMLAttributes, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import {
  DocumentType,
  UserDocumentStatus,
  UserDocumentsQuery,
  useSaveUserDocumentMutation,
  useUserDocumentsQuery,
} from '@nuna/api'
import { useDownloadDocument } from '@nuna/common'
import { documentService, errorService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import {
  Card,
  Confirm,
  ConfirmRef,
  FileTypeIcon,
  IconButton,
  IconFilter,
  IconPlus,
  IconToggleButton,
  IconTrash,
  Skeleton,
  toast,
} from '@nuna/tunic'

import { AddUserDocumentsForm } from './AddUserDocumentsForm'
import { DocumentTypeSelect } from './DocumentTypeSelect'

const { getReadableDocumentType } = documentService

type Row = {
  id: string
  document: UserDocumentsQuery['userDocuments'][0]['document']
  documentType: UserDocumentsQuery['userDocuments'][0]['document']['documentType']
  createdAt: UserDocumentsQuery['userDocuments'][0]['document']['createdAt']
  createdBy: UserDocumentsQuery['userDocuments'][0]['createdByUser']
}

export interface DocumentCenterProps extends HTMLAttributes<HTMLDivElement> {
  loginId?: string | null
  documentTypes?: DocumentType[]
  header?: ReactNode
  showHeader?: boolean
}

export function DocumentCenter({
  loginId,
  documentTypes,
  header = <h2 className="h4">Documents</h2>,
  showHeader = true,
  ...props
}: DocumentCenterProps) {
  const confirmRef = useRef<ConfirmRef>(null)
  const [downloadDocument, downloadDocumentRef] = useDownloadDocument()
  const [saveUserDocument] = useSaveUserDocumentMutation()

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [addDocumentOpen, setAddDocumentOpen] = useState(false)
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentType | undefined>()

  const { data, refetch } = useUserDocumentsQuery({
    variables: {
      searchOptions: { loginId: loginId ?? '', documentType: selectedDocumentType, documentTypes },
      includeDownloadUrl: true,
    },
    skip: !loginId,
  })

  const handleArchiveDocument = useCallback(
    async (id: string, documentId: string) => {
      confirmRef.current?.open(async response => {
        if (response) {
          try {
            await saveUserDocument({
              variables: { userDocument: { id, documentId, status: UserDocumentStatus.Archived } },
              refetchQueries: ['UserDocuments'],
            })
            toast.success('Document archived successfully')
          } catch (e) {
            toast.urgent(errorService.transformGraphQlError(e, 'Unable to archive document'))
          }
        }
      })
    },
    [saveUserDocument],
  )

  const rowData = useMemo(() => buildRows(data?.userDocuments), [data?.userDocuments])

  const columns: DataTableColumn<Row>[] = useMemo(
    () => [
      {
        Header: 'Document',
        accessor: 'document',
        Cell: ({ value }) => (
          <div key={value.id} className="v-align pb-1">
            <FileTypeIcon mimeType={value.mimeType} className="mr-1" />
            <a
              href={value.downloadUrl ?? ''}
              onClick={e => {
                e.preventDefault()
                downloadDocument(value.id)
              }}
              download
            >
              {value.fileName}
            </a>
          </div>
        ),
      },
      {
        Header: 'Type',
        accessor: 'documentType',
        Cell: ({ value }) => <>{getReadableDocumentType(value)}</>,
      },
      {
        Header: 'Date Added',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{moment.tz(value, moment.tz.guess()).format('ll h:MM z')}</>,
      },
      {
        Header: 'Added By',
        accessor: 'createdBy',
        Cell: ({ value }) => (
          <div>
            {value?.firstName} {value?.lastName}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value, row }) => (
          <IconButton
            tooltip="Archive document"
            variant="destroy"
            onClick={() => handleArchiveDocument(value, row.original.document.id)}
          >
            <IconTrash />
          </IconButton>
        ),
      },
    ],
    [downloadDocument, handleArchiveDocument],
  )

  const collapsableSectionOpen = filtersOpen || addDocumentOpen

  if (!loginId) return <Skeleton height={10} />

  return (
    <Container ref={downloadDocumentRef} {...props}>
      <div className="v-align py-1">
        {showHeader && header}
        <div className="v-align py-1 gap-1 ml-auto">
          <IconToggleButton
            tooltip="Filter"
            isActive={filtersOpen}
            onClick={() => {
              setFiltersOpen(!filtersOpen)
              setAddDocumentOpen(false)
            }}
          >
            <IconFilter />
          </IconToggleButton>
          <IconToggleButton
            tooltip="Add Document"
            isActive={addDocumentOpen}
            onClick={() => {
              setAddDocumentOpen(!addDocumentOpen)
              setFiltersOpen(false)
            }}
          >
            <IconPlus />
          </IconToggleButton>
        </div>
      </div>
      <AnimatePresence>
        {collapsableSectionOpen && (
          <motion.div
            key="document-center-collapsable-section"
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            <Card depth={filtersOpen ? -0.5 : 1} className="p-2 mb-1 v-align">
              {filtersOpen && (
                <div>
                  <DocumentTypeSelect
                    value={selectedDocumentType}
                    onChange={option => setSelectedDocumentType(option?.value ?? undefined)}
                    style={{ width: 240 }}
                  />
                </div>
              )}
              {addDocumentOpen && (
                <AddUserDocumentsForm
                  loginId={loginId}
                  onDocumentsUploaded={() => {
                    refetch()
                    setAddDocumentOpen(false)
                  }}
                />
              )}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>

      <DataTable
        columns={columns}
        rowData={rowData}
        loading={false}
        className="document-table"
        noRecordsMessage="No documents to show"
      />
      <Confirm ref={confirmRef}>Are you sure you want to archive the document?</Confirm>
    </Container>
  )
}

function buildRows(records?: UserDocumentsQuery['userDocuments'] | null): Row[] {
  if (!records) return []
  return records.map(record => ({
    id: record.id,
    document: record.document,
    documentType: record.document.documentType,
    createdAt: record.document.createdAt,
    createdBy: record.createdByUser,
  }))
}

const Container = styled('div')`
  .document-table {
    z-index: 2;
    background: #fff;
    position: relative;
  }
`
