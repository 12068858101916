import moment from 'moment'
import { useRef } from 'react'

import { useRefreshProvidersDataDenormalizedMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Confirm, ConfirmRef, IconButton, IconLoading, IconSwitch, toast } from '@nuna/tunic'

import { RosterDataValidationStatus } from '../PayerRosters/components/RosterDataValidationStatus'
import { useMasterRosterContext } from './MasterRosterContextProvider'
import { useRosterValidation } from './hooks/useRosterValidation'

export function MasterRosterOptions() {
  const refreshConfirmRef = useRef<ConfirmRef>(null)

  const { isValid, revalidate, loading: validationLoading } = useRosterValidation()

  const [refreshProvidersDataDenormalized] = useRefreshProvidersDataDenormalizedMutation()

  const { dataState, setDataState } = useMasterRosterContext()
  const lastRefreshed = dataState.updatedAt ? moment(dataState.updatedAt).fromNow() : ''

  const handleRefresh = () => {
    refreshConfirmRef.current?.open(async shouldRefreshData => {
      if (shouldRefreshData) {
        try {
          setDataState({ ...dataState, refreshing: true })

          await refreshProvidersDataDenormalized()

          toast.success(`Master Roster Data has been queued for refresh`)
        } catch (error) {
          toast.urgent(errorService.transformGraphQlError(error, 'There was a problem refreshing Master Roster Data'))
        }
      }
    })
  }

  return (
    <div className="v-align gap-2">
      {dataState.id && (
        <span>
          Refreshed at: {dataState.refreshing ? <IconLoading className="ml-1 rotate" size={18} /> : lastRefreshed}
        </span>
      )}

      <span className="v-align gap-1">
        <IconButton tooltip="Refresh data" disabled={dataState.refreshing} onClick={handleRefresh}>
          <IconSwitch />
        </IconButton>
        <RosterDataValidationStatus validationLoading={validationLoading} isValid={isValid} revalidate={revalidate} />
      </span>
      <Confirm ref={refreshConfirmRef}>
        Are you sure you want to refresh the current dataset? This will take a few minutes to complete.
      </Confirm>
    </div>
  )
}
