import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { HTMLAttributes, useMemo, useState } from 'react'
import { MdAdd, MdContentCopy } from 'react-icons/md'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { OrderBy, PayerContractSortKeys, usePlanContractsQuery } from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, IconButton, IconPencil, Select, Skeleton, toast } from '@nuna/tunic'

import { planContract } from '../../../util/routes'
import { PlanContractAmountTable } from './PlanContractAmountTable'
import { PlanContractCopyForm } from './PlanContractCopyForm'
import { PlanContractForm } from './PlanContractForm'
import { contractLabel, getContractFromParam, getOngoingContract } from './plan-contract-utils'

interface Props extends HTMLAttributes<HTMLDivElement> {
  payerId: string
  planId: string
  payerName: string
  planName: string
}

interface ContractOption {
  id: string
  label: string
}

export function PlanContracts({ payerId, planId, payerName, planName, ...props }: Props) {
  const navigate = useNavigate()
  const { planContractId = '' } = useParams<{ planContractId: string }>()
  const [showAddContractForm, setShowAddContractForm] = useState(false)
  const [showEditContractForm, setShowEditContractForm] = useState(false)
  const [showCopyContractForm, setShowCopyContractForm] = useState(false)

  const { data, error } = usePlanContractsQuery({
    variables: {
      filters: { insurancePayerPlanId: planId },
      order: [{ key: PayerContractSortKeys.EndDate, direction: OrderBy.Desc }],
      pagination: { limit: 1000 },
    },
    fetchPolicy: 'cache-and-network',
  })

  const contractOptions = useMemo<ContractOption[]>(() => {
    if (!data) {
      return []
    }

    return data.payerContracts.items.map(contract => ({ id: contract.id, label: contractLabel(contract) }))
  }, [data])

  if (error) {
    toast.urgent(errorService.transformGraphQlError(error))
  }

  if (!data) {
    return (
      <Container {...props} className="mt-2">
        <Skeleton height={8} className="mb-3" />
        <Skeleton height={30} />
      </Container>
    )
  }

  const contracts = data.payerContracts.items ?? []

  if (!contracts.length || showAddContractForm) {
    return (
      <Container {...props}>
        <PlanContractForm
          className="mt-2"
          payerId={payerId}
          insurancePayerPlanId={planId}
          initialStartDate={contracts[0]?.endDate ? moment(contracts[0].endDate).add(1, 'days') : null}
          showCancelButton={showAddContractForm}
          onCancel={() => setShowAddContractForm(false)}
          afterSave={() => setShowAddContractForm(false)}
        />
      </Container>
    )
  }

  /**
   * currentContract is one of the following
   * - if there is a contract id as a url param (/contracts/{contractId}) then it is that contract
   * - OR the contract where today is between start and end date
   * - OR the first contract in the array of contracts
   */
  const currentContract =
    getContractFromParam(planContractId, contracts) ?? getOngoingContract(contracts) ?? (contracts ?? [])[0]

  if (!planContractId && currentContract) {
    return <Navigate to={planContract(planId, payerId, currentContract.id)} />
  }

  return (
    <Container className="flex-column flex-remaining-space" {...props}>
      {showEditContractForm && (
        <PlanContractForm
          contract={currentContract}
          insurancePayerPlanId={planId}
          payerId={payerId}
          showCancelButton
          onCancel={() => setShowEditContractForm(false)}
          afterSave={() => setShowEditContractForm(false)}
          className="mb-4 mt-2"
        />
      )}
      {showCopyContractForm && (
        <PlanContractCopyForm
          contract={currentContract}
          onCancel={() => setShowCopyContractForm(false)}
          afterSave={() => setShowCopyContractForm(false)}
          className="mb-4 mt-2"
        />
      )}
      {!showEditContractForm && !showCopyContractForm && (
        <ContractDetails className="space-between v-align">
          {!showEditContractForm}
          <div className="v-align">
            <Select
              value={currentContract.id}
              onChange={event => navigate(planContract(planId, payerId, event.currentTarget.value))}
            >
              {contractOptions.map(option => (
                <option value={option.id} key={option.id}>
                  {option.label}
                </option>
              ))}
            </Select>
            <IconButton
              className="ml-2"
              style={{ marginTop: '0.8rem' }}
              tooltip="Edit contract"
              onClick={() => setShowEditContractForm(true)}
            >
              <IconPencil />
            </IconButton>
            <IconButton
              style={{ marginTop: '0.8rem' }}
              tooltip="Copy Contract"
              onClick={() => setShowCopyContractForm(true)}
            >
              <MdContentCopy />
            </IconButton>
          </div>
          <FillButton leadingIcon onClick={() => setShowAddContractForm(true)}>
            <MdAdd />
            Add Contract
          </FillButton>
        </ContractDetails>
      )}
      <section className="flex-column flex-remaining-space">
        <PlanContractAmountTable payerContract={currentContract} payerName={payerName} planName={planName} />
      </section>
    </Container>
  )
}

const ContractDetails = styled('div')`
  padding: 0 var(--margin-1) var(--margin-5) var(--margin-1);
`

const Container = styled('div')`
  width: 100%;
`
