import { useMemo, useState } from 'react'
import { Navigate, Route, Routes, matchPath, useLocation, useParams } from 'react-router-dom'

import { useInsurancePayerQuery } from '@nuna/api'
import { statusMap } from '@nuna/common'
import { MenuItem, MenuItemStack, OutlineButtonLink, StatusLabel, csx } from '@nuna/tunic'

import { NavLayout } from '../../../layouts/NavLayout'
import { BreadcrumbBar } from '../../../layouts/detail-layout/BreadcrumbBar'
import { BreadcrumbItem } from '../../../layouts/detail-layout/Breadcrumbs'
import {
  DetailColumn,
  DetailColumnLoader,
  DetailContent,
  DetailContentNav,
  DetailLayout,
  DetailMainView,
} from '../../../layouts/detail-layout/DetailLayout'
import { PayerSubRoutes, newPlan, payers } from '../../../util/routes'
import { InsurancePolicyTable } from '../../insurance-policy/InsurancePolicyTable/InsurancePolicyTable'
import { PayerConfiguration } from '../../payer-configuration/PayerConfiguration'
import { PayerNetworkIndex } from '../../payer-network/PayerNetworkIndex'
import { PayerPlanTable } from '../../payer-plan/PayerPlanTable'
import { PayerForm } from '../PayerForm'
import { PayerDetailColumn } from './PayerDetailColumn'
import { PayerEnrollments } from './PayerEnrollments'

export function PayerDetail() {
  const location = useLocation()
  const [isEditing, setIsEditing] = useState(false)

  const { id = '' } = useParams<{ id: string }>()
  const { data, loading } = useInsurancePayerQuery({ variables: { id } })

  const breadcrumbs = useMemo<BreadcrumbItem[]>(
    () => [{ label: 'Payers', path: payers }, { label: data?.insurancePayer.name ?? '' }],
    [data],
  )

  const payer = data?.insurancePayer

  const { intent, status } = statusMap(payer?.status)

  return (
    <NavLayout containerProps={{ className: 'flex-column' }}>
      <BreadcrumbBar breadcrumbs={breadcrumbs} loading={loading}>
        {payer && (
          <StatusLabel intent={intent} className="mr-2">
            {status}
          </StatusLabel>
        )}
      </BreadcrumbBar>
      <DetailLayout className="flex-remaining-space">
        <DetailColumn className={csx({ wide: isEditing })}>
          {!isEditing && loading && <DetailColumnLoader />}
          {!isEditing && !loading && payer && <PayerDetailColumn payer={payer} onEdit={() => setIsEditing(true)} />}
          {isEditing && payer && (
            <PayerForm payer={payer} onCancel={() => setIsEditing(false)} afterSave={() => setIsEditing(false)} />
          )}
        </DetailColumn>
        <DetailMainView>
          <DetailContentNav className="mb-1">
            <MenuItemStack direction="row">
              <MenuItem to={PayerSubRoutes.Plans}>Plans</MenuItem>
              <MenuItem to={PayerSubRoutes.Network}>Network</MenuItem>
              <MenuItem to={PayerSubRoutes.Enrollments}>Provider Enrollments</MenuItem>
              <MenuItem to={PayerSubRoutes.Policies}>Policies</MenuItem>
              <MenuItem to={PayerSubRoutes.Configuration}>Configuration</MenuItem>
            </MenuItemStack>
            <Routes>
              <Route
                path={PayerSubRoutes.Enrollments}
                element={
                  <OutlineButtonLink className="ml-auto" to="add">
                    Add Provider Enrollment
                  </OutlineButtonLink>
                }
              />
              <Route
                path={PayerSubRoutes.Plans}
                element={
                  <OutlineButtonLink className="ml-auto" to={newPlan(id)}>
                    New Plan
                  </OutlineButtonLink>
                }
              />
            </Routes>
          </DetailContentNav>
          <DetailContent className={csx({ 'bottom-buffer': !!matchPath('providers', location.pathname) })}>
            <Routes>
              <Route index element={<Navigate to={PayerSubRoutes.Plans} replace />} />
              <Route path={PayerSubRoutes.Plans} element={<PayerPlanTable payerId={id} />} />
              <Route path={PayerSubRoutes.Network} element={<PayerNetworkIndex payerId={id} />} />
              <Route
                path={PayerSubRoutes.Policies}
                element={
                  <InsurancePolicyTable
                    columns={['patient', 'plan', 'network', 'active']}
                    fixedFilters={{ insurancePayerId: id }}
                  />
                }
              />
              <Route
                path={PayerSubRoutes.Configuration}
                element={
                  <PayerConfiguration
                    configuration={payer?.insurancePayerConfiguration}
                    payerId={id}
                    isTpa={data?.insurancePayer ? !!data.insurancePayer.tpaForInsurancePayer : undefined}
                  />
                }
              />
              <Route
                path={`${PayerSubRoutes.Enrollments}/*`}
                element={<PayerEnrollments className="full-width" payerId={id} />}
              />
            </Routes>
          </DetailContent>
        </DetailMainView>
      </DetailLayout>
    </NavLayout>
  )
}
