import { isPlainObject } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type UrlFilters = Record<string, unknown>

export function useUrlFilters<T>(transform?: (values: UrlFilters) => T) {
  const [params, setParams] = useSearchParams()

  const [serializedFilterValues, setSerializedFilterValues] = useState<UrlFilters>(paramsToFilterValues(params))

  const filterValues = useMemo(() => {
    return transform ? transform(serializedFilterValues) : (serializedFilterValues as T)
  }, [serializedFilterValues, transform])

  const setFilter = useCallback(
    (key: string, value: unknown) => {
      setParams(prev => {
        const next = new URLSearchParams(prev)
        next.set(key, serializeValue(value))
        return next
      })
    },
    [setParams],
  )

  const removeFilter = useCallback(
    (key: string) => {
      setParams(prev => {
        const next = new URLSearchParams(prev)
        next.delete(key)
        return next
      })
    },
    [setParams],
  )

  const getCustomTransformedValues = useCallback(
    (customTransformFn: (params: UrlFilters) => unknown) => {
      return customTransformFn(paramsToFilterValues(params))
    },
    [params],
  )

  useEffect(() => {
    setSerializedFilterValues(prev => {
      const values = paramsToFilterValues(params)

      if (JSON.stringify(values) !== JSON.stringify(prev)) {
        return values
      }
      return prev
    })
  }, [params, transform])

  return { filterValues, getCustomTransformedValues, setFilter, removeFilter }
}

function paramsToFilterValues(params: URLSearchParams): UrlFilters {
  const values: UrlFilters = {}

  for (const [key, value] of params.entries()) {
    values[key] = deserializeValue(value)
  }
  return values
}

function serializeValue(value: unknown) {
  if (Array.isArray(value)) {
    return value.join(',')
  }

  if (isPlainObject(value)) {
    return JSON.stringify(value)
  }

  return String(value)
}

function deserializeValue(value: string) {
  try {
    return JSON.parse(value)
  } catch {
    // continue
  }

  if (value.includes(',')) {
    return value.split(',')
  }

  if (value === 'true') return true
  if (value === 'false') return false

  return value
}

export type UrlFilterService<T> = ReturnType<typeof useUrlFilters<T>>
