import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { MdOutlineDoNotDisturbAlt } from 'react-icons/md'

import { OrganizationRelationship, useSearchEligibleRecordsLazyQuery } from '@nuna/api'
import { useDebounce } from '@nuna/common'
import {
  Checkbox,
  ContextualAlert,
  IconSearch,
  OutlineButton,
  SpinningLoader,
  StatusLabel,
  TextField,
  borderGrey,
  greySet,
} from '@nuna/tunic'

import { useMemberMatchContext } from '../MemberMatchContextProvider'
import { MatchTypeIfDependent, MemberMatchPerson, mapEmployeeRecordToMatchPerson } from '../normalize-member-match-data'
import { EmployeeMatchTableRow } from './EmployeeMatchTableRow'
import { AddressCell, AssociationCell, FullNameCell, TextCell, TextCellProps } from './cells'

interface Props {
  patient: MemberMatchPerson
  matchTypeIfDependent: MatchTypeIfDependent
  markAsIneligible: () => void
  markAsIneligibleLoading: boolean
  association: OrganizationRelationship | null | undefined
  strictlyEnforceRoster: boolean
}

export function EmployeeRecordRows({
  patient,
  matchTypeIfDependent,
  markAsIneligible,
  markAsIneligibleLoading,
  association,
  strictlyEnforceRoster,
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 450)
  const [searchResults, setSearchResults] = useState<MemberMatchPerson[]>([])
  const [includeInactives, setIncludeInactives] = useState(false)
  const { patientEmployeeMatchMap } = useMemberMatchContext()

  const { employee: matchedEmployee } = patientEmployeeMatchMap.get(patient) ?? {}

  const [searchRecords, { data: searchResponse, loading: searchLoading }] = useSearchEligibleRecordsLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const memberTypesForSearch = useMemo(() => {
    if (strictlyEnforceRoster && association === OrganizationRelationship.Employee) {
      return [OrganizationRelationship.Employee]
    }

    if (strictlyEnforceRoster && association !== OrganizationRelationship.Employee) {
      return [OrganizationRelationship.SpousePartner, OrganizationRelationship.Child]
    }

    return undefined
  }, [association, strictlyEnforceRoster])

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      searchRecords({
        variables: {
          id: patient.companyId,
          searchQuery: debouncedSearchTerm,
          filters: {
            hideInactive: !includeInactives,
            memberTypes: memberTypesForSearch,
          },
        },
      })
    }
  }, [debouncedSearchTerm, searchRecords, patient, memberTypesForSearch, includeInactives])

  useEffect(() => {
    if (debouncedSearchTerm.length < 3) {
      setSearchResults([])
    } else {
      const results = searchResponse?.searchCustomerEligibleRecordsForCompany ?? []
      setSearchResults(results.map(result => mapEmployeeRecordToMatchPerson(result, patient.companyName)))
    }
  }, [debouncedSearchTerm, searchResponse, patient])

  if (matchedEmployee) {
    return (
      <>
        <Row patient={patient} record={matchedEmployee} />
        <BufferRow />
      </>
    )
  }

  return (
    <>
      <SearchRow
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        hasSearchResults={searchResults.length > 0}
        matchTypeIfDependent={matchTypeIfDependent}
        markAsIneligible={markAsIneligible}
        markAsIneligibleLoading={markAsIneligibleLoading}
        association={association}
        strictlyEnforceRoster={strictlyEnforceRoster}
        includeInactives={includeInactives}
        setIncludeInactives={setIncludeInactives}
      />
      {searchLoading && (
        <tr>
          <td colSpan={1}></td>
          <td>
            <div style={{ width: '24px' }} className="mt-1 ml-2">
              <SpinningLoader $inFlow size={24} />
            </div>
          </td>
        </tr>
      )}
      {!searchLoading && searchResults.map(record => <Row patient={patient} record={record} key={record.id} />)}
      <BufferRow />
    </>
  )
}

function BufferRow() {
  return (
    <BufferRowStyled>
      <td colSpan={1}>&nbsp;</td>
      <td colSpan={10}>&nbsp;</td>
    </BufferRowStyled>
  )
}

interface RowProps {
  patient: MemberMatchPerson
  record: MemberMatchPerson
}
function Row({ patient, record }: RowProps) {
  const { isMatch, setPatientEmployeeMatch, removePatientEmployeeMatch } = useMemberMatchContext()
  const EmployeeRecordCell = ({ record, ...props }: TextCellProps) => (
    <TextCell record={record} patientForComparison={patient} {...props} />
  )

  const handleCheckboxToggle = (checked: boolean, employee: MemberMatchPerson) => {
    if (checked) {
      setPatientEmployeeMatch(patient, employee)
    } else {
      removePatientEmployeeMatch(patient)
    }
  }

  return (
    <EmployeeMatchTableRow
      rowType="employeeRecord"
      key={record.id}
      columns={[
        null,
        <span className="v-align">
          <Checkbox
            checked={isMatch(patient, record.id)}
            onChange={e => handleCheckboxToggle(e.target.checked, record)}
          />
          <FullNameCell record={record} patientForComparison={patient} />
        </span>,
        <EmployeeRecordCell
          record={record}
          accessorKey="dob"
          formatter={dob => moment.tz(dob, 'UTC').format('MM/DD/YYYY')}
        />,
        <EmployeeRecordCell record={record} accessorKey="companyName" />,
        <AssociationCell record={record} />,
        <AddressCell record={record} patientForComparison={patient} />,
        <EmployeeRecordCell record={record} accessorKey="phone" />,
        <EmployeeRecordCell record={record} accessorKey="gender" />,
        record.emergencyContactName,
        record.emergencyContactRelationship,
        record.emergencyContactPhone,
      ]}
    ></EmployeeMatchTableRow>
  )
}

interface SearchRowProps {
  searchTerm: string
  setSearchTerm: (term: string) => void
  hasSearchResults: boolean
  matchTypeIfDependent: MatchTypeIfDependent
  markAsIneligible: () => void
  markAsIneligibleLoading: boolean
  association: OrganizationRelationship | null | undefined
  strictlyEnforceRoster: boolean
  includeInactives: boolean
  setIncludeInactives: (value: boolean) => void
}

function SearchRow({
  searchTerm,
  setSearchTerm,
  hasSearchResults,
  matchTypeIfDependent,
  markAsIneligible,
  markAsIneligibleLoading,
  association,
  strictlyEnforceRoster,
  includeInactives,
  setIncludeInactives,
}: SearchRowProps) {
  const isEmployee = association === OrganizationRelationship.Employee
  const alert = () => {
    if (matchTypeIfDependent === 'ineligible' && !isEmployee) {
      return (
        <ContextualAlert intent="urgent">
          <MdOutlineDoNotDisturbAlt /> Dependents are ineligible
        </ContextualAlert>
      )
    }
    const adjustArticle = isEmployee

    return (
      <ContextualAlert>
        Search for {adjustArticle ? 'an' : 'a'}&nbsp;<b>{isEmployee ? 'employee' : matchTypeIfDependent}</b>&nbsp;record
      </ContextualAlert>
    )
  }
  return (
    <SearchRowStyled showBottomBorder={!hasSearchResults}>
      <td></td>
      <td colSpan={2}>
        {!hasSearchResults && alert()}
        {!hasSearchResults && !isEmployee && strictlyEnforceRoster && (
          <StatusLabel className="mt-1">Dependents must match with themselves</StatusLabel>
        )}
      </td>
      <td colSpan={7}>
        <div className="v-align">
          <span className="search-field-wrapper">
            <IconSearch className="search-icon" />
            <TextField
              style={{ width: '600px' }}
              label="Enter a search criteria (separated by spaces)"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <Checkbox
              className="ml-2"
              checked={includeInactives}
              onChange={() => setIncludeInactives(!includeInactives)}
            >
              Include inactives
            </Checkbox>
          </span>
        </div>
      </td>
      <td>
        {matchTypeIfDependent === 'ineligible' && (
          <OutlineButton onClick={markAsIneligible} isLoading={markAsIneligibleLoading} variant="destroy">
            Mark Ineligible
          </OutlineButton>
        )}
      </td>
    </SearchRowStyled>
  )
}

const SearchRowStyled = styled('tr')<{ showBottomBorder: boolean }>`
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  td {
    padding: var(--margin-2) var(--margin-1);
    &:first-of-type {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      z-index: 1;
      background-color: #fff;
    }
    &:not(:first-of-type) {
      background-color: ${greySet[0].hex};
      /* border-bottom: ${({ showBottomBorder }) => (showBottomBorder ? `1px solid ${borderGrey}` : 'none')}; */
    }

    .search-field-wrapper {
      align-items: flex-end;
      display: flex;

      .search-icon {
        margin-bottom: 4px;
        margin-right: 4px;
      }
    }
  }
`

const BufferRowStyled = styled('tr')`
  td {
    line-height: var(---margin-2);

    &:first-of-type {
      position: sticky;
      position: -webkit-sticky;
      left 0;
      z-index: 1;
      background-color: #fff;
    }

    &:not(:first-of-type) {
      border-bottom: 1px solid ${borderGrey};
    }
  }
`
