import { Route, Routes } from 'react-router-dom'

import { PageContent } from '@nuna/tunic'

import { TavaAddressForm } from './components/TavaAddressForm'
import { TavaAddressTable } from './components/TavaAddressTable'

export function TavaAddressIndex() {
  return (
    <Routes>
      <Route
        index
        element={
          <PageContent className="overflow-hidden flex-column">
            <TavaAddressTable header={<h5 className="serif mb-0">Tava Practice Addresses</h5>} />
          </PageContent>
        }
      />
      <Route path="new" element={<TavaAddressForm />} />
      <Route path=":id" element={<TavaAddressForm />} />
    </Routes>
  )
}
