import { useMonaco } from '@monaco-editor/react'
import { HTMLAttributes, useEffect, useMemo, useState } from 'react'

import { OutlineButton } from '@nuna/tunic'

import { CodeEditor } from '../../../../../../shared/Editor/Editor'
import { getCustomEvalTypeDeclarations } from '../CustomEvalTypes'
import { useColumnMetaData } from '../hooks/useColumnMetaData'

interface EditCustomEvalFunctionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: string | null
  dataMappingKeys: string[]
  onChange: (value: string) => void
}

export function EditCustomEvalFunction({
  value: valueInput,
  dataMappingKeys,
  onChange,
  ...props
}: EditCustomEvalFunctionProps) {
  const [value, setValue] = useState(valueInput ?? `() => {\n /*${getHelperText()}*/\n}`)
  const { columnMetaData: allColumnMetaData } = useColumnMetaData()

  const columnMetaData = useMemo(
    () => allColumnMetaData?.filter(m => dataMappingKeys.includes(m.key)) ?? [],
    [dataMappingKeys, allColumnMetaData],
  )

  const monaco = useMonaco()

  useEffect(() => {
    if (monaco) {
      monaco.languages.typescript.javascriptDefaults.setExtraLibs([
        { content: getCustomEvalTypeDeclarations(columnMetaData) },
      ])
    }
  }, [monaco, columnMetaData])

  const canSave = value !== valueInput

  const handleSave = () => {
    onChange(value)
  }

  return (
    <div {...props}>
      <CodeEditor editorOptions={{ height: 250 }} code={value} onCodeChange={code => setValue(code)} />
      <OutlineButton disabled={!canSave} className="mt-2" type="button" onClick={handleSave}>
        Save Expression
      </OutlineButton>
    </div>
  )
}

function getHelperText() {
  return `
    Access values of the data mappings with the value variable.
    It is an array if there are multiple data mappings.
    Access helper methods with the ctx variable.
  `
}
