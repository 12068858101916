import { styled } from '@mui/material'
import { startCase } from 'lodash'
import { useState } from 'react'

import { LicenseCompensationTier } from '@nuna/api'
import { PageContent, PageHeader, PageHeading, PageWrapper, Select } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { ProviderDefaultCompensationTable } from './ProviderDefaultCompensationTable'

export function ProviderDefaultCompensationIndex() {
  const [tier, setTier] = useState(LicenseCompensationTier.IndependentlyLicensedMasters)

  return (
    <NavLayout containerProps={{ className: 'flex-column' }}>
      <PageWrapper>
        <PageHeader>
          <div className="v-align">
            <PageHeading withDivider className="mb-0">
              Provider Compensation
            </PageHeading>
            <TierSelect value={tier} onChange={e => setTier(e.currentTarget.value as LicenseCompensationTier)}>
              {Object.values(LicenseCompensationTier).map(compTier => (
                <option key={compTier} value={compTier}>
                  {startCase(compTier)}
                </option>
              ))}
            </TierSelect>
          </div>
        </PageHeader>
        <PageContent>
          <ProviderDefaultCompensationTable tier={tier} />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

const TierSelect = styled(Select)`
  & > div {
    padding-top: 0px;
  }
`
