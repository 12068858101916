import { flatten } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { type ProviderEnrollmentInput, ProviderEnrollmentStatus } from '@nuna/api'
import {
  PayerListItem,
  PayerSearch,
  ProviderSearch,
  SearchPayer,
  SearchProvider,
  useProviderEnrollmentsAndStates,
} from '@nuna/common'
import { toast } from '@nuna/tunic'

import {
  AggregateListComponentType,
  ListBox,
  ListBoxProps,
  ListComponentType,
  ListItemProps,
  SearchComponentType,
} from '../ListBox/ListBox'
import { SelectedEnrollmentsTree } from './SelectedEnrollmentsTree'
import { ChildViewProps, SelectedPayer } from './types'

export function AddProviderEnrollments({ onSave, isSaving, viewScopeId }: ChildViewProps) {
  const [providers, setProviders] = useState<(SearchProvider & ListItemProps)[]>([])
  const [payers, setPayers] = useState<(SearchPayer & ListItemProps)[]>([])
  const [selectedProviders, setSelectedProviders] = useState<(SearchProvider & ListItemProps)[]>([])
  const [selectedPayers, setSelectedPayers] = useState<(SelectedPayer & ListItemProps)[]>([])

  const { providerMissingCredentialedState, providerHasPayerEnrollment, resetMap } =
    useProviderEnrollmentsAndStates(selectedProviders)

  const handleSelectedPayers = (_: SearchPayer, selectedPayers: SearchPayer[]) => {
    setSelectedPayers(
      selectedPayers.map(selectedPayer => ({
        ...selectedPayer,
        enrollmentStatus: selectedPayer.enrollmentStatus || ProviderEnrollmentStatus.Pending,
      })),
    )
  }

  const handleRemovePayers = (_: SearchPayer, selectedPayers: SearchPayer[]) => {
    setSelectedPayers(selectedPayers)
  }

  const removePayer = useCallback((payerId: string) => {
    setSelectedPayers(prev => prev.filter(payer => payer.id !== payerId))
  }, [])

  const convertToEnrollmentBulkPayload = (
    providers: SearchProvider[],
    payers: SearchPayer[],
  ): ProviderEnrollmentInput[] => {
    const payload = providers.map(provider => {
      return payers.map(payer => {
        return {
          active: true,
          disableBilling: false,
          providerId: provider.id,
          insurancePayerId: payer.id,
          payerStatus: payer.enrollmentStatus || ProviderEnrollmentStatus.Pending,
        }
      })
    })

    return flatten(payload)
  }

  const handleSave = () => {
    onSave(convertToEnrollmentBulkPayload(selectedProviders, selectedPayers))
    resetMap()
  }

  const handleSearchProvidersChange = (changeValue: string | SearchProvider[]) => {
    if (typeof changeValue === 'string') return
    if (changeValue) {
      setProviders(changeValue as SearchProvider[])
      setSelectedProviders(changeValue as SearchProvider[])
    }
  }

  const handleSearchPayersChange = (changeValue: string | never[]) => {
    if (typeof changeValue === 'string') return
    if (changeValue) {
      setPayers(changeValue as SearchPayer[])
    }
  }

  const handleSelectedPayerOptionsChange = (payer: SearchPayer) => {
    const payers = selectedPayers.filter(selectedPayer => selectedPayer.id !== payer.id)
    if (payer) {
      setSelectedPayers([...payers, payer])
    }
  }

  const listBoxProps: ListBoxProps<SearchProvider, SearchPayer> = {
    columns: {
      primary: {
        title: 'Provider',
        wantsSearch: true,
        listProps: {
          props: {
            items: providers,
            selectedItems: selectedProviders,
            isLoading: false,
            canSelectMultiple: false,
          },
        },
        searchProps: {
          Component: ProviderSearch as unknown as SearchComponentType,
          props: {
            label: 'Search Providers',
            limit: 25,
            entityId: viewScopeId,
            onChange: handleSearchProvidersChange,
          },
        },
      },
      secondary: {
        title: 'Select Payers',
        wantsSearch: true,
        listProps: {
          Component: PayerListItem as unknown as ListComponentType<SearchPayer>,
          props: {
            items: payers,
            selectedItems: selectedPayers,
            isLoading: false,
            canSelectMultiple: true,
            onSelect: handleSelectedPayers,
            onDeselect: handleRemovePayers,
            onClick: handleSelectedPayerOptionsChange,
          },
        },
        searchProps: {
          Component: PayerSearch as unknown as SearchComponentType,
          props: {
            label: 'Search Payers',
            loadInitial: true,
            limit: 25,
            onChange: handleSearchPayersChange,
          },
        },
      },
      aggregate: {
        title: 'Proposed Enrollments',
        listProps: {
          Component: SelectedEnrollmentsTree as unknown as AggregateListComponentType<SearchProvider, SelectedPayer>,
          props: {
            primaryData: selectedProviders,
            secondaryData: selectedPayers,
          },
        },
      },
    },
    actions: {
      primary: {
        actionTitle: 'Save',
        onAction: handleSave,
        isDisabled: isSaving,
      },
    },
  }

  useEffect(() => {
    selectedPayers.forEach(payer => {
      const alreadyEnrolledProviderId = providerHasPayerEnrollment(payer.id)
      if (alreadyEnrolledProviderId) {
        removePayer(payer.id)
        toast.urgent(`Provider is already enrolled with this payer`)
      }
    })
  }, [selectedProviders, selectedPayers, providerMissingCredentialedState, providerHasPayerEnrollment, removePayer])

  return <ListBox<SearchProvider, SearchPayer> {...listBoxProps} />
}
