import { IconFileDocument, NavMenuButton, Tooltip } from '@nuna/tunic'

type Props = {
  tooltip?: string
  handleClick: () => void
}
export const DocumentsButton = ({ tooltip = 'View Documents', handleClick }: Props) => {
  return (
    <Tooltip content={tooltip}>
      <NavMenuButton>
        <IconFileDocument onClick={handleClick} />
      </NavMenuButton>
    </Tooltip>
  )
}
