import { Autocomplete, AutocompleteProps, InputProps, TextField } from '@mui/material'
import { isString, noop } from 'lodash'
import { ReactNode, useEffect, useMemo } from 'react'

import { SelectCustomerFragment, useSelectCustomersQuery } from '@nuna/api'

interface Props {
  label?: string
  value?: SelectCustomerFragment | string | null
  error?: boolean
  helperText?: string | ReactNode
  onLoaded?: () => void
  onChange: (customer: SelectCustomerFragment | null) => void
  onBlur?: InputProps['onBlur']
}

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<SelectCustomerFragment, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

// note that this select doesn't use server side searching
export function CustomerSelect({
  value,
  onChange,
  label = 'Company',
  onLoaded = noop,
  helperText,
  error = false,
  onBlur,
  ...props
}: Props & LimitedAutocompleteProps) {
  const { data: customersData, loading } = useSelectCustomersQuery({ fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    if (!loading) {
      onLoaded()
    }
  }, [loading, onLoaded])

  const customers = useMemo(() => customersData?.companies ?? [], [customersData])

  const fullValue = useMemo(() => {
    if (isString(value)) {
      return customers.find(customer => customer.id === value) ?? null
    }

    return value
  }, [customers, value])

  return (
    <Autocomplete
      disabled={customers.length === 0}
      loading={loading}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={company => company?.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={customers}
      value={fullValue}
      renderInput={params => (
        <TextField
          name="selectedCompany"
          error={error}
          label={label}
          helperText={helperText}
          onBlur={onBlur}
          {...params}
        />
      )}
      {...props}
    />
  )
}
