import { styled } from '@mui/material'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { isArray } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { GhostButton } from '@nuna/tunic'

import { Filter, FilterValueOption } from '../../../filters.types'

interface FilterValueSelectorProps {
  filter: Filter
  onChange: (value: unknown) => void
}

export function FilterAutocompleteValueSelector({ filter, onChange }: FilterValueSelectorProps) {
  const { value, getOptions = async () => [], async = false, multi = false, label: placeholder = 'Filter' } = filter
  const [internalMultiValue, setInternalMultiValue] = useState<string[]>(() => {
    return isArray(value) ? (value as string[]) : []
  })
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState<FilterValueOption[]>([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    getOptions(undefined).then(options => {
      setOptions(options)
      setLoading(false)
    })
  }, [getOptions, value, async])

  useEffect(() => {
    if (!async) return
    let active = true
    async function fetchOptions() {
      if (active) {
        setLoading(true)
        const options = await getOptions(searchValue)
        setOptions(options ?? [])
        setLoading(false)
      }
    }

    fetchOptions()

    return () => {
      active = false
    }
  }, [getOptions, searchValue, async])

  const valueAsOption = useMemo(() => options.find(option => option.value === value), [options, value])
  const multiValuesAsOptions = useMemo(
    () => options.filter(option => internalMultiValue.includes(option.value as string)),
    [internalMultiValue, options],
  )

  const filterOptions = createFilterOptions<FilterValueOption | FilterValueOption[]>({
    matchFrom: 'start',
    stringify: option => (option as FilterValueOption).label,
  })

  return (
    <Container>
      <Autocomplete
        multiple={multi}
        loading={loading}
        style={{ minWidth: 260 }}
        options={options}
        filterOptions={async ? undefined : filterOptions}
        isOptionEqualToValue={(option, value) =>
          (option as FilterValueOption).value === (value as FilterValueOption).value
        }
        getOptionLabel={option => (option as FilterValueOption).label}
        onChange={(_e, val) =>
          isArray(val)
            ? setInternalMultiValue((val as FilterValueOption[]).map(v => v.value) as string[])
            : onChange(val?.value ?? null)
        }
        renderInput={params => (
          <TextField
            autoFocus
            placeholder={placeholder}
            {...params}
            onChange={e => setSearchValue(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.key === 'Tab' && multi) {
                e.stopPropagation()
              }
            }}
          />
        )}
        value={multi ? multiValuesAsOptions : valueAsOption}
      />
      {multi && (
        <div className="v-align pt-2">
          <GhostButton className="ml-auto" onClick={() => onChange(internalMultiValue)}>
            Ok
          </GhostButton>
        </div>
      )}
    </Container>
  )
}

const Container = styled('div')`
  min-width: 260px;
  max-width: 400px;
`
