import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { useCaqhCredentialsQuery } from '@nuna/api'
import {
  Card,
  CopyButton,
  IconButton,
  IconPencil,
  IconPreview,
  IconPreviewOff,
  Skeleton,
  interactiveFill,
  makeTypographyComponent,
} from '@nuna/tunic'

import { CAQHCredentialsForm, CAQHFormValues } from './components/CAQHCredentialsForm'

type CAQHCredentials = Omit<CAQHFormValues, 'providerId'>

interface Props {
  providerId: string
}

export function CAQHCredentials({ providerId }: Props) {
  const [formOpen, setFormOpen] = useState(false)
  const [caqhCredentials, setCaqhCredentials] = useState<CAQHCredentials | undefined>()
  const { data, error } = useCaqhCredentialsQuery({
    variables: { providerId },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data?.providerCaqh || error?.message === 'Not found') {
      setCaqhCredentials({
        caqhUsername: data?.providerCaqh?.caqhUsername,
        caqhPassword: data?.providerCaqh?.caqhPassword,
        caqhId: data?.providerCaqh?.caqhId,
        id: data?.providerCaqh?.id,
      })
    }
  }, [data, error])

  useEffect(() => {
    if (caqhCredentials && !Object.values(caqhCredentials).some(v => v)) {
      setFormOpen(true)
    }
  }, [caqhCredentials])

  const handleAfterSave = async (result: CAQHFormValues) => {
    setCaqhCredentials(result)
    setFormOpen(false)
  }

  if (!caqhCredentials) {
    return <Skeleton height={100} width={440} dimensionUnits="px" />
  }

  if (formOpen) {
    return (
      <CAQHCredentialsForm
        initialFormValues={{
          providerId,
          ...caqhCredentials,
        }}
        afterSave={handleAfterSave}
        onCancel={() => setFormOpen(false)}
      />
    )
  }

  const { caqhId, caqhPassword, caqhUsername } = caqhCredentials

  return (
    <StyledCard className="p-2" style={{ width: 440, position: 'relative' }}>
      <IconButton tooltip="Edit" style={{ position: 'absolute', right: 2, top: 2 }} onClick={() => setFormOpen(true)}>
        <IconPencil />
      </IconButton>
      {caqhId && (
        <DetailSection>
          <DataLabel>CAQH ID</DataLabel>
          <CopyButton value={caqhId} />
        </DetailSection>
      )}
      {caqhUsername && (
        <DetailSection>
          <DataLabel>Username</DataLabel>
          <CopyButton value={caqhUsername} />
        </DetailSection>
      )}
      {caqhPassword && (
        <DetailSection>
          <DataLabel>Password</DataLabel>
          <MaskedPassword password={caqhPassword} />
        </DetailSection>
      )}
    </StyledCard>
  )
}

const DetailSection = styled('section')``

const StyledCard = styled(Card)`
  ${DetailSection} {
    &:not(:last-child) {
      margin-bottom: var(--spacing-2);
    }
  }
`

const DataLabel = makeTypographyComponent('body caption text-medium text-medium-grey mb-xs', 'div')

interface MaskedPasswordProps {
  password: string
}
function MaskedPassword({ password }: MaskedPasswordProps) {
  const [masked, setMasked] = useState(true)
  const length = password.length
  const display = masked ? ''.padStart(length, '•') : password
  const Icon = masked ? IconPreview : IconPreviewOff
  return (
    <MaskedPasswordWrapper className="v-align">
      {masked && display}
      {!masked && <CopyButton value={password} />}
      <Icon className="ml-3 mask-icon" onClick={() => setMasked(!masked)} />
    </MaskedPasswordWrapper>
  )
}

const MaskedPasswordWrapper = styled('div')`
  .mask-icon {
    cursor: pointer;
    &:hover {
      color: ${interactiveFill};
    }
  }
`
