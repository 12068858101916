import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { ReactNode, useMemo, useState } from 'react'

import { OrderBy, PayerRosterDefinition, usePayerRosterDefinitionsQuery } from '@nuna/api'

export type PayerRosterOption = Pick<PayerRosterDefinition, 'id' | 'name'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<PayerRosterOption | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface Props extends LimitedAutocompleteProps {
  value?: string | null
  onChange: (state: PayerRosterOption | undefined | null) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
  states?: string[]
}

export function PayerRosterSelect({
  value: valueInput,
  label = 'Roster',
  error,
  helperText,
  placeholder,
  onChange,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const { data } = usePayerRosterDefinitionsQuery({
    variables: { filters: {}, order: [{ key: 'name', direction: OrderBy.Asc }], pagination: { limit: 1000, page: 1 } },
  })

  const options: PayerRosterOption[] = useMemo(() => data?.payerRosterDefinitions.items ?? [], [data])

  const value = useMemo<PayerRosterOption | null>(
    () => options.find(option => option.id === valueInput) ?? null,
    [valueInput, options],
  )

  const filteredOptions = useMemo<PayerRosterOption[]>(() => {
    if (!searchTerm) {
      return options
    }
    const searcher = new FuzzySearch(options, ['name'], {
      caseSensitive: false,
    })

    return searcher.search(searchTerm)
  }, [searchTerm, options])

  return (
    <Autocomplete
      {...props}
      loading={!data}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={option => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      options={filteredOptions}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          data-testid="state-autocomplete"
          name="selectdSpecialty"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  )
}
