import { HTMLAttributes } from 'react'

import { ProviderCredentialingStatus } from '@nuna/api'
import { providerService } from '@nuna/core'
import { Intent, StatusLabel } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  status?: ProviderCredentialingStatus | null
}

export function CredentialingStatusLabel({ status, ...props }: Props) {
  if (!status) return null

  const getIntent = (): Intent => {
    switch (status) {
      case ProviderCredentialingStatus.Credentialed:
        return 'information'
      case ProviderCredentialingStatus.CredentialingPacketConcern:
      case ProviderCredentialingStatus.MissingInformation:
        return 'caution'
      case ProviderCredentialingStatus.Denied:
      case ProviderCredentialingStatus.Archived:
        return 'urgent'
      default:
        return 'default'
    }
  }

  return (
    <StatusLabel intent={getIntent()} {...props}>
      {providerService.readableCredentialingStatus(status)}
    </StatusLabel>
  )
}
