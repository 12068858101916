import { styled } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  DocumentType,
  OrderBy,
  PayerRosterArtifactStatus,
  PayerRosterArtifactsQuery,
  usePayerRosterArtifactQuery,
  usePayerRosterArtifactsQuery,
  useUpdatePayerRosterArtifactStatusMutation,
} from '@nuna/api'
import { useDownloadDocument } from '@nuna/common'
import { errorService } from '@nuna/core'
import { Paginator, usePagination } from '@nuna/data-table'
import {
  Card,
  DropdownButton,
  FileTypeIcon,
  List,
  Menu,
  MenuItem,
  Skeleton,
  StatusLabel,
  Tooltip,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { DeleteRosterButton } from './DeleteRosterButton'

const SORT = [{ key: 'createdAt', direction: OrderBy.Desc }]
const ITEM_LIMIT = 10

export function PreviousPayerRosters() {
  const { id } = useParams()
  const filters = useMemo(() => ({ payerRosterDefinitionId: id, mostRecent: true }), [id])
  const { queryOptions, getPaginatorProps, setPagination, setLoading } = usePagination(
    {
      loading: true,
      initialSort: SORT,
      filters,
    },
    { limit: ITEM_LIMIT },
  )
  const { data: mostRecent } = usePayerRosterArtifactQuery({
    variables: { filters },
  })
  const mostRecentArtifactId = mostRecent?.payerRosterArtifact?.id

  const { data, loading } = usePayerRosterArtifactsQuery({
    variables: {
      filters: queryOptions.filters,
      pagination: queryOptions.paginationInput,
      order: queryOptions.sortInput,
    },
  })

  useEffect(
    () => setPagination(data?.payerRosterArtifacts.pagination),
    [data?.payerRosterArtifacts.pagination, setPagination],
  )

  useEffect(() => setLoading(loading), [loading, setLoading])

  const artifacts = data?.payerRosterArtifacts.items ?? []

  if (!loading && artifacts.length === 0) {
    return <div>No previous rosters</div>
  }

  return (
    <div>
      <List className="flex-column gap-1 pb-2">
        {loading && <Skeleton />}{' '}
        {!loading &&
          artifacts.map(artifact => (
            <ArtifactListItem
              key={artifact.id}
              id={artifact.id}
              mostRecentArtifactId={mostRecentArtifactId}
              artifact={artifact}
            />
          ))}
      </List>
      <Paginator {...getPaginatorProps()} />
    </div>
  )
}

interface ArtifactListItemProps {
  id: string
  artifact: PayerRosterArtifactsQuery['payerRosterArtifacts']['items'][0]
  mostRecentArtifactId: string | undefined
}

function ArtifactListItem({ mostRecentArtifactId, artifact }: ArtifactListItemProps) {
  const [updatePayerRosterStatus, { loading: payerRosterStatusLoading }] = useUpdatePayerRosterArtifactStatusMutation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [downloadDocument] = useDownloadDocument()
  const document = artifact?.payerRosterArtifactDocuments.find(
    doc => doc.document.documentType === DocumentType.PayerTavaRoster,
  )?.document

  const handlePayerRosterStatusUpdate = async (payerRosterArtifactId: string) => {
    try {
      await updatePayerRosterStatus({
        variables: {
          payerRosterArtifactId,
          status: PayerRosterArtifactStatus.Submitted,
        },
        refetchQueries: ['PayerRosterArtifacts', 'PayerRosterArtifact'],
        awaitRefetchQueries: true,
      })

      toast.success('Payer Roster status updated successfully')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to update Payer Roster status'))
    }
  }

  if (!artifact || !document) return null

  return (
    <Card
      className="flex-column p-1"
      depth={1}
      intent="default"
      shadowColor="warm"
      style={{
        width: '300px',
      }}
    >
      <div className="v-align pb-1">
        <a
          href={document.downloadUrl ?? ''}
          onClick={e => {
            e.preventDefault()
            downloadDocument(document.id)
          }}
          className="v-align"
        >
          <FileTypeIcon mimeType={document.mimeType} className="mr-xs" />
          {document.fileName}
        </a>

        <span className="ml-auto">
          {mostRecentArtifactId === artifact.id && (
            <DeleteRosterButton rosterArtifactId={artifact.id} disabled={payerRosterStatusLoading} />
          )}
        </span>
      </div>

      <StyledElementActions>
        {moment(artifact.createdAt).format('lll')}

        <div>
          {artifact.status === PayerRosterArtifactStatus.Pending ? (
            <Tooltip content="When changing to submitted, enrollments tied to this roster will be updated from pending to submitted">
              <DropdownButton
                onClick={e => setAnchorEl(e.currentTarget)}
                isActive={!!anchorEl}
                disabled={payerRosterStatusLoading}
              >
                {artifact.status}
              </DropdownButton>
            </Tooltip>
          ) : (
            <StatusLabel intent="default" scheme="light">
              {artifact.status}
            </StatusLabel>
          )}

          {artifact.status === PayerRosterArtifactStatus.Pending && (
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={{ style: { padding: '8px' } }}
            >
              <MenuItem to="#" onClick={() => handlePayerRosterStatusUpdate(artifact.id)} style={{ padding: '8px' }}>
                Submitted
              </MenuItem>
            </Menu>
          )}
        </div>
      </StyledElementActions>
    </Card>
  )
}

const StyledElementActions = styled(makeTypographyComponent('text-secondary caption italic', 'div'))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-left: 8px;
  width: 280px;
`
