import { styled } from '@mui/material'
import { Autocomplete, TextField as MUITextField } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'

import {
  EnrollmentPacketItem,
  useGenerateEnrollmentPacketMutation,
  useGetEnrollmentPacketsQuery,
  useProviderPreferencesQuery,
  useSaveProviderPreferencesMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService } from '@nuna/core'
import { FillButton, Switch, toast } from '@nuna/tunic'

type Packet = Pick<EnrollmentPacketItem, 'id' | 'name'>

interface Props {
  providerId: string
}
export function EnrollmentPacketsGenerator({ providerId }: Props) {
  const isAdmin = useIsAdmin()
  const [saveProviderPreferences, { loading: preferencesSaving }] = useSaveProviderPreferencesMutation()
  const { data: preferencesData, loading: preferencesLoading } = useProviderPreferencesQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
  })
  const [selectedPacket, setSelectedPacket] = useState<Packet | null>(null)
  const [generatePacket] = useGenerateEnrollmentPacketMutation()
  const { data, loading } = useGetEnrollmentPacketsQuery()

  const options = useMemo(() => data?.enrollmentPackets ?? [], [data])

  const generatedPdf = async () => {
    const packetId = selectedPacket?.id
    try {
      const result = await generatePacket({ variables: { providerId, packetId } })
      window.open(result.data?.generateEnrollmentPacket?.continueUrl, '_blank')
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a problem. Try again.'))
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!isAdmin) return
    if (!providerId) {
      console.error('Provider ID was missing when updating provider preferences')
      toast.urgent(`We encountered an unexpected error while updating provider preferences.`)
      return
    }

    try {
      await saveProviderPreferences({
        variables: {
          providerId,
          preferences: {
            insuranceOptOut: e.target.checked,
          },
        },
        optimisticResponse: {
          updateProvider: {
            __typename: 'Provider',
            id: providerId,
            preferences: {
              ...preferencesData?.provider.preferences,
              insuranceOptOut: e.target.checked,
            },
          },
        },
      })

      toast.success(`Provider has been ${e.target.checked ? 'opted out of' : 'opted in to'} insurance auto enrollments`)
    } catch (error) {
      console.error(error)
      toast.urgent('There was an error updating provider preferences. Please try again.')
    }
  }

  return (
    <Container>
      <div className="v-align">
        <Switch
          onChange={handleChange}
          checked={preferencesData?.provider?.preferences?.insuranceOptOut ?? false}
          disabled={preferencesSaving || preferencesLoading}
        >
          Disable Auto Enrollments
        </Switch>
      </div>
      <div className="v-align">
        <Autocomplete
          className="packet-select"
          options={options}
          getOptionLabel={packet => packet.name}
          loading={loading}
          onChange={(_event, packet) => {
            setSelectedPacket(packet)
          }}
          renderInput={params => <MUITextField {...params} label="Enrollment Packet" />}
          value={selectedPacket}
        />
        <FillButton className="ml-2" disabled={!selectedPacket} onClick={generatedPdf}>
          Generate
        </FillButton>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-content: center;
  .packet-select {
    width: 260px;
  }
`
