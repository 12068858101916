import { sum } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import {
  ExternalEmploymentStatus,
  OrderBy,
  ProviderPayrollSummaryFragment,
  ProviderPayrollSummaryItemFragment,
  TraversablePaginationSortInput,
  useCreatePayrollMutation,
  usePayrollSummaryLazyQuery,
} from '@nuna/api'
import { UserLink } from '@nuna/common'
import { type ProviderProfileUser, errorService, numberService } from '@nuna/core'
import { DataTable, DataTableColumn, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { FeatureFlagCheck } from '@nuna/feature-flag'
import {
  Confirm,
  IconCheckCircle,
  IconError,
  OutlineButton,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  interactiveFill,
  salmonSet,
  toast,
} from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import {
  DEFAULT_PROVIDER_TABLE_FILTER_VALUES,
  ProviderFilters,
  ProvidersFilterValues,
  buildProviderFilterInput,
} from '../../shared/ProviderListNavigation/ProviderFilters'
import { PayPeriodPaginator } from './PayPeriodPaginator'
import { ProviderPayrollProviderDetailRow } from './ProviderPayrollProviderDetailRow'

type PayrollFilterValues = ProvidersFilterValues & {
  range: { fromDate: moment.Moment; toDate: moment.Moment }
}

const columns: DataTableColumn<ProviderPayrollSummaryFragment>[] = [
  {
    Header: 'Provider',
    accessor: 'providerId',
    Cell: ({ value, row }) => (
      <UserLink
        user={
          {
            id: value,
            firstName: row.original.providerFirstName,
            lastName: row.original.providerLastName,
            __typename: 'Provider',
          } as ProviderProfileUser
        }
        showAvatar={false}
        openInNewTab
      />
    ),
  },
  {
    Header: 'Payable',
    accessor: 'providerEmploymentStatus',
    className: 'right-align',
    Cell: ({ value }) =>
      value === ExternalEmploymentStatus.Completed ? (
        <IconCheckCircle size={18} color={interactiveFill} />
      ) : (
        <IconError size={18} color={salmonSet[80].hex} />
      ),
  },
  {
    Header: 'Incomplete Sessions',
    accessor: 'summaryItems',
    className: 'right-align',
    Cell: ({ value }) => <>{incompleteSummaryRollup(value, 'count')}</>,
  },
  {
    Header: 'Incomplete Sessions Amount Due',
    accessor: 'providerFirstName',
    className: 'right-align',
    Cell: ({ row }) => (
      <>
        {numberService.centsToFormattedDollars(incompleteSummaryRollup(row.original.summaryItems, 'total'), {
          maximumFractionDigits: 2,
        })}
      </>
    ),
  },
  {
    Header: 'Total Sessions',
    accessor: 'count',
    className: 'right-align',
  },
  {
    Header: 'Total Sessions Amount Due',
    accessor: 'total',
    className: 'right-align',
    Cell: ({ value }) => <>{numberService.centsToFormattedDollars(value, { maximumFractionDigits: 2 })}</>,
  },
]

const INITIAL_SORT: TraversablePaginationSortInput[] = [
  { key: 'lastName', direction: OrderBy.Asc },
  { key: 'firstName', direction: OrderBy.Asc },
]

const INITIAL_FILTER_VALUES: PayrollFilterValues = {
  ...DEFAULT_PROVIDER_TABLE_FILTER_VALUES,
  range: getPayPeriod(moment()),
}

export function ProviderPayroll() {
  const [showConfirm, setShowConfirm] = useState(false)
  const { filterValues, setFilterVal, clearFilters } = useDataTableFiltering(INITIAL_FILTER_VALUES)

  const [queryPayrollProviders, { data: payrollData, loading: payrollDataLoading, refetch }] =
    usePayrollSummaryLazyQuery()
  const [createPayroll, { loading: createPayrollLoading }] = useCreatePayrollMutation()

  const totalLabel = (payrollData?.payrollSummary.items ?? []).length === 1 ? 'total therapist' : 'total therapists'

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination(
    {
      pagination: payrollData?.payrollSummary.pagination,
      loading: payrollDataLoading,
      initialSort: INITIAL_SORT,
      filters: filterValues,
    },
    { totalLabel },
  )

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    const range = filters?.range
    const fromDate = range?.fromDate ?? moment()
    const toDate = range?.toDate ?? moment()
    queryPayrollProviders({
      variables: {
        startDate: fromDate.format(),
        endDate: toDate.format(),
        providerFilters: buildProviderFilterInput(filters),
        order: sortInput,
        pagination: paginationInput,
      },
    })
  }, [queryPayrollProviders, queryOptions])

  const rowData = useMemo(() => payrollData?.payrollSummary.items, [payrollData])

  async function handleCreatePayroll(shouldCreatePayroll: boolean) {
    if (shouldCreatePayroll) {
      try {
        const result = await createPayroll({
          variables: {
            startDate: filterValues.range.fromDate.format('YYYY-MM-DD'),
            endDate: filterValues.range.toDate.format('YYYY-MM-DD'),
          },
        })
        setShowConfirm(false)
        toast.success(`Payroll(s) created`, {
          duration: 30000,
          action: { buttonText: 'Go To Payroll', onClick: () => window.open(result.data?.createPayroll[0], '_blank') },
        })
      } catch (error) {
        toast.urgent(errorService.transformGraphQlError(error, 'There was a problem creating the payroll'))
      }
    }
    setShowConfirm(false)
  }

  return (
    <NavLayout>
      <PageWrapper>
        <PageHeader border={false} withBottomMargin={true}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PageHeading className="mb-0" withDivider>
              Provider Payouts
            </PageHeading>
            <PayPeriodPaginator
              className="ml-2"
              toDate={filterValues.range.toDate}
              fromDate={filterValues.range.fromDate}
              onNextPeriodClick={() => {
                setFilterVal('range', getPayPeriod(filterValues.range.toDate.clone().add(1, 'day')))
              }}
              onPreviousPeriodClick={() => {
                setFilterVal('range', getPayPeriod(filterValues.range.fromDate.clone().subtract(2, 'days')))
              }}
            />
            <ProviderFilters
              className="ml-2"
              filtersToInclude={[
                'active',
                'activeCredentialsIn',
                'statuses',
                'credentialStates',
                'gender',
                'specialties',
                'insurancePayerPlanId',
              ]}
              filterValues={filterValues}
              setFilterVal={setFilterVal}
              clearFilters={clearFilters}
            />
          </div>
          <FeatureFlagCheck flags={['adminFinancials']} featureType="component">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OutlineButton onClick={() => setShowConfirm(true)} isLoading={createPayrollLoading}>
                Create Payroll
              </OutlineButton>
              <Confirm onConfirm={handleCreatePayroll} isOpen={showConfirm}>
                Are you sure you want to create a payroll in the payroll system for{' '}
                {filterValues.range.fromDate.format('MMM Do')} to {filterValues.range.toDate.format('MMM Do')}?
              </Confirm>
            </div>
          </FeatureFlagCheck>
        </PageHeader>
        <PageContent $paddingTop={false}>
          <DataTable
            loading={payrollDataLoading}
            columns={columns}
            rowData={rowData}
            expandedRow={({ row }) => (
              <ProviderPayrollProviderDetailRow
                startDate={filterValues.range.fromDate}
                endDate={filterValues.range.toDate}
                onChange={() => refetch()}
                {...row}
              />
            )}
            paginated
            paginatorProps={getPaginatorProps()}
            onSort={handleSort}
            initialState={initialTableState}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}

function getPayPeriod(anchor: moment.Moment) {
  const dayOfMonth = anchor.toDate().getDate()

  if (dayOfMonth >= 15) {
    return {
      fromDate: anchor.clone().startOf('month').add(15, 'days').startOf('day'),
      toDate: anchor.clone().endOf('month').endOf('day'),
    }
  } else {
    return {
      fromDate: anchor.clone().startOf('month').startOf('day'),
      toDate: anchor.clone().startOf('month').add(14, 'days').endOf('day'),
    }
  }
}

function incompleteSummaryRollup(
  summaryItems: ProviderPayrollSummaryItemFragment[],
  key: keyof Pick<ProviderPayrollSummaryItemFragment, 'count' | 'total'>,
) {
  const incompleteSessions = summaryItems.filter(item => item.reason !== 'SESSION_COMPLETED')
  return sum(incompleteSessions.map(item => item[key]))
}
