import { HTMLAttributes, ReactNode, useMemo } from 'react'

import {
  AddressType,
  InsuranceTaxEntitiesQuery,
  ProviderAddressesQuery,
  useInsuranceTaxEntitiesQuery,
  useProviderAddressesQuery,
} from '@nuna/api'
import { addressService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { IconFileDocument } from '@nuna/tunic'

import { getW9Link } from '../../../../../util/w9'

interface ProviderW9DocumentsProps extends HTMLAttributes<HTMLDivElement> {
  providerId: string
  header?: ReactNode
  includeIds?: boolean
}

interface Row {
  addressName: string
  address: string
  taxEntityName: string
  w9DocumentUrl: string
  addressId: string
  taxEntityId: string
}

const COLUMNS: DataTableColumn<Row>[] = [
  { accessor: 'addressName', Header: 'Location Name' },
  { accessor: 'address', Header: 'Address' },
  { accessor: 'taxEntityName', Header: 'Tax Entity Name' },
  {
    accessor: 'w9DocumentUrl',
    Header: 'W9 Document',
    Cell: ({ value }) => (
      <a href={value} className="v-align">
        <IconFileDocument className="mr-xs" /> Download
      </a>
    ),
  },
]
export function ProviderW9Documents({
  providerId,
  includeIds = false,
  header = <h2 className="h4">W9 Forms</h2>,
  ...props
}: ProviderW9DocumentsProps) {
  const { data: taxEntityData, loading: taxEntityDataLoading } = useInsuranceTaxEntitiesQuery()
  const { data: addressData, loading: addressDataLoading } = useProviderAddressesQuery({
    variables: { searchOptions: { providerId, providerAddressType: AddressType.ProviderPractice } },
  })

  const columns = useMemo<DataTableColumn<Row>[]>(() => {
    if (includeIds) {
      return [
        { accessor: 'addressId', Header: 'Address ID' },
        { accessor: 'taxEntityId', Header: 'Tax Entity ID' },
        ...COLUMNS,
      ]
    } else return COLUMNS
  }, [includeIds])

  const rowData = useMemo(() => buildRows(taxEntityData, addressData), [taxEntityData, addressData])

  return (
    <div {...props}>
      {header}
      <DataTable loading={taxEntityDataLoading || addressDataLoading} columns={columns} rowData={rowData} />
    </div>
  )
}

function buildRows(
  taxEntityData: InsuranceTaxEntitiesQuery | undefined,
  addressData: ProviderAddressesQuery | undefined,
): Row[] | undefined {
  if (!taxEntityData?.insuranceTaxEntities || !addressData?.providerAddresses) {
    return undefined
  }

  return addressData.providerAddresses.flatMap(providerAddress => {
    return taxEntityData.insuranceTaxEntities.map<Row>(taxEntity => {
      const { address } = providerAddress
      return {
        addressId: address.id,
        taxEntityId: taxEntity.id,
        addressName: address.internalName,
        address: addressService.formatAddress(address),
        taxEntityName: taxEntity.organizationName,
        w9DocumentUrl: getW9Link({ addressId: address.id, insuranceTaxEntityId: taxEntity.id }),
      }
    })
  })
}
