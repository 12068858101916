import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { capitalize, isNil, startCase } from 'lodash'
import { useMemo } from 'react'

import {
  ProviderEnrollmentStatus,
  useBulkSaveProviderEnrollmentsMutation,
  useProviderEnrollmentsQuery,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import {
  Drawer,
  DrawerProps,
  FillButton,
  IconInfo,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Tooltip,
  eggshell,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { useGetAvailableEnrollmentStatuses } from '../hooks/useGetAvailableEnrollmentStatuses'

interface FormValues {
  payerStatus?: ProviderEnrollmentStatus
  disableBilling?: 0 | 1
  ignoreEnrollment?: 0 | 1
}

interface Row {
  id: string
  taskId?: string
}

interface Props extends DrawerProps {
  getSelectedRows: () => Row[]
  onSaved: () => void
}

const INITIAL_VALUES: FormValues = { disableBilling: undefined, ignoreEnrollment: undefined }
const TOGGLE_OPTIONS = [
  { value: undefined, label: 'No change' },
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' },
]

export function UpdateEnrollmentsDrawer({ getSelectedRows, onSaved, ...props }: Props) {
  const selectedRows = useMemo(() => {
    if (!props.isOpen) return []
    return getSelectedRows()
  }, [props.isOpen, getSelectedRows])

  const [saveEnrollments, { loading: saveLoading }] = useBulkSaveProviderEnrollmentsMutation({
    refetchQueries: ['SearchProviderEnrollmentsQuery'],
  })
  const { data } = useProviderEnrollmentsQuery({
    variables: { providerEnrollementSearchOptions: { ids: selectedRows.map(row => row.id) } },
    skip: !props.isOpen,
  })

  const handleSubmit = async ({ payerStatus, ignoreEnrollment, disableBilling }: FormValues) => {
    const mappedIgnoreEnrollment = mapBooleanFieldValue(ignoreEnrollment)
    try {
      await saveEnrollments({
        variables: {
          enrollments: selectedRows.map(row => ({
            id: row.id,
            payerStatus,
            disableBilling: mapBooleanFieldValue(disableBilling),
            active: mappedIgnoreEnrollment !== undefined ? !mappedIgnoreEnrollment : undefined,
          })),
        },
      })
      toast.success('Updated enrollments successfuly')
      onSaved()
      props.onClose()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to update enrollments'))
    }
  }

  const enrollments = data?.providerEnrollments
  const enrollmentsHaveSameStatus = enrollments?.every(
    enrollment => enrollment.payerStatus === enrollments[0].payerStatus,
  )

  const availableStatuses = useGetAvailableEnrollmentStatuses(
    enrollmentsHaveSameStatus ? enrollments?.[0].payerStatus : null,
  )

  return (
    <StyledDrawer size="min(400px, 100vw)" PaperProps={{ className: 'py-2' }} {...props}>
      <ContentContainer>
        <Header>Update Enrollments</Header>
        {!enrollments && <Skeleton height={5} />}
        {!!enrollments && (
          <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
            {({ values, setFieldValue, getFieldProps }) => (
              <Form>
                <FormGroup className="v-align gap-3">
                  <Select
                    {...getFieldProps('payerStatus')}
                    value={values.payerStatus ?? ''}
                    label="Payer status"
                    disabled={!enrollmentsHaveSameStatus}
                    className="flex-remaining-space"
                  >
                    <option value=""></option>
                    {availableStatuses.map(status => (
                      <option key={status} value={status}>
                        {capitalize(startCase(status))}
                      </option>
                    ))}
                  </Select>
                  {!enrollmentsHaveSameStatus && (
                    <Tooltip content="The enrollments you selected don't all have the same payer status. You can only change payer status on multiple enrollments if they currently have the same payer status.">
                      <span className="text-secondary ml-auto" style={{ alignSelf: 'flex-end' }}>
                        <IconInfo />
                      </span>
                    </Tooltip>
                  )}
                </FormGroup>
                <FormGroup>
                  <RadioLabel>Ignore enrollment</RadioLabel>
                  <RadioGroup>
                    {TOGGLE_OPTIONS.map(option => (
                      <Radio
                        checked={values.ignoreEnrollment === option.value}
                        value={option.value}
                        onChange={() => setFieldValue('ignoreEnrollment', option.value)}
                      >
                        {option.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </FormGroup>
                <FormGroup>
                  <RadioLabel>Disable billing</RadioLabel>
                  <RadioGroup>
                    {TOGGLE_OPTIONS.map(option => (
                      <Radio
                        checked={values.disableBilling === option.value}
                        value={option.value}
                        onChange={() => setFieldValue('disableBilling', option.value)}
                      >
                        {option.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </FormGroup>
                <FillButton
                  isLoading={saveLoading}
                  disabled={Object.values(values).every(value => isNil(value))}
                  className="full-width mt-3"
                  type="submit"
                >
                  Update Enrollments
                </FillButton>
              </Form>
            )}
          </Formik>
        )}
      </ContentContainer>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`

const ContentContainer = makeTypographyComponent('px-2', 'div')
const Header = makeTypographyComponent('sans-serif text-medium large space-between v-align', 'h1')
const FormGroup = makeTypographyComponent('pb-3', 'div')
const RadioLabel = makeTypographyComponent('text-secondary mb-1 block', 'label')

function mapBooleanFieldValue(value: 0 | 1 | undefined): undefined | boolean {
  if (value === undefined) {
    return value
  }

  return value ? true : false
}
