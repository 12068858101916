import React, { HTMLAttributes, MouseEvent, useState } from 'react'

import {
  AvatarToggleButtonProps,
  IconLeading,
  IconToggleButton,
  IconToggleButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuItemStack,
  ToggleButtonProps,
  csx,
} from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  dropdownButton?: JSX.Element
  dropdownButtonProps?: Partial<IconToggleButtonProps | AvatarToggleButtonProps | ToggleButtonProps>
  menuItems: MenuItemProps[]
}
export function DropdownMenu({
  menuItems,
  dropdownButton = (
    <IconToggleButton tooltip="">
      <IconLeading />
    </IconToggleButton>
  ),
  dropdownButtonProps = {},
  className,
  ...props
}: Props) {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>()
  return (
    <div {...props} className={csx(['v-align', className])}>
      {React.cloneElement(dropdownButton, {
        ...dropdownButtonProps,
        onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(e.currentTarget),
        isActive: !!anchorEl,
      })}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItemStack>
          {menuItems.map((menuItem, idx) => (
            <MenuItem
              key={idx}
              {...menuItem}
              onClick={(e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => {
                menuItem.onClick?.(e as MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>)
                setAnchorEl(undefined)
              }}
            />
          ))}
        </MenuItemStack>
      </Menu>
    </div>
  )
}
