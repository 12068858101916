import { Outlet } from 'react-router-dom'

import { NavLayout } from '../../layouts/NavLayout'

export function AppointmentsIndex() {
  return (
    <NavLayout>
      <Outlet />
    </NavLayout>
  )
}
