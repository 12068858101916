import { startCase } from 'lodash'

import { PatientTransactionStatus, PatientTransactionType } from '../types/internal-only/transaction.types'

function getTransactionType(type: PatientTransactionType) {
  return startCase(type.toLowerCase())
}

function getIntentFromTransactionStatus(status: PatientTransactionStatus) {
  switch (status) {
    case PatientTransactionStatus.ClaimSubmitted:
    case PatientTransactionStatus.Pending:
      return 'default'
    case PatientTransactionStatus.Paid:
      return 'information'
    case PatientTransactionStatus.Failed:
      return 'urgent'
    case PatientTransactionStatus.PaymentDue:
      return 'caution'
    default:
      return 'default'
  }
}

export const transactionService = {
  getTransactionType,
  getIntentFromTransactionStatus,
}
