import { uniq } from 'lodash'
import { useCallback, useState } from 'react'

import { useDocumentLazyQuery } from '@nuna/api'
import { documentService } from '@nuna/core'
import { toast } from '@nuna/tunic'

export function useDownloadDocument() {
  const [container, setContainer] = useState<HTMLElement | null>(document.body)
  const [queryDocument] = useDocumentLazyQuery()

  const setRef = useCallback((node: HTMLElement | null) => {
    setContainer(node)
  }, [])

  const downloadDocument = useCallback(
    async (documentId: string) => {
      // our AWS tokens expire after 5 minutes. So we either need to long poll or do this hack
      try {
        // fetch the document with an updated aws token
        const result = await queryDocument({ variables: { id: documentId, includeDownloadUrl: true } })

        const url = result.data?.document?.downloadUrl
        if (!url) throw Error('No download url')
        const fileName = (result.data?.document?.fileName as string) ?? uniq('file')

        // build a new hidden link and click it
        documentService.downloadDocument({ name: fileName, url, element: container })
      } catch (error) {
        toast.urgent('Failed to download document')
      }
    },
    [queryDocument, container],
  )

  return [downloadDocument, setRef] as const
}
