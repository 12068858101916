import { useInsurancePayerQuery } from '@nuna/api'
import { Skeleton } from '@nuna/tunic'

import { AddToPayerNetwork } from './components/AddToPayerNetwork'
import { PayerNetworkPlans } from './components/PayerNetworkPlans'

interface Props {
  payerId: string
}

export function PayerNetworkIndex({ payerId }: Props) {
  const { data, loading } = useInsurancePayerQuery({ variables: { id: payerId } })
  const network = data?.insurancePayer?.insurancePayerNetwork

  if (loading) {
    return <Skeleton height={4} />
  }

  return (
    <div className="py-2 full-width">
      {!network && <AddToPayerNetwork payerId={payerId} />}
      {network && <PayerNetworkPlans payerId={payerId} network={network} />}
    </div>
  )
}
