import { Route, Routes } from 'react-router-dom'

import { providerService } from '@nuna/core'

import { NavLayout } from '../../layouts/NavLayout'
import { ProviderListContextProvider } from '../../shared/ProviderListNavigation/ProviderListContextProvider'
import { ProviderListNavigation } from '../../shared/ProviderListNavigation/ProviderListNavigation'
import { ProviderDetail } from './ProviderDetail/ProviderDetail'
import { ProvidersIndex } from './ProvidersIndex'

function ProvidersModuleWithProvider() {
  return (
    <NavLayout>
      <Routes>
        <Route index element={<ProvidersIndex />}></Route>
        <Route
          path=":id/*"
          element={
            <ProviderListNavigation subHeading={provider => providerService.readableProviderStatus(provider.status)}>
              <ProviderDetail />
            </ProviderListNavigation>
          }
        />
      </Routes>
    </NavLayout>
  )
}

export function ProvidersModule() {
  return (
    <ProviderListContextProvider>
      <ProvidersModuleWithProvider />
    </ProviderListContextProvider>
  )
}
