import { routeService } from '@nuna/core'

export const clientList = '/clients'

export function clientDetail(id: string) {
  return `${clientList}/${id}`
}

export const providerList = '/providers'

export const newProvider = `/providers/new`

export function providerDetail(id: string) {
  return `${providerList}/${id}`
}

export const appointmentsViews = '/appointments'

export const sessionDetail = (appointmentId: string) => {
  return `${appointmentsViews}/${appointmentId}`
}

export const sessionNotes = (sessionId: string) => {
  return `/session-notes/${sessionId}`
}

export const customerIntegrationDashboard = `/customer/integrations`
export const customerList = `/customers`
export const newCustomer = `${customerList}/new`
export const customerDetail = (id: string, subPath?: string) => {
  return `${customerList}/${id}${subPath ? `/${subPath}` : ''}`
}

export const cptCodeList = '/cpt-codes'
export const diagnosesList = '/diagnoses'
export const taxEntityList = '/tax-entities'

export enum PayerSubRoutes {
  Plans = 'plans',
  Network = 'network',
  Enrollments = 'enrollments',
  Policies = 'policies',
  Configuration = 'configuration',
}

export enum PayerPlanSubRoutes {
  Contracts = 'contracts',
  Providers = 'providers',
  Policies = 'policies',
}

export const payers = '/payers'
export const newPayer = `${payers}/new`
export const editPayer = (id: string) => `${payers}/${id}/edit`
export const payerSubroute = (id: string, subRoute: PayerSubRoutes) => `${routeService.insurancePayer(id)}/${subRoute}`

export const newPlan = (payerId: string) => `${payers}/${payerId}/${PayerSubRoutes.Plans}/new`
export const planSubroute = (planId: string, payerId: string, subroute: PayerPlanSubRoutes) =>
  `${routeService.insurancePayerPlan(planId, payerId)}/${subroute}`
export const planContract = (planId: string, payerId: string, payerContractId: string) =>
  `${planSubroute(planId, payerId, PayerPlanSubRoutes.Contracts)}/${payerContractId}`

export const payroll = '/payroll'
export const providerPayroll = '/provider-payroll'
export const availability = '/availability'
export const memberMatch = '/member-match'
export const eventLog = '/event-log'
export const insuranceProviders = '/insurance-providers'

export const providerCompensation = '/provider-compensation'
export const providerDefaultCompensation = '/provider-default-compensation'
export const providerDefaultCompensationTier = (tier: string) => `${providerDefaultCompensation}/${tier}`

export const adminUsers = '/admin-users'
export const newAdminUser = `${adminUsers}/new`

export const hrAdminUsers = '/hr-admin-users'
export const newHrAdminUser = (customerId?: string) =>
  customerId ? `${customerDetail(customerId)}/new-hr-admin` : `${hrAdminUsers}/new`

export const enrollments = '/enrollments'
export const insuranceRosters = '/insurance-rosters'
export const addresses = '/addresses'
export const tavaAddresses = '/tava-addresses'
export const partnerList = '/partners'
export const partnerDetail = (id: string) => `${partnerList}/${id}`
export const newPartner = `${partnerList}/new`
export const updatePartner = (id: string) => `${partnerList}/${id}/update`
export const partnerAdminList = (partnerId: string) => `${partnerDetail(partnerId)}/admins`
export const partnerAdminDetail = (partnerId: string, adminId: string) => `${partnerAdminList(partnerId)}/${adminId}`
export const partnerAdminUpdate = (partnerId: string, adminId: string) =>
  `${partnerAdminDetail(partnerId, adminId)}/update`
export const newPartnerAdmin = (partnerId: string) => `${partnerAdminList(partnerId)}/new`
export const partnerAdmin = `/EAPs`
export const partnerAdminCustomerDetail = (customerId: string) => `/EAPs${customerDetail(customerId)}`
