import moment from 'moment-timezone'

import {
  EmployeeDataRecordFragment,
  OrganizationRelationship,
  PatientContractRelation,
  UnlinkedPatientFragment,
  UnlinkedPatientInfoFragment,
} from '@nuna/api'

export type RawMemberMatchPerson = EmployeeDataRecordFragment | UnlinkedPatientFragment

export type MatchTypeIfDependent = 'dependent' | 'ineligible' | 'sponsoring employee'

export interface MemberMatchPerson {
  id: string
  firstName: string
  lastName: string
  dob: string
  companyId: string
  companyName: string
  addressLineOne: string
  addressLineTwo?: string
  city: string
  state: string
  zipCode: string
  joined: string
  phone: string
  gender: string
  avatarUrl: string
  association: OrganizationRelationship | null | undefined
  emergencyContactName: string
  emergencyContactRelationship: string
  emergencyContactPhone: string
  loginId?: string
}

export interface UnlinkedPatientRecord {
  patient: MemberMatchPerson
  suggestedMatch?: MemberMatchPerson
  relationshipToOrganization?: OrganizationRelationship | null
  matchTypeIfDependent: MatchTypeIfDependent
  relatedEmployeeName?: string
  strictlyEnforceRoster: boolean
}

export function mapEmployeeRecordToMatchPerson(
  record: EmployeeDataRecordFragment,
  companyName: string,
): MemberMatchPerson {
  const {
    id,
    firstName,
    lastName,
    dob,
    companyId,
    homeAddressLineOne,
    homeAddressLineTwo,
    homeCity,
    homeState,
    homeZipCode,
    phoneNumber,
    gender,
    avatarUrl,
    relationshipToCompany,
  } = record

  return {
    id,
    firstName,
    lastName,
    dob,
    companyId,
    companyName,
    addressLineOne: homeAddressLineOne ?? '—',
    addressLineTwo: homeAddressLineTwo ?? '',
    city: homeCity ?? '—',
    state: homeState ?? '—',
    zipCode: homeZipCode ?? '—',
    joined: '',
    phone: phoneNumber ?? '—',
    gender: gender ?? '—',
    avatarUrl: avatarUrl ?? '',
    association: relationshipToCompany,
    emergencyContactName: '—',
    emergencyContactPhone: '—',
    emergencyContactRelationship: '—',
  }
}

function mapPatientToMatchPerson(record: UnlinkedPatientFragment): MemberMatchPerson {
  const {
    id,
    firstName,
    lastName,
    dob,
    company,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zipCode,
    createdAt,
    gender,
    mobilePhone,
    avatarUrl,
    currentContractRelation,
    emergencyContactName,
    emergencyContactRelationship,
    emergencyContactPhone,
    loginId,
  } = record as UnlinkedPatientFragment

  return {
    id,
    firstName,
    lastName,
    dob,
    companyId: company?.id ?? '—',
    companyName: company?.name ?? '—',
    addressLineOne: addressLineOne ?? '—',
    addressLineTwo: addressLineTwo ?? '',
    city: city ?? '—',
    state: state ?? '—',
    zipCode: zipCode ?? '—',
    joined: createdAt,
    phone: mobilePhone ?? '—',
    gender: gender ?? '—',
    avatarUrl: avatarUrl ?? '',
    association: currentContractRelation?.relationshipToOrganization
      ? currentContractRelation.relationshipToOrganization
      : null,
    emergencyContactName: emergencyContactName ?? '—',
    emergencyContactPhone: emergencyContactPhone ?? '—',
    emergencyContactRelationship: emergencyContactRelationship ?? '—',
    loginId: loginId ? loginId : undefined,
  }
}

export function normalizeMemberMatchData(records?: UnlinkedPatientInfoFragment[]): UnlinkedPatientRecord[] {
  if (!records) {
    return []
  }
  return records.map<UnlinkedPatientRecord>(record => {
    const unlinkedPatientRecord: UnlinkedPatientRecord = {
      patient: mapPatientToMatchPerson(record.patient),
      matchTypeIfDependent: determineMatchType(
        record.patient.dob,
        (record.patient.currentContractRelation ?? {}) as Partial<PatientContractRelation>,
      ),
      relationshipToOrganization: record.patient.currentContractRelation?.relationshipToOrganization,
      strictlyEnforceRoster: record.patient.currentContractRelation?.contract?.strictlyEnforceRoster ?? false,
    }

    if (record.suggestedRecord) {
      unlinkedPatientRecord.suggestedMatch = mapEmployeeRecordToMatchPerson(
        record.suggestedRecord,
        record.patient?.company?.name ?? '',
      )
    }

    unlinkedPatientRecord.relatedEmployeeName = record.patient.currentContractRelation?.relatedEmployeeName ?? ''

    return unlinkedPatientRecord
  })
}

function determineMatchType(
  dob: string,
  { contract, relationshipToOrganization }: Partial<PatientContractRelation>,
): MatchTypeIfDependent {
  if (relationshipToOrganization === 'EMPLOYEE') return 'sponsoring employee'

  if (!contract?.availableToDependents) {
    return 'ineligible'
  }

  if (relationshipToOrganization === 'CHILD' && dob && contract?.dependentAgeCutoff) {
    const cutoffDate = moment().subtract(contract.dependentAgeCutoff, 'years')
    const dobDate = moment.tz(dob, 'UTC')

    if (dobDate.isBefore(cutoffDate)) {
      return 'ineligible'
    }
  }

  if (
    contract?.availableToBenefitsEligible ||
    contract?.availableToBenefitsEnrolled ||
    contract.strictlyEnforceRoster
  ) {
    return 'dependent'
  }

  return 'sponsoring employee'
}
