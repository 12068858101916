import { noop } from 'lodash'
import { Dispatch, ReactNode, createContext, useContext, useState } from 'react'

interface ContextValue {
  openNavGroup: string
  setOpenNavGroup: Dispatch<React.SetStateAction<string>>
}

interface Props {
  children: ReactNode
}

const NavContext = createContext<ContextValue>(buildDefaultContextValue())

export function NavContextProvider({ children }: Props) {
  const [openNavGroup, setOpenNavGroup] = useState('')
  const contextValue: ContextValue = { openNavGroup, setOpenNavGroup }
  return <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
}

export function useNavContext() {
  return useContext(NavContext)
}

function buildDefaultContextValue(): ContextValue {
  return {
    openNavGroup: '',
    setOpenNavGroup: noop,
  }
}
