import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { ReactNode, useMemo, useState } from 'react'

import { Login, OrderBy, Role, useSearchLoginsQuery } from '@nuna/api'

type Option = Pick<Login, 'id' | 'firstName' | 'lastName'>

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<Option | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface AdminSelectProps extends LimitedAutocompleteProps {
  value?: string | null
  onChange: (admin: Option | undefined | null) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
  states?: string[]
}

export function AdminSelect({
  value: valueInput,
  label = 'Admin User',
  error,
  helperText,
  placeholder,
  onChange,
  loading,
  ...props
}: AdminSelectProps) {
  const [searchTerm, setSearchTerm] = useState('')

  const { data } = useSearchLoginsQuery({
    variables: {
      filters: { role: Role.Admin, active: true },
      order: [{ direction: OrderBy.Asc, key: 'firstName' }],
      pagination: { limit: 200 },
    },
  })

  const options: Option[] = useMemo(() => data?.searchLogins.items ?? [], [data])

  const value = useMemo<Option | null>(
    () => options.find(option => option.id === valueInput) ?? null,
    [valueInput, options],
  )

  const filteredOptions = useMemo<Option[]>(() => {
    if (!searchTerm) {
      return options
    }
    const searcher = new FuzzySearch(options, ['firstName', 'lastName'], {
      caseSensitive: false,
    })

    return searcher.search(searchTerm)
  }, [searchTerm, options])

  return (
    <Autocomplete
      {...props}
      loading={!data || loading}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={option => (option ? `${option.firstName} ${option.lastName}` : '')}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      options={filteredOptions}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          data-testid="admin-autocomplete"
          name="selected-admin"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  )
}
