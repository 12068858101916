import { PayerRosterDefinitionTransformationBoolean } from '@nuna/api'
import { TextField } from '@nuna/tunic'

import { ValueMappingTable } from './ValueMappingTable'

interface BooleanValueMappingProps {
  booleanTransformation: PayerRosterDefinitionTransformationBoolean
  onChange: (booleanTransformation: PayerRosterDefinitionTransformationBoolean) => void
}

export function BooleanValueMapping({ booleanTransformation, onChange }: BooleanValueMappingProps) {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof PayerRosterDefinitionTransformationBoolean,
  ) => {
    onChange({ ...booleanTransformation, [key]: e.target.value })
  }
  return (
    <div>
      <ValueMappingTable>
        <tbody>
          <tr>
            <td>True</td>
            <td>
              <TextField
                value={booleanTransformation.true}
                onChange={e => handleChange(e as React.ChangeEvent<HTMLInputElement>, 'true')}
              />
            </td>
          </tr>
          <tr>
            <td>False</td>
            <td>
              <TextField
                value={booleanTransformation.false}
                onChange={e => handleChange(e as React.ChangeEvent<HTMLInputElement>, 'false')}
              />
            </td>
          </tr>
        </tbody>
      </ValueMappingTable>
    </div>
  )
}
