import { isBoolean } from 'lodash'

import { PersistentAlert, TextButton } from '@nuna/tunic'

interface RosterDataValidationStatusProps {
  isValid?: boolean
  validationLoading?: boolean
  revalidate: () => void
}

export function RosterDataValidationStatus({
  isValid,
  validationLoading,
  revalidate,
}: RosterDataValidationStatusProps) {
  if (!isBoolean(isValid)) {
    return null
  }

  return (
    <PersistentAlert intent={isValid ? 'success' : 'caution'}>
      Data {isValid ? 'Valid' : 'Invalid'}
      <TextButton className="ml-1" isLoading={validationLoading} onClick={() => revalidate()}>
        revalidate
      </TextButton>
    </PersistentAlert>
  )
}
