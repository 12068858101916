import { useState } from 'react'

import { CredentialingChecklistDataPoint } from '@nuna/api'
import { CredentialingChecklist, CredentialingChecklistStatus, CredentialingChecklistType } from '@nuna/core'
import { Intent, StatusLabel } from '@nuna/tunic'

import { ChecklistDialog, ChecklistDialogTypes } from '../Dialogs'

type CellProps = {
  field: CredentialingChecklistType
  data: CredentialingChecklistDataPoint
  status: CredentialingChecklistStatus
  handleClick?: (e: never) => void
}
export function CredentialChecklistStatusCell(props: CellProps) {
  const [isOpen, setIsOpen] = useState(false)
  if (!props.status) return null

  const getIntent = (): Intent => {
    switch (props.status) {
      case CredentialingChecklistStatus.VERIFIED:
        return 'information'
      case CredentialingChecklistStatus.PENDING:
      case CredentialingChecklistStatus.MISSING:
      case CredentialingChecklistStatus.INCOMPLETE:
        return 'caution'
      case CredentialingChecklistStatus.FLAG:
      case CredentialingChecklistStatus.EXPIRED:
        return 'urgent'
      default:
        return 'default'
    }
  }

  const getDialogType = (): ChecklistDialogTypes | undefined => {
    const requestedDialog = props.field
    const dialogs = {
      [CredentialingChecklist.ATTESTATION]: CredentialingChecklist.ATTESTATION,
      [CredentialingChecklist.EDUCATION]: CredentialingChecklist.EDUCATION,
      [CredentialingChecklist.WORK_HISTORY]: CredentialingChecklist.WORK_HISTORY,
      [CredentialingChecklist.PROVIDER_LIABILITY_INSURANCE]: CredentialingChecklist.PROVIDER_LIABILITY_INSURANCE,
      [CredentialingChecklist.STATE_LICENSES]: CredentialingChecklist.STATE_LICENSES,
      [CredentialingChecklist.NPI]: CredentialingChecklist.NPI,
      [CredentialingChecklist.CAQH_DISCLOSURES]: CredentialingChecklist.CAQH_DISCLOSURES,
      [CredentialingChecklist.MEDICARE_OPT_OUT]: CredentialingChecklist.MEDICARE_OPT_OUT,
      [CredentialingChecklist.NPDB]: CredentialingChecklist.NPDB,
      [CredentialingChecklist.OIG_EXCLUSIONS]: CredentialingChecklist.OIG_EXCLUSIONS,
      [CredentialingChecklist.SAM_EXCLUSIONS]: CredentialingChecklist.SAM_EXCLUSIONS,
      [CredentialingChecklist.STATE_SANCTIONS]: CredentialingChecklist.STATE_SANCTIONS,
    }

    return dialogs[requestedDialog]
  }
  const noDataVerifiedTypes: CredentialingChecklistType[] = [
    CredentialingChecklist.SAM_EXCLUSIONS,
    CredentialingChecklist.OIG_EXCLUSIONS,
    CredentialingChecklist.STATE_SANCTIONS,
    CredentialingChecklist.MEDICARE_OPT_OUT,
  ]
  const dialogType = getDialogType()
  const verifiedBecauseThereIsNoData =
    !!dialogType && props.status === CredentialingChecklistStatus.VERIFIED && noDataVerifiedTypes.includes(props.field)
  const canInitializeDialog =
    !!dialogType && !verifiedBecauseThereIsNoData && props.status !== CredentialingChecklistStatus.MISSING

  const handleClick = (e: never) => {
    if (props.handleClick) {
      props.handleClick(e)
    }
    if (!canInitializeDialog) return
    if (dialogType) {
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const getComponentClasses = () => {
    return canInitializeDialog ? 'cursor-pointer' : ''
  }

  return (
    <>
      <StatusLabel className={getComponentClasses()} onClick={handleClick} intent={getIntent()} {...props}>
        {props.status}
      </StatusLabel>
      {dialogType && <ChecklistDialog data={props.data} open={isOpen} handleClose={handleClose} type={dialogType} />}
    </>
  )
}
