import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { ProviderEnrollmentStatus } from '@nuna/api'

type Props = {
  status?: ProviderEnrollmentStatus
  onChange: (status: ProviderEnrollmentStatus) => void
}
export function PayerStatusSelect({ status = ProviderEnrollmentStatus.Pending, onChange }: Props) {
  const handleChange = (e: SelectChangeEvent<ProviderEnrollmentStatus>) => {
    e.stopPropagation()
    onChange(e.target.value as ProviderEnrollmentStatus)
  }

  return (
    <Select value={status} onChange={handleChange} labelId="selected-enrollment-status">
      {[
        [ProviderEnrollmentStatus.Pending, 'Pending'],
        [ProviderEnrollmentStatus.OptedOut, 'Opted Out'],
      ].map(([value, label]) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}
