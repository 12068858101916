import { HTMLAttributes, useState } from 'react'

import { PanelingStatus, ProviderPanelingFragment, useTriggerProviderPanelingFormMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  ContextualAlert,
  CopyButton,
  GhostButtonExternalLink,
  Grid,
  IconChevron,
  IconExport,
  IconInfo,
  OutlineButton,
  toast,
} from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string
  panelingInfo?: ProviderPanelingFragment | null
}
export function ProviderPanelingLifecycle({ panelingInfo, providerId, ...props }: Props) {
  const [oneOpen, setOneOpen] = useState(() => panelingInfo?.status !== PanelingStatus.Complete)

  const [triggerProviderPaneling, { loading }] = useTriggerProviderPanelingFormMutation({
    refetchQueries: ['ProviderActivity'],
  })

  const handleCardClick = () => {
    setOneOpen(!oneOpen)
  }

  const handleBeginClick = async () => {
    try {
      await triggerProviderPaneling({ variables: { providerId } })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was a problem beginning the paneling process.'))
    }
  }

  return (
    <Card depth={1} shadowColor="cool" style={{ padding: 16 }} {...props}>
      <CardHeader
        divider={false}
        className="space-between v-align"
        onClick={handleCardClick}
        style={{ cursor: 'pointer' }}
      >
        <div className="v-align">
          <IconChevron direction={oneOpen ? 'up' : 'right'} />
          <h2 className="ml-2 sans-serif h6" style={{ marginBottom: 0 }}>
            Collecting Paneling Information
          </h2>
        </div>
      </CardHeader>
      <Collapse isOpen={oneOpen}>
        <CardBody className="space-between">
          <Grid container spacing={4}>
            <Grid>
              <div className="h5 sans-serif text-medium">Provider URL</div>
              <div className="mt-2">
                {panelingInfo?.submissionUrl ? (
                  <CopyButton value={panelingInfo.submissionUrl} style={{ maxWidth: 260 }} />
                ) : (
                  <ContextualAlert icon={<IconInfo />}>No URL Available</ContextualAlert>
                )}
              </div>
            </Grid>
            <Grid>
              <div className="h5 sans-serif text-medium">Paneling Stage</div>
              <div className="large text-default">{panelingStage(panelingInfo?.status)}</div>
              {panelingInfo?.reportUrl && (
                <div className="mt-1">
                  <GhostButtonExternalLink variant="secondary" href={panelingInfo.reportUrl}>
                    Review Paneling Responses <IconExport className="ml-1" size={20} />
                  </GhostButtonExternalLink>
                </div>
              )}
            </Grid>
          </Grid>
          {panelingInfo?.status === PanelingStatus.Legacy && (
            <OutlineButton variant="secondary" isLoading={loading} onClick={handleBeginClick}>
              Start Paneling
            </OutlineButton>
          )}
        </CardBody>
      </Collapse>
    </Card>
  )
}

function panelingStage(status?: PanelingStatus | null) {
  switch (status) {
    case PanelingStatus.Pending:
      return 'Gathering Provider Data'
    case PanelingStatus.Complete:
      return 'Completed'
    case PanelingStatus.NotStarted:
      return 'Not Started'
  }

  return 'Not Started'
}
