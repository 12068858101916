import { HTMLAttributes, useState } from 'react'

import { TavaLoginFragment } from '@nuna/api'
import { IconButton, IconSettings } from '@nuna/tunic'

import { AdminSettingsDrawer } from './AdminSettingsDrawer'

interface Props extends HTMLAttributes<HTMLElement> {
  user?: TavaLoginFragment
}

export function AdminSettingsIcon({ user, ...props }: Props) {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  if (!user?.id) return null

  return (
    <>
      <IconButton
        style={{ position: 'relative' }}
        tooltip="Administrative Settings"
        onClick={() => setDrawerIsOpen(!drawerIsOpen)}
        {...props}
      >
        <IconSettings />
      </IconButton>
      <AdminSettingsDrawer isOpen={drawerIsOpen} onClose={() => setDrawerIsOpen(false)} user={user} />
    </>
  )
}
