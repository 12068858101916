import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { ReactNode, useMemo, useState } from 'react'

import { Checkbox } from '@nuna/tunic'

import { PAYER_COUNTIES, StateCounty, payerCountyToString } from './payer-counties'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<StateCounty, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  value?: StateCounty[]
  onChange: (states: StateCounty[]) => void
  label?: string
  helperText?: string | ReactNode
  error?: boolean
  useCheckboxes?: boolean
}

export function PayerCountySelect({
  value,
  onChange,
  label = 'Counties',
  useCheckboxes = false,
  error = false,
  helperText,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredCounties = useMemo<StateCounty[]>(() => {
    const searcher = new FuzzySearch(PAYER_COUNTIES, ['state', 'county'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return PAYER_COUNTIES
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(value)
      }}
      groupBy={option => option.state}
      getOptionLabel={option => option.county}
      isOptionEqualToValue={(option, value) => payerCountyToString(option) === payerCountyToString(value)}
      options={filteredCounties}
      disableCloseOnSelect
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField error={error} helperText={helperText} label={label} {...params} />}
      renderOption={
        useCheckboxes
          ? (renderOptionProps, option, { selected }) => (
              <li {...renderOptionProps}>
                <Checkbox checked={selected}>{option.county}</Checkbox>
              </li>
            )
          : undefined
      }
    />
  )
}
