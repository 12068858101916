import { styled } from '@mui/material'
import { FormikProps } from 'formik'
import { MouseEvent, useRef, useState } from 'react'

import { PayerRosterDefinitionConfigSheet } from '@nuna/api'
import {
  Card,
  Confirm,
  ConfirmRef,
  FillButton,
  IconButton,
  IconClose,
  IconPencil,
  MenuItem,
  OutlineButton,
  TextField,
} from '@nuna/tunic'

import { PayerRosterDefinitionFormValues } from '../PayerRosterDefinition.types'

export interface SheetNameEditorProps extends FormikProps<PayerRosterDefinitionFormValues> {
  sheet: PayerRosterDefinitionConfigSheet
  onSheetClick: () => void
  index: number
  isActive: boolean
  onSheetRemove: (index: number) => void
}

export function SheetName({
  setFieldValue,
  sheet,
  index,
  isActive,
  submitForm,
  onSheetRemove,
  onSheetClick,
}: SheetNameEditorProps) {
  const confirmRef = useRef<ConfirmRef>(null)
  const [editValue, setEditValue] = useState(sheet?.name)
  const [isEditing, setIsEditing] = useState(!sheet?.name)

  const handleSave = async () => {
    setFieldValue(`config.sheets[${index}].name`, editValue)
    await submitForm()
    setIsEditing(false)
  }

  const handleRemove = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    confirmRef.current?.open(shouldRemove => {
      if (shouldRemove) {
        onSheetRemove(index)
      }
    })
  }

  if (!sheet) {
    return null
  }

  if (isEditing) {
    return (
      <Card depth={-1} className="v-align gap-2 p-1">
        <TextField
          placeholder="Sheet name"
          value={editValue}
          onChange={e => setEditValue(e.currentTarget.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSave()
            }
          }}
        />
        <div className="gap-1 v-align">
          <FillButton type="button" onClick={handleSave}>
            Save
          </FillButton>
          {!!sheet?.name && <OutlineButton onClick={() => setIsEditing(false)}>Cancel</OutlineButton>}
          {!!index && (
            <IconButton tooltip="Remove sheet" variant="destroy" onClick={handleRemove}>
              <IconClose />
            </IconButton>
          )}
        </div>
        <Confirm ref={confirmRef}>Are you sure you want to remove this sheet?</Confirm>
      </Card>
    )
  }

  return (
    <StyledMenuItem
      className="sheet-tab"
      button
      isActive={() => isActive}
      onClick={onSheetClick}
      style={{ width: 'auto' }}
    >
      <span className="v-align gap-2">
        {sheet?.name}
        <IconButton small className="edit-button" tooltip="Edit" onClick={() => setIsEditing(true)}>
          <IconPencil />
        </IconButton>
      </span>
    </StyledMenuItem>
  )
}

const StyledMenuItem = styled(MenuItem)`
  margin-top: 0;
  &.menu-item {
    padding: 2px var(--spacing-1);
  }
  .edit-button {
    visibility: hidden;
  }

  &:hover {
    .edit-button {
      visibility: visible;
    }
  }
`
