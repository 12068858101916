import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { styled } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import { keyBy } from 'lodash'
import { CSSProperties, HTMLAttributes, forwardRef, useEffect, useMemo, useState } from 'react'

import {
  Dialog,
  DialogProps,
  FillButton,
  IconButton,
  IconClose,
  IconLeading,
  OutlineButton,
  StatusLabel,
  body2,
  borderGrey,
  csx,
  eggshell,
  greySet,
  shadowDepth,
} from '@nuna/tunic'

import { PayerRosterDefinitionFormValues } from '../PayerRosterDefinition.types'
import { getColumnLetters, getIndexFromLetters, getLetterOptions } from '../PayerRosterDefinition.util'

interface ColumnOrderDialogProps extends FormikProps<PayerRosterDefinitionFormValues>, DialogProps {
  selectedSheetIndex: number
  saveLoading?: boolean
  onSave: (columnKeys: string[]) => void
}

export function ColumnOrderDialog({
  isOpen,
  selectedSheetIndex,
  onClose,
  values,
  onSave,
  saveLoading,
}: ColumnOrderDialogProps) {
  const [activeKey, setActiveKey] = useState<string | null>(null)
  const sheet = useMemo(() => values.config.sheets[selectedSheetIndex], [values, selectedSheetIndex])
  const columnMap = useMemo(() => keyBy(values.config.columns, c => c.key), [values])
  const [columnKeys, setColumnKeys] = useState<string[]>(() => {
    if (!sheet) return []
    return sheet.columnKeys
  })

  useEffect(() => {
    if (sheet) {
      setColumnKeys(sheet.columnKeys)
    }
  }, [sheet])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event

    setActiveKey(active.id as string)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setColumnKeys(cols => {
        const oldIndex = cols.indexOf(active.id as string)
        const newIndex = cols.indexOf(over?.id as string)

        return arrayMove(cols, oldIndex, newIndex)
      })
    }

    setActiveKey(null)
  }

  const handleMove = (key: string, letters: string) => {
    const newIndex = getIndexFromLetters(letters)
    setColumnKeys(cols => {
      const oldIndex = cols.indexOf(key)
      return arrayMove(cols, oldIndex, newIndex)
    })
  }

  const handleSave = () => {
    onSave(columnKeys)
  }

  return (
    <Dialog isOpen={isOpen} fullScreen onClose={onClose}>
      <div className="full-height flex-column overflow-hidden">
        <div className="v-align p-1">
          <h1 className="mb-0 h5 ml-1 sans-serif text-medium">{sheet.name} Column Order</h1>
          <IconButton
            variant="secondary"
            className="ml-auto"
            tooltip="Close"
            onClick={() => onClose && onClose({}, 'backdropClick')}
          >
            <IconClose />
          </IconButton>
        </div>
        <div className="flex-remaining-space flex-column gap-xs overflow-auto p-2">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={columnKeys} strategy={verticalListSortingStrategy}>
              {columnKeys.map((key, idx) => (
                <SortableItem
                  key={key}
                  id={key}
                  order={getColumnLetters(idx)}
                  count={columnKeys.length}
                  onMove={handleMove}
                >
                  {columnMap[key]?.alias}
                </SortableItem>
              ))}
            </SortableContext>
            <DragOverlay>
              {activeKey && (
                <Item active id={activeKey}>
                  {columnMap[activeKey]?.alias}
                </Item>
              )}
            </DragOverlay>
          </DndContext>
        </div>
        <div className="mt-auto p-2 v-align gap-1">
          <FillButton isLoading={saveLoading} onClick={handleSave}>
            Save
          </FillButton>
          <OutlineButton onClick={() => onClose && onClose({}, 'backdropClick')}>Cancel</OutlineButton>
        </div>
      </div>
    </Dialog>
  )
}

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  order?: string
  count?: number
  onMove?: (key: string, letters: string) => void
}

function SortableItem({ id, children, order, count = 0, onMove }: ItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id as string })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      ref={setNodeRef}
      style={style}
      order={order}
      count={count}
      {...attributes}
      onMove={onMove}
      listeners={listeners}
      id={id}
    >
      {children}
    </Item>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types -- dnd-kit doesn't export its listers type. Internally it is typed as Record<string, Function> which typescript doesn't like
const Item = forwardRef<HTMLDivElement, ItemProps & { listeners?: Record<string, Function> | undefined }>(
  ({ children, active, order, listeners, count, onMove, ...props }, ref) => {
    const letterOptions = useMemo(() => getLetterOptions(count ?? 0), [count])
    const handleMoveChange = (value?: string | null) => {
      if (value && onMove) {
        onMove(props.id as string, value)
      }
    }

    return (
      <StyledItem active={!!active} ref={ref} {...props}>
        <button className="drag-handle text-secondary" type="button" {...listeners}>
          <IconLeading style={{ transform: 'rotate(90deg)' }} />
          <IconLeading style={{ transform: 'rotate(90deg)', marginLeft: -13 }} />
        </button>
        <div className="top-align flex-remaining-space overflow-hidden px-1">
          <StatusLabel className={csx([{ hidden: !order }, 'text-medium mr-1 letters'])}>{order}</StatusLabel>
          {children}
        </div>
        <Autocomplete
          value={null}
          className="move-to-select"
          options={letterOptions}
          onChange={(_, value) => handleMoveChange(value)}
          renderInput={params => <TextField {...params} placeholder="Move to" />}
        />
      </StyledItem>
    )
  },
)

const StyledItem = styled('div')<{ active: boolean }>`
  border: 1px solid ${borderGrey};
  width: 800px;
  padding: var(--spacing-1);
  background-color: ${eggshell};
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-sm);
  color: ${body2};

  .drag-handle {
    cursor: grab;
    color: ${greySet[50].hex};
    width: 25px;
  }

  .letters {
    width: 36px;
    text-align: center;
    &.hidden {
      visibility: hidden;
    }
  }

  .move-to-select {
    width: 120px;
    &.hidden {
      visibility: hidden;
    }
  }

  ${({ active }) =>
    active
      ? `
    cursor: grabbing;
    box-shadow: ${shadowDepth(1, 'cool')}
  `
      : ''}
`
