import { useState } from 'react'

import { DocumentType, UserDocumentStatus, useSaveUserDocumentMutation } from '../generated/graphql'
import { useDocumentUpload } from './useDocumentUpload'

interface SaveUserDocumentParams {
  documentType: DocumentType
  status?: UserDocumentStatus
  loginId: string
}

export function useSaveUserDocuments() {
  const [uploading, setIsUploading] = useState(false)
  const { uploadDocument } = useDocumentUpload()
  const [saveUserDocument] = useSaveUserDocumentMutation()

  const saveDocuments = async (
    files: File[],
    { documentType, loginId, status = UserDocumentStatus.Default }: SaveUserDocumentParams,
  ) => {
    try {
      const documentIds: string[] = []
      if (!files.length) return

      setIsUploading(true)

      for (const file of files) {
        const document = await uploadDocument(file, documentType)
        documentIds.push(document.id)

        const { errors: userDocumentErrors } = await saveUserDocument({
          variables: {
            userDocument: {
              documentId: document.id,
              loginId,
              status,
            },
          },
        })

        if (userDocumentErrors?.length) {
          throw new Error(userDocumentErrors[0].message)
        }
      }

      setIsUploading(false)
      return documentIds
    } finally {
      setIsUploading(false)
    }
  }

  return [saveDocuments, { loading: uploading }] as const
}
