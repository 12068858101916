import { useState } from 'react'

import { DocumentType, useSaveUserDocuments } from '@nuna/api'
import { FileUpload, FillButton, toast } from '@nuna/tunic'

import { DocumentTypeSelect } from './DocumentTypeSelect'

interface Props {
  loginId: string
  onDocumentsUploaded: () => void
}

export function AddUserDocumentsForm({ loginId, onDocumentsUploaded }: Props) {
  const [files, setFiles] = useState<File[]>([])
  const [documentType, setDocumentType] = useState<DocumentType | undefined>()
  const [saveDocuments, { loading }] = useSaveUserDocuments()

  const handleSave = async () => {
    try {
      if (files.length && documentType) {
        await saveDocuments(files, { documentType, loginId })
        toast.success('Document uploaded successfully')
        onDocumentsUploaded()
      }
    } catch (e) {
      toast.urgent('There was an error uploading the document')
    }
  }
  return (
    <div style={{ width: 400 }}>
      <FileUpload onDrop={setFiles} />
      <DocumentTypeSelect className="mt-2" value={documentType} onChange={option => setDocumentType(option?.value)} />
      <FillButton isLoading={loading} className="mt-4" disabled={!files.length || !documentType} onClick={handleSave}>
        Upload File
      </FillButton>
    </div>
  )
}
