import { last } from 'lodash'
import moment, { Moment } from 'moment'
import { useMemo } from 'react'
import { Column } from 'react-table'

import { BasicPatientFragment, BasicProviderFragment, ConversationForAppointmentDetailFragment } from '@nuna/api'
import { DataTable, DataTableState } from '@nuna/data-table'
import { Avatar, csx } from '@nuna/tunic'

interface Props {
  messages: ConversationForAppointmentDetailFragment[]
  provider: BasicProviderFragment
  patient: BasicPatientFragment
}

interface ConversationRow {
  authorFullName: string
  authorId: string
  authorRole: 'Provider' | 'Client'
  body: string
  dateCreated: Moment
  offset: number
  id: string
}

const INITIAL_STATE: DataTableState<ConversationRow> = {
  sortBy: [
    {
      id: 'dateCreated',
      desc: false,
    },
  ],
}

export function AppointmentConversationTable({ patient, provider, messages }: Props) {
  const rowData: ConversationRow[] = useMemo(() => {
    const sortedMessages = [...messages].sort((a, b) => moment(b.dateCreated).unix() - moment(a.dateCreated).unix())
    const lastMessage = last(sortedMessages)

    return (sortedMessages || []).map(({ authorFullName, authorId, authorRole, body, dateCreated, id }) => {
      return {
        authorFullName,
        authorId,
        authorRole: authorRole === 'provider' ? 'Provider' : 'Client',
        body,
        dateCreated: moment(dateCreated),
        offset: moment(dateCreated).diff(moment(lastMessage?.dateCreated), 'seconds'),
        id,
      }
    })
  }, [messages])

  const columns: Column<ConversationRow>[] = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'dateCreated',
        id: 'date',
        Cell: ({ value }) => <>{value.format('YYYY/MM/DD LTS')}</>,
      },
      {
        Header: 'Time Since First Message',
        accessor: 'offset',
        Cell: ({ value }) => {
          const duration = moment.duration(value, 'seconds')
          return (
            <>
              {duration.minutes()}m {duration.seconds()}s
            </>
          )
        },
      },
      {
        Header: 'Name',
        accessor: 'authorFullName',
        Cell: ({ value, row }) => (
          <div className={csx(['v-align', { 'fs-exclude': row.original.authorRole === 'Client' }])}>
            <Avatar
              className="mr-1"
              size="mini"
              url={row.original.authorRole === 'Provider' ? provider.avatarUrl : patient.avatarUrl}
            />
            {value}
          </div>
        ),
      },
      {
        Header: 'Role',
        accessor: 'authorRole',
      },
      {
        Header: 'Message',
        accessor: 'body',
        Cell: ({ value }) => <div className="fs-exclude">{value}</div>,
      },
    ],
    [provider, patient],
  )

  return (
    <>
      {!messages.length && <div className="text-sedondary">No messages</div>}
      {!!messages.length && (
        <div>
          <DataTable
            columns={columns}
            rowData={rowData}
            loading={false}
            initialState={INITIAL_STATE}
            cellPadding="1rem"
          />
        </div>
      )}
    </>
  )
}
