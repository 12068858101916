import { styled } from '@mui/material'
import moment from 'moment'
import { HTMLAttributes } from 'react'

import { IconButton, IconChevronLeft, IconChevronRight, csx } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  fromDate: moment.Moment
  toDate: moment.Moment
  onNextPeriodClick: () => void
  onPreviousPeriodClick: () => void
}

export function PayPeriodPaginator({
  fromDate,
  toDate,
  onNextPeriodClick,
  onPreviousPeriodClick,
  className,
  ...props
}: Props) {
  return (
    <Container className={csx([className, 'v-align'])} {...props}>
      <IconButton type="button" tooltip="Previous pay period" onClick={onPreviousPeriodClick}>
        <IconChevronLeft />
      </IconButton>
      <span className="v-align">
        <span className="text-dark-grey">{fromDate.format('MMM Do')}</span>
        <span className="separator text-secondary">to</span>
        <span className="text-dark-grey">{toDate.format('MMM Do')}</span>
      </span>

      <IconButton type="button" tooltip="Next pay period" onClick={onNextPeriodClick}>
        <IconChevronRight />
      </IconButton>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  align-items: center;

  .separator {
    margin: 0 0.35rem;
  }
`
