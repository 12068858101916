/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from '@mui/material'
import { FieldInputProps, FieldProps, FormikProps } from 'formik'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

import { IconImage } from '../icons'
import { greySet } from '../styles/colorSets'
import { toast } from './Toast'

type ImagePurpose = 'avatar' | 'logo' | 'other'

const Container = styled('div')<any>`
  width: 100%;
  border: 2px dashed #ddd;
  border-radius: 4px;
  padding: 1.2rem;
  display: flex;
  background-color: ${({ hovering }) => (hovering ? '#d9f4d5' : 'none')};
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`

const HelperContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ImageContainer = styled('div')<{ hovering: boolean; purpose: ImagePurpose; url: string }>`
  background-color: ${({ hovering }) => (hovering ? '#d9f4d5' : 'none')};
  background-size: ${({ purpose }) => (purpose === 'avatar' ? 'cover' : 'contain')};
  background-position: ${({ purpose }) => (purpose === 'avatar' ? 'top center' : 'center')};
  background-repeat: no-repeat;
  background-image: url(${({ url }) => url});
  border-radius: ${({ purpose }) => (purpose === 'avatar' ? '50%' : 'none')};
  height: 125px;
  width: ${({ purpose }) => (purpose === 'avatar' ? '125px' : '184px')};
  cursor: pointer;
  &:hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
  transform: ${({ hovering }) => (hovering ? `scaleX(1.1) scaleY(1.1)` : 'none')};
  transition: all 0.25s;
`

interface Props extends Omit<Partial<FieldProps<any>>, 'field' | 'form'> {
  presetPreviewUrl?: string
  onImageAdd?: (file: File) => void
  maxSize?: number
  purpose?: ImagePurpose
  accept?: string | string[]
  captionText?: string
  form?: Partial<FormikProps<any>>
  field?: Partial<FieldInputProps<any>>
}

export function PictureField({
  field = {},
  form = {},
  presetPreviewUrl = '',
  onImageAdd = noop,
  maxSize = 200000,
  purpose = 'avatar',
  accept = 'image/*',
  captionText = '',
}: Props) {
  const [hovering, setHovering] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(presetPreviewUrl)
  const { name = '' } = field
  const { setFieldValue = noop } = form
  useEffect(() => {
    setPreviewUrl(presetPreviewUrl)
  }, [presetPreviewUrl])

  const handleDrop = ([file]: File[]) => {
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setPreviewUrl(objectUrl)
      setFieldValue(name, file)
      onImageAdd(file)
    }
  }

  const handleDragEnter = () => {
    setHovering(true)
  }

  const handleDragLeave = () => {
    setHovering(false)
  }

  const handleDropAccepted = () => {
    setHovering(false)
  }

  const handleDropRejected = () => {
    setHovering(false)

    toast.urgent('File is either too large or not an image.')
  }

  const getCaptionText = () => {
    if (captionText) {
      return captionText
    }
    return `Image must be square and less than ${maxSize / 1000} KB`
  }

  return (
    <Dropzone
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDropAccepted={handleDropAccepted}
      onDragLeave={handleDragLeave}
      onDropRejected={handleDropRejected}
      accept={accept}
      maxSize={maxSize}
    >
      {({ getRootProps, getInputProps }) => (
        <Container hovering={hovering} {...getRootProps()}>
          <input {...(getInputProps() as any)} />
          <div>{previewUrl ? <ImageContainer hovering={hovering} url={previewUrl} purpose={purpose} /> : null}</div>
          <HelperContainer>
            <IconImage size={54} color={greySet[30].hex} />
            <div className="text-secondary">Drag an image here, or click to add</div>
            <div className="text-secondary">{getCaptionText()}</div>
          </HelperContainer>
        </Container>
      )}
    </Dropzone>
  )
}
