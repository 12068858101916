import { useEffect } from 'react'

import { DataCollectionStep, ProviderCredentialingDataQuery, useProviderCredentialingDataQuery } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Intent, ListItem, Skeleton, StatusLabel, toast } from '@nuna/tunic'

export function ProviderCAQHStatus({ providerId }: { providerId: string }) {
  const { data, error } = useProviderCredentialingDataQuery({ variables: { providerId } })

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was a problem loading provider credentialing data'))
      console.error(error)
    }
  }, [error])

  const { intent, status } = getStatus(data)

  return (
    <ListItem className="v-align" style={{ maxWidth: 300 }}>
      Insurance setup
      <span className="ml-auto">
        {!data ? (
          <Skeleton width={83} height={21} dimensionUnits="px" style={{ borderRadius: 4 }} />
        ) : (
          <StatusLabel intent={intent}>{status}</StatusLabel>
        )}
      </span>
    </ListItem>
  )
}

function getStatus(data: ProviderCredentialingDataQuery | undefined): { intent: Intent; status: string } {
  if (!data?.providerCredentialingData) return { intent: 'default', status: 'Not started' }
  if (data.providerCredentialingData.currentStep === DataCollectionStep.Completed)
    return { intent: 'information', status: 'Complete' }

  return { intent: 'caution', status: data.providerCredentialingData.currentStep.replace('_', ' ') }
}
