import { useApolloClient } from '@apollo/client'
import { FormikProps } from 'formik'
import { useState } from 'react'

import { Card, CardBody, GhostButton, IconButton, IconPlus, Switch, TextField } from '@nuna/tunic'

import { PayerRosterDefinitionFormValues, PayerRosterSheetFilter } from '../PayerRosterDefinition.types'
import { buildEmptySheet } from '../PayerRosterDefinition.util'
import { ColumnOrderDialog } from './ColumnOrderDialog'
import { SheetColumnsEditor } from './SheetColumnsEditor'
import { SheetFiltersMultielect } from './SheetFiltersMultiselect'
import { SheetName } from './SheetName'

interface SheetsEditorProps extends FormikProps<PayerRosterDefinitionFormValues> {
  saveLoading?: boolean
}
export function SheetsEditor(props: SheetsEditorProps) {
  const apolloClient = useApolloClient()
  const { values, submitForm, setFieldValue, getFieldProps } = props
  const [isColumnOrderDialogOpen, setIsColumnOrderDialogOpen] = useState(false)
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(0)
  const selectedSheet = values.config.sheets[selectedSheetIndex]
  const sheets = values.config.sheets

  const handleAddSheet = async () => {
    props.setFieldValue('config.sheets', [...sheets, buildEmptySheet(sheets.length)])
    await submitForm()
    setSelectedSheetIndex(sheets.length)
  }

  const handleSheetRemove = async (index: number) => {
    setSelectedSheetIndex(index - 1)
    setFieldValue(
      'config.sheets',
      values.config.sheets.filter((_, i) => i !== index),
    )
    await submitForm()
  }

  const handleColumnOrderSave = async (columnKeys: string[]) => {
    setFieldValue(`config.sheets[${selectedSheetIndex}].columnKeys`, columnKeys)
    await submitForm()
    setIsColumnOrderDialogOpen(false)
  }

  const handleSheetSubmitAndRefetch = async () => {
    await submitForm()
    apolloClient.refetchQueries({ include: ['PayerRosterSheetData'] })
  }

  return (
    <div className="flex-column flex-remaining-space pt-4">
      <div className="v-align">
        <IconButton className="mr-2" small tooltip="Add sheet" onClick={handleAddSheet}>
          <IconPlus size={16} />
        </IconButton>
        <div className="flex-remaining-space overflow-hidden no-wrap">
          <nav className="v-align gap-1 overflow-auto no-wrap">
            {sheets.map((sheet, idx) => (
              <SheetName
                key={sheet.key}
                isActive={idx === selectedSheetIndex}
                index={idx}
                sheet={sheet}
                onSheetClick={() => setSelectedSheetIndex(idx)}
                onSheetRemove={handleSheetRemove}
                {...props}
              />
            ))}
          </nav>
        </div>
      </div>
      {selectedSheet && (
        <div className="py-2">
          <Card depth={-0.5}>
            <CardBody className="v-align space-between">
              <div className="v-align gap-4">
                <SheetFiltersMultielect
                  style={{ minWidth: 400 }}
                  label="Sheet filters"
                  value={selectedSheet.filters as PayerRosterSheetFilter[]}
                  onChange={filters => {
                    setFieldValue(
                      `config.sheets[${selectedSheetIndex}].filters`,
                      filters.map(f => f.value),
                    )
                    handleSheetSubmitAndRefetch()
                  }}
                />
                <TextField
                  label="Row Limit"
                  type="number"
                  {...getFieldProps(`config.sheets[${selectedSheetIndex}].limit`)}
                  onBlur={handleSheetSubmitAndRefetch}
                />
                <Switch
                  checked={!!selectedSheet.groupByPrimaryLocation}
                  onChange={e => {
                    setFieldValue(
                      `config.sheets[${selectedSheetIndex}].groupByPrimaryLocation`,
                      e.currentTarget.checked,
                    )
                    handleSheetSubmitAndRefetch()
                  }}
                >
                  Group by Primary Location
                </Switch>
                <Switch
                  checked={!!selectedSheet.groupByDefaultTaxEntity}
                  onChange={e => {
                    setFieldValue(
                      `config.sheets[${selectedSheetIndex}].groupByDefaultTaxEntity`,
                      e.currentTarget.checked,
                    )
                    handleSheetSubmitAndRefetch()
                  }}
                >
                  Group by Default Tax Entity
                </Switch>
              </div>
              <GhostButton onClick={() => setIsColumnOrderDialogOpen(true)}>Column Order</GhostButton>
            </CardBody>
          </Card>
        </div>
      )}
      <SheetColumnsEditor sheetIndex={selectedSheetIndex} className="pt-2" {...props} />
      <ColumnOrderDialog
        isOpen={isColumnOrderDialogOpen}
        saveLoading={props.saveLoading}
        selectedSheetIndex={selectedSheetIndex}
        onSave={handleColumnOrderSave}
        onClose={() => setIsColumnOrderDialogOpen(false)}
        {...props}
      />
    </div>
  )
}
