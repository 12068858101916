import { Divider } from '@mui/material'

import type { Provider } from '@nuna/api'
import { Grid } from '@nuna/tunic'

import { ChecklistActions } from './Actions/ChecklistActions'
import { CredentialingChecklistContextProvider } from './Context/CredentialingChecklistContextProvider'
import { CredentialingDialogTypes } from './Credentialing'
import { ChecklistTable } from './DataTable/ChecklistTable'
import { CredentialingStatus } from './Elements/CredentialingStatus'
import { SectionTitle } from './Elements/SectionTitle'

type Props = {
  provider: Provider
  handleAction: (credentialingDialogType: CredentialingDialogTypes) => void
}
export function Checklist({ provider, handleAction }: Props) {
  return (
    <CredentialingChecklistContextProvider providerId={provider.id}>
      <Grid container direction="column" spacing={2} style={{ width: '100%', height: '100%' }}>
        <Grid container justifyContent={'space-between'}>
          <SectionTitle title={`Checklist`} />
          {provider.providerCredentialing && (
            <CredentialingStatus providerCredentialing={provider.providerCredentialing} />
          )}
          <ChecklistActions handleAction={handleAction} provider={provider} />
        </Grid>
        <Divider />
        <ChecklistTable />
      </Grid>
    </CredentialingChecklistContextProvider>
  )
}
