import { styled } from '@mui/material'
import { Moment } from 'moment'
import { HTMLAttributes } from 'react'

import { InlineTextDatePicker } from './InlineTextDatePicker'

export interface DateRangeValue {
  fromDate: Moment
  toDate: Moment
}

export interface DateRangePickerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: DateRangeValue
  onChange: (value: DateRangeValue) => void
}

export function DateRangePicker({ value, onChange, ...props }: DateRangePickerProps) {
  const handleFromDateChange = (date: Moment | null) => {
    if (date) {
      onChange({ ...value, fromDate: date })
    }
  }
  const handleToDateChange = (date: Moment | null) => {
    if (date) {
      onChange({ ...value, toDate: date })
    }
  }
  return (
    <Wrapper {...props}>
      <InlineTextDatePicker value={value.fromDate} onChange={handleFromDateChange} hideUnderline />
      <span className="separator text-secondary">to</span>
      <InlineTextDatePicker value={value.toDate} onChange={handleToDateChange} hideUnderline hideIcon />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;

  .separator {
    margin: 0 0.35rem;
  }
`
