import { styled } from '@mui/material'

import { PaymentPreference, useIntakeProgressQuery, useSavePatientIntakeMutation } from '@nuna/api'
import { Select, toast } from '@nuna/tunic'

interface Props {
  patientId: string
}

export function PatientPaymentPreference({ patientId }: Props) {
  const [savePatientIntake] = useSavePatientIntakeMutation()
  const { data } = useIntakeProgressQuery({
    variables: {
      id: patientId,
    },
  })

  const preference = data?.intakeProgress?.paymentPreference

  const handlePreferenceSelection = async (e: React.FormEvent<HTMLSelectElement>) => {
    await savePatientIntake({
      variables: {
        id: patientId,
        input: {
          paymentPreference: (e.currentTarget.value ?? 'CASH') as PaymentPreference,
        },
      },
      optimisticResponse: {
        savePatientIntake: {
          __typename: 'PatientIntake',
          id: patientId,
          intakeCompleted: data?.intakeProgress?.intakeCompleted ?? false,
          paymentPreference: (e.currentTarget.value ?? 'CASH') as PaymentPreference,
        },
      },
    })

    toast.info('Payment preference saved')
  }

  return (
    <ThinSection>
      <h3 className="h5">Payment Preference</h3>
      <p className="text-secondary">
        The billing method the patient prefers. If valid, we will try to use this method to bill.
      </p>
      <Select
        label="Payment Preference"
        onChange={handlePreferenceSelection}
        value={preference ?? PaymentPreference.Cash}
      >
        <option value={PaymentPreference.Cash}>{PaymentPreference.Cash}</option>
        <option value={PaymentPreference.Employer}>{PaymentPreference.Employer}</option>
        <option value={PaymentPreference.Insurance}>{PaymentPreference.Insurance}</option>
        <option value={PaymentPreference.Accesscode}>{PaymentPreference.Accesscode}</option>
      </Select>
    </ThinSection>
  )
}

const ThinSection = styled('section')`
  max-width: 320px;
`
