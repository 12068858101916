import { HTMLAttributes } from 'react'

import { ProviderStatus, Role } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { providerStatusDetails } from '@nuna/provider'
import { StatusLabel } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  status?: ProviderStatus | null
  endDate?: string | null
}

export function ProviderStatusLabel({ status, endDate, ...props }: Props) {
  const { login } = useAuthDataContext()

  const notAdmin = !login?.role ? true : login.role !== Role.Admin

  if (!status) {
    return null
  }

  const { intent, statusText } = providerStatusDetails(status, endDate)

  // Do not show the status if the user is not an admin and the status is not
  // Offboarding or Onboarding
  if (!['Offboarding', 'Onboarding'].includes(statusText as string) && notAdmin) {
    return null
  }

  return (
    <StatusLabel {...props} intent={intent}>
      {statusText}
    </StatusLabel>
  )
}
