import { styled } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { compact } from 'lodash'
import { useEffect } from 'react'

import { useInsurancePlansQuery, useSaveInsurancePoliciesMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { PayerSelect } from '@nuna/coverage'
import { Drawer, FillButton, eggshell, makeTypographyComponent, toast } from '@nuna/tunic'

import { InsurancePolicyTablePlanSelect } from './InsurancePolicyTablePlanSelect'

interface FormValues {
  payerId?: string | null
  planId?: string | null
}

interface Props {
  isOpen: boolean
  onClose: () => void
  policyIds: string[]
}

const INITIAL_VALUES: FormValues = { payerId: null, planId: null }

export function UpdatePolicyPayerDrawer({ isOpen, policyIds, onClose }: Props) {
  const [savePolicies, { loading: saveLoading }] = useSaveInsurancePoliciesMutation({
    refetchQueries: ['SearchInsurancePolicyList'],
  })

  const handleSubmit = async (values: FormValues) => {
    try {
      await savePolicies({
        variables: {
          insurancePoliciesInput: policyIds.map(id => ({
            id,
            insurancePayerId: values.payerId ?? undefined,
            insurancePayerPlanId: values.planId ?? undefined,
          })),
        },
      })
      toast.success('Updated policies successfuly')
      onClose()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to update policies'))
    }
  }
  return (
    <StyledDrawer size="min(400px, 100vw)" onClose={onClose} isOpen={isOpen} PaperProps={{ className: 'py-2' }}>
      <ContentContainer>
        <Header>Update policies</Header>
        <div className="text-secondary mb-3">
          Change payer and/or plan for {policyIds.length} {policyIds.length === 1 ? 'policy' : 'policies'}
        </div>
        <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
          {formProps => <UpdatePolicyForm {...formProps} saveLoading={saveLoading} />}
        </Formik>
      </ContentContainer>
    </StyledDrawer>
  )
}

function UpdatePolicyForm({ values, setFieldValue, saveLoading }: FormikProps<FormValues> & { saveLoading: boolean }) {
  const { data, loading: plansLoading } = useInsurancePlansQuery({
    variables: { filters: { insurancePayerId: values.payerId } },
    skip: !values.payerId,
  })

  const showPlanSelect = (data?.insurancePayerPlans.items ?? []).length > 1

  useEffect(() => {
    setFieldValue('planId', null)
  }, [values.payerId, setFieldValue])

  return (
    <Form>
      <FormGroup>
        <PayerSelect label="New payer" value={values.payerId} onChange={value => setFieldValue('payerId', value?.id)} />
      </FormGroup>
      {showPlanSelect && (
        <FormGroup>
          <InsurancePolicyTablePlanSelect
            label="New plan"
            disabled={plansLoading}
            payerId={values.payerId}
            value={values.planId}
            onChange={value => setFieldValue('planId', value)}
          />
        </FormGroup>
      )}

      <FillButton
        isLoading={saveLoading}
        disabled={compact(Object.values(values)).length === 0}
        className="full-width mt-3"
        type="submit"
      >
        Update Policies
      </FillButton>
    </Form>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`

const ContentContainer = makeTypographyComponent('px-2', 'div')
const Header = makeTypographyComponent('sans-serif text-medium large space-between v-align', 'h1')
const FormGroup = makeTypographyComponent('pb-2', 'div')
