import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { pick } from 'lodash'
import { HTMLAttributes } from 'react'

import { SessionFinancialFragment, useCreateAdminAdjustmentFinancialMutation } from '@nuna/api'
import { errorService, formService, numberService } from '@nuna/core'
import { GreySection } from '@nuna/provider'
import { FillButton, OutlineButton, TextField, toast } from '@nuna/tunic'

const { composeHelperText } = formService

interface Props {
  adjustment?: Partial<SessionFinancialFragment> | null
  onCancel: () => void
  onSave: () => void
}

const VALIDATION_SCHEMA = Yup.object().shape({
  note: Yup.string().required('Note is required'),
  providerCompensatedAmount: Yup.number().required('Amount is required'),
  appointmentId: Yup.string().required('Appointment required'),
  cptCode: Yup.string().nullable(),
  appointmentHistoryItemId: Yup.string().nullable(),
})

export function AdminAdjustmentForm({
  adjustment = {},
  onCancel,
  onSave,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const [createAdjustment, { loading }] = useCreateAdminAdjustmentFinancialMutation()

  const submit = async (values: Partial<SessionFinancialFragment>) => {
    try {
      await createAdjustment({
        variables: {
          adminAdjustment: {
            note: values.note || '',
            providerCompensatedAmount: numberService.dollarsToCents(values.providerCompensatedAmount || 0),
            appointmentId: values.appointmentId || '',
            appointmentHistoryItemId: values.appointmentHistoryItemId,
            cptCode: values.cptCode,
          },
        },
      })
      toast.success('Financial created successfully')
      onSave()
    } catch (error) {
      toast.urgent(errorService.transformUserGraphqlError(error, 'There was a problem saving a financial'))
    }
  }

  const sessionFinancial: Partial<SessionFinancialFragment> = {
    ...pick(adjustment, ['appointmentHistoryItemId', 'appointmentId', 'cptCode']),
    note: undefined,
    providerCompensatedAmount: undefined,
  }

  return (
    <GreySection {...props}>
      <Formik initialValues={sessionFinancial} validationSchema={VALIDATION_SCHEMA} onSubmit={submit}>
        {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="v-align">
              <TextField
                name="providerCompensatedAmount"
                label="Amount"
                error={touched.providerCompensatedAmount && !!errors.providerCompensatedAmount}
                helperText={composeHelperText(
                  'Adjustment amount',
                  errors.providerCompensatedAmount,
                  touched.providerCompensatedAmount,
                )}
                className="text-field"
                value={values.providerCompensatedAmount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="note"
                label="Note"
                error={touched.note && !!errors.note}
                helperText={composeHelperText('Explain the reason for this adjustment', errors.note, touched.note)}
                className="ml-2 text-field"
                style={{ width: 300 }}
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                name="cptCode"
                label="CPT Code"
                error={touched.cptCode && !!errors.cptCode}
                helperText={composeHelperText('Adjusted CPT Code', errors.cptCode, touched.cptCode)}
                className="ml-2 text-field"
                value={values.cptCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="ml-auto actions">
                <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
                <FillButton isLoading={loading} type="submit" className="ml-1">
                  Save
                </FillButton>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </GreySection>
  )
}

const Form = styled('form')`
  .text-field {
    width: 130px;

    input[type='number'] {
      text-align: right;
    }
  }
  .actions {
    text-align: right;
  }
`
