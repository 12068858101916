import { styled } from '@mui/material'
import { useState } from 'react'

import { type ProviderEnrollmentInput, useBulkSaveProviderEnrollmentsMutation } from '@nuna/api'
import { Dialog, FillButton, greySet, makeTypographyComponent, toast } from '@nuna/tunic'

import { AddPayerEnrollments } from './AddPayerEnrollments'
import { AddProviderEnrollments } from './AddProviderEnrollments'
import { AddProviderPayerEnrollments } from './AddProviderPayerEnrollments'

type Props = {
  scope: { providerId?: string | null; insurancePayerId?: string | null }
  refetchEnrollments?: () => void
}

export function CreateNewEnrollment({ scope, refetchEnrollments }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const closeDialog = () => setIsOpen(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  return (
    <>
      <ActionButton handleClick={handleClick} />
      <EnrollmentDialog
        isOpen={isOpen}
        closeDialog={closeDialog}
        scope={scope}
        refetchEnrollments={refetchEnrollments}
      />
    </>
  )
}

type ActionButtonProps = {
  handleClick: () => void
}

function ActionButton({ handleClick }: ActionButtonProps) {
  return (
    <FillButton className="ml-2" onClick={handleClick}>
      New Enrollments
    </FillButton>
  )
}

type EnrollmentDialogProps = {
  isOpen: boolean
  closeDialog: () => void
  scope: { providerId?: string | null; insurancePayerId?: string | null }
  refetchEnrollments?: () => void
}

function EnrollmentDialog({ isOpen, closeDialog, scope, refetchEnrollments }: EnrollmentDialogProps) {
  const [saveEnrollments, { loading: isSaving }] = useBulkSaveProviderEnrollmentsMutation()

  const handleSave = async (enrollments: ProviderEnrollmentInput[]) => {
    await saveEnrollments({ variables: { enrollments } })
    toast.success(`Enrollments created`)
    if (refetchEnrollments) refetchEnrollments()
    closeDialog()
  }

  const templateScope = scope.providerId ? 'provider' : scope.insurancePayerId ? 'payer' : 'current'

  const template = {
    provider: <AddProviderEnrollments viewScopeId={scope.providerId} onSave={handleSave} isSaving={isSaving} />,
    payer: <AddPayerEnrollments viewScopeId={scope.insurancePayerId} onSave={handleSave} isSaving={isSaving} />,
    current: <AddProviderPayerEnrollments onSave={handleSave} isSaving={isSaving} />,
  }

  return (
    <Dialog isOpen={isOpen} maxWidth="xl" onClose={() => closeDialog()}>
      <Container>
        <Header>Create New Enrollments</Header>
        <Content id="create-new-enrollment-content">{template[templateScope]}</Content>
      </Container>
    </Dialog>
  )
}

const Header = styled(makeTypographyComponent('v-align', 'h5'))`
  border-bottom: 1px solid ${greySet[15].hex};
`
const Container = styled(makeTypographyComponent('p-2 flex-column full-height overflow-hidden', 'div'))`
  min-width: 1360px;
  gap: var(--spacing-1);
`
const Content = styled(makeTypographyComponent('overflow-hidden', 'div'))`
  height: 600px;
`
