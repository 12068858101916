import { styled } from '@mui/material'
import { Popover } from '@mui/material'
import { debounce, noop } from 'lodash'
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import { MouseEvent } from 'react'

import { usePrevious } from '@nuna/common'
import { Badge, DropdownButton, IconClose, IconFilter, PageSearch, csx, interactiveText } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onSearchTextChange: (searchText: string) => void
  searchPlaceholder?: string
  filtersApplied: number
  onClearFilters?: () => void
  orientation?: 'horizontal' | 'vertical'
}

export function DataFilters({
  onSearchTextChange,
  onClearFilters = noop,
  searchPlaceholder = 'Search records',
  filtersApplied,
  children,
  className,
  orientation = 'horizontal',
  ...props
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [searchText, setSearchText] = useState('')
  const initialized = useRef(false)
  const previousSearchText = usePrevious(searchText)
  const hasAppliedFilters = filtersApplied > 0

  const search = useMemo(
    () => debounce((searchText: string) => onSearchTextChange(searchText), 600),
    [onSearchTextChange],
  )

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      // check against the previous value
      // this prevents infinite loops caused by updating
      // filter values upstream which then cause this component
      // to render
    } else if (searchText !== previousSearchText) {
      search(searchText)
    }
  }, [search, searchText, previousSearchText])

  const handleClearFilters = useMemo(() => {
    if (hasAppliedFilters) {
      return (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onClearFilters()
      }
    }
    return undefined
  }, [hasAppliedFilters, onClearFilters])

  const handleOnClear = () => {
    setSearchText('')
  }

  return (
    <>
      <Container className={csx([{ vertical: orientation === 'vertical' }, className])} {...props}>
        <PageSearch
          onChange={event => setSearchText(event.target.value)}
          onClear={() => handleOnClear()}
          placeholder={searchPlaceholder}
          value={searchText}
          autoFocus={true}
          className="search"
        />
        <DropdownButton
          className="filter-button"
          onClick={event => setAnchorEl(event.currentTarget)}
          isActive={!!anchorEl}
          rightSlot={
            filtersApplied > 0 && <IconClose className="clear-icon ml-1" size={14} onClick={handleClearFilters} />
          }
        >
          <span className="v-align">
            {filtersApplied > 0 && <Badge>{filtersApplied}</Badge>}
            {!filtersApplied && <IconFilter />}
            <span className="ml-1">Filter</span>
          </span>
        </DropdownButton>
      </Container>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 36, horizontal: 0 }}
      >
        <PopoverContent>{children}</PopoverContent>
      </Popover>
    </>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  &.vertical {
    flex-direction: column;
    .search {
      width: 100%;
      margin-bottom: var(--spacing-2);
      .search-input {
        flex: 1;
      }
    }

    .filter-button .inner-button {
      width: 100%;
      justify-content: space-between;
    }
  }

  .clear-icon:hover {
    color: ${interactiveText};
  }

  &:not(.vertical) {
    align-items: center;
    .filter-button {
      margin-left: var(--margin-2);
    }
  }
`

const PopoverContent = styled('div')`
  padding: var(--margin-2);
  width: 300px;
`
