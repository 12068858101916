import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useSimplePayerQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { PageContent, PageWrapper } from '@nuna/tunic'

import { NavLayout } from '../../layouts/NavLayout'
import { BreadcrumbBar } from '../../layouts/detail-layout/BreadcrumbBar'
import { BreadcrumbItem } from '../../layouts/detail-layout/Breadcrumbs'
import { payers } from '../../util/routes'
import { PayerPlanForm } from './PayerPlanForm'

export function NewPayerPlan() {
  const { id: payerId = '' } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const { data, loading } = useSimplePayerQuery({ variables: { id: payerId } })

  const breadcrumbs = useMemo<BreadcrumbItem[]>(
    () => [
      { label: 'Payers', path: payers },
      {
        label: data?.insurancePayer.name ?? '',
        path: routeService.insurancePayer(data?.insurancePayer.id ?? ''),
      },
      {
        label: 'New Plan',
      },
    ],
    [data],
  )

  return (
    <NavLayout>
      <PageWrapper>
        <BreadcrumbBar breadcrumbs={breadcrumbs} loading={loading} />
        <PageContent>
          <PayerPlanForm
            payerId={payerId}
            afterSave={planId => navigate(routeService.insurancePayerPlan(planId ?? '', payerId))}
          />
        </PageContent>
      </PageWrapper>
    </NavLayout>
  )
}
