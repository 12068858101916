import { compact } from 'lodash'
import { HTMLAttributes, useMemo } from 'react'

import { csx } from '@nuna/tunic'

import { FilterDisplay } from './components/FilterDisplay'
import { FilterSelector } from './components/FilterSelector'
import { Filter, FilterUrlAccessor } from './filters.types'
import { UrlFilters, useUrlFilters } from './hooks/useUrlFilters'

interface FiltersProps<T extends Record<string, unknown>> extends HTMLAttributes<HTMLDivElement> {
  filters: Filter[]
  urlFilterService: ReturnType<typeof useUrlFilters<T>>
  transformUrlFilters?: (values: UrlFilters) => unknown
}

export function Filters<T extends Record<string, unknown>>({
  filters,
  urlFilterService,
  className,
  transformUrlFilters,
  ...props
}: FiltersProps<T>) {
  const { setFilter, removeFilter, filterValues, getCustomTransformedValues } = urlFilterService

  const transformedValues: Record<string, unknown> = useMemo(() => {
    return transformUrlFilters
      ? (getCustomTransformedValues(transformUrlFilters) as Record<string, unknown>)
      : filterValues
  }, [filterValues, getCustomTransformedValues, transformUrlFilters])

  const filtersMergedWithValues: Filter[] = useMemo(() => {
    const fromSelections = compact(
      Object.keys(transformedValues).map(key => {
        const filter = filters.find(f => f.key === key)
        return filter ? { ...filter, value: transformedValues[key] } : undefined
      }),
    )

    return [...fromSelections, ...filters.filter(f => !fromSelections.some(s => s?.key === f.key))]
  }, [filters, transformedValues])

  const filtersWithValues = filtersMergedWithValues.filter(filter => filter.value !== undefined)

  const handleRemoveFilter = ({ key, urlParam }: FilterUrlAccessor) => {
    removeFilter(urlParam ?? key)
    filters.find(f => f.key === key)?.onRemove?.()
  }

  const handleMultiValueRemove = ({ key, urlParam }: FilterUrlAccessor, value: unknown) => {
    const filter = filtersMergedWithValues.find(f => f.key === key)
    if (filter) {
      const values = Array.isArray(filter.value) ? filter.value : [filter.value]
      const newValue = values.filter(v => v !== value)
      if (newValue.length === 0) {
        removeFilter(urlParam ?? key)
        filters.find(f => f.key === key)?.onRemove?.()
        return
      }
      setFilter(urlParam ?? key, newValue)
    }
  }

  const handleSetFilter = ({ key, urlParam }: FilterUrlAccessor, value: unknown) => {
    setFilter(urlParam ?? key, value)
  }

  return (
    <div className={csx([className, 'v-align gap-2'])} {...props}>
      {filtersWithValues.length > 0 && (
        <div className="v-align gap-1 flex-wrap">
          {filtersWithValues.map(filter => (
            <FilterDisplay
              key={filter.key}
              filter={filter}
              onRemove={handleRemoveFilter}
              onMultiValueRemove={handleMultiValueRemove}
              onChange={value => setFilter(filter.urlParam ?? filter.key, value)}
            />
          ))}
        </div>
      )}

      <FilterSelector
        filters={filtersMergedWithValues}
        condensed={filtersWithValues.length > 0}
        onFilterChange={handleSetFilter}
      />
    </div>
  )
}
