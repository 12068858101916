import { DocumentType, Provider } from '@nuna/api'
import { DocumentCenter } from '@nuna/document-center'

type Props = {
  provider?: Provider
}
export const CredentialingDocumentsDialogContent = ({ provider }: Props) => {
  return (
    <DocumentCenter
      showHeader={false}
      loginId={provider?.loginId}
      documentTypes={[
        DocumentType.Attestation,
        DocumentType.StateLicense,
        DocumentType.CaqhForm,
        DocumentType.LiabilityInsurance,
        DocumentType.Resume,
        DocumentType.PracticeAddressW9,
      ]}
    />
  )
}
