import { styled } from '@mui/material'
import { Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

import { IconFilter, ToggleButton, body2, greySet, shadowDepth } from '@nuna/tunic'

import { Filter, FilterUrlAccessor } from '../filters.types'
import { FilterValueSelector } from './FilterValueSelector/FilterValueSelector'

interface FilterSelectorProps {
  filters: Filter[]
  onFilterChange: (filter: FilterUrlAccessor, value: unknown) => void
  condensed?: boolean
}

export function FilterSelector({ filters, condensed = false, onFilterChange }: FilterSelectorProps) {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const filtersWithoutValues = filters.filter(filter => filter.value === undefined)

  if (filtersWithoutValues.length === 0) return null

  const selectedFilter = filters.find(filter => filter.key === selectedFilterKey)

  const handleClose = () => {
    setAnchorEl(null)
    setTimeout(() => setSelectedFilterKey(null), 300)
  }

  const handleChange = (value: unknown) => {
    handleClose()
    onFilterChange(selectedFilter ?? { key: '' }, value)
    if (selectedFilter?.onChange) {
      selectedFilter.onChange(value)
    }
  }

  return (
    <>
      <ToggleButton tooltip="Apply filter" onClick={e => setAnchorEl(e.currentTarget)}>
        {!condensed && <span className="mr-1">Filter</span>}
        <IconFilter size={16} />
      </ToggleButton>
      <StyledMuiMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {!!selectedFilter && <FilterValueSelector filter={selectedFilter} onChange={handleChange} />}
        {!selectedFilter &&
          filtersWithoutValues.map(filter => (
            <StyledMuiMenuItem key={filter.key} onClick={() => setSelectedFilterKey(filter.key)}>
              {filter.label}
            </StyledMuiMenuItem>
          ))}
      </StyledMuiMenu>
    </>
  )
}

export const StyledMuiMenu = styled(Menu)`
  .MuiPopover-paper {
    background-color: ${greySet[0].hex};
    border: 1px solid ${greySet[30].hex};
    border-radius: var(--border-radius);
    box-shadow: ${shadowDepth(4.5)};
    padding: 0.75rem 1rem;
  }
`

export const StyledMuiMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    border-radius: var(--border-radius-sm);
    color: ${body2};
    display: flex;
    font-weight: 500;
    text-decoration: none;
    padding: 0.875rem 0.75rem;

    &.focus-visible {
      box-shadow: 0 0 0 4px ${greySet.tint[40]};
      outline: none;
    }

    &:hover {
      background-color: ${greySet[50].transparency(0.32)};
      color: ${greySet[80].hex};
    }
  }
`
