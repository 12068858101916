import { omit } from 'lodash'
import moment from 'moment'
import { useRef } from 'react'

import {
  ContractType,
  PatientCompanyHistoryItem,
  PatientCompanyHistoryItemInput,
  useUpdatePatientCompanyHistoryMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { Confirm, ConfirmRef, TextButton, toast } from '@nuna/tunic'

interface ResetCompanyHistoryItemButtonProps {
  patientId: string
  historyItems: PatientCompanyHistoryItem[]
  itemIndex: number
  onSaved: () => void
}

export function RestoreCompanyHistoryItemButton({
  patientId,
  historyItems,
  itemIndex,
  onSaved,
}: ResetCompanyHistoryItemButtonProps) {
  const confirmRef = useRef<ConfirmRef>(null)
  const [updatePatientCompanyHistory, { loading }] = useUpdatePatientCompanyHistoryMutation()

  const handleReset = async () => {
    confirmRef.current?.open(async userResponse => {
      if (userResponse) {
        try {
          const historyItemsInput: PatientCompanyHistoryItemInput[] = historyItems.map((item, index) => {
            const sanitized: PatientCompanyHistoryItemInput = {
              ...omit(item, [
                '__typename',
                'companyContractSummary',
                'couponContractSummary',
                'linkedEmployee',
                'linkedSponsor',
              ]),
              companyId: item.companyContractSummary?.companyId,
              contractId: item.couponContractSummary?.contractId,
              eligibleRecordId: item.linkedEmployee?.id ?? null,
              sponsoringEmployeeId: item.linkedSponsor?.id ?? null,
            }

            if (index === itemIndex) {
              return {
                ...sanitized,
                unassignDateReason: null,
                lastUnassignedDate: item.companyContractSummary?.lastCompanyEndDate,
                thisRelationHasBeenDeEligibilized: false,
                eligiblizationStatusLastChangedDate: null,
              }
            } else if (index === itemIndex - 1 && item.contractType === ContractType.CashContract) {
              const lastCompanyEndDate = moment(historyItems[itemIndex].companyContractSummary?.lastCompanyEndDate)
              if (!lastCompanyEndDate.isValid()) {
                throw new Error('Invalid last company end date')
              }
              const firstAssignedDate = lastCompanyEndDate.add(1, 'millisecond').toISOString()
              return {
                ...sanitized,
                firstAssignedDate,
              }
            }
            return sanitized
          })

          await updatePatientCompanyHistory({
            variables: {
              patientId,
              historyItems: historyItemsInput,
            },
          })
          onSaved()
        } catch (error) {
          toast.urgent(
            errorService.transformGraphQlError(
              error,
              (error as Error).message ?? 'Failed to reset company history item',
            ),
          )
        }
      }
    })
  }
  return (
    <>
      <TextButton isLoading={loading} onClick={handleReset}>
        Restore
      </TextButton>
      <Confirm ref={confirmRef}>Are you sure you want to restore this patient history record?</Confirm>
    </>
  )
}
