import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import { ReactNode, useEffect, useMemo } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { matchPath, useLocation } from 'react-router-dom'

import { usePrevious } from '@nuna/common'
import { MenuItem, MenuItemStack, csx, greySet } from '@nuna/tunic'

import { useNavContext } from '../../../context-providers/NavContextProvider'

interface Props {
  label: string
  name: string
  pathMatches: string[]
  children: ReactNode
}
export function NavGroup({ label, name, pathMatches, children }: Props) {
  const isRouteMatch = useMatchesGroupPaths(pathMatches)
  const { pathname } = useLocation()
  const previousPath = usePrevious(pathname)

  const { openNavGroup, setOpenNavGroup } = useNavContext()

  const isOpen = openNavGroup === name

  useEffect(() => {
    if (isRouteMatch && pathname !== previousPath) {
      setOpenNavGroup(name)
    }
  }, [isRouteMatch, name, setOpenNavGroup, pathname, previousPath])

  const handleClick = () => {
    setOpenNavGroup(openNavGroup === name ? '' : name)
  }

  return (
    <Container className={csx({ open: isOpen })}>
      <MenuItem button onClick={handleClick} className="space-between v-align full-width">
        {label}
        <MdArrowForwardIos className="ml-auto caret" />
      </MenuItem>
      <SecondaryNav
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <div className="inner-wrapper">
          <MenuItemStack>{children}</MenuItemStack>
        </div>
      </SecondaryNav>
    </Container>
  )
}

const Container = styled('div')`
  margin-top: 0.375rem;

  .caret {
    transition: transform 0.3s;
  }
  &.open {
    background-color: ${greySet[5].hex};
    border-radius: var(--border-radius-sm);
    .child-wrapper {
      height: auto;
    }

    .caret {
      transform: rotate(90deg);
    }
  }
`

const SecondaryNav = styled(motion.div)`
  overflow: hidden;
  .inner-wrapper {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
`

function useMatchesGroupPaths(paths: string[]) {
  const location = useLocation()
  return useMemo(() => compact(paths.map(path => matchPath(path, location.pathname))).length > 0, [paths, location])
}
