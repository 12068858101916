import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { compact } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { PayerRosterDefinitionConfigChangeset } from '@nuna/api'

type Option = { value: PayerRosterDefinitionConfigChangeset; label: string }
type LimitedAutocompleteProps = Omit<
  AutocompleteProps<Option | undefined, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface PayerRosterChangesetMultiselectProps extends LimitedAutocompleteProps {
  value?: PayerRosterDefinitionConfigChangeset[]
  onChange: (options: Option[]) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
}

export function PayerRosterChangesetMultiselect({
  value: valueInput = [],
  error,
  helperText,
  placeholder,
  label,
  onChange,
  ...props
}: PayerRosterChangesetMultiselectProps) {
  const [searchTerm, setSearchTerm] = useState('')

  const options = useMemo(() => buildOptions(), [])

  const value = useMemo<Option[]>(
    () => options.filter(option => valueInput.includes(option.value)),
    [valueInput, options],
  )

  const filteredOptions = useMemo<Option[]>(() => {
    if (!searchTerm) {
      return options
    }
    const searcher = new FuzzySearch(options, ['name', 'key'], {
      caseSensitive: false,
    })

    return searcher.search(searchTerm)
  }, [searchTerm, options])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(compact(value))
      }}
      defaultValue={[]}
      getOptionLabel={option => option?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={filteredOptions}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          name="selected-changesets"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  )
}

function buildOptions(): Option[] {
  return Object.values(PayerRosterDefinitionConfigChangeset)
    .filter(v => v !== PayerRosterDefinitionConfigChangeset.None)
    .map(changeset => ({
      value: changeset,
      label: getOptionLabel(changeset),
    }))
}

function getOptionLabel(value: PayerRosterDefinitionConfigChangeset) {
  switch (value) {
    case PayerRosterDefinitionConfigChangeset.Address:
      return 'Address'
    case PayerRosterDefinitionConfigChangeset.AgeLimits:
      return 'Age Limits'
    case PayerRosterDefinitionConfigChangeset.Billing:
      return 'Billing'
    case PayerRosterDefinitionConfigChangeset.License:
      return 'License'
    case PayerRosterDefinitionConfigChangeset.Name:
      return 'Provider Name'
    case PayerRosterDefinitionConfigChangeset.Organization:
      return 'Organization'
    case PayerRosterDefinitionConfigChangeset.Tax:
      return 'Tax Entity'
    case PayerRosterDefinitionConfigChangeset.Hours:
      return 'Office Hours'
    case PayerRosterDefinitionConfigChangeset.LicenseOther:
      return 'Other Licenses'
    case PayerRosterDefinitionConfigChangeset.Remit:
      return 'Remit Address'
    default:
      return ''
  }
}
