import * as Yup from 'yup'
import { Formik } from 'formik'

import {
  DocumentType,
  ProviderActivityEventType,
  useDocumentUpload,
  useSaveProviderActivityLogMutation,
} from '@nuna/api'
import { FileUpload, GhostButton, Select, TextField, toast } from '@nuna/tunic'

const historyItemSchema = Yup.object().shape({
  eventType: Yup.string().oneOf(Object.values(ProviderActivityEventType)).required(),
  description: Yup.string(),
  documentId: Yup.string(),
})

interface ProviderActivityLifecycleFormProps {
  onCancel: () => void
  onSuccess: () => void
  providerId: string
}

const acceptedFileTypes = [
  'application/msword', // doc
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument.text', // odt
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/x-tex',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'text/plain',
]

export function ProviderActivityLifecycleForm({ providerId, onSuccess, onCancel }: ProviderActivityLifecycleFormProps) {
  const { uploadDocument } = useDocumentUpload()
  const [saveProviderActivityLifecycle, { loading }] = useSaveProviderActivityLogMutation({
    update(cache, { data }) {
      const newItem = data?.saveProviderActivityLog

      if (!newItem) {
        return
      }

      cache.modify({
        fields: {
          providerActivityLogs(existingItems = []) {
            return [newItem, ...existingItems]
          },
        },
      })
    },
  })

  const handleProviderAcitivtyItemSubmit = async ({
    eventType,
    description,
    file,
  }: {
    eventType: ProviderActivityEventType
    description: string
    file: File | null
  }) => {
    try {
      const doc = file && (await uploadDocument(file, DocumentType.UserCustomDocument))

      await saveProviderActivityLifecycle({
        variables: {
          data: {
            providerId,
            eventType,
            // We will want to come back to this if we expand type
            title: eventType === ProviderActivityEventType.Note ? 'Note' : 'File Upload',
            description: description || file?.name,
            documentId: doc?.id,
          },
        },
      })

      onSuccess()
    } catch (e) {
      console.error(e)
      toast.urgent('Failed to save provider activity item')
    }
  }

  return (
    <Formik
      validationSchema={historyItemSchema}
      initialValues={{
        eventType: ProviderActivityEventType.Note,
        description: '',
        file: null,
      }}
      onSubmit={handleProviderAcitivtyItemSubmit}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form style={{ padding: '1px 0' }} className="v-align full-width" onSubmit={handleSubmit}>
          <Select
            style={{ width: 210, flex: '0 0 auto' }}
            className="mr-2"
            label="Event Type"
            name="eventType"
            onChange={handleChange}
            value={values.eventType}
          >
            <option value={ProviderActivityEventType.Note}>Note</option>
            <option value={ProviderActivityEventType.FileUpload}>File Upload</option>
          </Select>

          {values.eventType === ProviderActivityEventType.FileUpload && (
            <FileUpload
              onDrop={files => setFieldValue('file', files[0])}
              maxSize={25000000}
              accept={acceptedFileTypes}
            />
          )}

          {values.eventType === ProviderActivityEventType.Note && (
            <TextField
              className="fs-exclude"
              name="description"
              onChange={handleChange}
              value={values.description}
              label="Description"
              style={{ flex: '1 0 auto' }}
              autoFocus
            />
          )}

          <div className="ml-2" style={{ flex: '0 0 auto' }}>
            <GhostButton className="mr-2" variant="secondary" type="button" onClick={onCancel}>
              Cancel
            </GhostButton>
            <GhostButton
              type="submit"
              isLoading={loading}
              disabled={values.eventType === ProviderActivityEventType.Note ? !values.description : !values.file}
            >
              Submit
            </GhostButton>
          </div>
        </form>
      )}
    </Formik>
  )
}
